import React from 'react'
import { useNavigate } from 'react-router-dom'
import Paths from '../../../Paths'
import {
  BrightMyPracticesButton,
  PrimaryMyPracticesButton,
  SecondaryMyPracticesButton,
} from './styled'

const defaultText = 'Back to my practices'

const MyPracticesButton = ({ buttonType = 'primary', text = null }) => {
  const navigate = useNavigate()

  return (
    <>
      {buttonType === 'secondary' ? (
        <SecondaryMyPracticesButton onClick={() => navigate(Paths.myPractices)}>
          {text || defaultText}
        </SecondaryMyPracticesButton>
      ) : buttonType === 'bright' ? (
        <BrightMyPracticesButton onClick={() => navigate(Paths.myPractices)}>
          {text || defaultText}
        </BrightMyPracticesButton>
      ) : (
        <PrimaryMyPracticesButton onClick={() => navigate(Paths.myPractices)}>
          {text || defaultText}
        </PrimaryMyPracticesButton>
      )}
    </>
  )
}

export default MyPracticesButton
