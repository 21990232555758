import { useApolloClient } from '@apollo/client'
import { useMutationWithErrorLogging } from '../../../utils/hooks'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { usePracticeById } from '../../../api/hooks/practices'
import { EDIT_PRACTICE_LEASE } from '../../../api/mutations/practices'
import CreatePracticeInput from '../../../components/CreatePractice/CreatePracticeInput'
import CreatePracticeInputForm from '../../../components/CreatePractice/CreatePracticeInputForm/CreatePracticeInputForm'
import { updatePracticeByIdInCache } from '../../../utils/cache'
import {
  booleanFields,
  editingFlowKey,
  getBuildingForSaleValueFromSaleType,
  negotiableBooleanFields,
  optionalBooleanFields,
  practiceAndBuildingSaleTypeKey,
  practiceBuildingTypes,
  practiceOnlySaleTypeKey,
  practiceWithNegotiableBuildingSaleTypeKey,
  publishingFlowKey,
  roomForOperatoryExpansionOptions,
} from '../../../utils/constants'
import { valueIsEmpty } from '../../../utils/data'
import {
  getIdOfFirstInputFieldWithError,
  handleIntegerFieldChange,
} from '../../../utils/input'
import {
  getCreatePracticePathToNavigateTo,
  makeCreatePracticePathInCurrentFlow,
  navigateToPublishListingPage,
  navigateToViewPracticePage,
} from '../../../utils/routing'
import { scrollElementIntoView } from '../../../utils/view'

const currentStep = 2

const Lease = ({ user, width, ismobilescreen }) => {
  const navigate = useNavigate()
  const { cache } = useApolloClient()
  const practiceFormRef = useRef()

  const [editPracticeLeaseMutation] =
    useMutationWithErrorLogging(EDIT_PRACTICE_LEASE)

  const { practiceId } = useParams()
  const { practice } = usePracticeById(practiceId)

  const [searchParams] = useSearchParams()
  const currentFlow = searchParams.get('flow')

  const [ownsBuilding, setOwnsBuilding] = useState(practice?.ownsBuilding || '')
  const [buildingForSale, setBuildingForSale] = useState(
    getBuildingForSaleValueFromSaleType(practice?.saleType),
  )
  const [roomForOperatoryExpansion, setRoomForOperatoryExpansion] = useState(
    practice?.roomForOperatoryExpansion || '',
  )
  const [numOfExtraOperatories, setNumOfExtraOperatories] = useState(
    practice?.numOfExtraOperatories || '',
  )
  const [buildingType, setBuildingType] = useState(practice?.buildingType || '')
  const [buildingHasDemolitionClause, setBuildingHasDemolitionClause] =
    useState(practice?.buildingHasDemolitionClause || '')
  const [yearsLeftOnLease, setYearsLeftOnLease] = useState(
    practice?.yearsLeftOnLease || null,
  )
  const [buildingHasAppraisal, setBuildingHasAppraisal] = useState(
    practice?.buildingHasAppraisal || '',
  )

  const [numOperatories, setNumOperatories] = useState(
    practice?.operatories || null,
  )

  const [submittedDataWithErrors, setSubmittedDataWithErrors] = useState(false)
  const [changesMade, setChangesMade] = useState(false)

  const updateChangesMade = () => {
    if (!changesMade) {
      setChangesMade(true)
    }
  }

  useEffect(() => {
    if (practice) {
      setOwnsBuilding(practice.ownsBuilding)
      setBuildingForSale(getBuildingForSaleValueFromSaleType(practice.saleType))
      setNumOperatories(practice.operatories)
      setRoomForOperatoryExpansion(practice.roomForOperatoryExpansion)
      setBuildingType(practice.buildingType)
      setBuildingHasDemolitionClause(practice.buildingHasDemolitionClause)
      setYearsLeftOnLease(practice.yearsLeftOnLease)
      setBuildingHasAppraisal(practice?.buildingHasAppraisal)
      setNumOfExtraOperatories(practice?.numOfExtraOperatories)
    }
  }, [practice])

  // So that if they toggle ownsBuilding, but had previously set 'building for sale' = false, it doesnt default to false
  // when that new dropdown opens upon setting ownsBuilding = true. Same is true in the opposite way for demo/lease fields
  const toggleOwnsBuilding = (e) => {
    const { value } = e.target

    setOwnsBuilding(value)
    if (value === true) {
      setBuildingForSale('')
    } else {
      setBuildingHasDemolitionClause('')
      setBuildingHasAppraisal('')
      setYearsLeftOnLease(null)
    }

    updateChangesMade()
  }

  const callEditPracticeLeaseMutation = useCallback(
    async (flowToReturnTo = null) => {
      if (changesMade) {
        const navRef = practiceFormRef?.current?.getNavRef()
        navRef?.current?.setLoading(true)
        await editPracticeLeaseMutation({
          variables: {
            id: practiceId,
            ownsBuilding,
            buildingHasAppraisal: !ownsBuilding ? false : buildingHasAppraisal,
            saleType:
              !ownsBuilding || buildingForSale === 'No'
                ? practiceOnlySaleTypeKey
                : buildingForSale === 'Unsure'
                  ? practiceWithNegotiableBuildingSaleTypeKey
                  : practiceAndBuildingSaleTypeKey,
            roomForOperatoryExpansion,
            numOfExtraOperatories,
            buildingType,
            operatories: numOperatories,
            buildingHasDemolitionClause: !valueIsEmpty(
              buildingHasDemolitionClause,
            )
              ? buildingHasDemolitionClause
              : null,
            yearsLeftOnLease:
              yearsLeftOnLease != null ? parseInt(yearsLeftOnLease) : null,
          },
          onCompleted: async (data) => {
            await updatePracticeByIdInCache(
              practiceId,
              data.editPracticeLease,
              cache,
            )
            navRef?.current?.setLoading(false)

            if (flowToReturnTo === publishingFlowKey) {
              navigateToPublishListingPage(practiceId, navigate)
            } else if (flowToReturnTo === editingFlowKey) {
              navigateToViewPracticePage(practiceId, navigate)
            } else {
              // If we just want to navigate to the next step
              let nextPath = getCreatePracticePathToNavigateTo(
                currentStep,
                practiceId,
                'next',
                currentFlow,
                false,
                null,
                searchParams.get('valuation') === 'true',
              )

              if (currentFlow) {
                nextPath = makeCreatePracticePathInCurrentFlow(
                  nextPath,
                  currentFlow,
                )
              }

              navigate(nextPath)
            }
          },
        })
      } else {
        if (flowToReturnTo === publishingFlowKey) {
          navigateToPublishListingPage(practiceId, navigate)
        } else if (flowToReturnTo === editingFlowKey) {
          navigateToViewPracticePage(practiceId, navigate)
        } else {
          // If we just want to navigate to the next step
          let nextPath = getCreatePracticePathToNavigateTo(
            currentStep,
            practiceId,
            'next',
            currentFlow,
            false,
            null,
            searchParams.get('valuation') === 'true',
          )

          if (currentFlow) {
            nextPath = makeCreatePracticePathInCurrentFlow(
              nextPath,
              currentFlow,
            )
          }

          navigate(nextPath)
        }
      }
    },
    [
      practiceFormRef,
      buildingForSale,
      buildingHasAppraisal,
      buildingHasDemolitionClause,
      buildingType,
      cache,
      changesMade,
      currentFlow,
      numOperatories,
      editPracticeLeaseMutation,
      navigate,
      ownsBuilding,
      practiceId,
      roomForOperatoryExpansion,
      searchParams,
      yearsLeftOnLease,
      numOfExtraOperatories,
    ],
  )

  const onClickNext = (flowToReturnTo = null) => {
    // If they've clicked without next being enabled, we know there are errors
    let containsError = !nextEnabled

    let errorElementIdToScrollTo

    if (containsError) {
      errorElementIdToScrollTo =
        getIdOfFirstInputFieldWithError(inputElementsArray)
    }

    // If there is an error ID to scroll to, set state to reflect that, and scroll to the first erroneous field
    if (errorElementIdToScrollTo) {
      scrollElementIntoView(document, errorElementIdToScrollTo)
      setSubmittedDataWithErrors(true)
    } else {
      setSubmittedDataWithErrors(false)
      callEditPracticeLeaseMutation(flowToReturnTo)
    }
  }

  const inputElements = useMemo(
    () => ({
      ownsBuilding: {
        id: 'ownsBuilding',
        fieldType: 'dropdown',
        state: ownsBuilding,
        required: true,
      },
      buildingForSale: {
        id: 'buildingForSale',
        fieldType: 'dropdown',
        state: buildingForSale,
        required: ownsBuilding === true,
      },
      roomForOperatoryExpansion: {
        id: 'roomForOperatoryExpansion',
        fieldType: 'dropdown',
        state: roomForOperatoryExpansion,
        required: true,
      },
      numOfExtraOperatories: {
        id: 'numOfExtraOperatories',
        fieldType: 'dropdown',
        state: numOfExtraOperatories,
        required: !!roomForOperatoryExpansion,
      },
      buildingType: {
        id: 'buildingType',
        fieldType: 'dropdown',
        state: buildingType,
        required: true,
      },
      buildingHasDemolitionClause: {
        id: 'buildingHasDemolitionClause',
        fieldType: 'dropdown',
        state: buildingHasDemolitionClause,
        required: ownsBuilding !== true,
      },
      operatories: {
        id: 'operatories',
        fieldType: 'integer',
        state: numOperatories,
        required: true,
      },
      yearsLeftOnLease: {
        id: 'yearsLeftOnLease',
        fieldType: 'integer',
        state: yearsLeftOnLease,
        required: ownsBuilding !== true,
      },
      buildingHasAppraisal: {
        id: 'buildingHasAppraisal',
        fieldType: 'dropdown',
        state: buildingHasAppraisal,
        required: !!ownsBuilding,
      },
    }),
    [
      buildingForSale,
      buildingHasAppraisal,
      buildingHasDemolitionClause,
      buildingType,
      numOfExtraOperatories,
      numOperatories,
      ownsBuilding,
      roomForOperatoryExpansion,
      yearsLeftOnLease,
    ],
  )

  const formInputs = [
    <CreatePracticeInput
      value={ownsBuilding}
      label='Practice owner owns the building * '
      width={width}
      onChange={toggleOwnsBuilding}
      fieldType={inputElements['ownsBuilding']['fieldType']}
      elementId={inputElements['ownsBuilding']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
      options={booleanFields}
    />,
    <>
      {ownsBuilding && (
        <CreatePracticeInput
          value={buildingForSale}
          label='Building also for sale * '
          width={width}
          onChange={(e) => {
            setBuildingForSale(e.target.value)
            updateChangesMade()
          }}
          fieldType={inputElements['buildingForSale']['fieldType']}
          elementId={inputElements['buildingForSale']['id']}
          submittedDataWithErrors={submittedDataWithErrors}
          options={negotiableBooleanFields}
        />
      )}
    </>,
    <CreatePracticeInput
      value={roomForOperatoryExpansion}
      label='Room for operatory expansion * '
      width={width}
      onChange={(e) => {
        setRoomForOperatoryExpansion(e.target.value)
        updateChangesMade()
      }}
      fieldType={inputElements['roomForOperatoryExpansion']['fieldType']}
      elementId={inputElements['roomForOperatoryExpansion']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
      options={booleanFields}
    />,
    <>
      {roomForOperatoryExpansion && (
        <CreatePracticeInput
          value={numOfExtraOperatories}
          label='Amount of operatories that can be added * '
          width={width}
          onChange={(e) => {
            handleIntegerFieldChange(e, setNumOfExtraOperatories)
            updateChangesMade()
          }}
          fieldType={inputElements['numOfExtraOperatories']['fieldType']}
          elementId={inputElements['numOfExtraOperatories']['id']}
          submittedDataWithErrors={submittedDataWithErrors}
          options={roomForOperatoryExpansionOptions}
        />
      )}
    </>,
    <CreatePracticeInput
      value={buildingType}
      label='Building type * '
      width={width}
      onChange={(e) => {
        setBuildingType(e.target.value)
        updateChangesMade()
      }}
      fieldType={inputElements['buildingType']['fieldType']}
      elementId={inputElements['buildingType']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
      options={practiceBuildingTypes}
    />,
    <>
      {!ownsBuilding && (
        <CreatePracticeInput
          value={buildingHasDemolitionClause}
          label='Building has a demolition clause * '
          width={width}
          onChange={(e) => {
            setBuildingHasDemolitionClause(e.target.value)
            updateChangesMade()
          }}
          fieldType={inputElements['buildingHasDemolitionClause']['fieldType']}
          elementId={inputElements['buildingHasDemolitionClause']['id']}
          submittedDataWithErrors={submittedDataWithErrors}
          options={optionalBooleanFields}
        />
      )}
    </>,
    <CreatePracticeInput
      value={numOperatories}
      label='Number of operatories * '
      width={width}
      onChange={(e) => {
        handleIntegerFieldChange(e, setNumOperatories)
        updateChangesMade()
      }}
      fieldType={inputElements['operatories']['fieldType']}
      elementId={inputElements['operatories']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    <>
      {!ownsBuilding && (
        <CreatePracticeInput
          value={yearsLeftOnLease}
          label='Number of years left on the lease * '
          width={width}
          onChange={(e) => {
            handleIntegerFieldChange(e, setYearsLeftOnLease)
            updateChangesMade()
          }}
          fieldType={inputElements['yearsLeftOnLease']['fieldType']}
          elementId={inputElements['yearsLeftOnLease']['id']}
          submittedDataWithErrors={submittedDataWithErrors}
        />
      )}
    </>,
    <>
      {ownsBuilding && (
        <CreatePracticeInput
          value={buildingHasAppraisal}
          label='Building appraised * '
          width={width}
          onChange={(e) => {
            setBuildingHasAppraisal(e.target.value)
            updateChangesMade()
          }}
          fieldType={inputElements['buildingHasAppraisal']['fieldType']}
          elementId={inputElements['buildingHasAppraisal']['id']}
          submittedDataWithErrors={submittedDataWithErrors}
          options={booleanFields}
        />
      )}
    </>,
  ]

  const inputElementsArray = useMemo(() => {
    const fields = Object.values(inputElements)

    return fields
  }, [inputElements])

  const nextEnabled = useMemo(() => {
    return inputElementsArray.every((field) => {
      if (field['required']) {
        return field['state'] != null && field['state'] !== ''
      }
      return true
    })
  }, [inputElementsArray])

  return (
    practice && (
      <CreatePracticeInputForm
        ref={practiceFormRef}
        user={user}
        width={width}
        ismobilescreen={ismobilescreen}
        currentStep={currentStep}
        practice={practice}
        formTitle={'Real Estate'}
        formInputs={formInputs}
        nextEnabled={nextEnabled}
        onClickNext={() => onClickNext()}
        currentFlow={currentFlow}
        onSaveInFlow={() => onClickNext(currentFlow)}
        inValuationMode={searchParams.get('valuation') === 'true'}
      />
    )
  )
}

export default Lease
