import styled from 'styled-components/macro'
import {
  BaseWidth,
  ColumnFlexStyle,
  ContentContainer,
  Form,
  IntrasectionalGap,
  LargeParagraphText,
  TitleText,
} from '../../styles/shared-styled-components'

export const LocationSearchForm = styled(Form)`
  width: 100%;
`

export const Container = styled(ContentContainer)`
  justify-content: flex-start;
  align-items: center;
  ${BaseWidth}
  ${IntrasectionalGap}
`

export const HeaderTextContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const HeaderText = styled(TitleText)`
  text-align: ${(props) => props.ismobilescreen && 'center'};
  color: ${({ theme }) => theme.color.white};
`

export const BuySellContainer = styled(ContentContainer)`
  ${BaseWidth}
  justify-content: flex-start;
  align-items: center;
  ${IntrasectionalGap}
  height: 100%;
  margin-bottom: 50px;
`

export const Card = styled.div`
  display: flex;
  width: 100%;
  max-width: 700px;
  border-radius: ${({ theme }) => `${theme.spacing.baseBorderRadius}px`};
  border: ${({ theme }) => `1px solid ${theme.color.primary}`};
  background-color: white;
  height: 210px;
  padding: ${(props) => (props.ismobilescreen ? '40px 20px' : '20px')};
  ${IntrasectionalGap}
`

export const CardLeft = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`

export const CardAsset = styled.img`
  max-height: 130px;
  border-radius: ${({ theme }) => `${theme.spacing.baseBorderRadius}px`};
`

export const CardRight = styled.div`
  flex: 2;
  ${ColumnFlexStyle}
  align-items: ${(props) => (props.ismobilescreen ? 'center' : 'flex-start')};
  justify-content: center;
  ${IntrasectionalGap}
`

export const CardTitleText = styled(TitleText)``

export const CardSubtitleText = styled(LargeParagraphText)`
  text-align: ${(props) => props.ismobilescreen && 'center'};
`
