import { useApolloClient } from '@apollo/client'
import { useMutationWithErrorLogging } from '../../../utils/hooks'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { usePracticeById } from '../../../api/hooks/practices'
import { EDIT_PRACTICE_OVERVIEW } from '../../../api/mutations/practices'
import CreatePracticeInput from '../../../components/CreatePractice/CreatePracticeInput'
import { CreatePracticeTextInputComponent } from '../../../components/CreatePractice/CreatePracticeInput/styled'
import CreatePracticeInputForm from '../../../components/CreatePractice/CreatePracticeInputForm/CreatePracticeInputForm'
import PrivacyShield from '../../../components/Micro/PrivacyShield/PrivacyShield'
import LocationSearchBox from '../../../components/Search/LocationSearchBox'
import { updatePracticeByIdInCache } from '../../../utils/cache'
import {
  americanCountryCode,
  booleanFields,
  canadianCountryCode,
  dpmss,
  editingFlowKey,
  practiceTypes,
  publishingFlowKey,
  practiceOverviewFieldsBasedOnType,
  openDaysPerWeekFields,
  doctorNameBasedOnType,
} from '../../../utils/constants'
import { valueIsEmpty } from '../../../utils/data'
import {
  checkIfValidAmericanZipCode,
  checkIfValidCanadianPostalCode,
  getIdOfFirstInputFieldWithError,
  handlePercentageFieldChange,
} from '../../../utils/input'
import {
  formatLocationString,
  getCityFromGeocodedAddress,
  getProvinceOrStateFromGeocodedAddress,
} from '../../../utils/location'
import {
  getFloatFromPercentage,
  getIntFromCurrency,
} from '../../../utils/numbers'
import {
  getCreatePracticePathToNavigateTo,
  makeCreatePracticePathInCurrentFlow,
  navigateToPublishListingPage,
  navigateToViewPracticePage,
} from '../../../utils/routing'
import { scrollElementIntoView } from '../../../utils/view'
import { LocationSearchPopperMenuStyle } from './styled'

const currentStep = 0

const addressFieldPrivacyMessage = (
  <>
    Your full address will not be shown until the closing stages of the
    acquisition process. Unless you personally approve them, no user will ever
    be able to see your address.
    <br></br>
    <br></br>
    However, we do display the first 3 digits of your postal code to all users.
    This covers a range of 16 square kilometers, and is meant to help buyers
    understand your general area while still maintaining your anonymity.
  </>
)

const postalCodeFieldPrivacyMessage = (
  <>
    Your complete postal code is only visible to verified Dentacloud members
    that you've personally approved access for.
    <br></br>
    <br></br>
    However, we do display the first 3 digits of your postal code to all users.
    This covers a range of 16 square kilometers, and is meant to help buyers
    understand your general area while still maintaining your anonymity.
  </>
)

const Overview = ({ user, width, ismobilescreen }) => {
  const navigate = useNavigate()
  const { cache } = useApolloClient()
  const practiceFormRef = useRef()

  const [editPracticeOverviewMutation] = useMutationWithErrorLogging(
    EDIT_PRACTICE_OVERVIEW,
  )

  const { practiceId } = useParams()
  const { practice } = usePracticeById(practiceId)
  const [searchParams] = useSearchParams()
  const currentFlow = searchParams.get('flow')

  const [name, setName] = useState(practice?.name || null)
  // Need to always assign dropdown values an empty string if not populated,
  // otherwise the dropdown box doesn't make space for the label
  const [type, setType] = useState(practice?.type || '')
  const [address, setAddress] = useState(practice?.address || '')
  const [postalCode, setPostalCode] = useState(practice?.postalCode || '')
  const [postalCodeErrorMessage, setPostalCodeErrorMessage] = useState('')
  const [country, setCountry] = useState(practice?.country)
  const [countryErrorMessage, setCountryErrorMessage] = useState('')
  const [dpms, setDpms] = useState(practice?.dpms || '')
  const [averageAnnualBillings, setAverageAnnualBillings] = useState(
    practice?.averageAnnualBillings || null,
  )
  const [percentageOfRevenueFromHygiene, setPercentageOfRevenueFromHygiene] =
    useState(
      practice?.hygieneRevenuePercentage != null
        ? Math.round(practice.hygieneRevenuePercentage * 100)
        : null,
    )
  const [isInvisalignCertified, setIsInvisalignCertified] = useState(
    practice?.isInvisalignCertified || false,
  )
  const [hasIntraoralScanner, setHasIntraoralScanner] = useState(
    practice?.hasIntraoralScanner || false,
  )
  const [practiceHasDebt, setPracticeHasDebt] = useState(
    practice?.practiceHasDebt || false,
  )
  const [patientsSeenWeekly, setpatientsSeenWeekly] = useState(
    practice?.patientsSeenWeekly || null,
  )
  const [onlyProvidesAnesthesia, setOnlyProvidesAnesthesia] = useState(
    practice?.onlyProvidesAnesthesia || null,
  )
  const [numberDentistsRefer, setNumberDentistsRefer] = useState(
    practice?.numberDentistsRefer || null,
  )
  const [openDaysPerWeek, setOpenDaysPerWeek] = useState(
    practice?.openDaysPerWeek || null,
  )
  const [numberOfPatients, setNumberOfPatients] = useState(
    practice?.numberOfPatients || null,
  )
  const [numberOfDoctors, setNumberOfDoctors] = useState(
    practice?.numberOfDoctors || null,
  )
  const [submittedDataWithErrors, setSubmittedDataWithErrors] = useState(false)
  const [changesMade, setChangesMade] = useState(false)

  const updateChangesMade = () => {
    if (!changesMade) {
      setChangesMade(true)
    }
  }

  const handleNameChange = (e) => {
    const { value } = e.target
    setName(value)
    updateChangesMade()
  }

  const handleTypeChange = (e) => {
    const { value } = e.target
    setType(value)
    updateChangesMade()
  }

  const handlePostalCodeChange = (value) => {
    setPostalCode(value)
    setPostalCodeErrorMessage()
    updateChangesMade()
  }

  const onChangeAddress = (search) => {
    setAddress(search)
    setCountryErrorMessage()
    updateChangesMade()

    // Reset country if they've removed the search value
    if (valueIsEmpty(search)) {
      setCountry()
    }
  }

  const onEnterAddress = (selectedLocationValue) => {
    setAddress(selectedLocationValue)
    setCountryErrorMessage()
  }

  const handleCountryChange = (value) => {
    setCountry(value)
    setPostalCodeErrorMessage()
  }

  const handleDpmsChange = (e) => {
    const { value } = e.target
    setDpms(value)
    updateChangesMade()
  }

  // Don't need validation here because that's done by the NumberField input component
  const handleAverageAnnualBillingsChange = (e) => {
    const { value } = e.target
    setAverageAnnualBillings(value)
    updateChangesMade()
  }

  useEffect(() => {
    if (practice) {
      setName(practice.name)
      setType(practice.type)
      setDpms(practice.dpms)
      setAddress(practice.address || '')
      setCountry(practice.country)
      setPostalCode(practice.postalCode)
      setAverageAnnualBillings(practice.averageAnnualBillings)
      setPercentageOfRevenueFromHygiene(
        practice?.hygieneRevenuePercentage != null
          ? practice.hygieneRevenuePercentage * 100
          : null,
      )
      setIsInvisalignCertified(practice.isInvisalignCertified || false)
      setHasIntraoralScanner(practice.hasIntraoralScanner || false)
      setPracticeHasDebt(practice.practiceHasDebt || false)
      setpatientsSeenWeekly(practice?.patientsSeenWeekly || null)
      setOnlyProvidesAnesthesia(practice?.onlyProvidesAnesthesia || null)
      setNumberDentistsRefer(practice?.numberDentistsRefer || null)
      setOpenDaysPerWeek(practice?.openDaysPerWeek || null)
      setNumberOfPatients(practice?.numberOfPatients || null)
      setNumberOfDoctors(practice?.numberOfDoctors || null)
    }
  }, [practice])

  const callEditPracticeOverviewMutation = useCallback(
    async (flowToReturnTo = null) => {
      const geocode = await geocodeByAddress(address)
      if (isEmpty(geocode)) {
        if (!!address) {
          setCountryErrorMessage(
            'Only Canadian and American addresses are allowed.',
          )
        }
        return
      }

      const locationResult = geocode[0]

      const city = getCityFromGeocodedAddress(locationResult)
      const province = getProvinceOrStateFromGeocodedAddress(locationResult)
      const formattedProvince = formatLocationString(province)

      const formattedCountry = formatLocationString(country)
      const { lat, lng } = await getLatLng(locationResult)

      const formattedAnnualBillings = getIntFromCurrency(averageAnnualBillings)
      const formattedHygienePercentage = getFloatFromPercentage(
        percentageOfRevenueFromHygiene,
      )

      const formattedNumberDentistsRefer = numberDentistsRefer
        ? parseInt(numberDentistsRefer)
        : null
      const formattedPatientsSeenWeekly = patientsSeenWeekly
        ? parseInt(patientsSeenWeekly)
        : null
      const formattedNumberOfPatients = numberOfPatients
        ? parseInt(numberOfPatients)
        : null
      const formattedNumberOfDoctors = numberOfDoctors
        ? parseInt(numberOfDoctors)
        : null

      if (changesMade) {
        const navRef = practiceFormRef?.current?.getNavRef()
        navRef?.current?.setLoading(true)
        await editPracticeOverviewMutation({
          variables: {
            id: practiceId,
            name,
            type,
            address,
            city,
            isInvisalignCertified: practiceOverviewFieldsBasedOnType[
              type
            ].includes('isInvisalignCertified')
              ? isInvisalignCertified
              : null,
            hasIntraoralScanner: practiceOverviewFieldsBasedOnType[
              type
            ].includes('hasIntraoralScanner')
              ? hasIntraoralScanner
              : null,
            practiceHasDebt,
            province: formattedProvince,
            country: formattedCountry,
            postalCode,
            latitude: lat,
            longitude: lng,
            dpms,
            averageAnnualBillings: formattedAnnualBillings,
            hygieneRevenuePercentage: formattedHygienePercentage,
            patientsSeenWeekly: formattedPatientsSeenWeekly,
            onlyProvidesAnesthesia: onlyProvidesAnesthesia,
            numberDentistsRefer: formattedNumberDentistsRefer,
            openDaysPerWeek: openDaysPerWeek,
            numberOfPatients: formattedNumberOfPatients,
            numberOfDoctors: formattedNumberOfDoctors,
          },
          onCompleted: async (data) => {
            await updatePracticeByIdInCache(
              practiceId,
              data.editPracticeOverview,
              cache,
            )
            navRef?.current?.setLoading(false)

            if (flowToReturnTo === publishingFlowKey) {
              navigateToPublishListingPage(practiceId, navigate)
            } else if (flowToReturnTo === editingFlowKey) {
              navigateToViewPracticePage(practiceId, navigate)
            } else {
              // If we just want to navigate to the next step
              let nextPath = getCreatePracticePathToNavigateTo(
                currentStep,
                practiceId,
                'next',
                currentFlow,
                false,
                null,
                searchParams.get('valuation') === 'true',
              )

              if (currentFlow) {
                nextPath = makeCreatePracticePathInCurrentFlow(
                  nextPath,
                  currentFlow,
                )
              }

              navigate(nextPath)
            }
          },
        })
      } else {
        if (flowToReturnTo === publishingFlowKey) {
          navigateToPublishListingPage(practiceId, navigate)
        } else if (flowToReturnTo === editingFlowKey) {
          navigateToViewPracticePage(practiceId, navigate)
        } else {
          // If we just want to navigate to the next step
          let nextPath = getCreatePracticePathToNavigateTo(
            currentStep,
            practiceId,
            'next',
            currentFlow,
            false,
            null,
            searchParams.get('valuation') === 'true',
          )

          if (currentFlow) {
            nextPath = makeCreatePracticePathInCurrentFlow(
              nextPath,
              currentFlow,
            )
          }

          navigate(nextPath)
        }
      }
    },
    [
      practiceFormRef,
      address,
      averageAnnualBillings,
      cache,
      changesMade,
      country,
      currentFlow,
      dpms,
      editPracticeOverviewMutation,
      name,
      navigate,
      percentageOfRevenueFromHygiene,
      postalCode,
      practiceId,
      searchParams,
      type,
      isInvisalignCertified,
      hasIntraoralScanner,
      practiceHasDebt,
      numberDentistsRefer,
      patientsSeenWeekly,
      onlyProvidesAnesthesia,
      openDaysPerWeek,
      numberOfPatients,
      numberOfDoctors,
    ],
  )

  // Logic:
  // We only allow Canadian or American addresses
  // We always require a postal/zip code, and we validate the value
  const onClickNext = async (flowToReturnTo = null) => {
    // If they've clicked without next being enabled, we know there are errors
    let containsError = !nextEnabled

    let errorElementIdToScrollTo

    let postalCodeContainsError = false

    if (
      country &&
      country !== canadianCountryCode &&
      country !== americanCountryCode
    ) {
      setCountryErrorMessage(
        'Only Canadian and American addresses are allowed.',
      )
      return
    } else if (country === canadianCountryCode) {
      const isValidPostalCode = checkIfValidCanadianPostalCode(postalCode)
      postalCodeContainsError = !isValidPostalCode && !!postalCode
      if (postalCodeContainsError) {
        setPostalCodeErrorMessage('Please enter a valid Canadian postal code.')
      }
    } else if (country === americanCountryCode) {
      const isValidZipCode = checkIfValidAmericanZipCode(postalCode)
      postalCodeContainsError = !isValidZipCode && !!postalCode
      if (postalCodeContainsError) {
        setPostalCodeErrorMessage('Please enter a valid American zip code.')
      }
    }

    if (containsError) {
      errorElementIdToScrollTo =
        getIdOfFirstInputFieldWithError(inputElementsArray)
    } else if (postalCodeContainsError) {
      // If the postal code is entered but not valid, we know there's an error, and we should display an error message
      containsError = true
      errorElementIdToScrollTo = inputElements['postalCode']['id']
    }

    // If there is an error ID to scroll to, set state to reflect that, and scroll to the first erroneous field
    if (errorElementIdToScrollTo) {
      scrollElementIntoView(document, errorElementIdToScrollTo)
      setSubmittedDataWithErrors(true)
    } else {
      setSubmittedDataWithErrors(false)
      await callEditPracticeOverviewMutation(flowToReturnTo)
    }
  }

  const inputElements = {
    type: {
      id: 'type',
      fieldType: 'dropdown',
      state: type,
    },
    name: {
      id: 'name',
      fieldType: 'text',
      state: name,
    },
    address: {
      id: 'address',
      fieldType: 'text',
      state: address,
    },
    postalCode: {
      id: 'postalCode',
      fieldType: 'text',
      state: postalCode,
    },
    dpms: {
      id: 'dpms',
      fieldType: 'dropdown',
      state: dpms,
    },
    billings: {
      id: 'billings',
      fieldType: 'currency',
      state: averageAnnualBillings,
    },
    hygienePercentage: {
      id: 'hygienePercentage',
      fieldType: 'percentage',
      state: percentageOfRevenueFromHygiene,
    },
    isInvisalignCertified: {
      id: 'isInvisalignCertified',
      fieldType: 'dropdown',
      state: isInvisalignCertified,
    },
    hasIntraoralScanner: {
      id: 'hasIntraoralScanner',
      fieldType: 'dropdown',
      state: hasIntraoralScanner,
    },
    practiceHasDebt: {
      id: 'practiceHasDebt',
      fieldType: 'dropdown',
      state: practiceHasDebt,
    },
    patientsSeenWeekly: {
      id: 'patientsSeenWeekly',
      fieldType: 'integer',
      state: patientsSeenWeekly,
    },
    onlyProvidesAnesthesia: {
      id: 'onlyProvidesAnesthesia',
      fieldType: 'dropdown',
      state: onlyProvidesAnesthesia,
    },
    numberDentistsRefer: {
      id: 'numberDentistsRefer',
      fieldType: 'integer',
      state: numberDentistsRefer,
    },
    openDaysPerWeek: {
      id: 'openDaysPerWeek',
      fieldType: 'dropdown',
      state: openDaysPerWeek,
    },
    numberOfDoctors: {
      id: 'numberOfDoctors',
      fieldType: 'integer',
      state: numberOfDoctors,
    },
    numberOfPatients: {
      id: 'numberOfPatients',
      fieldType: 'integer',
      state: numberOfPatients,
    },
  }

  const postalOrZipCodeLabel = `${
    !country
      ? 'Postal/Zip code'
      : country === americanCountryCode
        ? 'Zip code'
        : 'Postal code'
  } *`

  const formInputs = [
    <CreatePracticeInput
      value={type}
      label='Practice type * '
      width={width}
      onChange={handleTypeChange}
      options={practiceTypes}
      fieldType={inputElements['type']['fieldType']}
      elementId={inputElements['type']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    <CreatePracticeInput
      value={name}
      label='Practice name * '
      roleAccessSetting={'premium'}
      width={width}
      onChange={handleNameChange}
      fieldType={inputElements['name']['fieldType']}
      elementId={inputElements['name']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    <LocationSearchBox
      ismobilescreen={ismobilescreen}
      value={address}
      onChange={onChangeAddress}
      onEnter={onEnterAddress}
      onChangePostalCode={handlePostalCodeChange}
      onChangeCountry={handleCountryChange}
      containsError={
        !!countryErrorMessage || (submittedDataWithErrors && !address)
      }
      errorMessage={countryErrorMessage}
      customLabel='Address * '
      CustomEndAdornment={
        searchParams.get('valuation') !== 'true' ? (
          <PrivacyShield fieldPrivacyMessage={addressFieldPrivacyMessage} />
        ) : (
          <></>
        )
      }
      CustomInputComponent={CreatePracticeTextInputComponent}
      customPopperMenuStyle={LocationSearchPopperMenuStyle}
      elementId={inputElements['address']['id']}
    />,
    <CreatePracticeInput
      value={postalCode}
      label={postalOrZipCodeLabel}
      width={width}
      roleAccessSetting={'premium'}
      fieldPrivacyMessage={postalCodeFieldPrivacyMessage}
      onChange={(e) => {
        setPostalCode(e.target.value)
        setPostalCodeErrorMessage()
        updateChangesMade()
      }}
      errorMessage={postalCodeErrorMessage}
      fieldType={inputElements['postalCode']['fieldType']}
      elementId={inputElements['postalCode']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    <CreatePracticeInput
      value={patientsSeenWeekly}
      label='Number of patients seen weekly * '
      width={width}
      onChange={(e) => {
        setpatientsSeenWeekly(e.target.value)
        updateChangesMade()
      }}
      fieldType={inputElements['patientsSeenWeekly']['fieldType']}
      elementId={inputElements['patientsSeenWeekly']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    <CreatePracticeInput
      value={onlyProvidesAnesthesia}
      label='Do you only provide anesthesia services? * '
      width={width}
      onChange={(e) => {
        setOnlyProvidesAnesthesia(e.target.value)
        updateChangesMade()
      }}
      options={booleanFields}
      fieldType={inputElements['onlyProvidesAnesthesia']['fieldType']}
      elementId={inputElements['onlyProvidesAnesthesia']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    <CreatePracticeInput
      value={numberDentistsRefer}
      label='Estimated number of dentists that refer to you * '
      width={width}
      onChange={(e) => {
        setNumberDentistsRefer(e.target.value)
        updateChangesMade()
      }}
      fieldType={inputElements['numberDentistsRefer']['fieldType']}
      elementId={inputElements['numberDentistsRefer']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    <CreatePracticeInput
      value={numberOfPatients}
      label='Number of patients * '
      width={width}
      onChange={(e) => {
        setNumberOfPatients(e.target.value)
        updateChangesMade()
      }}
      fieldType={inputElements['numberOfPatients']['fieldType']}
      elementId={inputElements['numberOfPatients']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    <CreatePracticeInput
      value={openDaysPerWeek}
      label='How many days per week is your practice open? * '
      width={width}
      onChange={(e) => {
        setOpenDaysPerWeek(e.target.value)
        updateChangesMade()
      }}
      options={openDaysPerWeekFields}
      fieldType={inputElements['openDaysPerWeek']['fieldType']}
      elementId={inputElements['openDaysPerWeek']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    <CreatePracticeInput
      value={numberOfDoctors}
      label={`Number of ${doctorNameBasedOnType[type]} * `}
      width={width}
      onChange={(e) => {
        setNumberOfDoctors(e.target.value)
        updateChangesMade()
      }}
      fieldType={inputElements['numberOfDoctors']['fieldType']}
      elementId={inputElements['numberOfDoctors']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    <CreatePracticeInput
      value={dpms}
      label='Practice management software * '
      width={width}
      options={dpmss}
      onChange={handleDpmsChange}
      fieldType={inputElements['dpms']['fieldType']}
      elementId={inputElements['dpms']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    <CreatePracticeInput
      value={averageAnnualBillings}
      label='Average annual billings * '
      width={width}
      onChange={handleAverageAnnualBillingsChange}
      fieldType={inputElements['billings']['fieldType']}
      elementId={inputElements['billings']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    <CreatePracticeInput
      value={percentageOfRevenueFromHygiene}
      label='Percentage of revenue from hygiene * '
      width={width}
      onChange={(e) => {
        handlePercentageFieldChange(e, setPercentageOfRevenueFromHygiene)
        updateChangesMade()
      }}
      fieldType={inputElements['hygienePercentage']['fieldType']}
      elementId={inputElements['hygienePercentage']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    <CreatePracticeInput
      value={isInvisalignCertified}
      label='Invisalign certified * '
      width={width}
      onChange={(e) => {
        setIsInvisalignCertified(e.target.value)
        updateChangesMade()
      }}
      options={booleanFields}
      fieldType={inputElements['isInvisalignCertified']['fieldType']}
      elementId={inputElements['isInvisalignCertified']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    <CreatePracticeInput
      value={hasIntraoralScanner}
      label='Intraoral scanner * '
      width={width}
      onChange={(e) => {
        setHasIntraoralScanner(e.target.value)
        updateChangesMade()
      }}
      options={booleanFields}
      fieldType={inputElements['hasIntraoralScanner']['fieldType']}
      elementId={inputElements['hasIntraoralScanner']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    <CreatePracticeInput
      value={practiceHasDebt}
      label='Debt on the practice * '
      width={width}
      onChange={(e) => {
        setPracticeHasDebt(e.target.value)
        updateChangesMade()
      }}
      options={booleanFields}
      fieldType={inputElements['practiceHasDebt']['fieldType']}
      elementId={inputElements['practiceHasDebt']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    // Only include fields that are appropriate for given practice type
  ].filter(
    (field) =>
      field.props.elementId === 'type' ||
      (type &&
        practiceOverviewFieldsBasedOnType[type].includes(
          field.props.elementId,
        )),
  )

  const inputElementsArray = Object.values(inputElements)
  const nextEnabled = inputElementsArray.every(
    (field) =>
      (type &&
        !practiceOverviewFieldsBasedOnType[type].includes(field['id'])) ||
      (field['state'] != null && field['state'] !== ''),
  )

  return (
    practice && (
      <CreatePracticeInputForm
        ref={practiceFormRef}
        user={user}
        width={width}
        ismobilescreen={ismobilescreen}
        currentStep={currentStep}
        practice={practice}
        formTitle={'Practice Overview'}
        formInputs={formInputs}
        nextEnabled={nextEnabled}
        onClickNext={() => onClickNext()}
        currentFlow={currentFlow}
        onSaveInFlow={() => onClickNext(currentFlow)}
        inValuationMode={searchParams.get('valuation') === 'true'}
      />
    )
  )
}

export default Overview
