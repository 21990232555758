import React from 'react'
import { arrowSeparatorString } from '../../../utils/numbers'
import { ArrowSeparatorContainer } from './styled'

const ArrowSeparator = () => {
  return (
    <ArrowSeparatorContainer>{arrowSeparatorString}</ArrowSeparatorContainer>
  )
}

export default ArrowSeparator
