import styled, { css } from 'styled-components/macro'
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../styles/shared-styled-components'

const ButtonStyle = css`
  width: 200px;
`

export const PrimaryViewOffersButton = styled(PrimaryButton)`
  ${ButtonStyle}
`

export const BrightViewOffersButton = styled(PrimaryViewOffersButton)`
  background-color: ${({ theme }) => theme.color.brightGreen};
  &:hover {
    background-color: ${({ theme }) => theme.color.brightGreenHover};
  }
`

export const SecondaryViewOffersButton = styled(SecondaryButton)`
  ${ButtonStyle}
`
