import Typesense from 'typesense'

let typesenseClient = new Typesense.Client({
  nodes: [
    {
      host: process.env.REACT_APP_TYPESENSE_HOST,
      port: process.env.REACT_APP_TYPESENSE_PORT,
      protocol: 'https',
    },
  ],
  apiKey: process.env.REACT_APP_TYPESENSE_SEARCH_ONLY_API_KEY,
  connectionTimeoutSeconds: 20,
})

export default typesenseClient
