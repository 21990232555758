import React from 'react'
import {
  AdornmentContainer,
  StyledTooltip,
} from '../../../styles/shared-styled-components'
import { InfoIconStyled } from './styled'

const InfoIcon = ({ message }) => {
  return (
    <StyledTooltip title={message}>
      <AdornmentContainer>
        <InfoIconStyled />
      </AdornmentContainer>
    </StyledTooltip>
  )
}

export default InfoIcon
