import React from 'react'
import Header from '../../../components/NavBar/Header'
import Panel from '../Panel'

const PracticeAdminPanelPage = ({ user, width, ismobilescreen }) => {
  // For now, this page is just a redirect to the Retool admin panel
  window.location.replace(
    'https://verones.retool.com/app/dentacloud-admin-panel',
  )
  return null

  // later, we may implement it ourselves
  return (
    <>
      <Header user={user} width={width} ismobilescreen={ismobilescreen} />
      <Panel style={{ marginTop: '100px' }} />
    </>
  )
}

export default PracticeAdminPanelPage
