import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Paths from '../../../Paths'
import {
  createPracticeFlowSteps,
  dueDiligenceFlowSteps,
} from '../../../utils/routing'
import GeneralCommissionModal from '../CreatePracticeModals/GeneralCommissionModal'
import {
  BackToPublishingButton,
  CheckmarkIcon,
  CommissionButton,
  CurrentStepCircle,
  FlagCircle,
  FlagIcon,
  SaveAndExitButton,
  StepCircle,
  StepContainer,
  StepLabel,
  StepLabelContainer,
  StepProgressContainer,
  StepTransitionLine,
  StepTransitionLineContainer,
  StepsContainer,
  TopBarContainer,
  TopButtonContainer,
} from './styled'

const CreatePracticeHeader = ({
  ismobilescreen,
  currentStep,
  practice,
  nextEnabled,
  currentFlow = null,
  onSaveInFlow,
  inValuationMode = false,
  onValuationPage = false,
  isDueDiligence = false,
}) => {
  const navigate = useNavigate()

  const handleOnSaveClicked = useCallback(() => {
    if (onSaveInFlow) onSaveInFlow()
    else navigate(Paths.myPractices)
  }, [onSaveInFlow, navigate])

  const [commisionModalOpen, setCommissionModalOpen] = useState(false)

  const steps = isDueDiligence ? dueDiligenceFlowSteps : createPracticeFlowSteps

  const totalNumSteps = steps.length

  const isDD = (steps) => {
    return steps[0].label == 'Due Diligence'
  }

  return (
    <>
      <TopBarContainer>
        {!onValuationPage && (
          <TopButtonContainer>
            {!currentFlow ? (
              <>
                {!inValuationMode ? (
                  <CommissionButton
                    ismobilescreen={ismobilescreen}
                    onClick={() => setCommissionModalOpen(true)}
                  >
                    See transaction fee
                  </CommissionButton>
                ) : (
                  // Placeholder to not disrupt layout
                  <div />
                )}
                {
                  <SaveAndExitButton
                    ismobilescreen={ismobilescreen}
                    onClick={() =>
                      inValuationMode
                        ? navigate(Paths.myValuations)
                        : navigate(Paths.myPractices)
                    }
                  >
                    Save and Exit
                  </SaveAndExitButton>
                }
              </>
            ) : (
              <>
                {!inValuationMode ? (
                  <CommissionButton
                    ismobilescreen={ismobilescreen}
                    onClick={() => setCommissionModalOpen(true)}
                  >
                    See transaction fee
                  </CommissionButton>
                ) : (
                  // Placeholder to not disrupt layout
                  <div />
                )}
                <BackToPublishingButton
                  onClick={handleOnSaveClicked}
                  disabled={!nextEnabled}
                  ismobilescreen={ismobilescreen}
                >
                  Save and return
                </BackToPublishingButton>
              </>
            )}
          </TopButtonContainer>
        )}
        <StepsContainer>
          {steps.map((step, index) => {
            const { label, type, skippable } = step
            const completed = currentStep != null && currentStep > index

            let skipped = false
            if (skippable) {
              const { validator } = step
              skipped = !validator(practice)
            }

            const current = currentStep === index

            return !isDD(steps) ? (
              label && (
                <StepContainer
                  key={label}
                  stepIndex={index}
                  totalNumSteps={totalNumSteps}
                >
                  <StepProgressContainer>
                    {index !== 0 && (
                      <StepTransitionLineContainer
                        stepIndex={index}
                        totalNumSteps={totalNumSteps}
                      >
                        <StepTransitionLine
                          // Making sure this line (which is on the left side of the current step) gets highlighted
                          // for the current step, if the previous step has been completed
                          completed={completed || current}
                        />
                      </StepTransitionLineContainer>
                    )}
                    {type === 'reward' ? (
                      <FlagCircle completed={completed || current}>
                        <FlagIcon completed={completed || current} />
                      </FlagCircle>
                    ) : (
                      <StepCircle
                        completed={completed}
                        current={current}
                        skipped={skipped}
                      >
                        {completed && !skipped && <CheckmarkIcon />}
                        {current && <CurrentStepCircle />}
                      </StepCircle>
                    )}
                    {index !== totalNumSteps - 1 && (
                      <StepTransitionLineContainer
                        stepIndex={index}
                        totalNumSteps={totalNumSteps}
                      >
                        <StepTransitionLine completed={completed} />
                      </StepTransitionLineContainer>
                    )}
                  </StepProgressContainer>
                  {!ismobilescreen ? (
                    <StepLabelContainer
                      stepIndex={index}
                      totalNumSteps={totalNumSteps}
                    >
                      <StepLabel
                        stepIndex={index}
                        totalNumSteps={totalNumSteps}
                        current={current}
                        type={type}
                        ismobilescreen={ismobilescreen}
                      >
                        {label}
                      </StepLabel>
                    </StepLabelContainer>
                  ) : ismobilescreen && current ? (
                    <StepLabelContainer
                      stepIndex={index}
                      totalNumSteps={totalNumSteps}
                      ismobilescreen={ismobilescreen}
                      isEdgeNode={index === 0 || index === steps.length - 1}
                    >
                      <StepLabel
                        stepIndex={index}
                        totalNumSteps={totalNumSteps}
                        current={current}
                        type={type}
                        ismobilescreen={ismobilescreen}
                      >
                        {label} ({index + 1}/{totalNumSteps})
                      </StepLabel>
                    </StepLabelContainer>
                  ) : (
                    <></>
                  )}
                </StepContainer>
              )
            ) : (
              <></>
            )
          })}
        </StepsContainer>
      </TopBarContainer>
      <GeneralCommissionModal
        isOpen={commisionModalOpen}
        onClose={() => setCommissionModalOpen(false)}
      />
    </>
  )
}

export default CreatePracticeHeader
