import {
  buyGoalKey,
  provideProductsOrServicesGoalKey,
  sellGoalKey,
  valueGoalKey,
} from './constants'

// We store the data from the onboarding flow in localStorage, so that
// we can know how to route the user without needing them to log back in, because the
// Auth0 user object doesn't get rehydrated until a new session is initiated
// --> Check both localstorage and the user object for the onboarding fields
export const getUserPhone = (user) => {
  const phoneInLocalStorage = localStorage.getItem('phone')
  return phoneInLocalStorage || user?.phone
}

export const getUserPhoneVerified = (user) => {
  const phoneVerifiedInLocalStorage = localStorage.getItem('phone_verified')
  return phoneVerifiedInLocalStorage || user?.phone_verified
}

export const getUserHasOnboarded = (user) => {
  const hasOnboardedInLocalStorage = localStorage.getItem('has_onboarded')
  return hasOnboardedInLocalStorage || user?.has_onboarded
}

export const getProductOrServiceGoal = (goals) => {
  if (!goals) {
    return null
  }

  const productOrServiceGoal = goals.find(
    (goal) => goal.mainGoal === provideProductsOrServicesGoalKey,
  )
  return productOrServiceGoal
}

export const checkIfBuyOrSellGoalPresent = (goals) => {
  if (!goals) {
    return false
  }

  const buyOrSellGoal = goals.find(
    (goal) => goal.mainGoal === buyGoalKey || goal.mainGoal === sellGoalKey,
  )
  return !!buyOrSellGoal
}

export const checkIfSellGoalIsPresent = (goals) => {
  if (!goals) {
    return false
  }

  const sellGoal = goals.find((goal) => goal.mainGoal === sellGoalKey)
  return !!sellGoal
}

export const checkIfBuyGoalPresent = (goals) => {
  if (!goals) {
    return false
  }

  const buyGoal = goals.find((goal) => goal.mainGoal === buyGoalKey)
  return !!buyGoal
}

export const checkIfValueGoalIsPresent = (goals) => {
  if (!goals) {
    return false
  }

  const valueGoal = goals.find((goal) => goal.mainGoal === valueGoalKey)
  return !!valueGoal
}

export const checkIfValueOrSellGoalPresent = (goals) => {
  if (!goals) {
    return false
  }

  const valueOrSellGoal = goals.find(
    (goal) => goal.mainGoal === valueGoalKey || goal.mainGoal === sellGoalKey,
  )
  return !!valueOrSellGoal
}

export const getMainGoalsFromUser = (user) => {
  const mainGoals = user?.goals?.map((g) => g.mainGoal) || []
  return mainGoals
}

export default {
  getUserPhone,
  getUserPhoneVerified,
  getProductOrServiceGoal,
  getMainGoalsFromUser,
  checkIfBuyOrSellGoalPresent,
}
