import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import MuiFlagIcon from '@mui/icons-material/SportsScore'
import styled, { css } from 'styled-components/macro'
import {
  AutoHorizontalMargins,
  BaseDiv,
  ColumnFlexStyle,
  DefaultIconStyle,
  IntrasectionalGap,
  IntraSubsectionalGap,
  MediumIconStyle,
  ParagraphText,
  RowFlexStyle,
  SecondaryButton,
} from '../../../styles/shared-styled-components'

export const TopBarContainer = styled(BaseDiv)`
  ${ColumnFlexStyle}
  ${IntrasectionalGap}
  max-width: 2000px;
  ${AutoHorizontalMargins}
`

export const TopButtonContainer = styled.div`
  ${RowFlexStyle}
  justify-content: space-between;
  align-items: center;
`

export const SaveAndExitButton = styled(SecondaryButton)`
  font-size: ${(props) =>
    props.ismobilescreen
      ? props.theme.typography.microFontSize
      : props.theme.typography.sentenceFontSize};
  width: ${(props) => (props.ismobilescreen ? '120px' : '150px')};
`

export const CommissionButton = styled(SaveAndExitButton)`
  color: ${({ theme }) => theme.color.purple};
  width: ${(props) => (props.ismobilescreen ? '160px' : '200px')};
`

export const BackToPublishingButton = styled(SecondaryButton)`
  width: ${(props) => (props.ismobilescreen ? '160px' : '200px')};
  font-size: ${(props) =>
    props.ismobilescreen
      ? props.theme.typography.microFontSize
      : props.theme.typography.sentenceFontSize};
`

export const StepsContainer = styled.div`
  ${RowFlexStyle}
  justify-content: space-between;
`

export const StepContainer = styled.div`
  ${ColumnFlexStyle}
  ${IntraSubsectionalGap}
  flex: ${(props) =>
    props.stepIndex === 0 || props.stepIndex === props.totalNumSteps - 1
      ? 1
      : 2};
`

export const StepProgressContainer = styled.div`
  ${RowFlexStyle}
`

export const StepLabelContainer = styled.div`
  ${RowFlexStyle}
  justify-content: ${(props) =>
    props.stepIndex === 0
      ? 'flex-start'
      : props.stepIndex === props.totalNumSteps - 1
        ? 'flex-end'
        : 'center'};
  // Keeping the text centered, except for the edge steps (0/last), as that makes the step look disproportionately long
  width: ${(props) =>
    props.ismobilescreen && !props.isEdgeNode && 'max-content'};
`

export const StepLabel = styled(ParagraphText)`
  text-align: ${(props) =>
    props.stepIndex !== 0 &&
    props.stepIndex !== props.totalNumSteps - 1 &&
    'center'};
  color: ${(props) =>
    props.type === 'reward'
      ? props.theme.color.brightGreen
      : props.current
        ? props.theme.color.black
        : props.theme.color.primary};
  font-weight: ${(props) => props.current && 600};
  font-size: ${(props) =>
    props.ismobilescreen
      ? props.theme.typography.microFontSize
      : props.theme.typography.sentenceFontSize};
`

const baseCircleSize = '20px'

const smallCircleSize = '10px'

const BaseCircleStyle = css`
  border-radius: ${baseCircleSize};
  height: ${baseCircleSize};
  width: ${baseCircleSize};
`

const SmallCircleStyle = css`
  border-radius: ${smallCircleSize};
  height: ${smallCircleSize};
  width: ${smallCircleSize};
`

export const StepCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${BaseCircleStyle}
  font-family: ${({ theme }) => theme.typography.typeFont};
  background-color: ${(props) =>
    props.skipped
      ? props.theme.color.superLightGrey
      : props.completed || props.selected
        ? props.theme.color.primary
        : props.theme.color.white};
  color: ${(props) =>
    props.selected || props.completed
      ? props.theme.color.white
      : props.theme.color.primary};
  border: ${(props) =>
    props.completed || props.current
      ? `1px solid ${props.theme.color.primary}`
      : `1px solid ${props.theme.color.lighterGrey}`};
`

export const StepTransitionLineContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

export const StepTransitionLine = styled.hr`
  margin: 0px;
  border: 3px solid
    ${(props) =>
      props.completed
        ? props.theme.color.primary
        : props.theme.color.lighterGrey};
  width: 100%;
  ${AutoHorizontalMargins}
`

export const CheckmarkIcon = styled(DoneOutlinedIcon)`
  ${DefaultIconStyle}
`

export const CurrentStepCircle = styled.div`
  ${SmallCircleStyle}
  background-color: ${({ theme }) => theme.color.primary};
`

export const FlagCircle = styled.div`
  ${BaseCircleStyle}
  ${ColumnFlexStyle}
  align-items: center;
  justify-content: center;

  background-color: ${(props) =>
    props.completed ? props.theme.color.brightGreen : props.theme.color.white};
  border: ${(props) =>
    `1px solid ${
      props.completed
        ? props.theme.color.brightGreen
        : props.theme.color.lighterGrey
    }`};
`

export const FlagIcon = styled(MuiFlagIcon)`
  ${MediumIconStyle}
  color: ${(props) =>
    props.completed ? props.theme.color.white : props.theme.color.brightGreen};
  top: -100px;
`
