import React, { useRef, useState } from 'react'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { valueIsEmpty } from '../../../utils/data'
import { formatLocationString } from '../../../utils/location'
import PopperMenu from '../../PopperMenu'
import {
  EndInputAdornment,
  IconButton,
  LocationSearchInput,
  LocationSuggestion,
  SearchIcon,
} from './styled'

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

let locationSuggestions = []

const LocationSearchBox = ({
  ismobilescreen,
  value,
  onChange,
  onEnter,
  onChangePostalCode,
  onChangeCountry,
  containsError,
  errorMessage,
  enterOnBlur = true,
  transparentbackground = 'false',
  customLabel = null,
  CustomEndAdornment = null,
  CustomInputComponent = null,
  customPopperMenuStyle = null,
  customPopperMenuWidth = null,
  elementId = 'location-search',
}) => {
  const searchBoxRef = useRef(null)

  const [anchorElement, setAnchorElement] = useState(null)
  const [hoveredSuggestionIndex, setHoveredSuggestionIndex] = useState(-1)

  const handleEnter = (selectedAddress) => {
    onEnter(selectedAddress)
    searchBoxRef.current.blur()
  }

  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress)
      const result = results[0]

      if (onChangePostalCode) {
        const postalCodeComponent = result.address_components.find(
          (component) => component.types.includes('postal_code'),
        )
        const postalCode = postalCodeComponent
          ? postalCodeComponent.long_name
          : ''
        onChangePostalCode(postalCode)
      }

      if (onChangeCountry) {
        // Get the country from the address components
        const countryComponent = result.address_components.find((component) =>
          component.types.includes('country'),
        )
        const country = countryComponent ? countryComponent.long_name : ''
        onChangeCountry(formatLocationString(country))
      }

      handleEnter(selectedAddress)
    } catch (error) {
      console.error('Error: ', error)
      onChange('')
    }
  }

  const onKeyPress = (e) => {
    if (e.key === 'ArrowUp') {
      setHoveredSuggestionIndex(hoveredSuggestionIndex - 1)
    } else if (e.key === 'ArrowDown') {
      setHoveredSuggestionIndex(hoveredSuggestionIndex + 1)
    }
  }

  const onChangeSearch = (search) => {
    setHoveredSuggestionIndex(-1)
    onChange(search)
  }

  const handleOnBlur = () => {
    if (enterOnBlur && !valueIsEmpty(value)) {
      const numSuggestions = locationSuggestions?.length

      const modulo = getModuloOfHoveredIndex(numSuggestions)

      const indexToAccessAt =
        modulo >= 0 && modulo < numSuggestions ? modulo : 0

      const suggestionToSave = locationSuggestions[indexToAccessAt]

      handleSelect(suggestionToSave?.description)
    }
  }

  const getModuloOfHoveredIndex = (numSuggestions) => {
    // All suggestions
    const numberOfOptionsToHoverUpon = numSuggestions + 1

    let divisor

    if (hoveredSuggestionIndex >= 0) {
      // Positive hover index - divisor is just the hover index
      divisor = hoveredSuggestionIndex
    } else {
      // Negative hover index - need to inverse the hoveredSuggestionIndex
      // The actual value will be the total number of suggestions subtract the absolute value of hoveredSuggestionIndex
      // We can then do the modulo based off that divisor
      divisor = numberOfOptionsToHoverUpon - Math.abs(hoveredSuggestionIndex)
    }

    const modulo = divisor % numberOfOptionsToHoverUpon

    return modulo
  }

  const checkIfSuggestionIndexIsHovered = (index, numSuggestions) => {
    const modulo = getModuloOfHoveredIndex(numSuggestions)

    return modulo === index
  }

  const defaultLabel = ismobilescreen
    ? 'Search by location'
    : 'Search by address, city, or postal code'
  const label = customLabel || defaultLabel

  const valueToDisplay = value || ''

  const endAdornment = CustomEndAdornment ? (
    CustomEndAdornment
  ) : (
    <EndInputAdornment onClick={() => handleEnter(value)}>
      <IconButton>
        <SearchIcon />
      </IconButton>
    </EndInputAdornment>
  )

  const isOpen = Boolean(anchorElement)

  return (
    <PlacesAutocomplete
      value={value}
      onChange={onChangeSearch}
      onSelect={handleSelect}
      googleCallbackName='initAutocomplete'
      googleMapsApiKey={googleMapsApiKey}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        return (
          <>
            {CustomInputComponent ? (
              <CustomInputComponent
                id={elementId}
                inputRef={searchBoxRef}
                label={label}
                ismobilescreen={ismobilescreen}
                transparentbackground={transparentbackground}
                value={valueToDisplay}
                onClick={(event) => setAnchorElement(event.currentTarget)}
                error={containsError}
                helperText={containsError && errorMessage}
                InputProps={{
                  onKeyPress,
                  onBlur: () => handleOnBlur(suggestions),
                  endAdornment,
                }}
                {...getInputProps({
                  onKeyDown: onKeyPress,
                })}
              />
            ) : (
              <LocationSearchInput
                id={elementId}
                inputRef={searchBoxRef}
                label={label}
                ismobilescreen={ismobilescreen}
                transparentbackground={transparentbackground}
                value={valueToDisplay}
                onClick={(event) => setAnchorElement(event.currentTarget)}
                error={containsError}
                helperText={containsError && errorMessage}
                InputProps={{
                  onKeyPress,
                  onBlur: () => handleOnBlur(suggestions),
                  endAdornment: (
                    <EndInputAdornment onClick={() => handleEnter(value)}>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </EndInputAdornment>
                  ),
                }}
                {...getInputProps({
                  onKeyDown: onKeyPress,
                })}
              />
            )}
            {suggestions?.length > 0 && (
              <PopperMenu
                open={isOpen}
                anchorElement={anchorElement}
                onClose={() => setAnchorElement(null)}
                disablebottompadding
                customStyle={customPopperMenuStyle}
                fixedWidth={customPopperMenuWidth}
                ismobilescreen={ismobilescreen}
                useThinScrollBar={true}
              >
                {suggestions.map((suggestion, idx) => {
                  const { description, placeId } = suggestion
                  const numSuggestions = suggestions?.length

                  // Have to store in global var, as the suggestions can disappear if the user presses escape on the dropdown
                  locationSuggestions = suggestions

                  return (
                    <LocationSuggestion
                      {...getSuggestionItemProps(suggestion)}
                      key={placeId}
                      onMouseEnter={() => setHoveredSuggestionIndex(idx)}
                      hovered={checkIfSuggestionIndexIsHovered(
                        idx,
                        numSuggestions,
                      )}
                    >
                      <span>{description}</span>
                    </LocationSuggestion>
                  )
                })}
              </PopperMenu>
            )}
          </>
        )
      }}
    </PlacesAutocomplete>
  )
}

export default LocationSearchBox
