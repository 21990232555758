import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import FmdGoodIcon from '@mui/icons-material/FmdGood'
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip'
import styled, { css } from 'styled-components/macro'
import {
  AutoHorizontalMargins,
  ColumnFlexStyle,
  HeroImageStyle,
  InterSubsectionalGap,
  IntraSubsectionalGap,
  LargeParagraphText,
  RowFlexStyle,
} from '../../../styles/shared-styled-components'

export const MapImageContainer = styled.div`
  ${HeroImageStyle}
  height: 80px;
  background-image: url('https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/waitlist-thankyou-map-cropped.png');
  background-position: 87% 50%;
`

export const ThankYouTextContainer = styled.div`
  width: 100%;
  ${ColumnFlexStyle}
  ${(props) =>
    !props.ismobilescreen &&
    css`
      gap: 5px;
    `}
  justify-content: flex-start;
  align-items: center;
`

export const ThankYouTitleText = styled.div`
  font-family: ${({ theme }) => theme.typography.typeFont};
  color: ${({ theme }) => theme.color.black};
  font-size: ${(props) =>
    props.ismobilescreen
      ? props.theme.typography.pageTitleFontSize
      : props.theme.typography.hugeFontSize};
`

export const ThankYouSubtitleText = styled(ThankYouTitleText)`
  color: ${({ theme }) => theme.color.darkGrey};
  font-size: ${(props) =>
    props.ismobilescreen
      ? props.theme.typography.titleFontSize
      : props.theme.typography.pageTitleFontSize};
`

export const BenefitsText = styled(LargeParagraphText)`
  margin-top: 20px;
  font-size: ${(props) =>
    props.ismobilescreen
      ? props.theme.typography.largeInputTextFontSize
      : props.theme.typography.titleFontSize};
  color: ${({ theme }) => theme.color.black};
  display: flex;
  width: ${(props) => (props.ismobilescreen ? '90%' : '40%')};
  ${AutoHorizontalMargins}
  text-align: center;
`

export const BenefitsContainer = styled.div`
  margin-top: 20px;
  ${RowFlexStyle}
  ${AutoHorizontalMargins}
  justify-content: space-evenly;
  align-items: center;
  ${(props) =>
    props.ismobilescreen
      ? css`
          ${InterSubsectionalGap}
        `
      : css`
          ${IntraSubsectionalGap}
        `}
  flex-wrap: wrap;
  margin-bottom: 30px;
`

export const BenefitsSection = styled.div`
  ${ColumnFlexStyle}
  height: ${(props) => !props.ismobilescreen && '200px'};
  width: 300px;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 10px;
`

export const BenefitsDescriptionText = styled(LargeParagraphText)`
  margin-top: 10px;
  display: flex;
  width: 90%;
  text-align: center;
`

const IconStyle = css`
  width: 100px;
  height: 100px;
`

export const PinIcon = styled(FmdGoodIcon)`
  color: ${({ theme }) => theme.color.primary};
  ${IconStyle}
`

export const PrivacyIcon = styled(PrivacyTipIcon)`
  color: ${({ theme }) => theme.color.primary};
  ${IconStyle}
`

export const SearchIcon = styled(CloudUploadIcon)`
  color: ${({ theme }) => theme.color.primary};
  ${IconStyle}
`
