import MuiLockIcon from '@mui/icons-material/Lock'
import Box from '@mui/material/Box'
import _ from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import {
  acquisitionRequestsStatuses,
  acquisitionTypes,
  buyerTypes,
} from '../../utils/constants'
import { getFormattedMoneyString } from '../../utils/numbers'
import {
  PurchaseIntentPriceChip,
  StyledColumnContainer,
  StyledFlexContainer,
} from './styled'
import {
  ApproveButton,
  ApprovedButton,
  BuyerImage,
  BuyerNameText,
  BuyerTag,
  BuyerTagConcealed,
  DeclinedButton,
  OfferContainer,
  OfferLabel,
  OfferListItemWrapper,
  OfferSpan,
  ViewDetailsButton,
} from './styled'
import { useIsMobileScreen } from '../../utils/view'

const OfferListItem = ({
  checkEmacaAccessSigned,
  hasSignedEmaca,
  offer,
  practice,
  viewBuyer,
  handleApprove,
  handleDecline,
}) => {
  const isMobileScreen = useIsMobileScreen()
  const [isAccepting, setAccepting] = useState(false)
  const [isRejecting, setRejecting] = useState(false)
  const concealed =
    offer?.type === acquisitionTypes.ACCESS_REQUEST && !hasSignedEmaca

  const onViewBuyerClicked = useCallback(() => {
    checkEmacaAccessSigned({
      offer,
    })
  }, [offer])

  const approve = useCallback(async () => {
    try {
      if (concealed || isAccepting || isRejecting) return
      setAccepting(true)
      await handleApprove(offer)
      setAccepting(false)
    } catch (error) {
      console.log(error)
    }
  }, [offer, concealed, isAccepting, isRejecting, handleApprove])

  const decline = useCallback(async () => {
    try {
      if (concealed || isAccepting || isRejecting) return
      setRejecting(true)
      await handleDecline(offer)
      setRejecting(false)
    } catch (error) {
      console.log(error)
    }
  }, [offer, concealed, isAccepting, isRejecting, handleDecline])

  const handleViewBuyerClicked = useCallback(
    (offer) => {
      if (concealed || isAccepting || isRejecting) return
      viewBuyer(offer)
    },
    [viewBuyer, concealed, isAccepting, isRejecting],
  )

  const buyerType = useMemo(() => {
    return buyerTypes.find(
      (entry) =>
        entry.key === offer?.buyer?.type || entry.label === offer?.buyer?.type,
    )
  }, [offer])

  const isPurchaseIntent = useMemo(
    () => offer?.type === acquisitionTypes.PURCHASE_INTENT,
    [offer?.type],
  )

  return (
    <OfferListItemWrapper>
      {concealed && (
        <>
          <BuyerTagConcealed>{buyerType?.label}</BuyerTagConcealed>
          <ViewDetailsButton onClick={onViewBuyerClicked}>
            <MuiLockIcon sx={{ marginRight: '10px' }} />
            View buyer details
          </ViewDetailsButton>
        </>
      )}
      <OfferContainer concealed={concealed}>
        {!isMobileScreen && (
          <BuyerImage
            src={offer?.buyer?.picture}
            onClick={() => handleViewBuyerClicked(offer)}
          />
        )}
        <Box flex={1} display='flex' flexDirection='column'>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            flexWrap='wrap'
            gap='15px'
            onClick={() => handleViewBuyerClicked(offer)}
          >
            <Box
              flexDirection='row'
              display='flex'
              gap='15px'
              alignItems='center'
              flex={1}
            >
              {concealed ? (
                <>
                  {buyerTypes === 1 ? (
                    <BuyerNameText concealed={concealed}>
                      Firstname&nbsp;Lastname
                    </BuyerNameText>
                  ) : (
                    <BuyerNameText concealed={concealed}>
                      Company Name
                    </BuyerNameText>
                  )}
                </>
              ) : (
                <>
                  {buyerType?.label === 'Dentist' ? (
                    <BuyerNameText concealed={concealed}>
                      {offer?.buyer?.account?.firstName}&nbsp;
                      {offer?.buyer?.account?.lastName}
                    </BuyerNameText>
                  ) : (
                    <BuyerNameText concealed={concealed}>
                      {offer?.buyer?.company_name}
                    </BuyerNameText>
                  )}
                </>
              )}

              <BuyerTag concealed={concealed}>{buyerType?.label}</BuyerTag>
            </Box>
            {isPurchaseIntent && offer?.purchase_price && (
              <PurchaseIntentPriceChip>
                Purchase Price: {getFormattedMoneyString(offer?.purchase_price)}
              </PurchaseIntentPriceChip>
            )}
          </Box>
          <StyledFlexContainer
            onClick={() => handleViewBuyerClicked(offer)}
            style={{
              flex: 1,
              alignItems: 'center',
            }}
          >
            <StyledColumnContainer>
              <OfferLabel concealed={concealed}>
                Desired to work post-sale
              </OfferLabel>
              <OfferSpan concealed={concealed}>
                {offer?.years_to_work_post_sale} years
              </OfferSpan>
            </StyledColumnContainer>
            <StyledColumnContainer>
              <OfferLabel concealed={concealed}>
                Pre-approved financing
              </OfferLabel>
              <OfferSpan concealed={concealed}>
                {offer?.pre_approved_financing}
              </OfferSpan>
            </StyledColumnContainer>
            <StyledColumnContainer>
              <OfferLabel concealed={concealed}>
                Does pre-approved financing meet target price?
              </OfferLabel>
              <OfferSpan concealed={concealed}>
                {offer?.purchase_price >= practice?.targetPrice ? 'Yes' : 'No'}
              </OfferSpan>
            </StyledColumnContainer>
          </StyledFlexContainer>
          {!concealed && (
            <Box
              flexDirection='row'
              display='flex'
              gap='15px'
              style={{ justifyContent: 'flex-end', marginTop: '15px' }}
            >
              {offer.status === acquisitionRequestsStatuses.ACCEPTED ||
              offer.status === acquisitionRequestsStatuses.APPROVED ? (
                <ApprovedButton>{offer.status}</ApprovedButton>
              ) : offer.status === acquisitionRequestsStatuses.DECLINED ||
                offer.status === acquisitionRequestsStatuses.REJECTED ? (
                <DeclinedButton>{offer.status}</DeclinedButton>
              ) : (
                ((isPurchaseIntent &&
                  (!offer.status ||
                    offer.status === acquisitionRequestsStatuses.PENDING)) ||
                  (!isPurchaseIntent &&
                    (!offer.status ||
                      offer.status ===
                        acquisitionRequestsStatuses.PENDING))) && (
                  <>
                    <ApproveButton
                      disabled={isAccepting || isRejecting || !hasSignedEmaca}
                      onClick={approve}
                    >
                      {isAccepting
                        ? 'Please wait...'
                        : isPurchaseIntent
                          ? 'Accept'
                          : 'Approve'}
                    </ApproveButton>
                    <DeclinedButton
                      disabled={isAccepting || isRejecting || !hasSignedEmaca}
                      onClick={decline}
                    >
                      {isRejecting
                        ? 'Please wait...'
                        : isPurchaseIntent
                          ? 'Reject'
                          : 'Decline'}
                    </DeclinedButton>
                  </>
                )
              )}
            </Box>
          )}
        </Box>
      </OfferContainer>
    </OfferListItemWrapper>
  )
}

export default OfferListItem
