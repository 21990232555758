import React, { Fragment, useState } from 'react'
import {
  AdornmentContainer,
  Dropdown,
  ErrorHelperText,
  StyledMenuItem,
} from '../../../styles/shared-styled-components'
import { valueIsEmpty } from '../../../utils/data'
import {
  checkIfNumericInput,
  checkIfValidCanadianPostalCodeInput,
} from '../../../utils/input'
import { screenWidthIsIpadSizeOrSmaller } from '../../../utils/view'
import PrivacyShield from '../../Micro/PrivacyShield/PrivacyShield'
import NumberFieldFormat from '../../NumberFieldFormat'
import {
  CreatePracticeDropdownLabel,
  CreatePracticeForm,
  CreatePracticeTextInputComponent,
  DollarIcon,
  PercentageIcon,
} from './styled'

const numericFieldTypes = ['currency', 'integer', 'percentage']

const postalCodeFieldPrivacyMessage = (
  <>
    Your complete postal code is only visible to verified Dentacloud members
    that you've personally approved access for.
    <br></br>
    <br></br>
    However, we do display the first 3 digits of your postal code to all users.
    This covers a range of 16 square kilometers, and is meant to help buyers
    understand your general area while still maintaining your anonymity.
  </>
)

const onlyInValuationMode = true

const CreatePracticeInput = ({
  value,
  label,
  width,
  onChange,
  onBlur,
  onClick = () => {},
  options,
  submittedDataWithErrors,
  elementId = null,
  fieldType = 'text',
  roleAccessSetting = 'basic',
  fieldPrivacyMessage = null,
  split = null,
  errorMessage = null,
  placeholder = '',
}) => {
  const [isSelected, setIsSelected] = useState(false)

  const isIpadScreenSizeOrSmaller = screenWidthIsIpadSizeOrSmaller(width)

  const fieldIsActive = isSelected || (value != null && value != '')

  const startAdornment =
    fieldType === 'currency' && fieldIsActive ? (
      <AdornmentContainer>
        <DollarIcon />
      </AdornmentContainer>
    ) : fieldType === 'percentage' && fieldIsActive ? (
      <AdornmentContainer>
        <PercentageIcon />
      </AdornmentContainer>
    ) : null

  const endAdornment =
    roleAccessSetting === 'premium' && !onlyInValuationMode ? (
      <PrivacyShield fieldPrivacyMessage={fieldPrivacyMessage} />
    ) : null

  const sanitizeInput = (event, fieldType) => {
    if (numericFieldTypes.includes(fieldType)) {
      if (!checkIfNumericInput(event)) {
        event.preventDefault()
      }
    } else if (fieldType === 'postal-code') {
      if (!checkIfValidCanadianPostalCodeInput(event)) {
        event.preventDefault()
      }
    }
  }

  const containsError =
    errorMessage || (submittedDataWithErrors && valueIsEmpty(value))

  return (
    <Fragment>
      {fieldType !== 'dropdown' ? (
        <CreatePracticeTextInputComponent
          value={value != null ? value : ''}
          label={label}
          InputProps={{
            endAdornment,
            startAdornment,
            inputComponent: fieldType === 'currency' && NumberFieldFormat,
          }}
          error={containsError}
          helperText={!!errorMessage && errorMessage}
          ismobilescreen={isIpadScreenSizeOrSmaller}
          includesStartAdornment={!!startAdornment}
          split={split}
          onFocus={() => setIsSelected(true)}
          onBlur={(e) => {
            setIsSelected(false)
            if (onBlur) onBlur(e)
          }}
          onClick={onClick}
          onChange={onChange}
          placeholder={placeholder}
          fieldType={fieldType}
          // For numeric fields, ensure only numbers are entered (ie no letters, -, .)
          onKeyDown={(e) => sanitizeInput(e, fieldType)}
          id={elementId}
        >
          Create new practice listing
        </CreatePracticeTextInputComponent>
      ) : (
        <CreatePracticeForm
          split={split}
          ismobilescreen={isIpadScreenSizeOrSmaller}
          id={elementId}
          containsError={containsError}
        >
          <CreatePracticeDropdownLabel
            ismobilescreen={isIpadScreenSizeOrSmaller}
          >
            {label}
          </CreatePracticeDropdownLabel>
          <Dropdown
            value={value}
            name={elementId}
            ismobilescreen={isIpadScreenSizeOrSmaller}
            onChange={onChange}
            label={label}
          >
            {options.map((option) => {
              const key = option['key']
              const label = option['label']
              return (
                <StyledMenuItem value={key} key={key}>
                  {label}
                </StyledMenuItem>
              )
            })}
          </Dropdown>
          <ErrorHelperText>{!!errorMessage && errorMessage}</ErrorHelperText>
        </CreatePracticeForm>
      )}
    </Fragment>
  )
}

export default CreatePracticeInput
