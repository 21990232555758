import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Paths from '../../Paths'
import {
  BackgroundContainer,
  FullPageContentContainer,
  PageContainer,
} from '../../styles/shared-styled-components'
import { NavigatingHomeText } from './styled'

const CallbackPage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(Paths.practices)
  }, [])

  return (
    <>
      <BackgroundContainer>
        <PageContainer>
          <FullPageContentContainer>
            <NavigatingHomeText>
              Navigating you to the homescreen...
            </NavigatingHomeText>
          </FullPageContentContainer>
        </PageContainer>
      </BackgroundContainer>
    </>
  )
}

export default CallbackPage
