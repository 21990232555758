import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useContainerDimensions from '../../../custom-hooks/use-container-dimensions'
import {
  BackIcon,
  BlackParagraphText,
  Checkbox,
  FormControlLabel,
  MenuHeader,
  MenuItemDivider,
  MoreFiltersIcon,
  NextIcon,
  RangeSlider,
  ScrollableFormGroup,
  FormGroup,
} from '../../../styles/shared-styled-components'
import {
  practiceBuildingTypes as practiceBuildingTypesMap,
  practiceOwnerWorkingSchedules as practiceOwnerWorkingScheduleMap,
  practiceTypes as practiceTypesMap,
  saleTypes as saleTypesMap,
} from '../../../utils/constants'
import {
  emptyFilterSearchParamValue,
  filterValues,
  getInitialOperatoriesFilter,
  getInitialRevenueFilter,
  getInitialSalePriceFilter,
  getInitialYearSoldFilter,
  getInitalPracticeTypeFitler,
} from '../../../utils/filters'
import {
  getAbbreviatedMoneyString,
  getFormattedMoneyString,
} from '../../../utils/numbers'
import { updateSearchParams } from '../../../utils/routing'
import { screenWidthIsSmallMobileSizeOrSmaller } from '../../../utils/view'
import ArrowIcon from '../../Micro/ArrowIcon'
import ArrowSeparator from '../../Micro/ArrowSeparator'
import CreatePracticeButton from '../../Micro/CreatePracticeButton'
import PopperMenu from '../../PopperMenu'
import Snackbar from '../../Snackbar'
import LocationSearchBox from '../LocationSearchBox'
import { LocationSuggestionWidthStyle } from '../LocationSearchBox/styled'
import {
  CarouselNavigationButton,
  FilterButton,
  FilterContentContainer,
  FiltersSubsection,
  LocationSearchForm,
  MoreFilterContainer,
  MoreFiltersCarousel,
  MoreFiltersFilterButton,
  RangeDescriptorContainer,
  RangeValueContainer,
  RangeValueSubtitleTextContainer,
  RangeValueTitleTextContainer,
  ResetFiltersButton,
  ResetFiltersContainer,
  ResetFiltersText,
  SearchFiltersContainer,
  SearchSectionContainer,
} from './styled'
import { useMyUser } from '../../../api/hooks/users'
import SignupButton from '../../Auth/SignupButton/SignupButton'
import Paths from '../../../Paths'

const getMarks = (min, max) => {
  return [
    {
      value: min,
      label: getAbbreviatedMoneyString(min),
    },
    {
      value: max,
      label: `${getAbbreviatedMoneyString(max)} +`,
    },
  ]
}

const salePriceMarks = getMarks(
  filterValues.minSalePrice,
  filterValues.maxSalePrice,
)
const revenueMarks = getMarks(filterValues.minRevenue, filterValues.maxRevenue)
const operatoriesMarks = [
  {
    value: filterValues.minOperatories,
    label: `${filterValues.minOperatories}`,
  },
  {
    value: filterValues.maxOperatories,
    label: `${filterValues.maxOperatories} +`,
  },
]

const practiceTypes = practiceTypesMap.map((practiceType) => practiceType.key)
const salePercentages = ['minority', 'majority', 'full']
const buildingTypes = practiceBuildingTypesMap.map(
  (buildingType) => buildingType.key,
)
const practiceOwnerWorkingSchedules = practiceOwnerWorkingScheduleMap.map(
  (schedule) => schedule.key,
)

const saleTypes = saleTypesMap.map((schedule) => schedule.key)

const getNumberOfFilterButtonsToDisplayBasedOnScreenWidth = (width) => {
  if (width < 548) {
    return 0
  }
  if (width < 870) {
    return 1
  }
  if (width < 1035) {
    return 2
  }
  if (width < 1275) {
    return 3
  }

  // If massive screen, return null
  return null
}

const SoldSearchFilters = ({
  user,
  ismobilescreen,
  listingView,
  numPractices,
  shouldResetFilters,
  setShouldResetFiltersToFalse,
  onResetListingTypeFilter,
}) => {
  const [width, setWidth] = useState(window.innerWidth)

  const [searchParams, setSearchParams] = useSearchParams()

  const locationSearchFormRef = useRef()
  const { width: locationSearchFormWidth } = useContainerDimensions(
    locationSearchFormRef,
  )

  // Location
  const [locationSearch, setLocationSearch] = useState(
    searchParams.get('location') || '',
  )

  const verifiedUser = useMyUser()
  const isAuth = verifiedUser.auth0Context.isAuthenticated

  // Sale price
  const initialSalePriceValue = user?.hasCreatedValuation
    ? getInitialSalePriceFilter(searchParams)
    : filterValues.defaultSalePriceRange
  const [anchorElementSalePriceFilter, setAnchorElementSalePriceFilter] =
    useState(null)
  const [stagingSalePriceFilter, setStagingSalePriceFilter] = useState(
    initialSalePriceValue,
  )
  const [salePriceFilter, setSalePriceFilter] = useState(initialSalePriceValue)

  // Revenue
  const initialRevenueValue = getInitialRevenueFilter(searchParams)
  const [anchorElementRevenueFilter, setAnchorElementRevenueFilter] =
    useState(null)
  const [stagingRevenueFilter, setStagingRevenueFilter] =
    useState(initialRevenueValue)
  const [revenueFilter, setRevenueFilter] = useState(initialRevenueValue)

  // Operatories
  const initialOperatoriesValue = getInitialOperatoriesFilter(searchParams)
  const [anchorElementOperatoriesFilter, setAnchorElementOperatoriesFilter] =
    useState(null)
  const [stagingOperatoriesFilter, setStagingOperatoriesFilter] = useState(
    initialOperatoriesValue,
  )
  const [operatoriesFilter, setOperatoriesFilter] = useState(
    initialOperatoriesValue,
  )

  // Year sold
  const initialYearSoldValue = getInitialYearSoldFilter(searchParams)
  const [anchorElementYearSoldFilter, setAnchorElementYearSoldFilter] =
    useState(null)
  const [yearSoldFilter, setYearSoldFilter] = useState(initialYearSoldValue)

  // Practice type
  const initialPracticeTypes =
    searchParams.get('type') && false
      ? searchParams.get('type').split(',')
      : practiceTypes
  const [anchorElementPracticeTypeFilter, setAnchorElementPracticeTypeFilter] =
    useState(null)
  const [practiceTypeFilter, setPracticeTypeFilter] =
    useState(initialPracticeTypes)

  //Practice type
  const practiceTypeHasBeenChanged =
    practiceTypeFilter.length !== practiceTypes.length
  const practiceTypeFilterOpen = Boolean(anchorElementPracticeTypeFilter)

  // Sale percentage
  const [
    anchorElementSalePercentageFilter,
    setAnchorElementSalePercentageFilter,
  ] = useState(null)
  const [salePercentageFilter, setSalePercentageFilter] =
    useState(salePercentages)

  // Building type
  const [anchorElementBuildingTypeFilter, setAnchorElementBuildingTypeFilter] =
    useState(null)
  const [buildingTypeFilter, setBuildingTypeFilter] = useState(buildingTypes)

  // Practice owner working schedule
  const [
    anchorElementPracticeOwnerWorkingScheduleFilter,
    setAnchorElementPracticeOwnerWorkingScheduleFilter,
  ] = useState(null)
  const [
    practiceOwnerWorkingScheduleFilter,
    setPracticeOwnerWorkingScheduleFilter,
  ] = useState(practiceOwnerWorkingSchedules)

  // Sale type
  const [anchorElementSaleTypeFilter, setAnchorElementSaleTypeFilter] =
    useState(null)
  const [saleTypeFilter, setSaleTypeFilter] = useState(
    searchParams.get('sale_type') || saleTypes,
  )

  // More filters
  const [anchorElementMoreFilter, setAnchorElementMoreFilter] = useState(null)
  const [moreFilterIsOpen, setMoreFilterIsOpen] = useState(false)
  const [activeStepInMoreFilterCarousel, setActiveStepInMoreFilterCarousel] =
    useState(0)

  const [snackbarMessage, setSnackbarMessage] = useState('')

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  // Mobile breakpoint logic
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  // Location
  const onChangeLocation = (search) => {
    setLocationSearch(search)

    // If they remove the location search entirely, update the params directly from the onChange fcn
    if (!search) {
      updateSearchParams(
        {
          location: '',
        },
        searchParams,
        setSearchParams,
      )
    }
  }

  const onEnterLocation = (selectedLocationValue) => {
    setLocationSearch(selectedLocationValue)

    updateSearchParams(
      {
        location: selectedLocationValue,
      },
      searchParams,
      setSearchParams,
    )
  }

  // Sale price
  const updateSalePriceFilter = (stagingSalePrice) => {
    setSalePriceFilter(stagingSalePrice)

    updateSearchParams(
      {
        price: stagingSalePrice,
      },
      searchParams,
      setSearchParams,
    )
  }

  // Use effects only needed when we buffer the filtered value (as we do for sliders)
  useEffect(() => {
    const timeout = setTimeout(() => {
      // I think this should still allow updates to take place even when they change it back to defaults,
      // because of the whole object reference array inequality thing
      if (stagingSalePriceFilter !== filterValues.defaultSalePriceRange) {
        updateSalePriceFilter(stagingSalePriceFilter)
      }
    }, 1000)

    return () => clearTimeout(timeout)
  }, [stagingSalePriceFilter])

  const onChangeSalePrice = (_, newSalePrice) => {
    setStagingSalePriceFilter(newSalePrice)
  }

  // Revenue
  const updateRevenueFilter = (stagingRevenue) => {
    setRevenueFilter(stagingRevenue)

    updateSearchParams(
      {
        revenue: stagingRevenue,
      },
      searchParams,
      setSearchParams,
    )
  }

  const onChangeRevenue = (_, newRevenue) => {
    setStagingRevenueFilter(newRevenue)
  }

  // Use effects only needed when we buffer the filtered value (as we do for sliders)
  useEffect(() => {
    const timeout = setTimeout(() => {
      // I think this should still allow updates to take place even when they change it back to defaults,
      // because of the whole object reference array inequality thing
      if (stagingRevenueFilter !== filterValues.defaultRevenueRange) {
        updateRevenueFilter(stagingRevenueFilter)
      }
    }, 1000)

    return () => clearTimeout(timeout)
  }, [stagingRevenueFilter])

  // Operatories
  const updateOperatoriesFilter = (stagingOperatories) => {
    setOperatoriesFilter(stagingOperatories)

    updateSearchParams(
      {
        operatories: stagingOperatories,
      },
      searchParams,
      setSearchParams,
    )
  }

  const onChangeOperatories = (_, newOperatories) => {
    setStagingOperatoriesFilter(newOperatories)
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      // I think this should still allow updates to take place even when they change it back to defaults,
      // because of the whole object reference array inequality thing
      if (stagingOperatoriesFilter !== filterValues.defaultOperatoriesRange) {
        updateOperatoriesFilter(stagingOperatoriesFilter)
      }
    }, 1000)

    return () => clearTimeout(timeout)
  }, [stagingOperatoriesFilter])

  // Year sold
  const onChangeYearSoldFilter = (value, key) => {
    let newYearSoldFilter =
      typeof yearSoldFilter === 'string'
        ? yearSoldFilter.split(',')
        : [...yearSoldFilter]
    if (value) {
      newYearSoldFilter.push(key)
    } else {
      newYearSoldFilter = newYearSoldFilter.filter((type) => type !== key)
    }

    setYearSoldFilter(newYearSoldFilter)

    updateSearchParams(
      {
        year: newYearSoldFilter.length
          ? newYearSoldFilter
          : emptyFilterSearchParamValue,
      },
      searchParams,
      setSearchParams,
    )
  }

  // Practice type
  const onChangePracticeTypeFilter = (value, key) => {
    let newPracticeTypeFilter = [...practiceTypeFilter]

    if (value) {
      newPracticeTypeFilter.push(key)
    } else {
      newPracticeTypeFilter = newPracticeTypeFilter.filter(
        (type) => type !== key,
      )
    }
    setPracticeTypeFilter(newPracticeTypeFilter)

    updateSearchParams(
      {
        type: newPracticeTypeFilter,
      },
      searchParams,
      setSearchParams,
    )
  }

  useEffect(() => {
    if (shouldResetFilters) {
      onClickResetFilters()
      setShouldResetFiltersToFalse()
    }
  }, [shouldResetFilters])

  const onClickResetFilters = () => {
    // Check if there are any valid filters to reset, if not show a successful snackbar message
    const searchParamKeys = [...searchParams.keys()]

    const oldSearchParams = {}
    searchParamKeys.forEach((key) => {
      oldSearchParams[key] = searchParams.get(key)
    })

    // now that we have all the old search params, we need to reset all the ones
    // that have to do with filters
    delete oldSearchParams.price
    delete oldSearchParams.operatories
    delete oldSearchParams.revenue
    delete oldSearchParams.type
    delete oldSearchParams.year
    delete oldSearchParams.listingType

    const newSearchParams = new URLSearchParams(oldSearchParams)
    setSearchParams(newSearchParams)
    setLocationSearch('')
    setStagingSalePriceFilter(filterValues.defaultSalePriceRange)
    setSalePriceFilter(filterValues.defaultSalePriceRange)
    setStagingRevenueFilter(filterValues.defaultRevenueRange)
    setRevenueFilter(filterValues.defaultRevenueRange)
    setStagingOperatoriesFilter(filterValues.defaultOperatoriesRange)
    setOperatoriesFilter(filterValues.defaultOperatoriesRange)
    setYearSoldFilter(filterValues.eligibleSaleYears)
    setPracticeTypeFilter(filterValues.defaultPracticeType)
    onResetListingTypeFilter()
  }
  const navigate = useNavigate()

  const handleSignUpClick = () => {
    navigate(Paths.practices)
  }

  const getFilterMenus = (key = null) => {
    const allFilterMenus = {
      price: (
        <Fragment key='price-filter-menu'>
          <MenuHeader>Sale Price Range</MenuHeader>
          <FilterContentContainer>
            {isAuth ? (
              <>
                <RangeSlider
                  value={stagingSalePriceFilter}
                  onChange={onChangeSalePrice}
                  valueLabelDisplay='auto'
                  marks={salePriceMarks}
                  step={filterValues.salePriceRangeIntervalSize}
                  min={filterValues.minSalePrice}
                  max={filterValues.maxSalePrice}
                  valueLabelFormat={(value) => getAbbreviatedMoneyString(value)}
                />
                <RangeDescriptorContainer>
                  <RangeValueContainer>
                    <RangeValueTitleTextContainer>
                      Min Price
                    </RangeValueTitleTextContainer>
                    <RangeValueSubtitleTextContainer>
                      {getFormattedMoneyString(stagingSalePriceFilter[0])}
                    </RangeValueSubtitleTextContainer>
                  </RangeValueContainer>
                  <ArrowSeparator />
                  <RangeValueContainer>
                    <RangeValueTitleTextContainer>
                      Max Price
                    </RangeValueTitleTextContainer>
                    <RangeValueSubtitleTextContainer>
                      {getFormattedMoneyString(stagingSalePriceFilter[1])}
                      {stagingSalePriceFilter[1] === filterValues.maxSalePrice
                        ? ' +'
                        : ''}
                    </RangeValueSubtitleTextContainer>
                  </RangeValueContainer>
                </RangeDescriptorContainer>
              </>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  textAlign: 'center',
                  gap: 10,
                  padding: '10px 20px',
                }}
              >
                Sign up to filter by sale price.
                <SignupButton
                  user={user}
                  onClick={handleSignUpClick}
                  allowShrinking
                  buttonType='text'
                  text='Sign up'
                  inValuationMode
                />
              </div>
            )}
          </FilterContentContainer>
        </Fragment>
      ),
      revenue: (
        <Fragment key='billings-filter-menu'>
          <MenuHeader>Average Annual Billings Range</MenuHeader>
          <FilterContentContainer>
            <RangeSlider
              value={stagingRevenueFilter}
              onChange={onChangeRevenue}
              valueLabelDisplay='auto'
              marks={revenueMarks}
              step={filterValues.revenueRangeIntervalSize}
              min={filterValues.minRevenue}
              max={filterValues.maxRevenue}
              valueLabelFormat={(value) => getAbbreviatedMoneyString(value)}
            />
            <RangeDescriptorContainer>
              <RangeValueContainer>
                <RangeValueTitleTextContainer>
                  Min Billings
                </RangeValueTitleTextContainer>
                <RangeValueSubtitleTextContainer>
                  {getFormattedMoneyString(stagingRevenueFilter[0])}
                </RangeValueSubtitleTextContainer>
              </RangeValueContainer>
              <ArrowSeparator />
              <RangeValueContainer>
                <RangeValueTitleTextContainer>
                  Max Billings
                </RangeValueTitleTextContainer>
                <RangeValueSubtitleTextContainer>
                  {getFormattedMoneyString(stagingRevenueFilter[1])}
                  {stagingRevenueFilter[1] === filterValues.maxRevenue
                    ? ' +'
                    : ''}
                </RangeValueSubtitleTextContainer>
              </RangeValueContainer>
            </RangeDescriptorContainer>
          </FilterContentContainer>
        </Fragment>
      ),
      operatories: (
        <Fragment key='operatories-filter-menu'>
          <MenuHeader>Number of Operatories Range</MenuHeader>
          <FilterContentContainer>
            <RangeSlider
              value={stagingOperatoriesFilter}
              onChange={onChangeOperatories}
              valueLabelDisplay='auto'
              marks={operatoriesMarks}
              step={1}
              min={filterValues.minOperatories}
              max={filterValues.maxOperatories}
            />
            <RangeDescriptorContainer>
              <RangeValueContainer>
                <RangeValueTitleTextContainer>
                  Min Ops
                </RangeValueTitleTextContainer>
                <RangeValueSubtitleTextContainer>
                  {stagingOperatoriesFilter[0]}
                </RangeValueSubtitleTextContainer>
              </RangeValueContainer>
              <ArrowSeparator />
              <RangeValueContainer>
                <RangeValueTitleTextContainer>
                  Max Ops
                </RangeValueTitleTextContainer>
                <RangeValueSubtitleTextContainer>
                  {stagingOperatoriesFilter[1]}
                  {stagingOperatoriesFilter[1] === filterValues.maxOperatories
                    ? ' +'
                    : ''}
                </RangeValueSubtitleTextContainer>
              </RangeValueContainer>
            </RangeDescriptorContainer>
          </FilterContentContainer>
        </Fragment>
      ),
      yearSold: (
        <Fragment key='years-sold-filter-menu'>
          <MenuHeader>Year Sold</MenuHeader>
          <ScrollableFormGroup height={187}>
            {filterValues.eligibleSaleYears.map((yearSold, idx) => (
              <Fragment key={idx}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={yearSoldFilter.includes(yearSold)}
                      onClick={(e) =>
                        onChangeYearSoldFilter(e.target.checked, yearSold)
                      }
                    />
                  }
                  label={yearSold}
                />
                {idx !== filterValues.eligibleSaleYears.length - 1 && (
                  <MenuItemDivider />
                )}
              </Fragment>
            ))}
          </ScrollableFormGroup>
        </Fragment>
      ),
      practiceType: (
        <Fragment key='practice-type-filter-menu'>
          <MenuHeader>Practice Type</MenuHeader>
          <FormGroup>
            {practiceTypesMap.map((practiceType, idx) => (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={practiceTypeFilter.includes(practiceType.key)}
                      onClick={(e) =>
                        onChangePracticeTypeFilter(
                          e.target.checked,
                          practiceType.key,
                        )
                      }
                    />
                  }
                  label={practiceType.label}
                />
                {idx !== practiceTypesMap.length - 1 && <MenuItemDivider />}
              </>
            ))}
          </FormGroup>
        </Fragment>
      ),
    }

    if (!key) {
      return allFilterMenus
    }

    return allFilterMenus[key]
  }

  const getFilterButtons = (numToDisplay) => {
    let allFilterButtons = [
      <Fragment key='price-filter-button'>
        <FilterButton
          id='priceFilterButton'
          onClick={(event) => {
            setAnchorElementSalePriceFilter(
              anchorElementSalePriceFilter ? null : event.currentTarget,
            )
          }}
          selected={salePriceHasBeenChanged}
          open={salePriceFilterOpen}
        >
          {salePriceHasBeenChanged
            ? `
                ${getAbbreviatedMoneyString(salePriceFilter[0])} - 
                ${getAbbreviatedMoneyString(salePriceFilter[1])}
              `
            : 'Sale Price'}
          <ArrowIcon open={salePriceFilterOpen} />
        </FilterButton>
        <PopperMenu
          open={salePriceFilterOpen}
          anchorElement={anchorElementSalePriceFilter}
          onClose={(event) => {
            if (
              !document
                .getElementById('priceFilterButton')
                .contains(event.target)
            ) {
              setAnchorElementSalePriceFilter(null)
            }
          }}
          disableTopPadding
          disablebottompadding={true}
        >
          {getFilterMenus('price')}
        </PopperMenu>
      </Fragment>,
      <Fragment key='revenue-filter-button'>
        <FilterButton
          id='revenueFilterButton'
          onClick={(event) =>
            setAnchorElementRevenueFilter(
              anchorElementRevenueFilter ? null : event.currentTarget,
            )
          }
          selected={revenueHasBeenChanged}
          open={revenueFilterOpen}
        >
          {revenueHasBeenChanged
            ? `
                ${getAbbreviatedMoneyString(revenueFilter[0])} - 
                ${getAbbreviatedMoneyString(revenueFilter[1])}
              `
            : 'Annual Billings'}
          <ArrowIcon open={revenueFilterOpen} />
        </FilterButton>
        <PopperMenu
          open={revenueFilterOpen}
          anchorElement={anchorElementRevenueFilter}
          onClose={(event) => {
            if (
              !document
                .getElementById('revenueFilterButton')
                .contains(event.target)
            ) {
              setAnchorElementRevenueFilter(null)
            }
          }}
          disableTopPadding
          disablebottompadding={true}
        >
          {getFilterMenus('revenue')}
        </PopperMenu>
      </Fragment>,
      <Fragment key='operatories-filter-button'>
        <FilterButton
          id='operatoriesFilterButton'
          onClick={(event) => {
            setAnchorElementOperatoriesFilter(
              anchorElementOperatoriesFilter ? null : event.currentTarget,
            )
          }}
          selected={operatoriesHaveBeenChanged}
          open={operatoriesFilterOpen}
        >
          {operatoriesHaveBeenChanged
            ? `${operatoriesFilter[0]} - ${operatoriesFilter[1]} Ops`
            : 'Operatories'}
          <ArrowIcon open={operatoriesFilterOpen} />
        </FilterButton>
        <PopperMenu
          open={operatoriesFilterOpen}
          anchorElement={anchorElementOperatoriesFilter}
          onClose={(event) => {
            if (
              !document
                .getElementById('operatoriesFilterButton')
                .contains(event.target)
            ) {
              setAnchorElementOperatoriesFilter(null)
            }
          }}
          disableTopPadding
          disablebottompadding={true}
        >
          {getFilterMenus('operatories')}
        </PopperMenu>
      </Fragment>,
      <Fragment key='year-sold-filter-button'>
        <FilterButton
          id='yearSoldFilterButton'
          onClick={(event) =>
            setAnchorElementYearSoldFilter(
              anchorElementYearSoldFilter ? null : event.currentTarget,
            )
          }
          selected={yearsSoldFilterHasBeenChanged}
          open={yearsSoldFilterOpen}
        >
          Year Sold
          <ArrowIcon open={yearsSoldFilterOpen} />
        </FilterButton>
        <PopperMenu
          open={yearsSoldFilterOpen}
          anchorElement={anchorElementYearSoldFilter}
          onClose={(event) => {
            if (
              !document
                .getElementById('yearSoldFilterButton')
                .contains(event.target)
            ) {
              setAnchorElementYearSoldFilter(null)
            }
          }}
          disableTopPadding
          fixedWidth={240}
        >
          {getFilterMenus('yearSold')}
        </PopperMenu>
      </Fragment>,
      <Fragment key='practice-type-filter-button'>
        <FilterButton
          id='practiceTypeFilterButton'
          onClick={(event) =>
            setAnchorElementPracticeTypeFilter(
              anchorElementPracticeTypeFilter ? null : event.currentTarget,
            )
          }
          selected={practiceTypeHasBeenChanged}
          open={practiceTypeFilterOpen}
        >
          Practice Type
          <ArrowIcon open={practiceTypeFilterOpen} />
        </FilterButton>
        <PopperMenu
          open={practiceTypeFilterOpen}
          anchorElement={anchorElementPracticeTypeFilter}
          onClose={(event) => {
            if (
              !document
                .getElementById('practiceTypeFilterButton')
                .contains(event.target)
            ) {
              setAnchorElementPracticeTypeFilter(null)
            }
          }}
          disableTopPadding
        >
          {getFilterMenus('practiceType')}
        </PopperMenu>
      </Fragment>,
    ]

    // If we have no set # to display, return them all
    // If we aren't displaying any, we'll only have the 'more filters' button (ie on mobile)
    // Else we slice the whole list and return
    return numToDisplay == null
      ? allFilterButtons
      : numToDisplay === 0
        ? []
        : allFilterButtons.slice(0, numToDisplay)
  }

  const salePriceFilterOpen = Boolean(anchorElementSalePriceFilter)
  const revenueFilterOpen = Boolean(anchorElementRevenueFilter)
  const operatoriesFilterOpen = Boolean(anchorElementOperatoriesFilter)
  const yearsSoldFilterOpen = Boolean(anchorElementYearSoldFilter)
  const moreFilterOpen = Boolean(anchorElementMoreFilter)

  const salePriceHasBeenChanged =
    stagingSalePriceFilter[0] !== filterValues.defaultSalePriceRange[0] ||
    stagingSalePriceFilter[1] !== filterValues.defaultSalePriceRange[1]
  const revenueHasBeenChanged =
    stagingRevenueFilter[0] !== filterValues.defaultRevenueRange[0] ||
    stagingRevenueFilter[1] !== filterValues.defaultRevenueRange[1]
  const operatoriesHaveBeenChanged =
    stagingOperatoriesFilter[0] !== filterValues.defaultOperatoriesRange[0] ||
    stagingOperatoriesFilter[1] !== filterValues.defaultOperatoriesRange[1]
  const yearsSoldFilterHasBeenChanged =
    yearSoldFilter.length !== filterValues.eligibleSaleYears.length
  const salePercentageHasBeenChanged =
    salePercentageFilter.length !== salePercentages.length
  const buildingTypeHasBeenChanged =
    buildingTypeFilter.length !== buildingTypes.length
  const practiceOwnerWorkingScheduleHasBeenChanged =
    practiceOwnerWorkingScheduleFilter.length !==
    practiceOwnerWorkingSchedules.length
  const saleTypeHasBeenChanged = saleTypeFilter.length !== saleTypes.length

  const allChangeTrackers = [
    salePriceHasBeenChanged,
    revenueHasBeenChanged,
    operatoriesHaveBeenChanged,
    yearsSoldFilterHasBeenChanged,
    salePercentageHasBeenChanged,
    buildingTypeHasBeenChanged,
    practiceOwnerWorkingScheduleHasBeenChanged,
    saleTypeHasBeenChanged,
    practiceTypeHasBeenChanged,
  ]
  const totalNumChangeTrackers = allChangeTrackers.length

  const numFilterButtonsToDisplay =
    getNumberOfFilterButtonsToDisplayBasedOnScreenWidth(width)
  const filterButtons = getFilterButtons(numFilterButtonsToDisplay)
  const allFilterMenus = Object.values(getFilterMenus())
  const menusToDisplayInMoreFilter =
    numFilterButtonsToDisplay === 0
      ? allFilterMenus
      : numFilterButtonsToDisplay == null
        ? []
        : allFilterMenus.slice(numFilterButtonsToDisplay)
  const numberOfMenusInMoreFilter = menusToDisplayInMoreFilter.length
  const numberOfMoreFiltersChanged = allChangeTrackers
    .slice(totalNumChangeTrackers - numberOfMenusInMoreFilter)
    .filter((bool) => !!bool).length

  const isSmallMobile = screenWidthIsSmallMobileSizeOrSmaller(width)

  const moreFiltersButtonTitle = `${
    numFilterButtonsToDisplay > 0 ? 'More' : 'Filters'
  }${numberOfMoreFiltersChanged > 0 ? ` (${numberOfMoreFiltersChanged}) ` : ''}`

  return (
    <>
      <SearchSectionContainer>
        <SearchFiltersContainer
          ismobilescreen={ismobilescreen}
          style={{ gap: ismobilescreen ? 20 : 30 }}
        >
          <LocationSearchForm
            style={{
              flex: 1,
              maxWidth:
                width > 1250 ? 800 : !ismobilescreen ? width * 0.4 : 400,
              minWidth: !ismobilescreen ? 330 : 180,
            }}
            ref={locationSearchFormRef}
          >
            <LocationSearchBox
              ismobilescreen={ismobilescreen}
              value={locationSearch}
              onChange={onChangeLocation}
              onEnter={onEnterLocation}
              customPopperMenuStyle={LocationSuggestionWidthStyle}
              customPopperMenuWidth={locationSearchFormWidth}
            />
          </LocationSearchForm>
          <div style={{ display: 'flex', gap: '10px' }}>
            {filterButtons}
            {menusToDisplayInMoreFilter.length > 0 && (
              <>
                <MoreFiltersFilterButton
                  onClick={(event) => {
                    if (moreFilterIsOpen) {
                      setAnchorElementMoreFilter(null)
                      setMoreFilterIsOpen(false)
                    } else {
                      setMoreFilterIsOpen(true)
                      setAnchorElementMoreFilter(event.currentTarget)
                    }
                  }}
                  selected={numberOfMoreFiltersChanged > 0}
                  open={moreFilterIsOpen}
                  style={{ minWidth: ismobilescreen ? 110 : 130 }}
                >
                  <MoreFilterContainer>
                    <MoreFiltersIcon />
                  </MoreFilterContainer>
                  {!isSmallMobile && moreFiltersButtonTitle}
                  <ArrowIcon
                    open={moreFilterIsOpen && anchorElementMoreFilter}
                  />
                </MoreFiltersFilterButton>
                <PopperMenu
                  open={moreFilterIsOpen && anchorElementMoreFilter}
                  anchorElement={anchorElementMoreFilter}
                  onClose={() => {
                    setAnchorElementMoreFilter(null)
                  }}
                  disableTopPadding
                  fixedWidth={340}
                >
                  {menusToDisplayInMoreFilter[activeStepInMoreFilterCarousel]}
                  {numberOfMenusInMoreFilter > 1 && (
                    <MoreFiltersCarousel
                      steps={numberOfMenusInMoreFilter}
                      position='static'
                      activeStep={activeStepInMoreFilterCarousel}
                      nextButton={
                        <CarouselNavigationButton
                          onClick={() =>
                            setActiveStepInMoreFilterCarousel(
                              (prevActiveStep) => prevActiveStep + 1,
                            )
                          }
                          disabled={
                            activeStepInMoreFilterCarousel ===
                            numberOfMenusInMoreFilter - 1
                          }
                        >
                          Next
                          <NextIcon />
                        </CarouselNavigationButton>
                      }
                      backButton={
                        <CarouselNavigationButton
                          onClick={() =>
                            setActiveStepInMoreFilterCarousel(
                              (prevActiveStep) => prevActiveStep - 1,
                            )
                          }
                          disabled={activeStepInMoreFilterCarousel === 0}
                        >
                          <BackIcon />
                          Back
                        </CarouselNavigationButton>
                      }
                    />
                  )}
                  <ResetFiltersContainer>
                    <ResetFiltersButton onClick={onClickResetFilters}>
                      Reset filters
                    </ResetFiltersButton>
                  </ResetFiltersContainer>
                </PopperMenu>
              </>
            )}
          </div>
        </SearchFiltersContainer>
        <FiltersSubsection>
          <BlackParagraphText>{numPractices} practices</BlackParagraphText>
          <ResetFiltersText onClick={onClickResetFilters}>
            Reset filters
          </ResetFiltersText>
        </FiltersSubsection>
      </SearchSectionContainer>
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
        variant={'success'}
      />
    </>
  )
}

export default SoldSearchFilters
