import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone'
import styled from 'styled-components/macro'

export const ThumbnailContainer = styled.div`
  position: relative;
  height: 80px;
  width: auto;
  border: 1px solid ${({ theme }) => theme.color.muiLightGrey};
  padding: 10px;
`

export const Image = styled.img`
  height: 100%;
  max-width: 200px;
  object-fit: cover;
`

export const RemoveImageIcon = styled(HighlightOffTwoToneIcon)`
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1;
  color: ${({ theme }) => theme.color.black};
  cursor: pointer;
  height: 20px;
  width: 20px;
`
