import { useApolloClient } from '@apollo/client'
import { useMutationWithErrorLogging } from '../../../utils/hooks'
import { matchIsValidTel } from 'mui-tel-input'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Paths from '../../../Paths'
import {
  EDIT_PHONE_NUMBER,
  VERIFY_PHONE_NUMBER,
} from '../../../api/mutations/users'
import { GET_MY_USER } from '../../../api/queries/users'
import Header from '../../../components/NavBar/Header'
import OnboardingSteps from '../../../components/Onboarding/OnboardingSteps'
import {
  BackgroundContainer,
  ColumnButtonContainer,
  FlexDiv,
  PageContainer,
} from '../../../styles/shared-styled-components'
import {
  Asset,
  AssetContainer,
  BackButton,
  ContinueButton,
  FormTitleText,
  OnboardingForm,
  OnboardingFormContainer,
  OnboardingInputField,
} from '../shared-onboarding-styles'
import { PhoneInput } from './styled'

const inputContainsError = (isValidInput, newLength) => {
  return !isValidInput && newLength >= expectedPhoneNumberLength
}

const formatNumber = (number) => {
  let baseNumber

  if (number == null) {
    return number
  }

  // Making sure it has the +1 dropdown
  if (!(number[0] === '+' && number[1] === '1')) {
    baseNumber = number[0] + '1' + number.substring(1)
    return baseNumber
  }

  baseNumber = number

  return baseNumber
}

const expectedPhoneNumberLength = 10
const expectedCodeLength = 6

const Phone = ({ user, width, ismobilescreen, isIpadSizeOrSmaller }) => {
  const navigate = useNavigate()
  const { cache } = useApolloClient()

  const [editPhoneNumberMutation, { data: editPhoneNumberData, loading }] =
    useMutationWithErrorLogging(EDIT_PHONE_NUMBER)
  const [
    verifyPhoneNumberMutation,
    { data: verifyPhoneNumberData, loading: loadingVerification },
  ] = useMutationWithErrorLogging(VERIFY_PHONE_NUMBER)

  const [stage, setStage] = useState('edit')
  const [phoneNumber, setPhoneNumber] = useState(user?.phone || '')
  const [verificationCode, setVerificationCode] = useState()
  const [phoneErrorMessage, setPhoneErrorMessage] = useState()
  const [verificationCodeErrorMessage, setVerificationCodeErrorMessage] =
    useState()
  const [continuePhoneEnabled, setContinuePhoneEnabled] = useState(user?.phone)
  // Don't need a state var for continuing from verification code input, because
  // its simple enough to capture in one line: code.length === 6

  const updatePhoneNumberDependents = (newPhoneNumber, newLength) => {
    const isValidInput = matchIsValidTel(newPhoneNumber)
    // Only contains error if its invalid and at least expected length
    const containsError = inputContainsError(isValidInput, newLength)

    if (containsError) {
      setPhoneErrorMessage('Please enter a valid phone number.')
    } else {
      setPhoneErrorMessage()
    }

    // Continue is disabled unless valid input + expected length
    const shouldEnableContinue =
      isValidInput && newLength === expectedPhoneNumberLength
    setContinuePhoneEnabled(shouldEnableContinue)
  }

  const goToNextOnboardingScreen = () => {
    navigate(Paths.goalsOnboarding)
  }

  const onClickContinueFromPhone = () => {
    // If they've already verified this phone number, and them clicking 'continue'
    // was just because they backtracked from a later step, then just proceed them to the next step
    // without an API call
    if (user.phone === phoneNumber && user.phoneVerified) {
      goToNextOnboardingScreen()
    } else {
      // When they first sign up this is the first authenticated call to our DB, so we attach the UTM param
      // from local storage here
      editPhoneNumberMutation({
        variables: {
          phone: phoneNumber,
        },
        onCompleted: async (data) => {
          const userDataFetch = cache.readQuery({
            query: GET_MY_USER,
          })

          const { success, errorCode } = data?.editPhoneNumber

          // If we have cached user data, update the name
          if (userDataFetch) {
            // Grab the data in the cache and append the name
            const existingUser = userDataFetch.getMyUser
            const updatedUser = {
              ...existingUser,
              phone: phoneNumber,
            }

            // Update the cache
            cache.writeQuery({
              query: GET_MY_USER,
              data: {
                getMyUser: updatedUser,
              },
              broadcast: false,
            })
          }

          if (success) {
            setStage('verification')
          } else {
            if (errorCode === 409) {
              setPhoneErrorMessage(
                'Phone number already taken. If you previously signed up with this phone number, try resetting the password for that account, or use a different number here.',
              )
            } else if (errorCode === 404) {
              setPhoneErrorMessage(
                'User not found. Please logout and try again.',
              )
            } else if (errorCode === 429) {
              setPhoneErrorMessage(
                'Max attempts reached for this phone number. Try again in 10 minutes.',
              )
            } else if (errorCode === 500) {
              setPhoneErrorMessage(
                'Error updating your app metadata. Please contact dentacloud support.',
              )
            }
          }
        },
      })
    }
  }

  const handlePhoneChange = (newPhoneNumber, info) => {
    const { countryCallingCode } = info

    const newLength =
      newPhoneNumber.replace(' ', '').replace(/\D/g, '')?.length -
      countryCallingCode?.length
    setPhoneNumber(formatNumber(newPhoneNumber))
    updatePhoneNumberDependents(newPhoneNumber, newLength)
  }

  const handleVerificationCodeChange = (newCode) => {
    const { value } = newCode.target
    const newLength = value.length
    setVerificationCode(value)
    const codeLongerThanExpected = newLength > expectedCodeLength
    if (codeLongerThanExpected) {
      setVerificationCodeErrorMessage('Verification code must be 6 digits.')
    } else {
      setVerificationCodeErrorMessage()
    }
  }

  const onClickContinueFromVerificationCode = () => {
    verifyPhoneNumberMutation({
      variables: {
        code: verificationCode,
      },
    })
  }

  const onBackPressed = useCallback(
    (stage) => {
      if (loadingVerification || loading) return
      if (stage === 'edit') {
        setStage('edit')
      } else {
        navigate(Paths.nameOnboarding)
      }
    },
    [loadingVerification, loading, navigate],
  )

  useEffect(() => {
    if (verifyPhoneNumberData) {
      const { success, errorCode } = verifyPhoneNumberData.verifyPhoneNumber

      if (success) {
        goToNextOnboardingScreen()
      } else {
        if (errorCode === 401) {
          setVerificationCodeErrorMessage(
            'Incorrect code. Check your phone again, or click the button below to get a new code if you want to change phone numbers.',
          )
        } else if (errorCode === 429) {
          setVerificationCodeErrorMessage(
            'Max attempts reached. Try again in 10 minutes.',
          )
        } else if (errorCode === 404) {
          setVerificationCodeErrorMessage(
            'Code expired. Please go back and request a new code.',
          )
        }
      }
    }
  }, [verifyPhoneNumberData])

  return (
    <>
      <Header
        user={user}
        width={width}
        ismobilescreen={isIpadSizeOrSmaller}
        isOnboardingScreen
      />
      <BackgroundContainer>
        <PageContainer>
          <FlexDiv>
            <OnboardingFormContainer ismobilescreen={isIpadSizeOrSmaller}>
              <OnboardingSteps currentStep={2} />
              {stage === 'edit' ? (
                <>
                  <OnboardingForm ismobilescreen={ismobilescreen}>
                    <FormTitleText ismobilescreen={ismobilescreen}>
                      Enter your mobile number -- just to verify you're a real
                      person.
                    </FormTitleText>
                    <PhoneInput
                      label='Mobile number * '
                      value={phoneNumber}
                      helperText={phoneErrorMessage || ' '}
                      autoFocus
                      onChange={handlePhoneChange}
                      focusOnSelectCountry
                      fullWidth
                      error={!!phoneErrorMessage}
                      defaultCountry='CA'
                      onlyCountries={['US', 'CA']}
                    />
                  </OnboardingForm>
                  <ColumnButtonContainer>
                    <ContinueButton
                      disabled={!continuePhoneEnabled || loading}
                      onClick={onClickContinueFromPhone}
                    >
                      {loading ? 'Please wait...' : 'Save and continue'}
                    </ContinueButton>
                    <BackButton
                      disabled={loading}
                      onClick={() => onBackPressed()}
                    >
                      Back
                    </BackButton>
                  </ColumnButtonContainer>
                </>
              ) : (
                <>
                  <OnboardingForm ismobilescreen={ismobilescreen}>
                    <FormTitleText ismobilescreen={ismobilescreen}>
                      Enter the 6-digit verification code sent to your phone.
                    </FormTitleText>
                    <OnboardingInputField
                      value={verificationCode}
                      label='Verification code * '
                      onChange={handleVerificationCodeChange}
                      error={!!verificationCodeErrorMessage}
                      helperText={verificationCodeErrorMessage || ' '}
                    />
                  </OnboardingForm>
                  <ColumnButtonContainer>
                    <ContinueButton
                      disabled={
                        verificationCode?.length !== expectedCodeLength ||
                        loadingVerification
                      }
                      onClick={onClickContinueFromVerificationCode}
                    >
                      {loadingVerification
                        ? 'Please wait...'
                        : 'Save and continue'}
                    </ContinueButton>
                    <BackButton
                      disabled={loading}
                      onClick={() => onBackPressed('edit')}
                    >
                      Get new code
                    </BackButton>
                  </ColumnButtonContainer>
                </>
              )}
            </OnboardingFormContainer>
            {!isIpadSizeOrSmaller && (
              <AssetContainer>
                <Asset src={Paths.dentacloudPhonePage} />
              </AssetContainer>
            )}
          </FlexDiv>
        </PageContainer>
      </BackgroundContainer>
    </>
  )
}

export default Phone
