import styled from 'styled-components/macro'
import { ColumnFlexStyle } from '../../../styles/shared-styled-components'

export const ChartContainer = styled.div`
  ${ColumnFlexStyle}
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const BarContainer = styled(ChartContainer)`
  ${ColumnFlexStyle}
  max-width: 500px;
  height: ${({ ismobilescreen }) => (ismobilescreen ? '200px' : '300px')};
  width: 100%;
  margin: 0 auto;
`
