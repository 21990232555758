import React from 'react'
import Paths from '../../../Paths'
import {
  AppBar,
  DesktopHeaderDentacloudLogo,
  DesktopNavToolbar,
  ToolbarContainerCenter,
  ToolbarContainerLeft,
  ToolbarContainerRight,
} from './styled'

const WaitlistHeader = () => {
  return (
    <>
      <AppBar>
        <DesktopNavToolbar disableGutters>
          <ToolbarContainerLeft>
            <DesktopHeaderDentacloudLogo
              component='img'
              alt='Dentacloud Logo'
              src={Paths.whiteLogoWithHorizontalText}
            />
          </ToolbarContainerLeft>
          <ToolbarContainerCenter />
          <ToolbarContainerRight />
        </DesktopNavToolbar>
      </AppBar>
    </>
  )
}

export default WaitlistHeader
