import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Paths from '../../Paths'
import EditSectionButton from '../../components/Micro/EditSectionButton'
import {
  Image,
  LargeParagraphText,
} from '../../styles/shared-styled-components'
import {
  practiceOwnerAccessTierKey,
  picturesSectionKey,
  editingFlowKey,
} from '../../utils/constants'
import { navigateToDueDiligenceSectionToEdit } from '../../utils/routing'
import { StyledLockIcon } from '../Micro/LockIcon/styled'
import PictureCarouselNavigator from '../Micro/PictureCarouselNavigator/PictureCarouselNavigator'
import {
  CarouselNavigationContainer,
  NoPicturesContainer,
  NoPicturesIconContainer,
  NoPicturesIconFrame,
  Picture,
  PictureContainer,
  PictureFrame,
} from './styled'

import styled from 'styled-components/macro'
import { PrimaryButton } from '../../styles/shared-styled-components'
import partnershipSvg from './partnership.svg'

const PracticePicturesButton = styled(PrimaryButton)`
  right: 20px;
  top: 34px;
  position: absolute;
  justify-content: space-around;
  gap: 5px;
  min-width: ${180}px;
  width: fit-content;
  height: ${48}px;
  padding: 5px 10px;
  font-weight: bold;
`

const PracticePicturesCarousel = ({
  practiceId,
  pictures,
  currentViewerUserType,
  hasDataroomAccess,
  handleRequestPressed,
}) => {
  const navigate = useNavigate()

  const [carouselIndex, setCarouselIndex] = useState(0)
  const [fullScreen, setFullScreen] = useState(false)

  const numPictures = pictures?.length

  return (
    <PictureContainer fullScreen={fullScreen}>
      {hasDataroomAccess && (
        <>
          {currentViewerUserType === practiceOwnerAccessTierKey && (
            <EditSectionButton
              fixed
              size={'medium'}
              onClick={() =>
                navigateToDueDiligenceSectionToEdit(
                  picturesSectionKey,
                  practiceId,
                  navigate,
                  editingFlowKey,
                )
              }
            />
          )}
          {numPictures > 0 ? (
            <PictureFrame>
              <Picture src={pictures[carouselIndex]} />
              {numPictures > 1 && (
                <CarouselNavigationContainer>
                  <PictureCarouselNavigator
                    index={carouselIndex}
                    numPictures={numPictures}
                    onClickBack={() => setCarouselIndex(carouselIndex - 1)}
                    onClickNext={() => setCarouselIndex(carouselIndex + 1)}
                  />
                </CarouselNavigationContainer>
              )}
            </PictureFrame>
          ) : (
            <NoPicturesContainer>
              <NoPicturesIconContainer>
                <NoPicturesIconFrame src={Paths.dentalChair} />
              </NoPicturesIconContainer>
            </NoPicturesContainer>
          )}
        </>
      )}
      {!hasDataroomAccess && (
        <NoPicturesContainer>
          <Image src={partnershipSvg} style={{ maxWidth: '700px' }} />
          <PracticePicturesButton onClick={handleRequestPressed}>
            <StyledLockIcon />
            Practice Pictures
          </PracticePicturesButton>
        </NoPicturesContainer>
      )}
    </PictureContainer>
  )
}

export default PracticePicturesCarousel
