import color from './color'
import spacing from './spacing'
import typography from './typography'

const baseStyles = {
  color,
  spacing,
  typography,
}

export default baseStyles
