import styled from 'styled-components/macro'
import {
  GreyParagraphText,
  PrimaryButtonStyle,
} from '../../../styles/shared-styled-components'

export const FileInputLabel = styled.label`
  ${PrimaryButtonStyle}
  width: 200px;
  text-align: center;
`

// Display: none makes sure the default input label doesn't show
export const ImageInput = styled.input.attrs({
  type: 'file',
  accept: 'image/jpeg, image/png',
  multiple: true,
})`
  display: none;
`

export const PdfOrCsvInput = styled.input.attrs({
  type: 'file',
  accept: 'application/pdf, .csv, application/vnd.ms-excel',
})`
  display: none;
`

export const AllFilesInput = styled.input.attrs({
  type: 'file',
  accept: '*',
})`
  display: none;
`

export const FileInputSubtitle = styled(GreyParagraphText)`
  font-size: ${({ theme }) => theme.typography.smallFontSize};
`
