import gql from 'graphql-tag'

export const MARK_NOTIFICATION_AS_READ = gql`
  mutation markNotificationAsRead($id: String!) {
    markNotificationAsRead(id: $id) {
      id
    }
  }
`

export const NOTIFICATION_SUBSCRIPTION = gql`
  subscription {
    notificationAdded {
      id
      userId
      deliveryMethods
      notificationType
      notificationMetadata
      viewUrl
      isRead
    }
  }
`
