import ReplyIcon from '@mui/icons-material/Reply'
import styled, { css } from 'styled-components/macro'
import { CreatePracticeForm } from '../../../components/CreatePractice/CreatePracticeInput/styled'
import {
  CreatePracticeFormContainer as CPFC,
  DynamicColumnHeightStyle,
} from '../../../components/CreatePractice/CreatePracticeInputForm/styled'
import {
  AutoHorizontalMargins,
  AvatarFrameStyle,
  BlackLargeInputText,
  BlackParagraphText,
  ColumnFlexStyle,
  GreyLargeInputText,
  GreyParagraphText,
  InterSubsectionalGap,
  IntersectionalGap,
  IntraSubsectionalGap,
  IntrasectionalGap,
  MenuItemDivider,
  MicroGap,
  ParagraphText,
  RowFlexStyle,
  ScrollStyle,
  SecondaryButton,
  SecondaryButtonLightHoverStyle,
  SubtitleText,
  TextButtonWithPrimaryColor,
  UnderlineStyle,
} from '../../../styles/shared-styled-components'

export const StyledCreatePracticeFormContainer = styled(CPFC)`
  align-items: center;
  max-width: 700px;
  ${AutoHorizontalMargins}
  overflow-x: hidden;
  ${ScrollStyle}
  ${DynamicColumnHeightStyle}
`

// Making sure the width of the overview container matches the width of the boxes below
const horizontalPadding = 10

export const PracticeOverviewContainer = styled.div`
  ${RowFlexStyle}
  width: calc(100% - ${horizontalPadding * 2}px);
  justify-content: space-between;
  border: 2px dotted ${({ theme }) => theme.color.muiLightGrey};
  border-radius: 10px;
  padding: 15px ${horizontalPadding}px;
`

export const PracticeAvatarAndTextContainer = styled.div`
  ${RowFlexStyle}
  ${IntrasectionalGap}
`

export const PracticeAvatarContainer = styled.div`
  ${AvatarFrameStyle}
  ${RowFlexStyle}
  align-items: center;
`

export const PracticeAvatar = styled.img`
  height: ${(props) => (props.ismobilescreen ? '60px' : '80px')};
`

export const OverviewTextFontSizeStyle = css`
  font-size: ${({ theme }) => theme.typography.largeInputTextFontSize};
`

export const CongratsText = styled.span`
  color: ${({ theme }) => theme.color.primary};
`

export const PercentCompleteContainer = styled.div`
  ${ColumnFlexStyle}
  justify-content: flex-end;
  align-items: end;
`

export const PercentCompleteParagraphText = styled(GreyLargeInputText)`
  text-align: ${(props) => props.ismobilescreen && 'end'};
  margin-top: 10px;
`

export const EditBlockContainer = styled.div`
  ${ColumnFlexStyle}
  ${IntersectionalGap}
  width: 100%;
`

export const PracticeOverviewTextContainer = styled.div`
  ${ColumnFlexStyle}
  justify-content: flex-start;
  align-items: flex-start;
  ${IntrasectionalGap}
`

export const EditBlock = styled.div`
  ${ColumnFlexStyle}
  ${InterSubsectionalGap}
`

export const EditBlockTitleTextContainer = styled.div`
  ${RowFlexStyle}
  justify-content: flex-start;
`

export const EditBlockTitleText = styled(SubtitleText)`
  color: ${({ theme }) => theme.color.black};
`

export const ListingTypeContainer = styled.div`
  ${ColumnFlexStyle}
  ${MicroGap}
  text-wrap: wrap;
`

export const ListingTypeTitleText = styled(BlackLargeInputText)`
  font-weight: bolder;
`

export const ListingTypeSubtitleText = styled(GreyParagraphText)``

export const DropdownForm = styled(CreatePracticeForm)`
  width: 100%;
`

export const InfoSectionsContainer = styled.div`
  ${ColumnFlexStyle}
  ${IntrasectionalGap}
`

export const InfoSectionContainer = styled.div`
  ${ColumnFlexStyle}
`

export const InfoSectionButton = styled(SecondaryButton)`
  border: 1px solid ${({ theme }) => theme.color.muiLightGrey};
  ${SecondaryButtonLightHoverStyle}
  ${RowFlexStyle}
  justify-content: space-between;
  font-size: ${({ theme }) => theme.typography.largeInputTextFontSize};
`

export const LeftSectionContentContainer = styled.div`
  ${RowFlexStyle}
  ${IntraSubsectionalGap}
`

export const RightSectionContentContainer = styled.div`
  ${RowFlexStyle}
  ${IntraSubsectionalGap}
`

export const SectionCompletionStatusText = styled.div`
  font-size: ${({ theme }) => theme.typography.largeInputTextFontSize};
  color: ${(props) =>
    props.completed ? props.theme.color.brightGreen : props.theme.color.red};
`

export const ExpandedSection = styled.div`
  ${ColumnFlexStyle}
  border: 2px dotted ${({ theme }) => theme.color.muiLightGrey};
  margin-top: -10px;
  width: calc(100% - 12px);
  ${AutoHorizontalMargins}
  padding: 10px 0px;
  border-radius: 0px 0px 10px 10px;
  ${(props) =>
    props.ismobilescreen &&
    css`
      ${MicroGap}
    `};
`

export const EditInfoButton = styled(TextButtonWithPrimaryColor)`
  width: fit-content;
  ${AutoHorizontalMargins}
`

export const MenuItemDividerWithBottomMargin = styled(MenuItemDivider)`
  margin-bottom: 10px;
`

export const LeaveToEditIcon = styled(ReplyIcon)`
  margin-left: 7px;
  color: ${({ theme }) => theme.color.primary};
`

export const ExpandedFieldsContainer = styled.div`
  ${ColumnFlexStyle}
  ${IntraSubsectionalGap}
`

export const ExpandedFieldContainer = styled.div`
  ${ColumnFlexStyle}
  padding: 0px 15px;
`

export const ExpandedFieldLabel = styled(BlackParagraphText)``

export const ExpandedFieldValue = styled(ParagraphText)``

export const ExpandedPicturesContainer = styled.div`
  ${(props) =>
    props.includesThumbnails &&
    css`
      margin-top: 10px;
    `};
  ${RowFlexStyle}
  ${IntraSubsectionalGap}
`
