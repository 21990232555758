import gql from 'graphql-tag'
import { ListingFragment } from '../fragments/practices'

export const CREATE_BUYER = gql`
  mutation createBuyer($type: Int) {
    createBuyer(type: $type) {
      id
      type
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_INSTITUTIONAL_BUYERS = gql`
  mutation updateBuyer(
    $id: String!
    $company_name: String
    $num_practices_under_management: Int
    $years_in_business: Int
    $location: String
    $website_link: String
    $gross_revenue: Int
    $years_to_work_post_sale: String
    $pre_approved_financing: String
    $percentage_to_buy: String
    $buying_conditions: String
    $purchase_price: String
  ) {
    updateBuyer(
      id: $id
      company_name: $company_name
      num_practices_under_management: $num_practices_under_management
      years_in_business: $years_in_business
      location: $location
      website_link: $website_link
      gross_revenue: $gross_revenue
      years_to_work_post_sale: $years_to_work_post_sale
      pre_approved_financing: $pre_approved_financing
      percentage_to_buy: $percentage_to_buy
      buying_conditions: $buying_conditions
      purchase_price: $purchase_price
    ) {
      id
      type
      company_name
      num_practices_under_management
      years_in_business
      location
      website_link
      gross_revenue
      years_to_work_post_sale
      pre_approved_financing
      percentage_to_buy
      buying_conditions
      purchase_price
      picture
      updatedAt
    }
  }
`

export const UPDATE_BUYER = gql`
  mutation updateBuyer(
    $id: String!
    $year_of_graduation: String
    $average_monthly_production: String
    $school_of_graduation: String
    $no_of_practices: String
    $languages: String
    $procedures: String
    $years_to_work_post_sale: String
    $pre_approved_financing: String
    $percentage_to_buy: String
    $buying_conditions: String
    $purchase_price: String
  ) {
    updateBuyer(
      id: $id
      year_of_graduation: $year_of_graduation
      average_monthly_production: $average_monthly_production
      school_of_graduation: $school_of_graduation
      no_of_practices: $no_of_practices
      languages: $languages
      procedures: $procedures
      years_to_work_post_sale: $years_to_work_post_sale
      pre_approved_financing: $pre_approved_financing
      percentage_to_buy: $percentage_to_buy
      buying_conditions: $buying_conditions
      purchase_price: $purchase_price
    ) {
      id
      type
      year_of_graduation
      average_monthly_production
      school_of_graduation
      no_of_practices
      languages
      procedures
      years_to_work_post_sale
      pre_approved_financing
      percentage_to_buy
      buying_conditions
      purchase_price
      picture
      file
      updatedAt
    }
  }
`

export const UPLOAD_BUYER_PROFILE_PICTURE = gql`
  mutation uploadBuyerProfilePicture($id: String!, $picture: Upload!) {
    uploadBuyerProfilePicture(id: $id, picture: $picture)
  }
`

export const UPLOAD_BUYER_FILE = gql`
  mutation uploadBuyerFile($id: String!, $file: Upload!) {
    uploadBuyerFile(id: $id, file: $file)
  }
`

export const UPLOAD_BUYER_PREFINANCING_REPORT = gql`
  mutation uploadBuyerPreFinancingReport($id: String!, $file: Upload!) {
    uploadFile(id: $id, file: $file)
  }
`
