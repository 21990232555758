import styled, { css } from 'styled-components/macro'
import { EditIcon } from '../../../styles/shared-styled-components'

export const EditButtonFrame = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.outlined &&
    css`
      border: 1px solid ${({ theme }) => theme.color.primary};
      border-radius: 30px;
      &:hover {
        background-color: ${({ theme }) => theme.color.darkHover};
        color: ${({ theme }) => theme.color.white};
      }
    `}
  ${(props) =>
    props.fixed &&
    css`
      position: absolute;
      top: ${({ theme }) => theme.spacing.pageLevelHorizontalPadding}px;
      right: ${({ theme }) => theme.spacing.pageLevelHorizontalPadding}px;
    `}  
  padding: 5px;
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.primary};
  cursor: pointer;
  z-index: 10;
`

export const StyledEditIcon = styled(EditIcon)`
  color: inherit;
`
