import { useMutationWithErrorLogging } from '../../../utils/hooks'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Paths from '../../../Paths'
import {
  COMPLETE_ONBOARDING,
  EDIT_LINKEDIN_URL,
} from '../../../api/mutations/users'
import { GET_MY_USER } from '../../../api/queries/users'
import Header from '../../../components/NavBar/Header'
import OnboardingModal from '../../../components/Onboarding/OnboardingModal'
import OnboardingSteps from '../../../components/Onboarding/OnboardingSteps'
import {
  BackgroundContainer,
  ColumnButtonContainer,
  ErrorText,
  FlexDiv,
  PageContainer,
} from '../../../styles/shared-styled-components'
import { checkIfBuyOrSellGoalPresent } from '../../../utils/user'
import {
  Asset,
  AssetContainer,
  BackButton,
  ContinueButton,
  FormTitleText,
  OnboardingForm,
  OnboardingFormContainer,
  OnboardingInputField,
} from '../shared-onboarding-styles'
import { ProfessionalLicensingErrorMessageContainer } from './styled'

export const checkIfIsValidLinkedinUrl = (url) => {
  return /(https?:\/\/(www.)|(www.))?linkedin.com\/(mwlite\/|m\/)?in\/[a-zA-Z0-9_.-]+\/?/.test(
    url,
  )
}

const LinkedinUrl = ({ user, width, ismobilescreen, isIpadSizeOrSmaller }) => {
  const navigate = useNavigate()
  const [editLinkedinUrlMutation, { data: editLinkedinUrlData }] =
    useMutationWithErrorLogging(EDIT_LINKEDIN_URL)
  const [completeOnboardingMutation, { data: completeOnboardingData }] =
    useMutationWithErrorLogging(COMPLETE_ONBOARDING)

  const buyOrSellGoalPresent = checkIfBuyOrSellGoalPresent(user?.goals)

  const [linkedinUrl, setLinkedinUrl] = useState(user?.linkedinUrl)
  const [errorMessage, setErrorMessage] = useState()
  const [showContinueModal, setShowContinueModal] = useState(false)

  const handleLinkedinUrlChange = (e) => {
    if (errorMessage) {
      setErrorMessage()
    }

    const { value: updatedUrl } = e.target
    setLinkedinUrl(updatedUrl)
  }

  const navigateToPreviousOnboardingStep = () => {
    if (buyOrSellGoalPresent) {
      if (user.isLicensedToPractice) {
        navigate(Paths.dentalLicenseInfoOnboarding)
      } else {
        navigate(Paths.isLicensedToPracticeOnboarding)
      }
    } else {
      navigate(Paths.goalsOnboarding)
    }
  }

  const onClickContinue = () => {
    const isValidLinkedinUrl = checkIfIsValidLinkedinUrl(linkedinUrl)

    if (!isValidLinkedinUrl) {
      setErrorMessage('Please enter a valid Linkedin URL.')
    } else {
      editLinkedinUrlMutation({
        variables: {
          linkedinUrl,
        },
      })
    }
  }

  const completeOnboarding = () => {
    completeOnboardingMutation({
      update: async (cache, { data }) => {
        const userDataFetch = cache.readQuery({
          query: GET_MY_USER,
        })

        // Grab the returned hasOnboarded value
        const hasOnboardedResult = data?.completeOnboarding?.hasOnboarded

        // If we have cached user data, update the hasOnboarded field
        if (userDataFetch) {
          // Grab the data in the cache and update hasOnboarded
          const existingUser = userDataFetch.getMyUser
          const updatedUser = {
            ...existingUser,
            hasOnboarded: hasOnboardedResult,
          }

          // Update the cache
          await cache.writeQuery({
            query: GET_MY_USER,
            data: {
              getMyUser: updatedUser,
            },
          })
        }
      },
    })
  }

  useEffect(() => {
    if (editLinkedinUrlData) {
      const { linkedinUrl } = editLinkedinUrlData.editLinkedinUrl

      if (linkedinUrl) {
        setShowContinueModal(true)
      } else {
        setErrorMessage(
          'Unable to save linkedin URL. Please refresh and try again.',
        )
      }
    }
  }, [editLinkedinUrlData])

  return (
    <>
      <Header
        user={user}
        width={width}
        ismobilescreen={isIpadSizeOrSmaller}
        isOnboardingScreen
      />
      <BackgroundContainer>
        <PageContainer>
          <FlexDiv>
            <OnboardingFormContainer ismobilescreen={isIpadSizeOrSmaller}>
              <OnboardingSteps currentStep={4} />
              <OnboardingForm ismobilescreen={ismobilescreen}>
                <FormTitleText ismobilescreen={ismobilescreen}>
                  To become a service provider on our platform, we have to
                  verify your identity.
                </FormTitleText>
                <OnboardingInputField
                  value={linkedinUrl}
                  label='Linkedin URL * '
                  onChange={handleLinkedinUrlChange}
                />
                <ProfessionalLicensingErrorMessageContainer
                  ismobilescreen={ismobilescreen}
                >
                  <ErrorText>{errorMessage}</ErrorText>
                </ProfessionalLicensingErrorMessageContainer>
                {/* Not sure why, but this empty div was needed to properly style the error message top margin */}
                <div />
              </OnboardingForm>
              <ColumnButtonContainer>
                <ContinueButton
                  disabled={!linkedinUrl}
                  onClick={onClickContinue}
                >
                  Save and continue
                </ContinueButton>
                <BackButton onClick={navigateToPreviousOnboardingStep}>
                  Back
                </BackButton>
              </ColumnButtonContainer>
            </OnboardingFormContainer>
            {!isIpadSizeOrSmaller && (
              <AssetContainer>
                <Asset src={Paths.acquireConnectMetrics} />
              </AssetContainer>
            )}
          </FlexDiv>
        </PageContainer>
      </BackgroundContainer>
      <OnboardingModal
        titleText={'Service Provider Application Submitted'}
        contentText={
          <>
            We've received your application to become a registered service
            provider.
            <br></br>
            <br></br>
            We'll reach out once it's been reviewed. In the meantime, explore
            the site!
          </>
        }
        continueText={'Proceed to site'}
        isOpen={showContinueModal}
        onClose={() => setShowContinueModal(false)}
        onContinue={completeOnboarding}
        variant='pending'
      />
    </>
  )
}

export default LinkedinUrl
