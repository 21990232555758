import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import LogoutIcon from '@mui/icons-material/Logout'
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting'
import Person2Icon from '@mui/icons-material/Person2'
import NotificationIcon from '@mui/icons-material/NotificationsOutlined'
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip'
import Menu from '@mui/material/Menu'
import EditNoteIcon from '@mui/icons-material/EditNote'
import ApartmentIcon from '@mui/icons-material/Apartment'
import FolderIcon from '@mui/icons-material/Folder'
import StoreIcon from '@mui/icons-material/Store'
import styled, { css } from 'styled-components/macro'
import {
  AvatarFrameStyle,
  Chip,
  FlexDiv,
  RowFlexStyle,
  StyledMenuItemIconStyle,
  TextButton,
} from '../../../styles/shared-styled-components'

import dentalIcon from './dental.svg'
import accountIcon from './account.svg'

export const ProfileMenuButton = styled(TextButton)`
  font-size: ${({ theme }) => theme.typography.defaultFontSize};
`

export const NotificationIconButton = styled(TextButton)`
  font-size: ${({ theme }) => theme.typography.defaultFontSize};
  margin-right: -20px;

  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 4px;
    right: 28px;
    width: 8px;
    height: 8px;
    background-color: ${({ theme }) => theme.color.red};
    border-radius: 50%;
    display: ${({ unread }) => (unread ? 'block' : 'none')};
  }
`

export const ExpandIcon = styled(KeyboardArrowDownIcon)``

export const ProfileMenu = styled(Menu)`
  color: ${({ theme }) => theme.color.white};
  min-width: 5vw;
  font-family: ${({ theme }) => theme.typography.typeFont};
  top: 10px;
  .MuiMenu-list {
    border: 1px solid ${({ theme }) => theme.color.lightGrey};
    border-radius: 4px;
  }
`

export const ProfileIconInDropdown = styled(Person2Icon)`
  ${StyledMenuItemIconStyle}
`

export const ProfileIconInHeader = styled(Person2Icon)`
  color: ${({ theme }) => theme.color.white};
  margin-right: 10px;
`

export const NotificationIconInHeader = styled(NotificationIcon)`
  color: ${({ theme }) => theme.color.white};
`

export const PrivacyPolicyIcon = styled(PrivacyTipIcon)`
  ${StyledMenuItemIconStyle}
`

export const ValuationAdminPanelIcon = styled(PermDataSettingIcon)`
  ${StyledMenuItemIconStyle}
`

export const ProfileLogoutIcon = styled(LogoutIcon)`
  ${StyledMenuItemIconStyle}
`

export const PracticeAdminPanelIcon = styled(EditNoteIcon)`
  ${StyledMenuItemIconStyle}
`

export const PracticeIcon = styled(ApartmentIcon)`
  ${StyledMenuItemIconStyle}
`

export const BuyIcon = styled(StoreIcon)`
  ${StyledMenuItemIconStyle}
`

export const folderIcon = styled(FolderIcon)``

export const NotificationsContainer = styled.div`
  border-radius: 11px;
  border: 1px solid #016299;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 19px;
  width: 486px;
  min-height: 200px;
  max-height: 358px;
  overflow: auto;
  flex-shrink: 0;

  @media (max-width: 768px) {
    max-width: 90vw;
    margin-auto;
  }
`
export const NotifWrapperHeaderText = styled.h1`
  color: ${({ theme }) => theme.color.black};
  font-family: Noto Sans KR;
  font-size: ${({ theme }) => theme.typography.sentenceLargeFontSize};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.1px;
  margin: 0px;
`

export const NotifChipsWrapper = styled(FlexDiv)`
  flex-direction: row;
  gap: 5px;
  margin-top: 16px;
`

export const NotifChips = styled(Chip)`
  border: 0px;
  min-width: 50px;
  border-radius: 16.5px;
  padding: 0px 5px;
  background-color: transparent;
  margin-bottom: 17px;

  ${(props) =>
    props?.active &&
    css`
      background-color: ${({ theme }) => theme.color.primaryWithTransparency};
    `}

  .MuiChip-root {
    max-width: 150px !important;
  }
  .MuiChip-label {
    color: ${({ theme }) => theme.color.black};
    overflow: visible;
    padding: 7px;
    background: transparent;
    font-family: ${({ theme }) => theme.typography.typeFont};
    font-size: ${({ theme }) => theme.typography.largeInputTextFontSize};
  }
  &:hover {
    transform: none;
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.primaryWithTransparency};
  }
`
export const NotificationText = styled.div`
  color: ${({ theme }) => theme.color.black};
  font-family: ${({ theme }) => theme.typography.typeFont};
  font-size: ${({ theme }) => theme.typography.sentenceFontSize};
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.1px;
  margin: 0px;
  flex-wrap: wrap;
`

export const NotificationSectionText = styled(NotificationText)`
  text-transform: capitalize;
  font-size: ${({ theme }) => theme.typography.largeInputTextFontSize};
  margin-bottom: 13px;
`

export const NotificationListItem = styled.div`
  ${RowFlexStyle}
  align-items: center;
  padding: 16px 10px;
  gap: 21px;
  margin-bottom: 10px;
  overflow: hidden;
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) =>
      theme.color.mediumPrimaryWithTransparency};
  }
  ${(props) =>
    props.isActive &&
    css`
      background-color: ${({ theme }) => theme.color.primaryWithTransparency};
    `}
`

export const NotificationListItemAvatar = styled.div`
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  ${(props) =>
    props.variant === 'ACCOUNT_CREATED'
      ? css`
          background-image: url(${accountIcon});
        `
      : css`
          background-image: url(${dentalIcon});
        `}
`
