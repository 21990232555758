import styled from 'styled-components/macro'
import { assumedDistanceFromTopOfPageToTopOfMap } from '../PracticePreviewThumbnail/PracticePreviewThumbnail'

export const MapContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

export const MapComponent = styled.div`
  height: 100%;
`
