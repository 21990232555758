import { useMutationWithErrorLogging } from '../utils/hooks'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMyUser } from '../api/hooks/users'
import { MARK_NOTIFICATION_AS_READ } from '../api/mutations/notifications'
import { NotificationType } from '../utils/constants'
import Paths from '../Paths'

const InternalLinkHandler = () => {
  const location = useLocation()
  const notification = location?.state?.notification
  const [isReadComplete, setIsReadComplete] = useState(false)
  const { user } = useMyUser()
  const navigate = useNavigate()

  const [markNotificationAsRead] = useMutationWithErrorLogging(
    MARK_NOTIFICATION_AS_READ,
  )

  useEffect(() => {
    if (notification && !isReadComplete) {
      markNotificationAsRead({
        variables: {
          id: notification.id,
        },
        onCompleted: (data) => {
          console.log(data)
          setIsReadComplete(true)
        },
        onError: (error) => {
          console.log(error)
          setIsReadComplete(true)
        },
      })
    }
  }, [notification, isReadComplete])

  if (!notification) {
    return <div>Loading...</div>
  }

  if (!isReadComplete) {
    return <div>Loading...</div>
  }

  // now that the notification has been marked as read, we can redirect to the correct page
  switch (notification.notificationType) {
    case NotificationType.DATAROOM_ACCESS_REQUEST_SUBMITTED: {
      const practiceId = notification.notificationMetadata.practiceId
      navigate(Paths.myOffers.replace(':practiceId', practiceId))
      return null
    }
    case NotificationType.DATAROOM_ACCESS_REQUEST_APPROVED: {
      const buyerId = user.id
      navigate(Paths.myAcquisition.replace(':buyerId', buyerId))
      return null
    }
    case NotificationType.PURCHASE_INTENT_SUBMITTED: {
      const practiceId = notification.notificationMetadata.practiceId
      navigate(Paths.myOffersPurchaseIntent.replace(':practiceId', practiceId))
      return null
    }
    case NotificationType.PURCHASE_INTENT_APPROVED: {
      const buyerId = user.id
      navigate(Paths.myAcquisitionPurchaseIntent.replace(':buyerId', buyerId))
      return null
    }
    default:
      throw new Error('unknown notification type')
  }
}

export default InternalLinkHandler
