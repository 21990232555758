import styled from 'styled-components/macro'
import { PracticeColorBasedOnListingStatusStyle } from '../../../styles/shared-styled-components'

export const ListingStatusChip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  width: fit-content;
  padding: 3px;
  border: 1px solid ${({ theme }) => theme.color.muiLightGrey};
  border-radius: ${({ theme }) => `${theme.spacing.mediumBorderRadius}px`};
  font-family: ${({ theme }) => theme.typography.typeFont};
  font-size: ${({ theme }) => theme.typography.sentenceFontSize};
  // Just to align the chip with the text, which naturally has some top padding
  ${PracticeColorBasedOnListingStatusStyle}
`
