import React, { useEffect, useRef, useState } from 'react'
import Paths from '../../Paths'
import { useMyPractices } from '../../api/hooks/practices'
import LoadingIndicator from '../../components/LoadingIndicator'
import BrowseListingsButton from '../../components/Micro/BrowseListingsButton'
import CompleteValuationButton from '../../components/Micro/CompleteValuationButton'
import CreatePracticeButton from '../../components/Micro/CreatePracticeButton'
import PublishPracticeListingButton from '../../components/Micro/PublishPracticeListingButton'
import ViewOffersButton from '../../components/Micro/ViewOffersButton'
import ViewPracticeButton from '../../components/Micro/ViewPracticeButton'
import MyPracticeCard from '../../components/MyPracticeCard'
import Header from '../../components/NavBar/Header'
import Snackbar from '../../components/Snackbar'
import {
  BackgroundContainer,
  FullPageContentContainer,
  FullPageContentContainerWithLargeGap,
  PageContainer,
} from '../../styles/shared-styled-components'
import {
  forSaleStageKey,
  getPracticeListingStatus,
  incompleteStatusKey,
  offMarketStageKey,
  readyToListStatusKey,
  underReviewStatusKey,
} from '../../utils/constants'
import { getEligiblePracticeListingStatuses } from '../../utils/data'
import { getPracticeCompletionPercentage } from '../../utils/numbers'
import { screenWidthIsSmallMobileSizeOrSmaller } from '../../utils/view'
import {
  BrainIcon,
  BrowseListingsContainer,
  BrowseListingsSubtitleText,
  BrowseListingsTitleText,
  CardButtonContainer,
  CardSubtitleText,
  CardTextContainer,
  CardTitleText,
  DatasetIcon,
  DentacloudNetworkContainer,
  DentacloudNetworkTitleText,
  HeaderContainer,
  HeaderContentContainer,
  HeaderImage,
  HeaderImageContainer,
  HeaderTitleText,
  ListingLiveIcon,
  MirrorIcon,
  MoneyIcon,
  MyPracticesTitleSection,
  MyPracticesTitleText,
  PencilIcon,
  PostListingIcon,
  PowerContainer,
  PowerDescriptionText,
  PowersContainer,
  PracticeContainer,
  PracticesSection,
  SoldIcon,
  TopCard,
  UnderReviewIcon,
} from './styled'

const MyValuations = ({ user, width, ismobilescreen }) => {
  const { practices, loading, refetch } = useMyPractices()

  const dentacloudNetworkContainerDivRef = useRef(null)
  const [
    remainingDentacloudNetworkContainerHeight,
    setRemainingDentacloudNetworkContainerHeight,
  ] = useState(0)
  const [cardNavigationIndex, setCardNavigationIndex] = useState(0)
  const [snackbarMessage, setSnackbarMessage] = useState('')

  // Setting the remaining height - need to watch the practices variable,
  // as the page rerenders after that
  useEffect(() => {
    const handleResize = () => {
      const windowHeight = window.innerHeight

      // Update dentacloud network container height
      const dentacloudNetworkContainerDivHeight =
        dentacloudNetworkContainerDivRef?.current?.getBoundingClientRect().top
      const dentacloudNetworkContainerHeightDifference =
        windowHeight - dentacloudNetworkContainerDivHeight
      setRemainingDentacloudNetworkContainerHeight(
        dentacloudNetworkContainerHeightDifference,
      )
    }

    window.addEventListener('resize', handleResize)
    // Call it initially to get the remaining height
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [practices])

  const onDeletePractice = () => {
    refetch()
    setSnackbarMessage('Successfully deleted practice')
  }

  let practiceToShowcase = null

  // Find first incomplete practice listing
  const incompletePracticeListing = practices?.find(
    (p) => p.completedAt == null,
  )

  if (incompletePracticeListing) {
    practiceToShowcase = incompletePracticeListing
  } else {
    // If we have none that are incomplete, the first one would be the most recently listed practice, so we can just grab that
    practiceToShowcase = practices[0]
  }

  const completionPercentage =
    getPracticeCompletionPercentage(practiceToShowcase)

  const eligibleListingStatuses =
    getEligiblePracticeListingStatuses(practiceToShowcase)
  const canListPracticeToShowcase = eligibleListingStatuses.length > 0

  const practiceToShowcaseListingStatus = getPracticeListingStatus(
    practiceToShowcase,
    canListPracticeToShowcase,
  )

  const cardContent =
    practiceToShowcaseListingStatus === incompleteStatusKey
      ? {
          icon: <PencilIcon />,
          title: `Your listing is ${completionPercentage}% complete`,
          subtitle: 'Complete your listing to engage with buyers.',
          button: <CompleteValuationButton practice={practiceToShowcase} />,
        }
      : practiceToShowcaseListingStatus === readyToListStatusKey
        ? eligibleListingStatuses.includes(forSaleStageKey)
          ? {
              icon: <PostListingIcon />,
              title: 'Your practice is now ready to be listed for sale!',
              subtitle: 'Post your listing to engage with buyers.',
              button: (
                <PublishPracticeListingButton
                  practiceId={practiceToShowcase?.id}
                />
              ),
            }
          : {
              icon: <PostListingIcon />,
              title: (
                <>
                  Your practice is now ready to be listed off-market! <br></br>(
                  {completionPercentage}% complete)
                </>
              ),
              subtitle: 'Post your listing to engage with buyers.',
              button: (
                <PublishPracticeListingButton
                  practiceId={practiceToShowcase?.id}
                />
              ),
            }
        : practiceToShowcaseListingStatus === underReviewStatusKey
          ? {
              icon: <UnderReviewIcon />,
              title:
                "Your listing is under review - we'll let you know when it's live",
              subtitle: 'The typical review process takes 24-48 hours.',
              button: (
                <ViewPracticeButton practiceId={practiceToShowcase?.id} />
              ),
            }
          : practiceToShowcaseListingStatus === offMarketStageKey
            ? {
                icon: <ListingLiveIcon />,
                title: 'Your practice is listed off-market.',
                subtitle: 'Engage with buyers to receive your target price',
                button: (
                  <ViewOffersButton
                    text='Engage with buyers'
                    buttonType='bright'
                  />
                ),
              }
            : practiceToShowcaseListingStatus === forSaleStageKey
              ? {
                  icon: <ListingLiveIcon />,
                  title: 'Your practice is listed for sale.',
                  subtitle: 'Engage with buyers to receive your target price',
                  button: (
                    <ViewOffersButton
                      text='Engage with buyers'
                      buttonType='bright'
                    />
                  ),
                }
              : {
                  icon: <SoldIcon />,
                  title: 'Your practice has been sold. Congrats!',
                  subtitle: 'Complete your listing to engage with buyers',
                  button: <ViewOffersButton buttonType='bright' />,
                }

  const networkSectionsWillWrap = width < 980
  const topCardsShouldFold = width < 621
  const topCards = [
    <TopCard isFolded={topCardsShouldFold}>
      {cardContent['icon']}
      <CardTextContainer>
        <CardTitleText>{cardContent['title']}</CardTitleText>
        <CardSubtitleText>{cardContent['subtitle']}</CardSubtitleText>
      </CardTextContainer>
      <CardButtonContainer>{cardContent['button']}</CardButtonContainer>
    </TopCard>,
    <TopCard isFolded={topCardsShouldFold}>
      <MirrorIcon />
      <CardTextContainer>
        <CardTitleText>See how you compare</CardTitleText>
        <CardSubtitleText>
          Check out the current listings on our marketplace.
        </CardSubtitleText>
      </CardTextContainer>
      <CardButtonContainer>
        <BrowseListingsButton />
      </CardButtonContainer>
    </TopCard>,
  ]

  const isSmallMobileScreen = screenWidthIsSmallMobileSizeOrSmaller(width)

  return practices != null && !loading ? (
    <>
      <Header user={user} width={width} ismobilescreen={ismobilescreen} />
      <BackgroundContainer>
        <PageContainer>
          {practices.length === 0 ? (
            <FullPageContentContainer>
              <HeaderContainer>
                <HeaderContentContainer ismobilescreen={ismobilescreen}>
                  <HeaderTitleText ismobilescreen={isSmallMobileScreen}>
                    Value your practice with confidence
                  </HeaderTitleText>
                  <CreatePracticeButton
                    user={user}
                    text='Value your practice'
                    allowShrinking={isSmallMobileScreen}
                    inValuationMode
                  />
                </HeaderContentContainer>
                <HeaderImageContainer>
                  <HeaderImage
                    src={Paths.handshakeCartoon}
                    ismobilescreen={ismobilescreen}
                    issmallmobilescreen={isSmallMobileScreen}
                  />
                </HeaderImageContainer>
              </HeaderContainer>
              <DentacloudNetworkContainer
                ismobilescreen={networkSectionsWillWrap}
                ref={dentacloudNetworkContainerDivRef}
                remainingHeight={remainingDentacloudNetworkContainerHeight}
              >
                <DentacloudNetworkTitleText>
                  The power of Dentacloud AI
                </DentacloudNetworkTitleText>
                <PowersContainer>
                  <PowerContainer>
                    <DatasetIcon />
                    <PowerDescriptionText ismobilescreen={ismobilescreen}>
                      Trained on high-quality, verified data
                    </PowerDescriptionText>
                  </PowerContainer>
                  <PowerContainer>
                    <BrainIcon />
                    <PowerDescriptionText ismobilescreen={ismobilescreen}>
                      Built by experts of the dental M&A industry
                    </PowerDescriptionText>
                  </PowerContainer>
                  <PowerContainer>
                    <MoneyIcon />
                    <PowerDescriptionText ismobilescreen={ismobilescreen}>
                      Used in brokering over $300M worth of transactions
                    </PowerDescriptionText>
                  </PowerContainer>
                </PowersContainer>
              </DentacloudNetworkContainer>
              <BrowseListingsContainer ismobilescreen={networkSectionsWillWrap}>
                <BrowseListingsTitleText>
                  Want to better understand the market?
                </BrowseListingsTitleText>
                <BrowseListingsSubtitleText>
                  Check out our dataset of sold practices.
                </BrowseListingsSubtitleText>
                <BrowseListingsButton text='Browse sold data' />
              </BrowseListingsContainer>
            </FullPageContentContainer>
          ) : (
            <FullPageContentContainerWithLargeGap style={{ marginTop: 20 }}>
              <PracticesSection>
                <MyPracticesTitleSection>
                  <MyPracticesTitleText>My Valuations</MyPracticesTitleText>
                  <CreatePracticeButton
                    user={user}
                    text={`Add new ${!ismobilescreen ? 'practice' : ''}`}
                    buttonType='secondary'
                    includeIcon
                    allowShrinking
                  />
                </MyPracticesTitleSection>
                <PracticeContainer>
                  {practices.map((p) => (
                    <MyPracticeCard
                      ismobilescreen={ismobilescreen}
                      practice={p}
                      width={width}
                      onDelete={onDeletePractice}
                      inValuationMode
                    />
                  ))}
                </PracticeContainer>
              </PracticesSection>
            </FullPageContentContainerWithLargeGap>
          )}
        </PageContainer>
      </BackgroundContainer>
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
    </>
  ) : (
    <LoadingIndicator fullScreen />
  )
}

export default MyValuations
