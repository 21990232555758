import { useApolloClient } from '@apollo/client'
import { DoneAll } from '@mui/icons-material'
import _ from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useBuyers } from '../../api/hooks/buyers'
import SignNdaModal from '../../components/NDA'
import {
  usePracticeById,
  usePracticeDataroom,
  usePracticePurchaseIntent,
} from '../../api/hooks/practices'
import { useMyUser } from '../../api/hooks/users'
import { CreatePracticeDropdownLabel } from '../../components/CreatePractice/CreatePracticeInput/styled'
import LoadingIndicator from '../../components/LoadingIndicator'
import BackButton from '../../components/Micro/BackButton'
import BuyerProfile from '../../components/Micro/BuyerProfile'
import EditSectionButton from '../../components/Micro/EditSectionButton'
import LockIcon from '../../components/Micro/LockIcon'
import { StyledLockIcon } from '../../components/Micro/LockIcon/styled'
import NDAModal from '../../components/Micro/MyAcquisition/NDAModal'
import PracticeListingStatusChip from '../../components/Micro/PracticeListingStatusChip/PracticeListingStatusChip'
import Header from '../../components/NavBar/Header'
import PracticePicturesCarousel from '../../components/PracticePicturesCarousel/PracticePicturesCarousel'
import {
  BackgroundContainer,
  FullPageContentContainer,
  PageContainer,
  StyledMenuItem,
} from '../../styles/shared-styled-components'
import {
  acquisitionRequestsStatuses,
  activeListingSaleStages,
  basicAccessTierKey,
  editingFlowKey,
  getLabelFromKey,
  getPracticeListingStatus,
  picturesSectionKey,
  practiceOwnerAccessTierKey,
  practiceTypes,
  practiceUserAccessTiers,
  practiceUserAccessTiersSortedByHierarchy,
  premiumAccessTierKey,
  privilegedUserAccessTiers,
  userRoles,
} from '../../utils/constants'
import {
  getEligiblePracticeListingStatuses,
  getExpandedPracticeFieldsBySection,
} from '../../utils/data'
import { dateToHumanReadableFormat } from '../../utils/dates'
import { defaultPracticeName } from '../../utils/naming'
import {
  formatNumberAbbreviation,
  getFormattedMoneyString,
} from '../../utils/numbers'
import {
  navigateToCreatePracticeSectionToEditInEditingFlow,
  navigateToDueDiligenceSectionToEdit,
} from '../../utils/routing'
import {
  DueDiligenceBox,
  DueDiligenceButton,
  DueDiligenceContainer,
  DueDiligenceTitleText,
  ExitViewAsContainer,
  ExitViewAsText,
  FieldSection,
  FieldTitleText,
  FieldValueText,
  FieldsSection,
  GetRequestAccessButton,
  PracticeBox,
  PracticeBoxContainer,
  PracticeDateText,
  PracticeHeaderSections,
  PracticeInfoSection,
  PracticeInfoSectionNameContainer,
  PracticeInfoSectionTitleText,
  PracticeInfoSections,
  PracticeInfoText,
  PracticeOverviewContainer,
  PracticeSubtextContainer,
  PracticeTitleAndBadgeContainer,
  PracticeTitleText,
  PracticeWebsiteContainer,
  PracticeWebsiteText,
  StyledDropdown,
  TopBarTitleText,
  TopBarTitleTextContainer,
  ValuationBox,
  ValuationTextField,
  ViewAsDropdownForm,
  ViewAsTopBar,
  ViewAsTopBarTopContainer,
} from './styled'

const practiceSections = [
  {
    title: 'Overview',
    key: 'overview',
  },
  {
    title: 'Team',
    key: 'team',
  },
  {
    title: 'Real Estate',
    key: 'lease',
  },
  {
    title: 'Their Terms',
    key: 'terms',
  },
  // {
  //   title: 'Financials',
  //   key: 'financials',
  // },
]

function hasAtleastOneCompleteProfile(buyers) {
  let complete = false
  buyers.forEach((buyer) => {
    if (
      buyer.year_of_graduation > 0 &&
      buyer.average_monthly_production > 0 &&
      buyer.language !== null &&
      buyer.procedures !== null &&
      buyer.no_of_practices &&
      buyer.file !== null
    ) {
      complete = true
    }
  })
  return complete
}

const ViewPractice = ({ user, width, ismobilescreen }) => {
  const { isAuthenticated, loginWithRedirect } = useMyUser().auth0Context
  const navigate = useNavigate()

  const { practiceId } = useParams()
  const {
    practice,
    error: practiceError,
    loading: loadingPractice,
  } = usePracticeById(practiceId)
  const { buyers, loading: loadingBuyers } = useBuyers()
  const {
    dataroom,
    error: dataroomError,
    loading: loadingDataroom,
  } = usePracticeDataroom(practiceId)
  const { purchaseIntent, loading: loadingPurchaseIntent } =
    usePracticePurchaseIntent(practiceId)

  const [viewAsUserType, setViewAsUserType] = useState('')
  const [showPopup, setShowPopup] = useState(false)
  const [showNdaPopup, setShowNdaPopup] = useState(false)

  const isOwnPractice = practice?.createdBy === user?.id

  const listingType = practice?.listing?.type
  const practiceInListingState = !!listingType

  const name = practice?.name || defaultPracticeName
  const eligibleListingStatuses = getEligiblePracticeListingStatuses(practice)
  const ableToBeListed = eligibleListingStatuses.length > 0
  const status = getPracticeListingStatus(practice, ableToBeListed)

  const practiceSectionFields = getExpandedPracticeFieldsBySection(practice)

  const viewingAs = !!viewAsUserType

  // TODO add logic here to check if they have dataroom/purchase intent access
  const currentViewerUserType = viewingAs
    ? viewAsUserType
    : isOwnPractice
      ? practiceOwnerAccessTierKey
      : user
        ? premiumAccessTierKey
        : basicAccessTierKey

  const currentViewerUserTypeHierarchyRanking =
    practiceUserAccessTiersSortedByHierarchy[currentViewerUserType]

  const willDisplayOnlyOneColumn = width < 1225

  const goToLoginPage = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: window.location.href,
      },
      authorizationParams: {
        screen_hint: 'signin',
        utm_affiliate: localStorage.getItem('utm_affiliate'),
      },
    })
  }

  const handleRequestPressed = useCallback(() => {
    if (!isAuthenticated) {
      goToLoginPage()
    } else if (buyers.length > 0 && hasAtleastOneCompleteProfile(buyers)) {
      setShowNdaPopup(true)
    } else {
      setShowPopup(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyers, practiceId, isAuthenticated])

  const hasDataroomAccess = useMemo(() => {
    if (user?.role === userRoles.staff) {
      return true
    }
    if (isOwnPractice) {
      return privilegedUserAccessTiers.includes(currentViewerUserType)
    } else {
      return dataroom && !dataroomError
    }
  }, [dataroom, dataroomError, isOwnPractice, currentViewerUserType, user])

  const openDataroomPage = useCallback(() => {
    if (isOwnPractice) {
      const dataroomLink = `/dataroom/${practiceId}/${user?.id}`
      navigate(dataroomLink)
    }
    if (_.isEmpty(buyers) || !hasDataroomAccess) return handleRequestPressed()
    const dataroomLink = `/dataroom/${practiceId}/${buyers[0].id}`
    navigate(dataroomLink)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practiceId, buyers, hasDataroomAccess])

  const handlePurchaseIntent = useCallback(() => {
    const purchaseIntentLink = `/buyers/${
      buyers && buyers[buyers.length - 1].id
    }/purchase-intent?practice_id=${practiceId}`
    navigate(purchaseIntentLink)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practiceId, buyers])

  const isLoading = useMemo(() => {
    return (
      loadingBuyers ||
      loadingDataroom ||
      loadingPractice ||
      loadingPurchaseIntent
    )
  }, [loadingBuyers, loadingDataroom, loadingPractice, loadingPurchaseIntent])

  if (!isLoading && !practice) {
    console.log(practice, practiceError, loadingPractice)
    return 'Practice not found'
  }

  return !isLoading && practice ? (
    <>
      <Header user={user} width={width} ismobilescreen={ismobilescreen} />
      <BackgroundContainer>
        <PageContainer>
          <FullPageContentContainer>
            <SignNdaModal isOpen={user && !user?.signedNda} user={user} />
            {isOwnPractice && (
              <ViewAsTopBar viewingAs={viewingAs}>
                <ViewAsTopBarTopContainer>
                  <TopBarTitleTextContainer>
                    <TopBarTitleText
                      viewingAs={viewingAs}
                      ismobilescreen={ismobilescreen}
                    >
                      {viewingAs ? (
                        <>
                          Viewing as a{' '}
                          {getLabelFromKey(
                            practiceUserAccessTiers,
                            viewAsUserType,
                          )}
                        </>
                      ) : (
                        'My Practice'
                      )}
                    </TopBarTitleText>
                  </TopBarTitleTextContainer>
                  <ViewAsDropdownForm
                    ismobilescreen={ismobilescreen}
                    width={width}
                    viewingAs={viewingAs}
                  >
                    <CreatePracticeDropdownLabel
                      ismobilescreen={ismobilescreen}
                    >
                      View as a buyer
                    </CreatePracticeDropdownLabel>
                    <StyledDropdown
                      value={viewAsUserType}
                      ismobilescreen={ismobilescreen}
                      onChange={(e) => setViewAsUserType(e.target.value)}
                      label='View as a buyer'
                    >
                      {practiceUserAccessTiers.map((userType) => {
                        const key = userType['key']
                        const label = userType['label']
                        return (
                          <StyledMenuItem value={key} key={key}>
                            {label}
                          </StyledMenuItem>
                        )
                      })}
                    </StyledDropdown>
                  </ViewAsDropdownForm>
                </ViewAsTopBarTopContainer>
                {viewingAs && (
                  <ExitViewAsContainer>
                    <ExitViewAsText
                      ismobilescreen={ismobilescreen}
                      onClick={() => setViewAsUserType('')}
                    >
                      Exit 'View As Buyer' Mode
                    </ExitViewAsText>
                  </ExitViewAsContainer>
                )}
              </ViewAsTopBar>
            )}
            <PracticePicturesCarousel
              hasDataroomAccess={hasDataroomAccess}
              pictures={practice?.pictures}
              practiceId={practiceId}
              currentViewerUserType={currentViewerUserType}
              handleRequestPressed={handleRequestPressed}
            />
            <PracticeBoxContainer>
              <PracticeHeaderSections>
                <PracticeInfoText>
                  <PracticeTitleAndBadgeContainer>
                    <PracticeTitleText ismobilescreen={ismobilescreen}>
                      {hasDataroomAccess
                        ? name
                        : !!practice?.city
                          ? `${getLabelFromKey(
                              practiceTypes,
                              practice?.type,
                              'abbreviated',
                            )} Practice in ${practice.city} `
                          : 'Practice Info'}
                    </PracticeTitleText>
                    {isOwnPractice && !viewingAs ? (
                      <>
                        <PracticeListingStatusChip status={status} />
                        {isOwnPractice &&
                          activeListingSaleStages.includes(status) && (
                            <EditSectionButton
                              onClick={() =>
                                navigateToCreatePracticeSectionToEditInEditingFlow(
                                  'overview',
                                  practiceId,
                                  navigate,
                                )
                              }
                            />
                          )}
                      </>
                    ) : practiceInListingState ? (
                      <PracticeListingStatusChip status={listingType} />
                    ) : (
                      <></>
                    )}
                  </PracticeTitleAndBadgeContainer>

                  <PracticeSubtextContainer>
                    <PracticeWebsiteContainer>
                      <FieldTitleText ismobilescreen={ismobilescreen}>
                        Website
                      </FieldTitleText>
                      <FieldValueText ismobilescreen={ismobilescreen}>
                        {hasDataroomAccess ? (
                          <PracticeWebsiteText
                            href={practice.website}
                            target='_blank'
                            rel='noreferrer'
                          >
                            {practice.website ?? 'N/A'}
                          </PracticeWebsiteText>
                        ) : (
                          <LockIcon onClick={handleRequestPressed} size='sm' />
                        )}
                      </FieldValueText>
                      <FieldValueText ismobilescreen={ismobilescreen}>
                        {isOwnPractice &&
                          currentViewerUserType ===
                            practiceOwnerAccessTierKey && (
                            <EditSectionButton
                              outlined={true}
                              size={'small'}
                              onClick={() =>
                                navigateToDueDiligenceSectionToEdit(
                                  picturesSectionKey,
                                  practiceId,
                                  navigate,
                                  editingFlowKey,
                                )
                              }
                            />
                          )}
                      </FieldValueText>
                    </PracticeWebsiteContainer>
                  </PracticeSubtextContainer>

                  <PracticeSubtextContainer>
                    <PracticeDateText>
                      {practiceInListingState
                        ? `Posted: ${dateToHumanReadableFormat(
                            practice?.listing?.createdAt,
                          )}`
                        : `Created ${dateToHumanReadableFormat(
                            practice?.createdAt,
                          )}`}
                    </PracticeDateText>
                  </PracticeSubtextContainer>
                </PracticeInfoText>

                {hasDataroomAccess ? (
                  <FieldSection>
                    <DueDiligenceButton onClick={openDataroomPage}>
                      Enter dataroom
                    </DueDiligenceButton>
                    {!isOwnPractice && (
                      <>
                        {!purchaseIntent ? (
                          <GetRequestAccessButton
                            onClick={handlePurchaseIntent}
                          >
                            Submit Purchase Intent
                          </GetRequestAccessButton>
                        ) : (
                          (purchaseIntent.status ===
                            acquisitionRequestsStatuses.ACCEPTED ||
                            purchaseIntent.status ===
                              acquisitionRequestsStatuses.APPROVED) && (
                            <GetRequestAccessButton success>
                              Purchase intent accepted
                              <DoneAll />
                            </GetRequestAccessButton>
                          )
                        )}
                      </>
                    )}
                  </FieldSection>
                ) : (
                  <div>
                    <GetRequestAccessButton onClick={handleRequestPressed}>
                      Request dataroom access
                    </GetRequestAccessButton>
                  </div>
                )}
                {showPopup && (
                  <BuyerProfile toggle={() => setShowPopup(false)} />
                )}
                {showNdaPopup && (
                  <NDAModal
                    toggle={() => setShowNdaPopup(false)}
                    buyerId={buyers && buyers[buyers.length - 1].id}
                    practiceId={practiceId}
                  />
                )}
              </PracticeHeaderSections>

              <ValuationBox>
                <ValuationTextField>
                  Target Price:{' '}
                  {currentViewerUserTypeHierarchyRanking >=
                    practiceUserAccessTiersSortedByHierarchy[
                      premiumAccessTierKey
                    ] || hasDataroomAccess ? (
                    getFormattedMoneyString(practice?.targetPrice)
                  ) : (
                    <LockIcon
                      onClick={handleRequestPressed}
                      style={{
                        height: '40px',
                        width: '85px',
                      }}
                      iconStyle={{
                        height: '20px',
                        width: '20px',
                      }}
                    />
                  )}
                </ValuationTextField>

                <ValuationTextField>
                  Valuation Range:{' '}
                  {`$${formatNumberAbbreviation(
                    practice?.mostRecentValuation?.lowerBound,
                  )} - $${formatNumberAbbreviation(
                    practice?.mostRecentValuation?.upperBound,
                  )}`}
                </ValuationTextField>

                {practiceSectionFields['info'].map((field) => {
                  const { label, value } = field
                  return (
                    <ValuationTextField>
                      {label}: {value}
                    </ValuationTextField>
                  )
                })}
              </ValuationBox>

              <PracticeBox ismobilescreen={ismobilescreen}>
                <PracticeOverviewContainer
                  willDisplayOnlyOneColumn={willDisplayOnlyOneColumn}
                  ismobilescreen={ismobilescreen}
                ></PracticeOverviewContainer>

                <PracticeInfoSections>
                  {practiceSections.map((section) => {
                    const { key, title } = section
                    return (
                      <PracticeInfoSection ismobilescreen={ismobilescreen}>
                        <PracticeInfoSectionNameContainer>
                          <PracticeInfoSectionTitleText>
                            {title}
                          </PracticeInfoSectionTitleText>
                          {isOwnPractice && !viewingAs && (
                            <EditSectionButton
                              outlined={true}
                              size={ismobilescreen ? 'extra-small' : 'small'}
                              onClick={() =>
                                navigateToCreatePracticeSectionToEditInEditingFlow(
                                  key,
                                  practiceId,
                                  navigate,
                                )
                              }
                            />
                          )}
                        </PracticeInfoSectionNameContainer>
                        <FieldsSection>
                          {practiceSectionFields[key].map(
                            (field) =>
                              field.accessTier !==
                                practiceOwnerAccessTierKey && (
                                <FieldSection>
                                  <FieldTitleText
                                    ismobilescreen={ismobilescreen}
                                  >
                                    {field.label}:
                                  </FieldTitleText>
                                  {/* Checking if the user is allowed to access the field */}
                                  {currentViewerUserTypeHierarchyRanking >=
                                    practiceUserAccessTiersSortedByHierarchy[
                                      field.accessTier
                                    ] || hasDataroomAccess ? (
                                    <FieldValueText
                                      ismobilescreen={ismobilescreen}
                                    >
                                      {field.value === '' ||
                                      field.value === null ||
                                      field.value === undefined
                                        ? 'N/A'
                                        : field.value === 0
                                          ? '0'
                                          : field.value}
                                    </FieldValueText>
                                  ) : (
                                    <FieldValueText
                                      ismobilescreen={ismobilescreen}
                                    >
                                      {field.valuePreview ? (
                                        field.valuePreview
                                      ) : (
                                        <LockIcon
                                          onClick={handleRequestPressed}
                                          style={{
                                            height: '48px',
                                            width: '103px',
                                          }}
                                          iconStyle={{
                                            height: '20px',
                                            width: '20px',
                                          }}
                                        />
                                      )}
                                    </FieldValueText>
                                  )}
                                </FieldSection>
                              ),
                          )}
                        </FieldsSection>
                      </PracticeInfoSection>
                    )
                  })}
                </PracticeInfoSections>
              </PracticeBox>

              <DueDiligenceBox ismobilescreen={ismobilescreen}>
                <PracticeOverviewContainer
                  willDisplayOnlyOneColumn={willDisplayOnlyOneColumn}
                  ismobilescreen={ismobilescreen}
                ></PracticeOverviewContainer>

                <DueDiligenceTitleText>Due Diligence</DueDiligenceTitleText>

                <DueDiligenceContainer>
                  {hasDataroomAccess ? (
                    <DueDiligenceButton onClick={openDataroomPage}>
                      Enter dataroom
                    </DueDiligenceButton>
                  ) : (
                    <GetRequestAccessButton onClick={handleRequestPressed}>
                      Request dataroom access
                    </GetRequestAccessButton>
                  )}
                </DueDiligenceContainer>
              </DueDiligenceBox>
              <BackButton />
            </PracticeBoxContainer>
          </FullPageContentContainer>
        </PageContainer>
      </BackgroundContainer>
    </>
  ) : (
    <LoadingIndicator fullScreen />
  )
}

export default ViewPractice
export { hasAtleastOneCompleteProfile }
