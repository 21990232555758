import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BackIcon, NextIcon } from '../../../styles/shared-styled-components'
import {
  getCreatePracticePathToNavigateTo,
  getDueDiligencePathToNavigateTo,
} from '../../../utils/routing'
import { BackButton, NavigationButtonContainer, NextButton } from './styled'

const updateListingText = 'Update listing'

const CreatePracticeFlowNavigation = forwardRef(
  (
    {
      currentStep,
      practiceId,
      nextEnabled,
      onClickNext,
      onClickBack,
      allowSkipping = false,
      isPublishingScreen = false,
      currentFlow = null,
      completedWholeListingFlow = false,
      listingStatus = null,
      inValuationMode = false,
      skipButtonText = 'Skip for now',
      isDueDiligence = false,
    },
    ref,
  ) => {
    const navigate = useNavigate()
    const backPath = isDueDiligence
      ? `${getDueDiligencePathToNavigateTo(currentStep, practiceId, 'back', currentFlow)}?navType=back`
      : `${getCreatePracticePathToNavigateTo(currentStep, practiceId, 'back', currentFlow)}?navType=back`

    const nextButtonText = isPublishingScreen
      ? !listingStatus
        ? 'Publish listing'
        : updateListingText
      : allowSkipping && !nextEnabled
        ? skipButtonText
        : 'Next'

    const nextButtonEnabled = nextEnabled || allowSkipping
    const nextIconEnabled = nextButtonText !== updateListingText
    const [isLoading, setIsLoading] = useState(false)

    useImperativeHandle(ref, () => ({
      toggleLoadingState: () => setIsLoading(!isLoading),
      setLoading: (v) => setIsLoading(v),
    }))
    return (
      <NavigationButtonContainer>
        <BackButton
          disabled={isDueDiligence && (isLoading || !onClickBack)}
          onClick={() => (onClickBack ? onClickBack() : navigate(backPath))}
        >
          <BackIcon /> Back
        </BackButton>
        {onClickNext && (
          <NextButton
            nextEnabled={nextButtonEnabled}
            onClick={onClickNext}
            disabled={isLoading}
          >
            {isLoading ? 'Please wait...' : nextButtonText}
            {nextIconEnabled && <NextIcon />}
          </NextButton>
        )}
      </NavigationButtonContainer>
    )
  },
)

export default CreatePracticeFlowNavigation
