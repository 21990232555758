import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import Header from '../../components/NavBar/Header'
import {
  BackgroundContainer,
  ErrorText,
  FullPageContentContainer,
  PageContainer,
} from '.././../styles/shared-styled-components'
import {
  FlexColumnBox,
  HorizontalDivider,
  OfferPageTitle,
  OfferStepBox,
  OfferStepDesc,
  OfferStepImage,
  OfferStepTitle,
  StepsWrapper,
} from './styled'
import OfferListItem from '../../components/MyOffers/OfferListItem'
import EMACAModal from '../../components/MyOffers/EMACAModal'
import { useRef } from 'react'
import { useCallback } from 'react'
import BuyerPreviewModal from '../../components/MyOffers/BuyerPreviewModal'
import { useAcquisitionOffers } from '../../api/hooks/acquisitions'
import LoadingIndicator from '../../components/LoadingIndicator'
import { acquisitionTypes, aquisitionFilters } from '../../utils/constants'
import { ErrorOutline } from '@mui/icons-material'
import { useMyUser } from '../../api/hooks/users'

const MyOffers = () => {
  const { practiceId } = useParams()
  const { user, loading: loadingUser } = useMyUser()
  const {
    loading,
    dataroomAccessRequests,
    purchaseIntents,
    practice,
    error,
    acceptOffer,
    rejectOffer,
  } = useAcquisitionOffers(practiceId)

  const [value, setValue] = useState(0)
  const [filter, setFilter] = useState('all')
  const emacaRef = useRef()
  const buyerRef = useRef()

  const dataSources = useMemo(
    () => [dataroomAccessRequests, purchaseIntents],
    [dataroomAccessRequests, purchaseIntents],
  )

  const filteredOffers = useMemo(() => {
    const source = dataSources[value]
    if (filter === 'all') return source

    return source.filter((data) => data.status === filter)
  }, [dataSources, filter, value])

  const dataTypeOptions = useMemo(() => {
    const dataTypeKeys = [
      acquisitionTypes.ACCESS_REQUEST,
      acquisitionTypes.PURCHASE_INTENT,
    ]
    const selectedTypeKey = dataTypeKeys[value]

    return aquisitionFilters[selectedTypeKey]
  }, [value])

  const handleFilter = (e) => {
    setFilter(e.target.value)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
    setFilter('all')
  }

  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  switch (page) {
    case 'purchase-intents':
      if (value !== 1) {
        setValue(1)
      }
      break
    case 'dataroom-access-requests':
      if (value !== 0) {
        setValue(0)
      }
      break
    default:
      break
  }

  const checkAccessSigned = useCallback(
    (data) => {
      emacaRef.current.openModal(data)
    },
    [emacaRef],
  )

  const handleBuyerPreviewClicked = useCallback(
    (offer) => {
      buyerRef.current.openModal({ offer, hasSignedEmaca: user?.signedEmaca })
    },
    [buyerRef, user],
  )

  const onApproveOffer = async (offer) => {
    await acceptOffer(offer?.id)
  }

  const onDeclineOffer = async (offer) => {
    await rejectOffer(offer?.id)
  }

  return (
    <>
      <Header user={user} />
      <EMACAModal ref={emacaRef} />
      <BuyerPreviewModal
        ref={buyerRef}
        handleApprove={onApproveOffer}
        handleDecline={onDeclineOffer}
      />
      <BackgroundContainer>
        <PageContainer>
          <FullPageContentContainer>
            {loading || loadingUser ? (
              <LoadingIndicator fullScreen />
            ) : error ? (
              <Box style={{ textAlign: 'center', margin: 30, width: '100%' }}>
                <ErrorOutline style={{ fontSize: '100px', color: '#D32F2F' }} />
                <ErrorText style={{ textAlign: 'center', fontSize: '25px' }}>
                  {error?.message}
                </ErrorText>
              </Box>
            ) : (
              <>
                <OfferPageTitle>My Offers</OfferPageTitle>
                <StepsWrapper>
                  <OfferStepBox>
                    <OfferStepImage variant={'list'} />
                    <OfferStepTitle>List your practice</OfferStepTitle>
                    <OfferStepDesc>
                      Seamlessly share your practice info online in your own
                      private, secure dataroom.
                    </OfferStepDesc>
                  </OfferStepBox>
                  <FlexColumnBox>
                    <HorizontalDivider />
                  </FlexColumnBox>
                  <OfferStepBox>
                    <OfferStepImage variant={'approve'} />
                    <OfferStepTitle>Approve buyers</OfferStepTitle>
                    <OfferStepDesc>
                      Only share sensitive data when the buyer has signed an
                      NDA, and you’ve approved their acquisition terms.
                    </OfferStepDesc>
                  </OfferStepBox>
                  <FlexColumnBox>
                    <HorizontalDivider />
                  </FlexColumnBox>
                  <OfferStepBox>
                    <OfferStepImage variant={'receive'} />
                    <OfferStepTitle>Receive offers</OfferStepTitle>
                    <OfferStepDesc>
                      Sell your practice on your terms.
                    </OfferStepDesc>
                  </OfferStepBox>
                </StepsWrapper>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderTop: 1,
                    borderColor: 'divider',
                    width: '100%',
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant='scrollable'
                    scrollButtons='auto'
                    allowScrollButtonsMobile
                  >
                    <Tab
                      sx={{ textTransform: 'none' }}
                      label='Dataroom Access Requests'
                    />
                    <Tab
                      sx={{ textTransform: 'none' }}
                      label='Purchase Intents'
                    />
                  </Tabs>
                </Box>
                <Box>
                  <FormControl sx={{ m: 1, minWidth: 300 }} size='small'>
                    <Select value={filter} onChange={handleFilter} displayEmpty>
                      {dataTypeOptions?.map((option, i) => (
                        <MenuItem key={i} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    padding: '10px',
                    width: '95%',
                    gap: '47px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {filteredOffers?.map((offer, i) => (
                    <OfferListItem
                      key={i}
                      offer={offer}
                      hasSignedEmaca={user?.signedEmaca}
                      practice={practice}
                      checkEmacaAccessSigned={checkAccessSigned}
                      viewBuyer={handleBuyerPreviewClicked}
                      handleApprove={onApproveOffer}
                      handleDecline={onDeclineOffer}
                    />
                  ))}
                </Box>
              </>
            )}
          </FullPageContentContainer>
        </PageContainer>
      </BackgroundContainer>
    </>
  )
}

export default MyOffers
