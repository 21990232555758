import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getPublishPracticeRouteFromId } from '../../../utils/routing'
import {
  PrimaryCompleteListingButton,
  SecondaryCompleteListingButton,
} from './styled'

const defaultText = 'Complete my listing'

const CompleteListingButton = ({
  practice,
  buttonType = 'primary',
  text = null,
  inValuationMode = false,
}) => {
  const navigate = useNavigate()

  const { id } = practice

  const publishPracticePath = getPublishPracticeRouteFromId(id)

  return (
    <>
      {buttonType === 'primary' ? (
        <PrimaryCompleteListingButton
          onClick={() => navigate(publishPracticePath)}
        >
          {text || defaultText}
        </PrimaryCompleteListingButton>
      ) : (
        <SecondaryCompleteListingButton
          onClick={() => navigate(publishPracticePath)}
        >
          {text || defaultText}
        </SecondaryCompleteListingButton>
      )}
    </>
  )
}

export default CompleteListingButton
