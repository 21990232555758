import React from 'react'
import {
  AdornmentContainer,
  StyledTooltip,
} from '../../../styles/shared-styled-components'
import { PrivacyIcon } from './styled'

const genericFieldPrivacyMessage = (
  <>
    This field is only visible to verified Dentacloud members that you've
    personally approved access for.
    <br></br>
    <br></br>
    Unless you personally approve them, no user will ever be able to see this
    information.
  </>
)

const PrivacyShield = ({ fieldPrivacyMessage = null, format = 'input' }) => {
  return (
    <StyledTooltip title={fieldPrivacyMessage || genericFieldPrivacyMessage}>
      <AdornmentContainer>
        <PrivacyIcon format={format} />
      </AdornmentContainer>
    </StyledTooltip>
  )
}

export default PrivacyShield
