import React from 'react'
import {
  BackgroundContainer,
  FullPageContentContainerWithGap,
  PageContainer,
} from '../../../styles/shared-styled-components'
import Header from '../WaitlistHeader'
import {
  BenefitsContainer,
  BenefitsDescriptionText,
  BenefitsSection,
  BenefitsText,
  MapImageContainer,
  PinIcon,
  PrivacyIcon,
  SearchIcon,
  ThankYouSubtitleText,
  ThankYouTextContainer,
  ThankYouTitleText,
} from './styled'

const WaitlistThankYou = ({ width, ismobilescreen }) => {
  return (
    <>
      <Header />
      <BackgroundContainer>
        <PageContainer>
          <FullPageContentContainerWithGap>
            <MapImageContainer ismobilescreen={ismobilescreen} />
            <ThankYouTextContainer>
              <ThankYouTitleText ismobilescreen={ismobilescreen}>
                Thank you
              </ThankYouTitleText>
              <ThankYouSubtitleText ismobilescreen={ismobilescreen}>
                for joining our waitlist
              </ThankYouSubtitleText>
            </ThankYouTextContainer>
            <BenefitsText ismobilescreen={ismobilescreen}>
              We look forward to keeping you updated on all the exciting
              developments ahead with Dentacloud Connect.
              <br></br>
              <br></br>
              Here are just a few benefits the platform will provide you on your
              journey towards growing your wealth.
            </BenefitsText>
            <BenefitsContainer ismobilescreen={ismobilescreen}>
              <BenefitsSection>
                <SearchIcon />
                <BenefitsDescriptionText>
                  Easily upload your practice info through our intuitive online
                  portal.
                </BenefitsDescriptionText>
              </BenefitsSection>
              <BenefitsSection>
                <PinIcon />
                <BenefitsDescriptionText ismobilescreen={ismobilescreen}>
                  Get matched with the right buyers all across North America.
                </BenefitsDescriptionText>
              </BenefitsSection>
              <BenefitsSection>
                <PrivacyIcon />
                <BenefitsDescriptionText>
                  We value your privacy. Engage with buyers while maintaining
                  your anonymity.
                </BenefitsDescriptionText>
              </BenefitsSection>
            </BenefitsContainer>
          </FullPageContentContainerWithGap>
        </PageContainer>
      </BackgroundContainer>
    </>
  )
}

export default WaitlistThankYou
