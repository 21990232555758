import CircularProgressWheel from '@mui/material/CircularProgress'
import styled from 'styled-components/macro'

export const StyledCircularProgressWheel = styled(CircularProgressWheel)`
  color: ${(props) =>
    props.value === 100
      ? props.theme.color.brightGreen
      : props.theme.color.lightPrimary};
  height: 60px !important;
  width: 60px !important;
`
