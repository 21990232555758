import styled, { css } from 'styled-components/macro'
import { RowFlexStyle } from '../../../styles/shared-styled-components'
import {
  allSaleStages,
  forSaleStageKey,
  offMarketStageKey,
} from '../../../utils/constants'
import { assumedDistanceFromTopOfPageToTopOfMap } from '../PracticePreviewThumbnail/PracticePreviewThumbnail'

const edgePadding = 15

const individualToggleWidth = 100
const rightBorderWidth = 1
const totalWidthOfToggle =
  individualToggleWidth * allSaleStages.length +
  rightBorderWidth * (allSaleStages.length - 1)

export const ListingTypesContainer = styled.div`
  position: absolute;
  top: ${edgePadding + assumedDistanceFromTopOfPageToTopOfMap}px;
  left: ${edgePadding}px;
  ${RowFlexStyle}
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.typography.typeFont};
  font-size: ${({ theme }) => theme.typography.sentenceFontSize};
  height: 40px;
  align-items: center;
  border-radius: ${({ theme }) => theme.spacing.mediumBorderRadius}px;
  box-shadow: 5px 5px 5px ${({ theme }) => theme.color.darkGrey};

  @media (max-width: 768px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`

export const ListingTypeButton = styled.div`
  ${(props) =>
    props.selected || props.disabled
      ? props.stage === offMarketStageKey
        ? css`
            // Listed off-market
            background-color: ${({ theme }) => theme.color.offMarket};
            color: ${({ theme }) => theme.color.white};
          `
        : props.stage === forSaleStageKey
          ? css`
              // Listed for sale
              background-color: ${({ theme }) => theme.color.primary};
              color: ${({ theme }) => theme.color.white};
            `
          : css`
              // Sold
              background-color: ${({ theme }) => theme.color.sold};
              color: ${({ theme }) => theme.color.white};
            `
      : css`
          background-color: ${({ theme }) => theme.color.muiLightGrey};
          color: ${({ theme }) => theme.color.black};
        `}
  ${(props) =>
    props.idx === 0
      ? css`
          // Put left-side border radius
          border-radius: ${({ theme }) => theme.spacing.mediumBorderRadius}px
            0px 0px ${({ theme }) => theme.spacing.mediumBorderRadius}px;
        `
      : props.isLastElement
        ? css`
            // Put right-side border radius
            border-radius: 0px
              ${({ theme }) => theme.spacing.mediumBorderRadius}px
              ${({ theme }) => theme.spacing.mediumBorderRadius}px 0px;
          `
        : null}        
  cursor: ${(props) => props.disabled && 'default'};
  width: ${individualToggleWidth}px;
  height: 100%;
  ${RowFlexStyle}
  align-items: center;
  justify-content: center;
  border-right: ${(props) =>
    !props.isLastElement &&
    `${rightBorderWidth}px solid ${props.theme.color.lightPrimary}`};
`
