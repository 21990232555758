import { useMutationWithErrorLogging } from '../../../utils/hooks'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Paths from '../../../Paths'
import { EDIT_PRODUCT_OR_SERVICE } from '../../../api/mutations/users'
import Header from '../../../components/NavBar/Header'
import OnboardingSteps from '../../../components/Onboarding/OnboardingSteps'
import {
  BackgroundContainer,
  ColumnButtonContainer,
  ErrorMessageContainerBelowField,
  ErrorText,
  FlexDiv,
  FormControlLabel,
  PageContainer,
  Radio,
} from '../../../styles/shared-styled-components'
import {
  checkIfBuyOrSellGoalPresent,
  getProductOrServiceGoal,
} from '../../../utils/user'
import {
  Asset,
  AssetContainer,
  BackButton,
  ContinueButton,
  FormTitleText,
  OnboardingForm,
  OnboardingFormContainer,
  OnboardingFormGroup,
} from '../shared-onboarding-styles'
import { VerticallyScrollableDivWithContainedWidth } from './styled'

const productsAndServicesMapArray = [
  {
    key: 'accounting',
    label: 'Accounting',
  },
  {
    key: 'legal',
    label: 'Legal',
  },
  // {
  //   key: 'brokerage',
  //   label: 'Brokerage',
  // },
  // {
  //   key: 'financial_services',
  //   label: 'Financial services',
  // },
  // {
  //   key: 'luxury_products',
  //   label: 'Luxury products',
  // },
]

const ProductOrServiceSubGoal = ({
  user,
  width,
  ismobilescreen,
  isIpadSizeOrSmaller,
}) => {
  const navigate = useNavigate()

  const [editProductOrServiceMutation, { data: editProductOrServiceData }] =
    useMutationWithErrorLogging(EDIT_PRODUCT_OR_SERVICE)

  const productOrServiceGoal = getProductOrServiceGoal(user?.goals)
  const goalId = productOrServiceGoal?.id

  const [productOrService, setProductOrService] = useState(
    productOrServiceGoal?.subGoal,
  )
  const [errorMessage, setErrorMessage] = useState()

  const continueEnabled = !!productOrService

  const onClickContinue = () => {
    editProductOrServiceMutation({
      variables: {
        goalId,
        productOrService,
      },
      onCompleted: (data) => {
        const goals = data?.editProductOrService?.goals || []

        // Verify that the subgoal we added is present on the returned goals array
        if (goals.length) {
          const productOrServicesGoal = getProductOrServiceGoal(goals)
          const buyOrSellGoalPresent = checkIfBuyOrSellGoalPresent(goals)
          if (productOrServicesGoal?.subGoal === productOrService) {
            if (buyOrSellGoalPresent) {
              navigate(Paths.isLicensedToPracticeOnboarding)
            } else {
              navigate(Paths.linkedinUrlOnboarding)
            }
          }
        } else {
          setErrorMessage('Unable to save. Please refresh and try again.')
        }
      },
    })
  }

  return (
    <>
      <Header
        user={user}
        width={width}
        ismobilescreen={isIpadSizeOrSmaller}
        isOnboardingScreen
      />
      <BackgroundContainer>
        <PageContainer>
          <FlexDiv>
            <OnboardingFormContainer ismobilescreen={isIpadSizeOrSmaller}>
              <OnboardingSteps currentStep={3} />
              <OnboardingForm ismobilescreen={ismobilescreen}>
                <FormTitleText ismobilescreen={ismobilescreen}>
                  What service would you like to offer?
                </FormTitleText>
                <VerticallyScrollableDivWithContainedWidth>
                  {productsAndServicesMapArray.map((psMap) => {
                    const key = psMap['key']
                    const label = psMap['label']
                    return (
                      <OnboardingFormGroup key={key}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={productOrService === key}
                              onClick={(e) => setProductOrService(key)}
                            />
                          }
                          label={label}
                        />
                      </OnboardingFormGroup>
                    )
                  })}
                  {errorMessage && (
                    <ErrorMessageContainerBelowField>
                      <ErrorText>{errorMessage}</ErrorText>
                    </ErrorMessageContainerBelowField>
                  )}
                </VerticallyScrollableDivWithContainedWidth>
              </OnboardingForm>
              <ColumnButtonContainer>
                <ContinueButton
                  disabled={!continueEnabled}
                  onClick={onClickContinue}
                >
                  Save and continue
                </ContinueButton>
                <BackButton onClick={() => navigate(Paths.goalsOnboarding)}>
                  Back
                </BackButton>
              </ColumnButtonContainer>
            </OnboardingFormContainer>
            {!isIpadSizeOrSmaller && (
              <AssetContainer>
                <Asset src={Paths.acquireTellUsAbout} />
              </AssetContainer>
            )}
          </FlexDiv>
        </PageContainer>
      </BackgroundContainer>
    </>
  )
}

export default ProductOrServiceSubGoal
