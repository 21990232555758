import MuiAddIcon from '@mui/icons-material/Add'
import MuiCloseIcon from '@mui/icons-material/Close'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import MuiDiscountIcon from '@mui/icons-material/Discount'
import MuiEditIcon from '@mui/icons-material/Edit'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import MuiMoreFiltersIcon from '@mui/icons-material/TuneOutlined'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import MuiCheckbox from '@mui/material/Checkbox'
import MuiChip from '@mui/material/Chip'
import MuiContainer from '@mui/material/Container'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import MuiFormControlLabel from '@mui/material/FormControlLabel'
import MuiFormGroup from '@mui/material/FormGroup'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import MuiRadio from '@mui/material/Radio'
import Select from '@mui/material/Select'
import Slider from '@mui/material/Slider'
import Snackbar from '@mui/material/Snackbar'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import styled, { css } from 'styled-components/macro'
import {
  forSaleStageKey,
  incompleteStatusKey,
  offMarketStageKey,
  readyToListStatusKey,
  underReviewStatusKey,
} from '../utils/constants'

export const RowFlexStyle = css`
  display: flex;
  flex-direction: row;
`

export const ColumnFlexStyle = css`
  display: flex;
  flex-direction: column;
`

export const BackgroundContainer = styled.div`
  ${ColumnFlexStyle}
  background-color: ${({ theme }) => theme.color.white};
  background-size: cover;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  top: 0;
  overflow-x: hidden;
  .MuiContainer-maxWidthLg {
    max-width: 100% !important;
  }
  overflow-y: ${(props) => props.hideVerticalScroll && 'hidden'};
`

// VerticallUnscrollableBackgroundContainer
// Using acronym because otherwise syntax highlighting gets messed up
export const VUNSBC = styled(BackgroundContainer)`
  overflow-y: hidden;
`

export const PageContainer = styled(MuiContainer)`
  // Making sure it page content will always start immediately below the header:
  // header height + header top padding + header bottom padding
  margin-top: ${({ theme }) =>
    theme.spacing.headerHeight + theme.spacing.headerVerticalPadding * 2}px;
  padding: 0px;
  // Give the page content the remaining height (unless its fullscreen)
  height: ${(props) =>
    !props.fullHeight
      ? `calc(
    100vh -
        ${
          props.theme.spacing.headerHeight +
          props.theme.spacing.headerVerticalPadding * 2
        }px`
      : '100vh'};
  width: 100vw;
  max-width: none;
`

export const IntersectionalGap = css`
  gap: 50px;
`

export const InterSubsectionalGapValue = '30px'
export const InterSubsectionalGap = css`
  gap: ${InterSubsectionalGapValue};
`

export const IntrasectionalGapValue = '20px'
export const IntrasectionalGap = css`
  gap: ${IntrasectionalGapValue};
`

export const IntraSubsectionalGap = css`
  gap: 10px;
`

export const MicroGap = css`
  gap: 5px;
`

export const BasePadding = css`
  // So that content aligns with header LHS (dentacloud logo) and RHS (profile dropdown)
  padding: 0px ${({ theme }) => theme.spacing.pageLevelHorizontalPadding}px;
`

export const BaseMargin = css`
  margin: ${({ theme }) => theme.spacing.desktopTopMargin}px 0px 0px 0px;
`

export const BaseWidth = css`
  width: calc(
    100% - ${({ theme }) => theme.spacing.pageLevelHorizontalPadding * 2}px
  );
`

export const AutoHorizontalMargins = css`
  margin-left: auto;
  margin-right: auto;
`

export const BaseWidthCentered = css`
  ${BaseWidth}
  ${AutoHorizontalMargins}
`

export const BaseDiv = styled.div`
  ${BasePadding}
  ${BaseMargin}  
  ${BaseWidth}
`

export const IndentedPadding = css`
  padding: 0px
    ${(props) =>
      props.ismobilescreen
        ? props.theme.spacing.pageLevelHorizontalPadding
        : props.theme.spacing.pageLevelHorizontalPadding * 6}px;
`

export const IndentedMargin = css`
  margin: ${(props) =>
      props.ismobilescreen
        ? props.theme.spacing.mobileTopMargin * 4
        : props.theme.spacing.desktopTopMargin * 4}px
    0px 0px 0px;
`

export const IndentedWidth = css`
  width: calc(
    100% -
      ${(props) =>
        props.ismobilescreen
          ? props.theme.spacing.pageLevelHorizontalPadding * 2
          : props.theme.spacing.pageLevelHorizontalPadding * 4}px
  );
`

export const IndentedDiv = styled.div`
  ${IndentedPadding}
  ${IndentedMargin} 
  ${IndentedWidth}
`

export const FlexDiv = styled.div`
  display: flex;
`

export const FullPageContentContainer = styled.div`
  ${ColumnFlexStyle}
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
`

export const FullPageContentContainerWithGap = styled(FullPageContentContainer)`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const FullPageContentContainerWithLargeGap = styled(
  FullPageContentContainer,
)`
  ${IntersectionalGap}
`

export const ContentContainer = styled(BaseDiv)`
  ${ColumnFlexStyle}
`

export const IndentedContentContainer = styled(IndentedDiv)`
  ${ColumnFlexStyle}
`

export const LandingPageTitle = styled(Typography).attrs({
  variant: 'h1',
})`
  font-family: ${({ theme }) => theme.typography.typeFont};
  font-size: 2em;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;

  @media (max-width: 768px) {
    font-size: 1.5em;
    padding-top: 0px;
    padding-bottom: 0px;
  }
`

export const LandingPageSubtitle = styled(Typography).attrs({
  variant: 'p',
})`
  font-family: ${({ theme }) => theme.typography.typeFont};
  font-size: 0.6em;
  margin-left: 8px;
`

export const PrimaryText = styled.span`
  color: ${({ theme }) => theme.color.primary};
`

export const TextStyle = css`
  font-family: ${({ theme }) => theme.typography.typeFont};
  color: ${({ theme }) => theme.color.primary};
  font-weight: 400;
`

export const TitleTextStyle = css`
  ${TextStyle}
  font-size: ${({ theme }) => theme.typography.pageTitleFontSize};
`

export const TitleText = styled(Typography).attrs({
  variant: 'h4',
})`
  ${TitleTextStyle}
  text-align: center;
`

export const smallTitleText = styled(Typography).attrs({
  variant: 'h4',
})`
  ${TitleTextStyle}
  text-align: center;
`

export const SubtitleText = styled(TitleText)`
  font-size: ${({ theme }) => theme.typography.pageSubtitleFontSize};
`

export const LargeParagraphText = styled(Typography).attrs({
  variant: 'h5',
})`
  ${TextStyle}
  font-size: ${({ theme }) => theme.typography.titleFontSize};
`

export const ErrorText = styled(TitleText)`
  color: ${({ theme }) => theme.color.error};
  font-size: ${({ theme }) => theme.typography.sentenceFontSize};
  text-align: start;
`

export const ErrorMessageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
`

export const ErrorMessageContainerBelowField = styled(ErrorMessageContainer)`
  margin-top: 10px;
`

export const ParagraphText = styled(Typography).attrs({
  variant: 'h7',
})`
  ${TextStyle}
  font-size: ${({ theme }) => theme.typography.sentenceFontSize};
`

export const LargeInputText = styled(ParagraphText)`
  font-size: ${({ theme }) => theme.typography.largeInputTextFontSize};
`

export const GreyLargeInputText = styled(LargeInputText)`
  color: ${({ theme }) => theme.color.grey};
`

export const BlackLargeInputText = styled(LargeInputText)`
  color: ${({ theme }) => theme.color.black};
`

export const GreyParagraphText = styled(ParagraphText)`
  color: ${({ theme }) => theme.color.grey};
`

export const BlackParagraphText = styled(GreyParagraphText)`
  color: ${({ theme }) => theme.color.black};
`

export const ErrorParagraphText = styled(ParagraphText)`
  color: ${({ theme }) => theme.color.error};
`

export const GreyTitleText = styled(GreyParagraphText)`
  font-size: ${({ theme }) => theme.typography.titleFontSize};
`

export const PrimaryButtonStyle = css`
  border-radius: ${({ theme }) => theme.spacing.baseBorderRadius}px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.typography.typeFont};
  font-size: ${({ theme }) => theme.typography.sentenceFontSize};
  text-transform: none;
  padding: ${(props) =>
    props.disablepadding === 'true' ? '0px' : '10px 15px;'};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  &.no-shadow: {
    box-shadow: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.darkHover};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.color.disabled};
  }
`

export const BaseMaterialUiButton = styled(Button).attrs({
  disableRipple: true,
})``

export const PrimaryButton = styled(BaseMaterialUiButton)`
  ${PrimaryButtonStyle}
`

export const OverviewParagraphText = styled(GreyLargeInputText)`
  text-align: ${(props) => props.ismobilescreen && 'start'};
`

export const SecondaryButton = styled(PrimaryButton).attrs({
  variant: 'outlined',
})`
  color: ${({ theme }) => theme.color.primary};
  border-color: ${({ theme }) => theme.color.primary};
  background-color: ${(props) =>
    props.open ? props.theme.color.lightHover : props.theme.color.white};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  &:hover {
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.primary};
  }
`

export const offMarketButton = styled(PrimaryButton).attrs({
  variant: 'outlined',
})`
  color: ${({ theme }) => theme.color.white};
  border-color: ${({ theme }) => theme.color.offMarket};
  background-color: ${(props) =>
    props.open ? props.theme.color.lightHover : props.theme.color.offMarket};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  &:hover {
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.darkPurple};
  }
`

export const TextButton = styled(Button).attrs({
  variant: 'text',
})`
  border-radius: ${({ theme }) => theme.spacing.baseBorderRadius}px;
  cursor: pointer;
  color: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.typography.typeFont};
  font-size: ${({ theme }) => theme.typography.sentenceFontSize};
  text-transform: none;
  padding: ${(props) =>
    props.disablepadding === 'true' ? '0px' : '10px 15px;'};
`

export const TextButtonWithPrimaryColor = styled(TextButton)`
  color: ${({ theme }) => theme.color.primary};
`

export const UnderlineStyle = css`
  text-decoration: underline;
  text-underline-offset: 4px;
  cursor: pointer;
`

export const TextOnlyButtonWithoutUnderline = styled(Button).attrs({
  variant: 'text',
})`
  color: ${({ theme }) => theme.color.primary};
  font-family: ${({ theme }) => theme.typography.typeFont};
  font-size: ${({ theme }) => theme.typography.sentenceFontSize};
  text-transform: none;
`

export const TextOnlyButton = styled(TextOnlyButtonWithoutUnderline)`
  ${UnderlineStyle}
  &:hover {
    ${UnderlineStyle}
  }
`

export const GreenButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.color.brightGreen};
  &:hover {
    background-color: ${({ theme }) => theme.color.brightGreenHover};
  }
`

export const GreenButtoNoHover = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.color.brightGreen};
`

export const RedButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.color.error};
  &:hover {
    background-color: ${({ theme }) => theme.color.red};
  }
`

export const redButtonNoHover = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.color.error};
`

export const ColumnButtonContainer = styled.div`
  ${ColumnFlexStyle}
  ${IntrasectionalGap}
`

export const ColumnButtonContainerWithSmallerGap = styled(
  ColumnButtonContainer,
)`
  ${MicroGap}
`

export const RowButtonContainer = styled.div`
  ${RowFlexStyle}
  ${IntraSubsectionalGap}
`

const MenuItemStyle = css`
  padding: 6px 16px;
`

export const StyledMenuItem = styled(MenuItem)`
  font-family: ${({ theme }) => theme.typography.typeFont};
  font-size: ${({ theme }) => theme.typography.defaultFontSize};
  color: ${({ theme }) => theme.color.black};
  justify-content: space-between;
  ${MenuItemStyle}
`

export const MenuHeader = styled.div`
  background-color: ${({ theme }) => theme.color.lightHover};
  font-family: ${({ theme }) => theme.typography.typeFont};
  font-size: ${({ theme }) => theme.typography.defaultFontSize};
  color: ${({ theme }) => theme.color.primary};
  padding: 15px 16px;
  font-weight: ${({ theme }) => theme.typography.boldWeight};
  display: flex;
  justify-content: flex-start;
`

export const FormGroup = styled(MuiFormGroup)`
  ${MenuItemStyle}
  width: ${(props) => props.width && `${props.width}px`};
`

export const ScrollStyle = css`
  overflow-y: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${({ theme }) => theme.color.primary};
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`

export const ScrollableFormGroup = styled(MuiFormGroup)`
  ${MenuItemStyle}
  width: ${(props) => props.width && `${props.width}px`};
  height: ${(props) => props.height && `${props.height}px`};
  ${ScrollStyle}
  flex-wrap: inherit;
`

export const FormControlLabel = styled(MuiFormControlLabel)`
  .MuiTypography-root {
    font-family: ${({ theme }) => theme.typography.typeFont};
    font-size: ${({ theme }) => theme.typography.defaultFontSize};
  }
  .MuiCheckbox-root.Mui-checked {
    color: ${({ theme }) => theme.color.primary};
  }
  .MuiTypography-root {
    ${RowFlexStyle}
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  margin-right: 0px;
  width: ${(props) => props.useFullWidth && '100%'};
`

export const Checkbox = styled(MuiCheckbox)``

export const StyledCheckbox = styled(Checkbox)`
  // (Adhoc) Making it align with the top of the goals text
  padding-top: 14px;
`

export const Radio = styled(MuiRadio)``

export const MenuItemDivider = styled(Divider)``

export const StyledMenuItemIconStyle = css`
  color: ${({ theme }) => theme.color.primary};
  margin-right: 40px;
`

export const StyledNavBarMenuItem = css`
  ${StyledMenuItemIconStyle}
  width: 40px;
  height: 40px;
`

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 3px;
`

const ArrowStyle = css`
  height: 16px;
  width: 16px;
`

export const DownArrow = styled(ExpandMoreIcon)`
  ${ArrowStyle}
`

export const UpArrow = styled(ExpandLessIcon)`
  ${ArrowStyle}
`

export const BackIcon = styled(KeyboardArrowLeft)``

export const NextIcon = styled(KeyboardArrowRight)``

export const Form = styled(FormControl)``

const InputStyleHeight = css`
  height: 60px;
`

export const InputBorderRadius = '8px'

export const InputStyle = css`
  .MuiOutlinedInput-input {
    font-family: ${({ theme }) => theme.typography.typeFont};
    font-size: ${({ theme }) => theme.typography.largeInputTextFontSize};
    padding: 10px 16px;
  }
  .MuiOutlinedInput-root {
    border-radius: ${InputBorderRadius};
    font-size: ${({ theme }) => theme.typography.sentenceFontSize};
    ${InputStyleHeight}
  }
  .MuiInputLabel-root {
    font-family: ${({ theme }) => theme.typography.typeFont};
    font-size: ${({ theme }) => theme.typography.sentenceFontSize};
    color: ${({ theme }) => theme.color.primary};
  }
  .MuiFormHelperText-root {
    font-family: ${({ theme }) => theme.typography.typeFont};
    margin-top: 5px;
    margin-left: 0px;
  }
  flex-grow: 1;
`

export const TextInput = styled(TextField).attrs({
  inputProps: {
    'data-hj-allow': true,
  },
})`
  ${InputStyle}
`

export const TitleTextInputStyle = css`
  ${InputStyle}
  .MuiOutlinedInput-input {
    font-size: ${({ theme }) => theme.typography.titleFontSize};
  }
  .MuiOutlinedInput-root {
    font-size: ${({ theme }) => theme.typography.titleFontSize};
  }
  .MuiInputLabel-root {
    font-size: ${({ theme }) => theme.typography.titleFontSize};
  }
`

export const TitleTextInput = styled(TextInput)`
  ${TitleTextInputStyle}
`

export const StyledTooltip = styled(Tooltip).attrs({
  // For mobile hovering so that it appears on click
  enterTouchDelay: 0,

  // Use this to debug the tooltip
  // open: true,

  // For mobile hovering, making sure it doesn't fade too quickly
  leaveTouchDelay: 7000,
  componentsProps: {
    tooltip: {
      sx: {
        // NOTE: These are hardcoded. Couldn't figure out how to use theme here
        fontFamily: 'Noto Sans KR',
        fontSize: 14,
        marginTop: '0px !important',
        padding: 2,
        width: '320px !important',
        maxWidth: '320px !important',
        backgroundColor: 'rgba(97, 97, 97, 0.95)',
      },
    },
  },
})`
  .MuiTooltip-tooltip {
    font-family: ${({ theme }) => theme.typography.typeFont};
    font-weight: 600;
  }
`

// For some reason inheritance didnt work with changing the component props
export const StyledTooltipWithLighterBackground = styled(Tooltip).attrs({
  // For mobile hovering so that it appears on click
  enterTouchDelay: 0,

  // Use this to debug the tooltip
  // open: true,

  // For mobile hovering, making sure it doesn't fade too quickly
  leaveTouchDelay: 7000,
  componentsProps: {
    tooltip: {
      sx: {
        // NOTE: These are hardcoded. Couldn't figure out how to use theme here
        fontFamily: 'Noto Sans KR',
        fontSize: 14,
        marginTop: '5px !important',
        marginLeft: '10px',
        padding: 2,
        width: '320px !important',
        maxWidth: '320px !important',
        backgroundColor: 'rgba(50, 50, 50, 0.90)',
        color: 'white',
      },
    },
  },
})`
  .MuiTooltip-tooltip {
    font-family: ${({ theme }) => theme.typography.typeFont};
    font-weight: 600;
  }
`

export const TooltipLinkText = styled.a`
  color: ${({ theme }) => theme.color.lightPrimary};
  ${UnderlineStyle}
`

export const LighterTooltipLinkText = styled(TooltipLinkText)`
  color: lightcyan;
  font-weight: bold;
`

export const IconContainerStyle = css`
  display: flex;
  align-items: center;
`

const IconContainerMargin = 3

export const IconContainerRight = styled.div`
  ${IconContainerStyle}
  margin-left: ${IconContainerMargin}px;
`

export const IconContainerLeft = styled(IconContainerRight)`
  ${IconContainerStyle}
  margin-right: ${IconContainerMargin}px;
`

export const ExtraSmallIconStyle = css`
  height: 10px;
  width: 10px;
`

export const SmallIconStyle = css`
  height: 13px;
  width: 13px;
`

export const DefaultIconStyle = css`
  height: 16px;
  width: 16px;
`

export const MediumIconStyle = css`
  height: 19px;
  width: 19px;
`

export const MoreFiltersIcon = styled(MuiMoreFiltersIcon)`
  ${DefaultIconStyle}
`

export const RangeSlider = styled(Slider)`
  width: 70%;
  color: ${({ theme }) => theme.color.blue};
  .MuiSlider-markLabel {
    font-family: ${({ theme }) => theme.typography.typeFont};
    color: ${({ theme }) => theme.color.black};
  }
  .MuiSlider-valueLabel {
    font-family: ${({ theme }) => theme.typography.typeFont};
  }
  .MuiSlider-mark {
    height: 0px;
  }
`

export const Chip = styled(MuiChip).attrs({
  variant: 'outlined',
})`
  color: ${({ theme }) => theme.color.white};
  .MuiChip-root {
    max-width: 150px !important;
  }
  .MuiChip-label {
    overflow: visible;
    background-color: ${({ theme }) => theme.color.red};
    border-radius: 15px;
    padding: 7px;
    font-family: ${({ theme }) => theme.typography.typeFont};
    font-size: ${({ theme }) => theme.typography.smallFontSize};
  }
  &:hover {
    transform: scale(${({ theme }) => theme.spacing.buttonHoverTransformScale});
    cursor: pointer;
  }
  height: fit-content;
`

export const PracticeColorBasedOnListingStatusStyle = css`
  ${(props) =>
    props.status === incompleteStatusKey
      ? css`
          // Incomplete
          background-color: ${({ theme }) => theme.color.red};
          color: ${({ theme }) => theme.color.white};
        `
      : props.status === readyToListStatusKey
        ? css`
            // Ready to list
            background-color: ${({ theme }) => theme.color.lightPrimary};
            color: ${({ theme }) => theme.color.white};
          `
        : props.status === underReviewStatusKey
          ? css`
              // Listing under review
              background-color: ${({ theme }) => theme.color.underReview};
              color: ${({ theme }) => theme.color.white};
            `
          : props.status === offMarketStageKey
            ? css`
                // Listed off-market
                background-color: ${({ theme }) => theme.color.offMarket};
                color: ${({ theme }) => theme.color.white};
              `
            : props.status === forSaleStageKey
              ? css`
                  // Listed for sale
                  background-color: ${({ theme }) => theme.color.primary};
                  color: ${({ theme }) => theme.color.white};
                `
              : css`
                  // Sold
                  background-color: ${({ theme }) => theme.color.sold};
                  color: ${({ theme }) => theme.color.white};
                `}
`

// Couldn't figure out how to make horizontal scrollbar thin, so just removed background and added a bottom border
export const ThinScrollBarStyle = css`
  overflow: scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    border-bottom: 6px solid ${({ theme }) => theme.color.primary};
  }
`

export const VerticallyScrollableDiv = styled.div`
  ${ColumnFlexStyle}
  ${ScrollStyle}
`

export const DropdownLabel = styled(InputLabel)`
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.typography.titleFontSizeInputLabel};
  ${TextStyle}
`

export const Dropdown = styled(Select)`
  ${InputStyleHeight}
  .MuiSelect-select {
    font-family: ${({ theme }) => theme.typography.typeFont};
    font-size: ${({ theme }) => theme.typography.titleFontSize};
  }
  .MuiOutlinedInput-notchedOutline {
    // Making sure the label text isnt cut off
    font-size: ${(props) => props.theme.typography.titleFontSize};
  }
  border-radius: ${InputBorderRadius};
  cursor: pointer;
`

export const DivWithBackgroundImage = styled.div`
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: ${(props) => `url(${props.image})`};
  display: flex;
`

export const AdornmentContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const CreatePracticeInputWidth = '90%'

export const CreatePracticeInputWidthStyle = css`
  width: ${CreatePracticeInputWidth};
`

export const UploadContainer = styled.div`
  ${ColumnFlexStyle}
  ${IntrasectionalGap}
  ${CreatePracticeInputWidthStyle}
`

export const UploadTitleText = styled(LargeParagraphText)`
  display: flex;
  justify-content: flex-start;
`

export const UploadBox = styled.div`
  min-height: 220px;
  height: fit-content;
  border: 1px solid ${({ theme }) => theme.color.muiLightGrey};
  border-radius: ${({ theme }) => `${theme.spacing.baseBorderRadius}px`};
  padding: 15px;
  ${ColumnFlexStyle}
  background-color: #ececf0;
`

export const UploadInformationalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const UploadButtonContainer = styled.div`
  ${ColumnFlexStyle}
  ${IntraSubsectionalGap}
  flex: 1;
  align-items: center;
  justify-content: center;
  ${AutoHorizontalMargins}
`

export const SaveMoneyText = styled.span`
  ${UnderlineStyle}
  color: ${({ theme }) => theme.color.brightGreen};
  font-size: ${({ theme }) => theme.typography.titleFontSize};
  font-weight: bolder;
`

export const ThumbnailContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.desktopTopMargin}px;
  width: 95%;
  padding: 15px;
  border: 2px dotted darkgray;
  border-radius: ${({ theme }) => theme.spacing.baseBorderRadius}px;
  ${RowFlexStyle}
  justify-content: flex-start;
  ${IntrasectionalGap}
  flex-wrap: wrap;
`

export const UploadIcon = styled(CloudUploadIcon)`
  color: ${({ theme }) => theme.color.primary};
  height: 70px;
  width: auto;
`

export const Modal = styled(Dialog)`
  border-right: 10px black;
  overflow: auto;
`

export const ModalContainer = styled.div`
  ${ColumnFlexStyle}
  justify-content: space-between;
  height: 100%;
  overflow-x: hidden;
  ${IntrasectionalGap}
`

export const ScrollableModalContainer = styled(ModalContainer)`
  ${ScrollStyle}
`

export const ModalContainerWithGap = styled(ModalContainer)`
  ${IntraSubsectionalGap}
`

export const ModalHeaderContainer = styled.div`
  padding: 10px 0px;
  margin-top: 20px;
  ${BaseWidthCentered}
  display: flex;
  flex-direction: column;
  ${IntrasectionalGap}
  align-items: center;
`

export const ModalEmoji = styled(TitleText)`
  font-size: 35px;
`

export const LargeIconStyle = css`
  color: ${({ theme }) => theme.color.primary};
  height: 30px;
  width: 30px;
`

export const ExtraLargeIconStyle = css`
  color: ${({ theme }) => theme.color.primary};
  height: 40px;
  width: auto;
`

export const ModalContent = styled(DialogContent)`
  display: flex;
  justify-content: center;
  align-items: center;
  ${BaseWidthCentered}
`

export const ScrollableModalContent = styled(ModalContent)`
  ${ScrollStyle}
`

export const ModalContentText = styled(LargeParagraphText)`
  text-align: center;
  color: ${({ theme }) => theme.color.black};
  ${(props) =>
    props.ismobilescreen &&
    css`
      font-size: ${(props) => props.theme.typography.defaultFontSize};
    `}
`

export const ModalContentTextWithLargerMobileText = styled(ModalContentText)`
  ${(props) =>
    props.ismobilescreen &&
    css`
      font-size: ${(props) => props.theme.typography.titleFontSizeInputLabel};
    `}
`

export const ModalActions = styled(DialogActions)`
  display: flex;
  justify-content: center;
  padding: 10px 0px;
  margin-bottom: 20px;
`

const ModalActionButtonStyle = css`
  width: 100%;
  min-width: 200px;
`

export const PrimaryModalActionButton = styled(PrimaryButton)`
  ${ModalActionButtonStyle}
`

export const SecondaryModalActionButton = styled(TextButtonWithPrimaryColor)`
  ${ModalActionButtonStyle}
`

export const HeroImageStyle = css`
  width: 100%;
  background-size: cover;
`

export const DefaultHeroSectionHeightValue = '180px'
export const DefaultHeroSectionHeightStyle = css`
  height: ${DefaultHeroSectionHeightValue};
`

export const BottomBorderStyle = css`
  border-bottom: 1px solid ${({ theme }) => theme.color.lightGrey};
`

export const CardStyle = css`
  border: 1px solid ${({ theme }) => theme.color.lightPrimary};
  border-radius: ${({ theme }) => `${theme.spacing.baseBorderRadius}px`};
  padding: ${({ theme }) => theme.spacing.basePadding}px;
`

export const AddIcon = styled(MuiAddIcon)`
  margin-right: 5px;
`

export const StyledSnackbar = styled(Snackbar).attrs({
  autoHideDuration: 4000,
})`
  color: white;
  width: fit-content;
  min-width: 300px;
  border-radius: ${({ theme }) => `${theme.spacing.baseBorderRadius}px`};
  border: 1px solid ${({ theme }) => theme.color.muiLightGrey};
`

export const StyledSuccessAlert = styled(Alert).attrs({
  severity: 'success',
})`
  width: 100%;
  font-weight: bolder;
  color: white;
  ${TextStyle}
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.brightGreen};
  border-radius: ${({ theme }) => `${theme.spacing.baseBorderRadius}px`};
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 1, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
  .MuiAlert-icon {
    color: ${({ theme }) => theme.color.white};
  }
`

export const StyledInfoAlert = styled(StyledSuccessAlert).attrs({
  severity: 'info',
})`
  background-color: ${({ theme }) => theme.color.info};
`

export const StyledErrorAlert = styled(StyledSuccessAlert).attrs({
  severity: 'error',
})`
  background-color: ${({ theme }) => theme.color.error};
`

export const AvatarFrameStyle = css`
  border-radius: ${({ theme }) => `${theme.spacing.baseBorderRadius}px`};
  padding: 10px;
  background-color: ${({ theme }) => theme.color.lightPrimary};
`

export const SecondaryButtonLightHoverStyle = css`
  &:hover {
    border: 1px solid ${({ theme }) => theme.color.primary};
    background-color: ${(props) => props.theme.color.lightHover};
    color: ${({ theme }) => theme.color.primary};
  }
`

export const DiscountIcon = styled(MuiDiscountIcon)`
  ${ExtraLargeIconStyle}
`

// RGBA conversion of the lightPrimary hexcode
export const LightPrimaryHorizontalGradientBackgroundStyle = css`
  background: linear-gradient(
    270deg,
    rgba(176, 204, 220, 1) 0%,
    rgba(176, 204, 220, 0.26) 26%
  );
`

export const EditIcon = styled(MuiEditIcon)`
  ${(props) =>
    props.size === 'extra-small'
      ? css`
          ${ExtraSmallIconStyle}
        `
      : props.size === 'small'
        ? css`
            ${SmallIconStyle}
          `
        : props.size === 'large'
          ? css`
              ${LargeIconStyle}
            `
          : css`
              ${MediumIconStyle}
            `}
`

export const CloseIcon = styled(MuiCloseIcon)`
  :hover {
    background-color: ${({ theme }) => theme.color.lightHover};
  }
  color: ${({ theme }) => theme.color.primary};
`

export const VerticalSectionMenuDivider = styled.hr`
  border-width: 2px;
  border-top: 0px;
  border-left: 0px;
  border-color: ${({ theme }) => theme.color.lighterGrey};
  margin: 0px 5px;
`

export const ErrorHelperText = styled(TitleText)`
  font-family: 'Noto Sans KR';
  text-align: left;
  margin-top: 5px;
  margin-left: 0px;
  color: #d32f2f;
  font-size: 0.75em;
`

export const Image = styled.img`
  height: 100%;
`
