import styled, { css } from 'styled-components/macro'
import {
  BlackLargeInputText,
  BlackParagraphText,
  CloseIcon,
  ColumnFlexStyle,
  IntraSubsectionalGap,
  IntrasectionalGap,
  MediumIconStyle,
  MicroGap,
  RowFlexStyle,
  ScrollStyle,
  TextStyle,
} from '../../../styles/shared-styled-components'

import { markerClusterHeight, practiceMarkerHeight } from '../../../utils/view'

export const singleHoveredPracticeMenuWidth = 280
const singleHoveredPracticeMenuHeight = 110
const singleHoveredPracticeMenuHeightOnceValuationCreated = 90
const multipleHoveredPracticesMenuHeight = 210
const multipleHoveredPracticesMenuWidth = 280

const minDistanceFromMarker = 10
const minDistanceFromCeiling = 20
const minDistanceFromSideBoundary = 10

// The default Y value is already at the center of the marker (practiceMarkerHeight/2), so putting it
// an additional practiceMarkerHeight/4 will put it immediately below the marker
const verticalOffsetFromMarker = practiceMarkerHeight / 4
const verticalOffsetFromCluster = markerClusterHeight / 4

export const PracticeThumbnailContainerStyled = styled.div`
  position: absolute;
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.white};
  height: ${(props) =>
    props.hasCreatedValuation
      ? singleHoveredPracticeMenuHeightOnceValuationCreated
      : singleHoveredPracticeMenuHeight}px;
  width: ${singleHoveredPracticeMenuWidth}px;
  ${(props) =>
    // If it would hit up against the ceiling, put it below
    singleHoveredPracticeMenuHeight +
      minDistanceFromMarker +
      minDistanceFromCeiling +
      props.thumbnailContainerPadding >
    props.heightAboveMarker
      ? css`
          top: ${(props) =>
            props.y + verticalOffsetFromMarker + minDistanceFromMarker}px;
        `
      : // Else if it has space above, put it above
        props.hasCreatedValuation
        ? css`
            top: ${(props) =>
              props.y -
              verticalOffsetFromMarker -
              minDistanceFromMarker -
              singleHoveredPracticeMenuHeightOnceValuationCreated -
              props.thumbnailContainerPadding * 2}px;
          `
        : css`
            top: ${(props) =>
              props.y -
              verticalOffsetFromMarker -
              minDistanceFromMarker -
              singleHoveredPracticeMenuHeight -
              props.thumbnailContainerPadding * 2}px;
          `}
  ${(props) =>
    props.x > props.endStateWidthOfSingleHoveredPracticeMenu / 2 &&
    props.width - props.x > props.endStateWidthOfSingleHoveredPracticeMenu / 2
      ? css`
          left: ${props.x -
          props.endStateWidthOfSingleHoveredPracticeMenu / 2}px;
        `
      : props.x < props.endStateWidthOfSingleHoveredPracticeMenu / 2
        ? css`
            left: ${minDistanceFromSideBoundary}px;
          `
        : css`
            right: ${minDistanceFromSideBoundary}px;
          `}
  text-align: center;
  border-radius: ${({ theme }) => `${theme.spacing.baseBorderRadius}px`};
  border: 1px solid ${({ theme }) => theme.color.primary};
  padding: ${(props) => props.thumbnailContainerPadding}px;
  ${RowFlexStyle}
  align-items: center;
  ${MicroGap}
  box-shadow: 3px 3px ${({ theme }) => theme.color.lightPrimary};
  z-index: 10;
`

export const PracticeThumbnailNavigationContainer = styled.div`
  ${RowFlexStyle}
  justify-content: flex-end;
`

export const PracticeThumbnailContentContainerStyled = styled.span`
  min-height: ${(props) => (props.hasCreatedValuation ? 70 : 90)}px;
  ${RowFlexStyle}
  align-items: center;
  ${IntraSubsectionalGap}
  width: 100%;
  ${(props) =>
    props.inVerticalScroll &&
    css`
      padding: ${(props) => (props.isMobileScreen ? '35px' : '30px')} 0px;
      border-bottom: ${(props) =>
        !props.isBottomElement && `1px solid ${props.theme.color.lighterGrey}`};
      ${IntrasectionalGap};
    `}
  ${(props) =>
    props.hasCreatedValuation &&
    css`
      padding: ${(props) => (props.isMobileScreen ? '25px' : '20px')} 0px;
    `}
`

// Need to add fixed pixel sizes for the avatar so that the image placeholder takes up the default space as
// soon as the thumbnail loads, otherwise you get a weird flashy effect when the frame expands as the image loads
export const PracticeAvatarContainer = styled.div`
  ${RowFlexStyle}
  align-items: flex-start;
  height: ${(props) => (props.hasCreatedValuation ? 50 : 70)}px;
  width: 70px;
  margin-top: ${(props) => !props.hasCreatedValuation && '-10px'};
`

export const PracticeAvatar = styled.img`
  height: 54px;
  width: 63px;
  border: 1px solid ${({ theme }) => theme.color.muiLightGrey};
  border-radius: ${({ theme }) => theme.spacing.baseBorderRadius}px;
  padding: 7px;
`

export const PracticeAvatarNouser = styled.img`
  height: 54px;
  width: 63px;
  border: 1px solid ${({ theme }) => theme.color.muiLightGrey};
  border-radius: ${({ theme }) => theme.spacing.baseBorderRadius}px;
  padding: 13px;
`

export const PracticeTextContainer = styled.div`
  ${ColumnFlexStyle}
  justify-content: ${(props) =>
    props.hasCreatedValuation ? 'flex-start' : 'center'};
  height: 100%;
  width: 100%;
  align-items: flex-start;
  ${TextStyle}
  font-size: ${({ theme }) => theme.typography.defaultFontSize};
  gap: 2px;
`

export const PracticeSubtext = styled(BlackParagraphText)``

export const PracticeThumbnailCloseContainer = styled.div`
  height: 100%;
  margin-top: 1px;
`

export const OffMarketPracticeThumbnailCloseContainer = styled.div`
  height: 100%;
  margin-top: -100px;
`

export const StyledCloseIcon = styled(CloseIcon)`
  ${MediumIconStyle}
  position: absolute;
  left: 10px;
`

export const StyledCloseIconNoUser = styled(CloseIcon)`
  ${MediumIconStyle}
  position: absolute;
  left: 10px;
  top: 10px;
`

// So that we dont get this annoying line formatting because the name is too long
const PTC = PracticeThumbnailContainerStyled
export const PracticeThumbnailContainerWithMultiplePracticesStyled = styled(
  PTC,
)`
  ${ColumnFlexStyle}
  overflow-x: hidden;
  height: ${multipleHoveredPracticesMenuHeight}px;
  width: ${multipleHoveredPracticesMenuWidth}px;
  text-align: center;
  ${(props) =>
    // If it would hit up against the ceiling, put it below
    multipleHoveredPracticesMenuHeight +
      minDistanceFromMarker +
      minDistanceFromCeiling +
      props.thumbnailContainerPadding >
    props.heightAboveMarker
      ? verticalOffsetFromCluster +
          multipleHoveredPracticesMenuHeight +
          minDistanceFromMarker +
          5 +
          props.thumbnailContainerPadding * 2 <
        props.heightBelowMarker
        ? css`
            top: ${(props) =>
              props.y + verticalOffsetFromCluster + minDistanceFromMarker}px;
          `
        : css`
            top: ${(props) => Math.min(props.heightBelowMarker, props.y)}px;
          `
      : // Else if it has space above, put it above
        css`
          top: ${(props) =>
            props.y -
            verticalOffsetFromCluster -
            minDistanceFromMarker -
            multipleHoveredPracticesMenuHeight -
            props.thumbnailContainerPadding * 2}px;
        `}
`

export const NumberOfPracticesHeadingContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.lightPrimary};
  width: 100%;
  ${RowFlexStyle}
  align-items: center;
  justify-content: space-between;
  padding-bottom: 7px;
`

export const CloseButtonNumberOfPracticesHeadingContainer = styled.div`
  width: 100%;
  ${RowFlexStyle}
  align-items: center;
  justify-content: space-between;
  padding-bottom: 9px;
`

export const NumberOfPracticesText = styled(BlackLargeInputText)``

export const ScrollablePracticesList = styled.div`
  ${ColumnFlexStyle}
  ${ScrollStyle}
  width: 100%;
`
