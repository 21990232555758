import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import CelebrationIcon from '@mui/icons-material/Celebration'
import CompareIcon from '@mui/icons-material/Compare'
import DataObjectIcon from '@mui/icons-material/DataObject'
import EditIcon from '@mui/icons-material/Edit'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import PostAddIcon from '@mui/icons-material/PostAdd'
import PsychologyIcon from '@mui/icons-material/Psychology'
import styled, { css } from 'styled-components/macro'
import {
  AutoHorizontalMargins,
  BaseDiv,
  BasePadding,
  BaseWidth,
  BottomBorderStyle,
  CardStyle,
  ColumnFlexStyle,
  GreyParagraphText,
  GreyTitleText,
  InterSubsectionalGap,
  IntersectionalGap,
  IntraSubsectionalGap,
  IntrasectionalGap,
  LargeIconStyle,
  LargeParagraphText,
  LightPrimaryHorizontalGradientBackgroundStyle,
  ParagraphText,
  PrimaryButton,
  RowFlexStyle,
  SubtitleText,
  TextButton,
  TitleText,
} from '../../styles/shared-styled-components'

export const HeaderContainer = styled.div`
  width: 100%;
  height: 220px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${LightPrimaryHorizontalGradientBackgroundStyle}
`

export const HeaderImageContainer = styled.div`
  ${RowFlexStyle}
  justify-content: flex-end;
  flex: 1;
`

export const HeaderImage = styled.img`
  ${(props) =>
    !props.ismobilescreen
      ? css`
          // Normal  screen
          height: 170px;
          padding-right: 30px;
        `
      : props.issmallmobilescreen
        ? css`
            // Small mobile screen
            height: 90px;
            padding-right: 10px;
          `
        : css`
            // Normal mobile screen
            height: 130px;
            padding-right: 10px;
          `}
`

export const HeaderContentContainer = styled.div`
  ${ColumnFlexStyle}
  height: 100%;
  flex: 1;
  align-items: flex-start;
  ${InterSubsectionalGap}
  justify-content: center;
  ${(props) =>
    !props.ismobilescreen
      ? css`
          padding-left: 30px;
        `
      : css`
          padding-left: 10px;
        `}
`

export const HeaderTitleText = styled(TitleText)`
  color: ${({ theme }) => theme.color.primary};
  font-weight: bold;
  text-align: inherit;
  font-size: ${(props) =>
    props.ismobilescreen && props.theme.typography.titleFontSize};
`

export const LetsBeginButton = styled(PrimaryButton)`
  width: ${(props) => (props.ismobilescreen ? '170px' : '250px')};
`

export const DentacloudNetworkContainer = styled.div`
  ${ColumnFlexStyle}
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.ismobilescreen
      ? css`
          ${InterSubsectionalGap}
        `
      : css`
          ${IntersectionalGap}
        `}
  width: 100%;
  ${BottomBorderStyle}
  padding-top: ${({ theme }) => `${theme.spacing.largeVerticalMargin}px`};
  // Leaving some room for the browse listings container
  height: ${(props) => !props.ismobilescreen && props.remainingHeight - 221}px;
`

export const DentacloudNetworkTitleText = styled(SubtitleText)`
  color: ${({ theme }) => theme.color.black};
  width: 80%;
`

export const PowersContainer = styled.div`
  ${RowFlexStyle}
  ${IntraSubsectionalGap}
  flex-wrap: wrap;
`

export const PowerContainer = styled.div`
  ${ColumnFlexStyle}
  width: 300px;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 10px;
  ${AutoHorizontalMargins}
  margin-bottom: ${({ theme }) => `${theme.spacing.largeVerticalMargin}px`};
`

export const PowerDescriptionText = styled(LargeParagraphText)`
  margin-top: 10px;
  display: flex;
  width: 90%;
  text-align: center;
`

const ContentSectionIconStyle = css`
  width: 60px;
  height: 60px;
`

export const DatasetIcon = styled(DataObjectIcon)`
  color: ${({ theme }) => theme.color.primary};
  ${ContentSectionIconStyle}
`

export const BrainIcon = styled(PsychologyIcon)`
  color: ${({ theme }) => theme.color.primary};
  ${ContentSectionIconStyle}
`

export const MoneyIcon = styled(AttachMoneyIcon)`
  color: ${({ theme }) => theme.color.primary};
  ${ContentSectionIconStyle}
`

export const BrowseListingsContainer = styled.div`
  background-color: ${({ theme }) => theme.color.superLightGrey};
  ${ColumnFlexStyle}
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: ${({ theme }) => `${theme.spacing.desktopTopMargin}px`};
  padding-left: 30px;
  padding-bottom: ${(props) =>
    props.ismobilescreen
      ? props.theme.spacing.desktopTopMargin * 2
      : props.theme.spacing.desktopTopMargin}px;
`

export const BrowseListingsTitleText = styled(DentacloudNetworkTitleText)`
  width: auto;
  margin-bottom: 10px;
`

export const BrowseListingsSubtitleText = styled(GreyTitleText)`
  margin-bottom: 30px;
`

export const TopCardSection = styled(BaseDiv)`
  ${(props) =>
    !props.cardsAreFolded
      ? css`
          ${RowFlexStyle}
          ${InterSubsectionalGap}
          justify-content: space-evenly;
        `
      : css`
          ${ColumnFlexStyle}
          ${IntraSubsectionalGap}
        `}
`

export const TopCard = styled.div`
  ${ColumnFlexStyle}
  ${CardStyle}
  ${IntrasectionalGap}
  ${(props) =>
    props.isFolded
      ? css`
          ${BaseWidth}
          height: 240px;
        `
      : css`
          width: 45%;
        `}
  align-items: stretch;
`

export const CardNavigationContainer = styled.div`
  ${RowFlexStyle}
  justify-content: center;
`

export const CardNavigationButton = styled(TextButton)`
  color: ${({ theme }) => theme.color.primary};
  padding: 0px;
  width: 40px;
`

export const PencilIcon = styled(EditIcon)`
  ${LargeIconStyle}
`

export const PostListingIcon = styled(PostAddIcon)`
  ${LargeIconStyle}
`

export const UnderReviewIcon = styled(HourglassBottomIcon)`
  ${LargeIconStyle}
`

export const ListingLiveIcon = styled(MonetizationOnIcon)`
  ${LargeIconStyle}
`

export const SoldIcon = styled(CelebrationIcon)`
  ${LargeIconStyle}
`

export const CardTextContainer = styled.div`
  ${ColumnFlexStyle}
  ${IntraSubsectionalGap}
  flex: 1;
`

export const CardTitleText = styled(ParagraphText)`
  font-size: ${({ theme }) => theme.typography.titleFontSize};
  color: ${({ theme }) => theme.color.black};
  font-weight: bolder;
`

export const CardSubtitleText = styled(GreyParagraphText)``

export const CardButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const MirrorIcon = styled(CompareIcon)`
  ${LargeIconStyle}
`

export const PracticesSection = styled.div`
  ${BaseWidth}
  ${BasePadding}
  ${IntrasectionalGap}
  ${ColumnFlexStyle}
`

export const MyPracticesTitleSection = styled.div`
  ${RowFlexStyle}
  justify-content: space-between;
`

export const MyPracticesTitleText = styled(TitleText)``

export const PracticeContainer = styled.div`
  ${ColumnFlexStyle}
  ${IntrasectionalGap}
  margin-bottom: 30px;
`
