import { useQueryWithErrorLogging } from '../utils/hooks'
import get from 'lodash/get'
import { useNavigate, useParams } from 'react-router-dom'
import Paths from '../Paths'
import { GET_NOTIFICATION_BY_ID } from '../api/queries/notifications'

const ExternalLinkHandler = () => {
  const { linkId } = useParams()
  const navigate = useNavigate()
  const { data, error, loading } = useQueryWithErrorLogging(
    GET_NOTIFICATION_BY_ID,
    {
      fetchPolicy: 'network-only',
      variables: {
        id: linkId,
      },
    },
  )

  if (loading) {
    return <div>Loading...</div>
  }
  if (error) {
    return <div>Error loading your link: {error.message}</div>
  }

  let notificationData = get(data, 'getNotificationById', [])

  console.log('external link handler has retrieved user notification')
  console.log(notificationData)

  navigate(Paths.internalLinkHandler, {
    state: { notification: notificationData },
  })
  return null
}

export default ExternalLinkHandler
