import { useMutationWithErrorLogging } from '../../utils/hooks'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useBuyerById } from '../../api/hooks/buyers'
import {
  UPDATE_INSTITUTIONAL_BUYERS,
  UPLOAD_BUYER_FILE,
  UPLOAD_BUYER_PROFILE_PICTURE,
} from '../../api/mutations/buyers'
import LoadingIndicator from '../../components/LoadingIndicator'
import NDAModal from '../../components/Micro/MyAcquisition/NDAModal'
import FileThumbnail from '../../components/Micro/Thumbnails/FileThumbnail/FileThumbnail'
import Header from '../../components/NavBar/Header'
import {
  BackgroundContainer,
  ErrorHelperText,
  PageContainer,
} from '../../styles/shared-styled-components'
import { getCapitalizedWord } from '../../utils/naming'
import folderIcon from './folder.svg'
import personIcon from './person.svg'
import {
  BasicLabel,
  CancelButton,
  ColumnFlexContainer,
  FlexContainer,
  FullPageFlexContainer,
  NextButton,
  NumericTextField,
  ResponsiveFlexContainer,
  StyledAvatar,
  StyledBasicLabel,
  StyledDropBoxWrapper,
  StyledFolderIcon,
  StyledLabel,
  StyledNoteLabel,
  StyledPhotoLabel,
  StyledUploadContainer,
  TitleText,
  Wrapper,
} from './styled'
import { usePracticeById } from '../../api/hooks/practices'
import { buyerTypes, valueGoalKey } from '../../utils/constants'
import userEvent from '@testing-library/user-event'

function addCommas(num) {
  const returnVal = num?.toLocaleString()
  return returnVal
}

function removeNonNumericalValues(str) {
  let value = str
  if (typeof str === 'number') {
    value = str.toString()
  }

  const returnVal = parseFloat(value?.replace(/\D/g, '')) || undefined
  return returnVal
}

const BuyerProfile = ({
  user,
  width,
  ismobilescreen,
  buyerProfileMade,
  setbuyerProfileMade,
}) => {
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false)
  const [profile, setProfile] = useState(null)
  const [errors, setErrors] = useState(null)
  const [uploadingAvatar, setUploadingAvatar] = useState(false)
  const [uploadingFile, setUploadingFile] = useState(false)
  const [showNdaPopup, setShowNdaPopup] = useState(false)

  const { buyerId } = useParams()
  const { buyer } = useBuyerById(buyerId)
  const { search } = useLocation()

  const queryParams = new URLSearchParams(search)
  const practiceId = queryParams.get('practice_id')

  const [updateBuyerMutation] = useMutationWithErrorLogging(
    UPDATE_INSTITUTIONAL_BUYERS,
  )
  const [uploadBuyerProfilePicture] = useMutationWithErrorLogging(
    UPLOAD_BUYER_PROFILE_PICTURE,
  )

  const yearsInBusinessRef = useRef()
  useMemo(() => {
    yearsInBusinessRef.current?.addEventListener('keypress', function (evt) {
      if (evt.which < 48 || evt.which > 57) {
        evt.preventDefault()
      }
    })
  }, [yearsInBusinessRef])

  const grossRevenueRef = useRef()
  useMemo(() => {
    yearsInBusinessRef.current?.addEventListener('keypress', function (evt) {
      if (evt.which < 48 || evt.which > 57) {
        evt.preventDefault()
      }
    })
  }, [grossRevenueRef])

  const estimatedPracticeRef = useRef()
  useMemo(() => {
    estimatedPracticeRef.current?.addEventListener('keypress', function (evt) {
      if (evt.which < 48 || evt.which > 57) {
        evt.preventDefault()
      }
    })
  }, [estimatedPracticeRef])

  const handleNumericalInput = (e) => {
    const value = removeNonNumericalValues(e.target.value)
    setProfile({
      id: buyerId,
      ...profile,
      [e.target.name]: value,
    })
  }

  const handleInput = (e) => {
    setProfile({
      id: buyerId,
      ...profile,
      [e.target.name]: e.target.value,
    })
  }

  const validate = useCallback(() => {
    const errors = {}
    let hasErrors = false

    if (!profile) {
      errors.general = 'Profile is missing.'
    } else {
      const requiredFields = [
        'picture',
        'company_name',
        'num_practices_under_management',
        'years_in_business',
        'location',
        'website_link',
        'gross_revenue',
      ]

      requiredFields.forEach((field) => {
        if (typeof profile[field] === 'string') {
          if (!profile[field]?.trim()) {
            // handle the case where profile[field] is ia number (and so trim doesn't exist)
            errors[field] = `${getCapitalizedWord(
              field.replace(/_/g, ' '),
            )} is required.`
            hasErrors = true
          }
        }
      })
    }
    setErrors(errors)

    return !hasErrors
  }, [profile])

  const handleProfilePicture = useCallback(
    (e) => {
      const pictureFile = e.target.files[0]

      const reader = new FileReader()
      reader.readAsDataURL(pictureFile)
      reader.onloadend = async () => {
        try {
          setUploadingAvatar(true)
          await uploadBuyerProfilePicture({
            variables: {
              id: buyerId,
              picture: {
                file: reader.result,
              },
            },
            onCompleted: (data) => {
              const pictureUrl = data.uploadBuyerProfilePicture
              if (pictureUrl) {
                setProfile({ ...profile, picture: pictureUrl })
              }
              setUploadingAvatar(false)
            },
          })
        } catch (error) {
          console.error('Error uploading production report file:', error)
          setUploadingAvatar(false)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [profile, buyerId],
  )

  const onRemoveFile = () => {
    setProfile((old) => ({ ...old, file: null }))
  }

  useEffect(() => {
    if (hasAttemptedSubmit) validate()
  }, [profile, hasAttemptedSubmit, validate])

  const handleContinue = useCallback(() => {
    setHasAttemptedSubmit(true)
    if (uploadingAvatar || uploadingFile || !validate()) return
    updateBuyerMutation({
      variables: {
        id: buyerId,
        ...profile,
      },
      onCompleted: async (data) => {
        setShowNdaPopup(true)
      },
    })
  }, [uploadingAvatar, uploadingFile, profile, validate, updateBuyerMutation])

  return buyer ? (
    <>
      {showNdaPopup && (
        <NDAModal
          toggle={() => setShowNdaPopup(false)}
          buyerId={buyerId}
          practiceId={practiceId}
        />
      )}
      <Header user={user} width={width} ismobilescreen={ismobilescreen} />
      <BackgroundContainer>
        <PageContainer>
          <FullPageFlexContainer>
            <Wrapper>
              <FlexContainer>
                <>
                  <TitleText>Insitutional Profile</TitleText>

                  <ResponsiveFlexContainer>
                    <StyledUploadContainer>
                      <StyledLabel>Upload your Company Logo </StyledLabel>
                      <div style={{ position: 'relative' }}>
                        <StyledAvatar
                          className={
                            profile?.picture ? 'avatar' : 'placeholder'
                          }
                          src={
                            !uploadingAvatar && (profile?.picture ?? personIcon)
                          }
                        >
                          {uploadingAvatar && <LoadingIndicator size={30} />}
                        </StyledAvatar>
                        <StyledPhotoLabel>
                          <PhotoCameraIcon fontSize='small' />
                          <VisuallyHiddenInput
                            type='file'
                            onChange={handleProfilePicture}
                            error={errors?.picture}
                            helperText={errors?.picture}
                          />
                        </StyledPhotoLabel>
                      </div>
                      {errors?.picture && (
                        <ErrorHelperText style={{ color: 'red' }}>
                          Please upload a photo*
                        </ErrorHelperText>
                      )}
                    </StyledUploadContainer>

                    <ColumnFlexContainer>
                      <Box>
                        <FormControl fullWidth>
                          <TextField
                            name='company_name'
                            value={profile?.company_name}
                            label='Company Name*'
                            variant='outlined'
                            placeholder='Enter Company Name'
                            onChange={handleInput}
                            error={errors?.company_name}
                            helperText={errors?.company_name}
                          />
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl fullWidth>
                          <NumericTextField
                            ref={yearsInBusinessRef}
                            name='years_in_business'
                            value={addCommas(profile?.years_in_business)}
                            label='Years in the Business?*'
                            placeholder='Enter Estimated Years'
                            onChange={handleNumericalInput}
                            error={errors?.years_in_business}
                            helperText={errors?.years_in_business}
                          ></NumericTextField>
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl fullWidth>
                          <TextField
                            name='location'
                            value={profile?.location}
                            label='Where are You Located?*'
                            placeholder='Enter Location'
                            onChange={handleInput}
                            error={errors?.location}
                            helperText={errors?.location}
                          />
                        </FormControl>
                      </Box>
                      <ErrorHelperText
                        style={{ marginLeft: 12, marginTop: -10 }}
                      >
                        {errors?.procedures}
                      </ErrorHelperText>
                    </ColumnFlexContainer>

                    <ColumnFlexContainer>
                      <Box>
                        <FormControl fullWidth>
                          <NumericTextField
                            ref={grossRevenueRef}
                            name='gross_revenue'
                            value={addCommas(profile?.gross_revenue)}
                            label='Gross Revenue*'
                            variant='outlined'
                            placeholder='Enter Gross Revenue'
                            onChange={handleNumericalInput}
                            error={errors?.gross_revenue}
                            helperText={errors?.gross_revenue}
                          />
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl fullWidth>
                          <NumericTextField
                            ref={estimatedPracticeRef}
                            name='num_practices_under_management'
                            value={addCommas(
                              profile?.num_practices_under_management,
                            )}
                            label='Estimated Practices Under Management or Sold*'
                            variant='outlined'
                            placeholder='Enter an Estimated Value'
                            onChange={handleNumericalInput}
                            error={errors?.num_practices_under_management}
                            helperText={errors?.num_practices_under_management}
                          />
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl fullWidth>
                          <TextField
                            name='website_link'
                            value={profile?.website_link}
                            label='Enter Website link*'
                            variant='outlined'
                            placeholder='ex. www.dentacloud.ai'
                            onChange={handleInput}
                            error={errors?.website_link}
                            helperText={errors?.website_link}
                          />
                        </FormControl>
                      </Box>
                    </ColumnFlexContainer>
                  </ResponsiveFlexContainer>
                </>
              </FlexContainer>
            </Wrapper>
            <ResponsiveFlexContainer block footer>
              {ismobilescreen ? (
                <>
                  <NextButton
                    disabled={uploadingAvatar || uploadingFile}
                    onClick={handleContinue}
                  >
                    Continue
                  </NextButton>
                  <CancelButton
                    disabled={uploadingAvatar || uploadingFile}
                    onClick={() => window.history.back()}
                  >
                    Back
                  </CancelButton>
                </>
              ) : (
                <>
                  <CancelButton
                    disabled={uploadingAvatar || uploadingFile}
                    onClick={() => window.history.back()}
                  >
                    Back
                  </CancelButton>
                  <NextButton
                    disabled={uploadingAvatar || uploadingFile}
                    onClick={handleContinue}
                  >
                    Continue
                  </NextButton>
                </>
              )}
            </ResponsiveFlexContainer>
          </FullPageFlexContainer>
        </PageContainer>
      </BackgroundContainer>
    </>
  ) : (
    <LoadingIndicator fullScreen />
  )
}

export default BuyerProfile
