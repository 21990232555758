import React from 'react'
import { LockIconButton, StyledLockIcon } from './styled'

const LockIcon = ({
  text = null,
  size = 'md',
  style = {},
  iconStyle = {},
  onClick,
}) => {
  return (
    <LockIconButton size={size} style={style} onClick={onClick}>
      <StyledLockIcon style={iconStyle} />
      {text && text}
    </LockIconButton>
  )
}

export default LockIcon
