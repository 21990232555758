import styled, { css } from 'styled-components'
import { TextInput } from '../../../styles/shared-styled-components'

export const Box = styled.div`
  &.mt-2 {
    margin-top: 12px;
  }
`

export const UploadBox = styled.div`
  border-radius: 11.292px;
  border: 1.129px dashed #000;
  background: rgba(217, 217, 217, 0.5);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 320px;
  min-height: 220px;
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 768px) {
    width: 300px;
  }
  & svg {
    fill: #8b8b8b;
  }

  & label {
    background-color: transparent;
    border-radius: 11.292px;
    border: 1.129px solid #016299;
    color: #016299;
    box-shadow: 0px 4.51675px 4.51675px 0px rgba(0, 0, 0, 0.25);
    width: 107px;

    &.disabled {
      background-color: #ccc;
      border-color: #ccc;
      color: #666;
      cursor: not-allowed;
      pointer-events: none;
    }

    &:hover:not(.disabled) {
      color: #fff;
    }
  }
`

export const EmployeeTableInput = styled.input`
  min-width: 150px;
  border: 0px;
  padding: 10px;
  background: transparent;
  font-size: 16px;
  outline: none;
`
