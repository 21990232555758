import DoneAllIcon from '@mui/icons-material/DoneAll'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import _ from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAcquisition } from '../../../api/hooks/acquisitions'
import {
  acquisitionRequestsStatuses,
  getLabelFromKey,
  practiceTypes,
} from '../../../utils/constants'
import {
  formatNumberAbbreviation,
  getFormattedMoneyString,
} from '../../../utils/numbers'
import { getViewPracticePathFromId } from '../../../utils/routing'
import dentalOfficeIcon from './dentalOffice.svg'
import {
  PurchaseIntentPriceChip,
  RequestDescText,
  RequestTitle,
  StyledColumnContainer,
  StyledFlexContainer,
  StyledLabel,
  StyledSpan,
} from './styled'
import { useIsMobileScreen } from '../../../utils/view'

const AcquisitionListItem = ({ acquisition, onArchive, onFavorite }) => {
  const isMobileScreen = useIsMobileScreen()
  const navigate = useNavigate()
  const { cancelRequest } = useAcquisition()
  const { practice } = acquisition
  const [cancelingRequest, setCancelingRequest] = useState(false)

  const imageUrl = useMemo(() => {
    if (_.isEmpty(practice?.pictures)) return dentalOfficeIcon
    return practice.pictures[0]
  }, [practice])

  const handleRequestCancel = useCallback(async () => {
    setCancelingRequest(true)
    await cancelRequest(acquisition?.id)
    setCancelingRequest(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acquisition?.id])

  const handlePracticeClicked = useCallback(() => {
    if (!practice) return
    navigate(getViewPracticePathFromId(practice?.id))
  }, [practice, navigate])

  const isPurchaseIntent = useMemo(() => {
    return acquisition?.type === 'purchase_intent'
  }, [acquisition?.type])

  const isApproved = useMemo(() => {
    return (
      acquisition?.status?.toLowerCase() ===
        acquisitionRequestsStatuses.APPROVED ||
      (isPurchaseIntent &&
        (acquisition?.status?.toLowerCase() ===
          acquisitionRequestsStatuses.APPROVED ||
          acquisition?.status?.toLowerCase() ===
            acquisitionRequestsStatuses.ACCEPTED))
    )
  }, [acquisition?.status, isPurchaseIntent])

  return (
    practice && (
      <Box sx={{ margin: '10px', cursor: 'pointer' }}>
        <div
          style={{
            border: '1px dotted #016299',
            borderRadius: '4px',
            display: 'flex',
            gap: '20px',
            padding: '10px',
            background: '#EEF3F7',
          }}
        >
          {!isMobileScreen && (
            <div
              onClick={handlePracticeClicked}
              style={{
                width: '200px',
                height: '150px',
                background: '#AEC6D1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '4px',
                backgroundImage: `url(${
                  isApproved || isPurchaseIntent ? imageUrl : dentalOfficeIcon
                })`,
                backgroundSize: 'cover',
                backgroundPosition: 'top center',
              }}
            />
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '10px 0',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div onClick={handlePracticeClicked} style={{ flex: 1 }}>
                <RequestTitle>
                  {isApproved || isPurchaseIntent
                    ? practice.name
                    : `${getLabelFromKey(
                        practiceTypes,
                        practice?.type,
                        'abbreviated',
                      )} practice in ${practice.city}`}
                </RequestTitle>
                <div>
                  <RequestDescText>{practice.type}</RequestDescText>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {
                  // TODO(Aaron): I'm removing the ability to archive because it's not mobile friendly
                  // Users on mobile can accidentally archive the request and there is no way to undo it
                }
                {/* <IconButton color='primary' onClick={() => onArchive(acquisition)}>
                {acquisition.is_archived ? <InventoryIcon /> : <InventoryOutlinedIcon />}
              </IconButton> */}
                <IconButton
                  color='primary'
                  onClick={() => onFavorite(acquisition)}
                >
                  {acquisition.is_favorite ? (
                    <FavoriteIcon />
                  ) : (
                    <FavoriteBorderIcon />
                  )}
                </IconButton>
                {isApproved ? (
                  <Button
                    variant='contained'
                    style={{
                      background: '#25B34D',
                      textTransform: 'none',
                      marginLeft: '10px',
                      display: 'block',
                    }}
                    endIcon={<DoneAllIcon />}
                  >
                    {isPurchaseIntent ? 'Accepted' : 'Approved'}
                  </Button>
                ) : (
                  <>
                    {!isMobileScreen && (
                      // Request sent button
                      <Button
                        variant='contained'
                        disabled={cancelingRequest}
                        style={{
                          background: '#808080',
                          textTransform: 'none',
                          marginLeft: '10px',
                        }}
                      >
                        {cancelingRequest ? 'Please wait...' : 'Request sent'}
                      </Button>
                    )}
                    {/* Cancel button */}
                    <Button
                      variant='contained'
                      style={{
                        background: 'rgb(211, 47, 47)',
                        textTransform: 'none',
                        marginLeft: '10px',
                      }}
                      disabled={cancelingRequest}
                      onClick={() => {
                        if (
                          acquisition?.type === 'purchase_intent' &&
                          window.confirm(
                            'Are you sure you want to cancel this purchase intent?',
                          )
                        ) {
                          handleRequestCancel()
                        } else if (
                          acquisition?.type === 'access_request' &&
                          window.confirm(
                            'Are you sure you want to cancel this dataroom access request?',
                          )
                        ) {
                          handleRequestCancel()
                        }
                      }}
                    >
                      {cancelingRequest ? 'Please wait...' : 'Cancel'}
                    </Button>
                  </>
                )}
              </div>
            </div>
            {isPurchaseIntent && acquisition?.purchase_price && (
              <PurchaseIntentPriceChip
                style={{
                  margin: '10px 0',
                }}
              >
                Purchase Price:{' '}
                {getFormattedMoneyString(acquisition?.purchase_price)}
              </PurchaseIntentPriceChip>
            )}
            <StyledFlexContainer
              onClick={handlePracticeClicked}
              style={{ flex: 1, alignItems: 'flex-end' }}
            >
              <StyledColumnContainer>
                <StyledLabel>Target Sale Price</StyledLabel>
                <StyledSpan>
                  ${formatNumberAbbreviation(practice.targetPrice)}
                </StyledSpan>
              </StyledColumnContainer>
              <StyledColumnContainer>
                <StyledLabel>Sales</StyledLabel>
                <StyledSpan>
                  ${formatNumberAbbreviation(practice.averageAnnualBillings)}
                </StyledSpan>
              </StyledColumnContainer>
              <StyledColumnContainer>
                <StyledLabel>Operatories</StyledLabel>
                <StyledSpan>{practice.operatories}</StyledSpan>
              </StyledColumnContainer>
              <StyledColumnContainer>
                <StyledLabel>Hygiene %</StyledLabel>
                <StyledSpan>
                  {practice.hygieneRevenuePercentage * 100}%
                </StyledSpan>
              </StyledColumnContainer>
              <StyledColumnContainer>
                <StyledLabel>Estimated Valuation</StyledLabel>
                <StyledSpan>
                  {practice.mostRecentValuation?.upperBound
                    ? `$${formatNumberAbbreviation(
                        Number(practice.mostRecentValuation?.upperBound),
                      )}`
                    : '--'}
                </StyledSpan>
              </StyledColumnContainer>
              <StyledColumnContainer>
                {/* this is here so that the columns line up */}
              </StyledColumnContainer>
            </StyledFlexContainer>
          </div>
        </div>
      </Box>
    )
  )
}

export default AcquisitionListItem
