import React from 'react'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { BarContainer } from './styled'

// Register the components with Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
)

const BarChart = ({ ismobilescreen, lowerBound, upperBound, median }) => {
  const labels = ['Brokerage', 'Direct to DSO', 'Dentacloud']
  const stepSize = upperBound - median

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Values',
        data: [lowerBound, median, upperBound],
        backgroundColor: ['#E67823', '#AEC6CF', '#0B6BA5'],
        borderColor: ['#D08E52', '#8CA3AC', '#006196'],
        borderWidth: 1,
      },
    ],
  }

  const options = {
    animation: {
      duration: 2000,
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      title: {
        display: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: function (value, context) {
          return context.chart.data.labels[context.dataIndex]
        },
        color: '#000',
        font: {
          weight: 'bold',
          size: 15,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: false, // Do not begin at zero
        min: lowerBound - stepSize >= 0 ? lowerBound - stepSize : 0, // Ensure min is >= 0
        max: upperBound,
        ticks: {
          font: {
            size: 15,
          },
          stepSize: stepSize,
          callback: function (value) {
            return '$' + value.toLocaleString() // Format as currency
          },
        },
        grid: {
          display: false, // Remove the gridlines on y-axis
        },
      },
      x: {
        grid: {
          display: false, // Remove the gridlines on x-axis
        },
        ticks: {
          font: {
            size: 50, // Correct the property name for font size
          },
          display: false, // Hide the default x-axis labels
        },
      },
    },
    layout: {
      padding: {
        top: 30, // To allow "Dentacloud" text to be visible
      },
    },
  }

  return (
    <BarContainer ismobilescreen={ismobilescreen}>
      <Bar data={data} options={options} />
    </BarContainer>
  )
}

export default BarChart
