import React from 'react'
import { StyledRevealPriceButton } from './styled'

const RevealPriceButton = ({ onClick }) => {
  return (
    <StyledRevealPriceButton onClick={onClick}>
      Reveal price
    </StyledRevealPriceButton>
  )
}

export default RevealPriceButton
