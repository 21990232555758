import styled, { css } from 'styled-components/macro'
import { CreatePracticeForm } from '../../components/CreatePractice/CreatePracticeInput/styled'
import {
  AutoHorizontalMargins,
  BaseMargin,
  BasePadding,
  BaseWidth,
  BottomBorderStyle,
  ColumnFlexStyle,
  DefaultHeroSectionHeightStyle,
  Dropdown,
  GreyParagraphText,
  HeroImageStyle,
  InterSubsectionalGap,
  IntraSubsectionalGap,
  IntrasectionalGap,
  IntrasectionalGapValue,
  LargeInputText,
  LargeParagraphText,
  MicroGap,
  RowFlexStyle,
  TextStyle,
  TitleText,
  UnderlineStyle,
  PrimaryButton,
  SecondaryButton,
  PrimaryButtonStyle,
} from '../../styles/shared-styled-components'

export const HeroContainer = styled.div`
  ${HeroImageStyle}
  ${DefaultHeroSectionHeightStyle}
`

export const ViewAsTopBar = styled.div`
  ${ColumnFlexStyle}
  ${BaseWidth}
  ${BasePadding}
  ${BottomBorderStyle}
  padding-top: ${({ theme }) => theme.spacing.mobileTopMargin}px;
  padding-bottom: ${({ theme }) => theme.spacing.mobileTopMargin}px;
  ${(props) =>
    !props.viewingAs
      ? css`
          background-color: ${({ theme }) =>
            theme.color.superLightGreyWithTransparency};
        `
      : css`
          background-color: ${({ theme }) => theme.color.brightGreen};
          position: sticky;
          top: 0;
          z-index: 100;
        `}
  ${MicroGap}
`

export const ViewAsTopBarTopContainer = styled.div`
  ${RowFlexStyle}
  justify-content: space-between;
  align-items: center;
  ${MicroGap}
`

export const TopBarTitleTextContainer = styled.div`
  ${RowFlexStyle}
  align-items: center;
  height: 100%;
`

export const TopBarTitleText = styled(LargeParagraphText)`
  color: ${(props) => props.viewingAs && props.theme.color.white};
  font-size: ${(props) =>
    props.ismobilescreen && props.theme.typography.defaultFontSize};
`

export const ViewAsActionButtonContainer = styled.div`
  ${ColumnFlexStyle}
  justify-content: space-between;
  align-items: center;
`

export const ViewAsDropdownForm = styled(CreatePracticeForm)`
  ${(props) =>
    !props.ismobilescreen
      ? css`
          width: 260px;
        `
      : css`
          max-width: ${(props) => `${(props.width * 0.45).toFixed(0)}`}px;
        `}

  .MuiInputLabel-root {
    font-size: ${({ theme }) => theme.typography.defaultFontSize};
    color: ${(props) => props.viewingAs && props.theme.color.white};
  }
  .MuiOutlinedInput-input {
    font-size: ${({ theme }) => theme.typography.defaultFontSize};
    color: ${(props) => props.viewingAs && props.theme.color.white};
  }
  .MuiOutlinedInput-notchedOutline {
    border: ${(props) =>
      props.viewingAs && `1px solid ${props.theme.color.primary}`};
  }
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: ${(props) =>
      props.viewingAs && `1px solid ${props.theme.color.primary}`};
  }
`

export const ExitViewAsContainer = styled.div`
  ${RowFlexStyle}
  justify-content: flex-end;
`

export const ExitViewAsText = styled.span`
  ${UnderlineStyle}
  color: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.typography.typeFont};
  font-size: ${(props) =>
    props.ismobilescreen && props.theme.typography.defaultFontSize};
`

export const StyledDropdown = styled(Dropdown)`
  height: auto;
`

export const PracticeBoxContainer = styled.div`
  ${BaseWidth}
  ${BasePadding}
  margin: ${({ theme }) =>
    `${theme.spacing.margin54}px 0px ${theme.spacing.margin44}px 0px`};
`

export const PracticeBox = styled.div`
  border-radius: ${({ theme }) => theme.spacing.baseBorderRadius}px;
  border: 1px solid ${({ theme }) => theme.color.darkPrimary};
  gap: 40px;
  ${ColumnFlexStyle}
  // justify-content: start;
  ${(props) =>
    !props.ismobilescreen
      ? css`
          padding: ${({ theme }) => theme.spacing.desktopTopMargin}px
            ${({ theme }) => theme.spacing.desktopTopMargin * 3}px;
        `
      : css`
          width: fit-content;
          padding: ${({ theme }) => theme.spacing.desktopTopMargin}px
            ${({ theme }) => theme.spacing.desktopTopMargin}px;
        `}
  ${AutoHorizontalMargins}
  margin-bottom: 25px;
`

export const PracticeActionButtonsContainer = styled.div`
  ${RowFlexStyle}
  justify-content: flex-end;
  ${IntraSubsectionalGap}
`

const PracticeInfoSectionWidth = 500
const maxNumColumnsToDisplay = 2
const SectionColumnGap = 80

// Making sure the practice name is left-aligned with the first section
export const PracticeOverviewContainer = styled.div`
  ${ColumnFlexStyle}
  justify-content: space-between;
  ${AutoHorizontalMargins}
  ${MicroGap}
  ${(props) =>
    props.willDisplayOnlyOneColumn
      ? props.ismobilescreen
        ? css`
            width: 100%;
          `
        : css`
            min-width: ${PracticeInfoSectionWidth}px;
            width: fit-content;
          `
      : css`
          min-width: ${PracticeInfoSectionWidth * maxNumColumnsToDisplay +
          SectionColumnGap}px;
          width: fit-content;
        `}
`

export const PracticeTitleAndBadgeContainer = styled.div`
  ${RowFlexStyle}
  ${IntraSubsectionalGap}
  align-items: center;
`

export const PracticeSubtextContainer = styled.div`
  ${ColumnFlexStyle}
  ${MicroGap}
  width: fit-content;
`

export const PracticeWebsiteContainer = styled.div`
  ${RowFlexStyle}
  ${MicroGap}
`

export const PracticeWebsiteText = styled.a`
  ${UnderlineStyle}
  text-underline-offset: 2px;
  ${TextStyle}
  font-size: ${({ theme }) => theme.typography.sentenceFontSize};
  width: fit-content;
`

export const PracticeDateText = styled(GreyParagraphText)``

export const PracticeTitleText = styled(TitleText)`
  font-size: ${(props) => props.theme.typography.pageTitleMediumFontSize};
  text-align: start;
  font-size: 700;
`

export const PracticeInfoSections = styled.div`
  ${RowFlexStyle}
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: ${IntrasectionalGapValue};
  row-gap: 158px;
  align-items: flex-start;
  column-gap: ${SectionColumnGap}px;
  ${AutoHorizontalMargins}
`

export const PracticeInfoSection = styled.div`
  ${ColumnFlexStyle}
  ${IntrasectionalGap}
  justify-content: center;
  ${(props) =>
    !props.ismobilescreen
      ? css`
          width: 46%;
        `
      : css`
          width: 100%;
        `}
`

export const PracticeInfoSectionNameContainer = styled.div`
  ${RowFlexStyle}
  ${IntraSubsectionalGap}
`

export const PracticeInfoSectionTitleText = styled(LargeParagraphText)`
  font-weight: 700;
  color: ${({ theme }) => theme.color.black};
  font-size: ${(props) => props.theme.typography.pageTitleMediumFontSize};
`

export const FieldsSection = styled.div`
  ${ColumnFlexStyle}
  ${IntraSubsectionalGap}
`

export const FieldSection = styled.div`
  ${RowFlexStyle}
  justify-content: space-between;
  align-items: center;
  margin-top: 12.5px;
  margin-bottom: 12.5px;
  ${InterSubsectionalGap}

  @media (max-width: 768px) {
    ${ColumnFlexStyle}
  }
`

export const FieldTitleText = styled(LargeInputText)`
  font-size: ${({ theme }) => theme.typography.sentenceLargeFontSize};
  text-align: ${({ ismobilescreen }) => (ismobilescreen ? 'center' : 'left')};
`

export const FieldValueText = styled(FieldTitleText)`
  color: ${({ theme }) => theme.color.black};
  text-align: ${({ ismobilescreen }) => (ismobilescreen ? 'center' : 'end')};
`

export const PracticeHeaderSections = styled.div`
  ${RowFlexStyle}
  justify-content: space-between;
  ${AutoHorizontalMargins}
  margin-bottom: 25px;

  @media (max-width: 768px) {
    ${ColumnFlexStyle}
  }
`

export const PracticeInfoText = styled.div`
  ${ColumnFlexStyle}
  justify-content: space-between;
  ${MicroGap}
`

const RequestAccessButtonWidth = 250
const RequestAccessButtonHeight = 65
export const GetRequestAccessButton = styled(PrimaryButton)`
  gap: 5px;
  min-width: ${RequestAccessButtonWidth}px;
  width: fit-content;
  height: ${RequestAccessButtonHeight}px;
  padding: 5px 25px;
  font-weight: bold;
  font-size: ${({ theme }) => theme.typography.sentenceLargeFontSize};

  ${(props) =>
    props?.success &&
    css`
      background-color: ${({ theme }) => theme.color.brightGreen};
    `}
`

export const ValuationBox = styled.div`
  border-radius: ${({ theme }) => theme.spacing.baseBorderRadius}px;
  border: 1px solid ${({ theme }) => theme.color.darkPrimary};
  gap: 40px;
  ${RowFlexStyle}
  flex-wrap: wrap;
  justify-content: space-between;
  ${AutoHorizontalMargins}
  ${MicroGap}
  width: 90%;
  padding: 30px 20px;
  margin-bottom: 25px;
  ${AutoHorizontalMargins}
`

export const ValuationTextField = styled.div`
  font-weight: 500;
  padding: 10px 20px;
  font-family: Noto Sans KR;
  font-size: ${({ theme }) => theme.typography.sentenceLargeFontSize};
`

export const DueDiligenceBox = styled.div`
  border-radius: ${({ theme }) => theme.spacing.baseBorderRadius}px;
  border: 1px solid ${({ theme }) => theme.color.darkPrimary};
  ${ColumnFlexStyle}
  ${(props) =>
    !props.ismobilescreen
      ? css`
          padding: ${({ theme }) => theme.spacing.desktopTopMargin}px
            ${({ theme }) => theme.spacing.desktopTopMargin * 3}px;
        `
      : css`
          width: 100%t;
          padding: ${({ theme }) => theme.spacing.desktopTopMargin}px
            ${({ theme }) => theme.spacing.desktopTopMargin}px;
        `}
  ${AutoHorizontalMargins}
`

export const DueDiligenceTitleText = styled(LargeParagraphText)`
  font-weight: bold;
  color: ${({ theme }) => theme.color.black};
  font-size: ${({ theme }) => theme.typography.pageTitleMediumFontSize};
`

export const DueDiligenceContainer = styled(LargeParagraphText)`
  ${AutoHorizontalMargins}
  ${MicroGap}
  margin-top: 28px;
  margin-bottom: 48px;
`

const DueDiligenceButtonWidth = 180
const DueDiligenceButtonHeight = 65
export const DueDiligenceButton = styled(SecondaryButton)`
  justify-content: space-around;
  gap: 5px;
  min-width: ${DueDiligenceButtonWidth}px;
  width: fit-content;
  height: ${DueDiligenceButtonHeight}px;
  padding: 5px 10px;
  font-weight: bold;

  ${(props) =>
    props.primary &&
    css`
      ${PrimaryButtonStyle}
    `}
  font-size: ${({ theme }) => theme.typography.sentenceLargeFontSize};
`
