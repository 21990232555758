import { useMutation, useQuery } from '@apollo/client'
import Bugsnag from '@bugsnag/js'
import { useRef } from 'react'

export function useFirstRender() {
  const ref = useRef(true)
  const firstRender = ref.current
  ref.current = false
  return firstRender
}

export function useQueryWithErrorLogging(query, options) {
  const result = useQuery(query, options)
  if (!options.skip) {
    if (process.env.REACT_APP_USE_BUGSNAG === '1') {
      Bugsnag.leaveBreadcrumb(query, options)
    }
    if (result.error) {
      console.log(result.error)
      if (process.env.REACT_APP_USE_BUGSNAG === '1') {
        Bugsnag.notify(result.error)
      }
    }
  }

  return result
}

export function useMutationWithErrorLogging(query) {
  const [executeMutationFunction, mutationResult] = useMutation(query)
  const wrappedMutationFunction = (...args) => {
    if (process.env.REACT_APP_USE_BUGSNAG === '1') {
      Bugsnag.leaveBreadcrumb(query)
    }
    const result = executeMutationFunction(...args)
    result.catch((err) => {
      console.error(err)
      if (process.env.REACT_APP_USE_BUGSNAG === '1') {
        Bugsnag.notify(err)
      }
      throw err
    })
    return result
  }

  return [wrappedMutationFunction, mutationResult]
}
