import {
  BuyerProfileInputGroup,
  BuyerProfileInputTitle,
  BuyerProfileInput,
} from './styled'

const CreateBuyerProfileInput = ({ label, options }) => {
  return (
    <>
      <BuyerProfileInputGroup>
        <BuyerProfileInputTitle>{label}</BuyerProfileInputTitle>
        <BuyerProfileInput>
          <ul>
            {options.map(({ key, label }) => {
              return (
                <li key={key}>
                  <input type='checkbox' id={key} value={label} />
                  <label for={key}>{label}</label>
                </li>
              )
            })}
          </ul>
        </BuyerProfileInput>
      </BuyerProfileInputGroup>
    </>
  )
}
export default CreateBuyerProfileInput
