import styled, { css } from 'styled-components/macro'
import {
  Checkbox,
  BaseDiv,
  RowFlexStyle,
  PrimaryButton,
  SecondaryButton,
  IndentedContentContainer,
  AutoHorizontalMargins,
} from '../../../styles/shared-styled-components'
import { FormControlLabel } from '@mui/material'

export const ErrorMessageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
`

export const NdaLink = styled.a`
  color: ${({ theme }) => theme.color.primary};
`

export const NdaContainer = styled(FormControlLabel)`
  margin-top: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 2px solid ${({ theme }) => theme.color.primary};
`

export const GoalsButtonContainer = styled(BaseDiv)`
  ${RowFlexStyle}
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0px;
  right: ${({ ismobilescreen }) => !ismobilescreen && '10px'};
  padding: 10px;
  background-color: ${({ theme }) => theme.color.white};
`

const GoalsButtonWidth = css`
  width: 175px;
`

export const GoalAndNdaFormContainer = styled(IndentedContentContainer)`
  padding-right: ${(props) => !props.ismobilescreen && '50px'};
  align-items: ${(props) => props.ismobilescreen && 'center'};
  flex: 1;
  ${(props) =>
    props.ismobilescreen &&
    css`
      ${AutoHorizontalMargins}
    `}
  // Combined these flags make sure the top and bottom sections are aligned from page-to-page
  height: 600px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
`

const GoalsButtonStyle = css`
  height: 50px;
  ${GoalsButtonWidth}
`

export const ContinueButton = styled(PrimaryButton)`
  ${GoalsButtonStyle};
  margin-left: ${({ ismobilescreen }) => ismobilescreen && '5px'};
`

export const BackButton = styled(SecondaryButton)`
  ${GoalsButtonStyle};
  margin-right: ${({ ismobilescreen }) => ismobilescreen && '5px'};
`
