import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import styled from 'styled-components/macro'
import {
  MediumIconStyle,
  RowFlexStyle,
} from '../../../styles/shared-styled-components'

export const StepsContainer = styled.div`
  ${RowFlexStyle}
  justify-content: flex-start;
`

export const StepCircle = styled.div`
  border-radius: 40px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.typography.typeFont};
  background-color: ${(props) =>
    props.completed || props.selected
      ? props.theme.color.primary
      : props.theme.color.white};
  color: ${(props) =>
    props.selected || props.completed
      ? props.theme.color.white
      : props.theme.color.primary};
  border: ${(props) =>
    !props.selected &&
    !props.completed &&
    `1px solid ${props.theme.color.primary}`};
`

export const StepTransitionLineContainer = styled.div`
  display: flex;
  align-items: center;
`

export const StepTransitionLine = styled.hr`
  margin: 0px;
  border-width: 1px 0px 0px 0px;
  border-color: ${({ theme }) => theme.color.primary};
  width: 13vw;
  max-width: 165px;
`

export const CheckmarkIcon = styled(DoneOutlinedIcon)`
  ${MediumIconStyle}
`
