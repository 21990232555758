import gql from 'graphql-tag'

export const GET_MY_USER = gql`
  query getMyUser {
    getMyUser {
      id
      role
      email
      hasOnboarded
      signedEmaca
      signedNda
      firstName
      lastName
      phone
      phoneVerified
      isLicensedToPractice
      hasCreatedValuation
      goals {
        id
        mainGoal
        subGoal
      }
      license {
        licensingBody
        licensingProvinceOrState
        licensingCountry
        licenseNumber
        licenseVerified
      }
      linkedinUrl
    }
  }
`

export const USER_IS_MWA_OR_LOGIN = gql`
  query getMyUser {
    getMyUser {
      id
      is_mwa
      has_logged_in_to_connect
    }
  }
`

export const CONNECT_USER = gql`
  mutation RecordUserHasLoggedIntoConnect {
    recordUserHasLoggedIntoConnect {
      has_logged_in_to_connect
    }
  }
`

export const MAP_FILTER_POP_UP = gql`
  query getMyUser {
    getMyUser {
      id
      has_seen_off_market_pop_up
      has_seen_sold_pop_up
    }
  }
`

export const HAS_SEEN_OFF_MARKET = gql`
  mutation RecordUserHasSeenOffMarketPopUp {
    recordUserHasSeenOffMarketPopUp {
      has_seen_off_market_pop_up
    }
  }
`

export const HAS_SEEN_SOLD = gql`
  mutation RecordUserHasSeenSoldPopUp {
    recordUserHasSeenSoldPopUp {
      has_seen_sold_pop_up
    }
  }
`

export const GET_USER_STAFF_VIEW = gql`
  query getAllUsers {
    getAllUsers {
      id
      email
      firstName
      lastName
      phone
    }
  }
`
