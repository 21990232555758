import { gql } from '@apollo/client'

export const ListingFragment = gql`
  fragment ListingFragment on Practice {
    listing {
      type
      status
      reviewedBy
      reviewNotes
      postedAt
      createdAt
      updatedAt
      deletedAt
    }
  }
`

export const ShallowListingFragment = gql`
  fragment ShallowListingFragment on Practice {
    listing {
      type
      status
      postedAt
      soldAt
    }
  }
`

export const MostRecentValuationFragment = gql`
  fragment MostRecentValuationFragment on Practice {
    mostRecentValuation {
      id
      lowerBound
      upperBound
      createdAt
      updatedAt
    }
  }
`
