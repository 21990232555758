import { valueIsEmpty } from './data'

export const checkIfValidEvent = (code) => {
  const allowedActionCodes = [
    'Backspace',
    'KeyR',
    'KeyC',
    'KeyV',
    'Tab',
    'Enter',
    'ArrowLeft',
    'ArrowRight',
    'KeyA',
  ]

  if (allowedActionCodes.includes(code)) {
    return true
  }
}

export const checkIfNumericInput = (event) => {
  const { key, code } = event

  const isValidEvent = checkIfValidEvent(code)
  if (isValidEvent) {
    return true
  }

  // Use an integer-only regex
  return /^\d+$/.test(key)
}

export const checkIfValidCanadianPostalCodeInput = (event) => {
  const { key, type, code, target } = event
  const { value: wholeString } = target

  const isValidEvent = checkIfValidEvent(code, type)

  // Unfortunately they can still type 'a', as this is needed to be allowed if they want to select all the text:
  // Cmd+a -- we check events one-by-one, so we'd have to remove that functionality
  if (wholeString.length >= 6 && !isValidEvent) {
    return false
  }

  if (isValidEvent) {
    return true
  }

  // Regular expression pattern that allows numbers and letters
  const pattern = /^[A-Za-z0-9]+$/

  // Check if the input matches the pattern
  const isValid = pattern.test(key)

  return isValid
}

const removeTypenameFieldFromMutationInputObject = (inputObject) => {
  const newInput = {
    ...inputObject,
  }
  if (newInput.hasOwnProperty('__typename')) {
    delete newInput.__typename
  }
  return newInput
}

export const removeTypenameFieldFromMutationInputArray = (inputArray) => {
  const updatedInputArray = inputArray.map((input) => {
    return removeTypenameFieldFromMutationInputObject(input)
  })

  return updatedInputArray
}

export const formatMutationInputRangeArray = (inputArray) => {
  return {
    min: inputArray[0],
    max: inputArray[1],
  }
}

export const handleIntegerFieldChange = (e, setStateCallback) => {
  const { value } = e.target

  if (Number(value) || value === '0') {
    setStateCallback(parseInt(value))
  } else if (value === '') {
    setStateCallback()
  }
}

export const handlePercentageFieldChange = (e, setStateCallback) => {
  const { value } = e.target

  if (valueIsEmpty(value)) {
    setStateCallback('')
    return
  }

  const numberValue = value === '0' ? 0 : Number(value)

  if (numberValue != null && numberValue !== '' && !(numberValue > 100)) {
    setStateCallback(parseInt(value))
  } else if (value === '') {
    setStateCallback()
  }
}

export const checkIfValidCanadianPostalCode = (postalCode) => {
  const formattedPostalCode = postalCode.replace(' ', '')

  if (formattedPostalCode.length !== 6) {
    return false
  }

  return /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(
    formattedPostalCode,
  )
}

export const checkIfValidAmericanZipCode = (zipCode) => {
  const formattedZipCode = zipCode.replace(' ', '')

  // Regular expression to match the format of a valid US Zip code.
  const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/

  return zipCodeRegex.test(formattedZipCode)
}

export const getFileNameFromUrl = (url, fileType) => {
  const filenameRegex =
    fileType === 'image'
      ? /\/([^/]+)\.(png|jpg|jpeg)$/i
      : /\/([^/]+)\.(pdf|csv|xls)$/i
  const match = url.match(filenameRegex)

  if (match && match.length > 1) {
    return match[1]
  } else {
    return ''
  }
}

export const getIdOfFirstInputFieldWithError = (inputs) => {
  let idOfFirstFieldWithError

  let idx = 0
  while (!idOfFirstFieldWithError && idx < inputs.length) {
    const field = inputs[idx]
    const { state, id } = field

    if (valueIsEmpty(state)) {
      idOfFirstFieldWithError = id
    }
    idx += 1
  }

  return idOfFirstFieldWithError
}

const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/i

export const getFormattedUrl = (website) => {
  try {
    let formattedWebsite = website.trim() // Remove leading and trailing whitespace
    formattedWebsite = formattedWebsite.replace(/\/+$/, '/') // Strip multiple trailing slashes

    if (!/^https?:\/\//i.test(formattedWebsite)) {
      // If "http://" or "https://" is not already added, prepend "https://"
      formattedWebsite = `https://${formattedWebsite}`
    }

    const url = new URL(formattedWebsite)

    const link = url?.href

    if (!urlPattern.test(link)) {
      return null
    }

    return link
  } catch (err) {
    return null
  }
}

export const wrapLinksInATag = (inputString) => {
  // Define a regular expression pattern to match URLs
  const urlRegex = /(https?:\/\/[^\s]+)/g

  // Replace URLs with anchor tags
  const resultString = inputString.replace(
    urlRegex,
    '<a href="$1" target="_blank">$1</a>',
  )

  return resultString
}

export default {
  checkIfNumericInput,
  checkIfValidEvent,
  formatMutationInputRangeArray,
  removeTypenameFieldFromMutationInputArray,
  handleIntegerFieldChange,
  handlePercentageFieldChange,
  checkIfValidCanadianPostalCode,
  getFileNameFromUrl,
  getIdOfFirstInputFieldWithError,
  checkIfValidAmericanZipCode,
  wrapLinksInATag,
}
