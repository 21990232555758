import MarkerClusterer from 'marker-clusterer-plus'
import { useEffect, useRef, useState } from 'react'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { useSearchParams } from 'react-router-dom'
import Paths from '../../../Paths'
import { useMyUser } from '../../../api/hooks/users'
import {
  clusterChipURLs,
  forSaleStageKey,
  listingTypeChipSvgMap,
  offMarketStageKey,
  soldStageKey,
  torontoLatLon,
} from '../../../utils/constants'
import {
  getPixelPositionFromLatLng,
  getProvinceOrStateFromGeocodedAddress,
} from '../../../utils/location'
import {
  getAbbreviatedMoneyString,
  getAnonymizedAndAbbreviatedMoneyString,
} from '../../../utils/numbers'
import { updateSearchParams } from '../../../utils/routing'
import { markerClusterHeight, practiceMarkerHeight } from '../../../utils/view'
import LoadingIndicator from '../../LoadingIndicator'
import Snackbar from '../../Snackbar'
import ListingTypeToggle from '../ListingTypeToggle'
import LocationAnalyticsModal from '../LocationAnalyticsModal/LocationAnalyticsModal'
import PracticePreviewThumbnail from '../PracticePreviewThumbnail'
import RecenterMapChip from '../RecenterMapChip'
import coordinates from './ontario-border-coordinates'
import { MapComponent, MapContainer } from './styled'
import {
  EmptySearchIcon,
  MapEmptyResultsWrapper,
  NoDataMessageContainer,
  ResetFiltersButton,
} from '../NoDataView/styled'
import { TitleText } from '../../../styles/shared-styled-components'

const defaultZoomLevel = 8
const displayClusteredPracticesThumbnailZoomThreshold = 7
const minZoom = 4
const maxZoom = 12
// Anything more than this seems to no longer pan smoothly
const focusZoomStep = 3
const maxNumPracticesToDisplayThumbnailThreshold = 40
const alreadyRecenteredSnackbarMessage = 'Already re-centered'

// rather unintuitively, you can set the z index on the individual chips (i.e. one practice)
// but not the cluster markers (i.e. multiple practices)
// this is a little hacky, but it turns out that the render order of the clusters determines the stacking
// so we sort the practices by listing type,
// then when we declare the markers, we set the z index
// importantly, we want clusters to appear over chips so that the user is encouraged to zoom deeper
// or work the filters to uncover them
const clusterChipOrdering = {
  [offMarketStageKey]: 4,
  [forSaleStageKey]: 5,
}

/**
 * A container for multiple marker clusterers
 * This supports having multiple clusters on the map at the same time
 * @class
 */
class MultipleMarkerClusterer {
  clusters

  /**
   * Creates an instance of MultipleMarkerCluster.
   * @constructor
   * @param {MarkerClusterer[]} clusters - An array of MarkerClusterer objects.
   */
  constructor(clusters) {
    if (!Array.isArray(clusters)) {
      throw new Error('Clusters must be an array of MarkerClusterer objects')
    }

    if (!clusters.every((cluster) => cluster instanceof MarkerClusterer)) {
      throw new Error(
        'All items in the clusters array must be instances of MarkerClusterer',
      )
    }

    this.clusters = clusters
  }

  clearMarkers() {
    this.clusters.forEach((cluster) => cluster.clearMarkers())
  }

  setMap(map) {
    this.clusters.forEach((cluster) => cluster.setMap(map))
  }
}

const getDefaultCoordinates = (firstPractice) => {
  return {
    lat: firstPractice?.coordinates[0] ?? torontoLatLon.lat,
    lng: firstPractice?.coordinates[1] ?? torontoLatLon.lng,
  }
}

const drawPolygon = (map) => {
  // Define the LatLng coordinates for the polygon's path.
  var triangleCoords = [
    { lat: 51.183, lng: -114.234 },
    { lat: 51.154, lng: -114.235 },
    { lat: 51.156, lng: -114.261 },
    { lat: 51.104, lng: -114.259 },
    { lat: 51.106, lng: -114.261 },
    { lat: 51.102, lng: -114.272 },
    { lat: 51.081, lng: -114.271 },
    { lat: 51.081, lng: -114.234 },
    { lat: 51.009, lng: -114.236 },
    { lat: 51.008, lng: -114.141 },
    { lat: 50.995, lng: -114.142 },
    { lat: 50.998, lng: -114.16 },
    { lat: 50.984, lng: -114.163 },
    { lat: 50.987, lng: -114.141 },
    { lat: 50.979, lng: -114.141 },
    { lat: 50.921, lng: -114.141 },
    { lat: 50.921, lng: -114.21 },
    { lat: 50.893, lng: -114.21 },
    { lat: 50.892, lng: -114.14 },
    { lat: 50.888, lng: -114.139 },
    { lat: 50.878, lng: -114.094 },
    { lat: 50.878, lng: -113.994 },
    { lat: 50.84, lng: -113.954 },
    { lat: 50.854, lng: -113.905 },
    { lat: 50.922, lng: -113.906 },
    { lat: 50.935, lng: -113.877 },
    { lat: 50.943, lng: -113.877 },
    { lat: 50.955, lng: -113.912 },
    { lat: 51.183, lng: -113.91 },
  ]

  // Construct the polygon.
  var bermudaTriangle = new window.google.maps.Polygon({
    paths: coordinates,
    strokeColor: 'blue',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillOpacity: 0,
  })
  bermudaTriangle.setMap(map)
}

const Map = ({
  user,
  ismobilescreen,
  width,
  practices,
  practicesLoading,
  listingTypeFilter,
  onChangeListingTypeFilter,
  zoomLevel = defaultZoomLevel,
  searchedLocation,
  onClickResetFilters,
  rounded,
}) => {
  const mapRef = useRef(null)

  const practicesByListingType = practices?.reduce((acc, practice) => {
    const listingType = practice.listingType
    if (!acc[listingType]) {
      acc[listingType] = []
    }
    acc[listingType].push(practice)
    return acc
  }, {})

  const hasCreatedValuation = !!user

  const [searchParams, setSearchParams] = useSearchParams()
  const { loginWithRedirect } = useMyUser().auth0Context

  const [hoveredPractices, setHoveredPractices] = useState({})
  const [centerCoordinates, setCenterCoordinates] = useState(
    !searchedLocation && practices?.length && torontoLatLon,
  )
  const [loadedMap, setLoadedMap] = useState()
  const [loadedMarkerCluster, setLoadedMarkerCluster] = useState()
  const [updatedZoomLevel, setUpdatedZoomLevel] = useState(zoomLevel)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [height, setHeight] = useState(window.innerHeight)
  const [locationAnalyticsModalOpen, setLocationAnalyticsModalOpen] =
    useState(false)

  const getMarkerCluster = (map) => {
    // rather unintuitively, you can set the z index on the individual chips (i.e. one practice)
    // but not the cluster markers (i.e. multiple practices)
    // this is a little hacky, but it turns out that the render order of the clusters determines the stacking
    // so we sort the practices by listing type,
    // then when we declare the markers, we set the z index
    // importantly, we want clusters to appear over chips so that the user is encouraged to zoom deeper
    // or work the filters to uncover them
    const practiceArrayArray = Object.keys(practicesByListingType).sort(
      (a, b) => clusterChipOrdering[a] - clusterChipOrdering[b],
    )
    const clusters = practiceArrayArray.map((listingType) => {
      const practices = practicesByListingType[listingType]
      return getMarkerClusterOnce(map, practices, listingType)
    })
    return new MultipleMarkerClusterer(clusters)
  }

  const getMarkerClusterOnce = (map, practices, listingType) => {
    const markers = practices.map((p) => {
      const formattedTargetPriceString = hasCreatedValuation
        ? getAbbreviatedMoneyString(p.targetPrice)
        : getAnonymizedAndAbbreviatedMoneyString(p.targetPrice)

      const listingType = p.listingType

      const marker = new window.google.maps.Marker({
        position: { lat: p.coordinates[0], lng: p.coordinates[1] },
        zIndex: clusterChipOrdering[listingType],
        map,
        icon: {
          url: listingTypeChipSvgMap[listingType],
          scaledSize: new window.google.maps.Size(65, practiceMarkerHeight),
        },
        label: {
          text: formattedTargetPriceString,
          color: 'white',
          fontSize: '12.5px',
          fontFamily: 'Noto Sans KR',
        },
        practiceData: p,
      })

      marker.addListener('click', () => handleMarkerClick(marker, map, p))

      return marker
    })

    const markerCluster = new MarkerClusterer(map, markers, {
      zoomOnClick: false,
      minimumClusterSize: 2,
      height: markerClusterHeight,
      averageCenter: true,
      width: 50,
      styles: [
        {
          textColor: 'white',
          textSize: 14,
          fontFamily: 'Noto Sans KR',
          url: clusterChipURLs[listingType],
          height: 35,
          width: 35,
        },
      ],
    })

    // Add cluster mouse event listeners
    markerCluster.addListener('clusterclick', (clickedCluster) => {
      const center = clickedCluster.getCenter()
      const clusterLatLng = new window.google.maps.LatLng(
        center.lat(),
        center.lng(),
      )
      const pixelLocation = getPixelPositionFromLatLng(
        clusterLatLng,
        map,
        window,
      )

      handleClusterClick(
        clickedCluster.center_.lat(),
        clickedCluster.center_.lng(),
        map,
        clickedCluster.getMarkers(),
        pixelLocation,
      )
    })

    markerCluster.addListener('clustermouseout', () => {
      setHoveredPractices({})
    })
    map.addListener('zoom_changed', function () {
      const zoom = map.getZoom()
      handleZoomChanged(zoom)
    })
    map.addListener('center_changed', () => {
      setHoveredPractices({})
    })

    return markerCluster
  }

  const handleWindowSizeChange = () => {
    setHeight(window.innerHeight)
  }

  // Mobile breakpoint logic
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  // This useEffect should only be ran once
  useEffect(() => {
    // Load the map and initialize markers
    const loadMap = (loadedMap) => {
      const mapOptions = {
        fullscreenControl: false,
        minZoom,
        maxZoom,
        clickableIcons: false,
        zoomControl: false,
        center: centerCoordinates,
        zoom: updatedZoomLevel,
        mapTypeControl: false,
        streetViewControl: false,
      }

      const map = loadedMap
        ? loadedMap
        : new window.google.maps.Map(document.getElementById('map'), mapOptions)
      mapRef.current = map

      // Create and add markers
      const markerCluster = getMarkerCluster(map)

      // drawPolygon(map);

      setLoadedMarkerCluster(markerCluster)
      setLoadedMap(map)
    }

    if (
      window.google &&
      window.google.maps &&
      // practices?.length &&
      !loadedMarkerCluster
    ) {
      loadMap(loadedMap)
    }
  }, [centerCoordinates, practices, loadedMarkerCluster, user])

  // This useEffect should be ran whenever practices gets updated
  useEffect(() => {
    if (loadedMarkerCluster) {
      setHoveredPractices({})

      loadedMarkerCluster.clearMarkers()
      loadedMarkerCluster.setMap(null)

      const updatedMarkerCluster = getMarkerCluster(loadedMap)
      updatedMarkerCluster.setMap(loadedMap)

      setLoadedMarkerCluster(updatedMarkerCluster)
      setLoadedMap(loadedMap)
    }
  }, [practices, user])

  useEffect(() => {
    async function updateFocusBasedOnSearchedLocation() {
      if (loadedMap) {
        if (searchedLocation) {
          const geocode = await geocodeByAddress(searchedLocation)
          const locationResult = geocode[0]

          const province = getProvinceOrStateFromGeocodedAddress(locationResult)

          const { lat, lng } = await getLatLng(locationResult)

          loadedMap.panTo({
            lat,
            lng,
          })
        }
      }
    }

    updateFocusBasedOnSearchedLocation()
  }, [searchedLocation, loadedMap])

  const handleClusterClick = (lat, lng, map, clusterMarkers, pixelLocation) => {
    const practices = clusterMarkers.map((cm) => cm.practiceData)

    const currentZoom = map.getZoom()

    if (
      // If they're zoomed out less than our display thumbnail threshold,
      // or if they have a lot of practices and they're not fully zoomed in, focus them
      currentZoom < displayClusteredPracticesThumbnailZoomThreshold ||
      (practices?.length > maxNumPracticesToDisplayThumbnailThreshold &&
        currentZoom < maxZoom - 1)
    ) {
      focusOnPractice(lat, lng, map, currentZoom)
    } else {
      setHoveredPractices({
        practices,
        x: pixelLocation.x,
        y: pixelLocation.y,
      })
    }
  }

  const focusOnPractice = async (
    latitude,
    longitude,
    map,
    currentZoom,
    useIncrementalZoom = true,
  ) => {
    // Make sure it always gets inside the threshold to be able to view practices
    const newZoom = useIncrementalZoom
      ? Math.max(
          currentZoom + focusZoomStep,
          displayClusteredPracticesThumbnailZoomThreshold,
        )
      : maxZoom

    map.panTo({
      lat: latitude,
      lng: longitude,
    })
    map.setZoom(newZoom)
  }

  const handleMarkerClick = (marker, map, practice) => {
    // Calculate the position for the menu based on the lat/lng of the pin
    const position = marker.position
    const clusterLatLng = new window.google.maps.LatLng(
      position.lat(),
      position.lng(),
    )
    const pixelLocation = getPixelPositionFromLatLng(clusterLatLng, map, window)

    // Seems like y
    const apparentVerticalOffset = 20
    setHoveredPractices({
      x: pixelLocation.x,
      y: pixelLocation.y - apparentVerticalOffset,
      practices: [practice],
    })
  }

  const handleZoomChanged = (zoom) => {
    setHoveredPractices({})
    setUpdatedZoomLevel(zoom)
  }

  const onClickRecenter = () => {
    // Zoom to default level and pan to default center
    // Right now, we just default to Toronto
    const currentCoordinates = loadedMap.getCenter()
    const defaultCenterCoords = torontoLatLon

    if (
      defaultCenterCoords.lat.toFixed(7) ===
        currentCoordinates.lat().toFixed(7) &&
      defaultCenterCoords.lng.toFixed(7) === currentCoordinates.lng().toFixed(7)
    ) {
      setSnackbarMessage(alreadyRecenteredSnackbarMessage)
    } else {
      setUpdatedZoomLevel(defaultZoomLevel)

      loadedMap.panTo(defaultCenterCoords)
      loadedMap.setZoom(defaultZoomLevel)

      updateSearchParams(
        {
          location: null,
        },
        searchParams,
        setSearchParams,
      )
    }
  }

  const onClickViewLocationAnalytics = () => {
    const center = loadedMap.getCenter()
    console.log('center lat', center.lat())
    setLocationAnalyticsModalOpen(true)
  }

  const handleSignUpRedirect = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: Paths.myPractices,
      },
      authorizationParams: {
        screen_hint: 'signup',
        utm_affiliate: localStorage.getItem('utm_affiliate'),
      },
    })
  }

  return (
    <MapContainer ismobilescreen={ismobilescreen}>
      {!practicesLoading ? (
        <>
          <MapComponent
            id='map'
            style={
              rounded
                ? {
                    borderRadius: '10px',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                  }
                : {}
            }
          >
            {hoveredPractices?.practices?.length && (
              <PracticePreviewThumbnail
                practices={hoveredPractices.practices}
                x={hoveredPractices.x}
                y={hoveredPractices.y}
                width={width}
                height={height}
                onClose={() => setHoveredPractices({})}
                onClickRevealPrice={handleSignUpRedirect}
                hasCreatedValuation={hasCreatedValuation}
              />
            )}
          </MapComponent>
          <Snackbar
            isOpen={!!snackbarMessage}
            onClose={() => setSnackbarMessage('')}
            message={snackbarMessage}
          />
          <ListingTypeToggle
            filter={listingTypeFilter}
            onClick={onChangeListingTypeFilter}
            width={width}
            ismobilescreen={ismobilescreen}
          />

          {!practices?.length && (
            <MapEmptyResultsWrapper>
              <NoDataMessageContainer ismobilescreen={ismobilescreen}>
                <EmptySearchIcon />
                <TitleText>Filters returned no matching practices</TitleText>
                <ResetFiltersButton onClick={onClickResetFilters}>
                  Reset filters
                </ResetFiltersButton>
              </NoDataMessageContainer>
            </MapEmptyResultsWrapper>
          )}

          <RecenterMapChip onClick={() => onClickRecenter()} />
          {/* <ViewLocationAnalyticsChip
            onClick={() => onClickViewLocationAnalytics()}
          /> */}
          <LocationAnalyticsModal
            user={user}
            isOpen={locationAnalyticsModalOpen}
            onClose={() => setLocationAnalyticsModalOpen(false)}
            ismobilescreen={ismobilescreen}
          />
        </>
      ) : (
        <LoadingIndicator />
      )}
    </MapContainer>
  )
}

export default Map
