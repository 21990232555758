import styled from 'styled-components/macro'
import Button from '@mui/material/Button'
import {
  AutoHorizontalMargins,
  LargeParagraphText,
  MicroGap,
  PrimaryButton,
  RowFlexStyle,
  TitleText,
} from '../../../styles/shared-styled-components'
import { NextButton } from '../../../pages/BuyerProfile/styled'

export const PopupBox = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
`

export const PopupContainer = styled.div`
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 0px rgba(1, 98, 153, 0.75);
  width: 40%;
`

export const HeaderTitle = styled(TitleText)`
  font-weight: bold;
  text-align: center;
`

export const HeaderInfo = styled(LargeParagraphText)`
  padding: 20px;
  text-algin: center;
  ${AutoHorizontalMargins}
`

export const BuyerForm = styled.div`
  padding: 20px;
  text-algin: center;
  ${AutoHorizontalMargins}
`

export const Box = styled.div`
  &.mt-2 {
    margin-top: 12px;
  }
`

export const BuyerButtons = styled.div`
  ${RowFlexStyle}
  justify-content: space-around;
  ${AutoHorizontalMargins}
  ${MicroGap}
`

export const BuyerContinueButton = styled(NextButton)``

export const BuyerBackButton = styled(Button)`
  border: 1px ${({ theme }) => theme.color.primary} solid;
  color: ${({ theme }) => theme.color.primary};
  border-radius: ${({ theme }) => theme.spacing.baseBorderRadius}px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.typography.typeFont};
  font-size: ${({ theme }) => theme.typography.sentenceFontSize};
  text-transform: none;
  padding: ${(props) =>
    props.disablepadding === 'true' ? '0px' : '10px 15px;'};
  &:hover {
    background-color: ${({ theme }) => theme.color.darkHover};
    color: ${({ theme }) => theme.color.white};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.color.disabled};
    color: ${({ theme }) => theme.color.white};
  }
`
