import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'
import { Box } from '../Micro/BuyerProfile/styled'
import {
  ApproveButton,
  BackButton,
  BuyerAvatar,
  BuyerAvatarContainer,
  BuyerAvatarLabel,
  BuyerDataContainer,
  BuyerPreviewTitleText,
  BuyerPreviewWrapper,
  BuyerSectionTitle,
  BuyerTextControl,
  DownloadButton,
  DeclinedButton,
  ApprovedButton,
} from './styled'
import { ResponsiveFlexContainer } from '../../pages/BuyerProfile/styled'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { getFormattedMoneyString } from '../../utils/numbers'
import { getFileNameFromUrl } from '../../utils/naming'
import { useMemo } from 'react'
import {
  acquisitionRequestsStatuses,
  acquisitionTypes,
  buyerTypes,
} from '../../utils/constants'
import { addCommasToNum } from '../../utils/numbers'

const BuyerPreviewModal = forwardRef(
  ({ handleApprove, handleDecline }, ref) => {
    const [showModal, setShowModal] = useState(false)
    const [config, setConfig] = useState(null)
    const [isAccepting, setAccepting] = useState(false)
    const [isRejecting, setRejecting] = useState(false)

    const offer = config?.offer
    const isPurchaseIntent = useMemo(
      () => offer?.type === acquisitionTypes.PURCHASE_INTENT,
      [offer?.type],
    )

    const dismiss = () => {
      setConfig(null)
      setShowModal(false)
    }

    const approve = useCallback(async () => {
      try {
        if (isAccepting || isRejecting) return
        setAccepting(true)
        await handleApprove(offer)
        setAccepting(false)
        dismiss()
      } catch (error) {
        console.log(error)
      }
    }, [offer, isAccepting, isRejecting, handleApprove])

    const decline = useCallback(async () => {
      try {
        if (isAccepting || isRejecting) return
        setRejecting(true)
        await handleDecline(offer)
        setRejecting(false)
        dismiss()
      } catch (error) {
        console.log(error)
      }
    }, [offer, isAccepting, isRejecting, handleDecline])

    useImperativeHandle(ref, () => ({
      dismiss: dismiss,
      openModal: (data) => {
        setConfig(data)
        setShowModal(true)
      },
    }))

    function commaSpacing(str) {
      let word = str.replaceAll(',', ',  ')
      return word
    }

    return (
      showModal && (
        <BuyerPreviewWrapper>
          {offer?.buyer?.type === 1 ? (
            <>
              <BuyerPreviewTitleText>Buyer Information</BuyerPreviewTitleText>
              <BuyerDataContainer>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '19px',
                    alignItems: 'center',
                  }}
                >
                  <BuyerAvatarContainer>
                    <Box style={{ position: 'relative' }}>
                      <BuyerAvatar
                        className={'avatar'}
                        src={offer?.buyer?.picture}
                      />
                      <BuyerAvatarLabel>
                        <PhotoCameraIcon fontSize='large' />
                      </BuyerAvatarLabel>
                    </Box>
                  </BuyerAvatarContainer>
                  {/* Check whether already accepted/rejected */}
                  {offer?.status === acquisitionRequestsStatuses.ACCEPTED ||
                  offer?.status === acquisitionRequestsStatuses.APPROVED ? (
                    <ApprovedButton>{offer?.status}</ApprovedButton>
                  ) : offer?.status === acquisitionRequestsStatuses.DECLINED ||
                    offer?.status === acquisitionRequestsStatuses.REJECTED ? (
                    <DeclinedButton>{offer?.status}</DeclinedButton>
                  ) : (
                    <>
                      <ApproveButton
                        disabled={
                          isAccepting || isRejecting || !config?.hasSignedEmaca
                        }
                        onClick={approve}
                      >
                        {isAccepting
                          ? 'Please wait...'
                          : isPurchaseIntent
                            ? 'Accept'
                            : 'Approve'}
                      </ApproveButton>
                      <DeclinedButton
                        disabled={
                          isAccepting || isRejecting || !config?.hasSignedEmaca
                        }
                        onClick={decline}
                      >
                        {isRejecting
                          ? 'Please wait...'
                          : isPurchaseIntent
                            ? 'Reject'
                            : 'Decline'}
                      </DeclinedButton>
                    </>
                  )}
                </Box>
                <Box style={{ flex: 1, maxWidth: '787px' }}>
                  <>
                    <BuyerSectionTitle>Personal Details</BuyerSectionTitle>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '30px',
                      }}
                    >
                      <BuyerTextControl>
                        <Box>
                          <span>Name:</span>
                        </Box>
                        <Box>
                          <span>
                            {offer?.buyer?.account?.firstName}&nbsp;
                            {offer?.buyer?.account?.lastName}
                          </span>
                        </Box>
                      </BuyerTextControl>

                      <BuyerTextControl>
                        <Box>
                          <span>Graduation year:</span>
                        </Box>
                        <Box>
                          <span>
                            {offer?.buyer?.year_of_graduation ?? 'N/A'}
                          </span>
                        </Box>
                      </BuyerTextControl>

                      <BuyerTextControl>
                        <Box>
                          <span>School name:</span>
                        </Box>
                        <Box>
                          <span>
                            {offer?.buyer?.school_of_graduation ?? 'N/A'}
                          </span>
                        </Box>
                      </BuyerTextControl>

                      <BuyerTextControl>
                        <Box>
                          <span>Languages spoken:</span>
                        </Box>
                        <Box>
                          <span>
                            {commaSpacing(offer?.buyer?.languages ?? 'N/A')}
                          </span>
                        </Box>
                      </BuyerTextControl>

                      <BuyerTextControl>
                        <Box>
                          <span>Procedure(s) to be performed:</span>
                        </Box>
                        <Box>
                          <span>
                            {commaSpacing(offer?.buyer?.procedures ?? 'N/A')}
                          </span>
                        </Box>
                      </BuyerTextControl>

                      <BuyerTextControl>
                        <Box>
                          <span>Avg monthly production:</span>
                        </Box>
                        <Box>
                          <span>
                            {getFormattedMoneyString(
                              addCommasToNum(
                                offer?.buyer?.average_monthly_production ?? 0,
                              ),
                            )}
                          </span>
                        </Box>
                      </BuyerTextControl>

                      <BuyerTextControl>
                        <Box>
                          <span>Number of practices:</span>
                        </Box>
                        <Box>
                          <span>
                            {addCommasToNum(offer?.buyer?.no_of_practices ?? 0)}
                          </span>
                        </Box>
                      </BuyerTextControl>

                      <BuyerTextControl>
                        <Box>
                          <span>Photo ID:</span>
                        </Box>
                        <Box>
                          <DownloadButton
                            href={offer?.buyer?.file}
                            target='_blank'
                          >
                            <CloudDownloadIcon />
                            Download Photo ID
                          </DownloadButton>
                        </Box>
                      </BuyerTextControl>
                    </Box>

                    <BuyerSectionTitle style={{ marginTop: '71px' }}>
                      Terms of sale
                    </BuyerSectionTitle>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '30px',
                      }}
                    >
                      <BuyerTextControl>
                        <Box>
                          <span>Years desired to work post sale:</span>
                        </Box>
                        <Box>
                          <span>{offer?.years_to_work_post_sale}</span>
                        </Box>
                      </BuyerTextControl>

                      <BuyerTextControl>
                        <Box>
                          <span>Buying percentage:</span>
                        </Box>
                        <Box>
                          <span>{offer?.percentage_to_buy}</span>
                        </Box>
                      </BuyerTextControl>

                      <BuyerTextControl>
                        <Box>
                          <span>Pre-approved financing:</span>
                        </Box>
                        <Box>
                          <span>{offer?.pre_approved_financing}</span>
                        </Box>
                      </BuyerTextControl>

                      {offer?.pre_approved_financing && (
                        <BuyerTextControl>
                          <Box>
                            <span>Pre-approved financing report:</span>
                          </Box>
                          <Box>
                            <DownloadButton
                              href={offer?.pre_financing_report}
                              target='_blank'
                            >
                              <CloudDownloadIcon />
                              Download Report
                            </DownloadButton>
                          </Box>
                        </BuyerTextControl>
                      )}
                    </Box>
                  </>
                </Box>
              </BuyerDataContainer>
            </>
          ) : (
            <>
              <BuyerPreviewTitleText>
                Insitutional Information
              </BuyerPreviewTitleText>
              <BuyerDataContainer>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '19px',
                    alignItems: 'center',
                  }}
                >
                  <BuyerAvatarContainer>
                    <Box style={{ position: 'relative' }}>
                      <BuyerAvatar
                        className={'avatar'}
                        src={offer?.buyer?.picture}
                      />
                      <BuyerAvatarLabel>
                        <PhotoCameraIcon fontSize='large' />
                      </BuyerAvatarLabel>
                    </Box>
                  </BuyerAvatarContainer>
                  <ApproveButton
                    disabled={
                      isAccepting || isRejecting || !config?.hasSignedEmaca
                    }
                    onClick={approve}
                  >
                    {isAccepting
                      ? 'Please wait...'
                      : isPurchaseIntent
                        ? 'Accept'
                        : 'Approve'}
                  </ApproveButton>
                  <DeclinedButton
                    disabled={
                      isAccepting || isRejecting || !config?.hasSignedEmaca
                    }
                    onClick={decline}
                  >
                    {isRejecting
                      ? 'Please wait...'
                      : isPurchaseIntent
                        ? 'Reject'
                        : 'Decline'}
                  </DeclinedButton>
                </Box>
                <Box style={{ flex: 1, maxWidth: '787px' }}>
                  <>
                    <BuyerSectionTitle>Insitute Details</BuyerSectionTitle>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '30px',
                      }}
                    >
                      <BuyerTextControl>
                        <Box>
                          <span>Company Name:</span>
                        </Box>
                        <Box>
                          <span>{offer?.buyer?.company_name}</span>
                        </Box>
                      </BuyerTextControl>

                      <BuyerTextControl>
                        <Box>
                          <span>Years in Business:</span>
                        </Box>
                        <Box>
                          <span>
                            {addCommasToNum(
                              offer?.buyer?.years_in_business ?? 'N/A',
                            )}
                          </span>
                        </Box>
                      </BuyerTextControl>

                      <BuyerTextControl>
                        <Box>
                          <span>Location:</span>
                        </Box>
                        <Box>
                          <span>{offer?.buyer?.location ?? 'N/A'}</span>
                        </Box>
                      </BuyerTextControl>

                      <BuyerTextControl>
                        <Box>
                          <span>Gross Revenue:</span>
                        </Box>
                        <Box>
                          <span>
                            {getFormattedMoneyString(
                              offer?.buyer?.gross_revenue ?? 0,
                            )}
                          </span>
                        </Box>
                      </BuyerTextControl>

                      <BuyerTextControl>
                        <Box>
                          <span>
                            Estimated Practices Under<br></br>Management or
                            Sold*:
                          </span>
                        </Box>
                        <Box>
                          <span>
                            {addCommasToNum(
                              offer?.buyer?.num_practices_under_management,
                            ) ?? 'N/A'}
                          </span>
                        </Box>
                      </BuyerTextControl>

                      <BuyerTextControl>
                        <Box>
                          <span>Website Url:</span>
                        </Box>
                        <Box>
                          <span>{offer?.buyer?.website_link}</span>
                        </Box>
                      </BuyerTextControl>
                    </Box>

                    <BuyerSectionTitle style={{ marginTop: '71px' }}>
                      Terms of sale
                    </BuyerSectionTitle>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '30px',
                      }}
                    >
                      <BuyerTextControl>
                        <Box>
                          <span>Years desired to work post sale:</span>
                        </Box>
                        <Box>
                          <span>{offer?.years_to_work_post_sale}</span>
                        </Box>
                      </BuyerTextControl>

                      <BuyerTextControl>
                        <Box>
                          <span>Buying percentage:</span>
                        </Box>
                        <Box>
                          <span>{offer?.percentage_to_buy}</span>
                        </Box>
                      </BuyerTextControl>

                      <BuyerTextControl>
                        <Box>
                          <span>Pre-approved financing:</span>
                        </Box>
                        <Box>
                          <span>{offer?.pre_approved_financing}</span>
                        </Box>
                      </BuyerTextControl>

                      {offer?.pre_approved_financing && (
                        <BuyerTextControl>
                          <Box>
                            <span>Pre-approved financing report:</span>
                          </Box>
                          <Box>
                            <DownloadButton href={offer?.pre_financing_report}>
                              <CloudDownloadIcon />
                              Download Here
                            </DownloadButton>
                          </Box>
                        </BuyerTextControl>
                      )}
                    </Box>
                  </>
                </Box>
              </BuyerDataContainer>
            </>
          )}

          <ResponsiveFlexContainer block footer>
            <BackButton disabled={isAccepting || isRejecting} onClick={dismiss}>
              <ChevronLeftIcon />
              Back
            </BackButton>
          </ResponsiveFlexContainer>
        </BuyerPreviewWrapper>
      )
    )
  },
)

export default BuyerPreviewModal
