import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import {
  FullPageContentContainerWithGap,
  PageContainer,
  VUNSBC,
} from '../../../styles/shared-styled-components'
import Header from '../../NavBar/Header'
import CreatePracticeFlowNavigation from '../CreatePracticeFlowNavigation'
import CreatePracticeHeader from '../CreatePracticeHeader'
import {
  CreatePracticeFormContainer,
  CreatePracticeFormInputColumn,
  CreatePracticeFormInputColumnContainer,
  CreatePracticeFormInputContainer,
  CreatePracticeFormSubtitleText,
  CreatePracticeFormTitleContainer,
  CreatePracticeFormTitleText,
} from './styled'

const CreatePracticeInputForm = forwardRef(
  (
    {
      user,
      width,
      ismobilescreen,
      currentStep,
      practice,
      formTitle,
      formSubtitle,
      formSubtitleColor,
      formInputs,
      nextEnabled,
      onClickNext,
      onClickBack,
      allowSkipping = false,
      currentFlow = null,
      onSaveInFlow,
      completedWholeListingFlow = false,
      inValuationMode = false,
      skipButtonText,
      children,
      onValuationPage = false,
      isDueDiligence = false,
    },
    ref,
  ) => {
    const divRef = useRef(null)
    const navRef = useRef(null)
    const [remainingHeight, setRemainingHeight] = useState(0)

    // Setting the remaining height - need to watch variables that trigger re-renders
    useEffect(() => {
      const handleResize = () => {
        const divHeight = divRef?.current?.getBoundingClientRect().top
        const windowHeight = window.innerHeight
        const heightDifference = windowHeight - divHeight
        setRemainingHeight(heightDifference)
      }

      window.addEventListener('resize', handleResize)
      handleResize() // Call it initially to get the remaining height

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [practice, width])

    useImperativeHandle(ref, () => ({
      getNavRef: () => navRef,
    }))

    const numInputs = formInputs.length
    const splitIntoMultipleColumns = numInputs >= 6
    const middleInputIndex = parseInt(numInputs / 2)
    const firstHalfOfInputs = formInputs.slice(0, middleInputIndex)
    const secondHalfOfInputs = formInputs.slice(middleInputIndex, numInputs)

    return (
      <>
        <Header user={user} width={width} ismobilescreen={ismobilescreen} />
        <VUNSBC>
          <PageContainer style={{ height: 'calc(100vh - 70px)' }}>
            <FullPageContentContainerWithGap ismobilescreen={ismobilescreen}>
              <CreatePracticeHeader
                currentStep={currentStep}
                ismobilescreen={ismobilescreen}
                practice={practice}
                currentFlow={currentFlow}
                onSaveInFlow={onSaveInFlow}
                nextEnabled={nextEnabled || allowSkipping}
                inValuationMode={inValuationMode}
                onValuationPage={onValuationPage}
                isDueDiligence={isDueDiligence}
              />
              <CreatePracticeFormContainer
                ismobilescreen={ismobilescreen}
                style={{
                  marginTop: 0,
                  gap: 10,
                  flex: 1,
                  overflow: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <CreatePracticeFormTitleContainer>
                  <CreatePracticeFormTitleText>
                    {formTitle}
                  </CreatePracticeFormTitleText>
                  {formSubtitle && (
                    <CreatePracticeFormSubtitleText
                      textColor={formSubtitleColor}
                    >
                      {formSubtitle}
                    </CreatePracticeFormSubtitleText>
                  )}
                </CreatePracticeFormTitleContainer>
                {children ?? (
                  <CreatePracticeFormInputColumnContainer
                    ismobilescreen={ismobilescreen}
                    ref={divRef}
                    remainingHeight={remainingHeight}
                  >
                    {ismobilescreen || !splitIntoMultipleColumns ? (
                      <CreatePracticeFormInputColumn splitScreen={false}>
                        <CreatePracticeFormInputContainer splitScreen={false}>
                          {formInputs?.map((i) => {
                            return i
                          })}
                        </CreatePracticeFormInputContainer>
                      </CreatePracticeFormInputColumn>
                    ) : (
                      <>
                        <CreatePracticeFormInputColumn splitScreen>
                          <CreatePracticeFormInputContainer splitScreen>
                            {firstHalfOfInputs?.map((i) => {
                              return i
                            })}
                          </CreatePracticeFormInputContainer>
                        </CreatePracticeFormInputColumn>
                        <CreatePracticeFormInputColumn splitScreen>
                          <CreatePracticeFormInputContainer splitScreen>
                            {secondHalfOfInputs?.map((i) => {
                              return i
                            })}
                          </CreatePracticeFormInputContainer>
                        </CreatePracticeFormInputColumn>
                      </>
                    )}
                  </CreatePracticeFormInputColumnContainer>
                )}
              </CreatePracticeFormContainer>
              <CreatePracticeFlowNavigation
                ref={navRef}
                currentStep={currentStep}
                practiceId={practice?.id}
                nextEnabled={nextEnabled}
                onClickBack={onClickBack}
                onClickNext={onClickNext}
                allowSkipping={allowSkipping}
                currentFlow={currentFlow}
                completedWholeListingFlow={completedWholeListingFlow}
                inValuationMode={inValuationMode}
                skipButtonText={skipButtonText}
                isDueDiligence={isDueDiligence}
              />
            </FullPageContentContainerWithGap>
          </PageContainer>
        </VUNSBC>
      </>
    )
  },
)

export default CreatePracticeInputForm
