import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getCreatePracticePathFromSectionName } from '../../../utils/routing'
import {
  PrimaryCompleteValuationButton,
  SecondaryCompleteValuationButton,
} from './styled'

const defaultText = 'Complete my listing'

const CompleteValuationButton = ({
  practice,
  buttonType = 'primary',
  text = null,
  inValuationMode = false,
}) => {
  const navigate = useNavigate()

  const { id, sectionCurrentlyEditing } = practice

  const firstIncompleteSectionPath = getCreatePracticePathFromSectionName(
    // If user has completed a valuation, send them to overview. Otherwise send them to section currently editing.
    !practice?.mostRecentValuation ? sectionCurrentlyEditing : 'overview',
    id,
    inValuationMode,
  )

  return (
    <>
      {buttonType === 'primary' ? (
        <PrimaryCompleteValuationButton
          onClick={() => navigate(firstIncompleteSectionPath)}
        >
          {text || defaultText}
        </PrimaryCompleteValuationButton>
      ) : (
        <SecondaryCompleteValuationButton
          onClick={() => navigate(firstIncompleteSectionPath)}
        >
          {text || defaultText}
        </SecondaryCompleteValuationButton>
      )}
    </>
  )
}

export default CompleteValuationButton
