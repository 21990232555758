import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  StyledPopupBox,
  StyledPopupContainer,
  HeaderTitle,
  BuyerButtons,
  BuyerContinueButton,
  BodyText,
} from './styled'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const MyAcquisition = ({ buyerId }) => {
  const navigate = useNavigate()

  const handleContinue = useCallback(() => {
    const myAcquisitionLink = `/buyers/${buyerId}/my-acquisition`
    navigate(myAcquisitionLink)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerId])

  return (
    <StyledPopupBox>
      <StyledPopupContainer
        style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
      >
        <HeaderTitle>
          <CheckCircleIcon sx={{ color: '#25B34D', fontSize: '60px' }} />
        </HeaderTitle>
        <BodyText>
          Your request for dataroom access has been submitted. The seller will
          review your request shortly. You can track the status of your requests
          in <span style={{ fontWeight: 'bold' }}>'My Acquisitions'</span>.
        </BodyText>
        <BuyerButtons>
          <BuyerContinueButton onClick={handleContinue}>OK</BuyerContinueButton>
        </BuyerButtons>
      </StyledPopupContainer>
    </StyledPopupBox>
  )
}

export default MyAcquisition
