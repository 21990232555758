/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import { usePracticeById } from '../../../api/hooks/practices'
import {
  FormTitle,
  SubtitleText,
} from '../../../pages/CreatePractice/DueDiligence/styled'
import DocumentUploader from './DocumentUploader'
import SkipSectionWarningModal from './SkipSectionWarningModal'
import { Box } from './styled'
import _, { filter } from 'lodash'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { GET_PRACTICE_BY_ID } from '../../../api/queries/practices'
import { useQueryWithErrorLogging } from '../../../utils/hooks'
import { useMyUser } from '../../../api/hooks/users'
import { format } from 'date-fns'
import {
  subYears,
  addDays,
  endOfMonth,
  subMonths,
  set,
  startOfMonth,
  addMonths,
} from 'date-fns'
import CreatePracticeInput from '../CreatePracticeInput'

const Accounting = forwardRef(
  (
    {
      subStepName,
      formProgress,
      onSkip,
      onClickNext,
      handleCanSkipChanged,
      onUpdateFinancialDetails,
    },
    ref,
  ) => {
    const { practiceId } = useParams()
    const { practice } = usePracticeById(practiceId)
    const uploaderRef = useRef()
    const [isSkipable, setIsSkipable] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [uploadedDocsUrls, setUploadedDocsUrls] = useState(
      practice?.dueDiligence?.accounting || {},
    )
    const [currentSection, setCurrentSection] = useState(0)
    const [fieldErrors, setFieldErrors] = useState()
    const useMyUserResults = useMyUser()

    const { data, error, loading } = useQueryWithErrorLogging(
      GET_PRACTICE_BY_ID,
      {
        variables: { id: practiceId },
        skip: !useMyUserResults.auth0Context.isAuthenticated,
        fetchPolicy: 'network-only',
      },
    )

    if (error) {
      throw error
    }

    //Fiscal Year Calculations
    const fiscalYearEndDate =
      data?.getPracticeById?.dueDiligence?.accounting?.fiscalYearEnd
        ?.fiscalYearEnd
    const fiscalYearEndDateNew = fiscalYearEndDate
      ? format(fiscalYearEndDate, 'MMMM dd yyyy')
      : null
    const formattedFiscalStart = fiscalYearEndDate
      ? addDays(fiscalYearEndDate, 1)
      : null
    const subYearsValue = fiscalYearEndDate
      ? subYears(formattedFiscalStart, 1)
      : null
    const YearBackFYEStart = fiscalYearEndDate
      ? format(subYearsValue, 'MMMM dd yyyy')
      : null
    const TwoYearBackFYEStart = fiscalYearEndDate
      ? format(subYears(formattedFiscalStart, 2), 'MMMM dd yyyy')
      : null
    const ThreeYearBackFYEStart = fiscalYearEndDate
      ? format(subYears(formattedFiscalStart, 3), 'MMMM dd yyyy')
      : null
    const YearBackFYE = fiscalYearEndDate
      ? format(subYears(fiscalYearEndDateNew, 1), 'MMMM dd yyyy')
      : null
    const TwoYearBackFYE = fiscalYearEndDate
      ? format(subYears(fiscalYearEndDateNew, 2), 'MMMM dd yyyy')
      : null

    //End of Month calculations
    const today = new Date()
    const formattedToday = format(today, 'MM dd yyyy')
    const EOM = format(endOfMonth(today), 'MM dd yyyy')

    const ytdEndOfMonth =
      EOM === formattedToday
        ? format(formattedToday, 'MMMM dd yyyy')
        : format(endOfMonth(subMonths(formattedToday, 1)), 'MMMM dd yyyy')

    //Start of Month Calculation
    const SOM = format(startOfMonth(today), 'MM dd yyyy')
    const ytdStartOfMonth =
      SOM === formattedToday
        ? format(formattedToday, 'MMMM dd yyyy')
        : format(startOfMonth(addMonths(formattedToday, 1)), 'MMMM dd yyyy')

    const monthsForward = format(addMonths(ytdStartOfMonth, 24), 'MMMM dd yyyy')

    //First of January calculation
    const januaryFirstDay = set(new Date(), { month: 0, date: 1 })
    const januaryFirst = format(januaryFirstDay, 'MMMM dd yyyy')

    const sections = useMemo(
      () => [
        {
          name: 'fiscalYearEnd',
          title: '2.1 Fiscal Year End',
          subtitle: 'Note: Please select your fiscal year end below',
          fields: [
            {
              label: 'Fiscal Year',
              id: 'fiscalYearEnd',
              fieldType: 'date',
            },
          ],
        },
        {
          name: 'financialStatement',
          title:
            '2.2 Accountant Prepared Notice to Reader Financial Statements',
          subtitle: 'Note: Please upload the documents listed below.',
          listedItems: fiscalYearEndDate
            ? [
                `T2 tax filing for last year`,
                `Notice to reader financial statements, accountant prepared trial balance for account groupings, accountant year end adjusting entries for the following dates:`,
                `${YearBackFYEStart} - ${fiscalYearEndDateNew}`,
                `${TwoYearBackFYEStart} - ${YearBackFYE}`,
                `${ThreeYearBackFYEStart} - ${TwoYearBackFYE}`,
              ]
            : [
                `Last 3 years notice to reader financial statements`,
                `Last 3 years accountant prepared trial balance for account groupings`,
                `Last 3 years accountant year end adjusting entries`,
                `T2 tax filing for last year`,
              ],
        },

        {
          name: 'generalLedgers',
          title: '2.3 General ledgers',
          subtitle: 'Note: Please upload the documents listed below.',
          listedItems: fiscalYearEndDate
            ? [
                'General ledgers by fiscal year end (Supported invoices on request) for the following dates:',
                `${YearBackFYEStart} - ${fiscalYearEndDateNew}`,
                `${TwoYearBackFYEStart} - ${YearBackFYE}`,
                `${ThreeYearBackFYEStart} - ${TwoYearBackFYE}`,
                `YTD (${januaryFirst} to ${ytdEndOfMonth}) general ledger (Supported invoices on request)`,
              ]
            : [
                `Last 3 years general ledgers by fiscal year end (Supported invoices on request)`,
                `YTD (January to last completed month) general ledger (Supported invoices on request)`,
              ],
        },
        {
          name: 'incomeStatements',
          title: '2.4 Income statements',
          subtitle: 'Note: Please upload the documents listed below.',
          listedItems: fiscalYearEndDate
            ? [
                'Income statements by fiscal year end for the following dates:',
                `${YearBackFYEStart} - ${fiscalYearEndDateNew}`,
                `${TwoYearBackFYEStart} - ${YearBackFYE}`,
                `${ThreeYearBackFYEStart} - ${TwoYearBackFYE}`,
                `YTD (${januaryFirst} to ${ytdEndOfMonth}) income statement`,
              ]
            : [
                ` Last 3 years income statements by fiscal year end`,
                `YTD (January to last completed month) income statement`,
              ],
        },
        {
          name: 'trialBalances',
          title: '2.5 Trial balances',
          subtitle: 'Note: Please upload the documents listed below.',
          listedItems: fiscalYearEndDate
            ? [
                `As of last fiscal year (as of ${YearBackFYE})`,

                'Most recent month completed',
              ]
            : [`As of last fiscal year`, `Most recent month completed`],
        },
        {
          name: 'forecast',
          title: '2.6 Forecast',
          subtitle: 'Note: Please upload the documents listed below.',
          listedItems: fiscalYearEndDate
            ? [
                `Next 24 months (${ytdStartOfMonth} to ${monthsForward}) forecast by year with explanations and assumptions`,
              ]
            : [
                `Next 2 years forecast by year with explanations and assumptions`,
              ],
        },
      ],
      [fiscalYearEndDate],
    )

    useEffect(() => {
      const currentPath = window.location.pathname
      const queryParams = new URLSearchParams(window.location.search)
      const navType = queryParams.get('navType')
      if (navType === 'back') {
        setCurrentSection(sections.length - 1)
      }
      queryParams.delete('navType')
      window.history.replaceState(
        {},
        '',
        `${currentPath}?${queryParams.toString()}`,
      )
    }, [])

    useEffect(() => {
      if (uploadedDocsUrls) onUpdateFinancialDetails(uploadedDocsUrls)
    }, [uploadedDocsUrls])

    useEffect(() => {
      setIsSkipable(
        sections[currentSection].fields
          ? false
          : _.isEmpty(
              uploadedDocsUrls &&
                uploadedDocsUrls[sections[currentSection].name],
            ),
      )
    }, [uploadedDocsUrls, currentSection, sections, practice])

    useEffect(() => {
      if (handleCanSkipChanged) {
        handleCanSkipChanged(isSkipable)
      }
    }, [isSkipable])

    const handleOnBackPressed = useCallback(
      (callback) => {
        if (currentSection > 0) {
          setCurrentSection(currentSection - 1)
        } else {
          callback()
        }
      },
      [currentSection],
    )

    useImperativeHandle(ref, () => ({
      submit: handleFormSubmit,
      onBack: handleOnBackPressed,
      isSkipable: isSkipable,
    }))

    const handleFormSubmit = useCallback(
      (callback, config) => {
        if (config?.override && callback) {
          callback(uploadedDocsUrls)
        } else if (isSkipable) {
          if (!!(formProgress && !_.isEmpty(formProgress.skipped))) {
            if (currentSection < sections.length - 1) {
              setCurrentSection(currentSection + 1)
            } else {
              onSkip()
            }
          } else {
            setShowModal(true)
          }
        } else {
          // handle form submission
          if (currentSection < sections.length - 1) {
            setCurrentSection(currentSection + 1)
          } else {
            if (callback) {
              const allFieldsFilled = _.every(
                uploadedDocsUrls,
                (field) => !_.isNull(field),
              )
              if (allFieldsFilled) {
                callback(uploadedDocsUrls)
              } else {
                onSkip()
              }
            } else if (onClickNext) {
              onClickNext()
            }
          }
        }
      },
      [isSkipable, uploaderRef, currentSection, uploadedDocsUrls, formProgress],
    )
    const handleOnUploadComplete = useCallback(
      (url) => {
        setUploadedDocsUrls((old) => ({
          ...old,
          [sections[currentSection].name]: _.concat(
            uploadedDocsUrls[sections[currentSection].name] || [],
            url,
          ),
        }))
      },
      [currentSection, uploadedDocsUrls, sections, uploaderRef],
    )

    const handleOnSkip = useCallback(() => {
      setShowModal(false)
      const hasNextSection = currentSection < sections.length - 1
      if (hasNextSection) {
        setCurrentSection(currentSection + 1)
      } else {
        onSkip()
      }
    }, [currentSection, sections])

    const currentSectionUploadedFiles = useMemo(() => {
      return uploadedDocsUrls[sections[currentSection]?.name]
    }, [uploadedDocsUrls, sections, currentSection])

    const handleNameChangeInADatePicker = useCallback(
      (date, fieldId) => {
        setUploadedDocsUrls((old) => {
          const newvalue = {
            ...old,
            [sections[currentSection].name]: {
              ...old[sections[currentSection].name],
              [fieldId]: date,
            },
          }
          return newvalue
        })
      },
      [currentSection, sections],
    )

    const handleNameChange = useCallback(
      (e) => {
        setUploadedDocsUrls((old) => {
          const newvalue = {
            ...old,
            [sections[currentSection].name]: {
              ...old[sections[currentSection].name],
              [e.target.id]: e.target.value,
            },
          }
          return newvalue
        })
      },
      [currentSection, sections],
    )
    const handleUpdate = useCallback(() => {
      if (uploadedDocsUrls) onUpdateFinancialDetails(uploadedDocsUrls)
    }, [uploadedDocsUrls])

    if (loading) {
      return null
    }

    return (
      <Box>
        <FormTitle>Accounting</FormTitle>
        <FormTitle style={{ marginTop: '10px', fontSize: '16px' }}>
          {sections[currentSection].title}
        </FormTitle>

        <SubtitleText className='note' style={{ margin: '10px 0px 10px 0px' }}>
          {sections[currentSection].subtitle}
        </SubtitleText>

        <SubtitleText className='note' style={{ margin: 0 }}>
          <ul>
            {sections[currentSection]?.listedItems?.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </SubtitleText>

        {_.isEmpty(sections[currentSection].fields) ? (
          <>
            <SubtitleText className='note' style={{ margin: 0 }}>
              <ul />
            </SubtitleText>

            <DocumentUploader
              ref={uploaderRef}
              onUploadComplete={handleOnUploadComplete}
              practiceId={practiceId}
              uploadedFilesUrls={currentSectionUploadedFiles || []}
            />
          </>
        ) : (
          _.map(sections[currentSection].fields, (field, i) => {
            const currentFieldValue =
              uploadedDocsUrls[sections[currentSection]?.name]?.[field.id]
            return (
              <div key={i} style={{ marginTop: 20 }}>
                {field.fieldType === 'date' ? (
                  <DatePicker
                    selected={
                      currentFieldValue
                        ? new Date(currentFieldValue)
                        : undefined
                    }
                    onBlur={handleUpdate}
                    onChange={(date) =>
                      handleNameChangeInADatePicker(date, field.id)
                    }
                    dateFormat='MM/dd/yyyy'
                  />
                ) : (
                  <CreatePracticeInput
                    key={i}
                    value={
                      uploadedDocsUrls[sections[currentSection]?.name]?.[
                        field.id
                      ]
                    }
                    label={field.label}
                    onChange={handleNameChange}
                    onBlur={handleUpdate}
                    fieldType={field.fieldType}
                    elementId={field.id}
                    errorMessage={fieldErrors?.[field.id]}
                    submittedDataWithErrors={!_.isNull(fieldErrors?.[field.id])}
                  />
                )}
              </div>
            )
          })
        )}

        <SkipSectionWarningModal
          subStepName={subStepName}
          formProgress={formProgress}
          practiceId={practiceId}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onSkip={handleOnSkip}
        />
      </Box>
    )
  },
)

export default Accounting
