import styled, { css } from 'styled-components'
import typography from '../../styles/typography'

export const Table = styled.table`
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  & thead {
    border-radius: 10px;
  }
`

export const TableHeader = styled.th`
  background: rgba(0, 0, 0, 0.1);
  padding: 10px;
  color: #000;
  text-align: center;
  font-family: Noto Sans KR;
  font-size: ${typography.defaultFontSize};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 0px;
  font-weight: 600;

  ${(props) =>
    props.leftAlign &&
    css`
      text-align: left;
    `}
`

export const TableRow = styled.tr`
  &:nth-child(even) {
    // background-color: #f9f9f9;
  }
`

export const TableCell = styled.td`
  padding: 10px;
  border: 1px solid rgba(1, 98, 153, 0.2);
  text-align: center;
  font-family: Noto Sans KR;
  font-size: ${typography.sentenceFontSize};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 0px;

  ${(props) =>
    props.leftAlign &&
    css`
      text-align: left;
    `}
`

export const ActionButton = styled.a`
  display: flex;
  flex-direction: row;
  border: 1px solid #000;
  background: #fff;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  gap: 2px;
  height: 27px;
  padding: 2px 1px;
  text-decoration: none;
  color: #000 !important;
  max-width: 80px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
    background: #d7d7d7;
    box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.1);
  }
`

export const ContactDealerBtn = styled.button`
  border-radius: 30.059px;
  background: #000;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.29);
  display: inline-flex;
  padding: 10px 20px;
  border: 0px;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-family: Noto Sans KR;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.223px;
  cursor: pointer;
`
