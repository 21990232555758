import styled, { css } from 'styled-components'
import {
  FullPageContentContainer,
  LargeParagraphText,
  PrimaryButton,
  SecondaryButton,
} from '../../styles/shared-styled-components'
import { TextField } from '@mui/material'

export const Wrapper = styled.div`
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
`

export const FullPageFlexContainer = styled(FullPageContentContainer)`
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
`

export const FlexContainer = styled.div`
  flex: 1;
  overflow: auto;

  &.with-gapping {
    padding-top: 20px;
    gap: 20px;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    &.with-gapping {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
`

export const ResponsiveFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 41px;

  ${(props) =>
    props.block &&
    css`
      width: 95vw;
      margin: auto;
    `}

  ${(props) =>
    props.footer &&
    css`
      margin-bottom: 20px;
    `}

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    gap: 20px;
  }
`

export const StyledUploadContainer = styled.div`
  border: 1px solid #016299;
  background: #e2eaf3;
  width: 317px;
  max-width: 90%;
  height: 260px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    max-width: 90%;
    margin: auto;
  }
`

export const StyledLabel = styled.label`
  color: #000;
  font-family: Noto Sans KR;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const StyledAvatar = styled.div`
  border: 1px solid #808080;
  background: #d9d9d9;
  width: 167px;
  height: 167px;
  border-radius: 50%;
  color: #808080;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.src &&
    css`
      background-image: url(${props.src});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    `}

  &.placeholder {
    background-size: 106px;
  }
`

export const TitleText = styled(LargeParagraphText)`
  font-size: 32px;
  font-weight: bold;
  color: #016299;
  margin-bottom: 51px;

  @media (max-width: 768px) {
    margin-left: 5vw;
    font-size: 25px;
  }
`

export const BlackTitleText = styled(TitleText)`
  font-weight: bold;
  color: black;
  margin-bottom: 51px;

  @media (max-width: 768px) {
    margin-left: 5vw;
    font-size: 25px;
  }
`

export const ColumnFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  gap: 20px;

  @media (max-width: 768px) {
    width: 90%;
    margin: auto;
  }
`

export const StyledPhotoLabel = styled.label`
  position: absolute;
  top: 120px;
  right: 0;
  cursor: pointer;
  border: 1px solid #016299;
  border-radius: 50%;
  width: 27px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eef3f7;
`

export const StyledFolderIcon = styled.img`
  border-radius: 0px;
  width: 80px;
`

export const BasicLabel = styled.label`
  font-family: Noto Sans KR;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
`

export const StyledBasicLabel = styled(BasicLabel)`
  color: #016299;
  ${(props) =>
    props?.error &&
    css`
      color: ${({ theme }) => theme.color.red};
    `}
`
export const StyledNoteLabel = styled(BasicLabel)`
  color: #808080;
  font-size: 12px;
`

export const StyledDropBoxWrapper = styled.div`
  border: 1px dashed #000;
  background: #eef3f7;
  height: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    padding: 30px 10px;
  }
`

export const CancelButton = styled(SecondaryButton)`
  min-width: 171px;
`

export const NextButton = styled(PrimaryButton)`
  min-width: 171px;
`

export const NumericTextField = styled(TextField)`
& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

& input[type=number] {
  -moz-appearance': textfield
}
`
