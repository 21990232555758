import ClickAwayListener from '@mui/material/ClickAwayListener'
import Paper from '@mui/material/Paper'
import {
  DefaultPopper,
  FullScreenPopper,
  HeaderPopper,
  MenuList,
  OffsetPopper,
} from './styled'

const PopperMenu = ({
  fixedWidth = null,
  variant = 'default',
  disableTopPadding,
  ismobilescreen,
  // Have to spell it in lowercase so React doesn't confuse it for a built-in prop
  disablebottompadding,
  open,
  anchorElement,
  onClose,
  children,
  customStyle,
  useThinScrollBar = false,
}) => {
  return variant === 'header' ? (
    <HeaderPopper open={open} anchorEl={anchorElement} onClose={onClose}>
      <ClickAwayListener onClickAway={onClose}>
        <Paper elevation={8}>
          <MenuList
            disableTopPadding={disableTopPadding}
            disablebottompadding={disablebottompadding}
          >
            {children}
          </MenuList>
        </Paper>
      </ClickAwayListener>
    </HeaderPopper>
  ) : variant === 'offset' ? (
    <OffsetPopper open={open} anchorEl={anchorElement} onClose={onClose}>
      <ClickAwayListener onClickAway={onClose}>
        <Paper elevation={8}>
          <MenuList
            disableTopPadding={disableTopPadding}
            disablebottompadding={disablebottompadding}
          >
            {children}
          </MenuList>
        </Paper>
      </ClickAwayListener>
    </OffsetPopper>
  ) : variant === 'fullscreen' ? (
    <FullScreenPopper
      open={open}
      anchorEl={anchorElement}
      onClose={onClose}
      fixedWidth={fixedWidth}
      customStyle={customStyle}
      ismobilescreen={ismobilescreen}
    >
      <ClickAwayListener onClickAway={onClose}>{children}</ClickAwayListener>
    </FullScreenPopper>
  ) : (
    <DefaultPopper
      open={open}
      anchorEl={anchorElement}
      onClose={onClose}
      fixedWidth={fixedWidth}
      customStyle={customStyle}
      ismobilescreen={ismobilescreen}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Paper elevation={8}>
          <MenuList
            disableTopPadding={disableTopPadding}
            disablebottompadding={disablebottompadding}
            useThinScrollBar={useThinScrollBar}
          >
            {children}
          </MenuList>
        </Paper>
      </ClickAwayListener>
    </DefaultPopper>
  )
}

export default PopperMenu
