import { useApolloClient } from '@apollo/client'
import { useMutationWithErrorLogging } from '../../../utils/hooks'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Paths from '../../../Paths'
import { useMyUser } from '../../../api/hooks/users'
import { CREATE_PRACTICE } from '../../../api/mutations/practices'
import { AddIcon } from '../../../styles/shared-styled-components'
import { updateUserPracticesInCache } from '../../../utils/cache'
import { getCreatePracticeRouteFromId } from '../../../utils/routing'
import {
  PrimaryCreatePracticeButton,
  SecondaryCreatePracticeButton,
  TextCreatePracticeButton,
} from './styled'

const defaultText = 'Create new practice listing'

const CreatePracticeButton = ({
  text = null,
  buttonType = 'primary',
  includeIcon = false,
  allowShrinking = false,
  inValuationMode = false,
}) => {
  const navigate = useNavigate()
  const { cache } = useApolloClient()
  const { user, loginWithRedirect } = useMyUser().auth0Context

  const [createPracticeMutation, { loading }] =
    useMutationWithErrorLogging(CREATE_PRACTICE)

  const onClickWhileLoggedOut = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: !inValuationMode ? Paths.myPractices : Paths.myValuations,
      },
      authorizationParams: {
        screen_hint: 'signup',
        utm_affiliate: localStorage.getItem('utm_affiliate'),
      },
    })
  }

  const onClickCreatePractice = () => {
    createPracticeMutation({
      onCompleted: async (data) => {
        const createdPractice = data.createPractice

        await updateUserPracticesInCache(createdPractice, cache)

        // Navigate to the next step
        navigate(
          getCreatePracticeRouteFromId(
            Paths.createPracticeStart,
            createdPractice.id,
            inValuationMode,
          ),
        )
      },
    })
  }

  const clickHandler = () => {
    if (user) {
      onClickCreatePractice()
    } else {
      onClickWhileLoggedOut()
    }
  }

  return (
    <>
      {buttonType === 'primary' ? (
        <PrimaryCreatePracticeButton
          onClick={clickHandler}
          allowShrinking={allowShrinking}
          disabled={loading}
        >
          {includeIcon && <AddIcon />}
          {loading ? 'Please wait...' : text || defaultText}
        </PrimaryCreatePracticeButton>
      ) : buttonType === 'secondary' ? (
        <SecondaryCreatePracticeButton
          onClick={clickHandler}
          allowShrinking={allowShrinking}
          disabled={loading}
        >
          {includeIcon && <AddIcon />}
          {loading ? 'Please wait...' : text || defaultText}
        </SecondaryCreatePracticeButton>
      ) : (
        <TextCreatePracticeButton
          onClick={clickHandler}
          allowShrinking={allowShrinking}
          disabled={loading}
        >
          {includeIcon && <AddIcon />}
          {loading ? 'Please wait...' : text || defaultText}
        </TextCreatePracticeButton>
      )}
    </>
  )
}

export default CreatePracticeButton
