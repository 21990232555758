import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getViewPracticePathFromId } from '../../../utils/routing'
import {
  PrimaryViewPracticeButton,
  SecondaryViewPracticeButton,
} from './styled'

const defaultText = 'View practice'

const ViewPracticeButton = ({
  practiceId,
  buttonType = 'primary',
  text = null,
}) => {
  const navigate = useNavigate()

  const viewPracticePath = getViewPracticePathFromId(practiceId)

  return (
    <>
      {buttonType === 'primary' ? (
        <PrimaryViewPracticeButton onClick={() => navigate(viewPracticePath)}>
          {text || defaultText}
        </PrimaryViewPracticeButton>
      ) : (
        <SecondaryViewPracticeButton onClick={() => navigate(viewPracticePath)}>
          {text || defaultText}
        </SecondaryViewPracticeButton>
      )}
    </>
  )
}

export default ViewPracticeButton
