import MedicalServicesIcon from '@mui/icons-material/MedicalServices'
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip'
import styled, { css } from 'styled-components/macro'
import {
  BasePadding,
  BaseWidth,
  ColumnFlexStyle,
  DefaultHeroSectionHeightStyle,
  ExtraLargeIconStyle,
  HeroImageStyle,
  InterSubsectionalGap,
  IntersectionalGap,
  IntraSubsectionalGap,
  IntrasectionalGap,
  LargeParagraphText,
  RowFlexStyle,
  TitleText,
} from '../../../styles/shared-styled-components'

const navigationSectionHeight = 90

export const FormOverviewContainer = styled.div`
  height: ${(props) => props.remainingHeight - navigationSectionHeight}px;
  ${ColumnFlexStyle}
  align-items: center;
  justify-content: ${(props) =>
    props.ismobilescreen ? 'center' : 'space-evenly'};
  ${(props) =>
    props.ismobilescreen
      ? css`
          ${IntersectionalGap}
        `
      : css`
          ${IntrasectionalGap}
        `};
`

export const ValuationSectionContainer = styled.div`
  ${ColumnFlexStyle}
  ${IntraSubsectionalGap}
  align-items: center;
  max-width: 700px;
`

export const PrivacySectionContainer = styled(ValuationSectionContainer)`
  padding: 20px;
  border: 1px dotted black;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.lightBlue};
`

export const ValuationSectionTitleText = styled(TitleText)`
  color: ${({ theme }) => theme.color.black};
  font-size: ${(props) =>
    props.isExtraSmallHeightScreen && props.theme.typography.titleFontSize};
`

export const PrivacySectionTitleText = styled(TitleText)`
  color: ${({ theme }) => theme.color.black};
  font-size: ${(props) =>
    props.isExtraSmallHeightScreen
      ? props.theme.typography.defaultFontSize
      : props.theme.typography.titleFontSize};
`

export const ValuationSectionSubtitleText = styled(LargeParagraphText)`
  text-align: center;
  font-size: ${(props) =>
    props.isExtraSmallHeightScreen &&
    props.theme.typography.largeInputTextFontSize};
  color: ${({ theme }) => theme.color.grey};
`

export const PrivacySectionSubtitleText = styled(LargeParagraphText)`
  text-align: center;
  font-size: ${(props) =>
    props.isExtraSmallHeightScreen
      ? props.theme.typography.smallFontSize
      : props.theme.typography.defaultFontSize};
  color: ${({ theme }) => theme.color.grey};
`

export const MedicalBusinessIcon = styled(MedicalServicesIcon)`
  ${ExtraLargeIconStyle}
`

export const PrivacyIcon = styled(PrivacyTipIcon)`
  ${ExtraLargeIconStyle}
  color: ${({ theme }) => theme.color.tooltipGrey};
`

export const HeroContainer = styled.div`
  ${HeroImageStyle}
  ${DefaultHeroSectionHeightStyle}
  background-image: url('https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/clinic-with-background-clouds.png');
  background-size: contain;
`

export const UnderReviewTextContainer = styled.div`
  ${ColumnFlexStyle}
  ${InterSubsectionalGap}
  ${BaseWidth}
  ${BasePadding}
  text-align: center;
`

export const MyPracticesButtonContainer = styled.div`
  ${RowFlexStyle}
  justify-content: center;
  align-items: center;
  width: 100%;
`
