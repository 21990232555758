import styled from 'styled-components'
import { Modal, ModalContainer } from '../../../styles/shared-styled-components'

export const WelcomeDialog = styled(Modal)`
  & .MuiDialog-paper.MuiPaper-root {
    border-radius: 20px;
  }
`

export const WelcomeDialogContainer = styled(ModalContainer)`
  border-radius: 20px;
`
