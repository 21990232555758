import styled, { css } from 'styled-components/macro'
import {
  ColumnFlexStyle,
  IntraSubsectionalGap,
} from '../../../../styles/shared-styled-components'

const SpecialTextMarginStyle = css`
  margin: 0px 3px;
`

export const CommissionPercentageText = styled.span`
  color: ${({ theme }) => theme.color.primary};
  ${SpecialTextMarginStyle}
`

export const SavingsText = styled.span`
  color: ${({ theme }) => theme.color.brightGreen};
  ${SpecialTextMarginStyle}
`

export const IncompleteSectionsContainer = styled.div`
  margin-top: 15px;
  ${ColumnFlexStyle}
  justify-content: flex-start;
  align-items: center;
  ${IntraSubsectionalGap}
`

export const IncompleteSectionNameText = styled.div`
  color: ${({ theme }) => theme.color.primary};
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`
