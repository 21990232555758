import React, { useCallback, useState } from 'react'
import { useMutationWithErrorLogging } from '../../../utils/hooks'
import { useNavigate, useParams } from 'react-router-dom'
import {
  PopupBox,
  PopupContainer,
  HeaderTitle,
  HeaderInfo,
  BuyerForm,
  Box,
  BuyerButtons,
  BuyerContinueButton,
  BuyerBackButton,
} from './styled'
import CreatePracticeInput from '../../CreatePractice/CreatePracticeInput'
import { CREATE_BUYER } from '../../../api/mutations/buyers'
import { buyerTypes } from '../../../utils/constants'

const BuyerProfile = (props) => {
  const { ismobilescreen } = props
  const navigate = useNavigate()
  const [buyerType, setBuyerType] = useState('')
  const { practiceId } = useParams()
  const [loading, setLoading] = useState(false)

  const [createBuyerMutation] = useMutationWithErrorLogging(CREATE_BUYER)

  const handleBuyerType = (e) => {
    setBuyerType(e.target.value)
  }

  const handleContinue = useCallback(() => {
    try {
      if (!buyerType || loading) return
      setLoading(true)
      createBuyerMutation({
        variables: {
          type: buyerType,
        },
        onCompleted: async (data) => {
          const createBuyer = data.createBuyer
          // Navigate to the next step
          if (buyerType === 1) {
            const buyerProfileLink = `/buyers/${createBuyer.id}?practice_id=${practiceId}`
            navigate(buyerProfileLink)
            setLoading(false)
          } else {
            const buyerProfileLink = `/buyers-institutional/${createBuyer.id}?practice_id=${practiceId}`
            navigate(buyerProfileLink)
            setLoading(false)
          }
        },
      })
    } catch (error) {
      console.log(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practiceId, buyerType, loading])

  return (
    <PopupBox>
      <PopupContainer>
        <HeaderTitle> Buyer Profile</HeaderTitle>
        <HeaderInfo>
          We need a bit more information about who you are as a buyer since this
          is your first interaction with a seller
        </HeaderInfo>
        <BuyerForm>
          <Box className='mt-2'>
            <CreatePracticeInput
              label='What type of buyer are you?'
              width='90%'
              options={buyerTypes}
              fieldType='dropdown'
              onChange={handleBuyerType}
            />
          </Box>
        </BuyerForm>
        <BuyerButtons>
          {ismobilescreen ? (
            <>
              <BuyerContinueButton disabled={loading} onClick={handleContinue}>
                {loading ? 'Please wait...' : 'Continue'}
              </BuyerContinueButton>
              <BuyerBackButton onClick={props.toggle} disabled={loading}>
                Back
              </BuyerBackButton>
            </>
          ) : (
            <>
              <BuyerBackButton onClick={props.toggle} disabled={loading}>
                Back
              </BuyerBackButton>
              <BuyerContinueButton disabled={loading} onClick={handleContinue}>
                {loading ? 'Please wait...' : 'Continue'}
              </BuyerContinueButton>
            </>
          )}
        </BuyerButtons>
      </PopupContainer>
    </PopupBox>
  )
}

export default BuyerProfile
