import _ from 'lodash'

const eventEmitter = new EventTarget()

const useEventEmitter = () => {
  const subscribe = (eventName, callback) => {
    eventEmitter.addEventListener(eventName, (event) => {
      callback(event.detail)
    })

    return () => {
      eventEmitter.removeEventListener(eventName, callback)
    }
  }

  const debouncedEmitEvent = _.debounce((eventName, eventData) => {
    const event = new CustomEvent(eventName, { detail: eventData })
    eventEmitter.dispatchEvent(event)
  }, 1000)

  const emitEvent = (eventName, eventData) => {
    debouncedEmitEvent(eventName, eventData)
  }

  return { subscribe, emitEvent }
}

export default useEventEmitter
