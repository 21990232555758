import PrivacyTipIcon from '@mui/icons-material/PrivacyTip'
import styled, { css } from 'styled-components/macro'

export const PrivacyIcon = styled(PrivacyTipIcon)`
  color: ${({ theme }) => theme.color.tooltipGrey};
  ${(props) =>
    props.format === 'title' &&
    css`
      height: 27px;
      width: 27px;
    `}
`
