import React from 'react'
import Header from '../../../components/NavBar/Header'
import {
  FullPageContentContainerWithGap,
  PageContainer,
} from '../../../styles/shared-styled-components'
import {
  BuyerProfileContainer,
  BuyerProfileTitle,
  CreateForm,
  ImageColumn,
  UploadBox,
  ImageUploadTitle,
  ImageUpload,
  ImageIcon,
  CameraIcon,
  FirstInputColumn,
  Box,
  SecondInputColumn,
  BuyerProfileButtons,
  BuyerProfileBackButton,
  BuyerProfileContinueButton,
} from './styled'
import CreatePracticeInput from '../../../components/CreatePractice/CreatePracticeInput'
import CreateBuyerProfileInput from '../CreateBuyerProfileInput/CreateBuyerProfileInput'

const CreateBuyerProfileForm = ({ user, width, ismobilescreen }) => {
  const languages = [
    {
      key: 1,
      label: 'English',
    },
    {
      key: 2,
      label: 'Hindi',
    },
    {
      key: 3,
      label: 'French',
    },
    {
      key: 4,
      label: 'Other',
    },
  ]
  const procedures = [
    {
      key: 1,
      label: 'Implant',
    },
    {
      key: 2,
      label: 'Orthodontics',
    },
    {
      key: 3,
      label: 'Surgery',
    },
    {
      key: 4,
      label: 'Other',
    },
  ]

  return (
    <>
      <Header user={user} width={width} ismobilescreen={ismobilescreen} />
      <PageContainer>
        <FullPageContentContainerWithGap
          ismobilescreen={ismobilescreen}
        ></FullPageContentContainerWithGap>
        <BuyerProfileContainer>
          <BuyerProfileTitle>Buyer Profile</BuyerProfileTitle>
          <CreateForm>
            <ImageColumn>
              <UploadBox>
                <ImageUploadTitle>Upload a profile picture</ImageUploadTitle>
                <ImageUpload>
                  <ImageIcon></ImageIcon>
                </ImageUpload>
                <CameraIcon></CameraIcon>
              </UploadBox>
            </ImageColumn>

            <FirstInputColumn>
              <Box className='mt-2'>
                <CreatePracticeInput
                  label='In what year did you graduate?'
                  width='90%'
                  options={[]}
                  fieldType='dropdown'
                />
              </Box>
              <Box className='mt-2'>
                <CreatePracticeInput
                  label='What dental school did you graduate from?'
                  width='90%'
                  options={[]}
                  fieldType='text'
                />
              </Box>
              <Box className='mt-2'>
                <CreateBuyerProfileInput
                  label='Which languages do you speak?'
                  options={languages}
                />
              </Box>
              <Box className='mt-2'>
                <CreateBuyerProfileInput
                  label='What procedures can you perform?'
                  options={procedures}
                />
              </Box>
            </FirstInputColumn>

            <SecondInputColumn>
              <Box className='mt-2'>
                <CreatePracticeInput
                  label='What is your average monthly production?'
                  width='90%'
                  options={[]}
                  fieldType='text'
                />
              </Box>
              <Box className='mt-2'>
                <CreatePracticeInput
                  label='How many practices do you  own?'
                  width='90%'
                  options={[]}
                  fieldType='text'
                />
              </Box>
              <Box className='mt-2'>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </Box>
            </SecondInputColumn>
          </CreateForm>

          <BuyerProfileButtons>
            <BuyerProfileBackButton>Back</BuyerProfileBackButton>
            <BuyerProfileContinueButton>Continue</BuyerProfileContinueButton>
          </BuyerProfileButtons>
        </BuyerProfileContainer>
      </PageContainer>
    </>
  )
}

export default CreateBuyerProfileForm
