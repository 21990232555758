import React from 'react'
import {
  AllFilesInput,
  FileInputLabel,
  FileInputSubtitle,
  ImageInput,
  PdfOrCsvInput,
} from './styled'

const FileInput = ({ fileType, onUpload, filesAlreadyUploaded, disabled }) => {
  const elementId = fileType
  const subtitleText =
    fileType === 'all'
      ? 'Supports all files'
      : fileType === 'images'
        ? 'Supports PNG, JPG, GIF, and WEBP formats'
        : 'Supports PDF, CSV, and XLS formats'

  const inputLabel = filesAlreadyUploaded ? 'Upload more' : 'Upload'

  return (
    <>
      <FileInputLabel className={disabled ? 'disabled' : ''} for={elementId}>
        {inputLabel}
      </FileInputLabel>
      {fileType === 'all' ? (
        <AllFilesInput id={elementId} onChange={onUpload} />
      ) : fileType === 'images' ? (
        <ImageInput id={elementId} onChange={onUpload} />
      ) : (
        <PdfOrCsvInput id={elementId} onChange={onUpload} />
      )}
      <FileInputSubtitle>{subtitleText}</FileInputSubtitle>
    </>
  )
}

export default FileInput
