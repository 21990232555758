import gql from 'graphql-tag'
import {
  ListingFragment,
  MostRecentValuationFragment,
  ShallowListingFragment,
} from '../fragments/practices'

export const GET_ALL_PRACTICE_LISTINGS = gql`
  ${ShallowListingFragment}
  ${MostRecentValuationFragment}
  query getAllPracticeListings {
    getAllPracticeListings {
      id
      name
      createdBy
      type
      country
      province
      latitude
      longitude
      operatories
      hygieneRevenuePercentage
      practiceOwnerWorkingSchedule
      ownsBuilding
      buildingType
      saleType
      targetPrice
      ...ShallowListingFragment
      ...MostRecentValuationFragment
      createdAt
      updatedAt
      completedAt
    }
  }
`

export const GET_SOLD_PRACTICE_LISTINGS = gql`
  ${ShallowListingFragment}
  query getSoldPracticeListings {
    getSoldPracticeListings {
      id
      name
      type
      country
      province
      postalCode
      latitude
      longitude
      averageAnnualBillings
      operatories
      targetPrice
      ...ShallowListingFragment
      createdAt
      updatedAt
      completedAt
    }
  }
`

export const GET_MY_PRACTICES = gql`
  ${ListingFragment}
  ${MostRecentValuationFragment}
  query getMyPractices {
    getMyPractices {
      id
      name
      createdBy
      type
      address
      country
      city
      province
      dpms
      website
      pictures
      sectionCurrentlyEditing
      ...MostRecentValuationFragment
      ...ListingFragment
      createdAt
      updatedAt
      completedAt
      dueDiligence {
        practiceProfile {
          numOfOps
          opsPurposes
          paymentTerminalVendor
          yearFounded
          yearOpened
        }
        legal {
          equipmentRentals
          legal
          permiseRentalLease
          regulatoryCompliance
          vendorContracts
        }
        humanResource {
          employees
          employeesSpreadsheet
        }
        accounting {
          fiscalYearEnd
          forecast
          generalLedgers
          incomeStatements
          trialBalances
          financialStatement
        }
        practiceManagementReports {
          agedAccRecProvider
          agedAccRecTotalPractice
          patientCount
          procedureCodesPerMonth
          procedureCodesPerYear
          referredOutCases
          totalAnnualCollections
          totalMonthlyCollections
        }
      }
    }
  }
`

export const GET_PRACTICE_DATAROOM = gql`
  query getPracticeDataroom($id: String!) {
    getPracticeDataroom(id: $id) {
      practiceId
      dueDiligence
    }
  }
`

export const GET_PRACTICE_BY_ID = gql`
  ${ListingFragment}
  ${MostRecentValuationFragment}
  query getPracticeById($id: String!) {
    getPracticeById(id: $id) {
      id
      name
      createdBy
      type
      country
      city
      province
      address
      latitude
      longitude
      postalCode
      averageAnnualBillings
      operatories
      hygieneRevenuePercentage
      practiceOwnerWorkingSchedule
      dpms
      partTimeAssociates
      fullTimeAssociates
      partTimeHygienists
      fullTimeHygienists
      partTimeAdmins
      fullTimeAdmins
      partTimeAssistants
      fullTimeAssistants
      ownsBuilding
      buildingHasAppraisal
      saleType
      roomForOperatoryExpansion
      numOfExtraOperatories
      isInvisalignCertified
      hasIntraoralScanner
      practiceHasDebt
      canAddAssociate
      buildingType
      buildingHasDemolitionClause
      yearsLeftOnLease
      targetPrice
      timeFrameLookingToSell
      monthsWorkingPostSale
      usedLifetimeCapitalGainsExemption
      website
      pictures
      sectionCurrentlyEditing
      ...MostRecentValuationFragment
      ...ListingFragment
      productionReportUrl
      dueDiligence {
        practiceProfile {
          numOfOps
          opsPurposes
          yearFounded
          yearOpened
          paymentTerminalVendor
        }
        accounting {
          fiscalYearEnd
          financialStatement
          generalLedgers
          incomeStatements
          trialBalances
          forecast
        }
        legal {
          legal
          permiseRentalLease
          vendorContracts
          equipmentRentals
          regulatoryCompliance
        }
        practiceManagementReports {
          totalAnnualCollections
          totalMonthlyCollections
          procedureCodesPerMonth
          procedureCodesPerYear
          agedAccRecTotalPractice
          agedAccRecProvider
          patientCount
          referredOutCases
        }
        humanResource {
          employeesSpreadsheet
          employees
        }
      }
      createdAt
      updatedAt
      completedAt
      patientsSeenWeekly
      onlyProvidesAnesthesia
      numberDentistsRefer
      openDaysPerWeek
      numberOfPatients
      numberOfDoctors
    }
  }
`
