import React from 'react'
import {
  StyledErrorAlert,
  StyledInfoAlert,
  StyledSnackbar,
  StyledSuccessAlert,
} from '../../styles/shared-styled-components'

const Snackbar = ({ isOpen, onClose, message, variant = 'success' }) => {
  return (
    <StyledSnackbar open={isOpen} onClose={onClose}>
      {variant === 'success' ? (
        <StyledSuccessAlert onClose={onClose}>{message}</StyledSuccessAlert>
      ) : variant === 'info' ? (
        <StyledInfoAlert onClose={onClose}>{message}</StyledInfoAlert>
      ) : (
        <StyledErrorAlert onClose={onClose}>{message}</StyledErrorAlert>
      )}
    </StyledSnackbar>
  )
}

export default Snackbar
