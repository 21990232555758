import { useMutationWithErrorLogging } from '../../utils/hooks'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import React, { useCallback, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useBuyerById } from '../../api/hooks/buyers'
import { CREATE_ACQUISITION } from '../../api/mutations/acquisitions'
import LoadingIndicator from '../../components/LoadingIndicator'
import MyAcquisition from '../../components/Micro/MyAcquisition/MyAcquisition'
import Header from '../../components/NavBar/Header'
import {
  BackgroundContainer,
  ErrorHelperText,
  PageContainer,
} from '../../styles/shared-styled-components'
import {
  BasicLabel,
  CancelButton,
  FlexContainer,
  FullPageFlexContainer,
  NextButton,
  ResponsiveFlexContainer,
  StyledBasicLabel,
  StyledDropBoxWrapper,
  StyledFolderIcon,
  StyledNoteLabel,
  TitleText,
  Wrapper,
} from '../BuyerProfile/styled'
import _ from 'lodash'
import {
  acquisitionTypes,
  digitsRegex,
  stringRegex,
} from '../../utils/constants'
import { getCapitalizedWord } from '../../utils/naming'
import {
  UPLOAD_BUYER_FILE,
  UPLOAD_BUYER_PREFINANCING_REPORT,
} from '../../api/mutations/buyers'
import FileThumbnail from '../../components/Micro/Thumbnails/FileThumbnail/FileThumbnail'
import styled from 'styled-components'
import { folderIcon } from '../../components/NavBar/ProfileDropdown/styled'
import { Upload } from '@mui/icons-material'

const defaultPayload = {
  years_to_work_post_sale: '',
  pre_approved_financing: '',
  percentage_to_buy: '',
  buying_conditions: '',
  pre_financing_report: '',
}

const RequestForAccess = ({ user, width, ismobilescreen }) => {
  const [payload, setPayload] = useState(defaultPayload)
  const [buyingConditions, setBuyingConditions] = useState([])
  const [showPopup, setShowPopup] = useState(false)
  const [errors, setErrors] = useState(null)
  const [uploadingFile, setUploadingFile] = useState(false)

  const { buyerId } = useParams()
  const { buyer } = useBuyerById(buyerId)
  const { search } = useLocation()

  const queryParams = new URLSearchParams(search)
  const practiceId = queryParams.get('practice_id')
  const [loading, setLoading] = useState(false)

  const [createAcquisitionMutation] =
    useMutationWithErrorLogging(CREATE_ACQUISITION)
  const [uploadBuyerPreFinancingReport] = useMutationWithErrorLogging(
    UPLOAD_BUYER_PREFINANCING_REPORT,
  )

  const handleBuyingCondition = (e) => {
    let modifyBuyingCondition = []
    if (e.target.checked) {
      modifyBuyingCondition = [...buyingConditions, e.target.value]
    } else {
      modifyBuyingCondition = buyingConditions.filter(
        (buyingCondition) => buyingCondition !== e.target.value,
      )
    }
    setBuyingConditions(modifyBuyingCondition)
    setPayload({
      ...payload,
      buying_conditions: modifyBuyingCondition.join(','),
    })
  }

  const validate = useCallback(() => {
    const errors = {}
    const requiredFields = [
      {
        name: 'years_to_work_post_sale',
        regex: digitsRegex,
      },
      {
        name: 'pre_approved_financing',
        regex: stringRegex,
      },
      {
        name: 'percentage_to_buy',
        regex: stringRegex,
      },
    ]

    requiredFields.forEach(({ name, regex }) => {
      if (!(payload[name]?.trim() && regex.test(payload?.[name]?.trim())))
        errors[name] = `${getCapitalizedWord(name.replace(/_/g, ' '))} is ${
          payload[name]?.trim() ? 'required' : 'invalid'
        }`
    })

    setErrors(errors)
    return _.isEmpty(Object.keys(errors))
  }, [payload])

  const onRemoveFile = () => {
    setPayload({ ...payload, pre_financing_report: null })
  }

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  const handleContinue = useCallback(() => {
    if (loading || !validate()) return
    setLoading(true)
    createAcquisitionMutation({
      variables: {
        ...payload,
        buyer_id: buyerId,
        practice_id: practiceId,
        type: acquisitionTypes.ACCESS_REQUEST,
        buying_conditions: _.isEmpty(payload?.buying_conditions)
          ? ''
          : payload?.buying_conditions,
      },
      onCompleted: () => {
        setLoading(false)
        togglePopup()
      },
      onError: () => {
        setLoading(false)
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerId, practiceId, payload, loading])

  function togglePopup() {
    setShowPopup(!showPopup)
  }

  const handleInput = useCallback(
    (e) => {
      setPayload({
        ...payload,
        [e.target.name]: e.target.value,
      })
    },
    [validate, payload],
  )

  const handleFileInput = (e) => {
    e.preventDefault()
    const file =
      e.target.files[0] || (e.dataTransfer && e.dataTransfer.files[0])
    if (file) {
      const reader = new FileReader()
      reader.onloadend = async () => {
        try {
          setUploadingFile(true)
          await uploadBuyerPreFinancingReport({
            variables: {
              id: buyerId,
              file: {
                file: reader.result,
              },
            },
            onCompleted: (data) => {
              const fileUrl = data.uploadFile
              if (fileUrl) {
                setPayload({ ...payload, pre_financing_report: fileUrl })
              }
              setUploadingFile(false)
            },
          })
        } catch (error) {
          console.error('Error uploading production report file:', error)
        }
      }
      reader.readAsDataURL(file)
    }
  }

  return buyer ? (
    <>
      <Header user={user} width={width} ismobilescreen={ismobilescreen} />
      <BackgroundContainer>
        <PageContainer>
          <FullPageFlexContainer>
            <Wrapper>
              <TitleText style={{ textAlign: 'center' }}>
                Request for Access
              </TitleText>

              <FlexContainer className='with-gapping'>
                <Box>
                  <FormControl fullWidth>
                    <TextField
                      name='years_to_work_post_sale'
                      value={payload?.years_to_work_post_sale}
                      label='Number of years for seller to work post-sale*'
                      variant='outlined'
                      placeholder='Enter number of years for seller to work post-sale'
                      onChange={handleInput}
                      error={errors?.years_to_work_post_sale}
                      helperText={errors?.years_to_work_post_sale}
                    />
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth error={errors?.pre_approved_financing}>
                    <InputLabel>
                      Do you have pre approved financing?*
                    </InputLabel>
                    <Select
                      name='pre_approved_financing'
                      value={payload?.pre_approved_financing}
                      label='Do you have pre approved financing?*'
                      onChange={handleInput}
                    >
                      <MenuItem value={''}>Select</MenuItem>
                      <MenuItem value={'Yes'}>Yes</MenuItem>
                      <MenuItem value={'No'}>No</MenuItem>
                    </Select>
                  </FormControl>
                  <ErrorHelperText style={{ marginLeft: 12 }}>
                    {errors?.pre_approved_financing}
                  </ErrorHelperText>
                </Box>

                {payload?.pre_approved_financing === 'Yes' && (
                  <StyledDropBoxWrapper
                    className='drop-zone'
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={handleFileInput}
                  >
                    {uploadingFile ? (
                      <Box
                        sx={{
                          width: '80px',
                          height: '80px',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <LoadingIndicator size={60} />
                      </Box>
                    ) : payload?.pre_financing_report ? (
                      <FileThumbnail
                        filePath={payload.pre_financing_report}
                        onRemove={onRemoveFile}
                      />
                    ) : (
                      <StyledFolderIcon src={folderIcon} />
                    )}
                    <BasicLabel>
                      Drag your files here or&nbsp;
                      <StyledBasicLabel style={{ cursor: 'pointer' }}>
                        browse
                        <VisuallyHiddenInput
                          type='file'
                          onChange={handleFileInput}
                        />
                      </StyledBasicLabel>
                    </BasicLabel>
                    <StyledNoteLabel>Supports PDF, CSV</StyledNoteLabel>
                  </StyledDropBoxWrapper>
                )}

                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth error={errors?.percentage_to_buy}>
                    <InputLabel>
                      What percentage are you willing to buy?*
                    </InputLabel>
                    <Select
                      name='percentage_to_buy'
                      value={payload?.percentage_to_buy}
                      label='What percentage are you willing to buy?*'
                      onChange={handleInput}
                    >
                      <MenuItem value={'0-20%'}>0-20%</MenuItem>
                      <MenuItem value={'20-40%'}>20-40%</MenuItem>
                      <MenuItem value={'40-60%'}>40-60%</MenuItem>
                      <MenuItem value={'60-80%'}>60-80%</MenuItem>
                      <MenuItem value={'80-100%'}>80-100%</MenuItem>
                    </Select>
                  </FormControl>
                  <ErrorHelperText style={{ marginLeft: 12 }}>
                    {errors?.percentage_to_buy}
                  </ErrorHelperText>
                </Box>
                <Box>
                  <StyledBasicLabel>
                    Buying conditions <small>(optional)</small>
                  </StyledBasicLabel>
                  <FormControl fullWidth>
                    <Box sx={{ display: 'flex' }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={handleBuyingCondition}
                              value={'Hold-back'}
                              checked={buyingConditions?.includes('Hold-back')}
                            />
                          }
                          label='Hold-back'
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={handleBuyingCondition}
                              value={'Earn-out'}
                              checked={buyingConditions?.includes('Earn-out')}
                            />
                          }
                          label='Earn-out'
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={handleBuyingCondition}
                              value={'Vendor take-back loan'}
                              checked={buyingConditions?.includes(
                                'Vendor take-back loan',
                              )}
                            />
                          }
                          label='Vendor take-back loan'
                        />
                      </FormGroup>
                    </Box>
                  </FormControl>
                </Box>
              </FlexContainer>
            </Wrapper>
            <ResponsiveFlexContainer block footer>
              {ismobilescreen ? (
                <>
                  <NextButton
                    variant='contained'
                    sx={{ textTransform: 'none', marginBottom: '10px' }}
                    disabled={loading || uploadingFile}
                    onClick={handleContinue}
                  >
                    {loading ? 'Please wait...' : 'Continue'}
                  </NextButton>
                  {showPopup && (
                    <MyAcquisition toggle={togglePopup} buyerId={buyerId} />
                  )}
                  <CancelButton
                    disabled={loading || uploadingFile}
                    onClick={() => window.history.back()}
                  >
                    Back
                  </CancelButton>
                </>
              ) : (
                <>
                  <CancelButton
                    disabled={loading || uploadingFile}
                    onClick={() => window.history.back()}
                  >
                    Back
                  </CancelButton>
                  <NextButton
                    variant='contained'
                    sx={{ textTransform: 'none', marginLeft: 'auto' }}
                    disabled={loading || uploadingFile}
                    onClick={handleContinue}
                  >
                    {loading ? 'Please wait...' : 'Continue'}
                  </NextButton>
                  {showPopup && (
                    <MyAcquisition toggle={togglePopup} buyerId={buyerId} />
                  )}
                </>
              )}
            </ResponsiveFlexContainer>
          </FullPageFlexContainer>
        </PageContainer>
      </BackgroundContainer>
    </>
  ) : (
    <LoadingIndicator fullScreen />
  )
}

export default RequestForAccess
