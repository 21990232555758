import styled, { css } from 'styled-components/macro'
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../styles/shared-styled-components'

const ButtonStyle = css`
  width: 200px;
`

export const PrimaryCompleteListingButton = styled(PrimaryButton)`
  ${ButtonStyle}
`

export const SecondaryCompleteListingButton = styled(SecondaryButton)`
  ${ButtonStyle}
`
