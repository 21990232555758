import { Box } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Confetti from 'react-confetti'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Paths from '../../../Paths'
import { usePracticeById } from '../../../api/hooks/practices'
import { usePracticeValuations } from '../../../api/hooks/valuations'
import CreatePracticeInputForm from '../../../components/CreatePractice/CreatePracticeInputForm/CreatePracticeInputForm'
import LoadingIndicator from '../../../components/LoadingIndicator'
import InfoIcon from '../../../components/Micro/InfoIcon/InfoIcon'
import Header from '../../../components/NavBar/Header'
import BarChart from '../../../components/Valuations/Chart/BarChart'
import { FlexDiv } from '../../../styles/shared-styled-components'
import {
  getFormattedMoneyStringRange,
  getFormattedMoneyStringWithNoCents,
} from '../../../utils/numbers'
import {
  getCreatePracticePathToNavigateTo,
  makeCreatePracticePathInCurrentFlow,
} from '../../../utils/routing'
import {
  ValuationResultText,
  ValuationResultUnderlinedText,
  ValuationWrapper,
  DiscoveryCallButton,
} from './styled'

const currentStep = 4

const maxValuationsToDisplay = 3

const ValuationInfoMessage = (
  <>
    Dentacloud uses AI to estimate practice value. This is a reference point
    only. Book a discovery call for better insight into potential value of your
    practice.
  </>
)

const Valuation = ({ user, width, ismobilescreen }) => {
  const navigate = useNavigate()
  const practiceFormRef = useRef()

  const { practiceId } = useParams()
  const { valuations } = usePracticeValuations(practiceId)
  const { practice } = usePracticeById(practiceId)
  const [showConfetti, setShowConfetti] = useState(true)
  const [searchParams] = useSearchParams()
  const currentFlow = searchParams.get('flow')

  // Animation delays
  const [showGraphAndCallToAction, setShowGraphAndCallToAction] =
    useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowGraphAndCallToAction(true)
    }, 1000)

    return () => clearTimeout(timer)
  }, [])

  const slicedValuations = valuations.slice(-maxValuationsToDisplay)

  const currentValuation = slicedValuations[slicedValuations.length - 1]

  const handleClick = () => {
    window.open('https://calendly.com/drjohn-dc/30min', '_blank')
  }

  const stopConfetti = useCallback(() => {
    setShowConfetti(false)
  }, [])

  useEffect(() => {
    async function stopConfettiOnTimer() {
      setTimeout(stopConfetti, 3000)
    }
    if (showConfetti) {
      stopConfettiOnTimer()
    }
  }, [showConfetti, stopConfetti])

  const onClickNext = () => {
    const inValuationMode = searchParams.get('valuation') === 'true'

    let nextPath
    if (inValuationMode) {
      nextPath = Paths.practices
    } else {
      nextPath = getCreatePracticePathToNavigateTo(
        currentStep,
        practiceId,
        'next',
        currentFlow,
      )
      if (currentFlow) {
        nextPath = makeCreatePracticePathInCurrentFlow(nextPath, currentFlow)
      }
    }

    // Navigate to the next step
    navigate(nextPath)
  }

  return (
    <>
      <Header user={user} width={width} ismobilescreen={ismobilescreen} />
      <CreatePracticeInputForm
        ref={practiceFormRef}
        user={user}
        width={width}
        ismobilescreen={ismobilescreen}
        currentStep={currentStep}
        practice={practice}
        formTitle={
          <>
            Your Practice Valuation <InfoIcon message={ValuationInfoMessage} />
          </>
        }
        formInputs={[]}
        allowSkipping={false}
        currentFlow={currentFlow}
        inValuationMode={true}
        onValuationPage={true}
      >
        {practice && currentValuation ? (
          <ValuationWrapper ismobilescreen={ismobilescreen}>
            <Confetti
              size={8}
              shape='circle'
              numberOfPieces={showConfetti ? 300 : 0}
            />
            <ValuationResultText
              ismobilescreen={ismobilescreen}
              colorScheme='secondary'
            >
              Similar practices sell for{' '}
              <ValuationResultUnderlinedText
                ismobilescreen={ismobilescreen}
                colorScheme='secondary'
              >
                {getFormattedMoneyStringWithNoCents(
                  currentValuation?.lowerBound,
                )}
              </ValuationResultUnderlinedText>{' '}
              to{' '}
              <ValuationResultUnderlinedText
                ismobilescreen={ismobilescreen}
                colorScheme='secondary'
              >
                {getFormattedMoneyStringWithNoCents(
                  (currentValuation?.lowerBound +
                    currentValuation?.upperBound) /
                    2,
                )}
              </ValuationResultUnderlinedText>
            </ValuationResultText>
            {showGraphAndCallToAction && (
              <>
                <BarChart
                  lowerBound={currentValuation?.lowerBound}
                  upperBound={currentValuation?.upperBound}
                  median={Math.round(
                    (currentValuation?.lowerBound +
                      currentValuation?.upperBound) /
                      2,
                  )}
                  ismobilescreen={ismobilescreen}
                />
                <ValuationResultText
                  ismobilescreen={ismobilescreen}
                  colorScheme='primary'
                >
                  Learn how Dentacloud can sell yours for over{' '}
                  <ValuationResultUnderlinedText
                    ismobilescreen={ismobilescreen}
                    colorScheme='primary'
                  >
                    {getFormattedMoneyStringWithNoCents(
                      currentValuation?.upperBound,
                    )}
                  </ValuationResultUnderlinedText>
                </ValuationResultText>
                <DiscoveryCallButton onClick={handleClick}>
                  Book Discovery Call
                </DiscoveryCallButton>
              </>
            )}
          </ValuationWrapper>
        ) : (
          <FlexDiv
            style={{
              width: '100%',
              height: 'calc(100vh - 400px)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <LoadingIndicator />
          </FlexDiv>
        )}
      </CreatePracticeInputForm>
    </>
  )
}

export default Valuation
