import { useApolloClient } from '@apollo/client'
import { useMutationWithErrorLogging } from '../../../utils/hooks'
import get from 'lodash/get'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Paths from '../../../Paths'
import { usePracticeById } from '../../../api/hooks/practices'
import { PUBLISH_PRACTICE_LISTING } from '../../../api/mutations/practices'
import CreatePracticeFlowNavigation from '../../../components/CreatePractice/CreatePracticeFlowNavigation'
import { CreatePracticeDropdownLabel } from '../../../components/CreatePractice/CreatePracticeInput/styled'
import SavingsSpecificCommissionModal from '../../../components/CreatePractice/CreatePracticeModals/SavingsSpecificCommissionModal'
import ArrowIcon from '../../../components/Micro/ArrowIcon'
import CircleWithNumber from '../../../components/Micro/CircleWithNumber'
import CircularProgress from '../../../components/Micro/CircularProgress'
import ImageThumbnail from '../../../components/Micro/Thumbnails/ImageThumbnail/ImageThumbnail'
import Header from '../../../components/NavBar/Header'
import {
  Dropdown,
  FullPageContentContainerWithGap,
  MenuItemDivider,
  PageContainer,
  StyledMenuItem,
  SubtitleText,
  VUNSBC,
  OverviewParagraphText,
  SaveMoneyText,
} from '../../../styles/shared-styled-components'
import { updatePracticeByIdInCache } from '../../../utils/cache'
import {
  activeListingSaleStages,
  whiteGloveServiceRate,
  discountedCommissionRate,
  forSaleStageKey,
  offMarketStageKey,
  saleStageLabelsForSellers,
  ConnectServicePlans,
  publishingFlowKey,
  underReviewStatusKey,
} from '../../../utils/constants'
import {
  checkIfPracticeIncludesFinancials,
  checkIfPracticeIncludesPictures,
  getExpandedPracticeFieldsBySection,
} from '../../../utils/data'
import {
  getAbbreviatedMoneyStringRange,
  getEstimatedCommissionDiscountSavings,
  getFormattedMoneyString,
  getPracticeCompletionPercentage,
} from '../../../utils/numbers'
import {
  getCreatePracticePathFromSectionName,
  getCreatePracticeRouteFromId,
  getCreatePracticePathToNavigateTo,
  makeCreatePracticePathInPublishingFlow,
  navigateToDueDiligenceSectionToEdit,
  navigateToCreatePracticeSectionToEditInPublishingFlow,
} from '../../../utils/routing'
import {
  screenWidthIsSmallMobileSizeOrSmaller,
  scrollElementIntoView,
} from '../../../utils/view'
import {
  CongratsText,
  DropdownForm,
  EditBlock,
  EditBlockContainer,
  EditBlockTitleText,
  EditBlockTitleTextContainer,
  EditInfoButton,
  ExpandedFieldContainer,
  ExpandedFieldLabel,
  ExpandedFieldValue,
  ExpandedFieldsContainer,
  ExpandedPicturesContainer,
  ExpandedSection,
  InfoSectionButton,
  InfoSectionContainer,
  InfoSectionsContainer,
  LeaveToEditIcon,
  LeftSectionContentContainer,
  ListingTypeContainer,
  ListingTypeSubtitleText,
  ListingTypeTitleText,
  MenuItemDividerWithBottomMargin,
  PercentCompleteContainer,
  PercentCompleteParagraphText,
  PracticeAvatar,
  PracticeAvatarContainer,
  PracticeOverviewContainer,
  RightSectionContentContainer,
  SectionCompletionStatusText,
  StyledCreatePracticeFormContainer,
  PracticeAvatarAndTextContainer,
  PracticeOverviewTextContainer,
} from './styled'

const Publish = ({ user, width, ismobilescreen }) => {
  const navigate = useNavigate()
  const { cache } = useApolloClient()
  const [searchParams] = useSearchParams()

  const { practiceId } = useParams()
  const { practice } = usePracticeById(practiceId)

  const [
    publishPracticeListingMutation,
    { data: publishPracticeListingData, loading },
  ] = useMutationWithErrorLogging(PUBLISH_PRACTICE_LISTING)

  const completionPercentage = getPracticeCompletionPercentage(practice)

  const lowerBound = practice?.mostRecentValuation?.lowerBound
  const upperBound = practice?.mostRecentValuation?.upperBound

  const valuationText = getAbbreviatedMoneyStringRange(lowerBound, upperBound)

  const currentFlow = publishingFlowKey

  const commissionRate =
    completionPercentage === 100
      ? discountedCommissionRate
      : whiteGloveServiceRate
  const estimatedCommissionSavings = getEstimatedCommissionDiscountSavings(
    lowerBound,
    upperBound,
  )

  const divRef = useRef(null)
  const [remainingHeight, setRemainingHeight] = useState(0)
  const [
    savingsSpecificCommisionModalOpen,
    setSavingsSpecificCommisionModalOpen,
  ] = useState(false)
  const [listingType, setListingType] = useState(
    !completionPercentage
      ? ''
      : completionPercentage === 100
        ? forSaleStageKey
        : offMarketStageKey,
  )
  const [overviewSectionOpen, setOverviewSectionOpen] = useState(false)
  const [teamSectionOpen, setTeamSectionOpen] = useState(false)
  const [leaseSectionOpen, setLeaseSectionOpen] = useState(false)
  const [termsSectionOpen, setTermsSectionOpen] = useState(false)
  const [picturesSectionOpen, setPicturesSectionOpen] = useState(false)
  const [financialsSectionOpen, setFinancialsSectionOpen] = useState(false)
  const [elementIdToScrollTo, setElementIdToScrollTo] = useState()

  // Setting the remaining height - need to watch the practice variable,
  // as the page rerenders after that
  useEffect(() => {
    const handleResize = () => {
      const divHeight = divRef?.current?.getBoundingClientRect().top
      const windowHeight = window.innerHeight
      const heightDifference = windowHeight - divHeight
      setRemainingHeight(heightDifference)
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Call it initially to get the remaining height

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [
    practice,
    overviewSectionOpen,
    teamSectionOpen,
    termsSectionOpen,
    picturesSectionOpen,
    financialsSectionOpen,
  ])

  useEffect(() => {
    if (practice && !publishPracticeListingData) {
      setListingType(
        !completionPercentage
          ? ''
          : completionPercentage === 100
            ? forSaleStageKey
            : offMarketStageKey,
      )
    }
  }, [practice])

  // This scrolling wasn't working properly for the bottom sections, as the window would scroll before the page expands
  // To fix this, we use a useEffect hook, which "perform the layout measurements before the browser repaints the screen"
  // For the record I tried useLayoutEffect first, because it seemed like this might be a good use case for it, but
  // I think I saw more lag there then compared to a vanilla useEffect
  useEffect(() => {
    if (elementIdToScrollTo)
      scrollElementIntoView(document, elementIdToScrollTo, 'start')
  }, [elementIdToScrollTo])

  const incompleteSections = []
  let pathToFirstIncompleteSection = ''

  const includesFinancials = checkIfPracticeIncludesFinancials(practice)
  if (!includesFinancials) {
    incompleteSections.push('financials')
  }

  if (incompleteSections.length > 0) {
    pathToFirstIncompleteSection = getCreatePracticePathFromSectionName(
      incompleteSections[0],
      practiceId,
    )
    pathToFirstIncompleteSection = makeCreatePracticePathInPublishingFlow(
      pathToFirstIncompleteSection,
    )
  }

  const onClickNext = () => {
    publishPracticeListingMutation({
      variables: {
        id: practiceId,
        type: listingType,
      },
      onCompleted: async (data) => {
        await updatePracticeByIdInCache(
          practiceId,
          data?.publishPracticeListing,
          cache,
        )
        const practiceResultStatus = get(
          data,
          'publishPracticeListing.listing.status',
          '',
        )

        let nextPath
        // Send user to publishing landing page
        if (practiceResultStatus === underReviewStatusKey) {
          nextPath = getCreatePracticeRouteFromId(
            Paths.createPracticePublishedLandingPage,
            practiceId,
          )
        } else {
          // Default just go back to my practices
          nextPath = Paths.myPractices
        }

        navigate(nextPath)
      },
    })
  }

  const infoSections = [
    {
      title: 'Overview',
      label: 'Overview',
      isOpenState: overviewSectionOpen,
      isOpenStateCallback: setOverviewSectionOpen,
      completed: true,
    },
    {
      title: 'Team',
      label: 'Team',
      isOpenState: teamSectionOpen,
      isOpenStateCallback: setTeamSectionOpen,
      completed: true,
    },
    {
      title: 'Lease',
      label: 'Real Estate',
      isOpenState: leaseSectionOpen,
      isOpenStateCallback: setLeaseSectionOpen,
      completed: true,
    },
    {
      title: 'Terms',
      label: 'Terms',
      isOpenState: termsSectionOpen,
      isOpenStateCallback: setTermsSectionOpen,
      completed: true,
    },
    {
      title: 'Financials',
      label: 'Due Diligence',
      isOpenState: financialsSectionOpen,
      isOpenStateCallback: setFinancialsSectionOpen,
      completed: includesFinancials,
    },
  ]

  const practiceSectionFields = getExpandedPracticeFieldsBySection(practice)

  const getExpandedFieldsSection = (sectionName) => {
    const fields = practiceSectionFields[sectionName]

    return (
      <ExpandedSection ismobilescreen={ismobilescreen}>
        <EditInfoButton
          onClick={() =>
            navigateToCreatePracticeSectionToEditInPublishingFlow(
              sectionName,
              practiceId,
              navigate,
              currentFlow,
            )
          }
        >
          Edit info
          <LeaveToEditIcon />
        </EditInfoButton>
        <MenuItemDividerWithBottomMargin />
        <ExpandedFieldsContainer>
          {fields.map((field, idx) => (
            <>
              {!field.dontDisplay === true && (
                <>
                  <ExpandedFieldContainer>
                    <ExpandedFieldLabel>{field.label}</ExpandedFieldLabel>
                    <ExpandedFieldValue>{field.value}</ExpandedFieldValue>
                  </ExpandedFieldContainer>
                  {idx !== fields.length - 1 && <MenuItemDivider />}
                </>
              )}
            </>
          ))}
        </ExpandedFieldsContainer>
      </ExpandedSection>
    )
  }

  const expandedFieldSections = {
    Overview: getExpandedFieldsSection('overview'),
    Team: getExpandedFieldsSection('team'),
    Lease: getExpandedFieldsSection('lease'),
    Terms: getExpandedFieldsSection('terms'),
    Financials: [
      <ExpandedSection ismobilescreen={ismobilescreen}>
        <EditInfoButton
          onClick={() =>
            navigateToDueDiligenceSectionToEdit(
              'financials',
              practiceId,
              navigate,
              currentFlow,
            )
          }
        >
          Edit Due Diligence
          <LeaveToEditIcon />
        </EditInfoButton>
      </ExpandedSection>,
    ],
  }

  const isSmallMobileScreen = screenWidthIsSmallMobileSizeOrSmaller(width)

  const handleBack = () => {
    window.history.back()
  }

  const listingTypeOptions = useMemo(() => {
    return activeListingSaleStages.map((stageKey) => {
      const stage = saleStageLabelsForSellers[stageKey]
      const { label, completeSubtext, incompleteSubtext } = stage
      const saleStageDisabled = !practice?.mostRecentValuation
      const subtext = saleStageDisabled ? incompleteSubtext : completeSubtext

      return {
        label,
        subtext,
        stageKey,
        saleStageDisabled,
      }
    })
  }, [practice])

  return (
    practice && (
      <>
        <Header user={user} width={width} ismobilescreen={ismobilescreen} />
        <VUNSBC>
          <PageContainer>
            <FullPageContentContainerWithGap ismobilescreen={ismobilescreen}>
              <StyledCreatePracticeFormContainer
                ismobilescreen={ismobilescreen}
                ref={divRef}
                remainingHeight={remainingHeight}
              >
                <PracticeOverviewContainer>
                  <PracticeAvatarAndTextContainer>
                    {!isSmallMobileScreen && (
                      <PracticeAvatarContainer>
                        <PracticeAvatar
                          src={Paths.dentalClinicIcon}
                          ismobilescreen={ismobilescreen}
                        />
                      </PracticeAvatarContainer>
                    )}
                    <PracticeOverviewTextContainer>
                      <SubtitleText>{practice.name}</SubtitleText>
                      <OverviewParagraphText ismobilescreen={ismobilescreen}>
                        Est. Valuation: {ismobilescreen && <br></br>}{' '}
                        {valuationText}
                      </OverviewParagraphText>
                      <OverviewParagraphText ismobilescreen={ismobilescreen}>
                        {completionPercentage !== 100 ? (
                          <>
                            Transaction Fee: {commissionRate}%<br></br>
                            <SaveMoneyText
                              onClick={() =>
                                setSavingsSpecificCommisionModalOpen(true)
                              }
                            >
                              I want to save{' '}
                              {getFormattedMoneyString(
                                estimatedCommissionSavings,
                                0,
                              )}
                            </SaveMoneyText>
                          </>
                        ) : (
                          <>
                            Transaction Fee: {commissionRate}% (pending
                            validation of due diligence)<br></br>
                            <CongratsText>
                              Congrats! You're getting the lowest rate in the
                              industry.
                            </CongratsText>
                          </>
                        )}
                      </OverviewParagraphText>
                    </PracticeOverviewTextContainer>
                  </PracticeAvatarAndTextContainer>
                </PracticeOverviewContainer>
                <EditBlockContainer>
                  <EditBlock>
                    <EditBlockTitleTextContainer>
                      <EditBlockTitleText>Listing Type</EditBlockTitleText>
                    </EditBlockTitleTextContainer>
                    <DropdownForm>
                      <CreatePracticeDropdownLabel
                        ismobilescreen={ismobilescreen}
                      >
                        Select your listing type
                      </CreatePracticeDropdownLabel>
                      <Dropdown
                        value={listingType}
                        ismobilescreen={ismobilescreen}
                        onChange={(e) => setListingType(e.target.value)}
                        label={'Select your listing type'}
                        renderValue={(value) => (
                          <ListingTypeTitleText>
                            {saleStageLabelsForSellers[listingType]['label']}
                          </ListingTypeTitleText>
                        )}
                      >
                        {listingTypeOptions.map(
                          ({ stageKey, label, subtext, saleStageDisabled }) => {
                            return (
                              <StyledMenuItem
                                value={stageKey}
                                key={stageKey}
                                disabled={saleStageDisabled}
                              >
                                <ListingTypeContainer>
                                  <ListingTypeTitleText>
                                    {label}
                                  </ListingTypeTitleText>
                                  <ListingTypeSubtitleText>
                                    {subtext}
                                  </ListingTypeSubtitleText>
                                </ListingTypeContainer>
                              </StyledMenuItem>
                            )
                          },
                        )}
                      </Dropdown>
                    </DropdownForm>
                  </EditBlock>
                  <EditBlock>
                    <EditBlockTitleTextContainer>
                      <EditBlockTitleText>
                        Review Practice Information
                      </EditBlockTitleText>
                    </EditBlockTitleTextContainer>
                    <InfoSectionsContainer>
                      {infoSections.map((section, idx) => {
                        const number = idx + 1
                        const {
                          completed,
                          title,
                          label,
                          isOpenState,
                          isOpenStateCallback,
                        } = section
                        const completionText = completed
                          ? 'Complete'
                          : 'To complete'
                        return (
                          <InfoSectionContainer>
                            <InfoSectionButton
                              onClick={() => {
                                isOpenStateCallback(!isOpenState)
                                setElementIdToScrollTo(title)
                              }}
                              id={title}
                            >
                              <LeftSectionContentContainer>
                                <CircleWithNumber number={number} />
                                {label}
                              </LeftSectionContentContainer>
                              <RightSectionContentContainer>
                                <SectionCompletionStatusText
                                  completed={completed}
                                >
                                  {completionText}
                                </SectionCompletionStatusText>
                                <ArrowIcon open={isOpenState} />
                              </RightSectionContentContainer>
                            </InfoSectionButton>
                            {isOpenState && expandedFieldSections[title]}
                          </InfoSectionContainer>
                        )
                      })}
                    </InfoSectionsContainer>
                  </EditBlock>
                </EditBlockContainer>
                <CreatePracticeFlowNavigation
                  currentStep={null}
                  practiceId={practiceId}
                  nextEnabled={!!listingType && !loading}
                  onClickBack={
                    searchParams?.get('plan')?.toLowerCase() ===
                      ConnectServicePlans.free?.toLowerCase() && handleBack
                  }
                  onClickNext={onClickNext}
                  isPublishingScreen
                  listingStatus={practice?.listing?.status}
                  currentFlow={currentFlow}
                />
              </StyledCreatePracticeFormContainer>
            </FullPageContentContainerWithGap>
          </PageContainer>
        </VUNSBC>
        <SavingsSpecificCommissionModal
          isOpen={savingsSpecificCommisionModalOpen}
          onClose={() => setSavingsSpecificCommisionModalOpen(false)}
          estimatedSavings={estimatedCommissionSavings}
          incompleteSections={incompleteSections}
          practiceId={practiceId}
          ismobilescreen={ismobilescreen}
          currentFlow={publishingFlowKey}
        />
      </>
    )
  )
}

export default Publish
