import { useQueryWithErrorLogging } from '../../utils/hooks'
import get from 'lodash/get'
import { GET_MY_USER } from '../queries/users'
import { useAuth0 } from '@auth0/auth0-react'
import Bugsnag from '@bugsnag/js'

export const useMyUser = () => {
  const auth0Context = useAuth0()

  const { data, error, loading, refetch } = useQueryWithErrorLogging(
    GET_MY_USER,
    {
      fetchPolicy: 'cache-first',
      skip: !auth0Context.isAuthenticated,
    },
  )

  const user = get(data, 'getMyUser', null)

  // this seems like a good place to set the user for bugsnag
  // it's a little overkill because it'll get set a lot,
  // but this way we definitely don't miss anything
  if (user) {
    if (Bugsnag.isStarted()) {
      Bugsnag.setUser(user.id, user.email, `${user.firstName} ${user.lastName}`)
    }
    if (process.env.REACT_APP_HOTJAR_APP_ID) {
      window.hj('identify', user.id, {
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
      })
    }
  }

  return {
    error,
    loading,
    refetch,
    user,
    auth0Context,
  }
}
