import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import MuiMapIcon from '@mui/icons-material/Map'
import styled from 'styled-components/macro'
import {
  MediumIconStyle,
  PrimaryButton,
} from '../../../styles/shared-styled-components'

const chipWidth = 130

export const ToggleListingChip = styled(PrimaryButton).attrs({
  disableRipple: false,
})`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 15px;
  background-color: ${({ theme }) => theme.color.black};
  width: ${chipWidth}px;
  display: flex;
  justify-content: space-evenly;
  padding: 7px;
  border-radius: ${({ theme }) => `${theme.spacing.largeBorderRadius}px`};
  &:hover {
    background-color: ${({ theme }) => theme.color.blackHovered};
  }
`

export const MapIcon = styled(MuiMapIcon)`
  ${MediumIconStyle}
`

export const ListIcon = styled(FormatListBulletedIcon)`
  ${MediumIconStyle}
`
