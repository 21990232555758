import gql from 'graphql-tag'

export const GET_PRACTICE_VALUATIONS = gql`
  query getPracticeValuations($practiceId: String!) {
    getPracticeValuations(practiceId: $practiceId) {
      id
      lowerBound
      upperBound
      createdAt
      updatedAt
    }
  }
`

export const GET_HEURISTICS = gql`
  query getHeuristics($environment: Environment!) {
    getHeuristics(environment: $environment) {
      inputName
      increment
      lessThanDefault {
        verticalTableRange {
          min
          max
        }
        deltas {
          minMultiple
          maxMultiple
          minEbitda
          maxEbitda
        }
      }
      default {
        verticalTableRange {
          min
          max
        }
        deltas {
          minMultiple
          maxMultiple
          minEbitda
          maxEbitda
        }
      }
      greaterThanDefault {
        verticalTableRange {
          min
          max
        }
        deltas {
          minMultiple
          maxMultiple
          minEbitda
          maxEbitda
        }
      }
    }
  }
`

export const GET_GLOBAL_INPUTS = gql`
  query getGlobalInputs($environment: Environment!) {
    getGlobalInputs(environment: $environment) {
      ebitda {
        min
        max
      }
      multiple {
        min
        max
      }
      multipleBound {
        min
        max
      }
      hygieneWeeklyHours
      hygieneRates {
        min
        max
      }
      adminWeeklyHours
      adminRates {
        min
        max
      }
    }
  }
`

export const GET_VALUATION_FOR_INPUTS = gql`
  query getTestValuationFromInputs($input: TestValuationInput!) {
    getTestValuationFromInputs(input: $input) {
      productionValuation {
        lowerBound
        upperBound
      }
      stagingValuation {
        lowerBound
        upperBound
      }
    }
  }
`
