import { useMutationWithErrorLogging } from '../../utils/hooks'
import React, { useState } from 'react'
import Paths from '../../Paths'
import { DELETE_PRACTICE } from '../../api/mutations/practices'
import {
  ErrorParagraphText,
  StyledMenuItem,
  SubtitleText,
  OverviewParagraphText,
  SaveMoneyText,
  GreyLargeInputText,
} from '../../styles/shared-styled-components'
import {
  getLabelFromKey,
  getPracticeListingStatus,
  practiceTypes,
  underReviewStatusKey,
  unpostedListingTypes,
  readyToListStatusKey,
  incompleteStatusKey,
  whiteGloveServiceRate,
  discountedCommissionRate,
  picturesSectionKey,
} from '../../utils/constants'
import {
  getEligiblePracticeListingStatuses,
  checkIfPracticeIncludesFinancials,
  checkIfPracticeIncludesPictures,
} from '../../utils/data'
import { dateToSentenceFormat, getDaysRemainingToSave } from '../../utils/dates'
import {
  defaultPracticeName,
  getEllipsedPracticeName,
} from '../../utils/naming'
import {
  getFormattedMoneyStringRange,
  getAbbreviatedMoneyStringRange,
  getEstimatedCommissionDiscountSavings,
  getFormattedMoneyString,
  getPracticeCompletionPercentage,
  getDueDiligenceCompletionPercentage,
} from '../../utils/numbers'
import { navigateToDueDiligenceSectionToEdit } from '../../utils/routing'
import SavingsSpecificCommissionModal from '../CreatePractice/CreatePracticeModals/SavingsSpecificCommissionModal'
import DeletePracticeModal from '../DeletePracticeModal/DeletePracticeModal'
import CompleteValuationButton from '../Micro/CompleteValuationButton'
import CompleteListingButton from '../Micro/CompleteListingButton'
import PracticeListingStatusChip from '../Micro/PracticeListingStatusChip/PracticeListingStatusChip'
import ThreeDotsIcon from '../Micro/ThreeDotsIcon'
import ViewOffersButton from '../Micro/ViewOffersButton'
import ViewPracticeButton from '../Micro/ViewPracticeButton'
import PopperMenu from '../PopperMenu'
import {
  ActionButtonContainer,
  MultipleButtonContainer,
  PracticeAvatar,
  PracticeAvatarContainer,
  PracticeBottomContentContainer,
  PracticeCard,
  PracticeContentContainer,
  PracticeHeaderContainer,
  PracticeHeaderText,
  PracticeNameAndBadgeContainer,
  PracticeSubText,
  PracticeTopSection,
} from './styled'
import { useNavigate } from 'react-router-dom'

const MyPracticeCard = ({
  practice,
  width,
  onDelete,
  inValuationMode = false,
  ismobilescreen,
}) => {
  const navigate = useNavigate()

  const [deletePracticeMutation] = useMutationWithErrorLogging(DELETE_PRACTICE)

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [moreMenuAnchorElement, setMoreMenuAnchorElement] = useState(null)
  const moreMenuOpen = Boolean(moreMenuAnchorElement)

  const completionPercentage = getPracticeCompletionPercentage(practice)

  const lowerBound = practice?.mostRecentValuation?.lowerBound
  const upperBound = practice?.mostRecentValuation?.upperBound

  const valuationText = getAbbreviatedMoneyStringRange(lowerBound, upperBound)

  let incompleteSections = []
  if (!checkIfPracticeIncludesFinancials(practice)) {
    incompleteSections.push('financials')
  }

  const [
    savingsSpecificCommisionModalOpen,
    setSavingsSpecificCommisionModalOpen,
  ] = useState(false)

  const commissionRate =
    completionPercentage === 100
      ? discountedCommissionRate
      : whiteGloveServiceRate
  const estimatedCommissionSavings = getEstimatedCommissionDiscountSavings(
    lowerBound,
    upperBound,
  )

  const wholeCardShouldFold = width < 664

  const name = practice.name || defaultPracticeName
  const formattedName = wholeCardShouldFold
    ? name
    : getEllipsedPracticeName(name, width)

  const eligibleListingStatuses = getEligiblePracticeListingStatuses(practice)
  const ableToBeListed = eligibleListingStatuses.length > 0
  const status = getPracticeListingStatus(practice, ableToBeListed)

  const displayingDefaultPicture = !practice?.pictures?.length
  const pictureUrlToDisplay = !displayingDefaultPicture
    ? practice.pictures[0]
    : Paths.dentalClinicIcon

  const onDeletePractice = () => {
    setIsDeleting(true)
    deletePracticeMutation({
      variables: {
        id: practice.id,
      },
      onCompleted: async () => {
        await onDelete()
        setDeleteModalOpen(false)
        setIsDeleting(false)
      },
    })
  }

  const cardButtons =
    status === incompleteStatusKey ? (
      <CompleteValuationButton
        practice={practice}
        buttonType='secondary'
        text='Resume'
        inValuationMode={inValuationMode}
      />
    ) : status === readyToListStatusKey ? (
      <MultipleButtonContainer>
        <CompleteListingButton
          practice={practice}
          buttonType='primary'
          text='List Practice'
          inValuationMode={inValuationMode}
        />
        <CompleteValuationButton
          practice={practice}
          buttonType='secondary'
          text='Update Valuation'
          inValuationMode={inValuationMode}
        />
      </MultipleButtonContainer>
    ) : status === underReviewStatusKey ? (
      <ViewPracticeButton
        practiceId={practice.id}
        buttonType='secondary'
        text='View'
      />
    ) : (
      <MultipleButtonContainer>
        <ViewOffersButton practice={practice} />
        <ViewPracticeButton
          practiceId={practice.id}
          buttonType='secondary'
          text='Edit'
        />
      </MultipleButtonContainer>
    )

  return (
    <>
      <PracticeCard wholeCardShouldFold={wholeCardShouldFold}>
        <PracticeTopSection>
          {wholeCardShouldFold && <ThreeDotsIcon useAsPlaceholder />}
          <PracticeAvatarContainer
            wholeCardShouldFold={wholeCardShouldFold}
            displayingDefaultPicture={displayingDefaultPicture}
          >
            <PracticeAvatar
              src={pictureUrlToDisplay}
              displayingDefaultPicture={displayingDefaultPicture}
            />
          </PracticeAvatarContainer>
          {wholeCardShouldFold && (
            <ThreeDotsIcon
              onClick={(e) => setMoreMenuAnchorElement(e.currentTarget)}
            />
          )}
        </PracticeTopSection>
        <PracticeContentContainer>
          <PracticeHeaderContainer>
            <PracticeNameAndBadgeContainer
              wholeCardShouldFold={wholeCardShouldFold}
            >
              <PracticeHeaderText>{formattedName}</PracticeHeaderText>
              {!inValuationMode && (
                <PracticeListingStatusChip status={status} />
              )}
            </PracticeNameAndBadgeContainer>
            {!wholeCardShouldFold && (
              <ThreeDotsIcon
                onClick={(e) => setMoreMenuAnchorElement(e.currentTarget)}
              />
            )}
          </PracticeHeaderContainer>
          <PracticeBottomContentContainer
            wholeCardShouldFold={wholeCardShouldFold}
          >
            <PracticeSubText wholeCardShouldFold={wholeCardShouldFold}>
              {practice?.mostRecentValuation ? (
                <GreyLargeInputText>
                  Est. Valuation: {valuationText}
                  {<br></br>}
                  {completionPercentage === 100
                    ? `Transaction Fee: ${commissionRate}% (pending validation of due diligence)`
                    : `Standard Transaction Fee: ${commissionRate}%`}
                  {<br></br>}
                  {completionPercentage === 100 ? (
                    <SaveMoneyText
                      onClick={() =>
                        navigateToDueDiligenceSectionToEdit(
                          picturesSectionKey,
                          practice?.id,
                          navigate,
                        )
                      }
                    >
                      {`You saved ${getFormattedMoneyString(estimatedCommissionSavings, 0)}! Click to update`}
                    </SaveMoneyText>
                  ) : (
                    <SaveMoneyText
                      onClick={() => setSavingsSpecificCommisionModalOpen(true)}
                    >
                      {`${getDaysRemainingToSave(
                        practice,
                        getFormattedMoneyString(estimatedCommissionSavings, 0),
                      )} days remaining to save ${getFormattedMoneyString(estimatedCommissionSavings, 0)} (${getDueDiligenceCompletionPercentage(practice)}% complete)`}
                    </SaveMoneyText>
                  )}
                </GreyLargeInputText>
              ) : (
                <GreyLargeInputText>
                  Created {dateToSentenceFormat(practice?.createdAt)}
                  {practice?.type && (
                    <>
                      <br></br>
                      {getLabelFromKey(practiceTypes, practice?.type)} Practice
                    </>
                  )}
                  {practice?.address && (
                    <>
                      <br></br>
                      {practice?.address}
                    </>
                  )}
                </GreyLargeInputText>
              )}
            </PracticeSubText>
            <ActionButtonContainer wholeCardShouldFold={wholeCardShouldFold}>
              {cardButtons}
            </ActionButtonContainer>
          </PracticeBottomContentContainer>
        </PracticeContentContainer>
      </PracticeCard>
      <PopperMenu
        open={moreMenuOpen}
        anchorElement={moreMenuAnchorElement}
        onClose={() => setMoreMenuAnchorElement(null)}
        variant='offset'
      >
        <StyledMenuItem
          onClick={() => {
            setMoreMenuAnchorElement(null)
            setDeleteModalOpen(true)
          }}
        >
          <ErrorParagraphText>Delete practice</ErrorParagraphText>
        </StyledMenuItem>
      </PopperMenu>
      <DeletePracticeModal
        practiceName={name}
        isOpen={deleteModalOpen}
        isDeleting={isDeleting}
        onClose={() => setDeleteModalOpen(false)}
        onDelete={onDeletePractice}
      />
      <SavingsSpecificCommissionModal
        isOpen={savingsSpecificCommisionModalOpen}
        onClose={() => setSavingsSpecificCommisionModalOpen(false)}
        estimatedSavings={estimatedCommissionSavings}
        incompleteSections={incompleteSections}
        practiceId={practice?.id}
        ismobilescreen={ismobilescreen}
      />
    </>
  )
}

export default MyPracticeCard
