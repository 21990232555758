import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { Box } from './styled'
import {
  FormTitle,
  SubtitleText,
} from '../../../pages/CreatePractice/DueDiligence/styled'
import CreatePracticeInput from '../CreatePracticeInput'
import { FlexDiv } from '../../../styles/shared-styled-components'
import OperatoryField from './OperatoryField'
import {
  booleanFields,
  operatoryPurposes,
  paymentTerminalVendorTypes,
} from '../../../utils/constants'
import { useParams } from 'react-router-dom'
import { usePracticeById } from '../../../api/hooks/practices'
import { useMemo } from 'react'
import PrivacyShield from '../../Micro/PrivacyShield/PrivacyShield'

const PracticeProfile = forwardRef(({ width, handleCanSkipChanged }, ref) => {
  const { practiceId } = useParams()
  const { practice } = usePracticeById(practiceId)
  const [submittedDataWithErrors, setSubmittedDataWithErrors] = useState(false)
  const [fieldErrors, setFieldErrors] = useState()
  const opsPurposeOptions = operatoryPurposes.map((purpose) => ({
    key: purpose,
    label: purpose,
  }))
  const [payload, setPayload] = useState(
    practice?.dueDiligence?.practiceProfile || {
      numOfOps: practice?.operatories ?? 1,
      opsPurposes: {},
    },
  )

  useEffect(() => {
    handleCanSkipChanged(false)
    const currentPath = window.location.pathname
    const queryParams = new URLSearchParams(window.location.search)
    queryParams.delete('navType')
    window.history.replaceState(
      {},
      '',
      `${currentPath}?${queryParams.toString()}`,
    )
  }, [])

  useEffect(() => {
    if (practice?.dueDiligence?.practiceProfile) {
      const {
        numOfOps,
        yearFounded,
        yearOpened,
        paymentTerminalVendor,
        opsPurposes,
      } = practice?.dueDiligence?.practiceProfile
      setPayload((old) => ({
        ...old,
        numOfOps,
        yearFounded,
        yearOpened,
        paymentTerminalVendor,
        opsPurposes,
      }))
    }
  }, [practice?.dueDiligence?.practiceProfile])

  const handleOnChange = (e, fieldType) => {
    const value =
      e.target.value === 'Select'
        ? e.target.value
        : fieldType === 'number'
          ? Number(e.target.value)
          : fieldType === 'boolean'
            ? e.target.value === booleanFields[0].label
            : e.target.value
    setPayload((old) => ({
      ...old,
      [e.target.name || e.target.id]: value,
    }))
  }

  const handleOperatoryChange = useCallback(
    (index) => (e) => {
      const { value } = e.target
      let selectedPurposes = payload.opsPurposes
        ? { ...payload.opsPurposes }
        : {}
      // selectedPurposes[index] = value
      selectedPurposes = Object.assign(selectedPurposes, { [index]: value })

      setPayload((old) => ({
        ...old,
        opsPurposes: selectedPurposes,
      }))
    },
    [payload],
  )

  const isFormValid = useCallback(() => {
    const updatedFieldErrors = {
      numOfOps: !payload.numOfOps && 'Please select an option',
      yearFounded:
        !payload?.yearFounded &&
        'Please provide the year the practice was founded',
      yearOpened:
        !payload?.yearOpened &&
        'Please provide the year the practice was opened',
      paymentTerminalVendor:
        !payload?.paymentTerminalVendor &&
        'Kindly specify the payment terminal vendor',
    }

    for (let i = 0; i < payload?.numOfOps; i++) {
      const purpose = payload?.opsPurposes[i]
      if (!purpose) {
        if (!updatedFieldErrors.opsPurposes) {
          updatedFieldErrors['opsPurposes'] = {}
        }
        updatedFieldErrors.opsPurposes[i] =
          'Please select a purpose for Operatory ' + (i + 1)
      }
    }

    setFieldErrors(updatedFieldErrors)

    const isValid = Object.values(updatedFieldErrors).every(
      (error) => error === false,
    )
    if (!isValid) {
      alert('Some required fields are empty')
    }

    return isValid
  }, [payload])

  const handleFormSubmit = useCallback(
    (callback, config) => {
      if (config?.override && callback) {
        callback(payload)
      } else if (isFormValid()) {
        callback(payload)
      } else {
        setSubmittedDataWithErrors(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [payload],
  )

  useImperativeHandle(ref, () => ({
    submit: handleFormSubmit,
  }))

  return (
    <Box>
      <FormTitle>Practice profile</FormTitle>
      <SubtitleText>Please enter your details</SubtitleText>
      <FlexDiv style={{ flex: 1 }}>
        <CreatePracticeInput
          value={payload?.numOfOps}
          label='Total number of operatories *'
          width={width}
          onChange={(e) => handleOnChange(e, 'number')}
          fieldType='number'
          elementId='numOfOps'
          submittedDataWithErrors={submittedDataWithErrors}
          errorMessage={fieldErrors?.numOfOps}
        />
      </FlexDiv>
      <Box className='mt-2'>
        <SubtitleText>Purpose of each operatory *</SubtitleText>
        <Box style={{ maxWidth: '97%' }}>
          {Array.from({ length: payload?.numOfOps || 0 }, (_, index) => (
            <OperatoryField
              key={index}
              value={payload?.opsPurposes[index] ?? ''}
              index={index}
              onChange={handleOperatoryChange(index)}
              options={opsPurposeOptions}
              errorMessage={
                fieldErrors?.opsPurposes
                  ? fieldErrors?.opsPurposes[index]
                  : null
              }
              submittedDataWithErrors={submittedDataWithErrors}
            />
          ))}
        </Box>
      </Box>
      <Box className='mt-2'>
        <CreatePracticeInput
          value={payload?.yearFounded}
          label='Year practice founded including predecessors *'
          width={width}
          onChange={handleOnChange}
          fieldType='text'
          elementId='yearFounded'
          submittedDataWithErrors={submittedDataWithErrors}
          errorMessage={fieldErrors?.yearFounded}
          placeholder='Enter year ex. 2024'
        />
      </Box>
      <Box className='mt-2'>
        <CreatePracticeInput
          value={payload?.yearOpened}
          label='Year current practice opened *'
          width={width}
          onChange={handleOnChange}
          fieldType='text'
          elementId='yearOpened'
          submittedDataWithErrors={submittedDataWithErrors}
          errorMessage={fieldErrors?.yearOpened}
          placeholder='Enter year ex. 2024'
        />
      </Box>
      <Box className='mt-2'>
        <CreatePracticeInput
          value={payload?.paymentTerminalVendor}
          label='Payment terminal vendor *'
          width={width}
          onChange={handleOnChange}
          fieldType='dropdown'
          elementId='paymentTerminalVendor'
          submittedDataWithErrors={submittedDataWithErrors}
          errorMessage={fieldErrors?.paymentTerminalVendor}
          options={paymentTerminalVendorTypes.map((type) => ({
            key: type,
            label: type,
          }))}
        />
      </Box>
    </Box>
  )
})

export default PracticeProfile
