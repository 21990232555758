import React, { useEffect, useRef, useState } from 'react'
import Paths from '../../Paths'
import { useMyPractices } from '../../api/hooks/practices'
import LoadingIndicator from '../../components/LoadingIndicator'
import BrowseListingsButton from '../../components/Micro/BrowseListingsButton'
import CompleteValuationButton from '../../components/Micro/CompleteValuationButton'
import CompleteListingButton from '../../components/Micro/CompleteListingButton'
import CreatePracticeButton from '../../components/Micro/CreatePracticeButton'
import PublishPracticeListingButton from '../../components/Micro/PublishPracticeListingButton'
import ViewOffersButton from '../../components/Micro/ViewOffersButton'
import ViewPracticeButton from '../../components/Micro/ViewPracticeButton'
import MyPracticeCard from '../../components/MyPracticeCard'
import Header from '../../components/NavBar/Header'
import Snackbar from '../../components/Snackbar'
import {
  BackIcon,
  BackgroundContainer,
  FullPageContentContainer,
  FullPageContentContainerWithLargeGap,
  NextIcon,
  PageContainer,
} from '../../styles/shared-styled-components'
import {
  forSaleStageKey,
  getPracticeListingStatus,
  incompleteStatusKey,
  offMarketStageKey,
  readyToListStatusKey,
  underReviewStatusKey,
} from '../../utils/constants'
import { getEligiblePracticeListingStatuses } from '../../utils/data'
import { getPracticeCompletionPercentage } from '../../utils/numbers'
import {
  screenWidthIsSmallMobileSizeOrSmaller,
  useIsMobileScreen,
} from '../../utils/view'
import {
  BrowseListingsContainer,
  BrowseListingsSubtitleText,
  BrowseListingsTitleText,
  CardButtonContainer,
  CardNavigationButton,
  CardNavigationContainer,
  CardSubtitleText,
  CardTextContainer,
  CardTitleText,
  DentacloudNetworkContainer,
  EmptyPracticeContentContainer,
  EmptyPracticeHeaderImageWrapper,
  EmptyPracticeWrapper,
  EmptyPracticeHeaderContainer,
  HeaderContainer,
  HeaderContentContainer,
  HeaderImage,
  HeaderImageContainer,
  HeaderTitleText,
  ListingLiveIcon,
  MirrorIcon,
  MyPracticesTitleSection,
  MyPracticesTitleText,
  PencilIcon,
  PinIcon,
  PostListingIcon,
  PowerContainer,
  PowerDescriptionText,
  PowersContainer,
  PracticeContainer,
  PracticePageContainer,
  PracticesSection,
  SoldIcon,
  TopCard,
  TopCardContainer,
  TopCardSection,
  UnderReviewIcon,
  EmptyPracticeTitleText,
  MyPracticeBackgroundContainer,
} from './styled'
import { BlackTitleText, TitleText } from '../BuyerProfile/styled'
import { HeaderTitle } from '../../components/Micro/PurchaseIntentDialog/styled'
import SignNdaModal from '../../components/NDA'

const MyPractices = ({ user, width }) => {
  const { error, practices, loading, refetch } = useMyPractices()
  const ismobilescreen = useIsMobileScreen()

  const dentacloudNetworkContainerDivRef = useRef(null)
  const [
    remainingDentacloudNetworkContainerHeight,
    setRemainingDentacloudNetworkContainerHeight,
  ] = useState(0)
  const [cardNavigationIndex, setCardNavigationIndex] = useState(0)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarMessageError, setSnackbarMessageError] = useState('')

  if (error && !snackbarMessageError) {
    setSnackbarMessageError('Failed to load your deals. Try reloading the page')
  }
  // Setting the remaining height - need to watch the practices variable,
  // as the page rerenders after that
  useEffect(() => {
    const handleResize = () => {
      const windowHeight = window.innerHeight

      // Update dentacloud network container height
      const dentacloudNetworkContainerDivHeight =
        dentacloudNetworkContainerDivRef?.current?.getBoundingClientRect().top
      const dentacloudNetworkContainerHeightDifference =
        windowHeight - dentacloudNetworkContainerDivHeight
      setRemainingDentacloudNetworkContainerHeight(
        dentacloudNetworkContainerHeightDifference,
      )
    }

    window.addEventListener('resize', handleResize)
    // Call it initially to get the remaining height
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [practices])

  const onDeletePractice = () => {
    refetch()
    setSnackbarMessage('Successfully deleted practice')
  }

  let practiceToShowcase = null

  // Find first incomplete practice listing
  const incompletePracticeListing = practices?.find(
    (p) => p.completedAt == null,
  )

  if (incompletePracticeListing) {
    practiceToShowcase = incompletePracticeListing
  } else {
    // If we have none that are incomplete, the first one would be the most recently listed practice, so we can just grab that
    practiceToShowcase = practices[0]
  }

  const completionPercentage =
    getPracticeCompletionPercentage(practiceToShowcase)

  const eligibleListingStatuses =
    getEligiblePracticeListingStatuses(practiceToShowcase)
  const canListPracticeToShowcase = eligibleListingStatuses.length > 0

  const practiceToShowcaseListingStatus = getPracticeListingStatus(
    practiceToShowcase,
    canListPracticeToShowcase,
  )

  const cardContent =
    practiceToShowcaseListingStatus === incompleteStatusKey
      ? {
          icon: <PencilIcon />,
          title: `Your listing is ${completionPercentage}% complete`,
          subtitle: 'Complete your listing to engage with buyers.',
          button: <CompleteValuationButton practice={practiceToShowcase} />,
        }
      : practiceToShowcaseListingStatus === readyToListStatusKey
        ? eligibleListingStatuses.includes(forSaleStageKey)
          ? {
              icon: <PostListingIcon />,
              title: 'Your practice is now ready to be listed for sale!',
              subtitle: 'Post your listing to engage with buyers.',
              button: (
                <PublishPracticeListingButton
                  practiceId={practiceToShowcase?.id}
                />
              ),
            }
          : {
              icon: <PostListingIcon />,
              title: (
                <>
                  Your practice is now ready to be listed off-market! <br></br>(
                  {completionPercentage}% complete)
                </>
              ),
              subtitle: 'Post your listing to engage with buyers.',
              button: (
                <PublishPracticeListingButton
                  practiceId={practiceToShowcase?.id}
                />
              ),
            }
        : practiceToShowcaseListingStatus === underReviewStatusKey
          ? {
              icon: <UnderReviewIcon />,
              title:
                "Your listing is under review - we'll let you know when it's live",
              subtitle: 'The typical review process takes 24-48 hours.',
              button: (
                <ViewPracticeButton practiceId={practiceToShowcase?.id} />
              ),
            }
          : practiceToShowcaseListingStatus === offMarketStageKey
            ? {
                icon: <ListingLiveIcon />,
                title: 'Your practice is listed off-market.',
                subtitle: 'Engage with buyers to receive your target price',
                button: (
                  <ViewOffersButton
                    text='Engage with buyers'
                    buttonType='bright'
                  />
                ),
              }
            : practiceToShowcaseListingStatus === forSaleStageKey
              ? {
                  icon: <ListingLiveIcon />,
                  title: 'Your practice is listed for sale.',
                  subtitle: 'Engage with buyers to receive your target price',
                  button: (
                    <ViewOffersButton
                      text='Engage with buyers'
                      buttonType='bright'
                    />
                  ),
                }
              : {
                  icon: <SoldIcon />,
                  title: 'Your practice has been sold. Congrats!',
                  subtitle: 'Complete your listing to engage with buyers',
                  button: <ViewOffersButton buttonType='bright' />,
                }

  const networkSectionsWillWrap = width < 980
  const topCardsShouldFold = width < 621
  const topCards = [
    <TopCard isFolded={topCardsShouldFold}>
      <MirrorIcon />
      <CardTextContainer>
        <CardTitleText>See how you compare</CardTitleText>
        <CardSubtitleText>
          Check out the current listings on our marketplace.
        </CardSubtitleText>
      </CardTextContainer>
      <CardButtonContainer>
        <BrowseListingsButton />
      </CardButtonContainer>
    </TopCard>,
  ]

  const isSmallMobileScreen = screenWidthIsSmallMobileSizeOrSmaller(width)

  return practices != null && !loading ? (
    <>
      <Header user={user} width={width} ismobilescreen={ismobilescreen} />
      <MyPracticeBackgroundContainer>
        <PracticePageContainer>
          <SignNdaModal isOpen={user && !user?.signedNda} user={user} />
          {practices.length === 0 ? (
            <EmptyPracticeWrapper>
              <EmptyPracticeHeaderContainer>
                <HeaderContentContainer ismobilescreen={ismobilescreen}>
                  <HeaderTitleText ismobilescreen={ismobilescreen}>
                    Sell Your Practice with Confidence
                  </HeaderTitleText>
                  <CreatePracticeButton
                    user={user}
                    text='Value your practice'
                    allowShrinking={isSmallMobileScreen}
                  />
                </HeaderContentContainer>
                <EmptyPracticeHeaderImageWrapper>
                  <HeaderImage
                    src={Paths.handshakeCartoon}
                    ismobilescreen={ismobilescreen}
                    issmallmobilescreen={isSmallMobileScreen}
                  />
                </EmptyPracticeHeaderImageWrapper>
              </EmptyPracticeHeaderContainer>

              <EmptyPracticeContentContainer
                ismobilescreen={networkSectionsWillWrap}
                ref={dentacloudNetworkContainerDivRef}
                remainingHeight={remainingDentacloudNetworkContainerHeight}
              >
                <EmptyPracticeTitleText ismobilescreen={ismobilescreen}>
                  The Power of the Dentacloud Network
                </EmptyPracticeTitleText>

                <PowersContainer>
                  <PowerContainer>
                    <PinIcon />
                    <HeaderTitle>Pre-Screened</HeaderTitle>
                    <PowerDescriptionText ismobilescreen={ismobilescreen}>
                      Our practices are pre-screened to ensure that they're
                      ready to be sold.
                    </PowerDescriptionText>
                  </PowerContainer>
                  <PowerContainer>
                    <PinIcon />
                    <HeaderTitle>Prepared Buyers</HeaderTitle>
                    <PowerDescriptionText ismobilescreen={ismobilescreen}>
                      We focus on qualifying and having buyers of all types -
                      PEs, DSOs, and fellow dentists.
                    </PowerDescriptionText>
                  </PowerContainer>
                  <PowerContainer>
                    <PinIcon />
                    <HeaderTitle>Post-Sale Support</HeaderTitle>
                    <PowerDescriptionText ismobilescreen={ismobilescreen}>
                      After the sale, we support your post-sale transition for
                      one year to make the merger seamless.
                    </PowerDescriptionText>
                  </PowerContainer>
                </PowersContainer>
              </EmptyPracticeContentContainer>
              <BrowseListingsContainer ismobilescreen={networkSectionsWillWrap}>
                <BrowseListingsTitleText>
                  Want to see how you compare?
                </BrowseListingsTitleText>
                <BrowseListingsSubtitleText>
                  Check out the current listings on our marketplace.
                </BrowseListingsSubtitleText>
                <BrowseListingsButton />
              </BrowseListingsContainer>
            </EmptyPracticeWrapper>
          ) : (
            <FullPageContentContainerWithLargeGap>
              <TopCardSection cardsAreFolded={topCardsShouldFold}>
                {!topCardsShouldFold ? (
                  topCards.map((card) => card)
                ) : (
                  <>{topCards[cardNavigationIndex]}</>
                )}
              </TopCardSection>
              <PracticesSection>
                <MyPracticesTitleSection>
                  <MyPracticesTitleText>My Practices</MyPracticesTitleText>
                  <CreatePracticeButton
                    text={`Add new ${!ismobilescreen ? 'practice' : ''}`}
                    buttonType='secondary'
                    includeIcon
                    allowShrinking
                  />
                </MyPracticesTitleSection>
                <PracticeContainer>
                  {practices.map((p) => (
                    <MyPracticeCard
                      practice={p}
                      width={width}
                      onDelete={onDeletePractice}
                      ismobilescreen={ismobilescreen}
                    />
                  ))}
                </PracticeContainer>
              </PracticesSection>
            </FullPageContentContainerWithLargeGap>
          )}
        </PracticePageContainer>
      </MyPracticeBackgroundContainer>
      <Snackbar
        isOpen={!!snackbarMessage}
        onClose={() => setSnackbarMessage('')}
        message={snackbarMessage}
      />
      <Snackbar
        variant='error'
        isOpen={!!snackbarMessageError}
        onClose={() => setSnackbarMessageError('')}
        message={snackbarMessageError}
      ></Snackbar>
    </>
  ) : (
    <LoadingIndicator fullScreen />
  )
}

export default MyPractices
