import styled, { css } from 'styled-components/macro'
import { PrimaryModalActionButton } from '../../styles/shared-styled-components'

const ButtonMinWidthStyle = css`
  min-width: 120px;
`

export const CancelButton = styled(PrimaryModalActionButton)`
  background-color: ${({ theme }) => theme.color.grey};
  &:hover {
    background-color: ${({ theme }) => theme.color.grey};
  }
  ${ButtonMinWidthStyle}
`

export const DeleteButton = styled(PrimaryModalActionButton)`
  background-color: ${({ theme }) => theme.color.error};
  &:hover {
    background-color: ${({ theme }) => theme.color.error};
  }
  ${ButtonMinWidthStyle}
`
