import React from 'react'
import {
  PlaceholderIconDiv,
  StyledThreeDotsIcon,
  ThreeDotsIconContainer,
} from './styled'

// Adding a 'placeholder' version of this class in order to calibrate the parent flexbox,
// as this is implemented on one side of a 3-block row
const ThreeDotsIcon = ({ onClick, useAsPlaceholder = false }) => {
  return (
    <>
      <ThreeDotsIconContainer
        onClick={onClick}
        useAsPlaceholder={useAsPlaceholder}
      >
        {!useAsPlaceholder ? <StyledThreeDotsIcon /> : <PlaceholderIconDiv />}
      </ThreeDotsIconContainer>
    </>
  )
}

export default ThreeDotsIcon
