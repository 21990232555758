import React from 'react'
import {
  ColumnButtonContainer,
  Modal,
  ModalActions,
  ModalContainer,
  ModalContent,
  ModalContentText,
  ModalHeaderContainer,
  PrimaryModalActionButton,
  TitleText,
} from '../../../../styles/shared-styled-components'
import { DiscountIcon } from './styled'
import {
  whiteGloveServiceRate,
  discountedCommissionRate,
} from '../../../../utils/constants'

const GeneralCommissionModal = ({ isOpen, onClose }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContainer>
        <ModalHeaderContainer>
          <DiscountIcon />
          <TitleText>Our Rates</TitleText>
        </ModalHeaderContainer>
        <ModalContent>
          <ModalContentText>
            Your valuation is free
            <br></br>
            <br></br>
            If you decide you want to sell, you can provide all the information
            needed for a fixed rate of <b>{discountedCommissionRate}%</b>
            <br></br>
            <br></br>
            Want white glove service? A Dentacloud deal manager will do your due
            diligence for you for a fixed rate of{' '}
            <b>{whiteGloveServiceRate}%</b>
            <br></br>
            <br></br>
            You can list for free and you don't pay until we sell your practice
          </ModalContentText>
        </ModalContent>
        <ModalActions>
          <ColumnButtonContainer>
            <PrimaryModalActionButton onClick={onClose}>
              Got it
            </PrimaryModalActionButton>
          </ColumnButtonContainer>
        </ModalActions>
      </ModalContainer>
    </Modal>
  )
}

export default GeneralCommissionModal
