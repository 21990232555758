import styled, { css } from 'styled-components/macro'
import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from '../../../styles/shared-styled-components'

const CreatePracticeButtonStyle = css`
  min-width: ${(props) => !props.allowShrinking && '200px'};
`

export const PrimaryCreatePracticeButton = styled(PrimaryButton)`
  ${CreatePracticeButtonStyle}
`

export const SecondaryCreatePracticeButton = styled(SecondaryButton)`
  ${CreatePracticeButtonStyle}
`

export const TextCreatePracticeButton = styled(TextButton)`
  ${CreatePracticeButtonStyle}
  color: ${({ theme }) => theme.color.primary};
`
