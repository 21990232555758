import React from 'react'
import { getFileNameFromUrl } from '../../../../utils/input'
import { Image, RemoveImageIcon, ThumbnailContainer } from './styled'

const FileThumbnail = ({ filePath, onRemove }) => {
  const imageName = getFileNameFromUrl(filePath, 'file')
  return (
    <ThumbnailContainer>
      <Image src={filePath} alt={imageName} title={imageName} />
      <RemoveImageIcon onClick={() => onRemove(filePath)} />
    </ThumbnailContainer>
  )
}

export default FileThumbnail
