import { useMutationWithErrorLogging } from '../../../utils/hooks'
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Paths from '../../../Paths'
import { updateUserPracticesInCache } from '../../../utils/cache'
import { getCreatePracticeRouteFromId } from '../../../utils/routing'
import { CREATE_PRACTICE } from '../../../api/mutations/practices'
import {
  COMPLETE_ONBOARDING,
  EDIT_MAIN_GOALS_AND_NDA,
} from '../../../api/mutations/users'
import { GET_MY_USER } from '../../../api/queries/users'
import Header from '../../../components/NavBar/Header'
import OnboardingSteps from '../../../components/Onboarding/OnboardingSteps'
import {
  BackgroundContainer,
  ColumnButtonContainer,
  ErrorText,
  FlexDiv,
  FormControlLabel,
  PageContainer,
  StyledCheckbox,
} from '../../../styles/shared-styled-components'
import {
  buyGoalKey,
  localStorageKeys,
  practiceFinancingKey,
  sellGoalKey,
  understandMarketGoalKey,
  valueGoalKey,
} from '../../../utils/constants'
import { getMainGoalsFromUser } from '../../../utils/user'
import {
  Asset,
  AssetContainer,
  FormTitleText,
  OnboardingForm,
  OnboardingFormContainer,
  OnboardingFormGroup,
  OnboardingFormGroupContainer,
} from '../shared-onboarding-styles'
import {
  ErrorMessageContainer,
  NdaContainer,
  NdaLink,
  GoalsButtonContainer,
  BackButton,
  ContinueButton,
  GoalAndNdaFormContainer,
} from './styled'
import { getPostOnboardingRoute } from '../../../utils/routing'

const mainGoalsMapArray = [
  {
    key: sellGoalKey,
    label: 'Sell a clinic',
  },
  {
    key: buyGoalKey,
    label: 'Buy a clinic',
  },
  {
    key: valueGoalKey,
    label: 'Value my clinic',
  },
  {
    key: understandMarketGoalKey,
    label: 'Better understand the market',
  },
  {
    key: practiceFinancingKey,
    label: 'Get financing for my practice',
  },
  // {
  //   key: provideProductsOrServicesGoalKey,
  //   label: 'Become a service provider',
  // },
]

const Goals = ({ user, width, ismobilescreen, isIpadSizeOrSmaller }) => {
  const navigate = useNavigate()
  const [
    editMainGoalsAndNdaMutation,
    { data: editMainGoalsData, loading: goalMutationLoading },
  ] = useMutationWithErrorLogging(EDIT_MAIN_GOALS_AND_NDA)
  const [
    completeOnboardingMutation,
    { data: completeOnboardingData, loading: completeOnboardingLoading },
  ] = useMutationWithErrorLogging(COMPLETE_ONBOARDING)
  const [createPracticeMutation, { loading: createPracticeMutationLoading }] =
    useMutationWithErrorLogging(CREATE_PRACTICE)

  const mainGoalsFromUser = getMainGoalsFromUser(user)

  const [mainGoals, setMainGoals] = useState(mainGoalsFromUser)
  const [signedNda, setSignedNda] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  const continueEnabled = mainGoals?.length && signedNda

  const onChangeMainGoals = (checked, goal) => {
    let newMainGoals = [...mainGoals]

    if (checked) {
      newMainGoals.push(goal)
    } else {
      newMainGoals = newMainGoals.filter((checkedGoal) => checkedGoal !== goal)
    }

    setMainGoals(newMainGoals)
  }

  const onClickContinue = () => {
    if (!signedNda) {
      return
    }
    editMainGoalsAndNdaMutation({
      variables: {
        mainGoals,
      },
      // Without updating the cache here, for some reason the product or service goals
      // wasn't always appearing on the ProductOrServiceSubGoal page
      update: async (cache, { data }) => {
        const userDataFetch = cache.readQuery({
          query: GET_MY_USER,
        })
        const goals = data?.editMainGoalsAndNda?.goals || []

        // If we have cached user data, update the goals
        if (userDataFetch) {
          // Grab the data in the cache and append the new goals
          const existingUser = userDataFetch.getMyUser
          const updatedUser = {
            ...existingUser,
            goals,
            signedNda: true,
          }

          // Update the cache
          await cache.writeQuery({
            query: GET_MY_USER,
            data: {
              getMyUser: updatedUser,
            },
          })
        }
      },
      onCompleted: (data) => {
        const goals = data?.editMainGoalsAndNda?.goals || []

        if (goals.length) {
          // // If they've stated that they want to provide products or services, we must route them to the
          // // products/services subgoal screen, else we can navigate them to the licensing step
          // const productOrServicesGoal = getProductOrServiceGoal(goals);
          // if (productOrServicesGoal) {
          //   navigate(Paths.productOrServiceSubGoalOnboarding);
          // } else {
          //   navigate(Paths.isLicensedToPracticeOnboarding);
          // }
          completeOnboardingMutation({
            update: async (cache, { data }) => {
              const userDataFetch = cache.readQuery({
                query: GET_MY_USER,
              })

              // Grab the returned hasOnboarded value
              const hasOnboardedResult = data?.completeOnboarding?.hasOnboarded

              // If we have cached user data, update the hasOnboarded field
              if (userDataFetch && hasOnboardedResult) {
                // Grab the data in the cache and update hasOnboarded
                const existingUser = userDataFetch.getMyUser
                const updatedUser = {
                  ...existingUser,
                  hasOnboarded: hasOnboardedResult,
                }

                // Update the cache
                cache.writeQuery({
                  query: GET_MY_USER,
                  data: {
                    getMyUser: updatedUser,
                  },
                })

                // this is the previous page the user was on before coming to the onboarding flow
                const previousPage = localStorage.getItem(
                  localStorageKeys.onboardingRedirectPath,
                )
                // if the item is set, we want to navigate back to it
                if (previousPage) {
                  localStorage.removeItem(
                    localStorageKeys.onboardingRedirectPath,
                  )
                  navigate(previousPage)
                  return
                } else {
                  // otherwise, navigate to the page based on the user's goals
                  const pathToChoose = getPostOnboardingRoute(goals)

                  // if valuing/selling practice, then start valuation automatically
                  if (pathToChoose === Paths.myPractices) {
                    createPracticeMutation({
                      onCompleted: async (data) => {
                        const createdPractice = data.createPractice

                        await updateUserPracticesInCache(createdPractice, cache)

                        navigate(
                          getCreatePracticeRouteFromId(
                            Paths.createPracticeStart,
                            createdPractice.id,
                            false,
                          ),
                        )
                      },
                    })
                  } else {
                    navigate(pathToChoose)
                  }
                  return
                }
              }

              if (!hasOnboardedResult) {
                setErrorMessage('Unable to save. Please refresh and try again.')
              }
            },
          })
        } else {
          setErrorMessage('Unable to save. Please refresh and try again.')
        }
      },
    })
  }

  const onBackPressed = useCallback(() => {
    if (
      createPracticeMutationLoading ||
      goalMutationLoading ||
      completeOnboardingLoading
    )
      return
    navigate(Paths.phoneOnboarding)
  }, [
    createPracticeMutationLoading,
    goalMutationLoading,
    completeOnboardingLoading,
    navigate,
  ])

  return (
    <>
      <Header
        user={user}
        width={width}
        ismobilescreen={isIpadSizeOrSmaller}
        isOnboardingScreen
      />
      <BackgroundContainer>
        <PageContainer>
          <FlexDiv>
            <GoalAndNdaFormContainer ismobilescreen={isIpadSizeOrSmaller}>
              <OnboardingSteps currentStep={3} />
              <OnboardingForm ismobilescreen={ismobilescreen}>
                <FormTitleText ismobilescreen={ismobilescreen}>
                  What service(s) can we help you with?
                </FormTitleText>
                <OnboardingFormGroupContainer>
                  {mainGoalsMapArray.map((mainGoalMap) => {
                    const key = mainGoalMap['key']
                    const label = mainGoalMap['label']
                    return (
                      <OnboardingFormGroup key={key}>
                        <FormControlLabel
                          control={
                            <StyledCheckbox
                              checked={mainGoals.includes(key)}
                              onClick={(e) =>
                                onChangeMainGoals(e.target.checked, key)
                              }
                            />
                          }
                          label={label}
                        />
                      </OnboardingFormGroup>
                    )
                  })}
                  <NdaContainer
                    control={
                      <StyledCheckbox
                        checked={signedNda}
                        onClick={(e) => setSignedNda(e.target.checked)}
                      />
                    }
                    label={
                      <span>
                        By checking this box, you agree to{' '}
                        <NdaLink
                          href={`${process.env.PUBLIC_URL}/dentacloud_nda_2024.pdf`}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Dentacloud's non-disclosure agreement
                        </NdaLink>
                        .
                      </span>
                    }
                  />
                  {errorMessage && (
                    <ErrorMessageContainer>
                      <ErrorText>{errorMessage}</ErrorText>
                    </ErrorMessageContainer>
                  )}
                </OnboardingFormGroupContainer>
              </OnboardingForm>
              <GoalsButtonContainer ismobilescreen={ismobilescreen}>
                <BackButton
                  ismobilescreen={ismobilescreen}
                  disabled={
                    createPracticeMutationLoading ||
                    goalMutationLoading ||
                    completeOnboardingLoading
                  }
                  onClick={onBackPressed}
                >
                  Back
                </BackButton>
                <ContinueButton
                  ismobilescreen={ismobilescreen}
                  disabled={
                    !continueEnabled ||
                    createPracticeMutationLoading ||
                    goalMutationLoading ||
                    completeOnboardingLoading
                  }
                  onClick={onClickContinue}
                >
                  {createPracticeMutationLoading ||
                  goalMutationLoading ||
                  completeOnboardingLoading
                    ? 'Please wait...'
                    : 'Save and continue'}
                </ContinueButton>
              </GoalsButtonContainer>
            </GoalAndNdaFormContainer>
            {!isIpadSizeOrSmaller && (
              <AssetContainer>
                <Asset src={Paths.dentacloudGoalsPage} />
              </AssetContainer>
            )}
          </FlexDiv>
        </PageContainer>
      </BackgroundContainer>
    </>
  )
}

export default Goals
