import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import React from 'react'

const LoadingIndicator = ({ fullScreen = false, size = 60 }) => {
  return (
    <Stack
      alignItems='center'
      justifyContent='center'
      height={fullScreen && '100vh'}
      width={fullScreen && '100%'}
      spacing={2}
    >
      <CircularProgress size={size} />
    </Stack>
  )
}

export default LoadingIndicator
