import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import typesenseClient from '../../api/typesense/typesense-client'
import LoadingIndicator from '../../components/LoadingIndicator'
import Map from '../../components/Map/Map'
import NoDataView from '../../components/Map/NoDataView/NoDataView'
import Header from '../../components/NavBar/Header'
import WelcomeModal from '../../components/Onboarding/WelcomeModal'
import SignNdaModal from '../../components/NDA'
import SoldSearchFilters from '../../components/Search/SearchFilters/SoldSearchFilters'
import {
  BackgroundContainer,
  FlexDiv,
  FullPageContentContainer,
  LandingPageSubtitle,
  LandingPageTitle,
  PageContainer,
  PrimaryText,
  SubtitleText,
  TitleText,
} from '../../styles/shared-styled-components'
import {
  allSaleStages,
  forSaleStageKey,
  offMarketStageKey,
  postedStatusKey,
  soldStageKey,
} from '../../utils/constants'
import {
  emptyFilterSearchParamValue,
  filterValues,
  getInitialListingTypeFilter,
  getInitialOperatoriesFilter,
  getInitialRevenueFilter,
  getInitialSalePriceFilter,
  getInitialYearSoldFilter,
  getInitalPracticeTypeFitler,
} from '../../utils/filters'
import {
  addMapSearchToSearchParams,
  updateSearchParams,
} from '../../utils/routing'
import { useIsMobileScreen } from '../../utils/view'
import Paths from '../../Paths'

const mapListingView = 'map'
const listListingView = 'list'
const defaultListingView = mapListingView

const getInitialSearchFilter = (searchParams) => {
  const initialOperatoriesFilter = getInitialOperatoriesFilter(searchParams)
  const initialRevenueFilter = getInitialRevenueFilter(searchParams)
  const initialSalePriceFilter = getInitialSalePriceFilter(searchParams)
  const initialYearSoldFilter = getInitialYearSoldFilter(searchParams)
  const initialListingTypeFilter = getInitialListingTypeFilter(searchParams)
  const InitalPracticeTypeFitler = getInitalPracticeTypeFitler(searchParams)

  return {
    operatories: initialOperatoriesFilter,
    revenue: initialRevenueFilter,
    price: initialSalePriceFilter,
    year: initialYearSoldFilter,
    listingType: initialListingTypeFilter,
    type: InitalPracticeTypeFitler,
  }
}

const Practices = ({ user, width }) => {
  const navigate = useNavigate()
  const ismobilescreen = useIsMobileScreen()
  const [searchParams, setSearchParams] = useSearchParams()

  const [listingView, setListingView] = useState(
    searchParams.get('view') || defaultListingView,
  )
  const [listingTypeFilter, setListingTypeFilter] = useState(
    searchParams.get('listingType') || filterValues.defaultListingTypeFilter,
  )

  const [practices, setPractices] = useState([])
  const [hasFetched, setHasFetched] = useState(false)

  const initialSearchFilter = getInitialSearchFilter(searchParams)
  const [searchFilter, setSearchFilter] = useState(initialSearchFilter)

  const [shouldResetFilters, setShouldResetFilters] = useState(false)

  const onClickListingView = () => {
    const newListingView =
      listingView === listListingView ? mapListingView : listListingView
    updateSearchParams(
      {
        view: newListingView,
      },
      searchParams,
      setSearchParams,
    )
  }

  // Listing type
  const onChangeListingTypeFilter = (key) => {
    let newListingTypeFilter = [...listingTypeFilter]
    const currentlySelected = newListingTypeFilter?.includes(key)

    if (!currentlySelected) {
      newListingTypeFilter.push(key)
    } else {
      newListingTypeFilter = newListingTypeFilter.filter(
        (stage) => stage !== key,
      )
    }

    // We don't let the user choose no filters, because
    // if they don't realize they've done so, they may think
    // that there are no practices on Connect or that the map is broken.
    const anyFiltersSelected = allSaleStages.some((filter) =>
      newListingTypeFilter.includes(filter),
    )
    if (!anyFiltersSelected) {
      return
    }

    setListingTypeFilter(newListingTypeFilter)

    updateSearchParams(
      {
        listingType: newListingTypeFilter.length
          ? newListingTypeFilter
          : emptyFilterSearchParamValue,
      },
      searchParams,
      setSearchParams,
    )
  }

  useEffect(() => {
    async function fetchPractices(
      opsFilter,
      revenueFilter,
      priceFilter,
      yearFilter,
      practiceFilter,
      listingTypeFilter,
    ) {
      let pageNumber = 1
      let totalNumResults
      let allDataFetched = false
      const searchResults = []

      let searchParameters = {
        q: '*',
        per_page: 250,
      }

      // Ops
      let filterString = `operatories:>=${parseInt(
        opsFilter[0],
      )} && operatories:<=${parseInt(opsFilter[1])}`

      // Billings
      filterString += ` && averageAnnualBillings:>=${parseInt(
        revenueFilter[0],
      )} && averageAnnualBillings:<=${parseInt(revenueFilter[1])}`

      // Price
      filterString += ` && targetPrice:>=${parseInt(
        priceFilter[0],
      )} && targetPrice:<=${parseInt(priceFilter[1])}`

      // Year
      // TODO(aaron): It's actually kind of difficult to write a filter query in typesense that
      // gives us practices matching the filter (sold for a particular price / sold in a particular year)
      // and also the practices that haven't been sold.
      // So for now, we're going to exclude sold stage filters when for-sale or off-market stage filters
      // are active.
      if (
        listingTypeFilter?.includes(soldStageKey) &&
        !(
          listingTypeFilter?.includes(forSaleStageKey) ||
          listingTypeFilter?.includes(offMarketStageKey)
        )
      ) {
        filterString += ` && yearSold:=[${yearFilter}]`
      }

      filterString += ` && type:=[${practiceFilter}]`

      // Listing type
      filterString += ` && listingType:=[${listingTypeFilter}]`

      searchParameters = {
        ...searchParameters,
        filter_by: filterString,
      }

      while (!allDataFetched) {
        searchParameters = {
          ...searchParameters,
          page: pageNumber,
        }
        try {
          const data = await typesenseClient
            .collections('practices')
            .documents()
            .search(searchParameters)
          // const data = await typesenseClient
          // .collections('practices')
          // .documents()
          // .search({ q: '*' });

          totalNumResults = data.found
          const documents = data.hits.map((hit) => hit.document)
          searchResults.push(...documents)

          if (searchResults.length >= totalNumResults) {
            allDataFetched = true
          }

          pageNumber += 1
        } catch (error) {
          console.log('typesenseClient err =>', error.message)
          allDataFetched = true
        }
      }

      // here we should exclude any off-market listings that haven't been posted yet
      const filteredByStatus = searchResults.filter((practice) => {
        if (practice.listingType === offMarketStageKey) {
          return practice.listingStatus === postedStatusKey
        } else {
          return true
        }
      })
      setPractices(filteredByStatus)
      setSearchFilter({
        operatories: opsFilter,
        revenue: revenueFilter,
        price: priceFilter,
        listingType: listingTypeFilter,
        type: practiceFilter,
      })

      if (!hasFetched) {
        setHasFetched(true)
      }
    }

    const listingViewInSearchParams = searchParams.get('view')
    if (
      listingViewInSearchParams &&
      listingViewInSearchParams !== listingView
    ) {
      setListingView(listingViewInSearchParams)
    }

    let opsFilter
    let revenueFilter
    let priceFilter
    let yearFilter
    let listingTypeFilter
    let practiceFilter
    let shouldRefetch

    if (!hasFetched) {
      opsFilter = initialSearchFilter.operatories
      revenueFilter = initialSearchFilter.revenue
      priceFilter = initialSearchFilter.price
      yearFilter = initialSearchFilter.year
      practiceFilter = initialSearchFilter.type
      listingTypeFilter = initialSearchFilter.listingType

      shouldRefetch = true
    } else {
      opsFilter = searchParams.get('operatories')
        ? searchParams.get('operatories').split(',')
        : null
      revenueFilter = searchParams.get('revenue')
        ? searchParams.get('revenue').split(',')
        : null
      priceFilter = searchParams.get('price')
        ? searchParams.get('price').split(',')
        : null
      yearFilter = searchParams.get('year')
        ? searchParams.get('year') === emptyFilterSearchParamValue
          ? []
          : searchParams.get('year')
        : null
      listingTypeFilter = searchParams.get('listingType')
        ? searchParams.get('listingType') === emptyFilterSearchParamValue
          ? [allSaleStages]
          : searchParams.get('listingType')
        : null
      practiceFilter = searchParams.get('type')
        ? searchParams.get('type') === emptyFilterSearchParamValue
          ? []
          : searchParams.get('type')
        : null

      const searchFiltersHaveChanged =
        opsFilter !== searchFilter.operatories ||
        revenueFilter !== searchFilter.revenue ||
        priceFilter !== searchFilter.price ||
        practiceFilter !== searchFilter.type ||
        listingTypeFilter !== searchFilter.listingType

      shouldRefetch = searchFiltersHaveChanged
    }
    if (_.isEmpty(initialSearchFilter.listingType) && hasFetched) {
      shouldRefetch = false
      setPractices([])
    }
    if (shouldRefetch) {
      fetchPractices(
        opsFilter || initialSearchFilter.operatories,
        revenueFilter || initialSearchFilter.revenue,
        priceFilter || initialSearchFilter.price,
        yearFilter || initialSearchFilter.year,
        practiceFilter || initialSearchFilter.type,
        listingTypeFilter || initialSearchFilter.listingType,
      )
    }
  }, [searchParams, hasFetched])

  if (!hasFetched) {
    return <LoadingIndicator fullScreen />
  } else {
    return (
      <>
        <Header user={user} width={width} ismobilescreen={ismobilescreen} />
        <BackgroundContainer hideVerticalScroll>
          <PageContainer
            style={{
              height: '100%',
            }}
          >
            <SignNdaModal isOpen={user && !user?.signedNda} user={user} />
            <FlexDiv
              style={{
                flex: 1,
                width: '100%',
                height: '100%',
                flexDirection: 'column',
                backgroundImage: `url(https://uploads-ssl.webflow.com/643385883030fde6d1dce3be/6447bf15ae3ce32f222cb572_633dc4dcb692e41b414485db_home-bg-pattern.svg)`,
              }}
            >
              <div
                style={{
                  paddingTop: '15px',
                  paddingLeft: '15px',
                  paddingRight: '15px',
                  textAlign: 'left',
                  fontFamily: 'Noto Sans KR',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                }}
              >
                <LandingPageTitle ismobilescreen={ismobilescreen}>
                  <PrimaryText
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      navigate({
                        pathname: Paths.myPractices,
                      })
                    }
                  >
                    Sell
                  </PrimaryText>{' '}
                  or{' '}
                  <PrimaryText
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      navigate({
                        pathname: Paths.practices,
                        search: addMapSearchToSearchParams(
                          window.location.search,
                        ),
                      })
                    }
                  >
                    Buy
                  </PrimaryText>{' '}
                  your Dental Practice
                  {!ismobilescreen && (
                    <LandingPageSubtitle>
                      in 90 days or less
                    </LandingPageSubtitle>
                  )}
                </LandingPageTitle>
                <FlexDiv
                  style={{
                    paddingBottom: '15px',
                  }}
                >
                  <SoldSearchFilters
                    user={user}
                    width={width}
                    ismobilescreen={ismobilescreen}
                    listingView={listingView}
                    numPractices={practices.length}
                    shouldResetFilters={shouldResetFilters}
                    setShouldResetFiltersToFalse={() =>
                      setShouldResetFilters(false)
                    }
                    onResetListingTypeFilter={() =>
                      setListingTypeFilter(
                        filterValues.defaultListingTypeFilter,
                      )
                    }
                  />
                </FlexDiv>
              </div>
              <Map
                user={user}
                ismobilescreen={ismobilescreen}
                width={width}
                practices={practices}
                listingTypeFilter={listingTypeFilter}
                onChangeListingTypeFilter={onChangeListingTypeFilter}
                searchedLocation={searchParams.get('location')}
                practicesLoading={!hasFetched}
                onClickResetFilters={() => setShouldResetFilters(true)}
              />
            </FlexDiv>
          </PageContainer>
        </BackgroundContainer>
      </>
    )
  }
}

export default Practices
