import React from 'react'
import {
  ColumnButtonContainerWithSmallerGap,
  Modal,
  ModalActions,
  ModalContainer,
  ModalContent,
  ModalContentTextWithLargerMobileText,
  ModalHeaderContainer,
  TextOnlyButtonWithoutUnderline,
  TitleText,
} from '../../../styles/shared-styled-components'
import CreatePracticeButton from '../../Micro/CreatePracticeButton'
import { LightIcon } from './styled'

const LocationAnalyticsModal = ({ user, isOpen, onClose, ismobilescreen }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContainer>
        <ModalHeaderContainer>
          <LightIcon />
          <TitleText>We can achieve transparency together</TitleText>
        </ModalHeaderContainer>
        <ModalContent>
          <ModalContentTextWithLargerMobileText ismobilescreen={ismobilescreen}>
            Use our AI model to value your practice, then we'll anonymize it and
            add it to our dataset.
            <br></br>
            <br></br>
            By sharing you'll get full access to the largest database of
            verified sold practices ever assembled in Ontario, spanning over 250
            practices sold over the past 6 years.
          </ModalContentTextWithLargerMobileText>
        </ModalContent>
        <ModalActions>
          <ColumnButtonContainerWithSmallerGap>
            <CreatePracticeButton
              text='Begin free valuation'
              user={user}
              inValuationMode
            />
            <TextOnlyButtonWithoutUnderline onClick={onClose}>
              Close
            </TextOnlyButtonWithoutUnderline>
          </ColumnButtonContainerWithSmallerGap>
        </ModalActions>
      </ModalContainer>
    </Modal>
  )
}

export default LocationAnalyticsModal
