import styled from 'styled-components/macro'
import { forSaleStageKey, offMarketStageKey } from '../../../utils/constants'

export const PracticeListingTypeColorCodeCircle = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background-color: ${(props) =>
    props.listingType === offMarketStageKey
      ? props.theme.color.offMarket
      : props.listingType === forSaleStageKey
        ? props.theme.color.primary
        : props.theme.color.sold};
  margin-left: 20px;
`
