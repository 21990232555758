import { useApolloClient } from '@apollo/client'
import { useMutationWithErrorLogging } from '../../../utils/hooks'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Paths from '../../../Paths'
import { useMyUser } from '../../../api/hooks/users'
import { EDIT_NAME } from '../../../api/mutations/users'
import { GET_MY_USER } from '../../../api/queries/users'
import Header from '../../../components/NavBar/Header'
import OnboardingSteps from '../../../components/Onboarding/OnboardingSteps'
import {
  BackgroundContainer,
  ColumnButtonContainer,
  FlexDiv,
  PageContainer,
} from '../../../styles/shared-styled-components'
import {
  Asset,
  AssetContainer,
  BackButton,
  ContinueButton,
  FormTitleText,
  OnboardingForm,
  OnboardingFormContainer,
  OnboardingInputField,
} from '../shared-onboarding-styles'

const Name = ({ user, width, ismobilescreen, isIpadSizeOrSmaller }) => {
  const { logout, loginWithRedirect } = useMyUser().auth0Context
  const { cache } = useApolloClient()
  const navigate = useNavigate()
  const [editNameMutation, { data: editNameData, loading }] =
    useMutationWithErrorLogging(EDIT_NAME)

  const [firstName, setFirstName] = useState(user?.firstName || '')
  const [lastName, setLastName] = useState(user?.lastName || '')
  const [errorMessage, setErrorMessage] = useState()

  const continueEnabled = !!(firstName && lastName)

  const handleLogout = async () => {
    await logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
    window.localStorage.clear()
    await loginWithRedirect({
      authorizationParams: {
        prompt: 'login',
        utm_affiliate: localStorage.getItem('utm_affiliate'),
      },
    })
  }

  const onClickContinue = () => {
    // This is the first authenticated call to our DB, so we attach the UTM param from local storage here
    const utmSourceInLocalStorage = localStorage.getItem('utm')
    editNameMutation({
      variables: {
        firstName,
        lastName,
        utm: utmSourceInLocalStorage,
      },
      onCompleted: async (data) => {
        const userDataFetch = cache.readQuery({
          query: GET_MY_USER,
        })
        const { firstName, lastName } = data?.editName

        // If we have cached user data, update the name
        if (userDataFetch) {
          // Grab the data in the cache and append the name
          const existingUser = userDataFetch.getMyUser
          const updatedUser = {
            ...existingUser,
            firstName,
            lastName,
          }

          // Update the cache
          await cache.writeQuery({
            query: GET_MY_USER,
            data: {
              getMyUser: updatedUser,
            },
          })
        }
      },
    })
  }

  useEffect(() => {
    if (editNameData) {
      const { firstName, lastName } = editNameData.editName

      if (firstName && lastName) {
        navigate(Paths.phoneOnboarding)
      } else {
        setErrorMessage('Unable to save name. Please refresh and try again.')
      }
    }
  }, [editNameData])

  return (
    <>
      <Header
        user={user}
        width={width}
        ismobilescreen={isIpadSizeOrSmaller}
        isOnboardingScreen
      />
      <BackgroundContainer>
        <PageContainer>
          <FlexDiv>
            <OnboardingFormContainer ismobilescreen={isIpadSizeOrSmaller}>
              <OnboardingSteps currentStep={1} />
              <OnboardingForm
                ismobilescreen={ismobilescreen}
                style={{ height: '250px', flex: 0 }}
              >
                <FormTitleText ismobilescreen={ismobilescreen}>
                  Welcome to Dentacloud!
                </FormTitleText>
                <OnboardingInputField
                  value={firstName}
                  label='First name * '
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <OnboardingInputField
                  value={lastName}
                  label='Last name * '
                  onChange={(e) => setLastName(e.target.value)}
                  errorMessage={errorMessage}
                />
              </OnboardingForm>
              <ColumnButtonContainer>
                <ContinueButton
                  disabled={!continueEnabled || loading}
                  onClick={onClickContinue}
                >
                  {loading ? 'Please wait...' : 'Save and continue'}
                </ContinueButton>
                <BackButton disabled={loading} onClick={handleLogout}>
                  Back to login
                </BackButton>
              </ColumnButtonContainer>
            </OnboardingFormContainer>
            {!isIpadSizeOrSmaller && (
              <AssetContainer>
                <Asset src={Paths.handshakeCartoon} />
              </AssetContainer>
            )}
          </FlexDiv>
        </PageContainer>
      </BackgroundContainer>
    </>
  )
}

export default Name
