import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import Auth0ProviderWithNavigate from '../src/Auth0ProviderWithNavigate'
import App from './App'
import Hotjar from '@hotjar/browser'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

const siteId = parseInt(process.env.REACT_APP_HOTJAR_APP_ID)
const hotjarVersion = 6

if (!!siteId) {
  Hotjar.init(siteId, hotjarVersion)
}

const root = ReactDOM.createRoot(document.getElementById('root'))

if (process.env.REACT_APP_USE_BUGSNAG === '1') {
  const bugsnagApiKey = process.env.REACT_APP_BUGSNAG_API_KEY
  Bugsnag.start({
    apiKey: bugsnagApiKey,
    plugins: [new BugsnagPluginReact()],
    enabledBreadcrumbTypes: ['error', 'log', 'navigation', 'request', 'user'],
  })

  const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

  root.render(
    <ErrorBoundary>
      <BrowserRouter>
        <Auth0ProviderWithNavigate>
          <App />
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
    </ErrorBoundary>,
  )
} else {
  root.render(
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <App />
      </Auth0ProviderWithNavigate>
    </BrowserRouter>,
  )
}
