import styled, { css } from 'styled-components/macro'
import {
  AutoHorizontalMargins,
  AvatarFrameStyle,
  BlackParagraphText,
  CardStyle,
  ColumnFlexStyle,
  InterSubsectionalGap,
  IntraSubsectionalGap,
  IntrasectionalGap,
  RowFlexStyle,
  SubtitleText,
} from '../../styles/shared-styled-components'

export const PracticeCard = styled.div`
  ${CardStyle}
  border-width: 2px;
  ${(props) =>
    !props.wholeCardShouldFold
      ? css`
          ${RowFlexStyle}
          flex-wrap: wrap;
          ${InterSubsectionalGap}
        `
      : css`
          ${ColumnFlexStyle}
          ${IntrasectionalGap}
        `}
`

export const PracticeTopSection = styled.div`
  ${RowFlexStyle}
`

export const PracticeAvatarContainer = styled.div`
  height: ${(props) => (props.wholeCardShouldFold ? '50px' : '100px')};
  width: 120px;
  ${RowFlexStyle}
  ${(props) =>
    !props.wholeCardShouldFold
      ? css`
          flex-wrap: wrap;
          ${InterSubsectionalGap}
        `
      : css`
          width: 40%;
          justify-content: center;
          ${AutoHorizontalMargins}
        `}
  ${(props) =>
    props.displayingDefaultPicture &&
    css`
      ${AvatarFrameStyle}
    `}
`

export const PracticeAvatar = styled.img`
  height: 100%;
  width: 100%;
  object-position: 50% 50%;
  object-fit: ${(props) =>
    props.displayingDefaultPicture ? 'contain' : 'cover'};
`

export const PracticeContentContainer = styled.div`
  flex: 1;
  justify-content: space-between;
  ${ColumnFlexStyle}
  ${IntrasectionalGap}
`

export const PracticeNameAndBadgeContainer = styled.div`
  ${(props) =>
    !props.wholeCardShouldFold
      ? css`
          ${IntraSubsectionalGap}
          ${RowFlexStyle}
        `
      : css`
          align-items: center;
          ${ColumnFlexStyle}
          ${IntraSubsectionalGap}
          width: 100%;
        `}
`

export const PracticeHeaderContainer = styled.div`
  width: 100%;
  ${RowFlexStyle}
  ${IntrasectionalGap}
  justify-content: space-between;
  align-items: flex-start;
`

export const PracticeBottomContentContainer = styled.div`
  ${IntraSubsectionalGap}
  width: 100%;
  ${(props) =>
    !props.wholeCardShouldFold
      ? css`
          ${RowFlexStyle}
          justify-content: space-between;
          flex-wrap: wrap;
        `
      : css`
          justify-content: center;
          ${ColumnFlexStyle}
          ${AutoHorizontalMargins}
        `}
`

export const PracticeSubText = styled(BlackParagraphText)`
  text-align: ${(props) => props.wholeCardShouldFold && 'center'};
  font-size: ${({ theme }) => theme.typography.defaultFontSize};
  line-height: 1.5rem;
  width: 100%;
  max-width: 500px;
  min-height: ${(props) => !props.wholeCardShouldFold && '72px'};
  display: flex;
  justify-content: ${(props) => props.wholeCardShouldFold && 'center'};
  align-self: center;
`

export const PracticeHeaderText = styled(SubtitleText)`
  color: ${({ theme }) => theme.color.black};
  font-weight: bold;
`

export const ActionButtonContainer = styled.div`
  ${IntraSubsectionalGap}
  width: 100%;
  display: flex;
  ${(props) =>
    !props.wholeCardShouldFold
      ? css`
          justify-content: flex-end;
          align-items: flex-end;
        `
      : css`
          justify-content: center;
          align-items: center;
          ${ColumnFlexStyle}
          ${AutoHorizontalMargins}
        `}
`

export const MultipleButtonContainer = styled.div`
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  ${RowFlexStyle}
  ${IntraSubsectionalGap}
`
