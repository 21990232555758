import styled from 'styled-components/macro'
import {
  BaseWidth,
  Checkbox,
  VerticallyScrollableDiv as VSD,
} from '../../../styles/shared-styled-components'

export const VerticallyScrollableDivWithContainedWidth = styled(VSD)`
  ${BaseWidth}
`
