import CameraAltIcon from '@mui/icons-material/CameraAlt'
import styled, { css } from 'styled-components/macro'
import {
  AutoHorizontalMargins,
  BaseWidth,
  ColumnFlexStyle,
  ExtraLargeIconStyle,
  IntraSubsectionalGap,
  IntrasectionalGap,
  PrimaryButton,
  RowFlexStyle,
  TitleText,
} from '../../styles/shared-styled-components'
import { LockIconButtonStyle } from '../Micro/LockIcon/styled'

export const PictureContainer = styled.div`
  height: ${(props) => (props.fullScreen ? '100%' : '436px')};
  ${RowFlexStyle}
  justify-content: center;
  width: 100%;
  background: linear-gradient(
    3deg,
    rgba(1, 98, 153, 0) 3%,
    rgba(1, 98, 153, 0.26) 66.92%
  );
  position: relative;
  padding: 0px;
`

export const GetVerifiedButton = styled(PrimaryButton)`
  ${LockIconButtonStyle}
  gap: 5px;
  min-width: fit-content;
  padding: 10px 20px;
  position: absolute;
  top: 15%;
  right: 0;
  transform: translate(-15%, 0%);
  z-index: 2;
  color: #fff;
  border: none;
  cursor: pointer;
`

export const PictureFrame = styled.div`
  width: 90%;
  ${AutoHorizontalMargins}
  ${RowFlexStyle}
  justify-content: center;
  position: relative;
`

export const Picture = styled.img`
  height: 100%;
  max-width: 100%;
  border: 1px solid ${({ theme }) => theme.color.primaryWithTransparency};
  object-fit: cover;
  border-radius: ${({ theme }) => `${theme.spacing.smallBorderRadius}px`};
`

export const CarouselNavigationContainer = styled.div`
  position: absolute;
  bottom: -1px;
  background-color: aliceblue;
  border: 1px solid ${({ theme }) => theme.color.primaryWithTransparency};
  border-radius: 2px 2px 0px 0px;
`

export const HeroContentContainer = styled.div`
  ${ColumnFlexStyle}
  ${IntrasectionalGap}
  flex: 1;
  align-items: center;
  ${(props) =>
    props.fullScreen === true
      ? css`
          margin-top: ${({ theme }) => `${theme.spacing.desktopTopMargin}px`};
          height: 100%;
        `
      : css`
          margin-top: auto;
          margin-bottom: auto;
          justify-content: center;
        `}
`

export const HeroText = styled(TitleText)`
  color: ${({ theme }) => theme.color.red};
  ${BaseWidth}
`

export const NoPicturesContainer = styled.div`
  width: 100%;
  ${ColumnFlexStyle}
  justify-content: center;
  align-items: center;
  ${IntraSubsectionalGap}
`

export const NoPicturesIconContainer = styled.div`
  height: 65%;
  aspect-ratio: 1 / 1;
  border-radius: 100px;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.lightPrimary};
`

export const NoPicturesIconFrame = styled.img`
  height: 70%;
`
export const CameraIcon = styled(CameraAltIcon)`
  ${ExtraLargeIconStyle}
  color: ${({ theme }) => theme.color.black};
`
