import { americanCountryCode } from './constants'

export const getProvinceOrStateFromGeocodedAddress = (geocodedAddress) => {
  // Get the province/state from the address components
  const provinceComponent = geocodedAddress.address_components.find(
    (component) => component.types.includes('administrative_area_level_1'),
  )
  const province = provinceComponent ? provinceComponent.long_name : ''

  return province
}

export const getCityFromGeocodedAddress = (geocodedAddress) => {
  // Checks for locality first (usually a more granular city)
  // If not found grabs sublocality
  const cityComponent = geocodedAddress.address_components.find(
    (component) =>
      component.types.includes('locality') ||
      component.types.includes('sublocality'),
  )

  const city = cityComponent ? cityComponent.long_name : ''

  return city
}

export const formatLocationString = (str) => {
  if (typeof str !== 'string') {
    throw new Error('Input must be a string', str)
  }

  // Convert to lowercase and replace spaces/dashes with underscores
  const formattedString = str.toLowerCase().replace(/[\s-]/g, '_')

  return formattedString
}

export const getLocationString = (locations, includePrefix = true) => {
  let locationString = 'Locations: '
  let locationStringUndefinedString = 'Undefined'
  let locationStringToAppend = ''
  locations?.map((l, i) => {
    if (l.city && l.province) {
      if (i !== 0) {
        locationStringToAppend += ', '
      }
      locationStringToAppend += `${l.city}`
    }
  })

  if (!includePrefix) {
    return locationStringToAppend
  }

  locationString += locationStringToAppend
    ? locationStringToAppend
    : locationStringUndefinedString

  return locationString
}

export const getSemiAnonymizedPostalOrZipCode = (postalCode, country) => {
  if (!postalCode) {
    return ''
  }

  const formattedPostalCode = postalCode.replace(' ', '')

  return country === americanCountryCode
    ? `${formattedPostalCode.slice(0, 2)}***`
    : `${formattedPostalCode.slice(0, 3)} ***`
}

export const getPixelPositionFromLatLng = (latLng, map, window) => {
  const topRight = map
    .getProjection()
    .fromLatLngToPoint(map.getBounds().getNorthEast())
  const bottomLeft = map
    .getProjection()
    .fromLatLngToPoint(map.getBounds().getSouthWest())

  const scale = Math.pow(2, map.getZoom())

  const worldPoint = map.getProjection().fromLatLngToPoint(latLng)

  return new window.google.maps.Point(
    (worldPoint.x - bottomLeft.x) * scale,
    (worldPoint.y - topRight.y) * scale,
  )
}

export default {
  getProvinceOrStateFromGeocodedAddress,
  getCityFromGeocodedAddress,
  formatLocationString,
  getLocationString,
  getSemiAnonymizedPostalOrZipCode,
  getPixelPositionFromLatLng,
}
