import {
  allSaleStages,
  anesthesiology_type_key,
  endodontics_type_key,
  general_type_key,
  hygiene_type_key,
  oral_max_surgery_type_key,
  oral_radiology_type_key,
  orthodontics_type_key,
  pediatrics_type_key,
  periodontics_type_key,
  practiceTypes,
  prosthodontics_type_key,
  yearsSold,
} from './constants'
import { parseRangeStringIntoArrayOfInts } from './numbers'

const minSalePrice = 50_000
const maxSalePrice = 30_000_000
const defaultMinSalePrice = minSalePrice
const defaultMaxSalePrice = maxSalePrice
const salePriceRangeIntervalSize = 50000
const defaultSalePriceRange = [defaultMinSalePrice, defaultMaxSalePrice]

const minRevenue = 0
const maxRevenue = 10_000_000
const defaultMinRevenue = minRevenue
const defaultMaxRevenue = maxRevenue
const revenueRangeIntervalSize = 50000
const defaultRevenueRange = [defaultMinRevenue, defaultMaxRevenue]

const minOperatories = 1
const maxOperatories = 30
const defaultMinOperatories = minOperatories
const defaultMaxOperatories = maxOperatories
const defaultOperatoriesRange = [defaultMinOperatories, defaultMaxOperatories]

const eligibleSaleYears = [...yearsSold]

const defaultPracticeType = [
  general_type_key,
  anesthesiology_type_key,
  hygiene_type_key,
  endodontics_type_key,
  oral_max_surgery_type_key,
  oral_radiology_type_key,
  orthodontics_type_key,
  pediatrics_type_key,
  periodontics_type_key,
  prosthodontics_type_key,
]

const defaultListingTypeFilter = allSaleStages

export const filterValues = {
  minSalePrice,
  maxSalePrice,
  defaultMinSalePrice,
  defaultMaxSalePrice,
  salePriceRangeIntervalSize,
  defaultSalePriceRange,
  minRevenue,
  maxRevenue,
  defaultMinRevenue,
  defaultMaxRevenue,
  revenueRangeIntervalSize,
  defaultRevenueRange,
  minOperatories,
  maxOperatories,
  defaultMinOperatories,
  defaultMaxOperatories,
  defaultOperatoriesRange,
  eligibleSaleYears,
  defaultListingTypeFilter,
  defaultPracticeType,
}

export const getInitialOperatoriesFilter = (searchParams) => {
  return searchParams.get('operatories')
    ? parseRangeStringIntoArrayOfInts(searchParams.get('operatories'))
    : filterValues.defaultOperatoriesRange
}

export const getInitialRevenueFilter = (searchParams) => {
  return searchParams.get('revenue')
    ? parseRangeStringIntoArrayOfInts(searchParams.get('revenue'))
    : filterValues.defaultRevenueRange
}

export const getInitialSalePriceFilter = (searchParams) => {
  return searchParams.get('price')
    ? parseRangeStringIntoArrayOfInts(searchParams.get('price'))
    : filterValues.defaultSalePriceRange
}

export const emptyFilterSearchParamValue = 'none'

export const getInitialYearSoldFilter = (searchParams) => {
  return searchParams.get('year')
    ? searchParams.get('year') === emptyFilterSearchParamValue
      ? []
      : parseRangeStringIntoArrayOfInts(searchParams.get('year'))
    : filterValues.eligibleSaleYears
}

export const getInitialListingTypeFilter = (searchParams) => {
  return searchParams.get('listingType')
    ? searchParams.get('listingType') === emptyFilterSearchParamValue
      ? []
      : searchParams.get('listingType')
    : filterValues.defaultListingTypeFilter
}

export const getInitalPracticeTypeFitler = (searchParams) => {
  return searchParams.get('practiceType')
    ? searchParams.get('practiceType') === emptyFilterSearchParamValue
      ? []
      : searchParams.get('practiceType')
    : filterValues.defaultPracticeType
}

export default {
  filterValues,
  getInitialOperatoriesFilter,
  getInitialRevenueFilter,
  getInitialSalePriceFilter,
  getInitialYearSoldFilter,
  emptyFilterSearchParamValue,
  getInitialListingTypeFilter,
  getInitalPracticeTypeFitler,
}
