import gql from 'graphql-tag'
import { ListingFragment } from '../fragments/practices'

export const CREATE_PRACTICE = gql`
  mutation createPractice {
    createPractice {
      id
      createdAt
      updatedAt
    }
  }
`

export const EDIT_PRACTICE_OVERVIEW = gql`
  mutation editPracticeOverview(
    $id: String!
    $name: String!
    $type: PracticeType!
    $city: String!
    $province: ProvinceOrState!
    $postalCode: String!
    $latitude: Float!
    $longitude: Float!
    $country: String!
    $address: String!
    $dpms: String!
    $averageAnnualBillings: Float!
    $hygieneRevenuePercentage: Float
    $isInvisalignCertified: Boolean
    $hasIntraoralScanner: Boolean
    $practiceHasDebt: Boolean!
    $patientsSeenWeekly: Int
    $onlyProvidesAnesthesia: Boolean
    $numberDentistsRefer: Int
    $openDaysPerWeek: Int
    $numberOfPatients: Int
    $numberOfDoctors: Int
  ) {
    editPracticeOverview(
      id: $id
      name: $name
      type: $type
      city: $city
      province: $province
      postalCode: $postalCode
      latitude: $latitude
      longitude: $longitude
      country: $country
      address: $address
      dpms: $dpms
      averageAnnualBillings: $averageAnnualBillings
      hygieneRevenuePercentage: $hygieneRevenuePercentage
      isInvisalignCertified: $isInvisalignCertified
      hasIntraoralScanner: $hasIntraoralScanner
      practiceHasDebt: $practiceHasDebt
      patientsSeenWeekly: $patientsSeenWeekly
      onlyProvidesAnesthesia: $onlyProvidesAnesthesia
      numberDentistsRefer: $numberDentistsRefer
      openDaysPerWeek: $openDaysPerWeek
      numberOfPatients: $numberOfPatients
      numberOfDoctors: $numberOfDoctors
    ) {
      id
      name
      city
      type
      country
      postalCode
      address
      dpms
      averageAnnualBillings
      hygieneRevenuePercentage
      sectionCurrentlyEditing
      updatedAt
      completedAt
      isInvisalignCertified
      hasIntraoralScanner
      practiceHasDebt
      patientsSeenWeekly
      onlyProvidesAnesthesia
      numberDentistsRefer
      openDaysPerWeek
      numberOfPatients
      numberOfDoctors
    }
  }
`

export const EDIT_PRACTICE_STAFF = gql`
  mutation editPracticeStaff(
    $id: String!
    $partTimeAssociates: Int!
    $fullTimeAssociates: Int!
    $partTimeHygienists: Int
    $fullTimeHygienists: Int
    $partTimeAdmins: Int!
    $fullTimeAdmins: Int!
    $partTimeAssistants: Int!
    $fullTimeAssistants: Int!
    $practiceOwnerWorkingSchedule: ChairsideStatus!
    $canAddAssociate: Boolean!
  ) {
    editPracticeStaff(
      id: $id
      partTimeAssociates: $partTimeAssociates
      fullTimeAssociates: $fullTimeAssociates
      partTimeHygienists: $partTimeHygienists
      fullTimeHygienists: $fullTimeHygienists
      partTimeAdmins: $partTimeAdmins
      fullTimeAdmins: $fullTimeAdmins
      partTimeAssistants: $partTimeAssistants
      fullTimeAssistants: $fullTimeAssistants
      practiceOwnerWorkingSchedule: $practiceOwnerWorkingSchedule
      canAddAssociate: $canAddAssociate
    ) {
      id
      partTimeAssociates
      fullTimeAssociates
      partTimeHygienists
      fullTimeHygienists
      partTimeAdmins
      fullTimeAdmins
      partTimeAssistants
      fullTimeAssistants
      sectionCurrentlyEditing
      practiceOwnerWorkingSchedule
      canAddAssociate
      updatedAt
      completedAt
    }
  }
`

export const EDIT_PRACTICE_LEASE = gql`
  mutation editPracticeLease(
    $id: String!
    $ownsBuilding: Boolean!
    $buildingHasAppraisal: Boolean
    $saleType: SaleType!
    $roomForOperatoryExpansion: Boolean!
    $numOfExtraOperatories: Int
    $buildingType: BuildingType!
    $buildingHasDemolitionClause: OptionalBoolean
    $yearsLeftOnLease: Int
    $operatories: Int!
  ) {
    editPracticeLease(
      id: $id
      ownsBuilding: $ownsBuilding
      buildingHasAppraisal: $buildingHasAppraisal
      numOfExtraOperatories: $numOfExtraOperatories
      saleType: $saleType
      roomForOperatoryExpansion: $roomForOperatoryExpansion
      buildingType: $buildingType
      buildingHasDemolitionClause: $buildingHasDemolitionClause
      yearsLeftOnLease: $yearsLeftOnLease
      operatories: $operatories
    ) {
      id
      ownsBuilding
      buildingHasAppraisal
      saleType
      roomForOperatoryExpansion
      numOfExtraOperatories
      buildingType
      buildingHasDemolitionClause
      yearsLeftOnLease
      sectionCurrentlyEditing
      operatories
      updatedAt
      completedAt
    }
  }
`

export const EDIT_PRACTICE_SALE_TERMS = gql`
  mutation editPracticeSaleTerms(
    $id: String!
    $targetPrice: Float!
    $timeFrameLookingToSell: String!
    $monthsWorkingPostSale: Int!
    $usedLifetimeCapitalGainsExemption: OptionalBoolean!
  ) {
    editPracticeSaleTerms(
      id: $id
      targetPrice: $targetPrice
      monthsWorkingPostSale: $monthsWorkingPostSale
      usedLifetimeCapitalGainsExemption: $usedLifetimeCapitalGainsExemption
      timeFrameLookingToSell: $timeFrameLookingToSell
    ) {
      id
      targetPrice
      timeFrameLookingToSell
      monthsWorkingPostSale
      usedLifetimeCapitalGainsExemption
      sectionCurrentlyEditing
      mostRecentValuation {
        id
        lowerBound
        upperBound
        createdAt
        updatedAt
      }
      updatedAt
      completedAt
    }
  }
`

export const UPLOAD_PRACTICE_PICTURES = gql`
  mutation uploadPracticePictures($id: String!, $pictures: [Upload!]) {
    uploadPracticePictures(id: $id, pictures: $pictures)
  }
`

export const EDIT_PRACTICE_PICTURES = gql`
  mutation editPracticePictures(
    $id: String!
    $website: String
    $pictureUrls: [String]
  ) {
    editPracticePictures(
      id: $id
      website: $website
      pictureUrls: $pictureUrls
    ) {
      id
      website
      pictures
      sectionCurrentlyEditing
      updatedAt
      completedAt
    }
  }
`

export const UPLOAD_PRACTICE_PRODUCTION_REPORT = gql`
  mutation uploadPracticeProductionReport(
    $id: String!
    $productionReport: Upload!
  ) {
    uploadPracticeProductionReport(id: $id, productionReport: $productionReport)
  }
`

export const EDIT_PRACTICE_PRODUCTION_REPORT = gql`
  mutation editPracticeProductionReport(
    $id: String!
    $productionReportUrl: String!
  ) {
    editPracticeProductionReport(
      id: $id
      productionReportUrl: $productionReportUrl
    ) {
      id
      productionReportUrl
      sectionCurrentlyEditing
      updatedAt
      completedAt
    }
  }
`

export const EDIT_PRACTICE_SECTION_CURRENTLY_EDITING = gql`
  mutation editPracticeSectionCurrentlyEditing(
    $id: String!
    $sectionCurrentlyEditing: CreateListingFlowSection!
  ) {
    editPracticeSectionCurrentlyEditing(
      id: $id
      sectionCurrentlyEditing: $sectionCurrentlyEditing
    ) {
      id
      sectionCurrentlyEditing
      updatedAt
      completedAt
    }
  }
`

export const DELETE_PRACTICE = gql`
  mutation deletePractice($id: String!) {
    deletePractice(id: $id)
  }
`

export const PUBLISH_PRACTICE_LISTING = gql`
  ${ListingFragment}
  mutation publishPracticeListing($id: String!, $type: ListingType!) {
    publishPracticeListing(id: $id, type: $type) {
      id
      ...ListingFragment
      updatedAt
      completedAt
    }
  }
`

export const EDIT_PRACTICE_FINANCIAL_DETAILS = gql`
  mutation EditPracticeFinancialDetails(
    $id: String!
    $financialDetails: PracticeFinancialDetailsInput!
  ) {
    editPracticeFinancialDetails(id: $id, financialDetails: $financialDetails) {
      id
      name
      dueDiligence {
        practiceProfile {
          numOfOps
          opsPurposes
          yearFounded
          yearOpened
          paymentTerminalVendor
        }
        accounting {
          fiscalYearEnd
          financialStatement
          generalLedgers
          incomeStatements
          trialBalances
          forecast
        }
        legal {
          legal
          permiseRentalLease
          vendorContracts
          equipmentRentals
          regulatoryCompliance
        }
        practiceManagementReports {
          totalAnnualCollections
          totalMonthlyCollections
          procedureCodesPerMonth
          procedureCodesPerYear
          agedAccRecTotalPractice
          agedAccRecProvider
          patientCount
          referredOutCases
        }
        humanResource {
          employeesSpreadsheet
          employees
        }
      }
    }
  }
`
