import React from 'react'
import Header from '../../../components/NavBar/Header'
import Panel from '../Panel'

const ValuationAdminPanelPage = ({ user, width, ismobilescreen }) => {
  return (
    <>
      <Header user={user} width={width} ismobilescreen={ismobilescreen} />
      <Panel />
    </>
  )
}

export default ValuationAdminPanelPage
