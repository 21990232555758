import { useRef } from 'react'
import {
  allSaleStageLabels,
  allSaleStages,
  forSaleStageKey,
  offMarketStageKey,
  soldStageKey,
} from '../../../utils/constants'
import { ListingTypeButton, ListingTypesContainer } from './styled'
import MouseOverPopover from '../../OverPopover'
import {
  ModalContentText,
  TitleText,
} from '../../../styles/shared-styled-components'
import { Box } from '@mui/material'
import { useMyUser } from '../../../api/hooks/users'
import { checkIfValueOrSellGoalPresent } from '../../../utils/user'
import {
  MAP_FILTER_POP_UP,
  HAS_SEEN_OFF_MARKET,
  HAS_SEEN_SOLD,
} from '../../../api/queries/users'
import { useMutationWithErrorLogging } from '../../../utils/hooks'
import { useQueryWithErrorLogging } from '../../../utils/hooks'

const ListingTypeToggle = ({ onClick, filter, width, ismobilescreen }) => {
  const popupRef = useRef()

  const useMyUserResult = useMyUser()
  const goals = useMyUserResult?.user?.goals
  const { data, loading, error } = useQueryWithErrorLogging(MAP_FILTER_POP_UP, {
    skip: !useMyUserResult.auth0Context.isAuthenticated,
  })

  const [updateOffMarket] = useMutationWithErrorLogging(HAS_SEEN_OFF_MARKET)
  const [updateSold] = useMutationWithErrorLogging(HAS_SEEN_SOLD)

  const handleClick = (event, stage) => {
    if (
      stage === offMarketStageKey ||
      stage === soldStageKey ||
      stage === forSaleStageKey
    ) {
      onClick(stage)
    }
    if (useMyUserResult?.auth0Context.isAuthenticated && !loading) {
      if (error) {
        console.log(error)
        return
      }
      const has_seen_off_market_pop_up =
        data?.getMyUser?.has_seen_off_market_pop_up
      const has_seen_sold_pop_up = data?.getMyUser?.has_seen_sold_pop_up

      let title = undefined,
        body = undefined

      if (stage !== forSaleStageKey) {
        switch (stage) {
          case offMarketStageKey:
            if (!has_seen_off_market_pop_up) {
              if (checkIfValueOrSellGoalPresent(goals)) {
                title = 'Off-Market Listing'
                body =
                  "If you're not looking to sell your practice right away, you can still complete your valuation and set a target price, even if your target price is higher than your valuation. Someone may be willing to pay it, but they'll only see your target price if you post an off-market listing."
              } else {
                title = 'Off-Market Listing'
                body =
                  "Even if you're not actively seeking to purchase a practice, exploring off-market listings can still uncover opportunities. Expressing interest, even if a practice's listed price exceeds its valuation, could lead to negotiations. Your interest will only be visible to sellers when you explore off-market listings."
              }
              updateOffMarket({
                update: (cache) => {
                  cache.evict({ fieldName: 'getMyUser' })
                },
              })
              break
            }
            break
          case soldStageKey:
            if (!has_seen_sold_pop_up) {
              title = 'Sold'
              body =
                "Because confidentiality and privacy are important to us, the sold data that we share is high-level and non-identifiable. If you’d like to hear about other Connect users' experiences on our platform, we can connect you with past clients within your postal code of interest."
              updateSold({
                update: (cache) => {
                  cache.evict({ fieldName: 'getMyUser' })
                },
              })
            }
            break

          default:
            break
        }
      }

      if (title !== undefined) {
        popupRef.current?.show({
          event,
          content: (
            <Box sx={{ maxWidth: 300, p: 1 }}>
              <TitleText style={{ marginBottom: 2 }}>{title}</TitleText>
              <ModalContentText
                style={{
                  textAlign: 'justify',
                  fontSize: '16px',
                  marginTop: '12px',
                }}
              >
                {body}
              </ModalContentText>
            </Box>
          ),
        })
      }
    }
  }

  return (
    <ListingTypesContainer width={width} ismobilescreen={ismobilescreen}>
      {allSaleStages.map((stage, idx) => {
        const stageLabel = allSaleStageLabels[stage]
        const labelText = stageLabel['label']
        const stageKey = stageLabel['key']
        return (
          <ListingTypeButton
            stage={stage}
            id={`${stageKey}_listing_type_btn`}
            selected={filter?.includes(stage)}
            key={idx}
            idx={idx}
            isLastElement={idx === allSaleStages.length - 1}
            onClick={(e) => handleClick(e, stage)}
          >
            {labelText}
          </ListingTypeButton>
        )
      })}
      <MouseOverPopover ref={popupRef} ismobilescreen={ismobilescreen} />
    </ListingTypesContainer>
  )
}

export default ListingTypeToggle
