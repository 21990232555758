import { useQueryWithErrorLogging } from '../../utils/hooks'
import get from 'lodash/get'
import { sortDataByDateField } from '../../utils/data'
import { removeTypenames } from '../../utils/object'
import {
  GET_GLOBAL_INPUTS,
  GET_HEURISTICS,
  GET_PRACTICE_VALUATIONS,
} from '../queries/valuations'

export const usePracticeValuations = (practiceId) => {
  const { data, error, loading, refetch } = useQueryWithErrorLogging(
    GET_PRACTICE_VALUATIONS,
    {
      variables: {
        practiceId,
      },
      fetchPolicy: 'cache-first',
    },
  )

  let valuationData = get(data, 'getPracticeValuations', [])
  if (valuationData?.length) {
    valuationData = sortDataByDateField({
      data: valuationData,
      dateField: 'createdAt',
    })
  }

  return {
    error,
    loading,
    refetch,
    valuations: valuationData,
  }
}

export const useHeuristics = (environment) => {
  const { data } = useQueryWithErrorLogging(GET_HEURISTICS, {
    variables: {
      environment: 'staging',
    },
  })

  const heuristics = get(data, 'getHeuristics', null)
  if (!heuristics) {
    return null
  }

  const combinedHeuristics = Object.fromEntries(
    heuristics.map((heuristic) => [
      heuristic.inputName,
      removeTypenames(heuristic),
    ]),
  )

  return combinedHeuristics
}

export const useGlobalInputs = (environment) => {
  const { data } = useQueryWithErrorLogging(GET_GLOBAL_INPUTS, {
    variables: {
      environment,
    },
  })

  const globalInputs = get(data, 'getGlobalInputs', null)
  return removeTypenames(globalInputs)
}
