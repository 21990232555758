import { Box } from '@mui/material'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useIsMobileScreen } from '../../utils/view'

const MouseOverPopover = React.forwardRef((props, ref) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [config, setConfig] = React.useState({})
  const [circleCoords, setCircleCoords] = React.useState({ x: 4, y: 15 })
  const ismobilescreen = useIsMobileScreen()

  React.useImperativeHandle(ref, () => ({
    show: (data) => {
      setAnchorEl(data.event.target)
      setConfig(data)
    },
  }))

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'listing-popover' : undefined

  React.useEffect(() => {
    if (open && config) {
      const handleCirclePositioning = () => {
        const target = document.getElementById(config.event.target?.id)
        const arrow = document.getElementById('arrow')
        const { offsetTop: offsetParentTop, offsetLeft: offsetParentLeft } =
          target.offsetParent
        const offsetTop = offsetParentTop + target.offsetTop
        const offsetLeft = offsetParentLeft + target.offsetLeft

        arrow.style.position = 'absolute'
        arrow.style.top = `${offsetTop + 60}px`
        arrow.style.left = `${offsetLeft + 40}px`

        setCircleCoords({
          x: offsetLeft + 15,
          y: offsetTop - 15,
        })
      }

      window.addEventListener('resize', handleCirclePositioning)
      handleCirclePositioning()

      return () => {
        window.removeEventListener('resize', handleCirclePositioning)
      }
    }
  }, [open, config, ismobilescreen])

  return (
    open && (
      <Box
        sx={{
          top: 0,
          left: 0,
          position: 'fixed',
          zIndex: 200,
          width: '100vw',
          height: '100vh',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            margin: '0 auto',
            overflow: 'hidden',

            '&::after': {
              content: '""',
              position: 'absolute',
              left: `${circleCoords.x}px`,
              top: `${circleCoords.y}px`,
              borderRadius: '50%',
              backgroundColor: 'transparent',
              boxShadow: '0 0 0 2000px rgba(0, 0, 0, 0.7)',
              width: '70px',
              height: '70px',
            },
          }}
        />

        <Box
          id='arrow'
          sx={{
            transform: 'translateX(-50%)',

            '&::after': {
              content: '""',
              position: 'absolute',
              transform: 'translateX(-50%)',
              width: '20px',
              height: '20px',
              backgroundColor: '#FFF',
              clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
              boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.3)',
            },
          }}
        />

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{
            '& .MuiPaper-root': {
              borderRadius: '20px',
              marginTop: '40px',
            },
          }}
        >
          <Typography sx={{ p: 2 }}>{config.content}</Typography>
        </Popover>
      </Box>
    )
  )
})

export default MouseOverPopover
