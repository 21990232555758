import gql from 'graphql-tag'

export const GET_MY_NOTIFICATIONS = gql`
  query getUserNotifications {
    getUserNotifications {
      id
      userId
      deliveryMethods
      notificationType
      notificationMetadata
      viewUrl
      isRead
    }
  }
`

export const GET_NOTIFICATION_BY_ID = gql`
  query GetNotificationById($id: String!) {
    getNotificationById(id: $id) {
      id
      userId
      deliveryMethods
      notificationType
      notificationMetadata
      viewUrl
      isRead
    }
  }
`
