import gql from 'graphql-tag'

export const CREATE_ACQUISITION = gql`
  mutation createAcquisition(
    $type: String!
    $practice_id: String!
    $buyer_id: String!
    $buying_conditions: String!
    $percentage_to_buy: String!
    $pre_approved_financing: String!
    $years_to_work_post_sale: String!
    $purchase_price: Int
    $pre_financing_report: String
  ) {
    createAcquisition(
      type: $type
      practice_id: $practice_id
      buyer_id: $buyer_id
      buying_conditions: $buying_conditions
      percentage_to_buy: $percentage_to_buy
      pre_approved_financing: $pre_approved_financing
      years_to_work_post_sale: $years_to_work_post_sale
      purchase_price: $purchase_price
      pre_financing_report: $pre_financing_report
    ) {
      id
      type
      status
      buyer_id
      practice_id
      practice
      is_favorite
      is_archived
      purchase_price
      buying_conditions
      percentage_to_buy
      pre_approved_financing
      years_to_work_post_sale
      pre_financing_report
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_ACQUISITION = gql`
  mutation updateAcquisition(
    $id: String!
    $status: String
    $is_archived: Boolean
    $is_favorite: Boolean
  ) {
    updateAcquisition(
      id: $id
      status: $status
      is_archived: $is_archived
      is_favorite: $is_favorite
    ) {
      id
      buyer_id
      practice_id
      practice
      type
      status
      is_favorite
      is_archived
      purchase_price
      buying_conditions
      pre_financing_report
      percentage_to_buy
      pre_approved_financing
      years_to_work_post_sale
      createdAt
      updatedAt
    }
  }
`

export const CANCEL_ACQUISITION_REQUEST = gql`
  mutation cancelAcquisitionRequest($id: String!) {
    cancelAcquisitionRequest(id: $id) {
      id
      buyer_id
      practice_id
      practice
      type
      status
      is_favorite
      is_archived
      purchase_price
      buying_conditions
      percentage_to_buy
      pre_financing_report
      pre_approved_financing
      years_to_work_post_sale
      createdAt
      updatedAt
    }
  }
`
export const ACCEPT_ACQUISITION_OFFER = gql`
  mutation acceptAcquisitionOffer($id: String!) {
    acceptAcquisitionOffer(id: $id)
  }
`
