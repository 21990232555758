import get from 'lodash/get'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMyUser } from '../../../api/hooks/users'
import { forSaleStageKey, soldStageKey } from '../../../utils/constants'
import {
  getAbbreviatedMoneyString,
  getAnonymizedAndAbbreviatedMoneyString,
} from '../../../utils/numbers'
import { getViewPracticePathFromId } from '../../../utils/routing'
import { ListingTypeButton } from '../ListingTypeToggle/styled'
import RevealPriceButton from '../RevealPriceButton/RevealPriceButton'
import DentalClinicSvg from './dental-clinic-icon-v2.svg'
import {
  NumberOfPracticesHeadingContainer,
  NumberOfPracticesText,
  PracticeAvatar,
  PracticeAvatarContainer,
  PracticeSubtext,
  PracticeTextContainer,
  PracticeThumbnailCloseContainer,
  ScrollablePracticesList,
  StyledCloseIcon,
  OffMarketPracticeThumbnailCloseContainer,
  CloseButtonNumberOfPracticesHeadingContainer,
  PracticeAvatarNouser,
  StyledCloseIconNoUser,
} from './styled'
import {
  PracticeThumbnailContainer,
  PracticeThumbnailContainerWithMultiplePractices,
  PracticeThumbnailContentContainer,
} from './ThumbnailContainers'

export const assumedDistanceFromTopOfPageToTopOfMap = 0

const PracticePreviewThumbnail = ({
  practices,
  x,
  y,
  width,
  height,
  onClose,
  onClickRevealPrice,
  hasCreatedValuation,
}) => {
  const navigate = useNavigate()

  const { isAuthenticated, loginWithRedirect } = useMyUser().auth0Context

  const firstPractice = get(practices, '[0]')
  const numPractices = practices.length

  const heightOfMap = height - assumedDistanceFromTopOfPageToTopOfMap
  const heightBelowMarker = heightOfMap - y
  const heightAboveMarker = y

  const formatPriceText = (practice, isAuthenticated, hasCreatedValuation) => {
    if (practice.listingType === soldStageKey) {
      const priceText = isAuthenticated
        ? hasCreatedValuation
          ? ` for ${getAbbreviatedMoneyString(practice.targetPrice)}`
          : ` at ${getAnonymizedAndAbbreviatedMoneyString(
              practice.targetPrice,
            )}`
        : ''
      return `Sold${priceText} (${practice.yearSold})`
    } else {
      return `Valuation ${getAbbreviatedMoneyString(practice.targetPrice)}`
    }
  }

  const goToLoginPage = async ({ redirectTo }) => {
    await loginWithRedirect({
      appState: {
        returnTo: redirectTo,
      },
      authorizationParams: {
        screen_hint: 'signup',
        utm_affiliate: localStorage.getItem('utm_affiliate'),
      },
    })
  }

  const goPracticeDetailpage = async (practice) => {
    navigate(getViewPracticePathFromId(practice?.practice_id))
  }

  const goDataroomAccessPage = (practice) => {
    if (isAuthenticated && practice.listingType !== soldStageKey) {
      goPracticeDetailpage(practice)
    } else if (!isAuthenticated) {
      goToLoginPage({
        redirectTo: `${window.location.origin}/${getViewPracticePathFromId(
          practice?.practice_id,
        )}`,
      })
    }
  }

  return numPractices === 0 ? (
    <></>
  ) : numPractices === 1 ? (
    <PracticeThumbnailContainer
      x={x}
      y={y}
      width={width}
      height={height}
      heightAboveMarker={heightAboveMarker}
      // onClick={() => {
      //   if (firstPractice.listingType !== soldStageKey) {
      //     navigate(getViewPracticePathFromId(firstPractice?.id));
      //   }
      // }}
      hasCreatedValuation={hasCreatedValuation}
    >
      <PracticeThumbnailContentContainer
        hasCreatedValuation={hasCreatedValuation}
      >
        <PracticeAvatarContainer
          hasCreatedValuation={hasCreatedValuation}
          onClick={() => goDataroomAccessPage(firstPractice)}
        >
          {hasCreatedValuation ? (
            <PracticeAvatar src={DentalClinicSvg} />
          ) : (
            <PracticeAvatarNouser src={DentalClinicSvg} />
          )}
        </PracticeAvatarContainer>

        {hasCreatedValuation ? (
          <OffMarketPracticeThumbnailCloseContainer>
            <StyledCloseIcon onClick={onClose} />
          </OffMarketPracticeThumbnailCloseContainer>
        ) : (
          <OffMarketPracticeThumbnailCloseContainer>
            <StyledCloseIconNoUser onClick={onClose} />
          </OffMarketPracticeThumbnailCloseContainer>
        )}

        <PracticeTextContainer
          hasCreatedValuation={hasCreatedValuation}
          onClick={() => goDataroomAccessPage(firstPractice)}
        >
          <ListingTypeButton
            selected
            stage={firstPractice.listingType}
            style={{
              width: '80px',
              padding: '1px 10px',
              borderRadius: '5px',
              textTransform: 'capitalize',
            }}
          >
            {firstPractice.listingType?.replace(/_/g, ' ')}
          </ListingTypeButton>
          <PracticeSubtext style={{ fontWeight: 'bold' }}>
            {formatPriceText(
              firstPractice,
              isAuthenticated,
              hasCreatedValuation,
            )}
          </PracticeSubtext>
          <PracticeSubtext style={{ fontWeight: 'bold' }}>
            Annual Billings:{' '}
            {getAbbreviatedMoneyString(firstPractice.averageAnnualBillings)}
          </PracticeSubtext>
          <PracticeSubtext style={{ fontWeight: 'bold' }}>
            Operatories: {firstPractice.operatories}
          </PracticeSubtext>
          {!hasCreatedValuation && (
            <RevealPriceButton onClick={onClickRevealPrice} />
          )}
        </PracticeTextContainer>
      </PracticeThumbnailContentContainer>
    </PracticeThumbnailContainer>
  ) : (
    <PracticeThumbnailContainerWithMultiplePractices
      x={x}
      y={y}
      width={width}
      height={height}
      heightAboveMarker={heightAboveMarker}
      heightBelowMarker={heightBelowMarker}
      // onClick={() => {
      //   if (firstPractice.listingType !== soldStageKey) {
      //     navigate(getViewPracticePathFromId(firstPractice?.id));
      //   }
      // }}
      hasCreatedValuation={hasCreatedValuation}
    >
      <CloseButtonNumberOfPracticesHeadingContainer>
        <PracticeThumbnailCloseContainer>
          <StyledCloseIcon onClick={onClose} />
        </PracticeThumbnailCloseContainer>
      </CloseButtonNumberOfPracticesHeadingContainer>

      <NumberOfPracticesHeadingContainer>
        <NumberOfPracticesText>
          {practices.length} practices in this area
        </NumberOfPracticesText>
      </NumberOfPracticesHeadingContainer>
      <ScrollablePracticesList>
        {practices.map((p, idx) => (
          <PracticeThumbnailContentContainer
            inVerticalScroll
            isBottomElement={idx === numPractices - 1}
            key={p.id}
            hasCreatedValuation={hasCreatedValuation}
            onClick={() => goDataroomAccessPage(p)}
          >
            <PracticeAvatarContainer hasCreatedValuation={hasCreatedValuation}>
              <PracticeAvatar src={DentalClinicSvg} />
            </PracticeAvatarContainer>
            <PracticeTextContainer>
              <ListingTypeButton
                selected
                stage={p.listingType}
                style={{
                  width: '80px',
                  padding: '1px 10px',
                  borderRadius: '5px',
                  textTransform: 'capitalize',
                }}
              >
                {p.listingType?.replace(/_/g, ' ')}
              </ListingTypeButton>
              <PracticeSubtext style={{ fontWeight: 'bold' }}>
                {formatPriceText(p, isAuthenticated, hasCreatedValuation)}
              </PracticeSubtext>
              <PracticeSubtext style={{ fontWeight: 'bold' }}>
                Annual Billings:{' '}
                {getAbbreviatedMoneyString(p.averageAnnualBillings)}
              </PracticeSubtext>
              <PracticeSubtext style={{ fontWeight: 'bold' }}>
                Operatories: {p.operatories}
              </PracticeSubtext>
              {!hasCreatedValuation && (
                <RevealPriceButton onClick={onClickRevealPrice} />
              )}
            </PracticeTextContainer>
          </PracticeThumbnailContentContainer>
        ))}
      </ScrollablePracticesList>
    </PracticeThumbnailContainerWithMultiplePractices>
  )
}

export default PracticePreviewThumbnail
