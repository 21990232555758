import MuiSearchIcon from '@mui/icons-material/Search'
import MuiIconButton from '@mui/material/IconButton'
import MuiDeleteIcon from '@mui/icons-material/Delete'
import InputAdornment from '@mui/material/InputAdornment'
import styled, { css } from 'styled-components/macro'
import {
  StyledMenuItem,
  TextInput,
} from '../../../styles/shared-styled-components'
import { searchFilterHeight } from '../SearchFilters/styled'

// MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-outlined css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root
export const LocationSearchInput = styled(TextInput)`
  .MuiOutlinedInput-root {
    height: ${searchFilterHeight};
    padding-right: ${(props) => (props.ismobilescreen ? '9px' : '4px')};
    background-color: ${(props) =>
      props.transparentbackground === 'false' && 'white'};
  }
  // If we have a non-transparent background for the search box, that probably means we have an image in the background
  // For this reason we want the label to be contained within the input, so we move the label down
  .MuiInputLabel-root.Mui-focused {
    margin-top: ${(props) => props.transparentbackground === 'false' && '7px'};
  }
  // Making sure the shrunk input label is large and has padding
  .MuiOutlinedInput-notchedOutline {
    font-size: ${(props) =>
      props.theme.typography.titleFontSizeInputLabelWithSpace} !important;
  }
  .MuiInputLabel-shrink {
    font-size: ${(props) =>
      props.theme.typography.titleFontSizeInputLabel} !important;
  }
`

export const EndInputAdornment = styled(InputAdornment).attrs({
  position: 'end',
})`
  padding: 0px;
  margin: 0px;
`

export const IconButton = styled(MuiIconButton)`
  padding: 0px 2px;

  &.danger {
    background: ${({ theme }) => theme.color.error};

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  &.rect {
    border-radius: 0px;
    padding: 3px 5px;
  }
`

export const SearchIcon = styled(MuiSearchIcon)`
  color: ${({ theme }) => theme.color.primary};
`

export const DeleteIcon = styled(MuiDeleteIcon)`
  color: ${({ theme }) => theme.color.error};
`

export const LocationSuggestionWidthStyle = css`
  width: ${(props) => (props.fixedWidth ? props.fixedWidth : 330)}px;
  ${(props) =>
    props.ismobilescreen &&
    !props.fixedWidth &&
    css`
      left: 10px !important;
    `}
`

export const LocationSuggestion = styled(StyledMenuItem)`
  width: max-content;
  min-width: 100%;
  background-color: ${(props) =>
    props.hovered && props.theme.color.lightPrimary};
  &:hover {
    background-color: ${(props) => props.theme.color.lightPrimary};
  }
`
