import { useQueryWithErrorLogging } from '../../utils/hooks'
import get from 'lodash/get'
import find from 'lodash/find'
import { sortPracticeData } from '../../utils/data'
import {
  GET_ALL_PRACTICE_LISTINGS,
  GET_MY_PRACTICES,
  GET_PRACTICE_BY_ID,
  GET_PRACTICE_DATAROOM,
  GET_SOLD_PRACTICE_LISTINGS,
} from '../queries/practices'
import { useEffect, useMemo } from 'react'
import { useAcquisition } from './acquisitions'
import { useMyUser } from './users'
import { useQuery } from '@apollo/client'
import Bugsnag from '@bugsnag/js'

export const useAllPracticeListings = () => {
  const { data, error, loading, refetch } = useQueryWithErrorLogging(
    GET_ALL_PRACTICE_LISTINGS,
    {
      fetchPolicy: 'network-only',
    },
  )

  let practiceData = get(data, 'getAllPracticeListings', [])
  let otherData = data?.getAllPracticeListings || []

  if (practiceData.length) {
    practiceData = sortPracticeData(practiceData)
  }

  return {
    error,
    loading,
    refetch,
    practices: practiceData,
  }
}

export const useSoldPracticeListings = () => {
  const { data, error, loading, refetch } = useQueryWithErrorLogging(
    GET_SOLD_PRACTICE_LISTINGS,
    {
      fetchPolicy: 'network-only',
    },
  )

  let practiceData = get(data, 'getSoldPracticeListings', [])
  if (practiceData.length) {
    practiceData = sortPracticeData(practiceData)
  }

  return {
    error,
    loading,
    refetch,
    practices: practiceData,
  }
}

export const useMyPractices = () => {
  const { data, error, loading, refetch } = useQueryWithErrorLogging(
    GET_MY_PRACTICES,
    {
      fetchPolicy: 'cache-first',
    },
  )

  let practiceData = get(data, 'getMyPractices', [])
  if (practiceData.length) {
    practiceData = sortPracticeData(practiceData)
  }

  return {
    error,
    loading,
    refetch,
    practices: practiceData,
  }
}

export const usePracticeById = (practiceId) => {
  const { data, error, loading, refetch } = useQueryWithErrorLogging(
    GET_PRACTICE_BY_ID,
    {
      variables: {
        id: practiceId,
      },
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
      skip: !practiceId,
    },
  )

  if (error) {
    console.log('apollo error', error)
    console.log('apollo data', data)
    console.log('apollo loading', loading)
  }

  return {
    error,
    loading,
    refetch,
    practice: get(data, 'getPracticeById', null),
  }
}

export const usePracticeDataroom = (practiceId) => {
  const useMyUserResults = useMyUser()
  const shouldRunQuery =
    !!practiceId && useMyUserResults.auth0Context.isAuthenticated

  useEffect(() => {
    if (shouldRunQuery) {
      refetch()
    }
  }, [practiceId, shouldRunQuery])

  const skip = !shouldRunQuery
  const query = GET_PRACTICE_DATAROOM
  const options = {
    variables: {
      id: practiceId,
    },
    fetchPolicy: 'network-only',
    skip,
  }
  const { data, error, loading, refetch } = useQuery(query, options)
  if (error) {
    if (
      error.message ===
      'You do not have access to this data. Please place a dataroom access request'
    ) {
      console.debug('User does not have access to practice dataroom')
    } else {
      console.log(error)
      Bugsnag.notify(error)
    }
  }

  return {
    error,
    loading,
    refetch,
    dataroom: get(data, 'getPracticeDataroom', null),
  }
}

export const usePracticePurchaseIntent = (practiceId) => {
  const { purchaseIntents, loading, error } = useAcquisition()

  const purchaseIntent = useMemo(() => {
    return find(purchaseIntents, (data) => data.practice_id === practiceId)
  }, [practiceId, purchaseIntents])

  return {
    purchaseIntent,
    loading,
    error,
  }
}
