export const concatNames = (first, last) => {
  return `${first} ${last}`
}

export const parseName = (fullName) => {
  const splitName = fullName.split(' ')

  let parsedFirstName
  let parsedlastName

  if (splitName.length > 1) {
    parsedlastName = splitName.pop()
    parsedFirstName = splitName.join(' ')
  } else {
    parsedlastName = ''
    parsedFirstName = splitName.pop()
  }

  return {
    parsedFirstName,
    parsedlastName,
  }
}

export const getCapitalizedWord = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export const getFormattedUsername = (username, isSmallScreen) => {
  const capitalizedUserName = getCapitalizedWord(username)
  const truncated =
    isSmallScreen && capitalizedUserName.length > 8
      ? capitalizedUserName.slice(0, 5) + '...'
      : capitalizedUserName
  return truncated
}

export const defaultPracticeName = 'Untitled Practice'

export const getEllipsedPracticeName = (name, width) => {
  const maxNumChars =
    width < 636
      ? 10
      : width < 693
        ? 15
        : width < 736
          ? 20
          : width < 785
            ? 25
            : width < 840
              ? 30
              : width < 890
                ? 35
                : width < 946
                  ? 40
                  : width < 983
                    ? 45
                    : 50

  return name?.length > maxNumChars
    ? `${name.slice(0, maxNumChars).trim()}...`
    : name
}

export const camelCaseToSpacedString = (camelCaseString) => {
  return camelCaseString
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (match) => match.toUpperCase())
    .trim()
}

export const getFileNameFromUrl = (fileUrl) => {
  const regex = /\/([^\/]+)$/
  const match = fileUrl?.match(regex)

  return match ? match[1] : null
}

export default {
  concatNames,
  parseName,
  getFormattedUsername,
  getCapitalizedWord,
  defaultPracticeName,
  getEllipsedPracticeName,
  camelCaseToSpacedString,
  getFileNameFromUrl,
}
