import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  split,
} from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/link-context'
import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect, useMemo, useState } from 'react'
import { Paths } from './Paths'
import useLocalStorageState from 'use-local-storage-state'
import { ACT_AS_USER_LOCAL_STORAGE_KEY } from './utils/constants'

const ApolloProviderWithToken = ({ children }) => {
  const { getAccessTokenSilently, user, logout } = useAuth0()
  const [userToken, setUserToken] = useState()
  const [actAsUser] = useLocalStorageState(ACT_AS_USER_LOCAL_STORAGE_KEY, null)

  const reactEnv = process.env.REACT_APP_ENV
  const httpLink = useMemo(
    () =>
      createHttpLink({
        uri:
          reactEnv === 'local'
            ? Paths.localConnectBackend
            : reactEnv === 'staging'
              ? Paths.stagingConnectBackend
              : Paths.prodConnectBackend,
      }),
    [reactEnv],
  )

  const wsLink = useMemo(() => {
    return new WebSocketLink({
      uri:
        reactEnv === 'local'
          ? Paths.localConnectBackendWebsocket
          : reactEnv === 'staging'
            ? Paths.stagingConnectBackendWebsocket
            : Paths.prodConnectBackendWebsocket,
      options: {
        reconnect: true,
        connectionParams: {
          authorization: userToken ? `Bearer ${userToken}` : null,
        },
      },
    })
  }, [reactEnv, userToken])

  const splitLink = useMemo(() => {
    return split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        )
      },
      wsLink,
      httpLink,
    )
  }, [wsLink, httpLink])

  const authLink = useMemo(() => {
    return setContext((_, { headers }) => {
      if (!userToken) {
        return headers
      }
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${userToken}`,
          'act-as-user': actAsUser?.id,
        },
      }
    })
  }, [userToken, actAsUser])

  const client = useMemo(() => {
    return new ApolloClient({
      link: authLink.concat(splitLink),
      cache: new InMemoryCache(),
      connectToDevTools: true,
    })
  }, [authLink, splitLink])

  useEffect(() => {
    ;(async () => {
      if (user) {
        try {
          const res = await getAccessTokenSilently()
          setUserToken(res)
        } catch (e) {
          if (e.message === 'Login required.') {
            await logout()
          }
        }
      }
    })()
  }, [user, getAccessTokenSilently, logout])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default ApolloProviderWithToken
