import { whiteGloveServiceRate, discountedCommissionRate } from './constants'
import {
  checkIfPracticeIncludesFinancials,
  checkIfPracticeIncludesPictures,
  findIndexOfKeyInMap,
} from './data'
import {
  createPracticeSectionPathMap,
  dueDiligenceSectionPathMap,
  allPracticeSectionPathMap,
} from './routing'
import { getCompletedDueDiligenceSteps } from './data'

export const arrowSeparatorString = ' → '

export const toSeparatorString = ' to '

const defaultNumDecimals = 2

export const getAbbreviatedMoneyString = (
  n,
  numDecimals = defaultNumDecimals,
) => {
  if (n < 1e3) return '$' + n.toFixed(0)
  if (n >= 1e3 && n < 1e6) return '$' + +(n / 1e3).toFixed(0) + 'K'
  if (n >= 1e6 && n < 1e9) return '$' + +(n / 1e6).toFixed(numDecimals) + 'M'
  if (n >= 1e9 && n < 1e12) return '$' + +(n / 1e9).toFixed(numDecimals) + 'B'
  if (n >= 1e12) return '$' + +(n / 1e12).toFixed(numDecimals) + 'T'
}

export const getAbbreviatedMoneyStringRange = (lower, upper) => {
  return `${getAbbreviatedMoneyString(
    lower,
  )}${arrowSeparatorString}${getAbbreviatedMoneyString(upper)}`
}

export const getAnonymizedAndAbbreviatedMoneyString = (
  n,
  numDecimals = defaultNumDecimals,
) => {
  const moneyString = getAbbreviatedMoneyString(n, numDecimals)

  const unit = moneyString.slice(-1)

  return `$***${unit}`
}

export const getFormattedMoneyString = (n, numDecimals = 2) => {
  if (n != null) {
    return `$${n.toLocaleString(undefined, {
      maximumFractionDigits: numDecimals,
    })}`
  }
}

export const addCommasToNum = (num) => {
  let number = num
  if (typeof num === 'string') {
    number = parseFloat(num)
  }
  return number?.toLocaleString()
}

export const getFormattedMoneyStringWithNoCents = (n) => {
  if (n != null) {
    return `$${n.toLocaleString(undefined, { maximumFractionDigits: 0 })}`
  }
}

export const getFormattedMoneyStringRange = (lower, upper) => {
  return `${getFormattedMoneyStringWithNoCents(
    lower,
  )}${toSeparatorString}${getFormattedMoneyStringWithNoCents(upper)}`
}

export const getNearestMinimumPowerOfTen = (n) => {
  return Math.pow(10, Math.floor(Math.log10(n)))
}

export const getIntFromCurrency = (currency) => {
  return parseInt(currency.toString().replaceAll(',', ''))
}

export const getFloatFromPercentage = (percentage) => {
  return parseInt(percentage) / 100
}

const indexOfPicturesStep = 4
const indexOfFinancialsStep = 5

export const getDueDiligenceCompletionPercentage = (practice) => {
  if (!practice) {
    return null
  }

  let totalSections = 0
  let completedSections = 0

  // Due diligence
  const formSteps = getCompletedDueDiligenceSteps(practice?.dueDiligence)
  const completedFormSteps = formSteps.filter((step) => step.stepCompleted)

  completedSections += completedFormSteps ? completedFormSteps.length : 0
  totalSections += formSteps ? formSteps.length : 0

  return Math.round((completedSections / totalSections) * 100)
}

export const getPracticeCompletionPercentage = (practice) => {
  if (!practice) {
    return null
  }

  const { sectionCurrentlyEditing } = practice

  const totalNumSections = allPracticeSectionPathMap.size

  let sectionIndex = findIndexOfKeyInMap(
    allPracticeSectionPathMap,
    sectionCurrentlyEditing,
  )

  let updatedSectionIndex = sectionIndex

  // Check if they've filled in the skippable fields
  if (sectionIndex > indexOfPicturesStep) {
    const completedPicturesSection = checkIfPracticeIncludesPictures(practice)
    if (!completedPicturesSection) {
      updatedSectionIndex -= 1
    }

    if (sectionIndex > indexOfFinancialsStep) {
      const completedFinancialsSection =
        checkIfPracticeIncludesFinancials(practice)
      if (!completedFinancialsSection) {
        updatedSectionIndex -= 1
      }
    }
  }

  // Add 1 since it's an index
  return parseInt(((updatedSectionIndex + 1) / totalNumSections) * 100)
}

export const getMeanOfRange = (lower, upper) => {
  if (!lower && !upper) {
    return 0
  }

  return parseInt((lower + upper) / 2)
}

export const getEstimatedCommissionDiscountSavings = (
  lowerBoundValuation,
  upperBoundValuation,
) => {
  const averageValuation = getMeanOfRange(
    lowerBoundValuation,
    upperBoundValuation,
  )
  const currentCommissionRate = whiteGloveServiceRate
  const estimatedCurrentCommission =
    averageValuation * (currentCommissionRate / 100)

  const commissionWithDiscount =
    averageValuation * (discountedCommissionRate / 100)

  return estimatedCurrentCommission - commissionWithDiscount
}

export const parseRangeStringIntoArrayOfInts = (rangeString) => {
  const arr = rangeString.split(',')

  return arr.map((value) => parseInt(value))
}

export const formatNumberAbbreviation = (number) => {
  if (number === 0) return '0'
  if (!number) return
  if (number >= 1000000) {
    const formattedNumber = (number / 1000000).toFixed(1)
    return formattedNumber.endsWith('.0')
      ? formattedNumber.slice(0, -2) + 'M'
      : formattedNumber + 'M'
  } else if (number >= 1000) {
    const formattedNumber = (number / 1000).toFixed(1)
    return formattedNumber.endsWith('.0')
      ? formattedNumber.slice(0, -2) + 'K'
      : formattedNumber + 'K'
  } else {
    return number?.toString()
  }
}

export const formatFileSize = (size) => {
  if (typeof size !== 'number') {
    size = parseFloat(size)
    if (isNaN(size)) {
      console.log(
        `formatFileSize received invalid input of ${size}. Please provide a valid number or string representing a file size in bytes.`,
      )
      return 'N/A'
    }
  }

  const kilobyte = 1024
  const megabyte = kilobyte * 1024
  const gigabyte = megabyte * 1024

  if (size < kilobyte) {
    return size + ' B'
  } else if (size < megabyte) {
    return (size / kilobyte).toFixed(2) + ' KB'
  } else if (size < gigabyte) {
    return (size / megabyte).toFixed(2) + ' MB'
  } else {
    return (size / gigabyte).toFixed(2) + ' GB'
  }
}

export default {
  arrowSeparatorString,
  getAbbreviatedMoneyString,
  getAbbreviatedMoneyStringRange,
  getFormattedMoneyString,
  getFormattedMoneyStringWithNoCents,
  getFormattedMoneyStringRange,
  getNearestMinimumPowerOfTen,
  getIntFromCurrency,
  getFloatFromPercentage,
  getMeanOfRange,
  getEstimatedCommissionDiscountSavings,
  parseRangeStringIntoArrayOfInts,
  getAnonymizedAndAbbreviatedMoneyString,
  formatNumberAbbreviation,
  formatFileSize,
}
