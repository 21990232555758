import styled from 'styled-components/macro'
import {
  BaseDiv,
  BasePadding,
  BaseWidth,
  PrimaryButton,
  RowFlexStyle,
  SecondaryButton,
} from '../../../styles/shared-styled-components'

export const NavigationButtonContainer = styled.div`
  ${RowFlexStyle}
  ${BasePadding}
  ${BaseWidth}
  justify-content: space-between;
  align-items: center;
  bottom: 0px;
  right: 0px;
  padding: 20px;
  z-index: 100;
  background-color: ${({ theme }) => theme.color.white};
`

const buttonWidth = '152px'

export const BackButton = styled(SecondaryButton)`
  height: fit-content;
  width: ${buttonWidth};
`

export const NextButton = styled(PrimaryButton)`
  height: fit-content;
  width: ${buttonWidth};
  background-color: ${(props) =>
    props.nextEnabled ? props.theme.color.primary : props.theme.color.disabled};
  color: ${(props) =>
    props.nextEnabled ? props.theme.color.white : props.theme.color.lightGrey};
  cursor: ${(props) => (props.nextEnabled ? 'pointer' : 'default')};
  &:hover {
    background-color: ${(props) =>
      props.nextEnabled
        ? props.theme.color.darkHover
        : props.theme.color.disabled};
  }
`
