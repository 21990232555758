import { useMutationWithErrorLogging } from '../../utils/hooks'
import { useQueryWithErrorLogging } from '../../utils/hooks'
import get from 'lodash/get'
import { GET_BUYERS, GET_BUYER_BY_ID } from '../queries/buyers'
import { UPDATE_BUYER } from '../mutations/buyers'
import _ from 'lodash'
import { useMyUser } from './users'

export const useBuyers = () => {
  const useMyUserResults = useMyUser()
  const [updateBuyerMutation] = useMutationWithErrorLogging(UPDATE_BUYER)
  const { data, error, loading, refetch } = useQueryWithErrorLogging(
    GET_BUYERS,
    {
      fetchPolicy: 'network-only',
      skip: !useMyUserResults.auth0Context.isAuthenticated,
    },
  )

  const buyerData = get(data, 'getBuyers', [])
  const orderedData = _.orderBy(buyerData, 'createdAt', ['desc'])

  const updateBuyer = (id, data = {}) => {
    updateBuyerMutation({
      variables: {
        id,
        ...data,
      },
      onCompleted: async (data) => {
        console.log('handleUpdate =>', data)
        refetch()
      },
    })
  }

  return {
    error,
    loading,
    refetch,
    buyers: orderedData,
    updateBuyer,
  }
}

export const useBuyerById = (buyerId) => {
  const { data, error, loading, refetch } = useQueryWithErrorLogging(
    GET_BUYER_BY_ID,
    {
      variables: {
        id: buyerId,
      },
      fetchPolicy: 'cache-first',
      skip: !buyerId,
    },
  )

  return {
    error,
    loading,
    refetch,
    buyer: get(data, 'getBuyerById', null),
  }
}
