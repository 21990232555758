import styled, { css } from 'styled-components/macro'
import {
  AutoHorizontalMargins,
  Dropdown,
  ErrorMessageContainerBelowField as EMCBF,
  FormControlLabel,
  FormGroup,
  IndentedContentContainer,
  IndentedMargin,
  InterSubsectionalGap,
  InterSubsectionalGapValue,
  PrimaryButton,
  SecondaryButton,
  TitleText,
  TitleTextInput,
  TitleTextInputStyle,
} from '../../styles/shared-styled-components'

export const PageContentContainer = styled.div`
  display: flex;
`

export const OnboardingFormContainer = styled(IndentedContentContainer)`
  padding-right: ${(props) => !props.ismobilescreen && '50px'};
  align-items: ${(props) => props.ismobilescreen && 'center'};
  flex: 1;
  ${(props) =>
    props.ismobilescreen &&
    css`
      ${AutoHorizontalMargins}
    `}
  // Combined these flags make sure the top and bottom sections are aligned from page-to-page
  height: 80vh;
  max-height: 550px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
`

export const AssetContainer = styled.div`
  ${IndentedMargin}
  margin-right: 90px;
  padding: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 2;
`

export const Asset = styled.img`
  width: 100%;
  max-width: 520px;
  min-width: 400px;
`

export const FormTitleText = styled(TitleText)`
  text-align: ${(props) => !props.ismobilescreen && 'start'};
  line-height: ${({ theme }) => theme.typography.titleLineHeight};
`

const OnboardingElementWidth = css`
  width: 80vw;
  max-width: 450px;
`

export const OnboardingElementStyle = css`
  ${TitleTextInputStyle}
  ${OnboardingElementWidth}
`

export const OnboardingInputField = styled(TitleTextInput)`
  ${OnboardingElementStyle}
  ${TitleTextInputStyle}
`

const OnboardingButtonStyle = css`
  height: 50px;
  ${OnboardingElementWidth}
`

export const OnboardingForm = styled.div`
  display: flex;
  flex-direction: column;
  ${InterSubsectionalGap}
  align-items: ${(props) => props.ismobilescreen && 'center'};
  min-height: 250px;
  flex: 1;
  overflow-y: auto;
  ${OnboardingElementWidth}
`

export const ContinueButton = styled(PrimaryButton)`
  ${OnboardingButtonStyle}
`

export const BackButton = styled(SecondaryButton)`
  ${OnboardingButtonStyle}
`

export const OnboardingFormGroupContainer = styled.div`
  ${OnboardingElementWidth}
`

export const OnboardingFormGroup = styled(FormGroup)`
  padding: 0px;
  ${TitleTextInputStyle}
  .MuiTypography-root {
    font-size: ${({ theme }) => theme.typography.titleFontSize};
  }
  width: fit-content;
`

export const OnboardingFormControlLabel = styled(FormControlLabel)``

export const OnboardingDropdown = styled(Dropdown)`
  ${OnboardingElementWidth}
  font-size: ${({ theme }) => theme.typography.titleFontSize};
`

// Used shorthand because name was so long that the styled() block would break up into multiple lines,
// which was making the 'margin-top' property have weird string formatting
export const ErrorMessageContainerAccountingForFlexGap = styled(EMCBF)`
  margin-top: ${(props) =>
    props.ismobilescreen ? '-10px' : `-${InterSubsectionalGapValue}`};
`
