import { css } from 'styled-components'
// Imported as NPM package
import '@fontsource/noto-sans-kr'

const typography = {
  title: css`
    font-size: 16px;
    font-stretch: 50%;
    font-weight: 500;
    letter-spacing: 1px;
  `,
  microFontSize: '12px',
  smallFontSize: '13px',
  sentenceFontSize: '14px',
  sentenceLargeFontSize: '20px',
  defaultFontSize: '15px',
  largeInputTextFontSize: '16px',
  // Slightly smaller than title font size, for use by input labels to have some padding
  titleFontSizeInputLabel: '17.5px',
  titleFontSize: '18px',
  titleFontSizeInputLabelWithSpace: '18.5px',
  pageSubtitleFontSize: '22px',
  pageTitleFontSize: '26px',
  pageTitleMediumFontSize: '24px',
  hugeFontSize: '40px',
  titleLineHeight: 1.335,
  boldWeight: 500,
  technoFont: 'monospace',
  typeFont: 'Noto Sans KR',
}

export default typography
