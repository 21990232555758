import {
  CheckOutlined,
  DownloadingOutlined,
  ErrorOutline,
  MailOutline,
  MailOutlined,
  MessageOutlined,
} from '@mui/icons-material'
import _ from 'lodash'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { usePracticeDataroom } from '../../api/hooks/practices'
import LoadingIndicator from '../../components/LoadingIndicator'
import { Box } from '../../components/Micro/PurchaseIntentDialog/styled'
import Header from '../../components/NavBar/Header'
import color from '../../styles/color'
import {
  BackgroundContainer,
  ErrorText,
  PageContainer,
  TitleText,
} from '../../styles/shared-styled-components'
import { camelCaseToSpacedString, getFileNameFromUrl } from '../../utils/naming'
import {
  CancelButton,
  FullPageFlexContainer,
  NextButton,
  ResponsiveFlexContainer,
  Wrapper,
} from '../BuyerProfile/styled'
import { ActionButton, Table, TableCell, TableHeader, TableRow } from './styled'
import { BuyerButtons } from '../../components/Micro/BuyerProfile/styled'

const ddSections = {
  practiceProfile: {
    key: 'practiceProfile',
    title: 'Practice Profile',
  },
  accounting: {
    key: 'accounting',
    title: 'Accounting',
  },
  legal: {
    key: 'legal',
    title: 'Legal',
  },
  practiceManagementReports: {
    key: 'practiceManagementReports',
    title: 'Practice Management Reports',
  },
  humanResource: {
    key: 'humanResource',
    title: 'Human Resource',
  },
}

const display = (dd, selectedGroup) => {
  switch (selectedGroup) {
    case 'practiceProfile':
      return displayPracticeProfile(dd)
    case 'accounting':
      return displayAccounting(dd)
    case 'legal':
      return displayLegal(dd)
    case 'practiceManagementReports':
      return displayPracticeManagementReports(dd)
    case 'humanResource':
      return displayHumanResource(dd)
    default:
      console.log(`Invalid selectedGroup: ${selectedGroup}`)
  }
}

const displayPracticeProfile = (dd) => {
  const practiceProfile = dd?.practiceProfile
  return (
    <Table>
      <thead>
        <tr>
          <TableHeader colSpan={2}>Operatories</TableHeader>
        </tr>
      </thead>
      <tbody>
        {Object.keys(practiceProfile?.opsPurposes).map((key, i) => {
          return (
            <TableRow>
              <TableCell>{i + 1}</TableCell>
              <TableCell>{practiceProfile?.opsPurposes[key]}</TableCell>
            </TableRow>
          )
        })}
      </tbody>
    </Table>
  )
}

const displayAccounting = (dd) => {
  const accounting = dd?.accounting
  return (
    <Table>
      <TableHeader>
        <TableCell>Financial Statement</TableCell>
        <TableCell>General Ledgers</TableCell>
        <TableCell>Income Statements</TableCell>
        <TableCell>Trial Balances</TableCell>
        <TableCell>Forecast</TableCell>
      </TableHeader>
      <TableRow>
        <TableCell>
          {accounting?.financialStatement?.map((url) => (
            <ActionButton
              key={url}
              href={url}
              target='_blank'
              download={getFileNameFromUrl(url)}
            >
              <DownloadingOutlined />
              {getFileNameFromUrl(url)}
            </ActionButton>
          ))}
        </TableCell>
        <TableCell>{accounting?.generalLedgers}</TableCell>
        <TableCell>{accounting?.incomeStatements}</TableCell>
        <TableCell>{accounting?.trialBalances}</TableCell>
        <TableCell>{accounting?.forecast}</TableCell>
      </TableRow>
    </Table>
  )
}

const displayLegal = (dd) => {
  const legal = dd?.legal
  return (
    <Table>
      <TableHeader>
        <TableCell>Legal</TableCell>
        <TableCell>Permise Rental Lease</TableCell>
        <TableCell>Vendor Contracts</TableCell>
        <TableCell>Equipment Rentals</TableCell>
        <TableCell>Regulatory Compliance</TableCell>
      </TableHeader>
      <TableRow>
        <TableCell>{legal?.legal}</TableCell>
        <TableCell>{legal?.permiseRentalLease}</TableCell>
        <TableCell>{legal?.vendorContracts}</TableCell>
        <TableCell>{legal?.equipmentRentals}</TableCell>
        <TableCell>{legal?.regulatoryCompliance}</TableCell>
      </TableRow>
    </Table>
  )
}

const displayPracticeManagementReports = (dd) => {
  const practiceManagementReports = dd?.practiceManagementReports
  return (
    <Table>
      <TableHeader>
        <TableCell>Total Annual Collections</TableCell>
        <TableCell>Total Monthly Collections</TableCell>
        <TableCell>Procedure Codes Per Month</TableCell>
        <TableCell>Procedure Codes Per Year</TableCell>
        <TableCell>Aged Acc Rec Total Practice</TableCell>
        <TableCell>Aged Acc Rec Provider</TableCell>
        <TableCell>Patient Count</TableCell>
        <TableCell>Referred Out Cases</TableCell>
      </TableHeader>
      <TableRow>
        <TableCell>
          {practiceManagementReports?.totalAnnualCollections}
        </TableCell>
        <TableCell>
          {practiceManagementReports?.totalMonthlyCollections}
        </TableCell>
        <TableCell>
          {practiceManagementReports?.procedureCodesPerMonth}
        </TableCell>
        <TableCell>
          {practiceManagementReports?.procedureCodesPerYear}
        </TableCell>
        <TableCell>
          {practiceManagementReports?.agedAccRecTotalPractice}
        </TableCell>
        <TableCell>{practiceManagementReports?.agedAccRecProvider}</TableCell>
        <TableCell>
          {practiceManagementReports?.patientCount?.activePatients} active
          patients,{' '}
          {practiceManagementReports?.patientCount?.newPatientsPermonth} new
          patients per month
        </TableCell>
        <TableCell>
          {
            practiceManagementReports?.referredOutCases
              ?.avgReferralsToOrthodontistPerMonth
          }{' '}
          avg referrals to orthodontist per month,{' '}
          {
            practiceManagementReports?.referredOutCases
              ?.avgReferralsToPerioOralSurgeonPerMonth
          }{' '}
          avg referrals to perio oral surgeon per month,{' '}
          {
            practiceManagementReports?.referredOutCases
              ?.avgReferralsToEndodontistPerMonth
          }{' '}
          avg referrals to endodontist per month,{' '}
          {
            practiceManagementReports?.referredOutCases
              ?.avgReferralsToPediatricDentistPerMonth
          }{' '}
          avg referrals to pediatric dentist per month
        </TableCell>
      </TableRow>
    </Table>
  )
}

const displayHumanResource = (dd) => {
  const humanResource = dd?.humanResource
  return (
    <Table>
      <TableHeader>
        <TableCell>Employees Spreadsheet</TableCell>
        <TableCell>Employees</TableCell>
      </TableHeader>
      <TableRow>
        <TableCell>{humanResource?.employeesSpreadsheet}</TableCell>
        <TableCell>{humanResource?.employees}</TableCell>
      </TableRow>
    </Table>
  )
}

const handleback = () => {
  window.history.back()
}

const Dataroom = ({ user, width, ismobilescreen }) => {
  // TODO(Aaron): finish this page
  return (
    <>
      <Header user={user} width={width} ismobilescreen={ismobilescreen} />
      <BackgroundContainer>
        <PageContainer>
          <Box style={{ textAlign: 'center', margin: 30 }}>
            <MailOutlined
              style={{ fontSize: '100px', color: color.mediumPrimary }}
            />
            <TitleText style={{ textAlign: 'center', fontSize: '25px' }}>
              A Dentacloud staff member will be in touch to give you access to
              this practice's dataroom
            </TitleText>
          </Box>
          <BuyerButtons>
            <NextButton onClick={handleback}>Back</NextButton>
          </BuyerButtons>
        </PageContainer>
      </BackgroundContainer>
    </>
  )

  const { practiceId } = useParams()
  const { dataroom, error, loading } = usePracticeDataroom(practiceId)
  const [selectedGroup, setSelectedGroup] = useState(Object.keys(ddSections)[0]) // defulat to first group
  const dd = dataroom?.dueDiligence
  console.log(dd)

  return loading ? (
    <LoadingIndicator fullScreen />
  ) : (
    <>
      <Header user={user} width={width} ismobilescreen={ismobilescreen} />
      <BackgroundContainer>
        <PageContainer>
          {error ? (
            <Box style={{ textAlign: 'center', margin: 30 }}>
              <ErrorOutline style={{ fontSize: '100px', color: '#D32F2F' }} />
              <ErrorText style={{ textAlign: 'center', fontSize: '25px' }}>
                {error?.message}
              </ErrorText>
            </Box>
          ) : (
            <>
              <FullPageFlexContainer>
                <Wrapper
                  style={{
                    width: 'calc(100% - 50px)',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <TitleText>Dataroom</TitleText>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      gap: '20px',
                      flex: 1,
                    }}
                  >
                    <div
                      style={{
                        border: '1px solid rgba(0, 0, 0, 0.10)',
                        minWidth: '200px',
                        minHeight: '150px',
                        borderRadius: '4px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        padding: '20px 10px',
                      }}
                    >
                      {Object.keys(ddSections).map((sectionKey, i) => (
                        <div
                          key={i}
                          onClick={() => setSelectedGroup(sectionKey)}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            fontFamily: 'Noto Sans KR',
                            cursor: 'pointer',
                            gap: 5,
                            padding: '5px',
                            background:
                              selectedGroup === sectionKey
                                ? color.lightHover
                                : 'none',
                            borderRadius:
                              selectedGroup === sectionKey ? '9px' : '0',
                            fontWeight:
                              selectedGroup === sectionKey ? 'bold' : 'normal',
                          }}
                        >
                          <div
                            style={{
                              width: '30px',
                              height: '30px',
                              background: '#016299',
                              borderRadius: '50%',
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'center',
                              color: '#FFF',
                              fontSize: '12px',
                            }}
                          >
                            {i + 1}
                          </div>
                          <div
                            style={{
                              flex: 1,
                              color: '#016299',
                              fontFamily: 'Noto Sans KR',
                              fontSize: '14px',
                              textTransform: 'capitalize',
                            }}
                          >
                            {ddSections[sectionKey].title}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        flexWrap: 'wrap',
                        gap: '20px',
                      }}
                    >
                      {display(dd, selectedGroup)}
                    </div>
                  </div>
                </Wrapper>
                <ResponsiveFlexContainer block footer>
                  <CancelButton onClick={() => window.history.back()}>
                    Back
                  </CancelButton>
                </ResponsiveFlexContainer>
              </FullPageFlexContainer>
            </>
          )}
        </PageContainer>
      </BackgroundContainer>
    </>
  )
}

export default Dataroom
