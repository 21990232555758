import React, { useState, useCallback } from 'react'
import {
  ColumnButtonContainer,
  Modal,
  ModalActions,
  ModalContainer,
  ModalContent,
  ModalContentText,
  ModalHeaderContainer,
  PrimaryModalActionButton,
  TitleText,
  StyledCheckbox,
} from '../../styles/shared-styled-components'
import { useMutationWithErrorLogging } from '../../utils/hooks'
import { GET_MY_USER } from '../../api/queries/users'
import { useApolloClient } from '@apollo/client'
import { RECORD_SIGNED_NDA } from '../../api/mutations/users'

const SignNdaModal = ({ isOpen, user }) => {
  const { cache } = useApolloClient()

  const [signedNda, setSignedNda] = useState(false)

  const [editSignedNdaMutation, { loading }] =
    useMutationWithErrorLogging(RECORD_SIGNED_NDA)

  const onClose = useCallback(() => {
    if (!signedNda) {
      return
    }
    editSignedNdaMutation({
      variables: {},
      onCompleted: async (data) => {
        const updatedUser = {
          ...user,
          signedNda: true,
        }

        // Update the cache
        cache.writeQuery({
          query: GET_MY_USER,
          data: {
            getMyUser: updatedUser,
          },
        })
      },
    })
  }, [user, cache, editSignedNdaMutation, signedNda])

  return (
    <Modal open={isOpen}>
      <ModalContainer>
        <ModalHeaderContainer>
          <TitleText>Dentacloud Non-Disclosure Agreement</TitleText>
        </ModalHeaderContainer>
        <ModalContent>
          <ModalContentText>
            Hi Dentacloud Connect User!
            <br></br>
            <br></br>
            To continue using this platform, please check the box to agree to{' '}
            <a
              href={`${process.env.PUBLIC_URL}/dentacloud_nda_2024.pdf`}
              target='_blank'
              rel='noopener noreferrer'
            >
              Dentacloud's non-disclosure agreement
            </a>{' '}
            (NDA).
            <StyledCheckbox
              style={{ display: 'inline', paddingBottom: '1rem' }}
              checked={signedNda}
              onChange={(e) => setSignedNda(e.target.checked)}
            ></StyledCheckbox>
            <br></br>
            <br></br>
            This is a necessary step to ensure that all users understand and
            commit to protecting confidential information. Thank you for your
            cooperation.
          </ModalContentText>
        </ModalContent>
        <ModalActions>
          <ColumnButtonContainer>
            <PrimaryModalActionButton
              onClick={onClose}
              disabled={!signedNda || loading}
            >
              {loading ? 'Please wait...' : 'Continue'}
            </PrimaryModalActionButton>
          </ColumnButtonContainer>
        </ModalActions>
      </ModalContainer>
    </Modal>
  )
}

export default SignNdaModal
