import styled from 'styled-components/macro'
import {
  LargeInputText,
  ColumnFlexStyle,
} from '../../../styles/shared-styled-components'

export const BuyerProfileInputGroup = styled.div`
  margin: 0;
  padding: 0;
  margin-bottom: 1.25em;
  padding: 0.125em;
  border: 1px ${({ theme }) => theme.color.primary} solid;
  border-radius: ${({ theme }) => theme.spacing.baseBorderRadius}px;

  &:hover {
    border-color: ${({ theme }) => theme.color.black};
  }
`

export const BuyerProfileInputTitle = styled(LargeInputText)`
  ${ColumnFlexStyle}
  margin-left: 15px;
  margin-bottom: 10px;
`

export const BuyerProfileInput = styled.div`
  & ul {
    list-style: none;
    columns: 2;
    column-gap: 20px;
  }

  & ul li {
    margin-bottom: 10px;
  }

  & ul li label {
    margin-left: 10px;
  }
`
