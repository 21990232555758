import styled, { keyframes } from 'styled-components/macro'
import { CreatePracticeFormContainer } from '../../../components/CreatePractice/CreatePracticeInputForm/styled'
import {
  BaseMargin,
  IntrasectionalGap,
  LargeParagraphText,
  ScrollStyle,
  PrimaryButton,
  ColumnFlexStyle,
  TextStyle,
} from '../../../styles/shared-styled-components'
import { Box } from '@mui/material'

export const ValuationResultContainer = styled(CreatePracticeFormContainer)`
  ${BaseMargin}
  ${IntrasectionalGap}
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  } 
  to {
    opacity: 1;
  }
`

export const ValuationResultText = styled.p`
  ${TextStyle}
  margin: 0px auto;
  color: ${({ theme, colorScheme }) =>
    colorScheme === 'primary' ? theme.color.primary : theme.color.brightGreen};
  font-size: ${({ ismobilescreen }) => (ismobilescreen ? '17.5px' : '20px')};
  font-weight: bold;
  text-align: center;
  opacity: 0;
  animation: ${fadeIn} 1s forwards;
`

export const ValuationResultUnderlinedText = styled(ValuationResultText)`
  text-decoration: underline;
  display: inline;
  text-underline-offset: 3px;
`

export const ValuationWrapper = styled(Box)`
  width: 100%;
  height: calc(100% - 150px);
  ${ColumnFlexStyle};
  align-items: center;
  gap: 30px;
  overflow: auto;
  padding: 20px 0px;

  ${ScrollStyle}
`

export const DiscoveryCallButton = styled(PrimaryButton)`
  background-color: green;

  background-color: ${(props) => props.theme.color.primary};

  &:hover {
    background-color: ${(props) => props.theme.color.darkHover}
`
