import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { usePracticeById } from '../../../api/hooks/practices'
import CreatePracticeFlowNavigation from '../../../components/CreatePractice/CreatePracticeFlowNavigation'
import Paths from '../../../Paths'
import CreatePracticeHeader from '../../../components/CreatePractice/CreatePracticeHeader'
import { CreatePracticeFormContainer } from '../../../components/CreatePractice/CreatePracticeInputForm/styled'
import Header from '../../../components/NavBar/Header'
import {
  FullPageContentContainerWithGap,
  PageContainer,
  VUNSBC,
} from '../../../styles/shared-styled-components'
import { getCreatePracticePathToNavigateTo } from '../../../utils/routing'
import {
  FormOverviewContainer,
  StartValuationDentacloudLogo,
  PrivacyIcon,
  PrivacySectionContainer,
  PrivacySectionSubtitleText,
  PrivacySectionTitleText,
  ValuationSectionContainer,
  ValuationSectionSubtitleText,
  ValuationSectionTitleText,
} from './styled'
import LoadingIndicator from '../../../components/LoadingIndicator'

const currentStep = -1

const Start = ({ user, width, ismobilescreen }) => {
  const navigate = useNavigate()
  const { practiceId } = useParams()
  const [searchParams] = useSearchParams()
  const { practice, loading } = usePracticeById(practiceId)
  const divRef = useRef(null)
  const [remainingHeight, setRemainingHeight] = useState(0)

  const inValuationMode = searchParams.get('valuation') === 'true'

  const onClickNext = () => {
    let nextPath = getCreatePracticePathToNavigateTo(
      currentStep,
      practiceId,
      'next',
      null,
      false,
      null,
      inValuationMode,
    )

    navigate(nextPath)
  }

  useEffect(() => {
    const handleResize = () => {
      const divHeight = divRef?.current?.getBoundingClientRect().top
      const windowHeight = window.innerHeight
      const heightDifference = windowHeight - divHeight
      setRemainingHeight(heightDifference)
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Call it initially to get the remaining height

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [practice])

  // Mainly for covering iPhone SE and Samsung Galaxy S8
  const isExtraSmallHeightScreen = ismobilescreen
    ? remainingHeight < 550
    : remainingHeight < 480

  return loading ? (
    <LoadingIndicator fullScreen />
  ) : (
    practice && (
      <>
        <Header user={user} width={width} ismobilescreen={ismobilescreen} />
        <VUNSBC>
          <PageContainer>
            <FullPageContentContainerWithGap ismobilescreen={ismobilescreen}>
              <CreatePracticeHeader
                currentStep={currentStep}
                ismobilescreen={ismobilescreen}
                practice={practice}
                inValuationMode={inValuationMode}
              />
              <CreatePracticeFormContainer ismobilescreen={ismobilescreen}>
                <FormOverviewContainer
                  ref={divRef}
                  ismobilescreen={ismobilescreen}
                  remainingHeight={remainingHeight}
                >
                  <ValuationSectionContainer>
                    <StartValuationDentacloudLogo
                      component='img'
                      alt='Dentacloud Logo'
                      src={Paths.blueLogoWithNoText}
                    />
                    <ValuationSectionTitleText
                      isExtraSmallHeightScreen={isExtraSmallHeightScreen}
                    >
                      Start Your Free Valuation
                    </ValuationSectionTitleText>
                    <ValuationSectionSubtitleText
                      isExtraSmallHeightScreen={isExtraSmallHeightScreen}
                    >
                      Our AI model will value your practice for free
                    </ValuationSectionSubtitleText>
                  </ValuationSectionContainer>
                  {!inValuationMode && (
                    <PrivacySectionContainer>
                      <PrivacyIcon />
                      <PrivacySectionTitleText
                        isExtraSmallHeightScreen={isExtraSmallHeightScreen}
                      >
                        The Dentacloud Privacy Guarantee
                      </PrivacySectionTitleText>
                      <PrivacySectionSubtitleText
                        isExtraSmallHeightScreen={isExtraSmallHeightScreen}
                      >
                        Your practice identity is kept confidential and your
                        practice information cannot be shared without your
                        explicit authorization.
                      </PrivacySectionSubtitleText>
                    </PrivacySectionContainer>
                  )}
                </FormOverviewContainer>
                <CreatePracticeFlowNavigation
                  currentStep={currentStep}
                  practiceId={practiceId}
                  nextEnabled
                  onClickNext={onClickNext}
                />
              </CreatePracticeFormContainer>
            </FullPageContentContainerWithGap>
          </PageContainer>
        </VUNSBC>
      </>
    )
  )
}

export default Start
