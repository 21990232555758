import { useAuth0 } from '@auth0/auth0-react'
import Paths from '../../Paths'

const DirectValuation = () => {
  useAuth0().loginWithRedirect({
    appState: {
      returnTo: Paths.myPractices,
    },
    authorizationParams: {
      screen_hint: 'signup',
      utm_affiliate: localStorage.getItem('utm_affiliate'),
    },
  })
}

export default DirectValuation
