import styled from 'styled-components/macro'
import { LargeParagraphText } from '../../../styles/shared-styled-components'

export const Circle = styled.div`
  height: 30px;
  width: 30px;
  background-color: ${({ theme }) => theme.color.superLightGrey};
  border-radius: 30px;
  border: 1px solid ${({ theme }) => theme.color.lightPrimary};
  color: ${({ theme }) => theme.color.primary};
`

export const OrBlockText = styled(LargeParagraphText)`
  font-weight: bolder;
`
