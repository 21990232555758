import styled from 'styled-components/macro'
import {
  IntrasectionalGap,
  RowFlexStyle,
  ScrollStyle,
  ThinScrollBarStyle,
} from '../../../styles/shared-styled-components'
import typography from '../../../styles/typography'

export const ImageThumbnailContainer = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing.desktopTopMargin}px;
  width: 95%;
  padding: 15px;
  border: 2px dotted darkgray;
  border-radius: 10px;
  ${RowFlexStyle}
  justify-content: flex-start;
  ${IntrasectionalGap}
  flex-wrap: wrap;
`

export const MainWrapper = styled.div`
  overflow-y: auto
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
`

export const BorderedWrapper = styled.div`
  overflow-y: auto;
  display: flex;
  align-items: flex-start;
  justfiy-content: space-around;
  flex-grow: 1;
`

export const CompletenesCard = styled.div`
  display: flex;
  border-radius: 10px;
  background: #eef3f7;
  box-shadow: 0px 11px 11.9px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
  width: 300px;
  justify-content: space-around;
  align-items: center;

  & ul {
    padding: 0px 10px;
    list-style-type: none;
  }

  & li {
    display: flex;
    color: ${({ theme }) => theme.color.primary};
    font-family: Noto Sans KR;
    font-size: ${({ theme }) => theme.typography.defaultFontSize};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 18px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  & li:hover,
  & li.active {
    cursor: pointer;
    font-weight: 900;
  }

  & li.disabled {
    cursor: not-allowed;
  }
`

export const FlexBox = styled.div`
  display: flex;
  overflow: auto;
  padding: 0px 30px;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  flex-wrap: wrap;
  height: auto;

  &.main {
    width: 100%;
    ${ScrollStyle}
  }
  @media (max-width: 768px) {
    padding: 0px 0px;
  }
`

export const FormTitle = styled.h2`
  color: ${({ theme }) => theme.color.primary};
  font-family: Noto Sans KR;
  font-size: ${() => typography.titleFontSize};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px;
`

export const SubtitleText = styled.p`
  color: ${({ theme }) => theme.color.primary};
  font-family: Noto Sans KR;
  font-size: ${() => typography.smallFontSize};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
  margin-bottom: 20px;
  flex-wrap: wrap;

  &.note {
    color: ${({ theme }) => theme.color.grey};
  }

  &.label {
    font-size: ${() => typography.microFontSize};
  }
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`

export const TableHeader = styled.th`
  border: 1px solid rgba(1, 98, 153, 0.2);
  background: #e2eaf3;
  padding: 10px;
  text-align: left;
  color: #000;
  text-align: center;
  font-family: Noto Sans KR;
  font-size: ${typography.defaultFontSize};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`

export const TableCell = styled.td`
  padding: 10px;
  border: 1px solid rgba(1, 98, 153, 0.2);
  text-align: center;
  font-family: Noto Sans KR;
  font-size: ${typography.defaultFontSize};
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &.borderless {
    border: 0px;
  }
`

export const AddRowButton = styled.button`
  border-radius: 10px;
  border: 1px solid #016299;
  background: #016299;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  padding: 16px 25px;
  align-items: center;
  gap: 4px;
  color: #fff;
  font-size: ${typography.largeInputTextFontSize};
  align-self: center;
  margin: 20px 0px;

  &:hover {
    cursor: pointer;
    color: #016299;
    background: #fff;
  }
`

export const ProgressBarWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #bbb;
  width: 80%;
  height: 24px;
  align-self: center;
  overflow: hidden;
  margin-bottom: 10px;

  & .progress {
    background: #25b34d;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: Noto Sans KR;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`
