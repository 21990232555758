import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import sortBy from 'lodash/sortBy'
import {
  activeListingSaleStages,
  americanCountryCode,
  basicAccessTierKey,
  booleanFields,
  dataroomAccessTierKey,
  dpmss,
  getBuildingForSaleValueFromSaleType,
  getLabelFromKey,
  negotiableBooleanFields,
  offMarketStageKey,
  optionalBooleanFields,
  practiceBuildingTypes,
  practiceOwnerAccessTierKey,
  practiceOwnerWorkingSchedules,
  practiceTypes,
  roomForOperatoryExpansionOptions,
  saleTimeFrames,
  sectionsReachedWherePracticeCanBeListed,
  ConnectServicePlans,
  premiumAccessTierKey,
  practiceOverviewFieldsBasedOnType,
  practiceTeamFieldsBasedOnType,
  doctorNameBasedOnType,
  anesthesiology_type_key,
  oral_max_surgery_type_key,
} from './constants'
import { getSemiAnonymizedPostalOrZipCode } from './location'
import { getCapitalizedWord } from './naming'
import { getFormattedMoneyString } from './numbers'
import { createPracticeSectionPathMap } from './routing'
import _ from 'lodash'

export const sortDataByDateField = ({ data, dateField }) => {
  return sortBy(data, (d) => new Date(get(d, dateField)))
}

export const findIndexOfKeyInMap = (map, key) => {
  let index = 0

  for (const [currentKey] of map) {
    if (currentKey === key) {
      return index
    }
    index++
  }

  // Key not found
  return -1
}

export const findKeyFromValueInMap = (map, value) => {
  for (const [key, val] of map.entries()) {
    if (val === value) {
      return key
    }
  }
  return null
}

// Sort practices based on two fields:
// First - sort by how far along they are in the creation flow. Furthest along ordered first.
// Next - sort by updatedAt. Show newest ones first.
export const sortPracticeData = (practices) => {
  const practicesWithSectionEditingIndex = practices.map((p) => {
    const sectionEditing = p.sectionCurrentlyEditing
    const sectionEditingIndex = findIndexOfKeyInMap(
      createPracticeSectionPathMap,
      sectionEditing,
    )
    return {
      ...p,
      sectionEditingIndex,
    }
  })

  const sortedPractices = orderBy(
    practicesWithSectionEditingIndex,
    ['sectionEditingIndex', 'updatedAt'],
    ['desc', 'desc'],
  )

  return sortedPractices
}

export const checkIfPracticeIncludesPictures = (practice) => {
  return !!(practice?.website || practice?.pictures?.length)
}

export const getCompletedDueDiligenceSteps = (obj = {}) => {
  try {
    const steps = [
      { name: 'practiceProfile' },
      { name: 'accounting' },
      { name: 'legal' },
      { name: 'practiceManagementReports' },
      { name: 'humanResource' },
    ]

    const formSteps = steps.map((step, i) => {
      const dueDiligence = _.cloneDeep(obj)
      try {
        if (dueDiligence && dueDiligence[step.name]?.__typename) {
          delete dueDiligence[step.name].__typename
        }
      } catch (error) {
        console.log(error.message)
      }
      const stepCompleted =
        dueDiligence &&
        !_.isEmpty(dueDiligence[step.name]) &&
        _.every(dueDiligence[step.name], (value) => {
          if (Array.isArray(value)) {
            return value.length > 0
          } else if (typeof value === 'object') {
            for (const key in value) {
              if (_.isNull(value[key])) {
                return false
              }
            }
            return !_.isEmpty(value)
          } else {
            return !_.isNull(value)
          }
        })

      return { ...step, stepCompleted }
    })

    return formSteps
  } catch (error) {
    return false
  }
}

const validateDueDiligenceObject = (obj = {}) => {
  try {
    const formSteps = getCompletedDueDiligenceSteps(obj)
    const allStepsCompleted = formSteps.every((step) => step.stepCompleted)

    return allStepsCompleted
  } catch (error) {
    return false
  }
}

export const checkIfPracticeIncludesFinancials = (practice) => {
  return validateDueDiligenceObject(practice?.dueDiligence)
}

export const getEligiblePracticeListingStatuses = (practice) => {
  if (!practice) {
    return []
  }

  // If they've completed their practice, they can list however they want
  if (practice.completedAt) {
    return activeListingSaleStages
  }

  // If they haven't even reached any of the sections from which they're now eligible to list,
  // they can't list at all
  if (
    !sectionsReachedWherePracticeCanBeListed.includes(
      practice.sectionCurrentlyEditing,
    )
  ) {
    return []
  }

  // If they've reached the sections where they're allowed to list but haven't filled in pictures/financials,
  // they can list off-market
  if (
    !(
      checkIfPracticeIncludesPictures(practice) &&
      checkIfPracticeIncludesFinancials(practice)
    )
  ) {
    return [offMarketStageKey]
  }

  // If they've reached the sections where they're allowed to list, and have filled in pictures/financials,
  // just haven't published, they can list however they want
  return activeListingSaleStages
}

export const getPracticeTypeLabelFromKey = (key) => {
  const label = practiceTypes.map((type) => {
    if (type.key === key) {
      return type.label
    }
  })

  return label
}

export const getPracticeLocationString = (city, province, country = 'CA') => {
  if (!city || !province) {
    return ''
  }

  return `${getCapitalizedWord(city)}, ${province.toUpperCase()}`
}

export const valueIsEmpty = (value) => {
  return value == null || value === ''
}

export const getExpandedPracticeFieldsBySection = (practice) => {
  const buildingForSaleValue = getBuildingForSaleValueFromSaleType(
    practice?.saleType,
  )

  return {
    info: [
      {
        label: 'Annual billings',
        value: getFormattedMoneyString(practice?.averageAnnualBillings),
        accessTier: basicAccessTierKey,
      },
      {
        label: 'Operatories',
        value: practice?.operatories,
        accessTier: basicAccessTierKey,
      },
    ],
    overview: [
      {
        id: 'name',
        label: 'Name',
        value: practice?.name,
        accessTier: dataroomAccessTierKey,
      },
      {
        id: 'address',
        label: 'Address',
        value: practice?.address,
        accessTier: dataroomAccessTierKey,
      },
      {
        id: 'postalCode',
        label:
          practice?.country === americanCountryCode
            ? 'Zip code'
            : 'Postal code',
        value: practice?.postalCode,
        valuePreview: getSemiAnonymizedPostalOrZipCode(
          practice?.postalCode,
          practice?.country,
        ),
        accessTier: dataroomAccessTierKey,
      },
      {
        id: 'type',
        label: 'Type',
        value: getLabelFromKey(practiceTypes, practice?.type),
        accessTier: premiumAccessTierKey,
      },
      {
        id: 'dpms',
        label: 'Practice management software',
        value: getLabelFromKey(dpmss, practice?.dpms),
        accessTier: premiumAccessTierKey,
      },
      {
        id: 'hygienePercentage',
        label: 'Percentage of revenue from hygiene',
        value: `${practice?.hygieneRevenuePercentage * 100}%`,
        accessTier: premiumAccessTierKey,
      },
      {
        id: 'isInvisalignCertified',
        label: 'Is Invisalign certified',
        value: practice?.isInvisalignCertified ? 'Yes' : 'No',
        accessTier: premiumAccessTierKey,
      },
      {
        id: 'hasIntraoralScanner',
        label: 'Has Intraoral Scanner',
        value: practice?.hasIntraoralScanner ? 'Yes' : 'No',
        accessTier: premiumAccessTierKey,
      },
      {
        id: 'patientsSeenWeekly',
        label: 'Number of patients seen weekly',
        value: practice?.patientsSeenWeekly,
        accessTier: premiumAccessTierKey,
      },
      {
        id: 'numberDentistsRefer',
        label: 'Estimated number of dentists that refer to them',
        value: practice?.numberDentistsRefer,
        accessTier: premiumAccessTierKey,
      },
      {
        id: 'onlyProvidesAnesthesia',
        label: 'Only provides anesthesia services',
        value: practice?.onlyProvidesAnesthesia ? 'Yes' : 'No',
        accessTier: premiumAccessTierKey,
      },
      {
        id: 'openDaysPerWeek',
        label: 'Days open per week',
        value: practice?.openDaysPerWeek,
        accessTier: premiumAccessTierKey,
      },
      {
        id: 'numberOfDoctors',
        label: 'Number of ' + doctorNameBasedOnType[practice?.type],
        value: practice?.numberOfDoctors,
        accessTier: premiumAccessTierKey,
      },

      // {
      //   label: 'Practice has Debt',
      //   value: practice?.practiceHasDebt ? 'Yes' : 'No',
      //   accessTier: basicAccessTierKey,
      // },
    ]
      // Only include fields that are appropriate for practice type
      .filter(
        (field) =>
          practice?.type &&
          practiceOverviewFieldsBasedOnType[practice?.type].includes(field.id),
      ),
    team: [
      {
        id: 'partTimeAssociates',
        label: 'Part-time associates',
        value: practice?.partTimeAssociates,
        accessTier: premiumAccessTierKey,
      },
      {
        id: 'fullTimeAssociates',
        label: 'Full-time associates',
        value: practice?.fullTimeAssociates,
        accessTier: premiumAccessTierKey,
      },
      {
        id: 'partTimeHygienists',
        label: 'Part-time hygienists',
        value: practice?.partTimeHygienists,
        accessTier: premiumAccessTierKey,
      },
      {
        id: 'fullTimeHygienists',
        label: 'Full-time hygienists',
        value: practice?.fullTimeHygienists,
        accessTier: premiumAccessTierKey,
      },
      {
        id: 'partTimeAdmins',
        label: 'Part-time admins',
        value: practice?.partTimeAdmins,
        accessTier: premiumAccessTierKey,
      },
      {
        id: 'fullTimeAdmins',
        label: 'Full-time admins',
        value: practice?.fullTimeAdmins,
        accessTier: premiumAccessTierKey,
      },
      {
        id: 'partTimeAssistants',
        label: 'Part-time assistants',
        value: practice?.partTimeAssistants,
        accessTier: premiumAccessTierKey,
      },
      {
        id: 'fullTimeAssistants',
        label: 'Full-time assistants',
        value: practice?.fullTimeAssistants,
        accessTier: premiumAccessTierKey,
      },
      {
        id: 'schedule',
        label: 'Practice owner chair-side status',
        value: getLabelFromKey(
          practiceOwnerWorkingSchedules,
          practice?.practiceOwnerWorkingSchedule,
        ),
        accessTier: premiumAccessTierKey,
      },
      {
        id: 'canAddAssociate',
        label: 'Work volume to add another associate',
        value: practice?.canAddAssociate ? 'Yes' : 'No',
        accessTier: premiumAccessTierKey,
      },
    ]
      // Only include fields that are appropriate for practice type
      .filter(
        (field) =>
          practice?.type &&
          practiceTeamFieldsBasedOnType[practice?.type].includes(field.id),
      ),
    lease: [
      {
        label: 'Practice owner owns the building',
        value: getLabelFromKey(booleanFields, practice?.ownsBuilding),
        accessTier: premiumAccessTierKey,
      },
      {
        label: 'Building for sale',
        value: getLabelFromKey(negotiableBooleanFields, buildingForSaleValue),
        dontDisplay: practice?.ownsBuilding !== true,
        accessTier: premiumAccessTierKey,
      },
      {
        label: 'Building type',
        value: getLabelFromKey(practiceBuildingTypes, practice?.buildingType),
        accessTier: premiumAccessTierKey,
      },
      {
        label: 'Room for operatory expansion',
        value: getLabelFromKey(
          roomForOperatoryExpansionOptions,
          practice?.roomForOperatoryExpansion,
        ),
        accessTier: premiumAccessTierKey,
      },
      {
        label: 'Building has a demolition clause',
        value: getLabelFromKey(
          optionalBooleanFields,
          practice?.buildingHasDemolitionClause,
        ),
        accessTier: dataroomAccessTierKey,
      },
      {
        label: 'Number of years left on the lease',
        value: practice?.yearsLeftOnLease,
        accessTier: dataroomAccessTierKey,
      },
    ],
    terms: [
      // {
      //   label: 'Target sale price',
      //   value: getFormattedMoneyString(practice?.targetPrice),
      //   accessTier: basicAccessTierKey,
      // },
      {
        label: "When they're looking to sell",
        value: getLabelFromKey(
          saleTimeFrames,
          practice?.timeFrameLookingToSell,
        ),
        accessTier: premiumAccessTierKey,
      },
      {
        label: 'Months willing to support the practice post-close',
        value: practice?.monthsWorkingPostSale,
        accessTier: dataroomAccessTierKey,
      },
      {
        label: 'Used lifetime capital gains exemption',
        value: getLabelFromKey(
          optionalBooleanFields,
          practice?.usedLifetimeCapitalGainsExemption,
        ),
        accessTier: practiceOwnerAccessTierKey,
      },
    ],
  }
}

export const getConnectServicePlanFeatures = () => ({
  Fee: {
    [ConnectServicePlans.free]: 'FREE',
    [ConnectServicePlans.slidingScale]: '5-10%',
    [ConnectServicePlans.lifetimeDeal]: '5%',
    [ConnectServicePlans.whiteGloveService]: '10%',
  },
  'List Off-Market': {
    [ConnectServicePlans.free]: true,
    [ConnectServicePlans.slidingScale]: false,
    [ConnectServicePlans.lifetimeDeal]: false,
    [ConnectServicePlans.whiteGloveService]: false,
  },
  'List Your Practice for Sale to Get Max Offers': {
    [ConnectServicePlans.free]: false,
    [ConnectServicePlans.slidingScale]: true,
    [ConnectServicePlans.lifetimeDeal]: true,
    [ConnectServicePlans.whiteGloveService]: true,
  },
  'Get Offers from Buyers': {
    [ConnectServicePlans.free]: true,
    [ConnectServicePlans.slidingScale]: true,
    [ConnectServicePlans.lifetimeDeal]: true,
    [ConnectServicePlans.whiteGloveService]: true,
  },
  'Get a Free Valuation': {
    [ConnectServicePlans.free]: true,
    [ConnectServicePlans.slidingScale]: true,
    [ConnectServicePlans.lifetimeDeal]: true,
    [ConnectServicePlans.whiteGloveService]: true,
  },
  'A Strategy Call with Dentaclouds Team to Outline the Selling Process': {
    [ConnectServicePlans.free]: false,
    [ConnectServicePlans.slidingScale]: false,
    [ConnectServicePlans.lifetimeDeal]: true,
    [ConnectServicePlans.whiteGloveService]: true,
  },
  'Assigned a Dentacloud Deal Manager for Support': {
    [ConnectServicePlans.free]: false,
    [ConnectServicePlans.slidingScale]: false,
    [ConnectServicePlans.lifetimeDeal]: true,
    [ConnectServicePlans.whiteGloveService]: true,
  },
  'Sliding Scale - The More You Upload Verified Due Diligence the More You Save':
    {
      [ConnectServicePlans.free]: true,
      [ConnectServicePlans.slidingScale]: true,
      [ConnectServicePlans.lifetimeDeal]: true,
      [ConnectServicePlans.whiteGloveService]: false,
    },
  'Lifetime - Upload at Your Leisure and Dentacloud will Prompt You When a Buyer is Interested':
    {
      [ConnectServicePlans.free]: true,
      [ConnectServicePlans.slidingScale]: true,
      [ConnectServicePlans.lifetimeDeal]: true,
      [ConnectServicePlans.whiteGloveService]: false,
    },
  'Connect with Transactional Lawyers and Accountants at Fixed Fees': {
    [ConnectServicePlans.free]: false,
    [ConnectServicePlans.slidingScale]: false,
    [ConnectServicePlans.lifetimeDeal]: true,
    [ConnectServicePlans.whiteGloveService]: true,
  },
  'Supports in Gathering Due Diligence Items': {
    [ConnectServicePlans.free]: false,
    [ConnectServicePlans.slidingScale]: false,
    [ConnectServicePlans.lifetimeDeal]: false,
    [ConnectServicePlans.whiteGloveService]: true,
  },
  'Calculate Normalized EBITDA': {
    [ConnectServicePlans.free]: false,
    [ConnectServicePlans.slidingScale]: false,
    [ConnectServicePlans.lifetimeDeal]: false,
    [ConnectServicePlans.whiteGloveService]: true,
  },
  'Offer Consulting Services to Increase EBITDA': {
    [ConnectServicePlans.free]: false,
    [ConnectServicePlans.slidingScale]: false,
    [ConnectServicePlans.lifetimeDeal]: false,
    [ConnectServicePlans.whiteGloveService]: true,
  },
  'Offer Financing Options': {
    [ConnectServicePlans.free]: false,
    [ConnectServicePlans.slidingScale]: false,
    [ConnectServicePlans.lifetimeDeal]: false,
    [ConnectServicePlans.whiteGloveService]: true,
  },
  'Negotiate Best Price and Terms': {
    [ConnectServicePlans.free]: false,
    [ConnectServicePlans.slidingScale]: false,
    [ConnectServicePlans.lifetimeDeal]: false,
    [ConnectServicePlans.whiteGloveService]: true,
  },
  '1 Year Post Transition Support': {
    [ConnectServicePlans.free]: false,
    [ConnectServicePlans.slidingScale]: false,
    [ConnectServicePlans.lifetimeDeal]: false,
    [ConnectServicePlans.whiteGloveService]: true,
  },
})

export default {
  sortDataByDateField,
  findIndexOfKeyInMap,
  checkIfPracticeIncludesPictures,
  checkIfPracticeIncludesFinancials,
  getEligiblePracticeListingStatuses,
  findKeyFromValueInMap,
  getPracticeTypeLabelFromKey,
  getPracticeLocationString,
  valueIsEmpty,
  getExpandedPracticeFieldsBySection,
  getConnectServicePlanFeatures,
}
