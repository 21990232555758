import React from 'react'
import { EditButtonFrame, StyledEditIcon } from './styled'

const EditSectionButton = ({
  onClick,
  size = 'medium',
  fixed = false,
  outlined = true,
}) => {
  return (
    <EditButtonFrame
      fixed={fixed}
      outlined={outlined}
      onClick={onClick}
      style={{ zIndex: 0 }}
    >
      <StyledEditIcon size={size} />
    </EditButtonFrame>
  )
}

export default EditSectionButton
