import MoreVertIcon from '@mui/icons-material/MoreVert'
import styled from 'styled-components/macro'

const iconSize = '27px'

export const ThreeDotsIconContainer = styled.div`
  cursor: ${(props) => !props.useAsPlaceholder && 'pointer'};
  max-height: ${iconSize};
  &:hover {
    background-color: ${(props) =>
      !props.useAsPlaceholder && props.theme.color.superLightGrey};
    border-radius: ${(props) => !props.useAsPlaceholder && '10px'};
    cursor: ${(props) => !props.useAsPlaceholder && 'pointer'};
  }
`

export const StyledThreeDotsIcon = styled(MoreVertIcon)`
  color: ${({ theme }) => theme.color.grey};
  height: ${iconSize};
  width: ${iconSize};
`

export const PlaceholderIconDiv = styled.div`
  height: ${iconSize};
  width: ${iconSize};
`
