import { useApolloClient } from '@apollo/client'
import { useMutationWithErrorLogging } from '../../../utils/hooks'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { usePracticeById } from '../../../api/hooks/practices'
import { EDIT_PRACTICE_STAFF } from '../../../api/mutations/practices'
import CreatePracticeInput from '../../../components/CreatePractice/CreatePracticeInput'
import CreatePracticeInputForm from '../../../components/CreatePractice/CreatePracticeInputForm/CreatePracticeInputForm'
import { updatePracticeByIdInCache } from '../../../utils/cache'
import {
  booleanFields,
  editingFlowKey,
  practiceOwnerWorkingSchedules,
  publishingFlowKey,
  practiceTeamFieldsBasedOnType,
} from '../../../utils/constants'
import {
  getIdOfFirstInputFieldWithError,
  handleIntegerFieldChange,
} from '../../../utils/input'
import {
  getCreatePracticePathToNavigateTo,
  makeCreatePracticePathInCurrentFlow,
  navigateToPublishListingPage,
  navigateToViewPracticePage,
} from '../../../utils/routing'
import { scrollElementIntoView } from '../../../utils/view'

const currentStep = 1

const Team = ({ user, width, ismobilescreen }) => {
  const navigate = useNavigate()
  const { cache } = useApolloClient()
  const practiceFormRef = useRef()

  const [editPracticeStaffMutation] =
    useMutationWithErrorLogging(EDIT_PRACTICE_STAFF)

  const { practiceId } = useParams()
  const { practice } = usePracticeById(practiceId)

  const [searchParams] = useSearchParams()
  const currentFlow = searchParams.get('flow')

  const [partTimeAssociates, setPartTimeAssociates] = useState(
    practice?.partTimeAssociates || null,
  )
  const [fullTimeAssociates, setFullTimeAssociates] = useState(
    practice?.fullTimeAssociates || null,
  )
  const [partTimeHygienists, setPartTimeHygienists] = useState(
    practice?.partTimeHygienists || null,
  )
  const [fullTimeHygienists, setFullTimeHygienists] = useState(
    practice?.fullTimeHygienists || null,
  )
  const [partTimeAdmins, setPartTimeAdmins] = useState(
    practice?.partTimeAdmins || null,
  )
  const [fullTimeAdmins, setFullTimeAdmins] = useState(
    practice?.fullTimeAdmins || null,
  )
  const [partTimeAssistants, setPartTimeAssistants] = useState(
    practice?.partTimeAssistants || null,
  )
  const [fullTimeAssistants, setFullTimeAssistants] = useState(
    practice?.fullTimeAssociates || null,
  )
  const [practiceOwnerWorkingSchedule, setPracticeOwnerWorkingSchedule] =
    useState(practice?.practiceOwnerWorkingSchedule || '')
  const [canAddAssociate, setCanAddAssociate] = useState(
    practice?.canAddAssociate || false,
  )
  const [submittedDataWithErrors, setSubmittedDataWithErrors] = useState(false)
  const [changesMade, setChangesMade] = useState(false)

  useEffect(() => {
    if (practice) {
      setPartTimeAssociates(practice.partTimeAssociates)
      setFullTimeAssociates(practice.fullTimeAssociates)
      setPartTimeHygienists(practice.partTimeHygienists)
      setFullTimeHygienists(practice.fullTimeHygienists)
      setPartTimeAdmins(practice.partTimeAdmins)
      setFullTimeAdmins(practice.fullTimeAdmins)
      setPartTimeAssistants(practice.partTimeAssistants)
      setFullTimeAssistants(practice.fullTimeAssistants)
      setPracticeOwnerWorkingSchedule(practice.practiceOwnerWorkingSchedule)
      setCanAddAssociate(practice.canAddAssociate)
    }
  }, [practice])

  const updateChangesMade = () => {
    if (!changesMade) {
      setChangesMade(true)
    }
  }

  const callEditPracticeStaffMutation = useCallback(
    async (flowToReturnTo) => {
      if (changesMade) {
        const navRef = practiceFormRef?.current?.getNavRef()
        navRef?.current?.setLoading(true)
        await editPracticeStaffMutation({
          variables: {
            id: practiceId,
            partTimeAssociates: parseInt(partTimeAssociates),
            fullTimeAssociates: fullTimeAssociates,
            partTimeHygienists: partTimeHygienists,
            fullTimeHygienists: fullTimeHygienists,
            partTimeAdmins: partTimeAdmins,
            fullTimeAdmins: fullTimeAdmins,
            partTimeAssistants: partTimeAssistants,
            fullTimeAssistants: fullTimeAssistants,
            practiceOwnerWorkingSchedule,
            canAddAssociate,
          },
          onCompleted: async (data) => {
            await updatePracticeByIdInCache(
              practiceId,
              data.editPracticeStaff,
              cache,
            )
            navRef?.current?.setLoading(false)

            if (flowToReturnTo === publishingFlowKey) {
              navigateToPublishListingPage(practiceId, navigate)
            } else if (flowToReturnTo === editingFlowKey) {
              navigateToViewPracticePage(practiceId, navigate)
            } else {
              // If we just want to navigate to the next step
              let nextPath = getCreatePracticePathToNavigateTo(
                currentStep,
                practiceId,
                'next',
                currentFlow,
                false,
                null,
                searchParams.get('valuation') === 'true',
              )

              if (currentFlow) {
                nextPath = makeCreatePracticePathInCurrentFlow(
                  nextPath,
                  currentFlow,
                )
              }

              navigate(nextPath)
            }
          },
        })
      } else {
        if (flowToReturnTo === publishingFlowKey) {
          navigateToPublishListingPage(practiceId, navigate)
        } else if (flowToReturnTo === editingFlowKey) {
          navigateToViewPracticePage(practiceId, navigate)
        } else {
          // If we just want to navigate to the next step
          let nextPath = getCreatePracticePathToNavigateTo(
            currentStep,
            practiceId,
            'next',
            currentFlow,
            false,
            null,
            searchParams.get('valuation') === 'true',
          )

          if (currentFlow) {
            nextPath = makeCreatePracticePathInCurrentFlow(
              nextPath,
              currentFlow,
            )
          }

          navigate(nextPath)
        }
      }
    },
    [
      practiceFormRef,
      cache,
      changesMade,
      currentFlow,
      editPracticeStaffMutation,
      fullTimeAdmins,
      fullTimeAssistants,
      fullTimeAssociates,
      fullTimeHygienists,
      navigate,
      partTimeAdmins,
      partTimeAssistants,
      partTimeAssociates,
      partTimeHygienists,
      practiceOwnerWorkingSchedule,
      practiceId,
      searchParams,
      canAddAssociate,
    ],
  )

  const onClickNext = (flowToReturnTo = null) => {
    // If they've clicked without next being enabled, we know there are errors
    let containsError = !nextEnabled

    let errorElementIdToScrollTo

    if (containsError) {
      errorElementIdToScrollTo =
        getIdOfFirstInputFieldWithError(inputElementsArray)
    }

    // If there is an error ID to scroll to, set state to reflect that, and scroll to the first erroneous field
    if (errorElementIdToScrollTo) {
      scrollElementIntoView(document, errorElementIdToScrollTo)
      setSubmittedDataWithErrors(true)
    } else {
      callEditPracticeStaffMutation(flowToReturnTo)
      setSubmittedDataWithErrors(false)
    }
  }

  const handlePracticeOwnerWorkingScheduleChange = (e) => {
    const { value } = e.target
    setPracticeOwnerWorkingSchedule(value)
    updateChangesMade()
  }

  const inputElements = {
    partTimeAssociates: {
      id: 'partTimeAssociates',
      fieldType: 'integer',
      state: partTimeAssociates,
    },
    fullTimeAssociates: {
      id: 'fullTimeAssociates',
      fieldType: 'integer',
      state: fullTimeAssociates,
    },
    partTimeHygienists: {
      id: 'partTimeHygienists',
      fieldType: 'integer',
      state: partTimeHygienists,
    },
    fullTimeHygienists: {
      id: 'fullTimeHygienists',
      fieldType: 'integer',
      state: fullTimeHygienists,
    },
    partTimeAdmins: {
      id: 'partTimeAdmins',
      fieldType: 'integer',
      state: partTimeAdmins,
    },
    fullTimeAdmins: {
      id: 'fullTimeAdmins',
      fieldType: 'integer',
      state: fullTimeAdmins,
    },
    partTimeAssistants: {
      id: 'partTimeAssistants',
      fieldType: 'integer',
      state: partTimeAssistants,
    },
    fullTimeAssistants: {
      id: 'fullTimeAssistants',
      fieldType: 'integer',
      state: fullTimeAssistants,
    },
    schedule: {
      id: 'schedule',
      fieldType: 'dropdown',
      state: practiceOwnerWorkingSchedule,
    },
    canAddAssociate: {
      id: 'canAddAssociate',
      fieldType: 'dropdown',
      state: canAddAssociate,
    },
  }

  const formInputs = [
    <CreatePracticeInput
      value={partTimeAssociates}
      label='Part-time associates * '
      width={width}
      onChange={(e) => {
        handleIntegerFieldChange(e, setPartTimeAssociates)
        updateChangesMade()
      }}
      fieldType={inputElements['partTimeAssociates']['fieldType']}
      elementId={inputElements['partTimeAssociates']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    <CreatePracticeInput
      value={fullTimeAssociates}
      label='Full-time associates * '
      width={width}
      onChange={(e) => {
        handleIntegerFieldChange(e, setFullTimeAssociates)
        updateChangesMade()
      }}
      fieldType={inputElements['fullTimeAssociates']['fieldType']}
      elementId={inputElements['fullTimeAssociates']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    <CreatePracticeInput
      value={partTimeHygienists}
      label='Part-time hygienists * '
      width={width}
      onChange={(e) => {
        handleIntegerFieldChange(e, setPartTimeHygienists)
        updateChangesMade()
      }}
      fieldType={inputElements['partTimeHygienists']['fieldType']}
      elementId={inputElements['partTimeHygienists']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    <CreatePracticeInput
      value={fullTimeHygienists}
      label='Full-time hygienists * '
      width={width}
      onChange={(e) => {
        handleIntegerFieldChange(e, setFullTimeHygienists)
        updateChangesMade()
      }}
      fieldType={inputElements['fullTimeHygienists']['fieldType']}
      elementId={inputElements['fullTimeHygienists']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    <CreatePracticeInput
      value={partTimeAdmins}
      label='Part-time admins * '
      width={width}
      onChange={(e) => {
        handleIntegerFieldChange(e, setPartTimeAdmins)
        updateChangesMade()
      }}
      fieldType={inputElements['partTimeAdmins']['fieldType']}
      elementId={inputElements['partTimeAdmins']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    <CreatePracticeInput
      value={fullTimeAdmins}
      label='Full-time admins * '
      width={width}
      onChange={(e) => {
        handleIntegerFieldChange(e, setFullTimeAdmins)
        updateChangesMade()
      }}
      fieldType={inputElements['fullTimeAdmins']['fieldType']}
      elementId={inputElements['fullTimeAdmins']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    <CreatePracticeInput
      value={partTimeAssistants}
      label='Part-time assistants * '
      width={width}
      onChange={(e) => {
        handleIntegerFieldChange(e, setPartTimeAssistants)
        updateChangesMade()
      }}
      fieldType={inputElements['partTimeAssistants']['fieldType']}
      elementId={inputElements['partTimeAssistants']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    <CreatePracticeInput
      value={fullTimeAssistants}
      label='Full-time assistants * '
      width={width}
      onChange={(e) => {
        handleIntegerFieldChange(e, setFullTimeAssistants)
        updateChangesMade()
      }}
      fieldType={inputElements['fullTimeAssistants']['fieldType']}
      elementId={inputElements['fullTimeAssistants']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    <CreatePracticeInput
      value={practiceOwnerWorkingSchedule}
      label="Practice owner's chair-side status * "
      width={width}
      onChange={handlePracticeOwnerWorkingScheduleChange}
      options={practiceOwnerWorkingSchedules}
      fieldType={inputElements['schedule']['fieldType']}
      elementId={inputElements['schedule']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    <CreatePracticeInput
      value={canAddAssociate}
      label='Work volume to add another associate * '
      width={width}
      onChange={(e) => {
        setCanAddAssociate(e.target.value)
        updateChangesMade()
      }}
      options={booleanFields}
      fieldType={inputElements['canAddAssociate']['fieldType']}
      elementId={inputElements['canAddAssociate']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
  ].filter(
    (field) =>
      practice?.type &&
      practiceTeamFieldsBasedOnType[practice?.type].includes(
        field.props.elementId,
      ),
  )

  const inputElementsArray = Object.values(inputElements)
  const nextEnabled = inputElementsArray.every(
    (field) =>
      (practice?.type &&
        !practiceTeamFieldsBasedOnType[practice?.type].includes(field['id'])) ||
      (field['state'] != null && field['state'] !== ''),
  )

  return (
    practice && (
      <CreatePracticeInputForm
        ref={practiceFormRef}
        user={user}
        width={width}
        ismobilescreen={ismobilescreen}
        currentStep={currentStep}
        practice={practice}
        formTitle={'Your Team'}
        formInputs={formInputs}
        nextEnabled={nextEnabled}
        onClickNext={() => onClickNext()}
        currentFlow={currentFlow}
        onSaveInFlow={() => onClickNext(currentFlow)}
        inValuationMode={searchParams.get('valuation') === 'true'}
      />
    )
  )
}

export default Team
