import React, { useContext, useEffect, useState } from 'react'
import {
  pathsForGlobalInputsFieldNames,
  pathsForHeuristicFieldNames,
} from '../../utils/constants'

import { get, set } from 'lodash'
import { InformationContext } from './Panel'

const inputNameMapping = {
  averageAnnualBillings: 'Average Annual Billings',
  hygieneRevenuePercentage: 'Hygiene Revenue Percentage',
  operatories: 'Operatories',
  fullTimeHygienistEffectiveness: 'Full Time Hygienist Effectiveness',
  fullTimeHygienistCost: 'Full Time Hygienist Cost',
  fullTimeAdmins: 'Full Time Admins',
}

const subtableNameMapping = {
  lessThanException: '<Exception',
  lessThanDefault: '<Default',
  default: 'Default',
  greaterThanDefault: '>Default',
  greaterThanException: '>Exception',
}

const getCurrentFieldValue = (heuristicsAndGlobalsState, path) => {
  const value = get(heuristicsAndGlobalsState, path, '')
  return value
}

const updateFieldValue = (setHeuristicsAndGlobalsState, path, newValue) => {
  setHeuristicsAndGlobalsState((prevHeuristicsAndGlobalsState) => {
    let updatedHeuristicsAndGlobalsState = set(
      prevHeuristicsAndGlobalsState,
      path,
      newValue,
    )

    // set updated to true so that it can be changed in the backend
    const stateName = path[0]
    updatedHeuristicsAndGlobalsState[stateName].requiresBackendUpdate = true

    return updatedHeuristicsAndGlobalsState
  })
}

// this field component works for both global input fields and heuristic fields
// for heuristic fields, inputName and subtableName are required, for globalInputs only fieldPath is required
const Field = ({ fieldPath, inputName, subtableName }) => {
  const { heuristicsAndGlobalsState, setHeuristicsAndGlobalsState } =
    useContext(InformationContext)

  // if inputName and subtableName are defined then this is a heuristic field and so the heuristic path is used
  // otherwise the globalInputs path is used
  const path =
    inputName && subtableName
      ? [inputName, 'data', subtableName, ...fieldPath]
      : ['globalInputs', 'data', ...fieldPath]

  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    // set initial input value
    setInputValue(getCurrentFieldValue(heuristicsAndGlobalsState, path))
  }, [heuristicsAndGlobalsState, path])

  const handleInputChange = (event) => {
    const newValue = event.target.value
    setInputValue(newValue)

    updateFieldValue(setHeuristicsAndGlobalsState, path, newValue)
  }

  return (
    <td>
      <input
        value={inputValue}
        onChange={(event) => handleInputChange(event)}
      ></input>
    </td>
  )
}

const Subtable = ({ inputName, subtableName }) => {
  return (
    <tr>
      <td>{subtableNameMapping[subtableName]}</td>
      {Object.values(pathsForHeuristicFieldNames).map((fieldPath, i) => (
        <Field
          inputName={inputName}
          subtableName={subtableName}
          fieldPath={fieldPath}
          key={i}
        />
      ))}
    </tr>
  )
}

const Increment = ({ inputName }) => {
  const { heuristicsAndGlobalsState, setHeuristicsAndGlobalsState } =
    useContext(InformationContext)
  const [inputValue, setInputValue] = useState('')

  const path = [inputName, 'data', 'increment']

  useEffect(() => {
    // set initial input value
    setInputValue(getCurrentFieldValue(heuristicsAndGlobalsState, path))
  }, [heuristicsAndGlobalsState, path])

  const handleInputChange = (event) => {
    const newValue = event.target.value
    setInputValue(newValue)

    updateFieldValue(setHeuristicsAndGlobalsState, path, newValue)
  }

  return (
    <>
      <div style={{ display: 'inline' }}>Increment</div>
      <input
        value={inputValue}
        onChange={(event) => handleInputChange(event)}
      ></input>
    </>
  )
}

const Heuristic = ({ inputName }) => {
  const subtableNames = ['lessThanDefault', 'default', 'greaterThanDefault']

  return (
    <div className='heuristic'>
      <h2 className='input-name'>{inputNameMapping[inputName]}</h2>
      <table>
        <tbody>
          <tr>
            <th>Case</th>
            <th>Lower Range</th>
            <th>Upper Range</th>
            <th>Min Multiple</th>
            <th>Max Multiple</th>
            <th>Min Ebitda</th>
            <th>Max Ebitda</th>
          </tr>
          {subtableNames.map((subtableName, i) => (
            <Subtable
              inputName={inputName}
              subtableName={subtableName}
              key={i}
            />
          ))}
        </tbody>
      </table>
      <Increment inputName={inputName} />
    </div>
  )
}

const GlobalInputs = () => {
  return (
    <div style={{ marginTop: 100 }}>
      <table>
        <tbody>
          <tr>
            {Object.keys(pathsForGlobalInputsFieldNames).map((fieldName, i) => (
              <th key={i}>{fieldName}</th>
            ))}
          </tr>
          <tr>
            {Object.values(pathsForGlobalInputsFieldNames).map(
              (fieldPath, i) => (
                <Field fieldPath={fieldPath} key={i} />
              ),
            )}
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const HeuristicsAndGlobalsPanel = () => {
  return (
    <>
      <GlobalInputs />
      <hr></hr>
      <h4>
        NOTE: If lower exception isn't defined then any value that's below the
        lowest lower range value will be set to the lowest lower value and the
        deltas for that will be applied. The same applies for a value above the
        highest upper value.
      </h4>
      {Object.keys(inputNameMapping).map((inputName, i) => (
        <Heuristic inputName={inputName} key={i} />
      ))}
    </>
  )
}

export default HeuristicsAndGlobalsPanel
