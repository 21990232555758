import React, { useCallback } from 'react'
import {
  Box,
  BuyerBackButton,
  BuyerButtons,
  BuyerContinueButton,
  ContentText,
  HeaderTitle,
  NdaPopupContainer,
  PopupBox,
} from '../Micro/BuyerProfile/styled'
import { useState } from 'react'
import { forwardRef } from 'react'
import { useImperativeHandle } from 'react'
import { CloseIcon } from '../../styles/shared-styled-components'
import { Sms } from '@mui/icons-material'

const EMACAModal = forwardRef((props, ref) => {
  const [showEMACAModal, setShowEMACAModal] = useState(false)

  const dismiss = () => {
    setShowEMACAModal(false)
  }

  useImperativeHandle(ref, () => ({
    dismiss: dismiss,
    openModal: () => {
      setShowEMACAModal(true)
    },
  }))

  return (
    showEMACAModal && (
      <PopupBox>
        <NdaPopupContainer>
          <CloseIcon
            onClick={dismiss}
            style={{ position: 'absolute', left: 450, cursor: 'pointer' }}
          >
            x
          </CloseIcon>
          <HeaderTitle>Book a Call with a Deal Manager</HeaderTitle>
          <Box
            style={{
              maxHeight: '100%',
              overflowY: 'auto',
              margin: '10px 20px',
              flex: 1,
            }}
          >
            <ContentText>
              Before you can proceed, we request that you schedule a call to
              finalize the signing of your agreement. This call will ensure that
              all terms and conditions are clearly understood and agreed upon.
              <br></br>
              <br></br>A Dentacloud representative is able to assist you should
              you have any questions via email at{' '}
              <a href='mailto:info@dentacloud.ai?'>info@dentacloud.ai</a> or by
              phone at <a href='tel:+14163022559'>+1 416-302-2559</a>.
            </ContentText>
          </Box>
          <BuyerButtons>
            <BuyerContinueButton
              onClick={() =>
                window.open(
                  'https://calendly.com/drjohn-dc/30min?month=2024-02&date=2024-02-23',
                  '_blank',
                )
              }
            >
              Book a Call Now
            </BuyerContinueButton>
          </BuyerButtons>
        </NdaPopupContainer>
      </PopupBox>
    )
  )
})

export default EMACAModal
