import Button from '@mui/material/Button'
import styled from 'styled-components/macro'
import { BasicLabel, NextButton } from '../../../pages/BuyerProfile/styled'
import {
  AutoHorizontalMargins,
  LargeParagraphText,
  MicroGap,
  RowFlexStyle,
  TitleText,
} from '../../../styles/shared-styled-components'
import { PopupBox, PopupContainer } from '../BuyerProfile/styled'

export const StyledPopupBox = styled(PopupBox)`
  justify-content: flex-start;
`

export const StyledPopupContainer = styled(PopupContainer)`
  height: fit-content;
`

export const HeaderTitle = styled(TitleText)`
  font-weight: bold;
  text-align: center;
`

export const HeaderInfo = styled(LargeParagraphText)`
  padding: 20px;
  text-algin: center;
  ${AutoHorizontalMargins}
`

export const BuyerForm = styled.div`
  padding: 20px;
  text-algin: center;
  ${AutoHorizontalMargins}
`

export const Box = styled.div`
  &.mt-2 {
    margin-top: 12px;
  }
`

export const BuyerButtons = styled.div`
  ${RowFlexStyle}
  justify-content: space-around;
  ${AutoHorizontalMargins}
  ${MicroGap}
`

export const BuyerContinueButton = styled(NextButton)``

export const BuyerBackButton = styled(Button)`
  border: 1px ${({ theme }) => theme.color.primary} solid;
  color: ${({ theme }) => theme.color.primary};
  border-radius: ${({ theme }) => theme.spacing.baseBorderRadius}px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.typography.typeFont};
  font-size: ${({ theme }) => theme.typography.sentenceFontSize};
  text-transform: none;
  padding: ${(props) =>
    props.disablepadding === 'true' ? '0px' : '10px 15px;'};
  &:hover {
    background-color: ${({ theme }) => theme.color.darkHover};
    color: ${({ theme }) => theme.color.white};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.color.disabled};
    color: ${({ theme }) => theme.color.white};
  }
`

export const BodyText = styled(BasicLabel)`
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const PurchaseIntentPriceChip = styled.div`
  border-radius: 4px;
  border: 1px solid #016299;
  background: #016299;
  color: #fff;
  font-family: Noto Sans KR;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
  letter-spacing: 0.1px;
  padding: 2px 5px;
  width: fit-content;
`

export const RequestTitle = styled(BodyText)`
  font-size: 24px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
`

export const RequestDescText = styled(BodyText)`
  font-size: 16px;
  color: #808080;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-transform: capitalize;
`

export const StyledFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 5px;
`

export const StyledColumnContainer = styled.div`
  display: flex;
  margin-top: 5px;
  margin-right: 5px;
  flex-direction: column;

  @media (max-width: 564px) {
    flex: 1 0 48%;
  }
  flex: 1 0 25%;
  gap: 2px;
`

export const StyledLabel = styled(BasicLabel)`
  font-size: 16px;
  color: #000;
  text-align: left;
`

export const StyledSpan = styled(BodyText)`
  font-size: 16px;
  color: #808080;
  text-align: left;
`
