import { useIsMobileScreen } from '../../../utils/view'
import { useMyUser } from '../../../api/hooks/users'
import {
  PracticeThumbnailContainerWithMultiplePracticesStyled,
  PracticeThumbnailContentContainerStyled,
  PracticeThumbnailContainerStyled,
  singleHoveredPracticeMenuWidth,
} from './styled'

const thumbnailTypes = {
  multiplePractices: 'multiple practices',
  singlePractice: 'single practice',
}

const useThumbnailContainerProps = (thumbnailType) => {
  const isMobileScreen = useIsMobileScreen()
  const { isAuthenticated } = useMyUser().auth0Context
  let thumbnailContainerPadding
  if (
    thumbnailType === thumbnailTypes.multiplePractices ||
    (isAuthenticated && !isMobileScreen)
  ) {
    thumbnailContainerPadding = 15
  } else if (
    (isAuthenticated && isMobileScreen) ||
    (!isAuthenticated && !isMobileScreen)
  ) {
    thumbnailContainerPadding = 20
  } else {
    thumbnailContainerPadding = 30
  }
  const endStateWidthOfSingleHoveredPracticeMenu =
    singleHoveredPracticeMenuWidth + thumbnailContainerPadding * 2

  return {
    isMobileScreen,
    thumbnailContainerPadding,
    endStateWidthOfSingleHoveredPracticeMenu,
  }
}

// The following components are used to increase padding on mobile devices

export const PracticeThumbnailContentContainer = (props) => {
  const isMobileScreen = useIsMobileScreen()

  return (
    <PracticeThumbnailContentContainerStyled
      isMobileScreen={isMobileScreen}
      {...props}
    >
      {props.children}
    </PracticeThumbnailContentContainerStyled>
  )
}

export const PracticeThumbnailContainerWithMultiplePractices = (props) => {
  const {
    thumbnailContainerPadding,
    endStateWidthOfSingleHoveredPracticeMenu,
  } = useThumbnailContainerProps(thumbnailTypes.multiplePractices)

  return (
    <PracticeThumbnailContainerWithMultiplePracticesStyled
      thumbnailContainerPadding={thumbnailContainerPadding}
      endStateWidthOfSingleHoveredPracticeMenu={
        endStateWidthOfSingleHoveredPracticeMenu
      }
      {...props}
    >
      {props.children}
    </PracticeThumbnailContainerWithMultiplePracticesStyled>
  )
}

export const PracticeThumbnailContainer = (props) => {
  const {
    thumbnailContainerPadding,
    endStateWidthOfSingleHoveredPracticeMenu,
  } = useThumbnailContainerProps(thumbnailTypes.singlePractice)

  return (
    <PracticeThumbnailContainerStyled
      thumbnailContainerPadding={thumbnailContainerPadding}
      endStateWidthOfSingleHoveredPracticeMenu={
        endStateWidthOfSingleHoveredPracticeMenu
      }
      {...props}
    >
      {props.children}
    </PracticeThumbnailContainerStyled>
  )
}
