import { useMutationWithErrorLogging } from '../../utils/hooks'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useBuyerById } from '../../api/hooks/buyers'
import {
  UPDATE_BUYER,
  UPLOAD_BUYER_FILE,
  UPLOAD_BUYER_PROFILE_PICTURE,
} from '../../api/mutations/buyers'
import LoadingIndicator from '../../components/LoadingIndicator'
import NDAModal from '../../components/Micro/MyAcquisition/NDAModal'
import FileThumbnail from '../../components/Micro/Thumbnails/FileThumbnail/FileThumbnail'
import Header from '../../components/NavBar/Header'
import {
  BackgroundContainer,
  ErrorHelperText,
  PageContainer,
} from '../../styles/shared-styled-components'
import { getCapitalizedWord } from '../../utils/naming'
import folderIcon from './folder.svg'
import personIcon from './person.svg'
import {
  BasicLabel,
  CancelButton,
  ColumnFlexContainer,
  FlexContainer,
  FullPageFlexContainer,
  NextButton,
  NumericTextField,
  ResponsiveFlexContainer,
  StyledAvatar,
  StyledBasicLabel,
  StyledDropBoxWrapper,
  StyledFolderIcon,
  StyledLabel,
  StyledNoteLabel,
  StyledPhotoLabel,
  StyledUploadContainer,
  TitleText,
  Wrapper,
} from './styled'
import { usePracticeById } from '../../api/hooks/practices'
import { buyerTypes } from '../../utils/constants'
import { getFormattedMoneyString } from '../../utils/numbers'

function addCommas(num) {
  switch (typeof num) {
    case 'string':
      return parseFloat(num).toLocaleString()
    case 'number':
      return num.toLocaleString()
    default:
      return null
  }
}

function removeNonNumericalValues(str) {
  const returnVal = parseFloat(str?.replace(/\D/g, '')) || undefined
  return returnVal
}

const BuyerProfile = ({
  user,
  width,
  ismobilescreen,
  buyerProfileMade,
  setbuyerProfileMade,
}) => {
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false)
  const [profile, setProfile] = useState(null)
  console.log(profile)
  const [errors, setErrors] = useState(null)
  const [uploadingAvatar, setUploadingAvatar] = useState(false)
  const [uploadingFile, setUploadingFile] = useState(false)
  const [languages, setLanguages] = useState([])
  const [procedures, setProcedures] = useState([])
  const [showNdaPopup, setShowNdaPopup] = useState(false)
  const { buyerId } = useParams()
  const { buyer } = useBuyerById(buyerId)
  const { search } = useLocation()
  const [updateBuyerMutation] = useMutationWithErrorLogging(UPDATE_BUYER)
  const [uploadBuyerProfilePicture] = useMutationWithErrorLogging(
    UPLOAD_BUYER_PROFILE_PICTURE,
  )
  const [uploadBuyerFile] = useMutationWithErrorLogging(UPLOAD_BUYER_FILE)

  const queryParams = new URLSearchParams(search)
  const practiceId = queryParams.get('practice_id')

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  })

  useEffect(() => {
    if (buyer) {
      setProfile({ ...buyer, id: buyer.id })
      if (buyer.languages) {
        setLanguages(buyer.languages.split(', '))
      }
      if (buyer.procedures) {
        setProcedures(buyer.procedures.split(', '))
      }
    }
  }, [buyer])

  const handleProfilePicture = useCallback(
    (e) => {
      const pictureFile = e.target.files[0]

      const reader = new FileReader()
      reader.readAsDataURL(pictureFile)
      reader.onloadend = async () => {
        try {
          setUploadingAvatar(true)
          await uploadBuyerProfilePicture({
            variables: {
              id: buyerId,
              picture: {
                file: reader.result,
              },
            },
            onCompleted: (data) => {
              const pictureUrl = data.uploadBuyerProfilePicture
              if (pictureUrl) {
                setProfile({ ...profile, picture: pictureUrl })
              }
              setUploadingAvatar(false)
            },
          })
        } catch (error) {
          console.error('Error uploading production report file:', error)
          setUploadingAvatar(false)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [profile, buyerId],
  )

  var years = Array.from(Array(new Date().getFullYear() - 1949), (_, i) =>
    (i + 1950).toString(),
  )

  const handleInput = (e) => {
    e.preventDefault()
    setProfile({
      ...profile,
      [e.target.name]: e.target.value,
    })
  }

  const averageBillingsRef = useRef()
  useMemo(() => {
    averageBillingsRef.current?.addEventListener('keypress', function (evt) {
      if (evt.which < 48 || evt.which > 57) {
        evt.preventDefault()
      }
    })
  }, [averageBillingsRef])

  const handleLanguage = (e) => {
    let modifyLanguage
    if (e.target.checked) {
      modifyLanguage = [...languages, e.target.value]
    } else {
      modifyLanguage = languages.filter(
        (language) => language !== e.target.value,
      )
    }
    setLanguages(modifyLanguage)
    setProfile({ ...profile, languages: modifyLanguage.join(',') })
  }

  const handleProcedure = (e) => {
    let modifyProcedure
    if (e.target.checked) {
      modifyProcedure = [...procedures, e.target.value]
    } else {
      modifyProcedure = procedures.filter(
        (procedure) => procedure !== e.target.value,
      )
    }
    setProcedures(modifyProcedure)
    setProfile({ ...profile, procedures: modifyProcedure.join(',') })
  }

  const validate = useCallback(() => {
    const errors = {}
    let hasErrors = false

    if (!profile) {
      errors.general = 'Profile is missing.'
    } else {
      const requiredFields = [
        'year_of_graduation',
        'school_of_graduation',
        'languages',
        'procedures',
        'average_monthly_production',
        'no_of_practices',
        'file',
      ]

      requiredFields.forEach((field) => {
        if (typeof profile[field] === 'string') {
          if (!profile[field]?.trim()) {
            errors[field] = `${getCapitalizedWord(
              field.replace(/_/g, ' '),
            )} is required.`
            hasErrors = true
          }
        } else if (profile[field] === undefined || profile[field] === null) {
          errors[field] = `${getCapitalizedWord(
            field.replace(/_/g, ' '),
          )} is required.`
          hasErrors = true
        }
      })
    }
    setErrors(errors)

    return !hasErrors
  }, [profile])

  const handleFileUpload = (e) => {
    e.preventDefault()
    const [file] = Array.from(e.dataTransfer?.files || e.target.files)
    if (!file) return

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = async () => {
      try {
        setUploadingFile(true)
        await uploadBuyerFile({
          variables: {
            id: buyerId,
            file: {
              file: reader.result,
            },
          },
          onCompleted: (data) => {
            const fileUrl = data.uploadBuyerFile
            if (fileUrl) {
              setProfile({ ...profile, file: fileUrl })
            }
            setUploadingFile(false)
          },
        })
      } catch (error) {
        console.error('Error uploading production report file:', error)
      }
    }
  }

  const onRemoveFile = () => {
    setProfile((old) => ({ ...old, file: null }))
  }

  useEffect(() => {
    if (hasAttemptedSubmit) validate()
  }, [profile, hasAttemptedSubmit, validate])

  const handleContinue = useCallback(() => {
    setHasAttemptedSubmit(true)
    if (uploadingAvatar || uploadingFile || !validate()) return

    const variables = {
      ...profile,
      average_monthly_production:
        profile?.average_monthly_production.toString(),
      no_of_practices: profile?.no_of_practices.toString(),
    }
    console.log(variables)
    updateBuyerMutation({
      variables,
      onCompleted: async (data) => {
        setShowNdaPopup(true)
      },
    })
  }, [uploadingAvatar, uploadingFile, profile, validate, updateBuyerMutation])

  const handleNumberInput = (e) => {
    const value = removeNonNumericalValues(e.target.value)
    setProfile({
      id: buyerId,
      ...profile,
      [e.target.name]: value,
    })
  }

  return buyer && profile ? (
    <>
      {showNdaPopup && (
        <NDAModal
          toggle={() => setShowNdaPopup(false)}
          buyerId={buyerId}
          practiceId={practiceId}
        />
      )}
      <Header user={user} width={width} ismobilescreen={ismobilescreen} />
      <BackgroundContainer>
        <PageContainer>
          <FullPageFlexContainer>
            <Wrapper>
              <FlexContainer>
                <>
                  <TitleText>Buyer Profile</TitleText>

                  <ResponsiveFlexContainer>
                    <StyledUploadContainer>
                      <StyledLabel>Upload a profile picture</StyledLabel>
                      <div style={{ position: 'relative' }}>
                        <StyledAvatar
                          className={
                            profile?.picture ? 'avatar' : 'placeholder'
                          }
                          src={
                            !uploadingAvatar && (profile?.picture ?? personIcon)
                          }
                        >
                          {uploadingAvatar && <LoadingIndicator size={30} />}
                        </StyledAvatar>
                        <StyledPhotoLabel>
                          <PhotoCameraIcon fontSize='small' />
                          <VisuallyHiddenInput
                            type='file'
                            onChange={handleProfilePicture}
                          />
                        </StyledPhotoLabel>
                      </div>
                    </StyledUploadContainer>

                    <ColumnFlexContainer>
                      <Box sx={{ minWidth: 120 }}>
                        <FormControl
                          fullWidth
                          error={errors?.year_of_graduation}
                        >
                          <InputLabel>
                            In what year did you graduate?*
                          </InputLabel>
                          <Select
                            name='year_of_graduation'
                            value={profile?.year_of_graduation || ''}
                            label='In what year did you graduate?*'
                            onChange={handleInput}
                          >
                            {years.map((year) => (
                              <MenuItem value={year}>{year}</MenuItem>
                            ))}
                          </Select>
                          <ErrorHelperText style={{ marginLeft: 12 }}>
                            {errors?.year_of_graduation}
                          </ErrorHelperText>
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl fullWidth>
                          <TextField
                            name='school_of_graduation'
                            value={profile?.school_of_graduation}
                            label='What dental school did you graduate from?*'
                            variant='outlined'
                            placeholder='Enter a school name'
                            onChange={handleInput}
                            error={errors?.school_of_graduation}
                            helperText={errors?.school_of_graduation}
                          />
                        </FormControl>
                      </Box>
                      <Box
                        sx={{
                          border: '1px solid #016299',
                          borderRadius: '4px',
                          padding: '14px',
                        }}
                      >
                        <StyledBasicLabel error={errors?.languages}>
                          Which languages do you speak?<sup>*</sup>
                        </StyledBasicLabel>
                        <FormControl fullWidth>
                          <Box sx={{ display: 'flex' }}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={handleLanguage}
                                    value='English'
                                    checked={languages?.includes('English')}
                                  />
                                }
                                label='English'
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={handleLanguage}
                                    value='French'
                                    checked={languages?.includes('French')}
                                  />
                                }
                                label='French'
                              />
                            </FormGroup>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={handleLanguage}
                                    value='Hindi'
                                    checked={languages?.includes('Hindi')}
                                  />
                                }
                                label='Hindi'
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={handleLanguage}
                                    value='Other'
                                    checked={languages?.includes('Other')}
                                  />
                                }
                                label='Other'
                              />
                            </FormGroup>
                          </Box>
                        </FormControl>
                      </Box>
                      <ErrorHelperText
                        style={{ marginLeft: 12, marginTop: -10 }}
                      >
                        {errors?.languages}
                      </ErrorHelperText>

                      <Box
                        sx={{
                          border: '1px solid #016299',
                          borderRadius: '4px',
                          padding: '14px',
                        }}
                      >
                        <StyledBasicLabel error={errors?.procedures}>
                          What procedures can you perform?<sup>*</sup>
                        </StyledBasicLabel>
                        <FormControl fullWidth>
                          <Box sx={{ display: 'flex' }}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={handleProcedure}
                                    value='Implant'
                                    checked={procedures?.includes('Implant')}
                                  />
                                }
                                label='Implant'
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={handleProcedure}
                                    value='Surgery'
                                    checked={procedures?.includes('Surgery')}
                                  />
                                }
                                label='Surgery'
                              />
                            </FormGroup>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={handleProcedure}
                                    value='Orthodontics'
                                    checked={procedures?.includes(
                                      'Orthodontics',
                                    )}
                                  />
                                }
                                label='Orthodontics'
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={handleProcedure}
                                    value='Other'
                                    checked={procedures?.includes('Other')}
                                  />
                                }
                                label='Other'
                              />
                            </FormGroup>
                          </Box>
                        </FormControl>
                      </Box>
                      <ErrorHelperText
                        style={{ marginLeft: 12, marginTop: -10 }}
                      >
                        {errors?.procedures}
                      </ErrorHelperText>
                    </ColumnFlexContainer>

                    <ColumnFlexContainer>
                      <Box>
                        <FormControl fullWidth>
                          <NumericTextField
                            ref={averageBillingsRef}
                            name='average_monthly_production'
                            value={addCommas(
                              profile?.average_monthly_production,
                            )}
                            label='What is your average monthly production?*'
                            variant='outlined'
                            placeholder='Enter a value'
                            onChange={handleNumberInput}
                            error={errors?.average_monthly_production}
                            helperText={errors?.average_monthly_production}
                          />
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl fullWidth>
                          <NumericTextField
                            name='no_of_practices'
                            value={addCommas(profile?.no_of_practices)}
                            label='How many practices do you own?*'
                            variant='outlined'
                            placeholder='Enter a value'
                            onChange={handleNumberInput}
                            error={errors?.no_of_practices}
                            helperText={errors?.no_of_practices}
                          />
                        </FormControl>
                      </Box>
                      <Box>
                        <StyledBasicLabel error={errors?.file}>
                          Upload a piece of photo-id<sup>*</sup>
                        </StyledBasicLabel>
                      </Box>
                      <StyledDropBoxWrapper
                        className='drop-zone'
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={handleFileUpload}
                      >
                        {uploadingFile ? (
                          <Box
                            sx={{
                              width: '80px',
                              height: '80px',
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <LoadingIndicator size={60} />
                          </Box>
                        ) : profile?.file ? (
                          <FileThumbnail
                            filePath={profile.file}
                            onRemove={onRemoveFile}
                          />
                        ) : (
                          <StyledFolderIcon src={folderIcon} />
                        )}
                        <BasicLabel>
                          Drag your files here or&nbsp;
                          <StyledBasicLabel style={{ cursor: 'pointer' }}>
                            browse
                            <VisuallyHiddenInput
                              type='file'
                              onChange={handleFileUpload}
                            />
                          </StyledBasicLabel>
                        </BasicLabel>
                        <StyledNoteLabel>Supports PDF, CSV</StyledNoteLabel>
                      </StyledDropBoxWrapper>
                      <ErrorHelperText
                        style={{ marginLeft: 12, marginTop: -10 }}
                      >
                        {errors?.file}
                      </ErrorHelperText>
                    </ColumnFlexContainer>
                  </ResponsiveFlexContainer>
                </>
              </FlexContainer>
            </Wrapper>
            <ResponsiveFlexContainer block footer>
              {ismobilescreen ? (
                <>
                  <NextButton
                    disabled={uploadingAvatar || uploadingFile}
                    onClick={handleContinue}
                  >
                    Continue
                  </NextButton>
                  <CancelButton
                    disabled={uploadingAvatar || uploadingFile}
                    onClick={() => window.history.back()}
                  >
                    Back
                  </CancelButton>
                </>
              ) : (
                <>
                  <CancelButton
                    disabled={uploadingAvatar || uploadingFile}
                    onClick={() => window.history.back()}
                  >
                    Back
                  </CancelButton>
                  <NextButton
                    disabled={uploadingAvatar || uploadingFile}
                    onClick={handleContinue}
                  >
                    Continue
                  </NextButton>
                </>
              )}
            </ResponsiveFlexContainer>
          </FullPageFlexContainer>
        </PageContainer>
      </BackgroundContainer>
    </>
  ) : (
    <LoadingIndicator fullScreen />
  )
}

export default BuyerProfile
