import { useSubscription } from '@apollo/client'
import { useQueryWithErrorLogging } from '../../utils/hooks'
import get from 'lodash/get'
import { useEffect, useState } from 'react'
import { NOTIFICATION_SUBSCRIPTION } from '../mutations/notifications'
import { GET_MY_NOTIFICATIONS } from '../queries/notifications'

export const useNotifications = (user) => {
  const [notifications, setNotifications] = useState([])

  const { data: myNotifs, refetch } = useQueryWithErrorLogging(
    GET_MY_NOTIFICATIONS,
    {
      fetchPolicy: 'network-only',
      skip: !user?.id,
    },
  )

  useEffect(() => {
    const notifData = get(myNotifs, 'getUserNotifications', [])
    setNotifications(notifData)
  }, [myNotifs])

  const { data, error, loading } = useSubscription(NOTIFICATION_SUBSCRIPTION, {
    fetchPolicy: 'cache-first',
    skip: !user,
  })

  if (data) {
    // Handle new notification data
    console.log('New Notification:', data.notificationAdded)
    setNotifications((old) => [...old, data])
  }

  return {
    error,
    loading,
    notifications,
    refetch,
  }
}
