import { ToggleListingChip } from '../../Micro/ToggleListingViewChip/styled'
import { RecenterIcon } from './styled'

const RecenterMapChip = ({ onClick }) => {
  return (
    <ToggleListingChip onClick={onClick}>
      Re-center
      <RecenterIcon />
    </ToggleListingChip>
  )
}

export default RecenterMapChip
