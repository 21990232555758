import { useMutationWithErrorLogging } from '../../../utils/hooks'
import _ from 'lodash'
import React, { forwardRef, useImperativeHandle } from 'react'
import { UPLOAD_PRACTICE_PRODUCTION_REPORT } from '../../../api/mutations/practices'
import { SubtitleText } from '../../../pages/CreatePractice/DueDiligence/styled'
import {
  ThumbnailContainer,
  UploadIcon,
} from '../../../styles/shared-styled-components'
import FileInput from '../../Micro/FileInput'
import { UploadBox } from './styled'
import LoadingIndicator from '../../LoadingIndicator'

function getNameOfFileFromURL(url) {
  return url.split('/').pop()
}

const DocumentUploader = forwardRef((props, ref) => {
  const filesAlreadyUploaded = !_.isEmpty(props.uploadedFilesUrls)
  const [uploadPracticeProductionReportMutation, { loading }] =
    useMutationWithErrorLogging(UPLOAD_PRACTICE_PRODUCTION_REPORT)

  const handleUpload = async (event) => {
    event.preventDefault()
    const [productionReportFile] = Array.from(
      event.dataTransfer?.files || event.target.files,
    )

    const reader = new FileReader()
    reader.readAsDataURL(productionReportFile)
    reader.onloadend = async () => {
      try {
        await uploadPracticeProductionReportMutation({
          variables: {
            id: props.practiceId,
            productionReport: {
              file: reader.result,
              filename: productionReportFile.name,
            },
          },
          onCompleted: (data) => {
            const uploadedProductionReportUrl =
              data.uploadPracticeProductionReport
            if (uploadedProductionReportUrl) {
              if (props.onUploadComplete) {
                document.getElementById('all').value = ''
                props.onUploadComplete(uploadedProductionReportUrl)
              }
            }
          },
        })
      } catch (error) {
        console.error('Error uploading production report file:', error)
      }
    }
  }

  useImperativeHandle(ref, () => ({
    filesAlreadyUploaded: filesAlreadyUploaded,
  }))

  return (
    <>
      <UploadBox onDragOver={(e) => e.preventDefault()} onDrop={handleUpload}>
        <>
          {!_.isEmpty(props.uploadedFilesUrls) &&
            _.map(props.uploadedFilesUrls, (url) => (
              <ThumbnailContainer
                style={{
                  margin: '0px',
                  overflow: 'hidden',
                  padding: '10px',
                  display: 'block',
                }}
              >
                <SubtitleText>
                  <a href={url} download>
                    {getNameOfFileFromURL(url)}
                  </a>
                </SubtitleText>
              </ThumbnailContainer>
            ))}
          {loading ? <LoadingIndicator /> : <UploadIcon />}
          <FileInput
            fileType='all'
            onUpload={handleUpload}
            disabled={loading}
            filesAlreadyUploaded={filesAlreadyUploaded}
          />
        </>
      </UploadBox>
    </>
  )
})
export default DocumentUploader
