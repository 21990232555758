import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import styled from 'styled-components/macro'
import { DefaultIconStyle } from '../../../styles/shared-styled-components'

export const DownArrow = styled(ExpandMoreIcon)`
  ${DefaultIconStyle}
  color: ${(props) =>
    props.color === 'primary' ? props.theme.color.primary : 'inherit'};
`

export const UpArrow = styled(ExpandLessIcon)`
  ${DefaultIconStyle}
  color: ${(props) =>
    props.color === 'primary' ? props.theme.color.primary : 'inherit'};
`
