import { GET_MY_PRACTICES, GET_PRACTICE_BY_ID } from '../api/queries/practices'
import { GET_MY_USER } from '../api/queries/users'
import { GET_PRACTICE_VALUATIONS } from '../api/queries/valuations'

export const updatePracticeByIdInCache = async (practiceId, data, cache) => {
  const practiceDataFetch = cache.readQuery({
    query: GET_PRACTICE_BY_ID,
    variables: {
      id: practiceId,
    },
  })

  if (practiceDataFetch) {
    const existingPractice = practiceDataFetch.getPracticeById
    const updatedPractice = {
      ...existingPractice,
      ...data,
    }

    // Update the cache
    await cache.writeQuery({
      query: GET_PRACTICE_BY_ID,
      variables: {
        id: practiceId,
      },
      data: {
        getPracticeById: updatedPractice,
      },
    })
  }
}

export const updateUserPracticesInCache = async (data, cache) => {
  const practiceDataFetch = cache.readQuery({
    query: GET_MY_PRACTICES,
  })

  if (practiceDataFetch) {
    const existingPractices = practiceDataFetch.getMyPractices
    const updatedPractices = [...existingPractices, data]

    // Update the cache
    await cache.writeQuery({
      query: GET_MY_PRACTICES,
      data: {
        getMyPractices: updatedPractices,
      },
    })
  }
}

export const updatePracticeValuationsInCache = async (
  practiceId,
  data,
  cache,
) => {
  const valuationsDataFetch = cache.readQuery({
    query: GET_PRACTICE_VALUATIONS,
    variables: {
      practiceId,
    },
  })

  if (valuationsDataFetch) {
    const existingValuations = valuationsDataFetch.getPracticeValuations
    const updatedValuations = [...existingValuations, data]

    // Update the cache
    await cache.writeQuery({
      query: GET_PRACTICE_VALUATIONS,
      variables: {
        practiceId,
      },
      data: {
        getPracticeValuations: updatedValuations,
      },
    })
  }
}

export const updateUserHasCreatedValuationInCache = async (user, cache) => {
  const userFetch = cache.readQuery({
    query: GET_MY_USER,
  })

  const updatedUser = {
    ...user,
    hasCreatedValuation: true,
  }

  if (userFetch) {
    // Update the cache
    await cache.writeQuery({
      query: GET_MY_USER,
      data: {
        getMyUser: updatedUser,
      },
    })
  }
}

export default {
  updatePracticeByIdInCache,
  updateUserPracticesInCache,
}
