/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import { usePracticeById } from '../../../api/hooks/practices'
import {
  FormTitle,
  SubtitleText,
} from '../../../pages/CreatePractice/DueDiligence/styled'
import DocumentUploader from './DocumentUploader'
import SkipSectionWarningModal from './SkipSectionWarningModal'
import { Box } from './styled'
import _ from 'lodash'
import CreatePracticeInput from '../CreatePracticeInput'

const PracticeManagementReports = forwardRef(
  (
    {
      width,
      subStepName,
      formProgress,
      onSkip,
      onClickNext,
      handleCanSkipChanged,
      onUpdateFinancialDetails,
    },
    ref,
  ) => {
    const { practiceId } = useParams()
    const { practice } = usePracticeById(practiceId)
    const uploaderRef = useRef()
    const [isSkipable, setIsSkipable] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [uploadedDocsUrls, setUploadedDocsUrls] = useState(
      practice?.dueDiligence?.practiceManagementReports || {},
    )
    const [currentSection, setCurrentSection] = useState(0)
    const [fieldErrors, setFieldErrors] = useState()

    const sections = useMemo(
      () => [
        {
          name: 'totalAnnualCollections',
          title: '4.1 Total Production & Collections by the Practice Annually',
          subtitle: 'Note: Please upload the documents listed below.',
          listedItems: [
            'For the Last 12 Months',
            'Year-to-Date (January to Last Completed Month)',
            'Last 3 Fiscal Years',
          ],
        },
        {
          name: 'totalMonthlyCollections',
          title: '4.2 Total Production & Collection by Provider per Month',
          subtitle: 'Note: Please upload the documents listed below.',
          listedItems: [
            'For the Last 12 Months',
            'Year-to-Date (January to Last Completed Month)',
            'Last 3 Fiscal Years',
          ],
        },
        {
          name: 'procedureCodesPerMonth',
          title: '4.3 Procedure Codes by month per Provider',
          subtitle: 'Note: Please upload the documents listed below.',
          listedItems: [
            'For the Last 12 Months',
            'Year-to-Date (January to Last Completed Month)',
            'Last 3 Fiscal Years',
          ],
        },
        {
          name: 'procedureCodesPerYear',
          title: '4.4 Procedure Codes Annually for Overall Practice',
          subtitle: 'Note: Please upload the documents listed below.',
          listedItems: [
            'For the Last 12 Months',
            'Year-to-Date (January to Last Completed Month)',
            'Last 3 Fiscal Years',
          ],
        },
        {
          name: 'agedAccRecTotalPractice',
          title: '4.5 Aged Account Receivable Total Practice',
          subtitle: 'Note: Please upload the documents listed below.',
          listedItems: [
            'X-Ray Floor Plans',
            'Ministry of Health Approvals for each X-Ray',
            'Sedation Permits with Last Date of Inspection, if applicable',
            'HARP Inspections reports for the last 2 years',
            'Recommendations from reports not completed to date',
            'Copy of Associate Licences',
          ],
        },
        {
          name: 'agedAccRecProvider',
          title: '4.6 Aged Account Receivables by Provider​',
          subtitle: 'Note: Please upload the documents listed below.',
          listedItems: [
            'As of Last Completed Month',
            'As of Each Last 3 Fiscal Year Ends',
          ],
        },
        {
          name: 'patientCount',
          title: '4.7 Patient count',
          fields: [
            {
              label: 'Number of active patients *',
              id: 'activePatients',
              fieldType: 'text',
            },
            {
              label: 'Number of new patients per month *',
              id: 'newPatientsPermonth',
              fieldType: 'text',
            },
          ],
        },
        {
          name: 'referredOutCases',
          title: '4.8 Referrals​',
          fields: [
            {
              label: 'Avg referrals per month to Orthodontist *',
              id: 'avgReferralsToOrthodontistPerMonth',
              fieldType: 'text',
            },
            {
              label: 'Avg referrals per month to Perio/Oral Surgeon *',
              id: 'avgReferralsToPerioOralSurgeonPerMonth',
              fieldType: 'text',
            },
            {
              label: 'Avg referrals per month to Endodontist *',
              id: 'avgReferralsToEndodontistPerMonth',
              fieldType: 'text',
            },
            {
              label: 'Avg referrals per month to Pediatric Dentist*',
              id: 'avgReferralsToPediatricDentistPerMonth',
              fieldType: 'text',
            },
          ],
        },
      ],
      [],
    )

    useEffect(() => {
      const currentPath = window.location.pathname
      const queryParams = new URLSearchParams(window.location.search)
      const navType = queryParams.get('navType')
      if (navType === 'back') {
        setCurrentSection(sections.length - 1)
      }
      queryParams.delete('navType')
      window.history.replaceState(
        {},
        '',
        `${currentPath}?${queryParams.toString()}`,
      )
    }, [sections])

    const handleUpdate = useCallback(() => {
      if (uploadedDocsUrls) onUpdateFinancialDetails(uploadedDocsUrls)
    }, [uploadedDocsUrls])

    useEffect(() => {
      setIsSkipable(
        sections[currentSection].fields
          ? false
          : _.isEmpty(
              uploadedDocsUrls &&
                uploadedDocsUrls[sections[currentSection].name],
            ),
      )
    }, [uploadedDocsUrls, currentSection, sections, practice])

    useEffect(() => {
      if (handleCanSkipChanged) {
        handleCanSkipChanged(isSkipable)
      }
    }, [isSkipable])

    const handleOnBackPressed = useCallback(
      (callback) => {
        if (currentSection > 0) {
          setCurrentSection(currentSection - 1)
        } else {
          callback()
        }
      },
      [currentSection],
    )

    useImperativeHandle(ref, () => ({
      submit: handleFormSubmit,
      onBack: handleOnBackPressed,
      isSkipable: isSkipable,
    }))

    const isFormValid = useCallback(() => {
      const payload = uploadedDocsUrls[sections[currentSection].name]
      const errors = {}
      const updatedFieldErrors = {
        activePatients:
          !payload?.activePatients && 'Active Patients is required.',
        newPatientsPermonth:
          !payload?.newPatientsPermonth &&
          'New Patients Per Month is required.',
        avgReferralsToOrthodontistPerMonth:
          !payload?.avgReferralsToOrthodontistPerMonth &&
          'Average Referrals to Orthodontist Per Month is required.',
        avgReferralsToPerioOralSurgeonPerMonth:
          !payload?.avgReferralsToPerioOralSurgeonPerMonth &&
          'Average Referrals to Perio/Oral Surgeon Per Month is required.',
        avgReferralsToEndodontistPerMonth:
          !payload?.avgReferralsToEndodontistPerMonth &&
          'Average Referrals to Endodontist Per Month is required.',
        avgReferralsToPediatricDentistPerMonth:
          !payload?.avgReferralsToPediatricDentistPerMonth &&
          'Average Referrals to Pediatric Dentist Per Month is required.',
      }

      sections[currentSection].fields.forEach((field) => {
        errors[field.id] = updatedFieldErrors[field.id]
      })

      setFieldErrors(errors)

      const isValid = Object.values(errors).every((error) => error === false)
      if (!isValid) {
        alert('Some required fields are empty')
      }

      return isValid
    }, [uploadedDocsUrls, sections, currentSection])

    const handleFormSubmit = useCallback(
      (callback, config) => {
        if (config?.override && callback) {
          callback(uploadedDocsUrls)
        } else if (isSkipable) {
          if (!!(formProgress && !_.isEmpty(formProgress.skipped))) {
            if (currentSection < sections.length - 1) {
              setCurrentSection(currentSection + 1)
            } else {
              onSkip()
            }
          } else {
            setShowModal(true)
          }
        } else {
          // handle form submission
          if (currentSection < sections.length - 1) {
            if (sections[currentSection].fields) {
              if (isFormValid()) {
                setCurrentSection(currentSection + 1)
              } else {
                return
              }
            } else {
              setCurrentSection(currentSection + 1)
            }
          } else {
            if (sections[currentSection].fields) {
              if (!isFormValid()) {
                return
              }
            }
            if (callback) {
              const allFieldsFilled = _.every(
                uploadedDocsUrls,
                (field) => !_.isNull(field),
              )
              if (allFieldsFilled) {
                callback(uploadedDocsUrls)
              } else {
                onSkip()
              }
            } else if (onClickNext) {
              onClickNext()
            }
          }
        }
      },
      [isSkipable, uploaderRef, currentSection, uploadedDocsUrls, formProgress],
    )

    const handleOnUploadComplete = useCallback(
      (url) => {
        const updatedDocs = {
          ...uploadedDocsUrls,
          [sections[currentSection].name]: _.concat(
            uploadedDocsUrls[sections[currentSection].name] || [],
            url,
          ),
        }
        setUploadedDocsUrls(updatedDocs)
        onUpdateFinancialDetails(updatedDocs)
      },
      [currentSection, uploadedDocsUrls, sections, uploaderRef, handleUpdate],
    )

    const handleNameChange = useCallback(
      (e) => {
        setUploadedDocsUrls((old) => ({
          ...old,
          [sections[currentSection].name]: {
            ...old[sections[currentSection].name],
            [e.target.id]: e.target.value,
          },
        }))
      },
      [currentSection, sections],
    )

    const handleOnSkip = useCallback(() => {
      setShowModal(false)
      const hasNextSection = currentSection < sections.length - 1
      if (hasNextSection) {
        setCurrentSection(currentSection + 1)
      } else {
        onSkip()
      }
    }, [currentSection, sections])

    const currentSectionUploadedFiles = useMemo(() => {
      return uploadedDocsUrls[sections[currentSection]?.name]
    }, [uploadedDocsUrls, sections, currentSection])

    return (
      <Box>
        <FormTitle>Practice Management Reports</FormTitle>
        <FormTitle style={{ marginTop: '10px', fontSize: '16px' }}>
          {sections[currentSection].title}
        </FormTitle>
        <SubtitleText className='note' style={{ margin: '10px 0px 10px 0px' }}>
          {sections[currentSection].subtitle}
        </SubtitleText>

        {_.isEmpty(sections[currentSection].fields) ? (
          <>
            <SubtitleText className='note' style={{ margin: 0 }}>
              {sections[currentSection]?.listedItems.map((item) => (
                <li>{item}</li>
              ))}
            </SubtitleText>

            <DocumentUploader
              ref={uploaderRef}
              onUploadComplete={handleOnUploadComplete}
              practiceId={practiceId}
              uploadedFilesUrls={currentSectionUploadedFiles || []}
            />
          </>
        ) : (
          _.map(sections[currentSection].fields, (field, i) => (
            <div style={{ marginTop: 20 }}>
              <CreatePracticeInput
                key={i}
                value={
                  uploadedDocsUrls[sections[currentSection]?.name]?.[field.id]
                }
                label={field.label}
                width={width}
                onChange={handleNameChange}
                onBlur={handleUpdate}
                fieldType={field.fieldType}
                elementId={field.id}
                errorMessage={fieldErrors?.[field.id]}
                submittedDataWithErrors={!_.isNull(fieldErrors?.[field.id])}
              />
            </div>
          ))
        )}
        <SkipSectionWarningModal
          subStepName={subStepName}
          formProgress={formProgress}
          practiceId={practiceId}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onSkip={handleOnSkip}
        />
      </Box>
    )
  },
)

export default PracticeManagementReports
