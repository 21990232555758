import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Paths from '../../Paths'
import CreatePracticeButton from '../../components/Micro/CreatePracticeButton'
import Header from '../../components/NavBar/Header'
import LocationSearchBox from '../../components/Search/LocationSearchBox/LocationSearchBox'
import {
  BackgroundContainer,
  DivWithBackgroundImage,
  FullPageContentContainer,
  PageContainer,
} from '../../styles/shared-styled-components'
import { getBrowsePracticesPathWithLocationSearch } from '../../utils/routing'
import {
  screenWidthIsMediumMobileSizeOrSmaller,
  screenWidthIsSmallMobileSizeOrSmaller,
} from '../../utils/view'
import {
  BuySellContainer,
  Card,
  CardAsset,
  CardLeft,
  CardRight,
  CardSubtitleText,
  CardTitleText,
  Container,
  HeaderText,
  HeaderTextContainer,
  LocationSearchForm,
} from './styled'

const SellOrBuy = ({ user, width, ismobilescreen }) => {
  const navigate = useNavigate()

  const isSmallMobileScreen = screenWidthIsSmallMobileSizeOrSmaller(width)
  const isMediumMobileScreen = screenWidthIsMediumMobileSizeOrSmaller(width)

  const [locationSearch, setLocationSearch] = useState()

  const onChangeLocation = (search) => {
    setLocationSearch(search?.target?.value)
  }

  const onSearchByLocation = () => {
    navigate(getBrowsePracticesPathWithLocationSearch(locationSearch))
  }

  return (
    <>
      <Header user={user} width={width} ismobilescreen={ismobilescreen} />
      <BackgroundContainer>
        <PageContainer fullHeight>
          <FullPageContentContainer>
            <DivWithBackgroundImage image={Paths.practiceStockImage}>
              <Container>
                <HeaderTextContainer>
                  <HeaderText ismobilescreen={ismobilescreen}>
                    The simple way to sell or buy a dental practice starts here.
                  </HeaderText>
                </HeaderTextContainer>
                <BuySellContainer>
                  <Card ismobilescreen={isMediumMobileScreen}>
                    {!isSmallMobileScreen && (
                      <CardLeft>
                        <CardAsset src={Paths.houseCartoon} />
                      </CardLeft>
                    )}
                    <CardRight ismobilescreen={isSmallMobileScreen}>
                      <CardTitleText>Sell a practice</CardTitleText>
                      <CardSubtitleText ismobilescreen={isSmallMobileScreen}>
                        Upload your info in a few easy steps, then sit back and
                        receive offers -- with as much anonymity as you like.
                      </CardSubtitleText>
                      <CreatePracticeButton user={user} />
                    </CardRight>
                  </Card>
                  <Card>
                    {!isSmallMobileScreen && (
                      <CardLeft>
                        <CardAsset src={Paths.houseCartoon} />
                      </CardLeft>
                    )}
                    <CardRight ismobilescreen={isSmallMobileScreen}>
                      <CardTitleText>Buy a practice</CardTitleText>
                      <CardSubtitleText ismobilescreen={isSmallMobileScreen}>
                        Your dream practice awaits.
                      </CardSubtitleText>
                      <LocationSearchForm>
                        <LocationSearchBox
                          // We send a larger screen value here (ismobilescreen vs the isSmallMobileScreen used above)
                          // because we want the searchbox label to be trimmed sooner, as its quite long
                          ismobilescreen={ismobilescreen}
                          onChange={onChangeLocation}
                          onEnter={onSearchByLocation}
                          value={locationSearch}
                          enterOnBlur={false}
                        />
                      </LocationSearchForm>
                    </CardRight>
                  </Card>
                </BuySellContainer>
              </Container>
            </DivWithBackgroundImage>
          </FullPageContentContainer>
        </PageContainer>
      </BackgroundContainer>
    </>
  )
}

export default SellOrBuy
