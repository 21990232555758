import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  BrightViewOffersButton,
  PrimaryViewOffersButton,
  SecondaryViewOffersButton,
} from './styled'
import { getMyOffersPathFromPracticeId } from '../../../utils/routing'

const defaultText = 'View offers'

const ViewOffersButton = ({
  buttonType = 'primary',
  text = null,
  practice,
}) => {
  const navigate = useNavigate()

  const openMyOffers = useCallback(() => {
    navigate(getMyOffersPathFromPracticeId(practice?.id))
  }, [practice, navigate])

  return (
    <>
      {buttonType === 'secondary' ? (
        <SecondaryViewOffersButton onClick={openMyOffers}>
          {text || defaultText}
        </SecondaryViewOffersButton>
      ) : buttonType === 'bright' ? (
        <BrightViewOffersButton onClick={openMyOffers}>
          {text || defaultText}
        </BrightViewOffersButton>
      ) : (
        <PrimaryViewOffersButton onClick={openMyOffers}>
          {text || defaultText}
        </PrimaryViewOffersButton>
      )}
    </>
  )
}

export default ViewOffersButton
