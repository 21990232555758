const spacing = {
  basePadding: 20,
  pageLevelHorizontalPadding: 20,
  headerHeight: 50,
  headerVerticalPadding: 10,
  headerZIndex: 1300,
  desktopTopMargin: 20,
  mobileTopMargin: 10,
  largeVerticalMargin: 40,
  buttonHoverTransformScale: 1.08,
  smallBorderRadius: 4,
  mediumBorderRadius: 6,
  baseBorderRadius: 10,
  largeBorderRadius: 20,
  margin54: 54,
  margin44: 44,
}

export default spacing
