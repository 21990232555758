import { useLazyQuery } from '@apollo/client'
import { useMutationWithErrorLogging } from '../../utils/hooks'
import React, { useContext, useEffect, useState } from 'react'
import {
  RESET_TO_OLD_STAGING_HEURISTICS_AND_GLOBALS,
  UPDATE_HEURISTICS_AND_OR_GLOBAL_INPUTS,
  UPDATE_STAGING_GLOBALS_AND_HEURISTICS_TO_PRODUCTION,
} from '../../api/mutations/valuations'
import { InformationContext } from './Panel'

import { get } from 'lodash'
import { GET_VALUATION_FOR_INPUTS } from '../../api/queries/valuations'
import {
  pathsForGlobalInputsFieldNames,
  pathsForHeuristicFieldNames,
} from '../../utils/constants'

// Check all input states (averageAnnualBillings, etc) that need to be updated
// to see whether their subtables are defined properly. In the case of global inputs, check all fields.
// A subtable is defined properly if all its values are defined.
const checkIfAllStatesToUpdateHaveDefinedPaths = (statesToUpdate) => {
  let undefinedPaths = []
  for (const [stateName, { data }] of Object.entries(statesToUpdate)) {
    if (stateName === 'globalInputs') {
      for (const path of Object.values(pathsForGlobalInputsFieldNames)) {
        const value = get(data, path, null)
        if (value === null || value === '') {
          undefinedPaths.push(`${stateName} is undefined at ${path.join('.')}`)
        }
      }
      continue
    }

    // Now that globalInputs has been considered, if there are still other states that need to be updated, they will only be the heuristics ones.
    for (const [fieldName, fieldValue] of Object.entries(data)) {
      if (fieldName === 'inputName') continue
      if (fieldName === 'increment' && !fieldValue) {
        undefinedPaths.push(`${stateName} has undefined ${fieldName}`)
        continue
      } else if (fieldName === 'increment' && fieldValue) {
        continue
      }

      // The only fields will be subtables, and their field values will be objects
      for (const path of Object.values(pathsForHeuristicFieldNames)) {
        const value = get(fieldValue, path, null)
        if (value === null || value === '') {
          undefinedPaths.push(
            `${stateName} is undefined at ${fieldName} for ${path.join('.')}`,
          )
          continue
        }
      }
    }
  }

  return {
    hasUndefinedPath: undefinedPaths.length ? true : false,
    undefinedPaths,
  }
}

const castObjectValuesToFloats = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  for (const key in obj) {
    if (key === 'inputName') {
      continue
    }

    if (typeof obj[key] === 'string') {
      const floatValue = parseFloat(obj[key])
      if (isNaN(floatValue)) {
        return null
      }

      obj[key] = floatValue
    } else if (typeof obj[key] === 'object') {
      const nestedResult = castObjectValuesToFloats(obj[key])
      if (nestedResult === null) {
        return null
      }

      obj[key] = nestedResult
    }
  }

  return obj
}

const Valuation = ({
  valuationType,
  valuationObject: { lowerBound, upperBound },
}) => {
  return (
    <>
      <h4>{valuationType}</h4>
      <div>
        <h5>Lower Bound: ${lowerBound.toLocaleString('en-US')}</h5>
        <h5>Upper Bound: ${upperBound.toLocaleString('en-US')}</h5>
      </div>
    </>
  )
}

const Test = ({ valuations, errorMessage, onClick }) => {
  return (
    <>
      <button
        onClick={() => onClick()}
        style={{ display: 'block', margin: '1em 0' }}
      >
        Test
      </button>
      {errorMessage && <div>{errorMessage}</div>}
      {valuations && (
        <>
          <Valuation
            valuationType={'Staging Valuation'}
            valuationObject={valuations.stagingValuation}
          />
          <Valuation
            valuationType={'Production Valuation'}
            valuationObject={valuations.productionValuation}
          />
        </>
      )}
    </>
  )
}

// Takes in heuristics of type { heuristicA: { requiresBackendUpdate: Boolean, data: Heuristic }, ... }
// and returns [ heuristicA.data, heuristicB.data, ... ]
const extractHeuristicsData = (heuristics) => {
  return Object.values(heuristics).map((heuristic) => heuristic.data)
}

// This function takes in new global inputs and/or heuristics and updates them in the backend.
// Once the states have been updated in the backend, it controls the backend state status that allows the valuation to get rendered.
// It will also changes the requiresBackendUpdate to false once all the heuristics and/or global inputs have been updated so that way they don't get updated again unnecessarily when there hasn't even been any change.
const handleUpdatingBackendAndFrontendState = ({
  globalInputs,
  heuristics,
  updateHeuristicsAndOrGlobalInputs,
  setHeuristicsAndGlobalsState,
  setUpdateStatus,
}) => {
  let variables = {}
  if (globalInputs) {
    variables = {
      newGlobalInputs: globalInputs.data,
    }
  }

  if (heuristics) {
    variables = {
      ...variables,
      newHeuristics: extractHeuristicsData(heuristics),
    }
  }

  updateHeuristicsAndOrGlobalInputs({
    variables: {
      environment: 'staging',
      ...variables,
    },
    onCompleted: (data) => {
      const hasBackendBeenUpdated =
        data.updateHeuristicsAndOrGlobalInputs.success

      // If the backend has updated, then the states that had requiresBackendUpdate = true need to be set to false so they don't get updated again causing an unnecessary DB call.
      // As a result of this, if you try updating and it succeeds, trying to update again will give an error saying that there have been no changes.
      if (hasBackendBeenUpdated) {
        // This combines the globalInputs and/or heuristics and then iterates through the entries of the combined object to change the requiresBackendUpdate to false and then the object is reconstructed using Object.fromEntries().
        const modifiedState = Object.fromEntries(
          Object.entries({
            ...(globalInputs && { globalInputs: globalInputs }),
            ...heuristics,
          }).map(([stateName, state]) => [
            stateName,
            //       !! This is what has been modified !!
            { ...state, requiresBackendUpdate: false },
          ]),
        )

        setHeuristicsAndGlobalsState((prev) => ({
          ...prev,
          ...modifiedState,
        }))

        setUpdateStatus('Completed updating staging databases!')
      }
    },
  })
}

const Update = () => {
  const { heuristicsAndGlobalsState, setHeuristicsAndGlobalsState } =
    useContext(InformationContext)
  const [updateHeuristicsAndOrGlobalInputs] = useMutationWithErrorLogging(
    UPDATE_HEURISTICS_AND_OR_GLOBAL_INPUTS,
  )

  const [errorMessages, setErrorMessages] = useState([])
  const [statesToUpdate, setStatesToUpdate] = useState(null)
  const [updateStatus, setUpdateStatus] = useState('')

  // All the states that have been updated and have been approved for being able to update will be updated within this useEffect.
  useEffect(() => {
    if (!statesToUpdate) {
      return
    }

    // This code handles what happens if there are heuristic/globalInputs that require an update in the backend.
    const { globalInputs, ...heuristics } = statesToUpdate
    handleUpdatingBackendAndFrontendState({
      globalInputs,
      heuristics,
      updateHeuristicsAndOrGlobalInputs,
      setHeuristicsAndGlobalsState,
      setUpdateStatus,
    })
  }, [statesToUpdate])

  // The onClick grabs the states that need to be updated and then determines if those states can be even be updated (checks for any problems in the states)
  // If there are no problems, then it sets the statesToUpdate in the component and the useEffect is fired resulting in an update.
  const onClick = () => {
    setUpdateStatus('')
    setErrorMessages('')

    // Only get states that have been updated because those are the states that will require an update in the backend.
    let statesToUpdate = Object.fromEntries(
      Object.entries(heuristicsAndGlobalsState).filter(
        ([_, informationObject]) => informationObject.requiresBackendUpdate,
      ),
    )

    if (!Object.keys(statesToUpdate).length) {
      setErrorMessages(['Change the heuristics or globals before updating.'])
      return
    }

    const { hasUndefinedPath, undefinedPaths } =
      checkIfAllStatesToUpdateHaveDefinedPaths(statesToUpdate)

    // Ensures only defined global inputs and heuristics can get in.
    if (hasUndefinedPath) {
      setErrorMessages(undefinedPaths)
      return
    }

    const parsedStatesToUpdate = castObjectValuesToFloats(statesToUpdate)
    if (parsedStatesToUpdate === null) {
      setErrorMessages([
        'Global inputs and Heuristics are supposed to accept floats or numbers only.',
      ])
      return
    }

    setStatesToUpdate(parsedStatesToUpdate)
    setErrorMessages([])
  }

  return (
    <>
      <button
        onClick={() => onClick()}
        style={{ display: 'block', margin: '1em 0' }}
      >
        Update Staging
      </button>
      {updateStatus}
      {errorMessages &&
        errorMessages.map((errorMessage, i) => (
          <div key={i}>{errorMessage}</div>
        ))}
    </>
  )
}

const PushToProduction = () => {
  const [pushGlobalsAndHeuristicsToProduction] = useMutationWithErrorLogging(
    UPDATE_STAGING_GLOBALS_AND_HEURISTICS_TO_PRODUCTION,
  )
  const [shouldPushToProduction, setShouldPushToProduction] = useState(false)
  const [hasPushCompleted, setHasPushCompleted] = useState(false)

  useEffect(() => {
    if (shouldPushToProduction) {
      pushGlobalsAndHeuristicsToProduction({
        onCompleted: () => {
          setHasPushCompleted(true)
          setShouldPushToProduction(false)
        },
      })
    }
  }, [shouldPushToProduction])

  return (
    <>
      <button
        style={{ display: 'block', margin: '1em 0' }}
        onClick={() => {
          setShouldPushToProduction(true)
          setHasPushCompleted(false)
        }}
      >
        Push To Production
      </button>
      {hasPushCompleted && <div>Completed pushing to production!</div>}
    </>
  )
}

const Reset = ({ onClickReset, resetStatus }) => {
  return (
    <>
      <button
        onClick={() => onClickReset()}
        style={{ display: 'block', margin: '1em 0' }}
      >
        Reset staging to default params
      </button>
      {resetStatus}
    </>
  )
}

const TestingPanel = ({ setNeedToFetchData }) => {
  const [input, setInputState] = useState({
    averageAnnualBillings: '',
    hygieneRevenuePercentage: '',
    operatories: '',
    fullTimeHygienists: '',
    fullTimeAdmins: '',
  })
  const [errorMessage, setErrorMessage] = useState('')
  const [resetStatus, setResetStatus] = useState('')

  const [resetToOldStagingHeuristicsAndGlobals] = useMutationWithErrorLogging(
    RESET_TO_OLD_STAGING_HEURISTICS_AND_GLOBALS,
  )
  const [getValuations, { data: valuations }] = useLazyQuery(
    GET_VALUATION_FOR_INPUTS,
  )

  const onClickTest = () => {
    const allParsedInputs = castObjectValuesToFloats(input)
    if (allParsedInputs === null) {
      setErrorMessage(
        'The input values are supposed to accept floats or numbers only.',
      )
      return
    }

    getValuations({
      variables: {
        input: allParsedInputs,
      },
      fetchPolicy: 'no-cache',
    })
    setErrorMessage('')
  }

  const onClickReset = () => {
    setResetStatus('Please wait, currently resetting!')
    resetToOldStagingHeuristicsAndGlobals({
      onCompleted: () => {
        console.log('completed resetting to old staging heuristics and globals')
        setNeedToFetchData(true)
        setResetStatus('Completed resetting!')
      },
    })
  }

  const onChange = (inputName, newValue) => {
    setInputState((prev) => ({ ...prev, [inputName]: newValue }))
  }

  return (
    <>
      <h3>Average Annual Billings</h3>
      <input
        value={input.averageAnnualBillings}
        onChange={(event) =>
          onChange('averageAnnualBillings', event.target.value)
        }
      ></input>
      <h3>Hygiene Revenue Percentage</h3>
      <input
        value={input.hygieneRevenuePercentage}
        onChange={(event) =>
          onChange('hygieneRevenuePercentage', event.target.value)
        }
      ></input>
      <h3>Operatories</h3>
      <input
        value={input.operatories}
        onChange={(event) => onChange('operatories', event.target.value)}
      ></input>
      <h3>Full Time Hygienists</h3>
      <input
        value={input.fullTimeHygienists}
        onChange={(event) => onChange('fullTimeHygienists', event.target.value)}
      ></input>
      <h3>Full Time Admins</h3>
      <input
        value={input.fullTimeAdmins}
        onChange={(event) => onChange('fullTimeAdmins', event.target.value)}
      ></input>
      <Reset onClickReset={onClickReset} resetStatus={resetStatus} />
      <Update />
      <PushToProduction />
      <Test
        valuations={valuations?.getTestValuationFromInputs || null}
        errorMessage={errorMessage}
        onClick={onClickTest}
      />
    </>
  )
}

export default TestingPanel
