import gql from 'graphql-tag'

export const CREATE_VALUATION = gql`
  mutation CreateValuation($practiceId: String!) {
    createValuation(practiceId: $practiceId) {
      id
      practiceId
    }
  }
`

export const EDIT_VALUATION_GENERAL_INFO = gql`
  mutation EditValuationGeneralInfo(
    $id: String!
    $annualBillings: Float!
    $hygienePercentage: Float!
    $numOperatories: Int!
    $ownerWorkingFullTime: Boolean!
  ) {
    editValuationGeneralInfo(
      id: $id
      annualBillings: $annualBillings
      hygienePercentage: $hygienePercentage
      numOperatories: $numOperatories
      ownerWorkingFullTime: $ownerWorkingFullTime
    ) {
      id
      updatedAt
    }
  }
`

export const EDIT_VALUATION_ASSOCIATES_AND_HYGIENISTS = gql`
  mutation EditValuationAssociatesAndHygienists(
    $id: String!
    $partTimeAssociates: Int!
    $fullTimeAssociates: Int!
    $partTimeHygienists: Int!
    $fullTimeHygienists: Int!
  ) {
    editValuationAssociatesAndHygienists(
      id: $id
      partTimeAssociates: $partTimeAssociates
      fullTimeAssociates: $fullTimeAssociates
      partTimeHygienists: $partTimeHygienists
      fullTimeHygienists: $fullTimeHygienists
    ) {
      id
      updatedAt
    }
  }
`

export const EDIT_VALUATION_ADMINS_AND_ASSISTANTS = gql`
  mutation EditValuationAdminsAndAssistants(
    $id: String!
    $partTimeAdmins: Int!
    $fullTimeAdmins: Int!
    $partTimeAssistants: Int!
    $fullTimeAssistants: Int!
  ) {
    editValuationAdminsAndAssistants(
      id: $id
      partTimeAdmins: $partTimeAdmins
      fullTimeAdmins: $fullTimeAdmins
      partTimeAssistants: $partTimeAssistants
      fullTimeAssistants: $fullTimeAssistants
    ) {
      id
      updatedAt
      result {
        id
        lower
        upper
        completedAt
      }
      recentResults {
        id
        lower
        upper
        completedAt
      }
    }
  }
`

export const SET_READY_TO_SELL = gql`
  mutation SetReadyToSell($id: String!, $readyToSell: Boolean!) {
    setReadyToSell(id: $id, readyToSell: $readyToSell)
  }
`

export const UPDATE_HEURISTICS_AND_OR_GLOBAL_INPUTS = gql`
  mutation updateHeuristicsAndOrGlobalInputs(
    $environment: Environment!
    $newHeuristics: [ValuesChartHeuristicInput]
    $newGlobalInputs: GlobalInputs
  ) {
    updateHeuristicsAndOrGlobalInputs(
      environment: $environment
      newHeuristics: $newHeuristics
      newGlobalInputs: $newGlobalInputs
    ) {
      success
      errorCode
    }
  }
`

export const UPDATE_STAGING_GLOBALS_AND_HEURISTICS_TO_PRODUCTION = gql`
  mutation updateStagingGlobalsAndHeuristicsToProduction {
    updateStagingGlobalsAndHeuristicsToProduction {
      success
      errorCode
    }
  }
`

export const RESET_TO_OLD_STAGING_HEURISTICS_AND_GLOBALS = gql`
  mutation resetToOldStagingHeuristicsAndGlobals {
    resetToOldStagingHeuristicsAndGlobals {
      success
      errorCode
    }
  }
`
