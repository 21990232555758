import React from 'react'
import { StyledBackButton } from './styled'
import styled from 'styled-components/macro'
import { DefaultIconStyle } from '../../../styles/shared-styled-components'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

export const StyledArrowBackIosIconIcon = styled(ArrowBackIosIcon)`
  display: flex;
  justify-content: center;
  align-items: center;
  ${DefaultIconStyle}
`

const BackButton = ({ text = 'Back' }) => {
  return (
    <StyledBackButton onClick={() => window.history.back()}>
      <StyledArrowBackIosIconIcon />
      {text}
    </StyledBackButton>
  )
}

export default BackButton
