import React from 'react'
import {
  Modal,
  ModalActions,
  ModalContainer,
  ModalContent,
  ModalContentText,
  ModalHeaderContainer,
  RowButtonContainer,
  TitleText,
} from '../../styles/shared-styled-components'
import { CancelButton, DeleteButton } from './styled'

const DeletePracticeModal = ({
  practiceName,
  isOpen,
  onClose,
  onDelete,
  isDeleting,
}) => {
  return (
    <Modal open={isOpen} onClose={!isDeleting && onClose}>
      <ModalContainer>
        <ModalHeaderContainer>
          <TitleText>Delete {practiceName}?</TitleText>
        </ModalHeaderContainer>
        <ModalContent>
          <ModalContentText>
            Are you sure you want to delete this practice?<br></br>This can't be
            undone.
          </ModalContentText>
        </ModalContent>
        <ModalActions>
          <RowButtonContainer>
            <CancelButton
              disabled={isDeleting}
              onClick={!isDeleting && onClose}
            >
              Cancel
            </CancelButton>
            <DeleteButton
              disabled={isDeleting}
              onClick={!isDeleting && onDelete}
            >
              {isDeleting ? 'Please wait...' : 'Delete'}
            </DeleteButton>
          </RowButtonContainer>
        </ModalActions>
      </ModalContainer>
    </Modal>
  )
}

export default DeletePracticeModal
