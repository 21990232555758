import React, { forwardRef } from 'react'
import { FlexDiv } from '../../../styles/shared-styled-components'
import { SubtitleText } from '../../../pages/CreatePractice/DueDiligence/styled'
import CreatePracticeInput from '../CreatePracticeInput'

const OperatoryField = forwardRef(
  (
    {
      index = 0,
      options,
      submittedDataWithErrors,
      value,
      onChange,
      errorMessage,
    },
    ref,
  ) => {
    return (
      <FlexDiv style={{ alignItems: 'center', marginBottom: 20 }}>
        <FlexDiv style={{ flex: 1 }}>
          <SubtitleText className='label'>{`Operatory ${
            index + 1
          }`}</SubtitleText>
        </FlexDiv>
        <FlexDiv style={{ flex: 2, flexDirection: 'column' }}>
          <CreatePracticeInput
            value={value}
            label='Purpose'
            width={'width'}
            onChange={onChange}
            fieldType={'dropdown'}
            elementId={`opPurpose-${index}`}
            submittedDataWithErrors={submittedDataWithErrors}
            errorMessage={errorMessage}
            options={options}
          />
        </FlexDiv>
      </FlexDiv>
    )
  },
)

export default OperatoryField
