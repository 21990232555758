import { MuiTelInput } from 'mui-tel-input'
import styled from 'styled-components/macro'
import { OnboardingElementStyle } from '../shared-onboarding-styles'

export const PhoneInput = styled(MuiTelInput).attrs({
  inputProps: {
    'data-hj-allow': true,
  },
})`
  ${OnboardingElementStyle}
  .MuiInputLabel-root {
    font-family: ${({ theme }) => theme.typography.typeFont};
    font-size: ${({ theme }) => theme.typography.titleFontSize};
  }
  & input {
    font-family: ${({ theme }) => theme.typography.typeFont};
    font-size: ${({ theme }) => theme.typography.titleFontSize};
  }
`
