import React from 'react'
import { useMyUser } from '../../../api/hooks/users'
import {
  PrimaryButton,
  TextButton,
} from '../../../styles/shared-styled-components'
import { defaultHomePath } from '../../../utils/routing'

const LoginButton = ({ user, onClick, variant = 'primary' }) => {
  const { loginWithRedirect } = useMyUser().auth0Context

  const handleLogin = async () => {
    onClick()
    await loginWithRedirect({
      appState: {
        returnTo: `${defaultHomePath}?li=true`,
      },
      authorizationParams: {
        utm_affiliate: localStorage.getItem('utm_affiliate'),
      },
    })
  }

  return (
    <>
      {variant === 'text' ? (
        <TextButton onClick={handleLogin}>Log&nbsp;in</TextButton>
      ) : (
        <PrimaryButton onClick={handleLogin}>Log&nbsp;in</PrimaryButton>
      )}
    </>
  )
}

export default LoginButton
