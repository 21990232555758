import MuiCloseIcon from '@mui/icons-material/Close'
import MenuIcon from '@mui/icons-material/Menu'
import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import styled, { css } from 'styled-components/macro'
import {
  BaseDiv,
  IntraSubsectionalGap,
  RowFlexStyle,
} from '../../../styles/shared-styled-components'
import UpTransition from '../../Micro/UpTransition'

export const AppBar = styled(MuiAppBar).attrs({
  position: 'fixed',
  elevation: 3,
})`
  z-index: ${({ theme }) => theme.spacing.headerZIndex};
`

export const DesktopNavToolbar = styled(Toolbar)`
  display: flex;
  background-color: ${({ theme }) => theme.color.primary};
  padding: ${({ theme }) => theme.spacing.headerVerticalPadding}px
    ${({ theme }) => theme.spacing.pageLevelHorizontalPadding}px;
  justify-content: space-around;
  height: ${({ theme }) => theme.spacing.headerHeight}px;
  // To override MUI's property which changes the header height when the window width shrinks
  min-height: ${({ theme }) => theme.spacing.headerHeight}px;
`

export const MobileNavToolbar = styled(DesktopNavToolbar)`
  background-color: ${({ theme }) => theme.color.white};
`

export const ToolbarContainerLeft = styled.div`
  display: flex;
  flex: ${(props) => (props.ismobilescreen ? 0 : 1)};
  justify-content: flex-start;
`

export const DrawerIcon = styled(MenuIcon)`
  color: ${({ theme }) => theme.color.white};
`

export const DrawerIconContainer = styled(IconButton)`
  margin-left: 0px;
  margin-right: 10px;
  padding: 0px;
`

export const SectionContainer = styled.div`
  margin-left: 30px;
  display: flex;
  align-items: center;
  height: 50px;
  gap: 20px;
`

export const SectionHeader = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.typography.typeFont};
  font-size: ${({ theme }) => theme.typography.defaultFontSize};
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    &.contact {
      width: 66px;
      font-size: ${({ theme }) => theme.typography.smallFontSize};
    }
  }
`

export const SectionHeaderTextContainer = styled.div`
  overflow-x: auto;
  white-space: nowrap;
`

export const DesktopSectionMenuColumn = styled.div``

const DividerStyle = css`
  border-width: 2px;
  border-top: 0px;
  border-left: 0px;
  border-color: ${({ theme }) => theme.color.lighterGrey};
`

export const VerticalSectionMenuDivider = styled.hr`
  ${DividerStyle}
  margin: 0px 5px;
`

export const DesktopSectionMenuColumnHeader = styled.div`
  padding-left: 16px;
  font-size: ${({ theme }) => theme.typography.titleFontSize};
  margin-bottom: 10px;
  color: ${({ theme }) => theme.typography.blue};
  font-weight: bold;
`

export const DesktopSectionMenuItem = styled(MenuItem)`
  font-family: ${({ theme }) => theme.typography.typeFont};
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.typography.sentenceFontSize};
`

export const ToolbarContainerCenter = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`

export const ToolbarContainerRight = styled.div`
  display: flex;
  flex: 2;
  justify-content: flex-end;
  z-index: 1000;
`

export const DesktopHeaderDentacloudLogo = styled(Box)`
  height: ${(props) => (props.ismobilescreen ? 'auto' : '40px')};
  width: ${(props) => (props.ismobilescreen ? 60 : 180)}px;
  cursor: pointer;
`

export const FullScreenHeader = styled(Dialog).attrs({
  fullScreen: true,
  TransitionComponent: UpTransition,
})`
  height: 100vh;
  z-index: 100000;
`

// Some janky styling to make everything align, but this seems to work
export const CloseIconContainer = styled(DrawerIconContainer)`
  // Want padding to make the hover shape circular, because the hover is more visible against this white backdrop
  // But also want it to be left-aligned with the drawer icon, so need to shift it 8px left
  padding: 8px;
  left: -11px;
  margin: 0px;
`

export const CloseIcon = styled(MuiCloseIcon)`
  color: ${({ theme }) => theme.color.primary};
`

export const MobileSectionsContainer = styled(BaseDiv)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${IntraSubsectionalGap}
  font-family: ${({ theme }) => theme.typography.typeFont};
  margin-bottom: 50px;
`

// Some janky styling to make everything align, but this seems to work
export const MobileHeaderDentacloudLogoContainer = styled.div`
  position: relative;
  left: -5px;
`
export const MobileHeaderDentacloudLogo = styled(DesktopHeaderDentacloudLogo)`
  height: ${(props) => (props.ismobilescreen ? 'auto' : '40px')};
  width: ${(props) => (props.ismobilescreen ? 60 : 180)}px;
  cursor: pointer;
`

const MobileSectionWidth = css`
  width: 100%;
`

export const MobileSection = styled.div`
  ${MobileSectionWidth}
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: ${(props) => (props.bottom ? 100 : 0)}px;
`

export const MobileSectionHeader = styled.div`
  ${RowFlexStyle}
  justify-content: space-between;
  margin-bottom: ${(props) => (props.open ? 30 : 5)}px;
  margin-top: ${(props) => (props.top ? 0 : 5)}px;
`

export const MobileSectionHeaderTextContainer = styled.div`
  font-size: ${({ theme }) => theme.typography.titleFontSize};
  font-weight: bold;
  color: ${({ theme }) => theme.color.primary};
`

export const MobileSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 20px;
`

export const MobileSectionMenuColumnHeader = styled.div`
  font-size: ${({ theme }) => theme.typography.largeInputTextFontSize};
  color: ${({ theme }) => theme.typography.blue};
  font-weight: bold;
  margin-bottom: 10px;
`

export const MobileSectionMenuColumn = styled.div``

export const MobileSectionMenuItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const MobileSectionMenuItem = styled(MenuItem)`
  font-family: ${({ theme }) => theme.typography.typeFont};
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.typography.sentenceFontSize};
  padding: 8px 0px;
  min-height: 20px;
`

export const HorizontalSectionMenuDivider = styled.hr`
  ${MobileSectionWidth}
  ${DividerStyle}
  border-width: 3px;
  margin: 0px;
`
