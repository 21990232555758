import _ from 'lodash'
import React from 'react'
import AcquisitionListItem from '../../components/Micro/MyAcquisition/AcquisitionListItem'

const DataAccessRequests = ({ acquisitions, onUpdate }) => {
  const handleRequestArchive = (data) => {
    onUpdate(data.id, {
      is_archived: !data.is_archived,
    })
  }

  const handleRequestFavorite = (data) => {
    onUpdate(data.id, {
      is_favorite: !data.is_favorite,
    })
  }

  return (
    <>
      {_.map(acquisitions, (acquisition, i) => (
        <AcquisitionListItem
          key={i}
          acquisition={acquisition}
          onArchive={handleRequestArchive}
          onFavorite={handleRequestFavorite}
        />
      ))}
    </>
  )
}

export default DataAccessRequests
