import React from 'react'
import { BackIcon, NextIcon } from '../../../styles/shared-styled-components'
import { CarouselNavigationButton } from './styled'

const PictureCarouselNavigator = ({
  index,
  numPictures,
  onClickBack,
  onClickNext,
}) => {
  return (
    <>
      <CarouselNavigationButton onClick={onClickBack} disabled={index === 0}>
        <BackIcon />
      </CarouselNavigationButton>
      <CarouselNavigationButton
        onClick={onClickNext}
        disabled={index === numPictures - 1}
      >
        <NextIcon />
      </CarouselNavigationButton>
    </>
  )
}

export default PictureCarouselNavigator
