import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import styled from 'styled-components/macro'

export const AppBar = styled(MuiAppBar).attrs({
  position: 'fixed',
  elevation: 3,
})`
  z-index: ${({ theme }) => theme.spacing.headerZIndex};
`

export const DesktopNavToolbar = styled(Toolbar)`
  display: flex;
  background-color: ${({ theme }) => theme.color.primary};
  padding: ${({ theme }) => theme.spacing.headerVerticalPadding}px
    ${({ theme }) => theme.spacing.pageLevelHorizontalPadding}px;
  justify-content: space-around;
  height: ${({ theme }) => theme.spacing.headerHeight}px;
  // To override MUI's property which changes the header height when the window width shrinks
  min-height: ${({ theme }) => theme.spacing.headerHeight}px;
`

export const DesktopHeaderDentacloudLogo = styled(Box)`
  height: ${(props) => (props.ismobilescreen ? 'auto' : '40px')};
  width: ${(props) => (props.ismobilescreen ? 60 : 180)}px;
  cursor: pointer;
`

export const ToolbarContainerCenter = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`

export const ToolbarContainerLeft = styled.div`
  display: flex;
  flex: ${(props) => (props.ismobilescreen ? 0 : 1)};
  justify-content: flex-start;
`

export const ToolbarContainerRight = styled.div`
  display: flex;
  flex: 2;
  justify-content: flex-end;
  z-index: 1000;
`
