import React, { useEffect, useState } from 'react'
import Confetti from 'react-confetti'
import { useNavigate, useParams } from 'react-router-dom'
import { usePracticeById } from '../../../api/hooks/practices'
import MyPracticesButton from '../../../components/Micro/MyPracticesButton'
import Header from '../../../components/NavBar/Header'
import {
  FullPageContentContainerWithLargeGap,
  PageContainer,
  SubtitleText,
  VUNSBC,
} from '../../../styles/shared-styled-components'
import {
  HeroContainer,
  MyPracticesButtonContainer,
  UnderReviewTextContainer,
} from './styled'

const currentStep = -1

const PublishedLandingPage = ({ user, width, ismobilescreen }) => {
  const navigate = useNavigate()
  const { practiceId } = useParams()
  const { practice } = usePracticeById(practiceId)

  let shouldShowConfetti = true

  const [showConfetti, setShowConfetti] = useState(shouldShowConfetti)

  const stopConfetti = () => {
    setShowConfetti(false)
    shouldShowConfetti = false
  }

  useEffect(() => {
    async function stopConfettiOnTimer() {
      setTimeout(stopConfetti, 3000)
    }
    if (shouldShowConfetti) {
      setShowConfetti(true)
      stopConfettiOnTimer()
    }
  }, [shouldShowConfetti])

  return (
    practice && (
      <>
        <Confetti
          size={8}
          shape='circle'
          numberOfPieces={showConfetti ? 300 : 0}
        />
        <Header user={user} width={width} ismobilescreen={ismobilescreen} />
        <VUNSBC>
          <PageContainer>
            <FullPageContentContainerWithLargeGap
              ismobilescreen={ismobilescreen}
            >
              <HeroContainer />
              <UnderReviewTextContainer>
                <SubtitleText>
                  Your listing has been submitted and is now under review.
                </SubtitleText>
                <SubtitleText>
                  Once your listing is approved it will go live in our
                  marketplace. This process usually takes 24-48 hours.
                </SubtitleText>
              </UnderReviewTextContainer>
              <MyPracticesButtonContainer>
                <MyPracticesButton />
              </MyPracticesButtonContainer>
            </FullPageContentContainerWithLargeGap>
          </PageContainer>
        </VUNSBC>
      </>
    )
  )
}

export default PublishedLandingPage
