/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import { usePracticeById } from '../../../api/hooks/practices'
import {
  FormTitle,
  SubtitleText,
} from '../../../pages/CreatePractice/DueDiligence/styled'
import DocumentUploader from './DocumentUploader'
import SkipSectionWarningModal from './SkipSectionWarningModal'
import { Box } from './styled'
import _ from 'lodash'

const Legal = forwardRef(
  (
    {
      subStepName,
      formProgress,
      onSkip,
      onClickNext,
      handleCanSkipChanged,
      onUpdateFinancialDetails,
    },
    ref,
  ) => {
    const { practiceId } = useParams()
    const { practice } = usePracticeById(practiceId)
    const uploaderRef = useRef()
    const [isSkipable, setIsSkipable] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [uploadedDocsUrls, setUploadedDocsUrls] = useState(
      practice?.dueDiligence?.legal || {},
    )
    const [currentSection, setCurrentSection] = useState(0)

    const sections = useMemo(
      () => [
        {
          name: 'legal',
          title: '3.1 Legal',
          subtitle: 'Note: Please upload the documents listed below.',
          listedItems: [
            'Legal organization chart',
            'Articles of incorporation',
            'List of Shareholder(s) and % Ownership',
            'List all associated corporations and the associated ownership',
            'List any pending litigation if applicable',
          ],
        },
        {
          name: 'permiseRentalLease',
          title: '3.2 Premise Rental Lease​',
          subtitle: 'Note: Please upload the documents listed below.',
          listedItems: [
            'Original lease',
            'All prior lease amendments',
            'Lease assignment if applicable',
            'Most recent lease extension agreement',
            'Last 3 years CAM + share of property tax if applicable',
          ],
        },
        {
          name: 'vendorContracts',
          title: '3.3 Vendor Contracts​',
          subtitle: 'Note: Please upload the documents listed below.',
          listedItems: [
            'Material vendor contracts (terms, cost and termination fee)​',
          ],
        },
        {
          name: 'equipmentRentals',
          title: '3.4 Equipment Lease/Rentals',
          subtitle: 'Note: Please upload the documents listed below.',
          listedItems: [
            'All Equipment Lease/Rent Agreements',
            'Description of the equipment, terms, lease/rent amount, termination fee',
          ],
        },
        {
          name: 'regulatoryCompliance',
          title: '3.5 Regulatory Compliance​',
          subtitle: 'Note: Please upload the documents listed below.',
          listedItems: [
            'X-Ray Floor Plans',
            'Ministry of Health Approvals for each X-Ray',
            'Sedation Permits with Last Date of Inspection, if applicable',
            'HARP Inspections reports for the last 2 years',
            'Recommendations from reports not completed to date',
            'Copy of Associate Licences',
          ],
        },
      ],
      [],
    )

    useEffect(() => {
      const currentPath = window.location.pathname
      const queryParams = new URLSearchParams(window.location.search)
      const navType = queryParams.get('navType')
      if (navType === 'back') {
        setCurrentSection(sections.length - 1)
      }
      queryParams.delete('navType')
      window.history.replaceState(
        {},
        '',
        `${currentPath}?${queryParams.toString()}`,
      )
    }, [sections])

    useEffect(() => {
      if (uploadedDocsUrls) onUpdateFinancialDetails(uploadedDocsUrls)
    }, [uploadedDocsUrls])

    useEffect(() => {
      setIsSkipable(
        _.isEmpty(
          uploadedDocsUrls && uploadedDocsUrls[sections[currentSection].name],
        ),
      )
    }, [uploadedDocsUrls, currentSection, sections, practice])

    useEffect(() => {
      if (handleCanSkipChanged) {
        handleCanSkipChanged(isSkipable)
      }
    }, [isSkipable])

    const handleOnBackPressed = useCallback(
      (callback) => {
        if (currentSection > 0) {
          setCurrentSection(currentSection - 1)
        } else {
          callback()
        }
      },
      [currentSection],
    )

    useImperativeHandle(ref, () => ({
      submit: handleFormSubmit,
      onBack: handleOnBackPressed,
      isSkipable: isSkipable,
    }))

    const handleFormSubmit = useCallback(
      (callback, config) => {
        if (config?.override && callback) {
          callback(uploadedDocsUrls)
        } else if (isSkipable) {
          if (!!(formProgress && !_.isEmpty(formProgress.skipped))) {
            if (currentSection < sections.length - 1) {
              setCurrentSection(currentSection + 1)
            } else {
              onSkip()
            }
          } else {
            setShowModal(true)
          }
        } else {
          // handle form submission
          if (currentSection < sections.length - 1) {
            setCurrentSection(currentSection + 1)
          } else {
            if (callback) {
              const allFieldsFilled = _.every(
                uploadedDocsUrls,
                (field) => !_.isNull(field),
              )
              if (allFieldsFilled) {
                callback(uploadedDocsUrls)
              } else {
                onSkip()
              }
            } else if (onClickNext) {
              onClickNext()
            }
          }
        }
      },
      [isSkipable, uploaderRef, currentSection, uploadedDocsUrls, formProgress],
    )

    const handleOnUploadComplete = useCallback(
      (url) => {
        setUploadedDocsUrls((old) => ({
          ...old,
          [sections[currentSection].name]: _.concat(
            uploadedDocsUrls[sections[currentSection].name] || [],
            url,
          ),
        }))
      },
      [currentSection, uploadedDocsUrls, sections, uploaderRef],
    )

    const handleOnSkip = useCallback(() => {
      setShowModal(false)
      const hasNextSection = currentSection < sections.length - 1
      if (hasNextSection) {
        setCurrentSection(currentSection + 1)
      } else {
        onSkip()
      }
    }, [currentSection, sections])

    const currentSectionUploadedFiles = useMemo(() => {
      return uploadedDocsUrls[sections[currentSection]?.name]
    }, [uploadedDocsUrls, sections, currentSection])

    return (
      <Box>
        <FormTitle>Legal</FormTitle>
        <FormTitle style={{ marginTop: '10px', fontSize: '16px' }}>
          {sections[currentSection].title}
        </FormTitle>

        <SubtitleText className='note' style={{ margin: '10px 0px 10px 0px' }}>
          {sections[currentSection].subtitle}
        </SubtitleText>
        <SubtitleText className='note' style={{ margin: 0 }}>
          {sections[currentSection]?.listedItems.map((item) => (
            <li>{item}</li>
          ))}
        </SubtitleText>

        <DocumentUploader
          ref={uploaderRef}
          onUploadComplete={handleOnUploadComplete}
          practiceId={practiceId}
          uploadedFilesUrls={currentSectionUploadedFiles || []}
        />

        <SkipSectionWarningModal
          subStepName={subStepName}
          formProgress={formProgress}
          practiceId={practiceId}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onSkip={handleOnSkip}
        />
      </Box>
    )
  },
)

export default Legal
