import gql from 'graphql-tag'

export const GET_ACQUISITIONS = gql`
  query getAcquisitions {
    getAcquisitions {
      id
      buyer_id
      practice_id
      practice
      type
      status
      is_favorite
      is_archived
      purchase_price
      buying_conditions
      percentage_to_buy
      pre_approved_financing
      years_to_work_post_sale
      createdAt
      updatedAt
    }
  }
`

export const GET_ACQUISITION_BY_ID = gql`
  query getAcquisitionById($id: String!) {
    getAcquisitionById(id: $id) {
      id
      buyer_id
      practice_id
      practice
      type
      status
      is_favorite
      is_archived
      purchase_price
      buying_conditions
      percentage_to_buy
      pre_approved_financing
      years_to_work_post_sale
      createdAt
      updatedAt
    }
  }
`

export const GET_OFFERS_BY_PRACTICE_ID = gql`
  query getMyOffersByPracticeId($id: String) {
    getMyOffersByPracticeId(id: $id)
  }
`
