export const removeTypenames = (obj) => {
  if (typeof obj === 'object') {
    if (Array.isArray(obj)) {
      return obj.map((item) => removeTypenames(item))
    } else {
      const newObj = {}
      for (const key in obj) {
        if (key !== '__typename') {
          newObj[key] = removeTypenames(obj[key])
        }
      }
      return newObj
    }
  } else {
    return obj
  }
}
