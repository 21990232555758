import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

export const screenWidthIsIpadSizeOrSmaller = (width) => {
  return width <= 1080
}

export const screenWidthIsMobileSizeOrSmaller = (width) => {
  return width <= 768
}

/**
 * deprecated use useIsMobileScreen or another useMediaQuery hook instead
 */
export function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth)

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  return width
}

export function useIsMobileScreen() {
  return useMediaQuery({ query: '(max-width: 768px)' })
}

export const screenWidthIsMediumMobileSizeOrSmaller = (width) => {
  return width <= 700
}

export const screenWidthIsSmallMobileSizeOrSmaller = (width) => {
  return width <= 475
}

export const screenWidthIsExtraSmallMobileSize = (width) => {
  return width <= 390
}

export const scrollElementIntoView = (
  document,
  elementId,
  elementScrollPosition = 'center',
) => {
  const mainRoot = document.getElementById(elementId)
  if (mainRoot) {
    mainRoot.scrollIntoView({
      behavior: 'smooth',
      block: elementScrollPosition,
    })
  }
}

export const practiceMarkerHeight = 40
export const markerClusterHeight = 50

export default {
  screenWidthIsIpadSizeOrSmaller,
  screenWidthIsMobileSizeOrSmaller,
  screenWidthIsMediumMobileSizeOrSmaller,
  screenWidthIsSmallMobileSizeOrSmaller,
  screenWidthIsExtraSmallMobileSize,
  scrollElementIntoView,
  practiceMarkerHeight,
  markerClusterHeight,
}
