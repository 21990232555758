import React, { createContext, useEffect, useState } from 'react'

import TestingPanel from './TestingPanel'
import HeuristicsAndGlobalsPanel from './HeuristicsAndGlobalsPanel'

import { useGlobalInputs, useHeuristics } from '../../api/hooks/valuations'

export const InformationContext = createContext(null)

const Panel = () => {
  const globalInputs = useGlobalInputs('staging')
  const heuristics = useHeuristics('staging')

  const [heuristicsAndGlobalsState, setHeuristicsAndGlobalsState] = useState({})
  const [needToFetchData, setNeedToFetchData] = useState(true)

  useEffect(() => {
    if (!!heuristics && !!globalInputs && needToFetchData) {
      // the heuristics will be of the type { heuristicName: heuristic },
      // but when passing to the state, we want to keep track of what heuristic gets updated, so we need to have a state of the type
      // { heuristicName: { requiresBackendUpdate: boolean, data: heuristic | globalInputs }} where data stores the heuristic
      // NOTE: `data` was the chosen field name because that applied universally to both heuristic and global inputs objects
      const modifiedHeuristics = Object.fromEntries(
        Object.entries(heuristics).map(([heuristicName, heuristic]) => [
          heuristicName,
          {
            requiresBackendUpdate: false,
            data: heuristic,
          },
        ]),
      )

      const initialHeuristicsAndGloalsState = {
        ...modifiedHeuristics,
        globalInputs: {
          requiresBackendUpdate: false,
          data: globalInputs,
        },
      }

      setHeuristicsAndGlobalsState(initialHeuristicsAndGloalsState)
      setNeedToFetchData(false)
    }
  }, [heuristics, globalInputs, needToFetchData])

  return (
    <>
      <InformationContext.Provider
        value={{
          heuristicsAndGlobalsState,
          setHeuristicsAndGlobalsState,
        }}
      >
        <HeuristicsAndGlobalsPanel />
        <TestingPanel setNeedToFetchData={setNeedToFetchData} />
      </InformationContext.Provider>
    </>
  )
}

export default Panel
