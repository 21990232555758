import NotificationsIcon from '@mui/icons-material/Notifications'
import MuiChip from '@mui/material/Chip'
import MobileStepper from '@mui/material/MobileStepper'
import styled, { css } from 'styled-components/macro'
import {
  BaseDiv,
  BlackParagraphText,
  ColumnFlexStyle,
  Form,
  GreenButton,
  IconContainerStyle,
  IntraSubsectionalGap,
  ParagraphText,
  RowFlexStyle,
  SecondaryButton,
  SecondaryButtonLightHoverStyle,
  TextButton,
  TextOnlyButton,
  UnderlineStyle,
} from '../../../styles/shared-styled-components'

export const searchFilterHeight = '52.5px'

export const SearchSectionContainer = styled(BaseDiv)`
  ${ColumnFlexStyle}
  ${IntraSubsectionalGap}

  width: calc(100vw - 30px);

  @media (max-width: 768px) {
    width: 90vw;
  }

  /* 
    Decrease padding to keep location bar/filter buttons centered on smaller mobile phones
  */
  @media (max-width: 400px) {
    padding: 0px 9px;
  }
`

export const SearchFiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
`

export const SearchFiltersContainerLeft = styled.div`
  display: flex;
`

export const SearchFiltersContainerCenter = styled.div`
  display: flex;
  gap: 0.5vw;
  margin: 0px 5px;
  flex-grow: 1;
  justify-content: center;
`

export const SearchFiltersContainerRight = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const LocationSearchWidthStyle = css`
  width: 30vw;
  max-width: 350px;
  min-width: 200px;
  flex-grow: 1;

  @media (max-width: 768px) {
    width: 200px;
    min-width: 180px;
  }

  @media screen and (min-width: 1250px) {
    max-width: 800px;
  }

  @media screen and (max-width: 1250px) {
    max-width: 40vw;
  }
}
`

export const LocationSearchForm = styled(Form)`
  ${LocationSearchWidthStyle}
`

export const TrackSearchIcon = styled(NotificationsIcon)`
  color: ${({ theme }) => theme.color.white};
`

export const FilterButton = styled(SecondaryButton)`
  color: ${({ theme }) => theme.color.primary};
  border: 1px solid
    ${(props) =>
      props.open || props.selected
        ? props.theme.color.primary
        : props.theme.color.muiLightGrey};
  font-size: ${({ theme }) => theme.typography.sentenceFontSize};
  height: ${searchFilterHeight};
  ${SecondaryButtonLightHoverStyle}
  width: 155px;
  padding: 0px;
`

export const MoreFiltersFilterButton = styled(FilterButton)`
  flex-grow: 1;
  width: auto;
  max-width: 180px;
`

export const NumberOfChoicesAppliedChip = styled(MuiChip)`
  position: absolute;
  top: -8px;
  right: -5px;
  background-color: ${(props) =>
    props.label > 0
      ? props.theme.color.lightPrimary
      : props.theme.color.muiLightGrey};
  z-index: 1;
  width: 49px;
  color: ${({ theme }) => theme.color.white};
  height: 25px;
`

export const MoreFilterContainer = styled.div`
  ${IconContainerStyle}
  margin-right: 5px;
`

export const SaveSearchButton = styled(GreenButton)`
  height: ${searchFilterHeight};
  min-width: 50px;
`

export const FilterContentContainer = styled.div`
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${IntraSubsectionalGap}
  padding: 25px 0px;
`

export const RangeDescriptorContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 30px;
  width: 90%;
`

export const RangeValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  // Otherwise the width changes as the length of the content changes, which happens as the filter gets modified
  width: 95px;
`

export const RangeValueTitleTextContainer = styled.div`
  color: ${({ theme }) => theme.color.darkGrey};
  font-size: ${({ theme }) => theme.typography.defaultFontSize};
`

export const RangeValueSubtitleTextContainer = styled.div`
  font-size: ${({ theme }) => theme.typography.defaultFontSize};
`

export const MoreFiltersCarousel = styled(MobileStepper)`
  margin-top: 10px;
`

export const CarouselNavigationButton = styled(TextButton)`
  color: ${({ theme }) => theme.color.primary};
  padding: 10px;
`

export const ResetFiltersContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ResetFiltersButton = styled(TextOnlyButton)``

export const FiltersSubsection = styled.div`
  ${RowFlexStyle}
  justify-content: space-between;
`

export const PracticeCountText = styled(BlackParagraphText)`
  font-size: ${({ theme }) => theme.typography.largeInputTextFontSize};
  font-weight: bold;
`

export const ResetFiltersText = styled(ParagraphText)`
  ${UnderlineStyle}
`
