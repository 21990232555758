/* eslint-disable react-hooks/exhaustive-deps */
import _, { sample } from 'lodash'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import { usePracticeById } from '../../../api/hooks/practices'
import useEventEmitter from '../../../custom-hooks/use-event-emitter'
import {
  FormTitle,
  SubtitleText,
} from '../../../pages/CreatePractice/DueDiligence/styled'
import DocumentUploader from './DocumentUploader'
import SkipSectionWarningModal from './SkipSectionWarningModal'
import { Box } from './styled'

const HumanResource = forwardRef(
  (
    {
      subStepName,
      formProgress,
      onSkip,
      handleFormSubmission,
      handleCanSkipChanged,
      onUpdateFinancialDetails,
    },
    ref,
  ) => {
    const { subscribe } = useEventEmitter()
    const { practiceId } = useParams()
    const { practice } = usePracticeById(practiceId)
    const uploaderRef = useRef()
    const [isSkipable, setIsSkipable] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [uploadedDocsUrls, setUploadedDocsUrls] = useState(
      practice?.dueDiligence?.humanResource || {},
    )
    const [currentSection] = useState(0)
    const sampleDocLink = process.env.REACT_APP_SAMPLE_DOC_LINK

    const sections = useMemo(
      () => [
        {
          name: 'employeesSpreadsheet',
          title: 'HR employee information',
          subtitle:
            'Please upload a spreadsheet with your employee information.',
        },
      ],
      [],
    )

    useEffect(() => {
      const unsubscribe = subscribe('onEmployeesTableChanged', (data) => {
        setUploadedDocsUrls((old) => ({
          ...old,
          ...data,
        }))
      })

      return unsubscribe
    }, [subscribe])

    useEffect(() => {
      if (uploadedDocsUrls) onUpdateFinancialDetails(uploadedDocsUrls)
    }, [uploadedDocsUrls])

    useEffect(() => {
      const canSkip = Object.keys(uploadedDocsUrls).some((key) => {
        if (uploadedDocsUrls[key] instanceof Array) {
          const allRowColumnsFilled =
            !_.isEmpty(uploadedDocsUrls[key]) &&
            uploadedDocsUrls[key].every((row) =>
              Object.values(row).every((entry) => !_.isEmpty(entry)),
            )
          return allRowColumnsFilled
        }
      })
      setIsSkipable(!canSkip)
    }, [uploadedDocsUrls, currentSection, sections, practice])

    useEffect(() => {
      if (handleCanSkipChanged) {
        handleCanSkipChanged(isSkipable)
      }
    }, [isSkipable])

    const handleOnBackPressed = useCallback(
      (callback) => {
        callback()
      },
      [currentSection],
    )

    useImperativeHandle(ref, () => ({
      submit: handleFormSubmit,
      onBack: handleOnBackPressed,
      isSkipable: isSkipable,
    }))

    const handleFormSubmit = useCallback(
      (callback, config) => {
        const data = _.cloneDeep(uploadedDocsUrls)
        delete data.employeesSpreadsheet
        if (config?.override && callback) {
          callback(data)
        } else if (isSkipable) {
          if (formProgress && !_.isEmpty(formProgress.skipped)) {
            callback(data)
          } else {
            setShowModal(true)
          }
        } else {
          // handle form submission
          if (callback) {
            callback(data)
          } else if (handleFormSubmission) {
            handleFormSubmission(data)
          }
        }
      },
      [
        isSkipable,
        formProgress,
        onSkip,
        setShowModal,
        handleFormSubmission,
        uploadedDocsUrls,
      ],
    )

    const handleOnUploadComplete = useCallback(
      (url) => {
        setUploadedDocsUrls((old) => ({
          ...old,
          [sections[currentSection].name]: _.concat(
            uploadedDocsUrls[sections[currentSection].name] || [],
            url,
          ),
        }))
      },
      [currentSection, uploadedDocsUrls, sections, uploaderRef],
    )

    const handleOnSkip = useCallback(() => {
      const data = _.cloneDeep(uploadedDocsUrls)
      delete data.employeesSpreadsheet
      setShowModal(false)
      handleFormSubmission(data)
    }, [uploadedDocsUrls])

    const currentSectionUploadedFiles = useMemo(() => {
      return uploadedDocsUrls[sections[currentSection]?.name]
    }, [uploadedDocsUrls, sections, currentSection])

    return (
      <Box>
        <FormTitle>Human resources</FormTitle>
        <FormTitle style={{ marginTop: '10px', fontSize: '16px' }}>
          {sections[currentSection].title}
        </FormTitle>

        <SubtitleText className='note' style={{ margin: '10px 0px 10px 0px' }}>
          {sections[currentSection].subtitle}
        </SubtitleText>
        <SubtitleText className='note' style={{ margin: 0 }}>
          If you do not have one here is an example; feel free to adjust the
          format. -{' '}
          <a
            href={sampleDocLink}
            download='Sample HR Due Diligence.xlsx'
            target='blank'
          >
            Sample HR Due Diligence.xlsx
          </a>
        </SubtitleText>

        <DocumentUploader
          ref={uploaderRef}
          onUploadComplete={handleOnUploadComplete}
          practiceId={practiceId}
          uploadedFilesUrls={currentSectionUploadedFiles || []}
        />

        <SkipSectionWarningModal
          subStepName={subStepName}
          formProgress={formProgress}
          practiceId={practiceId}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          onSkip={handleOnSkip}
        />
      </Box>
    )
  },
)

export default HumanResource
