import gql from 'graphql-tag'

export const EDIT_NAME = gql`
  mutation editName($firstName: String!, $lastName: String!, $utm: String) {
    editName(firstName: $firstName, lastName: $lastName, utm: $utm) {
      firstName
      lastName
    }
  }
`

export const EDIT_PHONE_NUMBER = gql`
  mutation editPhoneNumber($phone: String!) {
    editPhoneNumber(phone: $phone) {
      success
      errorCode
    }
  }
`

export const VERIFY_PHONE_NUMBER = gql`
  mutation verifyPhoneNumber($code: String!) {
    verifyPhoneNumber(code: $code) {
      success
      errorCode
    }
  }
`

export const EDIT_MAIN_GOALS_AND_NDA = gql`
  mutation editMainGoalsAndNda($mainGoals: [MainGoal]!) {
    editMainGoalsAndNda(mainGoals: $mainGoals) {
      goals {
        id
        mainGoal
        subGoal
      }
    }
  }
`

export const RECORD_SIGNED_NDA = gql`
  mutation recordUserHasSignedNda {
    recordUserHasSignedNda {
      id
      signedNda
    }
  }
`

export const EDIT_PRODUCT_OR_SERVICE = gql`
  mutation editProductOrService(
    $goalId: String!
    $productOrService: ProductOrService!
  ) {
    editProductOrService(goalId: $goalId, productOrService: $productOrService) {
      goals {
        id
        mainGoal
        subGoal
      }
    }
  }
`

export const EDIT_IS_LICENSED_TO_PRACTICE = gql`
  mutation editIsLicensedToPractice($isLicensedToPractice: Boolean!) {
    editIsLicensedToPractice(isLicensedToPractice: $isLicensedToPractice) {
      isLicensedToPractice
    }
  }
`

export const EDIT_LICENSE_INFO = gql`
  mutation editLicenseInfo(
    $licensingProvinceOrState: ProvinceOrState!
    $licensingCountry: Country!
    $licensingBody: LicensingBody!
    $licenseNumber: String!
  ) {
    editLicenseInfo(
      licensingProvinceOrState: $licensingProvinceOrState
      licensingCountry: $licensingCountry
      licensingBody: $licensingBody
      licenseNumber: $licenseNumber
    ) {
      license {
        licensingBody
        licensingProvinceOrState
        licensingCountry
        licenseNumber
        licenseVerified
      }
    }
  }
`

export const EDIT_LINKEDIN_URL = gql`
  mutation editLinkedinUrl($linkedinUrl: String!) {
    editLinkedinUrl(linkedinUrl: $linkedinUrl) {
      linkedinUrl
    }
  }
`

export const COMPLETE_ONBOARDING = gql`
  mutation completeOnboarding {
    completeOnboarding {
      hasOnboarded
    }
  }
`
