import { useMutationWithErrorLogging } from '../../../utils/hooks'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Paths from '../../../Paths'
import {
  COMPLETE_ONBOARDING,
  EDIT_IS_LICENSED_TO_PRACTICE,
} from '../../../api/mutations/users'
import { GET_MY_USER } from '../../../api/queries/users'
import Header from '../../../components/NavBar/Header'
import OnboardingSteps from '../../../components/Onboarding/OnboardingSteps'
import {
  BackgroundContainer,
  ColumnButtonContainer,
  ErrorMessageContainerBelowField,
  ErrorText,
  FlexDiv,
  FormControlLabel,
  PageContainer,
  Radio,
} from '../../../styles/shared-styled-components'
import { getProductOrServiceGoal } from '../../../utils/user'
import {
  Asset,
  AssetContainer,
  BackButton,
  ContinueButton,
  FormTitleText,
  OnboardingForm,
  OnboardingFormContainer,
  OnboardingFormGroup,
  OnboardingFormGroupContainer,
} from '../shared-onboarding-styles'

const IsLicensedToPractice = ({
  user,
  width,
  ismobilescreen,
  isIpadSizeOrSmaller,
}) => {
  const navigate = useNavigate()
  const [editIsLicensedToPracticeMutation, { data }] =
    useMutationWithErrorLogging(EDIT_IS_LICENSED_TO_PRACTICE)
  const [completeOnboardingMutation, { data: completeOnboardingData }] =
    useMutationWithErrorLogging(COMPLETE_ONBOARDING)

  const productOrServicesGoalPresent = !!getProductOrServiceGoal(user?.goals)

  const [isLicensed, setIsLicensed] = useState(user?.isLicensedToPractice)
  const [errorMessage, setErrorMessage] = useState()
  // Used so that just in case the navigation from onboarding -> home takes a long time,
  // the user can't smash the continue button while we're navigating them
  const [isLoading, setIsLoading] = useState(false)

  const navigateToPreviousOnboardingStep = () => {
    if (productOrServicesGoalPresent) {
      navigate(Paths.productOrServiceSubGoalOnboarding)
    } else {
      navigate(Paths.goalsOnboarding)
    }
  }

  const onClickContinueFromIsLicensed = () => {
    editIsLicensedToPracticeMutation({
      variables: {
        isLicensedToPractice: isLicensed,
      },
      // The next screen (license info) is already mounted, so it won't trigger a refetch of the user object
      // So we have to manually update the cache for it to get the updated isLicensedToPractice value
      update: async (cache, { data }) => {
        const userDataFetch = cache.readQuery({
          query: GET_MY_USER,
        })

        // Grab the returned isLicensed value, and if not present grab the state var passed into the mutation
        // (these two values should always be the same anyhow)
        const isLicensedToPracticeResult =
          data?.editIsLicensedToPractice?.isLicensedToPractice || isLicensed

        // If we have cached user data, update the isLicensedToPractice field
        if (userDataFetch) {
          // Grab the data in the cache and update isLicensedToPractice
          const existingUser = userDataFetch.getMyUser
          const updatedUser = {
            ...existingUser,
            isLicensedToPractice: isLicensedToPracticeResult,
          }

          // Update the cache
          await cache.writeQuery({
            query: GET_MY_USER,
            data: {
              getMyUser: updatedUser,
            },
          })
        }
      },
      onCompleted: (data) => {
        // Grab the returned isLicensed value, and if not present grab the state var passed into the mutation
        // (this is just a precaution, as these two values should both always be present, and have the same value)
        const isLicensedToPracticeResult =
          data?.editIsLicensedToPractice?.isLicensedToPractice || isLicensed

        if (isLicensedToPracticeResult != null) {
          navigateToNextOnboardingStep(isLicensedToPracticeResult)
        } else {
          setErrorMessage('Unable to save. Please refresh and try again.')
        }
      },
    })
  }

  const navigateToNextOnboardingStep = (isLicensedToPracticeResult) => {
    if (isLicensedToPracticeResult === true) {
      navigate(Paths.dentalLicenseInfoOnboarding)
    } else {
      if (productOrServicesGoalPresent) {
        navigate(Paths.linkedinUrlOnboarding)
      } else {
        // Complete onboarding
        setIsLoading(true)
        completeOnboardingMutation({
          update: async (cache, { data }) => {
            const userDataFetch = cache.readQuery({
              query: GET_MY_USER,
            })

            // Grab the returned hasOnboarded value
            const hasOnboardedResult = data?.completeOnboarding?.hasOnboarded

            // If we have cached user data, update the hasOnboarded field
            if (userDataFetch && hasOnboardedResult) {
              // Grab the data in the cache and update hasOnboarded
              const existingUser = userDataFetch.getMyUser
              const updatedUser = {
                ...existingUser,
                hasOnboarded: hasOnboardedResult,
              }

              // Update the cache
              await cache.writeQuery({
                query: GET_MY_USER,
                data: {
                  getMyUser: updatedUser,
                },
              })
            }

            if (!hasOnboardedResult) {
              setErrorMessage('Unable to save. Please refresh and try again.')
            }
          },
        })
      }
    }
  }

  return (
    <>
      <Header
        user={user}
        width={width}
        ismobilescreen={isIpadSizeOrSmaller}
        isOnboardingScreen
      />
      <BackgroundContainer>
        <PageContainer>
          <FlexDiv>
            <OnboardingFormContainer ismobilescreen={isIpadSizeOrSmaller}>
              <OnboardingSteps currentStep={4} />
              <OnboardingForm ismobilescreen={ismobilescreen}>
                <FormTitleText ismobilescreen={ismobilescreen}>
                  Are you licensed to practice dentistry in Canada?
                </FormTitleText>
                <OnboardingFormGroupContainer>
                  <OnboardingFormGroup key={'yes'}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={isLicensed === true}
                          onClick={() => setIsLicensed(true)}
                        />
                      }
                      label={'Yes'}
                    />
                  </OnboardingFormGroup>
                  <OnboardingFormGroup key={'no'}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={isLicensed === false}
                          onClick={() => setIsLicensed(false)}
                        />
                      }
                      label={'No'}
                    />
                  </OnboardingFormGroup>
                  {errorMessage && (
                    <ErrorMessageContainerBelowField>
                      <ErrorText>{errorMessage}</ErrorText>
                    </ErrorMessageContainerBelowField>
                  )}
                </OnboardingFormGroupContainer>
              </OnboardingForm>
              <ColumnButtonContainer>
                <ContinueButton
                  disabled={isLicensed == null || isLoading}
                  onClick={onClickContinueFromIsLicensed}
                >
                  Save and continue
                </ContinueButton>
                <BackButton onClick={navigateToPreviousOnboardingStep}>
                  Back
                </BackButton>
              </ColumnButtonContainer>
            </OnboardingFormContainer>
            {!isIpadSizeOrSmaller && (
              <AssetContainer>
                <Asset src={Paths.acquireConnectMetrics} />
              </AssetContainer>
            )}
          </FlexDiv>
        </PageContainer>
      </BackgroundContainer>
    </>
  )
}

export default IsLicensedToPractice
