const color = {
  primary: 'rgba(1, 98, 153, 1)',
  primaryWithTransparency: 'rgba(1, 98, 153, 0.4)',
  darkPrimaryWithTransparency: 'rgba(1, 98, 153, 0.4)',
  secondary: 'lightseagreen',
  ternary: 'rgb(238, 243, 247)',
  purple: '#8E66AC',
  darkPrimary: '#016299',
  mediumPrimary: '#4d91b7',
  mediumPrimaryWithTransparency: 'rgba(77, 145, 183, 0.4)',
  lightPrimary: 'rgb(176, 204, 220)',
  lightPrimaryWithTransparency: 'rgba(176, 204, 220, 0.11)',
  lightHover: '#f5f5f5',
  darkHover: '#004b76',
  darkPurple: '#871F78',
  disabled: 'rgb(238, 238, 250)',
  white: 'white',
  black: 'rgba(0, 0, 0, 0.8)',
  blackHovered: 'rgba(0, 0, 0, 1)',
  red: '#C50000',
  error: '#d32f2f',
  brightGreen: 'rgb(37, 179, 77)',
  brightGreenWithTransparency: 'rgba(37, 179, 77, 0.6)',
  brightGreenHover: '#197d35',
  muiLightGrey: '#cbcbcb',
  muiDarkGrey: '#717171',
  grey: 'rgb(107, 107, 107)',
  tooltipGrey: 'rgba(97, 97, 97)',
  lightGrey: 'rgb(107, 107, 107, 0.6)',
  lighterGrey: 'rgb(216, 216, 216, 0.65)',
  darkGrey: 'rgb(89, 107, 130)',
  lightBlue: '#eef3f7',
  superLightGrey: 'rgb(245, 245, 245)',
  superLightGreyWithTransparency: 'rgba(245, 245, 245, 0.3)',
  info: 'rgba(220,88,42)',
  pending: '#dc4d01',
  underReview: '#fdaa00',
  offMarket: '#865F9D',
  grey: '#808080',
  blackWithTransparency: 'rgba(0, 0, 0, 0.50)',
  // 'rgb(255,0,237)',
  // '#6a6aa0',
  sold: 'rgb(107, 107, 107)',
  // '#808080',
}

export default color
