import moment from 'moment'

export const dateToNicelyFormattedString = (date) => {
  return new Date(date).toDateString().split(' ').slice(1).join(' ')
}

export const dateToDMYFormat = (date) => {
  if (date) {
    return new Date(date).toLocaleDateString('en-GB')
  }
}

export const dateToDMFormat = (dateString) => {
  if (dateString) {
    const date = new Date(dateString)
    const monthIndex = date.getMonth()
    const year = date.getFullYear()

    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]

    const monthName = monthNames[monthIndex]

    return `${monthName} ${year}`
  } else {
    return ''
  }
}

export const numMonthsToString = (numMonths) => {
  if (numMonths === 0) return 'Immediately'
  if (numMonths < 12) {
    return `${numMonths} months`
  }

  const modulo = numMonths % 12
  const yearsAsInt = Math.floor(numMonths / 12)
  const yearsString = `${yearsAsInt} ${yearsAsInt === 1 ? 'year' : 'years'}`

  if (modulo === 0) {
    return `${yearsString}`
  }

  return `${yearsString} ${modulo} months`
}

export const dateToSentenceFormat = (inputDate) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const date = new Date(inputDate)
  const day = date.getDate()
  const month = months[date.getMonth()]
  const year = date.getFullYear()

  // Function to add the appropriate suffix to the day
  function getDayWithSuffix(day) {
    if (day >= 11 && day <= 13) {
      return day + 'th'
    } else {
      switch (day % 10) {
        case 1:
          return day + 'st'
        case 2:
          return day + 'nd'
        case 3:
          return day + 'rd'
        default:
          return day + 'th'
      }
    }
  }

  const formattedDate = `${month} ${getDayWithSuffix(day)}, ${year}`
  return formattedDate
}

export const dateToHumanReadableFormat = (inputDate) => {
  return moment(inputDate).fromNow()
}

export const getDaysRemainingToSave = (practice) => {
  const givenDate = new Date(practice?.createdAt)

  if (givenDate) {
    const currentDate = new Date()
    const timeDifference = currentDate - givenDate
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

    return 60 - daysDifference >= 0 ? 60 - daysDifference : 0
  }

  return 60
}

export default {
  dateToNicelyFormattedString,
  dateToDMYFormat,
  numMonthsToString,
  dateToDMFormat,
  dateToSentenceFormat,
  dateToHumanReadableFormat,
  getDaysRemainingToSave,
}
