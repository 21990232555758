import { useMutationWithErrorLogging } from '../../utils/hooks'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useBuyerById } from '../../api/hooks/buyers'
import { CREATE_ACQUISITION } from '../../api/mutations/acquisitions'
import LoadingIndicator from '../../components/LoadingIndicator'
import PurchaseIntentDialog from '../../components/Micro/PurchaseIntentDialog/PurchaseIntentDialog'
import Header from '../../components/NavBar/Header'
import {
  BackgroundContainer,
  ErrorHelperText,
  PageContainer,
} from '../../styles/shared-styled-components'
import {
  CancelButton,
  FlexContainer,
  FullPageFlexContainer,
  NextButton,
  ResponsiveFlexContainer,
  StyledBasicLabel,
  TitleText,
  Wrapper,
} from '../BuyerProfile/styled'
import {
  acquisitionTypes,
  digitsRegex,
  stringRegex,
} from '../../utils/constants'
import { getCapitalizedWord } from '../../utils/naming'
import _ from 'lodash'

const PurchaseIntent = ({ user, width, ismobilescreen }) => {
  const [payload, setPayload] = useState({})
  const [buyingConditions, setBuyingConditions] = useState([])
  const [showPopup, setShowPopup] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(null)

  const { buyerId } = useParams()
  const { buyer } = useBuyerById(buyerId)
  const { search } = useLocation()

  const queryParams = new URLSearchParams(search)
  const practiceId = queryParams.get('practice_id')

  useEffect(() => {
    if (buyer) {
      if (buyer.buying_conditions) {
        setBuyingConditions(buyer.buying_conditions.split(','))
      }
    }
  }, [buyer])

  const [createAcquisitionMutation] =
    useMutationWithErrorLogging(CREATE_ACQUISITION)

  const handleBuyingCondition = (e) => {
    let modifyBuyingCondition
    if (e.target.checked) {
      modifyBuyingCondition = [...buyingConditions, e.target.value]
    } else {
      modifyBuyingCondition = buyingConditions.filter(
        (buyingCondition) => buyingCondition !== e.target.value,
      )
    }
    setBuyingConditions(modifyBuyingCondition)
    setPayload({
      ...payload,
      buying_conditions: modifyBuyingCondition.join(','),
    })
  }

  const validate = useCallback(() => {
    const errors = {}
    const requiredFields = [
      {
        name: 'years_to_work_post_sale',
        regex: digitsRegex,
      },
      {
        name: 'pre_approved_financing',
        regex: stringRegex,
      },
      {
        name: 'percentage_to_buy',
        regex: stringRegex,
      },
      {
        name: 'purchase_price',
        regex: digitsRegex,
      },
    ]

    requiredFields.forEach(({ name, regex }) => {
      if (!(payload[name]?.trim() && regex.test(payload?.[name]?.trim())))
        errors[name] = `${getCapitalizedWord(name.replace(/_/g, ' '))} is ${
          payload[name]?.trim() ? 'required' : 'invalid'
        }`
    })

    setErrors(errors)
    return _.isEmpty(Object.keys(errors))
  }, [payload])

  const handleContinue = useCallback(() => {
    if (loading || !validate()) return
    setLoading(true)
    createAcquisitionMutation({
      variables: {
        ...payload,
        buyer_id: buyerId,
        practice_id: practiceId,
        type: acquisitionTypes.PURCHASE_INTENT,
        purchase_price: Number(payload?.purchase_price),
        buying_conditions: _.isEmpty(payload?.buying_conditions)
          ? ''
          : payload?.buying_conditions,
      },
      onCompleted: () => {
        // Navigate to the next step
        setLoading(false)
        togglePopup()
      },
      onError: () => {
        setLoading(false)
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload, buyerId, practiceId, loading])

  const handleBackPressed = () => {
    window.history.back()
  }

  function togglePopup() {
    setShowPopup(!showPopup)
  }

  const handleInput = useCallback(
    (e) => {
      setPayload({
        ...payload,
        [e.target.name]: e.target.value,
      })
      setTimeout(validate, 1000)
    },
    [validate, payload],
  )

  return buyer ? (
    <>
      <Header user={user} width={width} ismobilescreen={ismobilescreen} />
      <BackgroundContainer>
        <PageContainer>
          <FullPageFlexContainer>
            <Wrapper>
              <TitleText style={{ textAlign: 'center' }}>
                Purchase Intent
              </TitleText>

              <FlexContainer className='with-gapping'>
                <Box>
                  <FormControl fullWidth>
                    <TextField
                      name='years_to_work_post_sale'
                      value={payload?.years_to_work_post_sale}
                      label='Number of years for seller to work post-sale*'
                      variant='outlined'
                      placeholder='Enter number of years'
                      onChange={handleInput}
                      error={errors?.years_to_work_post_sale}
                      helperText={errors?.years_to_work_post_sale}
                    />
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth error={errors?.pre_approved_financing}>
                    <InputLabel>
                      Do you have pre approved financing?*
                    </InputLabel>
                    <Select
                      name='pre_approved_financing'
                      value={payload?.pre_approved_financing}
                      label='Do you have pre approved financing?*'
                      onChange={handleInput}
                    >
                      <MenuItem value={''}>Select</MenuItem>
                      <MenuItem value={'Yes'}>Yes</MenuItem>
                      <MenuItem value={'No'}>No</MenuItem>
                    </Select>
                  </FormControl>
                  <ErrorHelperText style={{ marginLeft: 12 }}>
                    {errors?.pre_approved_financing}
                  </ErrorHelperText>
                </Box>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth error={errors?.percentage_to_buy}>
                    <InputLabel>
                      What percentage are you willing to buy?*
                    </InputLabel>
                    <Select
                      name='percentage_to_buy'
                      value={payload?.percentage_to_buy}
                      label='What percentage are you willing to buy?*'
                      onChange={handleInput}
                    >
                      <MenuItem value={'0-20%'}>0-20%</MenuItem>
                      <MenuItem value={'20-40%'}>20-40%</MenuItem>
                      <MenuItem value={'40-60%'}>40-60%</MenuItem>
                      <MenuItem value={'60-80%'}>60-80%</MenuItem>
                      <MenuItem value={'80-100%'}>80-100%</MenuItem>
                    </Select>
                  </FormControl>
                  <ErrorHelperText style={{ marginLeft: 12 }}>
                    {errors?.percentage_to_buy}
                  </ErrorHelperText>
                </Box>
                <Box>
                  <StyledBasicLabel>
                    Buying conditions <small>(optional)</small>
                  </StyledBasicLabel>
                  <FormControl fullWidth>
                    <Box sx={{ display: 'flex' }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={handleBuyingCondition}
                              value={'Hold-back'}
                              checked={buyingConditions?.includes('Hold-back')}
                            />
                          }
                          label='Hold-back'
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={handleBuyingCondition}
                              value={'Earn-out'}
                              checked={buyingConditions?.includes('Earn-out')}
                            />
                          }
                          label='Earn-out'
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={handleBuyingCondition}
                              value={'Vendor take-back loan'}
                              checked={buyingConditions?.includes(
                                'Vendor take-back loan',
                              )}
                            />
                          }
                          label='Vendor take-back loan'
                        />
                      </FormGroup>
                    </Box>
                  </FormControl>
                </Box>
                <Box>
                  <FormControl fullWidth>
                    <TextField
                      name='purchase_price'
                      value={payload?.purchase_price}
                      label='Enter your purchase price*'
                      variant='outlined'
                      placeholder='Enter a value'
                      onChange={handleInput}
                      error={errors?.purchase_price}
                      helperText={errors?.purchase_price}
                    />
                  </FormControl>
                </Box>
              </FlexContainer>
            </Wrapper>
            <ResponsiveFlexContainer block footer>
              {ismobilescreen ? (
                <>
                  <NextButton disabled={loading} onClick={handleContinue}>
                    {loading ? 'Please wait...' : 'Continue'}
                  </NextButton>
                  {showPopup ? (
                    <PurchaseIntentDialog toggle={togglePopup} />
                  ) : null}
                  <CancelButton disabled={loading} onClick={handleBackPressed}>
                    Back
                  </CancelButton>
                </>
              ) : (
                <>
                  <CancelButton disabled={loading} onClick={handleBackPressed}>
                    Back
                  </CancelButton>

                  <NextButton disabled={loading} onClick={handleContinue}>
                    {loading ? 'Please wait...' : 'Continue'}
                  </NextButton>
                  {showPopup ? (
                    <PurchaseIntentDialog toggle={togglePopup} />
                  ) : null}
                </>
              )}
            </ResponsiveFlexContainer>
          </FullPageFlexContainer>
        </PageContainer>
      </BackgroundContainer>
    </>
  ) : (
    <LoadingIndicator fullScreen />
  )
}

export default PurchaseIntent
