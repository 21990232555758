import React from 'react'
import {
  ColumnButtonContainer,
  Modal,
  ModalActions,
  ModalContainer,
  ModalContent,
  ModalContentText,
  ModalEmoji,
  ModalHeaderContainer,
  PrimaryModalActionButton,
  TitleText,
} from '../../../styles/shared-styled-components'

const OnboardingModal = ({
  titleText,
  contentText,
  continueText,
  isOpen,
  onClose,
  onContinue,
  variant,
}) => {
  const icon =
    variant === 'success'
      ? '✅'
      : variant === 'pending'
        ? '⌛'
        : variant === 'info'
          ? 'ℹ️'
          : ''

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContainer>
        <ModalHeaderContainer>
          {variant && <ModalEmoji>{icon}</ModalEmoji>}
          <TitleText>{titleText}</TitleText>
        </ModalHeaderContainer>
        <ModalContent>
          <ModalContentText>{contentText}</ModalContentText>
        </ModalContent>
        <ModalActions>
          <ColumnButtonContainer>
            <PrimaryModalActionButton onClick={onContinue}>
              {continueText}
            </PrimaryModalActionButton>
          </ColumnButtonContainer>
        </ModalActions>
      </ModalContainer>
    </Modal>
  )
}

export default OnboardingModal
