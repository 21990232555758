import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import PercentIcon from '@mui/icons-material/Percent'
import styled, { css } from 'styled-components/macro'
import {
  CreatePracticeInputWidth,
  CreatePracticeInputWidthStyle,
  DropdownLabel,
  Form,
  IntraSubsectionalGap,
  RowFlexStyle,
  TitleTextInput,
} from '../../../styles/shared-styled-components'

const CreatePracticeInputStyle = css`
  // Changing the base label text when not floating
  .MuiInputLabel-root {
    font-size: ${(props) =>
      props.ismobilescreen && !props.includesStartAdornment
        ? props.theme.typography.defaultFontSize
        : props.theme.typography.titleFontSizeInputLabel};
    padding-right: 8px;
  }
  // Removing horizontal padding when there's a start adornment
  .MuiOutlinedInput-input {
    padding: ${(props) => props.includesStartAdornment && '10px 0px'};
  }
  .MuiOutlinedInput-root {
    padding-right: 9px;
  }
  // Changing the floating label text when shrunk
  .MuiInputLabel-shrink {
    font-size: ${(props) =>
      props.theme.typography.titleFontSizeInputLabel} !important;
  }
  // Changing the floating label text when focused
  .MuiInputLabel-root.Mui-focused {
    font-size: ${(props) => props.theme.typography.titleFontSizeInputLabel};
  }
  // To make sure fields dont expand to take up a disproportionate amount of space on the page
  flex-grow: 0;
`

export const CreatePracticeSplitInputContainer = styled.div`
  ${RowFlexStyle}
  ${IntraSubsectionalGap}
  ${CreatePracticeInputWidthStyle}
`

export const CreatePracticeTextInputWidthStyle = css`
  width: ${(props) =>
    props.split == null
      ? CreatePracticeInputWidth
      : props.split === 'majority'
        ? '60%'
        : '40%'};
`

export const CreatePracticeTextInputStyle = css`
  ${CreatePracticeInputStyle}
  cursor: ${(props) => props.fieldType !== 'text' && 'pointer'};
  width: ${(props) =>
    props.split == null
      ? CreatePracticeInputWidth
      : props.split === 'majority'
        ? '60%'
        : '40%'};
`

export const CreatePracticeTextInputComponent = styled(TitleTextInput)`
  ${CreatePracticeTextInputStyle}
`

export const CreatePracticeForm = styled(Form)`
  ${CreatePracticeInputStyle}
  width: ${(props) =>
    props.split == null
      ? CreatePracticeInputWidth
      : props.split === 'majority'
        ? '60%'
        : '40%'};
  .MuiInputLabel-root {
    font-size: ${(props) =>
      props.ismobilescreen
        ? props.theme.typography.defaultFontSize
        : props.theme.typography.titleFontSizeInputLabel};
  }
  .MuiInputLabel-shrink {
    font-size: ${(props) =>
      props.theme.typography.titleFontSizeInputLabel} !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) =>
      props.containsError
        ? props.theme.color.error
        : props.theme.color.muiLightGrey};
  }
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) =>
      props.containsError
        ? props.theme.color.error
        : props.theme.color.primary};
  }
`

export const CreatePracticeDropdownLabel = styled(DropdownLabel)`
  font-size: ${(props) =>
    props.ismobilescreen
      ? props.theme.typography.defaultFontSize
      : props.theme.typography.titleFontSizeInputLabel};
`

export const DollarIcon = styled(AttachMoneyIcon)`
  color: ${({ theme }) => theme.color.primary};
  height: 21px;
  width: 21px;
`

export const PercentageIcon = styled(PercentIcon)`
  color: ${({ theme }) => theme.color.primary};
  height: 18px;
  width: 18px;
  padding-right: 5px;
`
