import PracticeListingTypeColorCode from '../components/Micro/PracticeListingTypeColorCode/PracticeListingTypeColorCode'

export const userRoles = {
  staff: 'staff',
  user: 'user',
}

export const sellGoalKey = 'sell'
export const buyGoalKey = 'buy'
export const valueGoalKey = 'value'
export const understandMarketGoalKey = 'understand_market'
export const practiceFinancingKey = 'practice_financing'
export const provideProductsOrServicesGoalKey = 'provide_products_service'
export const DUE_DILIGENCE_KEY = 'dueDiligenceFormProgress'

export const acquisitionTypes = {
  ACCESS_REQUEST: 'access_request',
  PURCHASE_INTENT: 'purchase_intent',
}

export const acquisitionRequestsStatuses = {
  ACCEPTED: 'accepted',
  APPROVED: 'approved',
  PENDING: 'pending',
  DECLINED: 'declined',
  REJECTED: 'rejected',
}

export const aquisitionFilters = {
  [acquisitionTypes.ACCESS_REQUEST]: [
    { value: 'all', label: 'All Requests' },
    { value: acquisitionRequestsStatuses.APPROVED, label: 'Approved Requests' },
    { value: acquisitionRequestsStatuses.PENDING, label: 'Pending Requests' },
    { value: acquisitionRequestsStatuses.DECLINED, label: 'Declined Requests' },
  ],
  [acquisitionTypes.PURCHASE_INTENT]: [
    { value: 'all', label: 'All Intents' },
    { value: acquisitionRequestsStatuses.ACCEPTED, label: 'Accepted Intents' },
    { value: acquisitionRequestsStatuses.REJECTED, label: 'Rejected Intents' },
  ],
}

export const ACT_AS_USER_LOCAL_STORAGE_KEY = 'actAsUser'

export const provincesAndStatesCodeNameMapArray = [
  {
    key: 'alberta',
    label: 'Alberta',
  },
  {
    key: 'british_columbia',
    label: 'British Columbia',
  },
  {
    key: 'manitoba',
    label: 'Manitoba',
  },
  {
    key: 'new_brunswick',
    label: 'New Brunswick',
  },
  {
    key: 'newfoundland_and_labrador',
    label: 'Newfoundland',
  },
  {
    key: 'north_west_territories',
    label: 'Northwest Territories',
  },
  {
    key: 'nova_scotia',
    label: 'Nova Scotia',
  },
  {
    key: 'nunavut',
    label: 'Nunavut',
  },
  {
    key: 'ontario',
    label: 'Ontario',
  },
  {
    key: 'prince_edward_island',
    label: 'Prince Edward Island',
  },
  {
    key: 'quebec',
    label: 'Quebec',
  },
  {
    key: 'saskatchewan',
    label: 'Saskatchewan',
  },
  {
    key: 'yukon',
    label: 'Yukon',
  },
]

export const onboardingPathPrefix = '/onboarding/'

export const incompleteStatusKey = 'incomplete'
export const readyToListStatusKey = 'ready_to_list'
export const underReviewStatusKey = 'under_review'
export const postedStatusKey = 'posted'

export const offMarketStageKey = 'off_market'
export const forSaleStageKey = 'for_sale'
export const soldStageKey = 'sold'
export const allSaleStages = [offMarketStageKey, forSaleStageKey, soldStageKey]
export const activeListingSaleStages = [offMarketStageKey, forSaleStageKey]

export const allListingStatuses = [
  incompleteStatusKey,
  readyToListStatusKey,
  underReviewStatusKey,
  postedStatusKey,
  ...allSaleStages,
]

export const unpostedListingTypes = [incompleteStatusKey, readyToListStatusKey]

export const saleStageLabelsForBuyers = {
  [offMarketStageKey]: (
    <>
      Off-market practices{' '}
      <PracticeListingTypeColorCode listingType={offMarketStageKey} />{' '}
    </>
  ),
  [forSaleStageKey]: (
    <>
      Practices for sale{' '}
      <PracticeListingTypeColorCode listingType={forSaleStageKey} />{' '}
    </>
  ),
  [soldStageKey]: (
    <>
      Sold practices <PracticeListingTypeColorCode listingType={soldStageKey} />{' '}
    </>
  ),
}

export const saleStageLabelsForSellers = {
  [offMarketStageKey]: {
    label: 'Off-Market',
    key: offMarketStageKey,
    completeSubtext:
      "Off-market listings get less attention from buyers, though they can still receive offers. It's meant for those who want to passively solicit interest.",
    requiresCompleteListing: false,
  },
  [forSaleStageKey]: {
    label: 'For Sale',
    key: forSaleStageKey,
    completeSubtext:
      'For sale listings are preferable to buyers, so they get more visibility in the marketplace.',
    incompleteSubtext:
      'You can only be fully listed for sale if you complete all sections of the upload flow.',
    requiresCompleteListing: true,
  },
}

export const allSaleStageLabels = {
  ...saleStageLabelsForSellers,
  [soldStageKey]: {
    label: 'Sold',
    key: soldStageKey,
    completeSubtext: 'Sold practices dating back to 2018.',
    incompleteSubtext: 'Sold practices dating back to 2018.',
    requiresCompleteListing: false,
  },
}

export const booleanFields = [
  {
    key: true,
    label: 'Yes',
  },
  {
    key: false,
    label: 'No',
  },
]

export const openDaysPerWeekFields = [
  {
    key: 1,
    label: '1',
  },
  {
    key: 2,
    label: '2',
  },
  {
    key: 3,
    label: '3',
  },
  {
    key: 4,
    label: '4',
  },
  {
    key: 5,
    label: '5',
  },
  {
    key: 6,
    label: '6',
  },
  {
    key: 7,
    label: '7',
  },
]

// Because this is a custom type, we must use capitalized strings to not clash with the
// reserved boolean keywords in GraphQL
export const optionalBooleanFields = [
  {
    key: 'True',
    label: 'Yes',
  },
  {
    key: 'False',
    label: 'No',
  },
  {
    key: 'Unsure',
    label: 'Not sure',
  },
]

// Use the optional boolean fields, just change the 'Unsure' label to 'Negotiable'
export const negotiableBooleanFields = optionalBooleanFields.map((field) => {
  if (field.key === 'Unsure') {
    return {
      ...field,
      label: 'Negotiable',
    }
  }
  return field
})

export const paymentTerminalVendorTypes = [
  'Moneris',
  'Clover',
  'TD',
  'Stripe',
  'Square',
  'Elavon',
  'Global Payments',
  'Other',
]

export const general_type_key = 'general'
export const anesthesiology_type_key = 'anesthesiology'
export const endodontics_type_key = 'endodontics'
export const hygiene_type_key = 'hygiene'
export const oral_max_surgery_type_key = 'oral_max_surgery'
export const oral_radiology_type_key = 'oral_max_radiology'
export const orthodontics_type_key = 'orthodontics'
export const pediatrics_type_key = 'pediatrics'
export const periodontics_type_key = 'periodontics'
export const prosthodontics_type_key = 'prosthodontics'

export const practiceTypes = [
  {
    key: general_type_key,
    label: 'General Dentistry',
    abbreviatedLabel: 'General Dentistry',
  },
  {
    key: anesthesiology_type_key,
    label: 'Dental Anesthesiology',
    abbreviatedLabel: 'Anesthesiology',
  },
  {
    key: endodontics_type_key,
    label: 'Endodontics',
    abbreviatedLabel: 'Endodontics',
  },
  {
    key: hygiene_type_key,
    label: 'Hygiene Clinic',
    abbreviatedLabel: 'Hygiene',
  },
  {
    key: oral_max_surgery_type_key,
    label: 'Oral & Maxillofacial Surgery',
    abbreviatedLabel: 'OMFS',
  },
  {
    key: oral_radiology_type_key,
    label: 'Oral Radiology',
    abbreviatedLabel: 'Oral Radiology',
  },
  {
    key: orthodontics_type_key,
    label: 'Orthodontics',
    abbreviatedLabel: 'Orthodontics',
  },
  {
    key: pediatrics_type_key,
    label: 'Pediatric Dentistry',
    abbreviatedLabel: 'Pediatrics',
  },
  {
    key: periodontics_type_key,
    label: 'Periodontics',
    abbreviatedLabel: 'Periodontics',
  },
  {
    key: prosthodontics_type_key,
    label: 'Prosthodontics',
    abbreviatedLabel: 'Prosthodontics',
  },
]

export const yearsSold = [2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024]

export const practiceOnlySaleTypeKey = 'practice_only'
export const practiceWithNegotiableBuildingSaleTypeKey =
  'practice_with_negotiable_building'
export const practiceAndBuildingSaleTypeKey = 'practice_and_building'
export const saleTypes = [
  {
    key: practiceOnlySaleTypeKey,
    label: 'Practice only',
  },
  {
    key: practiceWithNegotiableBuildingSaleTypeKey,
    label: 'Practice with building negotiable',
  },
  {
    key: practiceAndBuildingSaleTypeKey,
    label: 'Practice and building',
  },
]

export const getBuildingForSaleValueFromSaleType = (saleType) => {
  if (!saleType) {
    return ''
  }

  if (saleType === practiceAndBuildingSaleTypeKey) {
    return 'True'
  }

  if (saleType === practiceWithNegotiableBuildingSaleTypeKey) {
    return 'Unsure'
  }

  return 'False'
}

export const dpmss = [
  {
    key: 'adstra',
    label: 'ADSTRA',
  },
  {
    key: 'akituone',
    label: 'Akitu One',
  },
  {
    key: 'cerebra',
    label: 'Cerebra',
  },
  {
    key: 'cleardent',
    label: 'Cleardent',
  },
  {
    key: 'consult_pro',
    label: 'Consult PRO',
  },
  {
    key: 'ctrl',
    label: 'CTRL',
  },
  {
    key: 'curve',
    label: 'Curve',
  },
  {
    key: 'dentalware',
    label: 'DentalWare',
  },
  {
    key: 'dentimax',
    label: 'DentiMax',
  },
  {
    key: 'dentrix',
    label: 'Dentrix',
  },
  {
    key: 'domtrak',
    label: 'Domtrak',
  },
  {
    key: 'abeldent',
    label: 'Abeldent',
  },
  {
    key: 'exan',
    label: 'EXAN',
  },
  {
    key: 'live_ddm',
    label: 'Live DDM',
  },
  {
    key: 'logic_tech',
    label: 'LogicTech',
  },
  {
    key: 'maxident',
    label: 'Maxident',
  },
  {
    key: 'novologik',
    label: 'Novologik',
  },
  {
    key: 'opendental',
    label: 'Open Dental',
  },
  {
    key: 'opes',
    label: 'OPES',
  },
  {
    key: 'oryx',
    label: 'Oryx',
  },
  {
    key: 'tracker',
    label: 'Tracker',
  },
  {
    key: 'other',
    label: 'Other',
  },
].sort((a, b) => {
  if (a.label === 'Other') return 1
  if (b.label === 'Other') return -1
  return a.label.localeCompare(b.label)
})

export const practiceOwnerWorkingSchedules = [
  {
    key: 'full_time',
    label: 'Full-time',
  },
  {
    key: 'part_time',
    label: 'Part-time',
  },
  {
    key: 'not_seeing_patients',
    label: 'Not seeing patients',
  },
]

export const practiceBuildingTypes = [
  {
    key: 'plaza',
    label: 'Plaza',
  },
  {
    key: 'indoor_mall',
    label: 'Indoor mall',
  },
  {
    key: 'high_rise_office_space',
    label: 'High-rise office space',
  },
  {
    key: 'street_level',
    label: 'Street-level',
  },
  {
    key: 'repurposed_home',
    label: 'Repurposed home',
  },
]

export const saleTimeFrames = [
  {
    key: '0_6',
    label: '0-6 months from now',
  },
  {
    key: '6_12',
    label: '6-12 months from now',
  },
  {
    key: '12_18',
    label: '12-18 months from now',
  },
  {
    key: '18+',
    label: '> 18 months from now',
  },
]

export const roomForOperatoryExpansionOptions = [
  {
    key: '1',
    label: 'Room for 1 more operatory',
  },
  {
    key: '2',
    label: 'Room for 2 more operatories',
  },
  {
    key: '3',
    label: 'Room for 3 more operatories',
  },
  {
    key: '4',
    label: 'Room for 4 more operatories',
  },
  {
    key: '5',
    label: 'Room for 5 more operatories',
  },
  {
    key: '6',
    label: 'Room for 6+ more operatories',
  },
]

export const basicAccessTierKey = 'user'
export const premiumAccessTierKey = 'premium'
export const dataroomAccessTierKey = 'dataroom'
export const purchaseIntentAccessTierKey = acquisitionTypes.PURCHASE_INTENT
export const practiceOwnerAccessTierKey = 'practice_owner'

export const privilegedUserAccessTiers = [
  dataroomAccessTierKey,
  purchaseIntentAccessTierKey,
  practiceOwnerAccessTierKey,
]

export const practiceUserAccessTiers = [
  {
    key: basicAccessTierKey,
    label: 'Basic user',
  },
  {
    key: premiumAccessTierKey,
    label: 'Dentacloud member',
  },
  {
    key: dataroomAccessTierKey,
    label: 'Dentacloud member with dataroom access',
  },
  {
    key: purchaseIntentAccessTierKey,
    label: 'Dentacloud member with an accepted purchase intent',
  },
]

export const practiceUserAccessTiersSortedByHierarchy = {
  [basicAccessTierKey]: 0,
  [premiumAccessTierKey]: 1,
  [dataroomAccessTierKey]: 2,
  [purchaseIntentAccessTierKey]: 3,
  [practiceOwnerAccessTierKey]: 4,
}

export const getLabelFromKey = (optionsArray, typeKey, type = 'full') => {
  // For some reason it wouldn't let me import the valueIsEmpty fcn here
  if (typeKey == null || typeKey === '') {
    return ''
  }

  const matchingOption = optionsArray.find((option) => typeKey === option.key)

  return type === 'abbreviated'
    ? matchingOption.abbreviatedLabel || ''
    : matchingOption?.label || ''
}

export const getPracticeListingStatus = (practice, ableToBeListed) => {
  const listing = practice?.listing

  if (!listing) {
    // if a listing hasn't been created yet,
    // the caller will tell us whether it's incomplete or ready to list
    return ableToBeListed ? readyToListStatusKey : incompleteStatusKey
  }

  if (listing.status === postedStatusKey) {
    // then just show the type: off-market, for sale, sold
    return listing.type
  }

  // if it's not already posted, just return the listing status
  return listing.status
}

export const getPracticeListingStatusSubtext = (
  practice,
  ableToBeListed,
  status = null,
) => {
  let statusToUse = status
  if (!statusToUse) {
    statusToUse = getPracticeListingStatus(practice, ableToBeListed)
  }

  const forSaleSubText =
    'Your practice is listed for sale. Your next step is to review interested buyers, and proceed in the sales process.'

  switch (statusToUse) {
    case incompleteStatusKey:
      return "You haven't uploaded enough information to be able to list your practice. Complete your listing to begin receiving offers."
    case readyToListStatusKey:
      return "You've filled in enough information to list your practice on our marketplace. Click 'Resume' to begin receiving offers."
    case underReviewStatusKey:
      return "Our team is currently reviewing your listing. We'll notify you when you your listing is live. Usually this process takes between 24-48 hours."
    case offMarketStageKey:
      return 'Your practice is posted as an off-market listing. Your next step is to review interested buyers, and proceed in the sales process.'
    case forSaleStageKey:
      return forSaleSubText
    case soldStageKey:
      return 'Your practice has been sold! Congrats!'
    default:
      return forSaleSubText
  }
}

export const getPracticeListingStatusChipText = (status) => {
  switch (status) {
    case incompleteStatusKey:
      return 'Incomplete'
    case readyToListStatusKey:
      return 'Ready to List'
    case underReviewStatusKey:
      return 'Under Review'
    case offMarketStageKey:
      return 'Off-Market'
    case soldStageKey:
      return 'Sold'
    case forSaleStageKey:
      return 'For Sale'
    default:
      return 'For Sale'
  }
}

// Storing as variables - used below
export const offMarketSectionKey = 'off_market'
export const picturesSectionKey = 'pictures'
const financialsSectionKey = 'financials'
export const publishSectionKey = 'publish'

export const orderedCreatePathSectionKeys = [
  'overview',
  'team',
  'lease',
  'terms',
  'off_market',
]

export const orderedDueDiligencePathSectionKeys = [
  picturesSectionKey,
  financialsSectionKey,
]

export const sectionsReachedWherePracticeCanBeListed = [
  offMarketSectionKey,
  picturesSectionKey,
  financialsSectionKey,
  publishSectionKey,
]

export const whiteGloveServiceRate = 10
export const discountedCommissionRate = 4.5

export const canadianCountryCode = 'canada'
export const americanCountryCode = 'united_states'

export const publishingFlowKey = 'publishing'
export const dueDiligenceFlowKey = 'due_diligence'
export const editingFlowKey = 'editing'

export const listingTypeChipSvgMap = {
  [soldStageKey]:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/sold-chip.svg',
  [offMarketStageKey]:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/off-market-chip.svg',
  [forSaleStageKey]:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/for-sale-chip.svg',
}

export const pathsForHeuristicFieldNames = {
  rangeLower: ['verticalTableRange', 'min'],
  rangeUpper: ['verticalTableRange', 'max'],
  minMultiple: ['deltas', 'minMultiple'],
  maxMultiple: ['deltas', 'maxMultiple'],
  minEbitda: ['deltas', 'minEbitda'],
  maxEbitda: ['deltas', 'maxEbitda'],
}

export const pathsForGlobalInputsFieldNames = {
  'Default Min Ebitda': ['ebitda', 'min'],
  'Default Max Ebitda': ['ebitda', 'max'],
  'Default Min Multiple': ['multiple', 'min'],
  'Default Max Multiple': ['multiple', 'max'],
  'Min Multiple Bound': ['multipleBound', 'min'],
  'Max Multiple Bound': ['multipleBound', 'max'],
  'Hygiene Weekly Hours': ['hygieneWeeklyHours'],
  'Min Hygiene Rate': ['hygieneRates', 'min'],
  'Max Hygiene Rate': ['hygieneRates', 'max'],
  'Admin Weekly Hours': ['adminWeeklyHours'],
  'Min Admin Rate': ['adminRates', 'min'],
  'Max Admin Rate': ['adminRates', 'max'],
}

export const operatoryPurposes = [
  'Dental',
  'Hygiene',
  'Mixed use',
  'Consult',
  'Recovery room',
]

export const buyerTypes = [
  {
    key: 1,
    label: 'Dentist',
  },
  {
    key: 2,
    label: 'Dental Service Organization',
  },
  {
    key: 3,
    label: 'Dental Partnership Organization',
  },
  {
    key: 4,
    label: 'Private Equity',
  },
]

/**
 * Enum representing different actions a user could take that would
 * result in notifications being sent
 */
export const NotificationType = Object.freeze({
  DATAROOM_ACCESS_REQUEST_SUBMITTED: 'DATAROOM_ACCESS_REQUEST_SUBMITTED',
  DATAROOM_ACCESS_REQUEST_APPROVED: 'DATAROOM_ACCESS_REQUEST_APPROVED',
  PURCHASE_INTENT_SUBMITTED: 'PURCHASE_INTENT_SUBMITTED',
  PURCHASE_INTENT_APPROVED: 'PURCHASE_INTENT_APPROVED',
})

/**
 * If you change this, you may want to change connect-server as well to keep the messages in sync.
 * However:
 *  - it may be the case that we want to make the client-displayed message and the server-sent message different
 *  - we may want to display the message in a different language
 * so the message text is not saved into the database, only the message type,
 * and it is left up to the client to decide what message to display based on the message type.
 */
export const notificationMessages = {
  [NotificationType.DATAROOM_ACCESS_REQUEST_APPROVED]:
    "A dataroom access request you submitted on Dentacloud has been approved! View the practice's dataroom",
  [NotificationType.DATAROOM_ACCESS_REQUEST_SUBMITTED]:
    "A buyer has requested to view your practice's dataroom on Dentacloud! Review and approve their request",
  [NotificationType.PURCHASE_INTENT_APPROVED]:
    'A purchase intent you submitted on Dentacloud has been approved! View the practice here',
  [NotificationType.PURCHASE_INTENT_SUBMITTED]:
    'A buyer has submitted an intent to purchase your practice on Dentacloud! Review and approve their request',
}

export const digitsRegex = /^\d+$/
export const stringRegex = /^[ A-Za-z0-9_@./%#&+-]*$/

export const clusterChipURLs = {
  [soldStageKey]:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/cluster-chip-sold-type.svg',
  [offMarketStageKey]:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/cluster-chip-off-market-type.svg',
  [forSaleStageKey]:
    'https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/cluster-chip-for-sale-type.svg',
}

export const localStorageKeys = {
  onboardingRedirectPath: 'onboardingRedirectPath',
}

export const torontoLatLon = {
  lat: 43.65107,
  lng: -79.347015,
}

export const ConnectServicePlans = {
  free: 'Free',
  slidingScale: 'Sliding Scale',
  lifetimeDeal: 'Lifetime Deal',
  whiteGloveService: 'White Glove Service',
}

const practiceOverviewFieldsCommonToAllTypes = [
  'name',
  'type',
  'address',
  'postalCode',
  'dpms',
  'billings',
  'practiceHasDebt',
]
const practiceTeamFieldsCommonToAllTypes = [
  'partTimeAssociates',
  'fullTimeAssociates',
  'partTimeAdmins',
  'fullTimeAdmins',
  'partTimeAssistants',
  'fullTimeAssistants',
  'schedule',
  'canAddAssociate',
]

export const practiceOverviewFieldsBasedOnType = {
  general: [
    ...practiceOverviewFieldsCommonToAllTypes,
    'hygienePercentage',
    'isInvisalignCertified',
    'hasIntraoralScanner',
  ],
  anesthesiology: [
    ...practiceOverviewFieldsCommonToAllTypes,
    'hygienePercentage',
    'isInvisalignCertified',
    'hasIntraoralScanner',
    'patientsSeenWeekly',
    'onlyProvidesAnesthesia',
    'numberDentistsRefer',
  ],
  endodontics: [
    ...practiceOverviewFieldsCommonToAllTypes,
    'openDaysPerWeek',
    'numberDentistsRefer',
    'numberOfDoctors',
  ],
  hygiene: [
    ...practiceOverviewFieldsCommonToAllTypes,
    'hygienePercentage',
    'isInvisalignCertified',
    'hasIntraoralScanner',
  ],
  oral_max_surgery: [
    ...practiceOverviewFieldsCommonToAllTypes,
    'openDaysPerWeek',
    'numberDentistsRefer',
    'numberOfDoctors',
  ],
  oral_max_radiology: [
    ...practiceOverviewFieldsCommonToAllTypes,
    'hygienePercentage',
    'isInvisalignCertified',
    'hasIntraoralScanner',
  ],
  orthodontics: [
    ...practiceOverviewFieldsCommonToAllTypes,
    'hygienePercentage',
    'isInvisalignCertified',
    'hasIntraoralScanner',
  ],
  pediatrics: [
    ...practiceOverviewFieldsCommonToAllTypes,
    'hygienePercentage',
    'isInvisalignCertified',
    'hasIntraoralScanner',
  ],
  periodontics: [
    ...practiceOverviewFieldsCommonToAllTypes,
    'hygienePercentage',
    'isInvisalignCertified',
    'hasIntraoralScanner',
  ],
  prosthodontics: [
    ...practiceOverviewFieldsCommonToAllTypes,
    'hygienePercentage',
    'isInvisalignCertified',
    'hasIntraoralScanner',
  ],
}

export const practiceTeamFieldsBasedOnType = {
  general: [
    ...practiceTeamFieldsCommonToAllTypes,
    'partTimeHygienists',
    'fullTimeHygienists',
  ],
  anesthesiology: [
    ...practiceTeamFieldsCommonToAllTypes,
    'partTimeHygienists',
    'fullTimeHygienists',
  ],
  endodontics: [...practiceTeamFieldsCommonToAllTypes],
  hygiene: [
    ...practiceTeamFieldsCommonToAllTypes,
    'partTimeHygienists',
    'fullTimeHygienists',
  ],
  oral_max_surgery: [...practiceTeamFieldsCommonToAllTypes],
  oral_max_radiology: [
    ...practiceTeamFieldsCommonToAllTypes,
    'partTimeHygienists',
    'fullTimeHygienists',
  ],
  orthodontics: [
    ...practiceTeamFieldsCommonToAllTypes,
    'partTimeHygienists',
    'fullTimeHygienists',
  ],
  pediatrics: [
    ...practiceTeamFieldsCommonToAllTypes,
    'partTimeHygienists',
    'fullTimeHygienists',
  ],
  periodontics: [
    ...practiceTeamFieldsCommonToAllTypes,
    'partTimeHygienists',
    'fullTimeHygienists',
  ],
  prosthodontics: [
    ...practiceTeamFieldsCommonToAllTypes,
    'partTimeHygienists',
    'fullTimeHygienists',
  ],
}

export const doctorNameBasedOnType = {
  general: 'general dentists',
  anesthesiology: 'anesthesiologists',
  endodontics: 'endodontists',
  hygiene: 'hygienists',
  oral_max_surgery: 'oral surgeons',
  oral_max_radiology: 'radiologists',
  orthodontics: 'orthodontists',
  pediatrics: 'pediatricians',
  periodontics: 'periodontists',
  prosthodontics: 'prosthodontists',
}

export default {
  localStorageKeys,
  clusterChipURLs,
  userRoles,
  dpmss,
  sellGoalKey,
  buyGoalKey,
  provideProductsOrServicesGoalKey,
  provincesAndStatesCodeNameMapArray,
  onboardingPathPrefix,
  forSaleStageKey,
  soldStageKey,
  allSaleStages,
  practiceTypes,
  practiceBuildingTypes,
  booleanFields,
  optionalBooleanFields,
  activeListingSaleStages,
  orderedCreatePathSectionKeys,
  offMarketSectionKey,
  publishSectionKey,
  sectionsReachedWherePracticeCanBeListed,
  saleStageLabelsForBuyers,
  saleStageLabelsForSellers,
  getLabelFromKey,
  whiteGloveServiceRate,
  discountedCommissionRate,
  saleTimeFrames,
  roomForOperatoryExpansionOptions,
  practiceOnlySaleTypeKey,
  practiceAndBuildingSaleTypeKey,
  getBuildingForSaleValueFromSaleType,
  canadianCountryCode,
  americanCountryCode,
  practiceUserAccessTiers,
  listingTypeChipSvgMap,
  pathsForHeuristicFieldNames,
  pathsForGlobalInputsFieldNames,
  operatoryPurposes,
  buyerTypes,
  notificationMessages,
  digitsRegex,
  stringRegex,
  ConnectServicePlans,
}
