import styled, { css } from 'styled-components'
import { RowFlexStyle, TitleText } from '../../styles/shared-styled-components'
import listImage from './listPractices.svg'
import approveImage from './approveBuyers.svg'
import receiveImage from './receiveOffers.svg'

export const OfferPageTitle = styled(TitleText)`
  margin: 23px 38px;
`

export const StepsWrapper = styled.div`
  padding: 32px 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 30px;
  width: 80%;
  margin: auto;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

export const OfferStepBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 25%;

  @media (max-width: 768px) {
    max-width: 100%;
    justify-content: center;
  }
`

export const OfferStepImage = styled.div`
  width: 72px;
  height: 92px;
  background-size: 100%;
  background-poition: top center;
  background-repeat: no-repeat;

  ${(props) =>
    props.variant && props.variant === 'list'
      ? css`
          background-image: url(${listImage});
        `
      : props.variant === 'approve'
        ? css`
            background-image: url(${approveImage});
          `
        : css`
            background-image: url(${receiveImage});
          `}
`
export const FlexColumnBox = styled.div`
  ${RowFlexStyle}
  height: 92px;
  flex: 1;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    display: none;
  }
`

export const HorizontalDivider = styled.div`
  height: 1px;
  flex: 1;
  background: ${({ theme }) => theme.color.primaryWithTransparency};
`

export const OfferStepTitle = styled(TitleText)`
  text-align: center;
  margin-top: 14px;
  font-size: ${({ theme }) => theme.typography.sentenceLargeFontSize};
`

export const OfferStepDesc = styled.p`
  color: ${({ theme }) => theme.color.primaryWithTransparency};
  text-align: center;
  font-family: ${({ theme }) => theme.typography.typeFont};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
