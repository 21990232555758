import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import styled, { css } from 'styled-components'
import {
  RedButton,
  GreenButton,
  LargeParagraphText,
  PrimaryButton,
  PrimaryButtonStyle,
  RowFlexStyle,
  SecondaryButton,
  GreenButtoNoHover,
} from '../../styles/shared-styled-components'
import {
  BasicLabel,
  StyledAvatar,
  StyledPhotoLabel,
  StyledUploadContainer,
} from '../../pages/BuyerProfile/styled'
import { BodyText } from '../Micro/MyAcquisition/styled'

export const StyledLabel = styled(BasicLabel)`
  font-size: 16px;
  color: #000;
  text-align: left;
`

export const StyledSpan = styled(BodyText)`
  font-size: 16px;
  color: #808080;
  text-align: left;
`

export const OfferListItemWrapper = styled(Box)`
  width: calc(100vw - 20px);
  min-height: 255px;
  border-radius: 5px;
  border: 1px dotted #016299;
  overflow: hidden;
  display: block;
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.lightBlue};

  &:hover {
    cursor: pointer;
  }
`

export const OfferContainer = styled(Box)`
  ${RowFlexStyle}
  padding: 28px 16px;
  gap: 31px;

  ${(props) =>
    props.concealed &&
    `
        filter: blur(6px);
    `}
`

export const BuyerImage = styled(Box)`
  width: 280px;
  height: 216px;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.color.primaryWithTransparency};
  background-size: 100%;
  background-position: top center;
  background-repeat: not-repeat;

  ${(props) =>
    props.src &&
    css`
      background-image: url(${props.src});
    `}
`

export const BuyerNameText = styled(Typography)`
  color: ${({ theme }) => theme.color.black};
  font-family: ${({ theme }) => theme.typography.typeFont};
  font-size: ${({ theme }) => theme.typography.pageTitleMediumFontSize};
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-decoration-line: underline;

  ${(props) =>
    props.concealed &&
    css`
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    `}
`

export const BuyerTag = styled(Box)`
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 5px;
  border: ${({ theme }) => `1px solid ${theme.color.primary}`};
  padding: 4px 14px;
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.typography.sentenceLargeFontSize};
  font-family: ${({ theme }) => theme.typography.typeFont};

  ${(props) =>
    props.concealed &&
    css`
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    `}
`

export const BuyerTagConcealed = styled(BuyerTag)`
  position: absolute;
  margin-top: 27px;
  margin-left: 23px;
  background: ${({ theme }) => theme.color.white};
  z-index: 2;
`

export const ApproveButton = styled(SecondaryButton)`
  height: 48px;
  min-width: 128px;
  text-transform: capitalize;
`
export const ApprovedButton = styled(GreenButtoNoHover)`
  height: 48px;
  min-width: 128px;
  text-transform: capitalize;
`

export const DeclinedButton = styled(RedButton)`
  height: 48px;
  min-width: 128px;
  text-transform: capitalize;
`

export const CancelButton = styled(SecondaryButton)`
  height: 48px;
  min-width: 128px;
  border: 0px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.color.white};
  background-color: ${(props) => props.theme.color.grey};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  &:hover {
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.solid};
  }
`

export const ViewDetailsButton = styled(PrimaryButton)`
  margin-left: calc(50% - 100px);
  margin-top: 100px;
  position: absolute;
  z-index: 2;
`

export const OfferLabel = styled(StyledLabel)`
  ${(props) =>
    props.concealed &&
    css`
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    `}
`

export const OfferSpan = styled(StyledSpan)`
  ${(props) =>
    props.concealed &&
    css`
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    `}
`

export const BuyerPreviewWrapper = styled(Box)`
  width: 100vw;
  height: calc(100vh - 60px);
  position: fixed;
  z-index: 5;
  top: 60px;
  left: 0px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.white};
`

export const BuyerPreviewTitleText = styled(LargeParagraphText)`
  font-size: 32px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.primary};
  padding: 0 15px;
  margin-top: 57px;
  margin-left: 123px;
`

export const BuyerDataContainer = styled(Box)`
  ${RowFlexStyle}

  overflow: auto;
  margin-left: 123px;
  margin-top: 33px;
  gap: 96px;
`

export const BuyerAvatarContainer = styled(StyledUploadContainer)`
  border: 0px;
  background: none;
  width: 250px;
  height: 250px;
  margin-bottom: 64px;
`

export const BuyerAvatar = styled(StyledAvatar)`
  width: 247px;
  height: 247px;
  border-width: 2px;
`
export const BuyerAvatarLabel = styled(StyledPhotoLabel)`
  width: 53px;
  height: 53px;
  border-width: 2px;
  top: 150px;
`

export const BuyerSectionTitle = styled(Typography)`
  color: ${({ theme }) => theme.color.black};
  font-family: ${({ theme }) => theme.typography.typeFont};
  font-size: ${({ theme }) => theme.typography.pageTitleMediumFontSize};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 45px;
`

export const BuyerTextControl = styled(Box)`
  ${RowFlexStyle}

  & div {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & span {
    color: ${({ theme }) => theme.color.black};
    font-family: ${({ theme }) => theme.typography.typeFont};
    font-size: ${({ theme }) => theme.typography.sentenceLargeFontSize};
    font-style: normal;
    line-height: normal;
  }
`

export const BackButton = styled(SecondaryButton)``

export const DownloadButton = styled.a`
  ${PrimaryButtonStyle}
  ${RowFlexStyle}

    text-decoration: none;
  padding: 2px 10px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-left: 20px;
  height: 27px;
`

export const PurchaseIntentPriceChip = styled.div`
  display: flex;
  border-radius: 4px;
  border: 1px solid #016299;
  background: #016299;
  color: #fff;
  font-family: Noto Sans KR;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.5px;
  letter-spacing: 0.1px;
  padding: 2px 5px;
  width: fit-content;
`

export const StyledFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 5px;
`

export const StyledColumnContainer = styled.div`
  display: flex;
  margin-top: 5px;
  margin-right: 5px;
  flex-direction: column;

  @media (max-width: 564px) {
    flex: 1 0 48%;
  }
  flex: 1 0 25%;
  gap: 2px;
`
