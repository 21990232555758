import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ColumnButtonContainerWithSmallerGap,
  DiscountIcon,
  Modal,
  ModalActions,
  ModalContainerWithGap,
  ModalContent,
  ModalContentText,
  ModalHeaderContainer,
  PrimaryModalActionButton,
  SecondaryModalActionButton,
  TitleText,
} from '../../../../styles/shared-styled-components'
import { getCapitalizedWord } from '../../../../utils/naming'
import { getFormattedMoneyStringWithNoCents } from '../../../../utils/numbers'
import { navigateToDueDiligenceSectionToEdit } from '../../../../utils/routing'
import {
  CommissionPercentageText,
  IncompleteSectionNameText,
  IncompleteSectionsContainer,
  SavingsText,
} from './styled'
import {
  whiteGloveServiceRate,
  discountedCommissionRate,
} from '../../../../utils/constants'

const SavingsSpecificCommissionModal = ({
  practiceId,
  estimatedSavings,
  incompleteSections,
  isOpen,
  onClose,
  ismobilescreen,
  currentFlow,
}) => {
  const navigate = useNavigate()

  const handleOpenIncompleteSection = useCallback(
    (sectionName) => {
      navigateToDueDiligenceSectionToEdit(sectionName, practiceId, navigate)
    },
    [practiceId, navigate],
  )

  const formattedSavingsString =
    getFormattedMoneyStringWithNoCents(estimatedSavings)

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContainerWithGap>
        <ModalHeaderContainer>
          <DiscountIcon />
          <TitleText>Save {formattedSavingsString}</TitleText>
        </ModalHeaderContainer>
        <ModalContent>
          <ModalContentText ismobilescreen={ismobilescreen}>
            Our White Glove Service is{' '}
            <CommissionPercentageText>
              {whiteGloveServiceRate}%
            </CommissionPercentageText>{' '}
            of the sale price -- only due when the sale closes.
            <br></br>
            <br></br>
            If you upload the remaining information, that gets reduced to{' '}
            <CommissionPercentageText>
              {discountedCommissionRate}%,
            </CommissionPercentageText>{' '}
            saving you an estimated{' '}
            <SavingsText>{formattedSavingsString}</SavingsText> when you sell
            your practice.
            <br></br>
            <br></br>
            Complete the following section
            {incompleteSections?.length > 0 && 's'} to lock in your discount:
            <IncompleteSectionsContainer>
              {incompleteSections.map((sectionName) => (
                <IncompleteSectionNameText
                  key={sectionName}
                  onClick={() => handleOpenIncompleteSection(sectionName)}
                >
                  {getCapitalizedWord(sectionName)}
                </IncompleteSectionNameText>
              ))}
            </IncompleteSectionsContainer>
          </ModalContentText>
        </ModalContent>
        <ModalActions>
          <ColumnButtonContainerWithSmallerGap>
            <PrimaryModalActionButton
              onClick={() =>
                navigateToDueDiligenceSectionToEdit(
                  !!incompleteSections[0]
                    ? incompleteSections[0]
                    : 'financials',
                  practiceId,
                  navigate,
                  currentFlow,
                )
              }
            >
              Complete now
            </PrimaryModalActionButton>
            <SecondaryModalActionButton onClick={onClose}>
              Complete later
            </SecondaryModalActionButton>
          </ColumnButtonContainerWithSmallerGap>
        </ModalActions>
      </ModalContainerWithGap>
    </Modal>
  )
}

export default SavingsSpecificCommissionModal
