import React from 'react'
import { IconContainerRight } from '../../../styles/shared-styled-components'
import { DownArrow, UpArrow } from './styled'

const ArrowIcon = ({ open, color = 'inherit' }) => {
  return (
    <IconContainerRight>
      {open ? <UpArrow color={color} /> : <DownArrow color={color} />}
    </IconContainerRight>
  )
}

export default ArrowIcon
