import styled, { css } from 'styled-components/macro'
import { PrimaryButton } from '../../../styles/shared-styled-components'

const ButtonStyle = css`
  width: 200px;
`

export const GreenPublishPracticeListingButton = styled(PrimaryButton)`
  ${ButtonStyle}
  background-color: ${({ theme }) => theme.color.brightGreen};
  &:hover {
    background-color: ${({ theme }) => theme.color.brightGreenHover};
  }
`
