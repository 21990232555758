import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  BodyText,
  StyledPopupBox,
  StyledPopupContainer,
} from '../MyAcquisition/styled'
import { BuyerButtons, BuyerContinueButton, HeaderTitle } from './styled'

const PurchaseIntentDialog = (props) => {
  const navigate = useNavigate()

  const { buyerId } = useParams()

  const handleContinue = () => {
    const myAcquisitionLink = `/buyers/${buyerId}/my-acquisition`
    navigate(myAcquisitionLink)
  }

  return (
    <StyledPopupBox>
      <StyledPopupContainer
        style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
      >
        <HeaderTitle>
          <CheckCircleIcon sx={{ color: '#25B34D', fontSize: '60px' }} />
        </HeaderTitle>
        <BodyText>
          Your purchase intent has been submitted. The seller will review your
          request shortly. You can track the status of your requests in{' '}
          <span style={{ fontWeight: 'bold' }}>'My Acquisitions'</span>.
        </BodyText>
        <BuyerButtons>
          <BuyerContinueButton onClick={handleContinue}>OK</BuyerContinueButton>
        </BuyerButtons>
      </StyledPopupContainer>
    </StyledPopupBox>
  )
}

export default PurchaseIntentDialog
