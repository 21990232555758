import React from 'react'
import { useNavigate } from 'react-router-dom'
import Paths from '../../../Paths'
import { StyledBrowseListingsButton } from './styled'

const BrowseListingsButton = ({ text = 'Browse listings' }) => {
  const navigate = useNavigate()

  return (
    <StyledBrowseListingsButton onClick={() => navigate(Paths.practices)}>
      {text}
    </StyledBrowseListingsButton>
  )
}

export default BrowseListingsButton
