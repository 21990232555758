import React, { useCallback } from 'react'
import {
  Modal,
  ModalActions,
  ModalContainer,
  ModalContent,
  ModalContentText,
  ModalHeaderContainer,
  PrimaryModalActionButton,
  RowButtonContainer,
  SecondaryModalActionButton,
} from '../../../styles/shared-styled-components'
import { DUE_DILIGENCE_KEY } from '../../../utils/constants'
import { PracticeAvatar } from '../../MyPracticeCard/styled'
import bellIcon from './reminder.svg'
import _ from 'lodash'

const SkipSectionWarningModal = ({
  subStepName,
  formProgress,
  practiceId,
  isOpen,
  onClose,
  onSkip,
  skipType = 'upload',
}) => {
  const handleSkip = useCallback(() => {
    if (formProgress && practiceId) {
      let saved = localStorage.getItem(DUE_DILIGENCE_KEY) || null
      saved = saved ? JSON.parse(saved) : {}

      saved[practiceId]['skipped'] = _.uniq(
        _.concat(saved[practiceId].skipped, subStepName),
      ).filter((v) => !!v)
      localStorage.setItem(DUE_DILIGENCE_KEY, JSON.stringify(saved))
    }
    onSkip()
  }, [formProgress, practiceId, subStepName, onSkip])

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalContainer>
        <ModalHeaderContainer>
          <PracticeAvatar src={bellIcon} style={{ width: '81px' }} />
        </ModalHeaderContainer>
        <ModalContent style={{ padding: 0 }}>
          <ModalContentText>
            {`In order to receive a greater number of valid offers, it is best to ${
              skipType === 'upload'
                ? 'upload a complete list'
                : 'complete the form'
            }.`}
          </ModalContentText>
        </ModalContent>
        <ModalActions>
          <RowButtonContainer>
            <PrimaryModalActionButton onClick={onClose}>
              {`${skipType === 'upload' ? 'Upload' : 'Complete'} Now`}
            </PrimaryModalActionButton>
            <SecondaryModalActionButton onClick={handleSkip}>
              {`${skipType === 'upload' ? 'Upload' : 'Complete'} Later`}
            </SecondaryModalActionButton>
          </RowButtonContainer>
        </ModalActions>
      </ModalContainer>
    </Modal>
  )
}

export default SkipSectionWarningModal
