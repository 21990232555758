import MuiLockIcon from '@mui/icons-material/Lock'
import styled, { css } from 'styled-components/macro'
import {
  DefaultIconStyle,
  PrimaryButton,
} from '../../../styles/shared-styled-components'

export const LockIconButtonStyle = css`
  height: 30px;
  width: 60px;
  min-width: 0px;
  border-radius: 5px;
  box-shadow: none;

  ${(props) =>
    props.size === 'sm' &&
    css`
      height: 25px;
      width: 50px;
    `}
`

export const LockIconButton = styled(PrimaryButton)`
  ${LockIconButtonStyle}
`

export const StyledLockIcon = styled(MuiLockIcon)`
  display: flex;
  justify-content: center;
  align-items: center;
  ${DefaultIconStyle}
`
