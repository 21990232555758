import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { useAcquisition } from '../../api/hooks/acquisitions'
import LoadingIndicator from '../../components/LoadingIndicator'
import Header from '../../components/NavBar/Header'
import {
  BackgroundContainer,
  FullPageContentContainer,
  LargeParagraphText,
  PageContainer,
} from '../../styles/shared-styled-components'
import DataAccessRequests from './DataAccessRequests'
import {
  acquisitionRequestsStatuses,
  acquisitionTypes,
  aquisitionFilters,
} from '../../utils/constants'
import Snackbar from '../../components/Snackbar'

const MyAcquisition = ({ user }) => {
  const { acquisitions, purchaseIntents, updateAcquisition, loading, error } =
    useAcquisition()

  const [value, setValue] = useState(0)
  const [filter, setFilter] = useState('all')
  const [snackBarMessageError, setSnackBarMessageError] = useState('')

  if (error && !snackBarMessageError) {
    setSnackBarMessageError('Failed to load your deals. Try reloading the page')
  }
  const handleChange = (event, newValue) => {
    setValue(newValue)
    setFilter('all')
  }

  const handleFilter = (e) => {
    setFilter(e.target.value)
  }

  const accessRequests = useMemo(() => {
    return _.filter(acquisitions, (data) => {
      return !data.is_archived /*&& !_.some(purchaseIntents, ['practice_id', data.practice_id])*/
    })
  }, [acquisitions])

  const favorites = useMemo(() => {
    const combined = _.concat(acquisitions, purchaseIntents)
    return _.filter(combined, (data) => data.is_favorite)
  }, [acquisitions, purchaseIntents])

  const archived = useMemo(() => {
    const combined = _.concat(acquisitions, purchaseIntents)
    return _.filter(combined, (data) => data.is_archived)
  }, [acquisitions, purchaseIntents])

  const selectedRequests = useMemo(() => {
    const groupedAcquisitions = [
      accessRequests,
      purchaseIntents,
      favorites,
      archived,
    ]
    return groupedAcquisitions[value]
  }, [value, accessRequests, purchaseIntents, favorites, archived])

  const filteredRequests = useMemo(() => {
    const source = selectedRequests
    if (filter === 'all') return source
    return source.filter((data) => {
      return filter === acquisitionRequestsStatuses.PENDING
        ? !data.status || data.status === acquisitionRequestsStatuses.PENDING
        : data.status === filter
    })
  }, [selectedRequests, filter])

  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get('page')
  switch (page) {
    case 'purchase-intents':
      if (value !== 1) {
        setValue(1)
      }
      break
    case 'dataroom-access-requests':
      if (value !== 0) {
        setValue(0)
      }
      break
    default:
      break
  }

  const dataTypeOptions = useMemo(() => {
    const selectedTypeKey =
      value === 1
        ? acquisitionTypes.PURCHASE_INTENT
        : acquisitionTypes.ACCESS_REQUEST

    return aquisitionFilters[selectedTypeKey]
  }, [value])

  return acquisitions ? (
    <>
      <Header user={user} />
      <BackgroundContainer>
        <PageContainer>
          <FullPageContentContainer>
            <div
              style={{
                margin: '20px auto',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                width: '100%',
              }}
            >
              <LargeParagraphText
                style={{
                  fontSize: '24px',
                  fontWeight: 'bold',
                  color: '#016299',
                  padding: '0 15px',
                }}
              >
                My Acquisitions
              </LargeParagraphText>

              <Box
                sx={{ borderBottom: 1, borderTop: 1, borderColor: 'divider' }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant='scrollable'
                  scrollButtons='auto'
                  allowScrollButtonsMobile
                >
                  <Tab
                    sx={{ textTransform: 'none' }}
                    label='Dataroom Access Requests'
                  />
                  <Tab
                    sx={{ textTransform: 'none' }}
                    label='Purchase Intents'
                  />
                  <Tab sx={{ textTransform: 'none' }} label='Favorites' />
                </Tabs>
              </Box>
              <Box sx={{ margin: '0 10px' }}>
                {loading ? (
                  <Box sx={{ mt: 5 }}>
                    <LoadingIndicator fullScreen />
                  </Box>
                ) : (
                  <>
                    <Box>
                      <FormControl sx={{ m: 1, minWidth: 300 }} size='small'>
                        <Select
                          value={filter}
                          onChange={handleFilter}
                          displayEmpty
                        >
                          {dataTypeOptions?.map((option, i) => (
                            <MenuItem key={i} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <DataAccessRequests
                      acquisitions={filteredRequests}
                      onUpdate={updateAcquisition}
                    />
                  </>
                )}
              </Box>
            </div>
          </FullPageContentContainer>
        </PageContainer>
      </BackgroundContainer>
      <Snackbar
        variant='error'
        isOpen={!!snackBarMessageError}
        onClose={() => setSnackBarMessageError('')}
        message={snackBarMessageError}
      ></Snackbar>
    </>
  ) : (
    <LoadingIndicator fullScreen />
  )
}

export default MyAcquisition
