import { withAuthenticationRequired } from '@auth0/auth0-react'
import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Paths from '../../../Paths'
import { useMyUser } from '../../../api/hooks/users'
import {
  localStorageKeys,
  onboardingPathPrefix,
} from '../../../utils/constants'
import {
  getHomePathToRedirectToFromUserGoals,
  getOnboardingRouteToRedirectTo,
} from '../../../utils/routing'
import LoadingIndicator from '../../LoadingIndicator'

/**
 * AuthRouterV2 is an improved version of AuthRouter that doesn't rerender
 * the entire component tree everytime the window width changes.
 * It's a pain to go through every component that the current AuthRouter
 * uses and remove the use of `width` and `ismobilescreen`, so instead
 * we'll move to V2 over time
 */
const AuthRouterV2 = ({
  Component,
  authGated = false,
  ...propsForComponent
}) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const utmAffiliate = searchParams.get('utm_affiliate')
  if (utmAffiliate !== null) {
    localStorage.setItem('utm_affiliate', utmAffiliate)
  }

  const { user, loading: userLoading } = useMyUser()

  const path = window.location.pathname

  const AuthGatedComponent = withAuthenticationRequired(Component, {
    onRedirecting: () => <LoadingIndicator fullScreen />,
  })

  if (user) {
    const pathIsOnboardingPage = path?.includes(onboardingPathPrefix)

    const goals = user.goals

    // If they've already onboarded and are accessing an onboarding screen, navigate them to the appropriate homescreen
    // Or -- if they've just logged in, navigate them to the appropriate homescreen based on their goals
    if (
      (user.hasOnboarded === true && pathIsOnboardingPage) ||
      searchParams.get('li') === 'true'
    ) {
      const pathToRedirectTo = getHomePathToRedirectToFromUserGoals(goals)
      navigate(pathToRedirectTo)
    } else if (!user.hasOnboarded && !pathIsOnboardingPage) {
      // If they've not already onboarded and are accessing a non-onboarding screen,
      // redirect them back to the appropriate step in the onboarding flow
      const onboardingRouteToNavigateTo = getOnboardingRouteToRedirectTo(goals)
      // If the user started the onboarding flow from a page other than the defulat,
      // we want to redirect them back to the page they were on before starting onboarding.
      // Since this is a multi-step process with multiple page navigations,
      // we store the current path in the session storage so we can redirect back to it at the end
      if (
        path !== Paths.default &&
        !localStorage.getItem(localStorageKeys.onboardingRedirectPath)
      ) {
        localStorage.setItem(localStorageKeys.onboardingRedirectPath, path)
      }
      navigate(onboardingRouteToNavigateTo)
    }
  }

  if (userLoading) {
    // While the user object is loading, show a loading indicator
    return <LoadingIndicator fullScreen />
  }

  if (authGated) {
    // If this component is auth gated, redirect to the login page if they're unauthenticated
    // Then render the component
    return <AuthGatedComponent user={user} {...propsForComponent} />
  } else {
    // if not auth gated, just render the component
    return <Component user={user} {...propsForComponent} />
  }
}

export default AuthRouterV2
