import { useMutationWithErrorLogging } from '../../utils/hooks'
import { useQueryWithErrorLogging } from '../../utils/hooks'
import _ from 'lodash'
import get from 'lodash/get'
import {
  ACCEPT_ACQUISITION_OFFER,
  CANCEL_ACQUISITION_REQUEST,
  UPDATE_ACQUISITION,
} from '../mutations/acquisitions'
import {
  GET_ACQUISITIONS,
  GET_ACQUISITION_BY_ID,
  GET_OFFERS_BY_PRACTICE_ID,
} from '../queries/acquisitions'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  acquisitionRequestsStatuses,
  acquisitionTypes,
} from '../../utils/constants'
import { useMyUser } from './users'

export const useAcquisition = () => {
  const useMyUserResults = useMyUser()
  const [updateAcquisitionMutation] =
    useMutationWithErrorLogging(UPDATE_ACQUISITION)
  const [cancelAcquisitionRequest] = useMutationWithErrorLogging(
    CANCEL_ACQUISITION_REQUEST,
  )

  const { data, error, loading, refetch } = useQueryWithErrorLogging(
    GET_ACQUISITIONS,
    {
      fetchPolicy: 'network-only',
      skip: !useMyUserResults.auth0Context.isAuthenticated,
    },
  )

  const results = useMemo(() => get(data, 'getAcquisitions', []), [data])
  const orderedData = useMemo(
    () => _.orderBy(results, 'createdAt', ['desc']),
    [results],
  )

  const [acquisitionRes, setAcquisitionRes] = useState([])

  useEffect(() => {
    setAcquisitionRes(orderedData)
  }, [orderedData])

  const acquisitions = useMemo(() => {
    return _.filter(
      acquisitionRes,
      (data) => data.type === acquisitionTypes.ACCESS_REQUEST,
    )
  }, [acquisitionRes])

  const purchaseIntents = useMemo(() => {
    return _.filter(
      acquisitionRes,
      (data) => data.type === acquisitionTypes.PURCHASE_INTENT,
    )
  }, [acquisitionRes])

  const updateAcquisition = useCallback(
    async (id, data = {}) => {
      try {
        // Update record locally
        const tempRecords = acquisitionRes.map((record) =>
          record.id === id ? { ...record, ...data } : record,
        )
        setAcquisitionRes(tempRecords)
        // Update record on db
        await updateAcquisitionMutation({
          variables: {
            id,
            ...data,
          },
          onCompleted: () => {
            setAcquisitionRes(tempRecords)
          },
          update: (cache, { data: { updateAcquisition } }) => {
            cache.writeQuery({
              query: GET_ACQUISITIONS,
              data: {
                getAcquisitions: acquisitionRes.map((record) =>
                  record.id === updateAcquisition.id
                    ? { ...record, ...updateAcquisition }
                    : record,
                ),
              },
            })
          },
        })

        // await refetch();
      } catch (error) {
        console.error('Error updating acquisition:', error.message)
      }
    },
    [acquisitionRes, updateAcquisitionMutation],
  )

  const cancelRequest = useCallback(
    async (id) => {
      try {
        const tempRecords = acquisitionRes.filter((data) => data.id !== id)
        await cancelAcquisitionRequest({
          variables: { id },
          onCompleted: () => {
            setAcquisitionRes(tempRecords)
          },
          update: (cache) => {
            cache.evict({
              fieldName: 'getAcquisitions',
            })
          },
        })
      } catch (error) {
        console.error('Error canceling request:', error.message)
      }
    },
    [acquisitionRes, cancelAcquisitionRequest],
  )

  return {
    error,
    loading,
    refetch,
    acquisitions,
    purchaseIntents,
    updateAcquisition,
    cancelRequest,
  }
}

export const useAcquisitionOffers = (id) => {
  const { data, error, loading } = useQueryWithErrorLogging(
    GET_OFFERS_BY_PRACTICE_ID,
    {
      variables: {
        id: id,
      },
      fetchPolicy: 'cache-first',
    },
  )
  const [offerRes, setOfferRes] = useState([])
  const [rejectBuyerOfferMutation] = useMutationWithErrorLogging(
    CANCEL_ACQUISITION_REQUEST,
  )
  const [acceptBuyerOfferMutation] = useMutationWithErrorLogging(
    ACCEPT_ACQUISITION_OFFER,
  )

  const { offers, practice } = useMemo(
    () => get(data, 'getMyOffersByPracticeId', {}),
    [data],
  )
  const orderedData = useMemo(
    () => _.orderBy(offers, 'createdAt', ['desc']),
    [offers],
  )

  useEffect(() => {
    setOfferRes(orderedData)
  }, [orderedData])

  const dataroomAccessRequests = useMemo(() => {
    return _.filter(
      offerRes,
      (data) => data.type === acquisitionTypes.ACCESS_REQUEST,
    )
  }, [offerRes])

  const purchaseIntents = useMemo(() => {
    return _.filter(
      offerRes,
      (data) => data.type === acquisitionTypes.PURCHASE_INTENT,
    )
  }, [offerRes])

  const acceptOffer = useCallback(
    async (id) => {
      try {
        await acceptBuyerOfferMutation({
          variables: {
            id,
          },
          onCompleted: (data) => {
            const newOffers = get(data, 'acceptAcquisitionOffer', null)?.offers
            setOfferRes(newOffers)
          },
          update(cache, { data: { acceptAcquisitionOffer } }) {
            // Since this is returned untyped (see the `JSONObject` return type in the mutation, and the lack of subfield selection),
            // we can't do the surgical updating of the cache like we can with typed returns.
            // So we'll just evict the entire `getMyOffersByPracticeId` field from the cache to force a refetch.
            cache.evict({
              fieldName: 'getMyOffersByPracticeId',
              args: { id: acceptAcquisitionOffer.practice.id },
            })
          },
        })
      } catch (error) {
        console.error('Error updating offer:', error.message)
      }
    },
    [acceptBuyerOfferMutation],
  )

  const rejectOffer = useCallback(
    async (id) => {
      try {
        const tempRecords = offerRes.filter((data) => data.id !== id)
        await rejectBuyerOfferMutation({
          variables: { id },
          onCompleted: () => {
            setOfferRes(tempRecords)
          },
          update(cache) {
            // This one is even worse, because we don't have access to the practice id in the return.
            // So we have to evict the entire field
            cache.evict({ fieldName: 'getMyOffersByPracticeId' })
          },
        })
      } catch (error) {
        console.error('Error rejecting request:', error.message)
      }
    },
    [offerRes, rejectBuyerOfferMutation],
  )

  return {
    error,
    loading,
    offers,
    practice,
    dataroomAccessRequests,
    purchaseIntents,
    rejectOffer,
    acceptOffer,
  }
}

export const useAcquisitionById = (id) => {
  const { data, error, loading, refetch } = useQueryWithErrorLogging(
    GET_ACQUISITION_BY_ID,
    {
      variables: {
        id: id,
      },
      fetchPolicy: 'cache-first',
      skip: !id,
    },
  )

  return {
    error,
    loading,
    refetch,
    acquisitions: get(data, 'getAcquisitionById', null),
  }
}
