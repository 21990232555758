import styled, { css } from 'styled-components/macro'
import {
  AutoHorizontalMargins,
  BaseDiv,
  ColumnFlexStyle,
  InterSubsectionalGap,
  IntraSubsectionalGap,
  IntrasectionalGap,
  LargeParagraphText,
  RowFlexStyle,
  ScrollStyle,
  TitleText,
} from '../../../styles/shared-styled-components'

export const DynamicColumnHeightStyle = css`
  height: ${(props) => props.remainingHeight - 110}px;
`

export const CreatePracticeFormContainer = styled(BaseDiv)`
  ${ColumnFlexStyle}
  ${InterSubsectionalGap}
`

export const CreatePracticeFormTitleContainer = styled.div`
  ${ColumnFlexStyle}
  align-items: center;
  ${IntraSubsectionalGap}
`

export const CreatePracticeFormTitleText = styled(TitleText)`
  text-align: ${(props) => !props.ismobilescreen && 'start'};
  line-height: ${({ theme }) => theme.typography.titleLineHeight};
  display: flex;
  ${IntraSubsectionalGap}
`

export const CreatePracticeFormSubtitleText = styled(LargeParagraphText)`
  text-align: center;
  line-height: ${({ theme }) => theme.typography.titleLineHeight};
  color: ${({ theme, textColor }) =>
    textColor ? theme.color[textColor] : theme.color.black};
`

export const CreatePracticeFormInputColumnContainer = styled.div`
  ${RowFlexStyle}
  ${ScrollStyle}
  overflow-x: hidden;
  ${IntrasectionalGap}
  ${AutoHorizontalMargins}
  ${DynamicColumnHeightStyle}
`

export const CreatePracticeFormInputColumnWidthStyle = css`
  width: ${(props) => (!props.splitScreen ? '90vw' : '43vw')};
  max-width: 650px;
`

export const CreatePracticeFormInputColumn = styled.div`
  flex: 1;
  ${AutoHorizontalMargins}
  ${CreatePracticeFormInputColumnWidthStyle}
`

export const CreatePracticeFormInputContainer = styled.div`
  ${ColumnFlexStyle}
  ${IntrasectionalGap}
  // Take up remaining height, but leave some room for the navigation box
  // This margin: auto; was needed, as adding align/justify = center caused the div to not be totally scrollable vertically
  // https://stackoverflow.com/a/33455342/9571288
  margin: auto;
  align-items: center;
  // So that the input label doesnt get blocked when focused
  padding-top: 5px;
`
