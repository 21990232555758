import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  BuyerBackButton,
  BuyerButtons,
  BuyerContinueButton,
  ContentText,
  HeaderTitle,
  NdaPopupContainer,
  PopupBox,
} from '../BuyerProfile/styled'
import { Box } from './styled'
import { style } from 'd3'

const NDAModal = ({ toggle, buyerId, practiceId }) => {
  const navigate = useNavigate()
  const [accepted, setAccepted] = useState(false)

  const handleContinue = useCallback(() => {
    if (accepted) {
      const requestForAccessLink = `/buyers/${buyerId}/request-for-access?practice_id=${practiceId}`
      navigate(requestForAccessLink)
    } else {
      alert('Please accept the terms and conditions to continue')
    }
  }, [accepted, buyerId, practiceId, navigate])

  const toggleAcceptance = useCallback(() => {
    setAccepted(!accepted)
  }, [accepted])

  return (
    <PopupBox>
      <NdaPopupContainer>
        <HeaderTitle>Sign NDA to request access</HeaderTitle>
        <Box
          style={{
            maxHeight: '100%',
            overflowY: 'auto',
            margin: '10px 20px',
            flex: 1,
          }}
        >
          <ContentText style={{ fontFamily: 'Noto Sans KR' }}>
            This Mutual Non-Disclosure Agreement (“Agreement”) is entered by and
            between Dentacloud Inc. its related or affiliated entities and/or
            associated professional entities and and its related or affiliated
            entities and/or associated professional entities, hereinafter
            referred to individually as “Party” and collectively as “Parties”
            for the purpose of the permitted disclosure of Proprietary
            Information related to the exploration of a business opportunity of
            mutual interest (the “Purpose”). 1. "Proprietary Information" shall
            mean information disclosed by either Party or its affiliates (the
            “DISCLOSURE”) to the other party (the “RECIPIENT”), regardless of
            the form in which it is communicated or maintained (whether oral,
            written or visual, and whether evidenced by document or by
            electronic storage media), all confidential and/or proprietary
            information, including but not limited to: (I) financial
            information, including financial statements and related interim
            financial statements, trade secrets, pricing, margins, invoicing,
            cost and expense data, market and customer information, supplier and
            vendor information, technical information, contracts, agreements,
            and any and all other information related to the assets,
            liabilities, operations and/or business of DISCLOSURE that is not
            publicly available, whether patented or not, developed and/or owned
            by DISCLOSURE or to which DISCLOSURE has obtained rights by license
            or otherwise; (II) any and all such other information as may be of
            value to DISCLOSURE, or that in the event of disclosure thereof, may
            negatively affect the operations and/or business of DISCLOSURE; and
            (III) any and all notes, analyses, summaries, reports, drawings,
            compilations, studies, memoranda, correspondence, and computer
            software that contains, discusses or otherwise reflects any of the
            information set forth above.
          </ContentText>
        </Box>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label>
            <input
              type='checkbox'
              checked={accepted}
              onChange={toggleAcceptance}
            />
          </label>
          <span style={{ marginLeft: '10px', fontFamily: 'Noto Sans KR' }}>
            I accept the terms and conditions
          </span>
        </div>
        <BuyerButtons>
          <BuyerBackButton onClick={toggle}>Back</BuyerBackButton>

          <BuyerContinueButton onClick={handleContinue}>
            Continue
          </BuyerContinueButton>
        </BuyerButtons>
      </NdaPopupContainer>
    </PopupBox>
  )
}

export default NDAModal
