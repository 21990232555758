import React from 'react'
import styled from 'styled-components'
import Paths from '../../../Paths'
import { useMyUser } from '../../../api/hooks/users'
import { SecondaryButton } from '../../../styles/shared-styled-components'

const PurpleHoverButton = styled(SecondaryButton)`
  &:hover {
    background-color: ${({ theme }) => theme.color.purple};
  }
`

const SignupButton = ({ onClick, text = 'Value My Practice' }) => {
  const { loginWithRedirect } = useMyUser().auth0Context

  const handleSignup = async () => {
    onClick()
    await loginWithRedirect({
      appState: {
        returnTo: Paths.default,
      },
      authorizationParams: {
        screen_hint: 'signup',
        utm_affiliate: localStorage.getItem('utm_affiliate'),
      },
    })
  }

  return (
    <>
      <PurpleHoverButton id='signup-button' onClick={handleSignup}>
        {'Sign Up'}
      </PurpleHoverButton>
    </>
  )
}

export default SignupButton
