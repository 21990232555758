import MuiMenuList from '@mui/material/MenuList'
import MuiPopper from '@mui/material/Popper'
import styled, { css } from 'styled-components/macro'
import {
  ScrollStyle,
  ThinScrollBarStyle,
} from '../../styles/shared-styled-components'

export const DefaultPopper = styled(MuiPopper)`
  color: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.typography.typeFont};
  z-index: ${({ theme }) => theme.spacing.headerZIndex + 1};
  position: fixed !important;
  bottom: 0;
  right: 0;
  top: unset;
  left: unset;
  width: ${(props) => props.fixedWidth && `${props.fixedWidth}px`};
  .MuiMenu-list {
    border: 1px solid ${({ theme }) => theme.color.lightGrey};
  }
  .MuiPaper-root {
    border-radius: 4px;
  }
  ${(props) =>
    props.customStyle &&
    css`
      ${props.customStyle}
    `}
`

export const OffsetPopper = styled(MuiPopper)`
  color: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.typography.typeFont};
  z-index: ${({ theme }) => theme.spacing.headerZIndex + 1};
  top: 10px !important;
  left: -10px !important;
  .MuiMenu-list {
    border: 1px solid ${({ theme }) => theme.color.lightGrey};
    border-radius: 4px;
  }
`

export const HeaderPopper = styled(MuiPopper)`
  width: 100%;
  top: 25px !important;
  left: 0px;
  font-family: ${({ theme }) => theme.typography.typeFont};
  z-index: ${({ theme }) => theme.spacing.headerZIndex + 1};
  .MuiList-root {
    display: flex;
    padding: 30px 20px;
    gap: 20px;
  }
`

export const FullScreenPopper = styled(OffsetPopper)`
  top: 40px !important;
  left: -20px !important;

  @media (max-width: 768px) {
    left: 0px !important;
  }
`

export const MenuList = styled(MuiMenuList)`
  padding-top: ${(props) => props.disableTopPadding && '0px'};
  padding-bottom: ${(props) => props.disablebottompadding && '0px'};
  max-height: 365px;
  ${(props) =>
    !props.useThinScrollBar
      ? css`
          ${ScrollStyle}
        `
      : css`
          ${ThinScrollBarStyle}
        `};
`
