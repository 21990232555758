import isEmpty from 'lodash/isEmpty'
import range from 'lodash/range'
import { createSearchParams } from 'react-router-dom'
import Paths from '../Paths'
import {
  buyGoalKey,
  editingFlowKey,
  orderedCreatePathSectionKeys,
  orderedDueDiligencePathSectionKeys,
  publishingFlowKey,
  publishSectionKey,
  sellGoalKey,
  underReviewStatusKey,
} from './constants'
import {
  checkIfPracticeIncludesFinancials,
  checkIfPracticeIncludesPictures,
} from './data'
import {
  checkIfBuyOrSellGoalPresent,
  checkIfSellGoalIsPresent,
  checkIfValueGoalIsPresent,
  getProductOrServiceGoal,
} from './user'

export const defaultHomePath = Paths.practices

const totalNumOnboardingSteps = 3
export const onboardingStepsList = range(1, totalNumOnboardingSteps + 1)

export function removeMapSearchFromSearchParams(params) {
  if (params) {
    return params.replace(/(\?|&)mapSearch=[^&]+/, '')
  }
  return ''
}

export function addMapSearchToSearchParams(params) {
  return removeMapSearchFromSearchParams(params) + '&mapSearch=true'
}

const checkIfProductOrServiceSubgoalIsComplete = (productOrServiceGoal) => {
  // If there's no product or service goal defined, they're complete, as none needs to be added
  if (!productOrServiceGoal) {
    return true
  }

  // Else, check if they've filled in the subgoal for their product or service goal
  return productOrServiceGoal && !!productOrServiceGoal.subGoal
}

export const getPostOnboardingRoute = (goals) => {
  if (checkIfSellGoalIsPresent(goals) || checkIfValueGoalIsPresent(goals)) {
    return Paths.myPractices
  } else {
    return Paths.practices
  }
}

export const getOnboardingRouteToRedirectTo = (user) => {
  const goals = user?.goals
  const buyOrSellGoalPresent = checkIfBuyOrSellGoalPresent(goals)
  const productOrServiceGoal = getProductOrServiceGoal(goals)

  if (!(user.firstName && user.lastName)) {
    // Name
    return Paths.nameOnboarding
  } else if (!(user.phone && user.phoneVerified)) {
    // Phone
    return Paths.phoneOnboarding
  } else if (isEmpty(goals)) {
    // Goals
    return Paths.goalsOnboarding
  } else if (!checkIfProductOrServiceSubgoalIsComplete(productOrServiceGoal)) {
    // Subgoal
    return Paths.productOrServiceSubGoalOnboarding
  } else if (buyOrSellGoalPresent && user.isLicensedToPractice == null) {
    // Is licensed to practice
    return Paths.isLicensedToPracticeOnboarding
  } else if (
    buyOrSellGoalPresent &&
    user.isLicensedToPractice !== null &&
    isEmpty(user.license)
  ) {
    // Dental license info
    return Paths.dentalLicenseInfoOnboarding
  } else if (!!productOrServiceGoal) {
    // Professional licensing
    return Paths.linkedinUrlOnboarding
  }

  // Default return (should never hit here if we've accounted for all cases in the if statements)
  return Paths.nameOnboarding
}

export const getBrowsePracticesPathWithLocationSearch = (
  locationSearchTerm,
) => {
  if (!locationSearchTerm) {
    return Paths.practices
  }

  const locationSearchParams = {
    location: locationSearchTerm,
  }
  const practicePathWithLocationSearch = {
    pathname: Paths.practices,
    search: createSearchParams(locationSearchParams).toString(),
  }
  return practicePathWithLocationSearch
}

export const getHomePathToRedirectToFromUserGoals = (goals) => {
  if (!goals?.length) {
    return defaultHomePath
  }

  const goalKeys = goals.map((g) => g.mainGoal)

  const includesSellingGoal = goalKeys.includes(sellGoalKey)
  const includesBuyingGoal = goalKeys.includes(buyGoalKey)

  if (includesBuyingGoal && includesSellingGoal) {
    return Paths.practices
  } else if (includesSellingGoal) {
    return Paths.myPractices
  } else if (includesBuyingGoal) {
    return Paths.practices
  } else {
    return defaultHomePath
  }
}

export const updateSearchParams = (
  newParamMap,
  currentSearchParams,
  setSearchParamsInStateCallback,
) => {
  const newSearchParams = new URLSearchParams(currentSearchParams.toString())
  for (const key in newParamMap) {
    const searchValue = newParamMap[key]
    // Want to make sure we allow 'false' or 0 as valid values, even though they're falsey
    if (searchValue != null && searchValue != '') {
      newSearchParams.set(key, searchValue)
    } else {
      newSearchParams.delete(key)
    }
  }
  setSearchParamsInStateCallback(newSearchParams)
}

export const getCreatePracticeRouteFromId = (
  baseRoute,
  practiceId,
  inValuationMode = false,
) => {
  let formattedRoute = baseRoute

  if (inValuationMode) {
    formattedRoute += `?valuation=true`
  }

  return formattedRoute?.replace(':practiceId', practiceId)
}

export const getDueDiligenceRouteFromId = (baseRoute, practiceId) => {
  return baseRoute?.replace(':practiceId', practiceId)
}

export const dueDiligenceFlowSteps = [
  {
    path: Paths.createPracticeDueDiligence,
    label: 'Due Diligence',
    type: 'input',
    skippable: true,
    validator: checkIfPracticeIncludesFinancials,
  },
]

export const createPracticeFlowSteps = [
  {
    path: Paths.createPracticeOverview,
    label: 'Overview',
    type: 'input',
    skippable: false,
  },
  {
    path: Paths.createPracticeTeam,
    label: 'Team',
    type: 'input',
    skippable: false,
  },
  {
    path: Paths.createPracticeLease,
    label: 'Real Estate',
    type: 'input',
    skippable: false,
  },
  {
    path: Paths.createPracticeTerms,
    label: 'Your Terms',
    type: 'input',
    skippable: false,
  },
  {
    path: Paths.createPracticeValuation,
    label: 'Valuation',
    type: 'reward',
    skippable: false,
  },
]

// Sends user to "publish" page when exit
export const makeCreatePracticePathInPublishingFlow = (path) => {
  return `${path}?flow=${publishingFlowKey}`
}

// Sends user to "view practice" page when exit
export const makeCreatePracticePathInEditingFlow = (path) => {
  return `${path}?flow=${editingFlowKey}`
}

export const makeCreatePracticePathInCurrentFlow = (path, flow) => {
  if (flow === publishingFlowKey) {
    return makeCreatePracticePathInPublishingFlow(path)
  }

  if (flow === editingFlowKey) {
    return makeCreatePracticePathInEditingFlow(path)
  }

  return path
}

export const getDueDiligencePathToNavigateTo = (
  currentStepIndex,
  practiceId,
  action,
  currentFlow = null,
  inValuationMode = false,
) => {
  if (action === 'next') {
    // Going forward
    if (currentStepIndex < dueDiligenceFlowSteps.length - 1) {
      let nextPath = dueDiligenceFlowSteps[currentStepIndex + 1]['path']

      return getCreatePracticeRouteFromId(nextPath, practiceId, inValuationMode)
    }

    // Reached end of flow
    if (currentFlow === publishingFlowKey) {
      return getPublishPracticeRouteFromId(practiceId)
    } else if (currentFlow === editingFlowKey) {
      return getViewPracticePathFromId(practiceId)
    }

    return Paths.myPractices
  }

  // Backwards
  if (currentStepIndex > 0) {
    let backPath = dueDiligenceFlowSteps[currentStepIndex - 1]['path']

    return getCreatePracticeRouteFromId(backPath, practiceId, inValuationMode)
  } else {
    if (currentFlow === publishingFlowKey) {
      return getPublishPracticeRouteFromId(practiceId)
    } else if (currentFlow === editingFlowKey) {
      return getViewPracticePathFromId(practiceId)
    }

    return Paths.myPractices
  }
}

export const getCreatePracticePathToNavigateTo = (
  currentStepIndex,
  practiceId,
  action,
  currentFlow = null,
  completedWholeListingFlow = false,
  listingStatus = null,
  inValuationMode = false,
) => {
  if (action === 'next') {
    // Going forward
    if (currentStepIndex < createPracticeFlowSteps.length - 1) {
      let nextPath = createPracticeFlowSteps[currentStepIndex + 1]['path']

      // If the back path was supposed to be the off-market page, but we've already completed the whole listing flow,
      // we don't want to show the 'post off-market' page when they click back.
      // So instead we go one step further back, to the valuation screen
      if (
        nextPath === Paths.createPracticePostOffMarket &&
        (completedWholeListingFlow || currentFlow)
      ) {
        nextPath = createPracticeFlowSteps[currentStepIndex + 2]['path']
      }

      // Can't append the 'publishing' query param here, because it messes up with the way this path is navigated to
      // by the calling component later on
      return getCreatePracticeRouteFromId(nextPath, practiceId, inValuationMode)
    } else {
      // If we've just published and the listing is still under review
      if (listingStatus === underReviewStatusKey) {
        return getCreatePracticeRouteFromId(
          Paths.createPracticePublishedLandingPage,
          practiceId,
        )
      }

      // Default just go back to my practices
      return inValuationMode ? Paths.myValuations : Paths.myPractices
    }
  } else {
    // Going back
    if (currentStepIndex > 0) {
      let backPath = createPracticeFlowSteps[currentStepIndex - 1]['path']

      // If the back path was supposed to be the off-market page, but we've already completed the whole listing flow,
      // we don't want to show the 'post off-market' page when they click back.
      // So instead we go one step further back, to the valuation screen
      if (
        backPath === Paths.createPracticePostOffMarket &&
        (completedWholeListingFlow || currentFlow)
      ) {
        backPath = createPracticeFlowSteps[currentStepIndex - 3]['path']
      }

      if (currentFlow) {
        backPath = makeCreatePracticePathInCurrentFlow(backPath, currentFlow)
      }

      return getCreatePracticeRouteFromId(backPath, practiceId, inValuationMode)
    } else if (currentStepIndex === 0) {
      // If we're on the overview page
      return getCreatePracticeRouteFromId(
        Paths.createPracticeStart,
        practiceId,
        inValuationMode,
      )
    } else {
      // If we're on the start page
      return inValuationMode ? Paths.myValuations : Paths.myPractices
    }
  }
}

export const openUrlInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export const createPracticeSectionPathMap = new Map([
  [orderedCreatePathSectionKeys[0], Paths.createPracticeOverview],
  [orderedCreatePathSectionKeys[1], Paths.createPracticeTeam],
  [orderedCreatePathSectionKeys[2], Paths.createPracticeLease],
  [orderedCreatePathSectionKeys[3], Paths.createPracticeTerms],
])

export const dueDiligenceSectionPathMap = new Map([
  [orderedDueDiligencePathSectionKeys[0], Paths.createPracticePictures],
  [orderedDueDiligencePathSectionKeys[1], Paths.createPracticeDueDiligence],
])

export const allPracticeSectionPathMap = new Map([
  [orderedCreatePathSectionKeys[0], Paths.createPracticeOverview],
  [orderedCreatePathSectionKeys[1], Paths.createPracticeTeam],
  [orderedCreatePathSectionKeys[2], Paths.createPracticeLease],
  [orderedCreatePathSectionKeys[3], Paths.createPracticeTerms],
  [orderedCreatePathSectionKeys[4], Paths.createPracticeTerms],
  [orderedDueDiligencePathSectionKeys[0], Paths.createPracticePictures],
  [orderedDueDiligencePathSectionKeys[1], Paths.createPracticeDueDiligence],
])

export const getCreatePracticePathFromSectionName = (
  sectionName,
  id,
  inValuationMode = false,
) => {
  const baseRoute = createPracticeSectionPathMap.get(sectionName)

  return getCreatePracticeRouteFromId(baseRoute, id, inValuationMode)
}

export const getPublishPracticeRouteFromId = (id) => {
  return getCreatePracticeRouteFromId(Paths.createPracticePublish, id)
}

export const getDueDiligencePathFromSectionName = (sectionName, id) => {
  const baseRoute = dueDiligenceSectionPathMap.get(sectionName)

  return getDueDiligenceRouteFromId(baseRoute, id)
}

export const getViewPracticePathFromId = (id) => {
  return Paths.viewPractice.replace(':practiceId', id)
}

export const getMyOffersPathFromPracticeId = (id) => {
  return `${Paths.myOffers}/${id ?? ''}`
}

export const navigateToCreatePracticeSectionToEditInPublishingFlow = (
  sectionName,
  practiceId,
  navigatorCallback,
) => {
  let path = getCreatePracticePathFromSectionName(sectionName, practiceId)

  path = makeCreatePracticePathInPublishingFlow(path)

  navigatorCallback(path)
}

export const navigateToDueDiligenceSectionToEdit = (
  sectionName,
  practiceId,
  navigatorCallback,
  currentFlow,
) => {
  let path = getDueDiligencePathFromSectionName(sectionName, practiceId)

  path = makeCreatePracticePathInCurrentFlow(path, currentFlow)

  navigatorCallback(path)
}

export const navigateToCreatePracticeSectionToEditInEditingFlow = (
  sectionName,
  practiceId,
  navigatorCallback,
) => {
  let path = getCreatePracticePathFromSectionName(sectionName, practiceId)

  path = makeCreatePracticePathInEditingFlow(path)

  navigatorCallback(path)
}

export const navigateToPublishListingPage = (practiceId, navigatorCallback) => {
  const path = getCreatePracticeRouteFromId(
    Paths.createPracticePublish,
    practiceId,
  )

  navigatorCallback(path)
}

export const navigateToViewPracticePage = (practiceId, navigatorCallback) => {
  const path = getViewPracticePathFromId(practiceId)

  navigatorCallback(path)
}

export default {
  defaultHomePath,
  onboardingStepsList,
  getBrowsePracticesPathWithLocationSearch,
  getHomePathToRedirectToFromUserGoals,
  updateSearchParams,
  getCreatePracticeRouteFromId,
  createPracticeFlowSteps,
  getCreatePracticePathToNavigateTo,
  openUrlInNewTab,
  createPracticeSectionPathMap,
  getCreatePracticePathFromSectionName,
  navigateToCreatePracticeSectionToEditInPublishingFlow,
  navigateToPublishListingPage,
  getViewPracticePathFromId,
  navigateToCreatePracticeSectionToEditInEditingFlow,
  navigateToViewPracticePage,
}
