import { CheckCircleRounded, CloseOutlined } from '@mui/icons-material'
import {
  TableCell,
  TableContainer,
  TableRow,
  tableCellClasses,
} from '@mui/material'
import styled, { css } from 'styled-components/macro'
import {
  ColumnFlexStyle,
  InterSubsectionalGap,
  IntersectionalGap,
  ParagraphText,
  PrimaryButton,
  SecondaryButton,
  offMarketButton,
} from '../../../styles/shared-styled-components'

export const PostOffMarketTextContainer = styled.div`
  ${ColumnFlexStyle}
  align-items: center;
  justify-content: center;
  text-align: center;
  ${(props) =>
    props.ismobilescreen
      ? css`
          ${InterSubsectionalGap}
        `
      : css`
          ${IntersectionalGap}
        `}
`

export const PostOffMarketText = styled(ParagraphText)`
  color: ${({ theme }) => theme.color.black};
  font-size: ${(props) =>
    props.ismobilescreen
      ? props.theme.typography.sentenceFontSize
      : props.theme.typography.titleFontSize};
`

const ButtonStyle = css`
  width: ${(props) => (props.ismobilescreen ? '250px' : '300px')};
`

export const CompleteListingButton = styled(PrimaryButton)`
  ${ButtonStyle}
`

export const PostOffMarketButton = styled(offMarketButton)`
  ${ButtonStyle}
`

export const PlansTableContainer = styled(TableContainer)`
  height: calc(100vh - 400px);
  width: 70%;
  margin: auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const PlansTableHeaderRow = styled(TableRow)`
  & th:nth-child(2) {
    background-color: #8e66ac;
    color: white;
  }
  & th:nth-child(3) {
    background-color: #bdbdbd;
    color: white;
  }
  & th:nth-child(4) {
    background-color: #539b53;
    color: white;
  }
  & th:nth-child(5) {
    background-color: #2196f3;
    color: white;
  }
`

export const PlansTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.color.lighterGrey,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export const PlansTableCell = styled(TableCell)(({ variant }) => ({
  fontWeight: variant ?? 'normal',
  textAlign: 'center',
  width: '100px',
}))

export const PlanCheckIcon = styled(CheckCircleRounded)`
  font-size: 30px;
  color: green;
`

export const PlanCloseIcon = styled(CloseOutlined)`
  ${css`
    font-size: ${({ theme }) => theme.typography.hugeFontSize};
    color: ${({ theme }) => theme.color.muiLightGrey};
  `}
`

export const PlanSelectButton = styled(PrimaryButton)`
  ${css`
    font-size: ${({ theme }) => theme.typography.microFontSize};
    padding: 10px 15px;
    min-width: 120px;
    text-align: 'center';
  `}
`
