import React, { Fragment } from 'react'
import { onboardingStepsList } from '../../../utils/routing'
import {
  CheckmarkIcon,
  StepCircle,
  StepTransitionLine,
  StepTransitionLineContainer,
  StepsContainer,
} from './styled'

const totalNumSteps = onboardingStepsList.length

const OnboardingSteps = ({ currentStep }) => {
  return (
    <StepsContainer>
      {onboardingStepsList.map((step) => {
        const completed = currentStep > step
        const selected = currentStep === step
        return (
          <Fragment key={step}>
            <StepCircle selected={selected} completed={completed}>
              {completed ? <CheckmarkIcon /> : step}
            </StepCircle>
            {step !== totalNumSteps && (
              <StepTransitionLineContainer>
                <StepTransitionLine />
              </StepTransitionLineContainer>
            )}
          </Fragment>
        )
      })}
    </StepsContainer>
  )
}

export default OnboardingSteps
