import styled from 'styled-components/macro'
import {
  AutoHorizontalMargins,
  ColumnFlexStyle,
  MediumIconStyle,
  PrimaryButton,
  MicroGap,
  ParagraphText,
  RowFlexStyle,
  TitleText,
} from '../../../styles/shared-styled-components'
import { Person, CameraAltRounded } from '@mui/icons-material'
import Button from '@mui/material/Button'

export const BuyerProfileContainer = styled.div`
  gap: 5;
  padding: 20px 0px;
`

export const BuyerProfileTitle = styled(TitleText)`
  text-align: left;
  margin-bottom: 20px;
`

export const CreateForm = styled.div`
  ${RowFlexStyle};
  justify-content: space-around;
  ${AutoHorizontalMargins};
  margin-bottom: 20px;
`

export const ImageColumn = styled.div`
  ${AutoHorizontalMargins};
  width: 22%;
`

export const UploadBox = styled.div`
  min-height: 180px;
  height: fit-content;
  border: 1px solid ${({ theme }) => theme.color.muiLightGrey};
  border-radius: ${({ theme }) => `${theme.spacing.baseBorderRadius}px`};
  padding: 20px;
  ${ColumnFlexStyle}
  justify-content: center;
  align-items: center;
  background-color: #ececf0;
`

export const ImageUploadTitle = styled(ParagraphText)`
  padding: 10px;
`

export const ImageUpload = styled(ParagraphText)`
  ${ColumnFlexStyle}
  justify-content: center;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.color.muiLightGrey};
  width: 100px;
  height: 100px;
`

export const ImageIcon = styled(Person)`
  color: ${({ theme }) => theme.color.primary};
  height: 80px;
  width: auto;
`

export const CameraIcon = styled(CameraAltRounded)`
  ${MediumIconStyle}
  margin-top: -20px;
  margin-right: -60px;
`

export const FirstInputColumn = styled.div`
  width: 39%;
  padding: 0px 20px;
`

export const Box = styled.div`
  &.mt-2 {
    margin-top: 12px;
  }
`

export const SecondInputColumn = styled.div`
  width: 39%;
  padding: 0px 20px;
`

export const BuyerProfileButtons = styled.div`
  ${RowFlexStyle}
  justify-content: space-between;
  ${AutoHorizontalMargins}
  ${MicroGap}
`

export const BuyerProfileContinueButton = styled(PrimaryButton)``

export const BuyerProfileBackButton = styled(Button)`
  border: 1px ${({ theme }) => theme.color.primary} solid;
  color: ${({ theme }) => theme.color.primary};
  border-radius: ${({ theme }) => theme.spacing.baseBorderRadius}px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.typography.typeFont};
  font-size: ${({ theme }) => theme.typography.sentenceFontSize};
  text-transform: none;
  padding: ${(props) =>
    props.disablepadding === 'true' ? '0px' : '10px 15px;'};
  &:hover {
    background-color: ${({ theme }) => theme.color.darkHover};
    color: ${({ theme }) => theme.color.white};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.color.disabled};
    color: ${({ theme }) => theme.color.white};
  }
`
