import SearchOffIcon from '@mui/icons-material/SearchOff'
import styled from 'styled-components/macro'
import {
  AutoHorizontalMargins,
  BaseMargin,
  ColumnFlexStyle,
  ExtraLargeIconStyle,
  IntrasectionalGap,
  PrimaryButton,
} from '../../../styles/shared-styled-components'
import { assumedDistanceFromTopOfPageToTopOfMap } from '../PracticePreviewThumbnail/PracticePreviewThumbnail'
import { Box } from '@mui/material'

export const NoDataContainer = styled.div`
  height: calc(100vh - ${assumedDistanceFromTopOfPageToTopOfMap}px);
  width: 100%;
  ${BaseMargin}
  ${ColumnFlexStyle}
  justify-content: center;
  align-items: center;
  background-image: url('https://dentacloud-connect-storage.nyc3.cdn.digitaloceanspaces.com/assets/empty-map.png');
  background-size: cover;
`

export const NoDataMessageContainer = styled.div`
  border-radius: ${({ theme }) => `${theme.spacing.baseBorderRadius}px`};
  border: 1px solid ${({ theme }) => theme.color.black};
  padding: 30px;
  ${ColumnFlexStyle}
  ${IntrasectionalGap}
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.superLightGrey};
  width: ${(props) => props.ismobilescreen && '70%'};
  ${AutoHorizontalMargins}
  text-align: center;
  opacity: 100%;
`

export const EmptySearchIcon = styled(SearchOffIcon)`
  ${ExtraLargeIconStyle}
  height: 60px;
`

export const ResetFiltersButton = styled(PrimaryButton)`
  width: 250px;
`

export const MapEmptyResultsWrapper = styled(Box)`
  position: absolute;
  top: 300px;
  left: 0px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
