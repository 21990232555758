import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Paths from '../../../Paths'
import { usePracticeById } from '../../../api/hooks/practices'
import CreatePracticeFlowNavigation from '../../../components/CreatePractice/CreatePracticeFlowNavigation'
import CreatePracticeHeader from '../../../components/CreatePractice/CreatePracticeHeader'
import {
  CreatePracticeFormContainer,
  CreatePracticeFormTitleContainer,
  CreatePracticeFormTitleText,
} from '../../../components/CreatePractice/CreatePracticeInputForm/styled'
import LoadingIndicator from '../../../components/LoadingIndicator'
import Header from '../../../components/NavBar/Header'
import {
  FlexDiv,
  FullPageContentContainerWithGap,
  PageContainer,
  VUNSBC,
} from '../../../styles/shared-styled-components'
import { ConnectServicePlans } from '../../../utils/constants'
import { getConnectServicePlanFeatures } from '../../../utils/data'
import {
  getCreatePracticePathToNavigateTo,
  getCreatePracticeRouteFromId,
} from '../../../utils/routing'
import {
  PlanCheckIcon,
  PlanCloseIcon,
  PlanSelectButton,
  PlansTableCell,
  PlansTableContainer,
  PlansTableHeaderRow,
  PlansTableRow,
} from './styled'

const currentStep = 5

const PostOffMarket = ({ user, width, ismobilescreen }) => {
  const navigate = useNavigate()

  const { practiceId } = useParams()
  const { practice, loading } = usePracticeById(practiceId)
  const servicePlanFeatures = getConnectServicePlanFeatures()

  const onClickNext = (action, plan) => {
    let nextPath

    if (action === 'upload') {
      // Navigate to the next step in the flow
      nextPath = getCreatePracticePathToNavigateTo(
        currentStep,
        practiceId,
        'next',
      )
    } else if (action === 'publish') {
      nextPath = getCreatePracticeRouteFromId(
        Paths.createPracticePublish,
        practiceId,
      )
    } else if (action === 'book') {
      window.open(
        'https://calendly.com/drjohn-dc/30min?month=2024-02&date=2024-02-23',
      )
    }

    if (action !== 'book') {
      navigate(`${nextPath}?plan=${plan}`)
    }
  }

  useEffect(() => {
    if (practice?.completedAt) {
      const nextPath = getCreatePracticePathToNavigateTo(
        currentStep,
        practiceId,
        'next',
      )
      navigate(nextPath)
    }
  }, [practice])

  return (
    practice && (
      <Box sx={{ flex: 1 }}>
        <Header user={user} width={width} ismobilescreen={ismobilescreen} />
        <VUNSBC style={{ height: 'calc(100vh - 0px)', display: 'flex' }}>
          <PageContainer style={{ flex: 1 }}>
            <FullPageContentContainerWithGap ismobilescreen={ismobilescreen}>
              <CreatePracticeHeader
                currentStep={currentStep}
                ismobilescreen={ismobilescreen}
                practice={practice}
              />
              <CreatePracticeFormContainer ismobilescreen={ismobilescreen}>
                <CreatePracticeFormTitleContainer>
                  <CreatePracticeFormTitleText>
                    Dentacloud Pricing Options
                  </CreatePracticeFormTitleText>
                </CreatePracticeFormTitleContainer>
                {loading ? (
                  <FlexDiv
                    style={{
                      width: '100%',
                      height: 'calc(100vh - 400px)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <LoadingIndicator />
                  </FlexDiv>
                ) : (
                  <PlansTableContainer>
                    <Table stickyHeader={!ismobilescreen}>
                      <TableHead>
                        <PlansTableHeaderRow>
                          <TableCell sx={{ maxWidth: 200 }}></TableCell>
                          {Object.values(ConnectServicePlans).map(
                            (value, i) => (
                              <PlansTableCell key={i}>{value}</PlansTableCell>
                            ),
                          )}
                        </PlansTableHeaderRow>
                      </TableHead>
                      <TableBody sx={{ overflow: 'auto', height: '300px' }}>
                        {Object.keys(servicePlanFeatures).map(
                          (featureKey, i) => (
                            <PlansTableRow key={i}>
                              <TableCell>{featureKey}</TableCell>
                              {Object.values(
                                servicePlanFeatures[featureKey],
                              ).map((value, j) => (
                                <PlansTableCell key={j} variant='bold'>
                                  {typeof value === 'boolean' ? (
                                    value ? (
                                      <PlanCheckIcon />
                                    ) : (
                                      <PlanCloseIcon />
                                    )
                                  ) : (
                                    value
                                  )}
                                </PlansTableCell>
                              ))}
                            </PlansTableRow>
                          ),
                        )}
                        <TableRow>
                          <TableCell></TableCell>
                          {Object.keys(ConnectServicePlans).map((key, i) => (
                            <PlansTableCell key={i}>
                              {key === 'free' && (
                                <PlanSelectButton
                                  onClick={() => onClickNext('publish', key)}
                                >
                                  Publish <br></br>Listing
                                </PlanSelectButton>
                              )}
                              {key === 'lifetimeDeal' && (
                                <PlanSelectButton
                                  onClick={() => onClickNext('book', key)}
                                >
                                  Book a Call Now
                                </PlanSelectButton>
                              )}
                              {key === 'slidingScale' && (
                                <PlanSelectButton
                                  onClick={() => onClickNext('upload', key)}
                                >
                                  Upload Information
                                </PlanSelectButton>
                              )}
                              {key === 'whiteGloveService' && (
                                <PlanSelectButton
                                  onClick={() => onClickNext('book', key)}
                                >
                                  Book a Call Now
                                </PlanSelectButton>
                              )}
                            </PlansTableCell>
                          ))}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </PlansTableContainer>
                )}
              </CreatePracticeFormContainer>
            </FullPageContentContainerWithGap>
          </PageContainer>
          <CreatePracticeFlowNavigation
            currentStep={currentStep}
            practiceId={practiceId}
          />
        </VUNSBC>
      </Box>
    )
  )
}

export default PostOffMarket
