import React from 'react'
import { getPracticeListingStatusChipText } from '../../../utils/constants'
import { ListingStatusChip } from './styled'

const PracticeListingStatusChip = ({ status }) => {
  const chipText = getPracticeListingStatusChipText(status)

  return <ListingStatusChip status={status}>{chipText}</ListingStatusChip>
}

export default PracticeListingStatusChip
