import { useMutationWithErrorLogging } from '../../../utils/hooks'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Paths from '../../../Paths'
import {
  COMPLETE_ONBOARDING,
  EDIT_LICENSE_INFO,
} from '../../../api/mutations/users'
import { GET_MY_USER } from '../../../api/queries/users'
import Header from '../../../components/NavBar/Header'
import OnboardingModal from '../../../components/Onboarding/OnboardingModal'
import OnboardingSteps from '../../../components/Onboarding/OnboardingSteps'
import {
  BackgroundContainer,
  ColumnButtonContainer,
  DropdownLabel,
  ErrorText,
  FlexDiv,
  Form,
  PageContainer,
  StyledMenuItem,
} from '../../../styles/shared-styled-components'
import { provincesAndStatesCodeNameMapArray } from '../../../utils/constants'
import { getProductOrServiceGoal } from '../../../utils/user'
import {
  Asset,
  AssetContainer,
  BackButton,
  ContinueButton,
  FormTitleText,
  OnboardingDropdown,
  OnboardingForm,
  OnboardingFormContainer,
  OnboardingInputField,
} from '../shared-onboarding-styles'
import { DentalLicensingErrorMessageContainer } from './styled'

const defaultLicenseBodyLabel = 'License'
const getLicenseBodyLabelFromProvince = (province) => {
  switch (province) {
    case null:
      return defaultLicenseBodyLabel
    case 'on':
      return 'RCDSO'
    case 'ab':
      return 'CDSA'
    case 'qc':
      return 'ODQ'
    case 'mb':
      return 'MDA'
    case 'nb':
      return 'NBDAA'
    default:
      return defaultLicenseBodyLabel
  }
}

const defaultLicenseVerificationFailureMessage =
  'License could not be verified. Please try again with a registered number.'

const justUpdatedLocalStorageKey = 'just-updated-dental-license'

const DentalLicensingInfo = ({
  user,
  width,
  ismobilescreen,
  isIpadSizeOrSmaller,
}) => {
  const navigate = useNavigate()
  const justUpdated =
    localStorage.getItem(justUpdatedLocalStorageKey) === 'true'

  const [editLicenseInfoMutation, { data: editLicenseInfoData }] =
    useMutationWithErrorLogging(EDIT_LICENSE_INFO)
  const [completeOnboardingMutation, { data: completeOnboardingData }] =
    useMutationWithErrorLogging(COMPLETE_ONBOARDING)

  const productOrServicesGoalPresent = !!getProductOrServiceGoal(user?.goals)

  const [licenseNumber, setLicenseNumber] = useState(
    user?.license?.licenseNumber,
  )
  const [province, setProvince] = useState(
    user?.license?.licensingProvinceOrState || '',
  )
  const [showContinueModal, setShowContinueModal] = useState(
    justUpdated && user?.license?.licenseVerified ? true : false,
  )

  // We need to set the error message like this, otherwise the error message doesn't always show
  // Reasoning:
  // We make the API call to save the license info
  // It returns that the verification failed, so we setErrorMessage
  // But then at the same time we update the cache (needed to properly display saved changes when
  // toggling between other onboarding screens)
  // Updating the cache causes the component to re-render, which sets error message back to null again
  const [errorMessage, setErrorMessage] = useState(
    user?.license?.licenseVerified === false
      ? defaultLicenseVerificationFailureMessage
      : '',
  )

  // Used so that just in case the navigation from onboarding -> home takes a long time,
  // the user can't smash the continue button while we're navigating them
  const [isLoading, setIsLoading] = useState(false)

  const licenseBodyLabel = getLicenseBodyLabelFromProvince(province)

  const changeJustUpdatedFieldFromLocalStorage = (value) => {
    localStorage.setItem(justUpdatedLocalStorageKey, value)
  }

  const handleLicenseNumberChange = (e) => {
    if (errorMessage) {
      setErrorMessage('')
    }
    setLicenseNumber(e.target.value)
  }

  const onClickContinueFromLicenseInfo = () => {
    editLicenseInfoMutation({
      variables: {
        licensingProvinceOrState: province,
        // Currently only supporting Canadian licenses
        licensingCountry: 'canada',
        licensingBody: licenseBodyLabel.toLowerCase(),
        licenseNumber,
      },
      update: async (cache, { data }) => {
        const userDataFetch = cache.readQuery({
          query: GET_MY_USER,
        })
        const license = data?.editLicenseInfo?.license

        // If we have cached user data, update the license
        if (userDataFetch) {
          // Grab the data in the cache and append the new license
          const existingUser = userDataFetch.getMyUser
          const updatedUser = {
            ...existingUser,
            license,
          }

          // Needed to make sure the license verified modal pops up properly after the following:
          // Enter a wrong license and click continue -- error message pops up
          // Then delete and enter the right license and press continue -- nothing happens
          // This is because the continue triggered a refetch, which rerendered the component, and set
          // showModal = false. To get around that, we have to add a localstorage field to store info when
          // we just updated the user cache, and make sure to properly show the continue modal
          changeJustUpdatedFieldFromLocalStorage(true)

          // Update the cache
          await cache.writeQuery({
            query: GET_MY_USER,
            data: {
              getMyUser: updatedUser,
            },
          })
        }
      },
      onCompleted: (data) => {
        const { license } = data.editLicenseInfo

        if (license != null) {
          if (license.licenseVerified === true) {
            setErrorMessage('')
            setShowContinueModal(true)
          } else {
            setErrorMessage(defaultLicenseVerificationFailureMessage)
          }
        } else {
          setErrorMessage('Unable to save. Please refresh and try again.')
        }
      },
    })
  }

  const onNavigateBack = () => {
    changeJustUpdatedFieldFromLocalStorage(false)
    navigate(Paths.isLicensedToPracticeOnboarding)
  }

  const navigateToNextOnboardingStep = () => {
    // Reset the localstorage field
    changeJustUpdatedFieldFromLocalStorage(false)

    if (productOrServicesGoalPresent) {
      navigate(Paths.linkedinUrlOnboarding)
    } else {
      // Complete onboarding
      setIsLoading(true)
      completeOnboardingMutation({
        update: async (cache, { data }) => {
          const userDataFetch = cache.readQuery({
            query: GET_MY_USER,
          })

          // Grab the returned hasOnboarded value
          const hasOnboardedResult = data?.completeOnboarding?.hasOnboarded

          // If we have cached user data, update the hasOnboarded field
          if (userDataFetch) {
            // Grab the data in the cache and update hasOnboarded
            const existingUser = userDataFetch.getMyUser
            const updatedUser = {
              ...existingUser,
              hasOnboarded: hasOnboardedResult,
            }

            // Update the cache
            await cache.writeQuery({
              query: GET_MY_USER,
              data: {
                getMyUser: updatedUser,
              },
            })
          }
        },
      })
    }
  }

  return (
    <>
      <Header
        user={user}
        width={width}
        ismobilescreen={isIpadSizeOrSmaller}
        isOnboardingScreen
      />
      <BackgroundContainer>
        <PageContainer>
          <FlexDiv>
            <OnboardingFormContainer ismobilescreen={isIpadSizeOrSmaller}>
              <OnboardingSteps currentStep={4} />
              <OnboardingForm ismobilescreen={ismobilescreen}>
                <FormTitleText ismobilescreen={ismobilescreen}>
                  Enter your license info.
                </FormTitleText>
                <Form>
                  <DropdownLabel>Issuing province *</DropdownLabel>
                  <OnboardingDropdown
                    label='Issuing province *'
                    onChange={(e) => setProvince(e.target.value)}
                    value={province}
                  >
                    {provincesAndStatesCodeNameMapArray.map((psMap) => {
                      const key = psMap['key']
                      const label = psMap['label']
                      return (
                        <StyledMenuItem value={key} key={key}>
                          {label}
                        </StyledMenuItem>
                      )
                    })}
                  </OnboardingDropdown>
                </Form>
                <OnboardingInputField
                  value={licenseNumber}
                  label={`${licenseBodyLabel} number *`}
                  onChange={handleLicenseNumberChange}
                />
                <DentalLicensingErrorMessageContainer
                  ismobilescreen={ismobilescreen}
                >
                  <ErrorText>{errorMessage}</ErrorText>
                </DentalLicensingErrorMessageContainer>
              </OnboardingForm>
              <ColumnButtonContainer>
                <ContinueButton
                  disabled={!licenseNumber || isLoading}
                  onClick={onClickContinueFromLicenseInfo}
                >
                  Save and continue
                </ContinueButton>
                <BackButton onClick={onNavigateBack}>Back</BackButton>
              </ColumnButtonContainer>
            </OnboardingFormContainer>
            {!isIpadSizeOrSmaller && (
              <AssetContainer>
                <Asset src={Paths.acquireConnectMetrics} />
              </AssetContainer>
            )}
          </FlexDiv>
        </PageContainer>
      </BackgroundContainer>
      <OnboardingModal
        titleText={'Dental License Verified'}
        contentText={
          <>
            We've verified your dental license. You'll now have access to
            premium features unavailable to non-dentists.
          </>
        }
        continueText={
          productOrServicesGoalPresent ? 'Continue' : 'Proceed to site'
        }
        isOpen={showContinueModal}
        onClose={() => setShowContinueModal(false)}
        onContinue={navigateToNextOnboardingStep}
        variant='success'
      />
    </>
  )
}

export default DentalLicensingInfo
