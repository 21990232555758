import gql from 'graphql-tag'

export const GET_BUYERS = gql`
  query getBuyers {
    getBuyers {
      id
      type
      year_of_graduation
      average_monthly_production
      school_of_graduation
      no_of_practices
      languages
      procedures
      years_to_work_post_sale
      pre_approved_financing
      percentage_to_buy
      buying_conditions
      purchase_price
      picture
      status
      updatedAt
      createdAt
      is_favorite
      is_archived
    }
  }
`

export const GET_BUYER_BY_ID = gql`
  query getBuyerById($id: String!) {
    getBuyerById(id: $id) {
      id
      type
      year_of_graduation
      average_monthly_production
      school_of_graduation
      no_of_practices
      languages
      procedures
      years_to_work_post_sale
      pre_approved_financing
      percentage_to_buy
      buying_conditions
      purchase_price
      picture
      file
      status
      updatedAt
      createdAt
    }
  }
`
