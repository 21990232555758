import { useApolloClient } from '@apollo/client'
import { useMutationWithErrorLogging } from '../../../utils/hooks'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { usePracticeById } from '../../../api/hooks/practices'
import { EDIT_PRACTICE_SALE_TERMS } from '../../../api/mutations/practices'
import CreatePracticeInput from '../../../components/CreatePractice/CreatePracticeInput'
import CreatePracticeInputForm from '../../../components/CreatePractice/CreatePracticeInputForm/CreatePracticeInputForm'
import {
  updatePracticeByIdInCache,
  updatePracticeValuationsInCache,
  updateUserHasCreatedValuationInCache,
} from '../../../utils/cache'
import {
  editingFlowKey,
  optionalBooleanFields,
  publishingFlowKey,
  saleTimeFrames,
} from '../../../utils/constants'
import { getIdOfFirstInputFieldWithError } from '../../../utils/input'
import { getIntFromCurrency } from '../../../utils/numbers'
import {
  getCreatePracticePathToNavigateTo,
  makeCreatePracticePathInCurrentFlow,
  navigateToPublishListingPage,
  navigateToViewPracticePage,
} from '../../../utils/routing'
import { scrollElementIntoView } from '../../../utils/view'

const currentStep = 3

const Terms = ({ user, width, ismobilescreen }) => {
  const navigate = useNavigate()
  const { cache } = useApolloClient()
  const practiceFormRef = useRef()

  const [editPracticeSaleTermsMutation] = useMutationWithErrorLogging(
    EDIT_PRACTICE_SALE_TERMS,
  )

  const { practiceId } = useParams()
  const { practice } = usePracticeById(practiceId)
  const [searchParams] = useSearchParams()
  const currentFlow = searchParams.get('flow')

  const [targetPrice, setTargetPrice] = useState(practice?.targetPrice || null)
  const [timeFrameLookingToSell, setTimeFrameLookingToSell] = useState(
    practice?.timeFrameLookingToSell || '',
  )
  const [monthsWorkingPostSale, setMonthsWorkingPostSale] = useState(
    practice?.monthsWorkingPostSale || null,
  )
  const [
    usedLifetimeCapitalGainsExemption,
    setUsedLifetimeCapitalGainsExemption,
  ] = useState(practice?.usedLifetimeCapitalGainsExemption || '')
  const [submittedDataWithErrors, setSubmittedDataWithErrors] = useState(false)
  const [changesMade, setChangesMade] = useState(false)

  const updateChangesMade = () => {
    if (!changesMade) {
      setChangesMade(true)
    }
  }

  useEffect(() => {
    if (practice) {
      setTargetPrice(practice.targetPrice)
      setTimeFrameLookingToSell(practice.timeFrameLookingToSell)
      setMonthsWorkingPostSale(practice.monthsWorkingPostSale)
      setUsedLifetimeCapitalGainsExemption(
        practice.usedLifetimeCapitalGainsExemption,
      )
    }
  }, [practice])

  const callEditPracticeSaleTermsMutation = useCallback(
    async (flowToReturnTo = null) => {
      const formattedTargetPrice = getIntFromCurrency(targetPrice)

      const navRef = practiceFormRef?.current?.getNavRef()
      navRef?.current?.setLoading(true)
      await editPracticeSaleTermsMutation({
        variables: {
          id: practiceId,
          targetPrice: formattedTargetPrice,
          timeFrameLookingToSell,
          monthsWorkingPostSale: parseInt(monthsWorkingPostSale),
          usedLifetimeCapitalGainsExemption,
        },
        onCompleted: async (data) => {
          await updatePracticeByIdInCache(
            practiceId,
            data?.editPracticeSaleTerms,
            cache,
          )

          await updatePracticeValuationsInCache(
            practiceId,
            data?.editPracticeSaleTerms?.mostRecentValuation,
            cache,
          )

          if (!user.hasCreatedValuation) {
            await updateUserHasCreatedValuationInCache(user, cache)
          }
          navRef?.current?.setLoading(false)

          if (flowToReturnTo === publishingFlowKey) {
            navigateToPublishListingPage(practiceId, navigate)
          } else if (flowToReturnTo === editingFlowKey) {
            navigateToViewPracticePage(practiceId, navigate)
          } else {
            // If we just want to navigate to the next step
            let nextPath = getCreatePracticePathToNavigateTo(
              currentStep,
              practiceId,
              'next',
              currentFlow,
              false,
              null,
              searchParams.get('valuation') === 'true',
            )

            if (currentFlow) {
              nextPath = makeCreatePracticePathInCurrentFlow(
                nextPath,
                currentFlow,
              )
            }

            navigate(nextPath)
          }
        },
      })
    },
    [
      practiceFormRef,
      cache,
      changesMade,
      currentFlow,
      editPracticeSaleTermsMutation,
      monthsWorkingPostSale,
      navigate,
      practiceId,
      searchParams,
      targetPrice,
      timeFrameLookingToSell,
      usedLifetimeCapitalGainsExemption,
      user,
    ],
  )

  const onClickNext = (flowToReturnTo = null) => {
    // If they've clicked without next being enabled, we know there are errors
    let containsError = !nextEnabled

    let errorElementIdToScrollTo

    if (containsError) {
      errorElementIdToScrollTo =
        getIdOfFirstInputFieldWithError(inputElementsArray)
    }

    // If there is an error ID to scroll to, set state to reflect that, and scroll to the first erroneous field
    if (errorElementIdToScrollTo) {
      scrollElementIntoView(document, errorElementIdToScrollTo)
      setSubmittedDataWithErrors(true)
    } else {
      setSubmittedDataWithErrors(false)
      callEditPracticeSaleTermsMutation(flowToReturnTo)
    }
  }

  const inputElements = {
    targetPrice: {
      id: 'targetPrice',
      fieldType: 'currency',
      state: targetPrice,
    },
    timeFrameLookingToSell: {
      id: 'timeFrameLookingToSell',
      fieldType: 'dropdown',
      state: timeFrameLookingToSell,
    },
    monthsWorkingPostSale: {
      id: 'monthsWorkingPostSale',
      fieldType: 'integer',
      state: monthsWorkingPostSale,
    },
    usedLifetimeCapitalGainsExemption: {
      id: 'usedLifetimeCapitalGainsExemption',
      fieldType: 'dropdown',
      state: usedLifetimeCapitalGainsExemption,
    },
  }

  const formInputs = [
    <CreatePracticeInput
      value={targetPrice}
      label='Target sale price * '
      width={width}
      onChange={(e) => {
        setTargetPrice(e.target.value)
        updateChangesMade()
      }}
      fieldType={inputElements['targetPrice']['fieldType']}
      elementId={inputElements['targetPrice']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    <CreatePracticeInput
      value={timeFrameLookingToSell}
      label='When are you looking to sell * '
      width={width}
      onChange={(e) => {
        setTimeFrameLookingToSell(e.target.value)
        updateChangesMade()
      }}
      fieldType={inputElements['timeFrameLookingToSell']['fieldType']}
      elementId={inputElements['timeFrameLookingToSell']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
      options={saleTimeFrames}
    />,
    <CreatePracticeInput
      value={monthsWorkingPostSale}
      label='Months willing to support the practice post-close * '
      width={width}
      onChange={(e) => {
        setMonthsWorkingPostSale(e.target.value)
        updateChangesMade()
      }}
      fieldType={inputElements['monthsWorkingPostSale']['fieldType']}
      elementId={inputElements['monthsWorkingPostSale']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
    />,
    <CreatePracticeInput
      value={usedLifetimeCapitalGainsExemption}
      label='Used lifetime capital gains exemption * '
      width={width}
      onChange={(e) => {
        setUsedLifetimeCapitalGainsExemption(e.target.value)
        updateChangesMade()
      }}
      fieldType={
        inputElements['usedLifetimeCapitalGainsExemption']['fieldType']
      }
      elementId={inputElements['usedLifetimeCapitalGainsExemption']['id']}
      submittedDataWithErrors={submittedDataWithErrors}
      options={optionalBooleanFields}
    />,
  ]

  const inputElementsArray = Object.values(inputElements)
  const nextEnabled = inputElementsArray.every(
    (field) => field['state'] != null && field['state'] !== '',
  )

  return (
    practice && (
      <CreatePracticeInputForm
        ref={practiceFormRef}
        user={user}
        width={width}
        ismobilescreen={ismobilescreen}
        currentStep={currentStep}
        practice={practice}
        formTitle={'Your Terms'}
        formInputs={formInputs}
        nextEnabled={nextEnabled}
        onClickNext={() => onClickNext()}
        currentFlow={currentFlow}
        onSaveInFlow={() => onClickNext(currentFlow)}
        inValuationMode={searchParams.get('valuation') === 'true'}
      />
    )
  )
}

export default Terms
