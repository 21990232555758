import { useMutationWithErrorLogging } from '../../../utils/hooks'
import { useApolloClient } from '@apollo/client'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CircleIcon from '@mui/icons-material/Circle'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Paths from '../../../Paths'
import { updatePracticeByIdInCache } from '../../../utils/cache'
import { usePracticeById } from '../../../api/hooks/practices'
import { EDIT_PRACTICE_FINANCIAL_DETAILS } from '../../../api/mutations/practices'
import CreatePracticeInputForm from '../../../components/CreatePractice/CreatePracticeInputForm/CreatePracticeInputForm'
import Accounting from '../../../components/CreatePractice/DueDeligenceForms/Accounting'
// import EmployeesTable from "../../../components/CreatePractice/DueDeligenceForms/EmployeesTable";
import HumanResource from '../../../components/CreatePractice/DueDeligenceForms/HumanResource'
import Legal from '../../../components/CreatePractice/DueDeligenceForms/Legal'
import PracticeManagementReports from '../../../components/CreatePractice/DueDeligenceForms/PracticeManagementReports'
import PracticeProfile from '../../../components/CreatePractice/DueDeligenceForms/PracticeProfile'
import PrivacyShield from '../../../components/Micro/PrivacyShield/PrivacyShield'
import {
  DUE_DILIGENCE_KEY,
  publishingFlowKey,
  editingFlowKey,
} from '../../../utils/constants'
import { getCompletedDueDiligenceSteps } from '../../../utils/data'
import {
  getDueDiligencePathToNavigateTo,
  navigateToViewPracticePage,
  navigateToPublishListingPage,
} from '../../../utils/routing'
import {
  BorderedWrapper,
  CompletenesCard,
  FlexBox,
  MainWrapper,
} from './styled'
import { active } from 'd3'
import { useIsMobileScreen } from '../../../utils/view'

const currentStep = 1
const temProg = {
  completed: [],
  skipped: [],
}

const dueDiligenceFieldPrivacyMessage = (
  <>
    Your due diligence information will not automatically be shown to buyers.
    Buyers may send you requests to view your due diligence information, and you
    can review information about the buyer before approving their access.
  </>
)

const FinancialDetails = (props) => {
  const navigate = useNavigate()
  const { cache } = useApolloClient()
  const [searchParams] = useSearchParams()
  const currentFlow = searchParams.get('flow')
  const savedProgress = localStorage.getItem(DUE_DILIGENCE_KEY)
  const { user, width } = props
  const ismobilescreen = useIsMobileScreen()
  const { practiceId } = useParams()
  const { practice, refetch } = usePracticeById(practiceId)
  const [canSkipForm, setCanSkipForm] = useState(false)
  const formRef = useRef()
  const practiceFormRef = useRef()
  const [financialDetails, setFinancialDetails] = useState(
    practice?.dueDiligence,
  )
  const [tempStore, setTempStore] = useState(practice?.dueDiligence)
  const [activeSubStep, setActiveSubStep] = useState(0)
  const [formProgress, setFormProgress] = useState(
    savedProgress ? JSON.parse(savedProgress)[practiceId] || temProg : temProg,
  )
  const [viewedSteps, setViewedSteps] = useState([])
  const [pageLoaded, setPageLoaded] = useState(false)
  const controllerRef = useRef(new AbortController())

  const [steps] = useState([
    {
      name: 'practiceProfile',
      label: 'Practice Profile',
      component: PracticeProfile,
    },
    { name: 'accounting', label: 'Accounting', component: Accounting },
    { name: 'legal', label: 'Legal', component: Legal },
    {
      name: 'practiceManagementReports',
      label: 'Practice Management Reports',
      component: PracticeManagementReports,
    },
    {
      name: 'humanResource',
      label: 'HR',
      component: HumanResource,
      /*subComponent: EmployeesTable,*/ hasOptionalFields: true,
    },
  ])

  const [editPracticeFinancialDetails, { loading, data }] =
    useMutationWithErrorLogging(EDIT_PRACTICE_FINANCIAL_DETAILS, {
      context: {
        fetchOptions: {
          signal: controllerRef.current.signal,
        },
      },
    })

  useEffect(() => {
    // automatically navigates the user to the uncompleted sub step on load
    if (financialDetails && !pageLoaded) {
      const stepStatuses = getCompletedDueDiligenceSteps(financialDetails)
      const uncompletedIndex = stepStatuses.findIndex(
        (step) => !step.stepCompleted,
      )
      if (uncompletedIndex >= 0) {
        setActiveSubStep(uncompletedIndex)
        setPageLoaded(true)
      }
    }
  }, [pageLoaded, financialDetails])

  useEffect(() => {
    debouncedRefetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempStore, financialDetails, loading])

  const debouncedRefetch = _.debounce(
    useCallback(() => {
      if (!loading && tempStore && !_.isEqual(tempStore, financialDetails)) {
        refetch()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tempStore, financialDetails, loading]),
    10000,
  )

  const debouncedEdit = _.debounce(
    useCallback(
      (data, callback) => {
        const payload = JSON.parse(JSON.stringify(data))
        delete payload.__typename
        if (payload.practiceProfile?.__typename)
          delete payload.practiceProfile?.__typename
        if (payload.accounting?.__typename)
          delete payload.accounting?.__typename
        if (payload.legal?.__typename) delete payload.legal?.__typename
        if (payload.practiceManagementReports?.__typename)
          delete payload.practiceManagementReports?.__typename
        if (payload.humanResource?.__typename)
          delete payload.humanResource?.__typename

        const navRef = practiceFormRef?.current?.getNavRef()
        navRef?.current?.setLoading(true)

        if (
          !payload ||
          (payload.constructor === Object && Object.keys(payload).length === 0)
        ) {
          throw new Error(
            'Received empty payload for due diligence form. Aborting to safeguard against accidental deletion.',
          )
        }
        editPracticeFinancialDetails({
          variables: {
            id: practiceId,
            financialDetails: payload,
          },
          onCompleted: async (data) => {
            setTempStore(financialDetails)

            await updatePracticeByIdInCache(
              practiceId,
              data?.editPracticeFinancialDetails,
              cache,
            )

            navRef?.current?.setLoading(false)

            if (callback) {
              callback(data)
            }
          },
        })
      },
      [editPracticeFinancialDetails, practiceId, financialDetails],
    ),
    1000,
  )

  useEffect(() => {
    try {
      if (financialDetails && !_.isEqual(tempStore, financialDetails)) {
        debouncedEdit(financialDetails)
      }
    } catch (error) {
      console.log('ERR =>', error.message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financialDetails, practiceId, tempStore, practiceFormRef])

  useEffect(() => {
    const handleStorageChange = (e) => {
      const data = localStorage.getItem(DUE_DILIGENCE_KEY)
      setFormProgress(data ? JSON.parse(data)[practiceId] || temProg : temProg)
    }

    const interval = setInterval(handleStorageChange, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [practiceId])

  const subSteps = useMemo(() => {
    return steps.map((step, i) => {
      const dueDiligence = _.cloneDeep(financialDetails)
      try {
        if (dueDiligence && dueDiligence[step.name]?.__typename) {
          delete dueDiligence[step.name].__typename
        }
      } catch (error) {
        console.log(error.message)
      }
      const areAllPropertiesNotNull =
        dueDiligence &&
        !_.isEmpty(dueDiligence[step.name]) &&
        _[step.hasOptionalFields ? 'some' : 'every'](
          dueDiligence[step.name],
          (value) => !_.isNull(value),
        )
      const isSkipped =
        formProgress.skipped.includes(step.name) ||
        (viewedSteps.includes(i) && !areAllPropertiesNotNull)
      const isCompleted =
        (formProgress.completed.includes(step.name) ||
          areAllPropertiesNotNull) &&
        !isSkipped
      const status =
        activeSubStep === i
          ? 'pending'
          : isCompleted
            ? 'completed'
            : isSkipped
              ? 'skipped'
              : 'pending'

      return { ...step, status }
    })
  }, [formProgress, financialDetails, activeSubStep, viewedSteps, steps])

  const progressPercentage = useMemo(() => {
    return (activeSubStep / subSteps.length) * 100
  }, [subSteps, activeSubStep])

  useEffect(() => {
    if (practice) {
      setFinancialDetails(practice.dueDiligence)
    }
  }, [practice])

  useEffect(() => {
    const editedData = _.cloneDeep(
      data?.editPracticeFinancialDetails?.dueDiligence,
      {},
    )
    const savedPractice = _.cloneDeep(practice?.dueDiligence || {})
    const mergedData = _.merge(savedPractice, editedData)

    if (mergedData) {
      if (_.some(mergedData, (entry) => !_.isEmpty(entry))) {
        const viewedIndexes = []
        steps.forEach((step, i) => {
          if (mergedData[step.name]) {
            viewedIndexes.push(i)
          }
        })
        setViewedSteps(viewedIndexes)
      }
    }
  }, [practice, data, steps])

  useEffect(() => {
    setViewedSteps((old) => _.uniq(_.concat(old, activeSubStep)))
  }, [activeSubStep])

  useEffect(() => {
    compileProgress()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setFormProgressInLocalStorage = (newFormProgress) => {
    let saved = localStorage.getItem(DUE_DILIGENCE_KEY) || null
    if (saved) {
      saved = JSON.parse(saved)
    }
    localStorage.setItem(
      DUE_DILIGENCE_KEY,
      JSON.stringify({
        ...saved,
        [practiceId]: newFormProgress,
      }),
    )
  }

  useEffect(() => {
    setFormProgressInLocalStorage(formProgress)
  }, [formProgress, practiceId])

  const compileProgress = useCallback(() => {
    const tempProgress = { ...formProgress }
    const groupedByStatus = _.groupBy(subSteps, 'status')
    _.forEach(groupedByStatus.completed, (step) => {
      tempProgress.completed = _.uniq(
        _.concat(tempProgress.completed, step.name),
      )
      if (_.includes(tempProgress.skipped, step.name)) {
        tempProgress.skipped = _.filter(
          tempProgress.skipped,
          (v) => v !== step.name,
        )
      }
    })
    setFormProgress(tempProgress)
  }, [subSteps, formProgress])

  const FormComponent = useMemo(() => {
    return subSteps[activeSubStep]?.component
  }, [activeSubStep, subSteps])

  const FormSubComponent = useMemo(() => {
    return subSteps[activeSubStep]?.subComponent
  }, [activeSubStep, subSteps])

  const onClickBack = useCallback(
    () => {
      const currentPath = window.location.pathname
      const queryParams = new URLSearchParams(window.location.search)
      queryParams.set('navType', 'back')

      window.history.pushState(
        {},
        '',
        `${currentPath}?${queryParams.toString()}`,
      )

      if (formRef.current && formRef.current.onBack) {
        formRef.current.onBack(() => {
          setActiveSubStep(activeSubStep - 1)
        })
      } else {
        setActiveSubStep(activeSubStep - 1)
      }
    },
    [activeSubStep],
    formRef,
  )

  const handleFormSubmission = useCallback(
    (data) => {
      if (activeSubStep < subSteps.length - 1) {
        setFormProgress((old) => ({
          ...old,
          completed: _.uniq(
            _.concat(old.completed, subSteps[activeSubStep].name),
          ),
          skipped: _.filter(
            old.skipped,
            (p) => p !== subSteps[activeSubStep].name,
          ),
        }))
        if (data) {
          setFinancialDetails({
            ...financialDetails,
            [subSteps[activeSubStep].name]: data,
          })
        }
        setActiveSubStep(activeSubStep + 1)
      } else {
        const skipped =
          _.isEmpty(data.employees) &&
          _.isEmpty(financialDetails?.humanResource?.employeesSpreadsheet)

        const updatedFormProgress = {
          ...formProgress,
          completed: skipped
            ? _.filter(
                formProgress.completed,
                (p) => p !== subSteps[activeSubStep].name,
              )
            : _.uniq(
                _.concat(formProgress.completed, subSteps[activeSubStep].name),
              ),
          skipped: skipped
            ? _.uniq(
                _.concat(formProgress.skipped, subSteps[activeSubStep].name),
              )
            : _.filter(
                formProgress.skipped,
                (p) => p !== subSteps[activeSubStep].name,
              ),
        }

        setFormProgress(updatedFormProgress)
        setFormProgressInLocalStorage(updatedFormProgress)

        // move to the next section
        let nextPath = getDueDiligencePathToNavigateTo(
          currentStep,
          practiceId,
          'next',
          currentFlow,
        )
        navigate(nextPath)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [
      activeSubStep,
      subSteps,
      currentStep,
      practiceId,
      currentFlow,
      financialDetails,
    ],
  )

  const handleSaveAndReturn = useCallback(
    (data) => {
      const payload = {
        ...financialDetails,
        [subSteps[activeSubStep].name]: data,
      }

      debouncedEdit(payload, () => {
        // User came from publish page
        if (currentFlow === publishingFlowKey) {
          navigateToPublishListingPage(practiceId, navigate)
          // User is editing their listing
        } else if (currentFlow === editingFlowKey) {
          navigateToViewPracticePage(practiceId, navigate)
        } else {
          // Default send user to my practices page
          navigate(Paths.myPractices)
        }
      })
    },
    [activeSubStep, debouncedEdit, financialDetails, navigate, subSteps],
  )

  const onClickNext = useCallback(() => {
    formRef.current && formRef.current.submit(handleFormSubmission)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formRef, handleFormSubmission])

  const onSaveInFlow = useCallback(() => {
    formRef.current &&
      formRef.current.submit(handleSaveAndReturn, { override: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formRef, handleSaveAndReturn, currentStep])

  const handleOnSkip = useCallback(
    (ignoreStepUpdate) => {
      setFormProgress((old) => ({
        ...old,
        skipped: _.uniq(_.concat(old.skipped, subSteps[activeSubStep].name)),
      }))
      if (!ignoreStepUpdate) {
        setActiveSubStep(activeSubStep + 1)
      }
    },
    [activeSubStep, subSteps],
  )

  const onUpdateFinancialDetails = useCallback(
    (data) => {
      if (data) {
        setFinancialDetails((old) => ({
          ...old,
          [subSteps[activeSubStep].name]: data,
        }))
      }
    },
    [subSteps, activeSubStep],
  )

  const stepIsClickable = useCallback(
    (i) => {
      const reversedIndex = subSteps
        .slice()
        .reverse()
        .findIndex((step) => step.status !== 'pending')
      const lastNonPendingIndex =
        reversedIndex === -1 ? -1 : subSteps.length - 1 - reversedIndex
      const lastIndex = Math.max(lastNonPendingIndex, activeSubStep)
      if (i > lastIndex) return false
      return true
    },
    [subSteps, activeSubStep],
  )

  const handleSubStepClicked = (i) => {
    if (!stepIsClickable(i)) return
    const currentPath = window.location.pathname
    const queryParams = new URLSearchParams(window.location.search)
    queryParams.delete('navType')
    window.history.replaceState(
      {},
      '',
      `${currentPath}?${queryParams.toString()}`,
    )
    setActiveSubStep(i)
  }

  return (
    practice && (
      <>
        <CreatePracticeInputForm
          ref={practiceFormRef}
          user={user}
          width={width}
          ismobilescreen={ismobilescreen}
          currentStep={currentStep}
          practice={practice}
          formTitle={
            <>
              Due Diligence{' '}
              <PrivacyShield
                fieldPrivacyMessage={dueDiligenceFieldPrivacyMessage}
              />
            </>
          }
          formSubtitleColor={'blue'}
          formInputs={[]}
          onClickBack={activeSubStep > 0 && onClickBack}
          onClickNext={onClickNext}
          nextEnabled={!canSkipForm}
          allowSkipping={canSkipForm}
          currentFlow={currentFlow}
          onSaveInFlow={onSaveInFlow}
          skipButtonText={'Skip'}
          isDueDiligence
        >
          <>
            <MainWrapper>
              <BorderedWrapper>
                {!ismobilescreen ? (
                  <CompletenesCard>
                    <ul>
                      {subSteps.map((step, i) => (
                        <li
                          key={i}
                          className={
                            i === activeSubStep
                              ? 'active'
                              : !stepIsClickable(i)
                                ? 'disabled'
                                : 'inactive'
                          }
                          onClick={() => handleSubStepClicked(i)}
                        >
                          {`${i + 1}. ${step.label}`}
                          {step.status === 'completed' && (
                            <CheckCircleIcon sx={{ color: '#016299' }} />
                          )}
                          {step.status === 'skipped' && (
                            <CancelIcon sx={{ color: '#C50000' }} />
                          )}
                          {step.status === 'pending' && (
                            <CircleIcon sx={{ color: '#D9D9D9' }} />
                          )}
                        </li>
                      ))}
                    </ul>
                  </CompletenesCard>
                ) : (
                  <></>
                )}

                <FlexBox className='main'>
                  {FormComponent && (
                    <FormComponent
                      {...props}
                      ref={formRef}
                      subStepName={subSteps[activeSubStep]?.name}
                      formProgress={formProgress}
                      handleCanSkipChanged={setCanSkipForm}
                      onSkip={handleOnSkip}
                      onClickNext={onClickNext}
                      handleFormSubmission={handleFormSubmission}
                      onUpdateFinancialDetails={onUpdateFinancialDetails}
                    />
                  )}
                </FlexBox>
              </BorderedWrapper>
              {FormSubComponent && (
                <FormSubComponent
                  {...props}
                  dueDiligence={
                    data?.editPracticeFinancialDetails?.dueDiligence
                  }
                  onUpdateFinancialDetails={onUpdateFinancialDetails}
                />
              )}
            </MainWrapper>
          </>
        </CreatePracticeInputForm>
      </>
    )
  )
}
export default FinancialDetails
