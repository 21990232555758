import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Paths } from '../../../Paths'
import { notificationMessages } from '../../../utils/constants'
import PopperMenu from '../../PopperMenu'
import {
  NotifChips,
  NotifChipsWrapper,
  NotifWrapperHeaderText,
  NotificationListItem,
  NotificationListItemAvatar,
  NotificationSectionText,
  NotificationText,
  NotificationsContainer,
} from './styled'

const NotificationsPopover = ({ anchorEl, open, onClose, notifications }) => {
  const [showUnread, setShowUnread] = useState(false)

  const sortedNotifications = useMemo(() => {
    const entries = showUnread
      ? _.filter(notifications, (notif) => {
          return !notif.isRead
        })
      : notifications

    const groupedNotifications = _.groupBy(entries, 'isRead')

    const newNotifications = groupedNotifications[false] || []
    const earlierNotifications = groupedNotifications[true] || []

    return {
      new: newNotifications,
      earlier: earlierNotifications,
    }
  }, [showUnread, notifications])

  return (
    <PopperMenu
      open={open}
      anchorElement={anchorEl}
      onClose={onClose}
      variant='fullscreen'
      customStyle={{
        background: 'red',
      }}
    >
      <NotificationsContainer>
        <NotifWrapperHeaderText>Notifications</NotifWrapperHeaderText>
        <NotifChipsWrapper>
          <NotifChips
            label='All'
            active={!showUnread}
            onClick={() => setShowUnread(false)}
          />
          <NotifChips
            label='Unread'
            active={showUnread}
            onClick={() => setShowUnread(true)}
          />
        </NotifChipsWrapper>
        {_.isEmpty(
          _.concat(sortedNotifications.new, sortedNotifications.earlier),
        ) ? (
          <>
            <NotificationText style={{ textAlign: 'center', marginTop: 20 }}>
              You do not have any notifications
            </NotificationText>
          </>
        ) : (
          <>
            {_.map(
              Object.keys(sortedNotifications),
              (key, j) =>
                !_.isEmpty(sortedNotifications[key]) && (
                  <div key={key}>
                    <NotificationSectionText key={j}>
                      {key}
                    </NotificationSectionText>
                    {_.map(sortedNotifications[key], (notif, i) => (
                      <Link
                        key={i}
                        style={{ textDecoration: 'none' }}
                        to={Paths.internalLinkHandler}
                        state={{
                          notification: notif,
                        }}
                      >
                        <NotificationListItem isActive={!notif.isRead}>
                          <div style={{ with: 54, height: 54 }}>
                            <NotificationListItemAvatar
                              variant={notif.notificationType}
                            />
                          </div>
                          <NotificationText>
                            {notificationMessages[notif.notificationType]}
                          </NotificationText>
                        </NotificationListItem>
                      </Link>
                    ))}
                  </div>
                ),
            )}
          </>
        )}
      </NotificationsContainer>
    </PopperMenu>
  )
}

export default NotificationsPopover
